import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter , Route , Routes } from "react-router-dom";
import Login from './Pages/Login';
import AdminFirmax from './Pages/AdminFirmax/AdminFirmax'
import CambiiarContraseña from './Pages/AdminFirmax/Sections/CambiiarContraseña'
import CrearContrato from './Pages/AdminFirmax/Sections/CrearContrato';
import CrearCalculadora from './Pages/AdminFirmax/Sections/CrearCalculadora.jsx';
import CambiarContraseniaInmo from './Pages/Inmobiliaria/CambiarContraseniaInmo.jsx'
import InmobiliariAdmin from './Pages/Inmobiliaria/InmobiliariaAdmin'
import CrearUser from './Pages/AdminFirmax/Sections/CrearUser';
import ListaUserInmo from './Pages/Inmobiliaria/ListaUserInmo';
import VerCalculadoras from './Pages/Inmobiliaria/Calculadora/VerCalculadoras'
import CrearMejoras from './Pages/AdminFirmax/Sections/CrearMejoras';
import Empleado from './Pages/Inmobiliaria/Empleado/Empleado.jsx'
import DiferencialEuribor from './Pages/AdminFirmax/Sections/DiferencialEuribor';
import RecuperarSimulacion from './Pages/Inmobiliaria/recuperarSimulacion';
import Simulaciones from './Pages/AdminFirmax/Sections/Simulaciones';
import DatosInmoAdmin from './Pages/Inmobiliaria/DatosInmoAdmin';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>} />
        <Route path="login" element={<Login/>}/>
        {/* Segmento (rutas) de adminFirmax */}
        <Route path="firmax" element={<AdminFirmax/>}/>
        <Route path="firmax/Crear-user" element={<CrearUser/>}/>
        <Route path="firmax/Cambiar-contraseña" element={<CambiiarContraseña/>}/>
        <Route path="firmax/Crear-contrato" element={<CrearContrato/>}/>
        <Route path="firmax/Crear-calculadora" element={<CrearCalculadora/>}/>
        <Route path="firmax/Crear-mejoras" element={<CrearMejoras/>}/>
        <Route path="firmax/Actualizar-diferencial-euribor" element={<DiferencialEuribor/>}/>
        <Route path="firmax/simulaciones" element={<Simulaciones/>}/>
        {/* Segmento (rutas) de inmobiliarias */}
        <Route path="/inmobiliaria" element={<InmobiliariAdmin/>}/>
        <Route path="inmobiliaria/Lista" element={<ListaUserInmo/>}/> 
        <Route path="inmobiliaria/Cambiar-contraseña" element={<CambiarContraseniaInmo/>}/>    
        <Route path="inmobiliaria/Calculadoras" element={<VerCalculadoras/>}/> 
        <Route path="inmobiliaria/simulacion" element={<RecuperarSimulacion/>}/>
        <Route path="inmobiliaria/configuracion" element={<DatosInmoAdmin/>}/>
        {/* Segmento (rutas) de inmobiliarias */}   
        <Route path="inmobiliaria/inicio" element={<Empleado/>}/> 
      </Routes>
    </BrowserRouter>

  </React.StrictMode>
);


