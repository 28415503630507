import logo from "../../../img/logo.png";
import { db } from "../../../Firebase/Credenciales";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import LogicaFinanzas from "./LogicaFinanzas";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../Firebase/Credenciales";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

const VerCalculadoras = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [seteo] = useState(false)
  const [setearCalculadoras, setSetearCalculadoras] = useState([]);
  //estado de mostrar
  // eslint-disable-next-line no-unused-vars
  const [estadoButton, setEstadoButton] = useState(false);
  //esdel formulario
  const [cuestionarioInicial, setCuestionarioInicial] = useState(0);
  const [cuestionario, setCuestionario] = useState(1);
  //setear por boton los datos
  const [setearDatosCalculadora, setSetearDatosCalculadora] = useState({
    nombre: "",
    nombreInmobiliaria: "",
    porcentajeDelimitante: "",
    honorariosMinimoInferior: "",
    honorariosMaximoInferior: "",
    comisionMinima: "",
    comisionPrimeraIncremnto: "",
    comisionExtraIncremento: "",
    uid: "",
    id: "",
  });
  const [cargando, setCargando] = useState(1);
  const [flag, setFlag] = useState(false);
  const [flagDos, setFlagDos] = useState(true);

  const [mostrarBotones, setMostrarBotones] = useState(true);
  const [idsVarios, setIdsVarios] = useState(true);
  const [actionBack, setActionBack] = useState(null);
  const [seteoDeDatosUser, setSeteoDeDatosUser] = useState()

  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setDate(userLog);
        async function buscarUser() {
          const docRef = doc(db, "users", userLog.uid);
          const docSnap = await getDoc(docRef);
          setSeteoDeDatosUser(docSnap.data())
          switch (docSnap.data().rol) {
            case "adminFirmax":
              navigate("/firmax");
              break;
            case "inmobiliaria":
              navigate("/inmobiliaria/Calculadoras");
              setActionBack(true);
              break;
            case "inmobiliariaEmpleado":
              navigate("/inmobiliaria/Calculadoras");
              setActionBack(false);
              break;
            default:
              break;
          }
        }
        buscarUser();
      } else {
        setDate(null);
        navigate("/login");
      }
    });

    if (flagDos === true) {
      let ids = [];
      async function buscarIds() {
        const querySnapshotTres = await getDocs(collection(db, date.email));
        querySnapshotTres.forEach((docTres) => {
          if (date.email === docTres.data().mail) {
            let id = {
              idsVarios: docTres.data().ids,
            };
            ids.push(id);
          }
        });
        setIdsVarios(ids);
        setFlagDos(false);
        setFlag(true);
      }
      buscarIds();
    }

    if (flag === true) {
      let calculadoras = [];

      async function calc() {
        setCargando(1);
        const querySnapshotDos = await getDocs(collection(db, "calculadoras"));

        querySnapshotDos.forEach((docDos) => {
          for (let i = 0; i < 10; i++) {
            if (idsVarios[0].idsVarios[i] === docDos.data().id) {
              let calculadora = {
                bonificacion: docDos.data().datosNuevaCalculadora.bonificacion,
                mostrarHipotecaFija:
                docDos.data().datosNuevaCalculadora.mostrarHipotecaFija,
              mostrarHipotecaVariable:
                docDos.data().datosNuevaCalculadora.mostrarHipotecaVariable,
                mostrarHipotecaMixta:
                docDos.data().datosNuevaCalculadora.mostrarHipotecaMixta,
                primerPeriodoVariable:
                  docDos.data().datosNuevaCalculadora.primerPeriodoVariable,
                primerPeriodoMixto:
                  docDos.data().datosNuevaCalculadora.primerPeriodoMixto,
                hipotecaMinima:
                  docDos.data().datosNuevaCalculadora.hipotecaMinima,
                hipotecaMaxima:
                  docDos.data().datosNuevaCalculadora.hipotecaMaxima,
                nombre: docDos.data().datosNuevaCalculadora.nombre,
                nombreInmobiliaria:
                  docDos.data().datosNuevaCalculadora.nombreInmobiliaria,
                porcentajeDelimitante:
                  docDos.data().datosNuevaCalculadora.porcentajeDelimitante,
                honorariosMinimoInferior:
                  docDos.data().datosNuevaCalculadora.honorariosMinimoInferior,
                honorariosMaximoInferior:
                  docDos.data().datosNuevaCalculadora.honorariosMaximoInferior,
                comisionMinima:
                  docDos.data().datosNuevaCalculadora.comisionMinima,
                comisionPrimeraIncremnto:
                  docDos.data().datosNuevaCalculadora.comisionPrimeraIncremnto,
                comisionExtraIncremento:
                  docDos.data().datosNuevaCalculadora.comisionExtraIncremento,
                uid: docDos._document.key.path.segments[6],
                id: docDos.data().id,
                mostrarComision:
                  docDos.data().datosNuevaCalculadora.mostrarComision,
                incuirEdadtitualMenor:
                  docDos.data().datosNuevaCalculadora.incuirEdadtitualMenor,
                maximoSobreTasacion:
                  docDos.data().datosNuevaCalculadora.maximoSobreTasacion,
                productoHipotecario:
                  docDos.data().datosNuevaCalculadora.productoHipotecario,
                tinPrimerosMesesVariable:
                  docDos.data().datosNuevaCalculadora.tinPrimerosMesesVariable,
                  tinPrimerosMesesMixto:
                  docDos.data().datosNuevaCalculadora.tinPrimerosMesesMixto,
                //interes fijo
                plazoFijoMinimo:
                  docDos.data().datosNuevaCalculadora.plazoFijoMinimo,
                plazoFijoMaximo:
                  docDos.data().datosNuevaCalculadora.plazoFijoMaximo,
                plazoVariableMinimo:
                  docDos.data().datosNuevaCalculadora.plazoVariableMinimo,
                plazoVariableMaximo:
                  docDos.data().datosNuevaCalculadora.plazoVariableMaximo,
                plazoMixtoMinimo:
                  docDos.data().datosNuevaCalculadora.plazoMixtoMinimo,
                plazoMixtoMaximo:
                  docDos.data().datosNuevaCalculadora.plazoMixtoMaximo,
                edadMaximaTitular:
                  docDos.data().datosNuevaCalculadora.edadMaximaTitular,
                  edadMaximaTitularMenor: docDos.data().datosNuevaCalculadora.edadMaximaTitularMenor,
                hastaDiezFijo:
                  docDos.data().datosNuevaCalculadora.hastaDiezFijo,
                hastaQuinceFijo:
                  docDos.data().datosNuevaCalculadora.hastaQuinceFijo,
                hastaVeinteFijo:
                  docDos.data().datosNuevaCalculadora.hastaVeinteFijo,
                hastaVeinteCincoFijo:
                  docDos.data().datosNuevaCalculadora.hastaVeinteCincoFijo,
                hastaTreintaFijo:
                  docDos.data().datosNuevaCalculadora.hastaTreintaFijo,
                hastaTreintaCincoFijo:
                  docDos.data().datosNuevaCalculadora.hastaTreintaCincoFijo,
                hastaCuarentaFijo:
                  docDos.data().datosNuevaCalculadora.hastaCuarentaFijo,
                hastaCuarentaCincoFijo:
                  docDos.data().datosNuevaCalculadora.hastaCuarentaCincoFijo,
                hastaCincuentFijo:
                  docDos.data().datosNuevaCalculadora.hastaCincuentFijo,
                //interes variable
                hastaDiezVariable:
                  docDos.data().datosNuevaCalculadora.hastaDiezVariable,
                hastaQuinceVariable:
                  docDos.data().datosNuevaCalculadora.hastaQuinceVariable,
                hastaVeinteVariable:
                  docDos.data().datosNuevaCalculadora.hastaVeinteVariable,
                hastaVeinteCincoVariable:
                  docDos.data().datosNuevaCalculadora.hastaVeinteCincoVariable,
                hastaTreintaVariable:
                  docDos.data().datosNuevaCalculadora.hastaTreintaVariable,
                hastaTreintaCincoVariable:
                  docDos.data().datosNuevaCalculadora.hastaTreintaCincoVariable,
                hastaCuarentaVariable:
                  docDos.data().datosNuevaCalculadora.hastaCuarentaVariable,
                hastaCuarentaCincoVariable:
                  docDos.data().datosNuevaCalculadora
                    .hastaCuarentaCincoVariable,
                hastaCincuentVariable:
                  docDos.data().datosNuevaCalculadora.hastaCincuentVariable,
                //interes mixto
                hastaDiezMixto:
                  docDos.data().datosNuevaCalculadora.hastaDiezMixto,
                hastaQuinceMixto:
                  docDos.data().datosNuevaCalculadora.hastaQuinceMixto,
                hastaVeinteMixto:
                  docDos.data().datosNuevaCalculadora.hastaVeinteMixto,
                hastaVeinteCincoMixto:
                  docDos.data().datosNuevaCalculadora.hastaVeinteCincoMixto,
                hastaTreintaMixto:
                  docDos.data().datosNuevaCalculadora.hastaTreintaMixto,
                hastaTreintaCincoMixto:
                  docDos.data().datosNuevaCalculadora.hastaTreintaCincoMixto,
                hastaCuarentaMixto:
                  docDos.data().datosNuevaCalculadora.hastaCuarentaMixto,
                hastaCuarentaCincoMixto:
                  docDos.data().datosNuevaCalculadora.hastaCuarentaCincoMixto,
                hastaCincuentMixto:
                  docDos.data().datosNuevaCalculadora.hastaCincuentMixto,
                //DOBLE GARANTIA
                delEscnario0DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia1,
                delEscnario0DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia2,
                delEscnario0DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia3,
                delEscnario0DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia4,
                delEscnario0DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia5,
                delEscnario0DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia6,
                delEscnario0DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia7,
                delEscnario0DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia8,
                delEscnario0DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia9,
                delEscnario0DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia10,
                delEscnario0DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia11,
                delEscnario0DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0DobleGarantia12,
                //1
                delEscnario1DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia1,
                delEscnario1DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia2,
                delEscnario1DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia3,
                delEscnario1DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia4,
                delEscnario1DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia5,
                delEscnario1DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia6,
                delEscnario1DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia7,
                delEscnario1DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia8,
                delEscnario1DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia9,
                delEscnario1DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia10,
                delEscnario1DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia11,
                delEscnario1DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1DobleGarantia12,
                //2
                delEscnario2DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia1,
                delEscnario2DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia2,
                delEscnario2DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia3,
                delEscnario2DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia4,
                delEscnario2DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia5,
                delEscnario2DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia6,
                delEscnario2DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia7,
                delEscnario2DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia8,
                delEscnario2DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia9,
                delEscnario2DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia10,
                delEscnario2DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia11,
                delEscnario2DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2DobleGarantia12,
                //3
                delEscnario3DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia1,
                delEscnario3DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia2,
                delEscnario3DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia3,
                delEscnario3DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia4,
                delEscnario3DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia5,
                delEscnario3DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia6,
                delEscnario3DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia7,
                delEscnario3DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia8,
                delEscnario3DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia9,
                delEscnario3DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia10,
                delEscnario3DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia11,
                delEscnario3DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3DobleGarantia12,
                //4
                delEscnario4DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia1,
                delEscnario4DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia2,
                delEscnario4DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia3,
                delEscnario4DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia4,
                delEscnario4DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia5,
                delEscnario4DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia6,
                delEscnario4DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia7,
                delEscnario4DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia8,
                delEscnario4DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia9,
                delEscnario4DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia10,
                delEscnario4DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia11,
                delEscnario4DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4DobleGarantia12,
                //5
                delEscnario5DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia1,
                delEscnario5DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia2,
                delEscnario5DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia3,
                delEscnario5DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia4,
                delEscnario5DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia5,
                delEscnario5DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia6,
                delEscnario5DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia7,
                delEscnario5DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia8,
                delEscnario5DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia9,
                delEscnario5DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia10,
                delEscnario5DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia11,
                delEscnario5DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5DobleGarantia12,
                //6
                delEscnario6DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia1,
                delEscnario6DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia2,
                delEscnario6DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia3,
                delEscnario6DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia4,
                delEscnario6DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia5,
                delEscnario6DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia6,
                delEscnario6DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia7,
                delEscnario6DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia8,
                delEscnario6DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia9,
                delEscnario6DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia10,
                delEscnario6DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia11,
                delEscnario6DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6DobleGarantia12,
                //7
                delEscnario7DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia1,
                delEscnario7DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia2,
                delEscnario7DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia3,
                delEscnario7DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia4,
                delEscnario7DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia5,
                delEscnario7DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia6,
                delEscnario7DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia7,
                delEscnario7DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia8,
                delEscnario7DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia9,
                delEscnario7DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia10,
                delEscnario7DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia11,
                delEscnario7DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7DobleGarantia12,
                //8
                delEscnario8DobleGarantia1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia1,
                delEscnario8DobleGarantia2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia2,
                delEscnario8DobleGarantia3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia3,
                delEscnario8DobleGarantia4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia4,
                delEscnario8DobleGarantia5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia5,
                delEscnario8DobleGarantia6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia6,
                delEscnario8DobleGarantia7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia7,
                delEscnario8DobleGarantia8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia8,
                delEscnario8DobleGarantia9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia9,
                delEscnario8DobleGarantia10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia10,
                delEscnario8DobleGarantia11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia11,
                delEscnario8DobleGarantia12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8DobleGarantia12,

                //MEJORAS
                delEscnario0Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras1,
                delEscnario0Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras2,
                delEscnario0Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras3,
                delEscnario0Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras4,
                delEscnario0Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras5,
                delEscnario0Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras6,
                delEscnario0Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras7,
                delEscnario0Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras8,
                delEscnario0Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras9,
                delEscnario0Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras10,
                delEscnario0Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras11,
                delEscnario0Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras12,
                delEscnario0Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras13,
                delEscnario0Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras14,
                delEscnario0Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras15,
                //1
                delEscnario1Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras1,
                delEscnario1Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras2,
                delEscnario1Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras3,
                delEscnario1Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras4,
                delEscnario1Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras5,
                delEscnario1Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras6,
                delEscnario1Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras7,
                delEscnario1Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras8,
                delEscnario1Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras9,
                delEscnario1Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras10,
                delEscnario1Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras11,
                delEscnario1Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras12,
                delEscnario1Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras13,
                delEscnario1Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras14,
                delEscnario1Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario1Mejoras15,
                //2
                delEscnario2Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras1,
                delEscnario2Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras2,
                delEscnario2Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras3,
                delEscnario2Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras4,
                delEscnario2Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras5,
                delEscnario2Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras6,
                delEscnario2Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras7,
                delEscnario2Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras8,
                delEscnario2Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras9,
                delEscnario2Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras10,
                delEscnario2Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras11,
                delEscnario2Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras12,
                delEscnario2Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras13,
                delEscnario2Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras14,
                delEscnario2Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario2Mejoras15,
                //3
                delEscnario3Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras1,
                delEscnario3Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras2,
                delEscnario3Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras3,
                delEscnario3Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras4,
                delEscnario3Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras5,
                delEscnario3Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras6,
                delEscnario3Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras7,
                delEscnario3Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras8,
                delEscnario3Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras9,
                delEscnario3Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras10,
                delEscnario3Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras11,
                delEscnario3Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras12,
                delEscnario3Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras13,
                delEscnario3Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario3Mejoras14,
                delEscnario3Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario0Mejoras15,
                //4
                delEscnario4Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras1,
                delEscnario4Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras2,
                delEscnario4Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras3,
                delEscnario4Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras4,
                delEscnario4Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras5,
                delEscnario4Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras6,
                delEscnario4Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras7,
                delEscnario4Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras8,
                delEscnario4Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras9,
                delEscnario4Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras10,
                delEscnario4Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras11,
                delEscnario4Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras12,
                delEscnario4Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras13,
                delEscnario4Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras14,
                delEscnario4Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario4Mejoras15,
                //5
                delEscnario5Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras1,
                delEscnario5Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras2,
                delEscnario5Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras3,
                delEscnario5Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras4,
                delEscnario5Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras5,
                delEscnario5Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras6,
                delEscnario5Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras7,
                delEscnario5Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras8,
                delEscnario5Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras9,
                delEscnario5Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras10,
                delEscnario5Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras11,
                delEscnario5Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras12,
                delEscnario5Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras13,
                delEscnario5Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras14,
                delEscnario5Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario5Mejoras15,
                //6
                delEscnario6Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras1,
                delEscnario6Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras2,
                delEscnario6Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras3,
                delEscnario6Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras4,
                delEscnario6Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras5,
                delEscnario6Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras6,
                delEscnario6Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras7,
                delEscnario6Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras8,
                delEscnario6Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras9,
                delEscnario6Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras10,
                delEscnario6Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras11,
                delEscnario6Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras12,
                delEscnario6Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras13,
                delEscnario6Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras14,
                delEscnario6Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario6Mejoras15,
                //7
                delEscnario7Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras1,
                delEscnario7Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras2,
                delEscnario7Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras3,
                delEscnario7Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras4,
                delEscnario7Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras5,
                delEscnario7Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras6,
                delEscnario7Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras7,
                delEscnario7Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras8,
                delEscnario7Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras9,
                delEscnario7Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras10,
                delEscnario7Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras11,
                delEscnario7Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras12,
                delEscnario7Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras13,
                delEscnario7Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras14,
                delEscnario7Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario7Mejoras15,
                //8
                delEscnario8Mejoras1:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras1,
                delEscnario8Mejoras2:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras2,
                delEscnario8Mejoras3:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras3,
                delEscnario8Mejoras4:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras4,
                delEscnario8Mejoras5:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras5,
                delEscnario8Mejoras6:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras6,
                delEscnario8Mejoras7:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras7,
                delEscnario8Mejoras8:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras8,
                delEscnario8Mejoras9:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras9,
                delEscnario8Mejoras10:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras10,
                delEscnario8Mejoras11:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras11,
                delEscnario8Mejoras12:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras12,
                delEscnario8Mejoras13:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras13,
                delEscnario8Mejoras14:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras14,
                delEscnario8Mejoras15:
                  docDos.data().datosNuevaCalculadora.delEscnario8Mejoras15,
                //LIMITES DE PORCENTAJE
                //0
                delEscnario0Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite1,
                delEscnario0Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite2,
                delEscnario0Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite3,
                delEscnario0Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite4,
                delEscnario0Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite5,
                delEscnario0Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite6,
                delEscnario0Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite7,
                delEscnario0Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite8,
                delEscnario0Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite9,
                delEscnario0Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite10,
                delEscnario0Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite11,
                delEscnario0Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite12,
                delEscnario0Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite13,
                delEscnario0Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite14,
                delEscnario0Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite15,
                //1
                delEscnario1Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite1,
                delEscnario1Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite2,
                delEscnario1Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite3,
                delEscnario1Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite4,
                delEscnario1Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite5,
                delEscnario1Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite6,
                delEscnario1Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite7,
                delEscnario1Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite8,
                delEscnario1Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite9,
                delEscnario1Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite10,
                delEscnario1Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite11,
                delEscnario1Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite12,
                delEscnario1Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite13,
                delEscnario1Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite14,
                delEscnario1Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario1Limite15,
                //2
                delEscnario2Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite1,
                delEscnario2Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite2,
                delEscnario2Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite3,
                delEscnario2Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite4,
                delEscnario2Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite5,
                delEscnario2Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite6,
                delEscnario2Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite7,
                delEscnario2Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite8,
                delEscnario2Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite9,
                delEscnario2Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite10,
                delEscnario2Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite11,
                delEscnario2Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite12,
                delEscnario2Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite13,
                delEscnario2Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite14,
                delEscnario2Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario2Limite15,
                //3
                delEscnario3Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite1,
                delEscnario3Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite2,
                delEscnario3Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite3,
                delEscnario3Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite4,
                delEscnario3Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite5,
                delEscnario3Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite6,
                delEscnario3Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite7,
                delEscnario3Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite8,
                delEscnario3Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite9,
                delEscnario3Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite10,
                delEscnario3Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite11,
                delEscnario3Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite12,
                delEscnario3Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite13,
                delEscnario3Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario3Limite14,
                delEscnario3Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario0Limite15,
                //4
                delEscnario4Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite1,
                delEscnario4Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite2,
                delEscnario4Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite3,
                delEscnario4Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite4,
                delEscnario4Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite5,
                delEscnario4Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite6,
                delEscnario4Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite7,
                delEscnario4Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite8,
                delEscnario4Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite9,
                delEscnario4Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite10,
                delEscnario4Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite11,
                delEscnario4Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite12,
                delEscnario4Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite13,
                delEscnario4Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite14,
                delEscnario4Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario4Limite15,
                //5
                delEscnario5Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite1,
                delEscnario5Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite2,
                delEscnario5Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite3,
                delEscnario5Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite4,
                delEscnario5Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite5,
                delEscnario5Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite6,
                delEscnario5Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite7,
                delEscnario5Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite8,
                delEscnario5Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite9,
                delEscnario5Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite10,
                delEscnario5Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite11,
                delEscnario5Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite12,
                delEscnario5Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite13,
                delEscnario5Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite14,
                delEscnario5Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario5Limite15,
                //6
                delEscnario6Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite1,
                delEscnario6Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite2,
                delEscnario6Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite3,
                delEscnario6Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite4,
                delEscnario6Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite5,
                delEscnario6Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite6,
                delEscnario6Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite7,
                delEscnario6Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite8,
                delEscnario6Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite9,
                delEscnario6Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite10,
                delEscnario6Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite11,
                delEscnario6Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite12,
                delEscnario6Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite13,
                delEscnario6Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite14,
                delEscnario6Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario6Limite15,
                //7
                delEscnario7Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite1,
                delEscnario7Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite2,
                delEscnario7Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite3,
                delEscnario7Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite4,
                delEscnario7Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite5,
                delEscnario7Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite6,
                delEscnario7Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite7,
                delEscnario7Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite8,
                delEscnario7Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite9,
                delEscnario7Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite10,
                delEscnario7Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite11,
                delEscnario7Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite12,
                delEscnario7Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite13,
                delEscnario7Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite14,
                delEscnario7Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario7Limite15,
                //8
                delEscnario8Limite1:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite1,
                delEscnario8Limite2:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite2,
                delEscnario8Limite3:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite3,
                delEscnario8Limite4:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite4,
                delEscnario8Limite5:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite5,
                delEscnario8Limite6:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite6,
                delEscnario8Limite7:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite7,
                delEscnario8Limite8:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite8,
                delEscnario8Limite9:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite9,
                delEscnario8Limite10:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite10,
                delEscnario8Limite11:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite11,
                delEscnario8Limite12:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite12,
                delEscnario8Limite13:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite13,
                delEscnario8Limite14:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite14,
                delEscnario8Limite15:
                  docDos.data().datosNuevaCalculadora.delEscnario8Limite15,
                //DESDE (DESDE) X NUMERO PORCENTAJE
                delEscnario0: docDos.data().datosNuevaCalculadora.delEscnario0,
                delEscnario1: docDos.data().datosNuevaCalculadora.delEscnario1,
                delEscnario2: docDos.data().datosNuevaCalculadora.delEscnario2,
                delEscnario3: docDos.data().datosNuevaCalculadora.delEscnario3,
                delEscnario4: docDos.data().datosNuevaCalculadora.delEscnario4,
                delEscnario5: docDos.data().datosNuevaCalculadora.delEscnario5,
                delEscnario6: docDos.data().datosNuevaCalculadora.delEscnario6,
                delEscnario7: docDos.data().datosNuevaCalculadora.delEscnario7,
                delEscnario8: docDos.data().datosNuevaCalculadora.delEscnario8,
                //HASTA (AL) X NUMERO PORCENTAJE
                alEscnario0: docDos.data().datosNuevaCalculadora.alEscnario0,
                alEscnario1: docDos.data().datosNuevaCalculadora.alEscnario1,
                alEscnario2: docDos.data().datosNuevaCalculadora.alEscnario2,
                alEscnario3: docDos.data().datosNuevaCalculadora.alEscnario3,
                alEscnario4: docDos.data().datosNuevaCalculadora.alEscnario4,
                alEscnario5: docDos.data().datosNuevaCalculadora.alEscnario5,
                alEscnario6: docDos.data().datosNuevaCalculadora.alEscnario6,
                alEscnario7: docDos.data().datosNuevaCalculadora.alEscnario7,
                alEscnario8: docDos.data().datosNuevaCalculadora.alEscnario8,
                //LIMITE DE FINANCIAZION CARTEL POR ESCENARIO

                // ANTIGUEDAD DE CONTRATO MENOR A (TIEMPO)
                // ESCENARIO 0
                delEscnario0meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses1,
                delEscnario0meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses2,
                delEscnario0meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses3,
                delEscnario0meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses4,
                delEscnario0meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses5,
                delEscnario0meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses6,
                delEscnario0meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses7,
                delEscnario0meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses8,
                delEscnario0meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses9,
                delEscnario0meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses10,
                delEscnario0meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses11,
                delEscnario0meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses12,
                delEscnario0meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses13,
                delEscnario0meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses14,
                delEscnario0meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario0meses15,
                //ESCENARIO 1
                delEscnario1meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses1,
                delEscnario1meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses2,
                delEscnario1meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses3,
                delEscnario1meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses4,
                delEscnario1meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses5,
                delEscnario1meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses6,
                delEscnario1meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses7,
                delEscnario1meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses8,
                delEscnario1meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses9,
                delEscnario1meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses10,
                delEscnario1meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses11,
                delEscnario1meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses12,
                delEscnario1meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses13,
                delEscnario1meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses14,
                delEscnario1meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses15,
                //ESCENARIO 2
                delEscnario2meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses1,
                delEscnario2meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses2,
                delEscnario2meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses3,
                delEscnario2meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses4,
                delEscnario2meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses5,
                delEscnario2meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses6,
                delEscnario2meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses7,
                delEscnario2meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses8,
                delEscnario2meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses9,
                delEscnario2meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses10,
                delEscnario2meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses11,
                delEscnario2meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses12,
                delEscnario2meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses13,
                delEscnario2meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses14,
                delEscnario2meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario2meses15,
                //ESCENARIO 3
                delEscnario3meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses1,
                delEscnario3meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses2,
                delEscnario3meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses3,
                delEscnario3meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses4,
                delEscnario3meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses5,
                delEscnario3meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses6,
                delEscnario3meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses7,
                delEscnario3meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses8,
                delEscnario3meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses9,
                delEscnario3meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses10,
                delEscnario3meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses11,
                delEscnario3meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses12,
                delEscnario3meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses13,
                delEscnario3meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses14,
                delEscnario3meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario3meses15,
                //ESCENARIO 4
                delEscnario4meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses1,
                delEscnario4meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses2,
                delEscnario4meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses3,
                delEscnario4meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses4,
                delEscnario4meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses5,
                delEscnario4meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses6,
                delEscnario4meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses7,
                delEscnario4meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses8,
                delEscnario4meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses9,
                delEscnario4meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses10,
                delEscnario4meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses11,
                delEscnario4meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses12,
                delEscnario4meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses13,
                delEscnario4meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses14,
                delEscnario4meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario4meses15,
                //ESCENARIO 5
                delEscnario5meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses1,
                delEscnario5meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses2,
                delEscnario5meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses3,
                delEscnario5meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses4,
                delEscnario5meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses5,
                delEscnario5meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses6,
                delEscnario5meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses7,
                delEscnario5meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses8,
                delEscnario5meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses9,
                delEscnario5meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses10,
                delEscnario5meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses11,
                delEscnario5meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses12,
                delEscnario5meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses13,
                delEscnario5meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses14,
                delEscnario5meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario5meses15,
                //ESCENARIO 6
                delEscnario6meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses1,
                delEscnario6meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses2,
                delEscnario6meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses3,
                delEscnario6meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses4,
                delEscnario6meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses5,
                delEscnario6meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses6,
                delEscnario6meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses7,
                delEscnario6meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses8,
                delEscnario6meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses9,
                delEscnario6meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses10,
                delEscnario6meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses11,
                delEscnario6meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses12,
                delEscnario6meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses13,
                delEscnario6meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario6meses14,
                delEscnario6meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario1meses15,
                //ESCENARIO 7
                delEscnario7meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses1,
                delEscnario7meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses2,
                delEscnario7meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses3,
                delEscnario7meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses4,
                delEscnario7meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses5,
                delEscnario7meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses6,
                delEscnario7meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses7,
                delEscnario7meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses8,
                delEscnario7meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses9,
                delEscnario7meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses10,
                delEscnario7meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses11,
                delEscnario7meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses12,
                delEscnario7meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses13,
                delEscnario7meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses14,
                delEscnario7meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario7meses15,
                //ESCENARIO 8
                delEscnario8meses1:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses1,
                delEscnario8meses2:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses2,
                delEscnario8meses3:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses3,
                delEscnario8meses4:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses4,
                delEscnario8meses5:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses5,
                delEscnario8meses6:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses6,
                delEscnario8meses7:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses7,
                delEscnario8meses8:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses8,
                delEscnario8meses9:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses9,
                delEscnario8meses10:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses10,
                delEscnario8meses11:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses11,
                delEscnario8meses12:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses12,
                delEscnario8meses13:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses13,
                delEscnario8meses14:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses14,
                delEscnario8meses15:
                  docDos.data().datosNuevaCalculadora.delEscnario8meses15,

                // ANTIGUEDAD DE CONTRATO MENOR A (PENA)
                // ESCENARIO 0
                delEscnario0pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena1,
                delEscnario0pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena2,
                delEscnario0pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena3,
                delEscnario0pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena4,
                delEscnario0pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena5,
                delEscnario0pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena6,
                delEscnario0pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena7,
                delEscnario0pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena8,
                delEscnario0pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena9,
                delEscnario0pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena10,
                delEscnario0pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena11,
                delEscnario0pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena12,
                delEscnario0pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena13,
                delEscnario0pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena14,
                delEscnario0pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena15,
                //ESCENARIO 1
                delEscnario1pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena1,
                delEscnario1pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena2,
                delEscnario1pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena3,
                delEscnario1pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena4,
                delEscnario1pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena5,
                delEscnario1pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena6,
                delEscnario1pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena7,
                delEscnario1pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena8,
                delEscnario1pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena9,
                delEscnario1pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena10,
                delEscnario1pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena11,
                delEscnario1pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena12,
                delEscnario1pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena13,
                delEscnario1pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario1pena14,
                delEscnario1pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena15,
                //ESCENARIO 2
                delEscnario2pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena1,
                delEscnario2pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena2,
                delEscnario2pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena3,
                delEscnario2pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena4,
                delEscnario2pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena5,
                delEscnario2pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena6,
                delEscnario2pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena7,
                delEscnario2pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena8,
                delEscnario2pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena9,
                delEscnario2pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena10,
                delEscnario2pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena11,
                delEscnario2pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena12,
                delEscnario2pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena13,
                delEscnario2pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena14,
                delEscnario2pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario2pena15,
                //ESCENARIO 3
                delEscnario3pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena1,
                delEscnario3pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena2,
                delEscnario3pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena3,
                delEscnario3pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena4,
                delEscnario3pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena5,
                delEscnario3pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena6,
                delEscnario3pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena7,
                delEscnario3pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena8,
                delEscnario3pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena9,
                delEscnario3pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena10,
                delEscnario3pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena11,
                delEscnario3pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena12,
                delEscnario3pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena13,
                delEscnario3pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario3pena14,
                delEscnario3pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena15,
                //ESCENARIO 4
                delEscnario4pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena1,
                delEscnario4pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena2,
                delEscnario4pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena3,
                delEscnario4pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena4,
                delEscnario4pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena5,
                delEscnario4pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena6,
                delEscnario4pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena7,
                delEscnario4pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena8,
                delEscnario4pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena9,
                delEscnario4pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena10,
                delEscnario4pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena11,
                delEscnario4pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena12,
                delEscnario4pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena13,
                delEscnario4pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario4pena14,
                delEscnario4pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario0pena15,
                //ESCENARIO 5
                delEscnario5pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena1,
                delEscnario5pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena2,
                delEscnario5pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena3,
                delEscnario5pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena4,
                delEscnario5pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena5,
                delEscnario5pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena6,
                delEscnario5pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena7,
                delEscnario5pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena8,
                delEscnario5pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena9,
                delEscnario5pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena10,
                delEscnario5pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena11,
                delEscnario5pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena12,
                delEscnario5pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena13,
                delEscnario5pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena14,
                delEscnario5pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario5pena15,
                //ESCENARIO 6
                delEscnario6pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena1,
                delEscnario6pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena2,
                delEscnario6pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena3,
                delEscnario6pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena4,
                delEscnario6pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena5,
                delEscnario6pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena6,
                delEscnario6pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena7,
                delEscnario6pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena8,
                delEscnario6pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena9,
                delEscnario6pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena10,
                delEscnario6pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena11,
                delEscnario6pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena12,
                delEscnario6pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena13,
                delEscnario6pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena14,
                delEscnario6pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario6pena15,
                //ESCENARIO 7
                delEscnario7pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena1,
                delEscnario7pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena2,
                delEscnario7pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena3,
                delEscnario7pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena4,
                delEscnario7pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena5,
                delEscnario7pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena6,
                delEscnario7pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena7,
                delEscnario7pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena8,
                delEscnario7pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena9,
                delEscnario7pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena10,
                delEscnario7pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena11,
                delEscnario7pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena12,
                delEscnario7pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena13,
                delEscnario7pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena14,
                delEscnario7pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario7pena15,
                //ESCENARIO 8
                delEscnario8pena1:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena1,
                delEscnario8pena2:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena2,
                delEscnario8pena3:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena3,
                delEscnario8pena4:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena4,
                delEscnario8pena5:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena5,
                delEscnario8pena6:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena6,
                delEscnario8pena7:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena7,
                delEscnario8pena8:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena8,
                delEscnario8pena9:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena9,
                delEscnario8pena10:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena10,
                delEscnario8pena11:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena11,
                delEscnario8pena12:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena12,
                delEscnario8pena13:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena13,
                delEscnario8pena14:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena14,
                delEscnario8pena15:
                  docDos.data().datosNuevaCalculadora.delEscnario8pena15,

                // SEGUNDA ANTIGUEDAD MAYOR IGUAL(TIEMPO)
                // ESCENARIO 0
                delEscnario0antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses1,
                delEscnario0antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses2,
                delEscnario0antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses3,
                delEscnario0antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses4,
                delEscnario0antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses5,
                delEscnario0antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses6,
                delEscnario0antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses7,
                delEscnario0antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses8,
                delEscnario0antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses9,
                delEscnario0antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses10,
                delEscnario0antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses11,
                delEscnario0antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses12,
                delEscnario0antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses13,
                delEscnario0antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses14,
                delEscnario0antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadMeses15,
                // ESCENARIO 1
                delEscnario1antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses1,
                delEscnario1antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses2,
                delEscnario1antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses3,
                delEscnario1antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses4,
                delEscnario1antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses5,
                delEscnario1antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses6,
                delEscnario1antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses7,
                delEscnario1antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses8,
                delEscnario1antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses9,
                delEscnario1antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses10,
                delEscnario1antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses11,
                delEscnario1antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses12,
                delEscnario1antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses13,
                delEscnario1antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses14,
                delEscnario1antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadMeses15,
                // ESCENARIO 2
                delEscnario2antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses1,
                delEscnario2antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses2,
                delEscnario2antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses3,
                delEscnario2antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses4,
                delEscnario2antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses5,
                delEscnario2antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses6,
                delEscnario2antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses7,
                delEscnario2antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses8,
                delEscnario2antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses9,
                delEscnario2antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses10,
                delEscnario2antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses11,
                delEscnario2antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses12,
                delEscnario2antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses13,
                delEscnario2antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses14,
                delEscnario2antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadMeses15,
                // ESCENARIO 3
                delEscnario3antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses1,
                delEscnario3antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses2,
                delEscnario3antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses3,
                delEscnario3antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses4,
                delEscnario3antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses5,
                delEscnario3antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses6,
                delEscnario3antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses7,
                delEscnario3antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses8,
                delEscnario3antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses9,
                delEscnario3antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses10,
                delEscnario3antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses11,
                delEscnario3antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses12,
                delEscnario3antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses13,
                delEscnario3antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses14,
                delEscnario3antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadMeses15,
                // ESCENARIO 4
                delEscnario4antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses1,
                delEscnario4antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses2,
                delEscnario4antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses3,
                delEscnario4antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses4,
                delEscnario4antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses5,
                delEscnario4antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses6,
                delEscnario4antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses7,
                delEscnario4antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses8,
                delEscnario4antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses9,
                delEscnario4antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses10,
                delEscnario4antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses11,
                delEscnario4antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses12,
                delEscnario4antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses13,
                delEscnario4antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses14,
                delEscnario4antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadMeses15,
                // ESCENARIO 5
                delEscnario5antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses1,
                delEscnario5antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses2,
                delEscnario5antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses3,
                delEscnario5antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses4,
                delEscnario5antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses5,
                delEscnario5antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses6,
                delEscnario5antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses7,
                delEscnario5antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses8,
                delEscnario5antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses9,
                delEscnario5antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses10,
                delEscnario5antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses11,
                delEscnario5antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses12,
                delEscnario5antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses13,
                delEscnario5antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses14,
                delEscnario5antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadMeses15,
                // ESCENARIO 6
                delEscnario6antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses1,
                delEscnario6antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses2,
                delEscnario6antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses3,
                delEscnario6antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses4,
                delEscnario6antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses5,
                delEscnario6antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses6,
                delEscnario6antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses7,
                delEscnario6antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses8,
                delEscnario6antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses9,
                delEscnario6antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses10,
                delEscnario6antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses11,
                delEscnario6antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses12,
                delEscnario6antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses13,
                delEscnario6antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses14,
                delEscnario6antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadMeses15,
                // ESCENARIO 7
                delEscnario7antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses1,
                delEscnario7antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses2,
                delEscnario7antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses3,
                delEscnario7antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses4,
                delEscnario7antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses5,
                delEscnario7antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses6,
                delEscnario7antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses7,
                delEscnario7antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses8,
                delEscnario7antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses9,
                delEscnario7antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses10,
                delEscnario7antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses11,
                delEscnario7antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses12,
                delEscnario7antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses13,
                delEscnario7antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses14,
                delEscnario7antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadMeses15,
                // ESCENARIO 8
                delEscnario8antiguedadMeses1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses1,
                delEscnario8antiguedadMeses2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses2,
                delEscnario8antiguedadMeses3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses3,
                delEscnario8antiguedadMeses4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses4,
                delEscnario8antiguedadMeses5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses5,
                delEscnario8antiguedadMeses6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses6,
                delEscnario8antiguedadMeses7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses7,
                delEscnario8antiguedadMeses8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses8,
                delEscnario8antiguedadMeses9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses9,
                delEscnario8antiguedadMeses10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses10,
                delEscnario8antiguedadMeses11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses11,
                delEscnario8antiguedadMeses12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses12,
                delEscnario8antiguedadMeses13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses13,
                delEscnario8antiguedadMeses14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses14,
                delEscnario8antiguedadMeses15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadMeses15,

                // SEGUNDA ANTIGUEDAD MAYOR IGUAL(PENA)
                // ESCENARIO 0
                delEscnario0antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena1,
                delEscnario0antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena2,
                delEscnario0antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena3,
                delEscnario0antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena4,
                delEscnario0antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena5,
                delEscnario0antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena6,
                delEscnario0antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena7,
                delEscnario0antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena8,
                delEscnario0antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena9,
                delEscnario0antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena10,
                delEscnario0antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena11,
                delEscnario0antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena12,
                delEscnario0antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena13,
                delEscnario0antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena14,
                delEscnario0antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0antiguedadPena15,
                // //ESCENARIO 1
                delEscnario1antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena1,
                delEscnario1antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena2,
                delEscnario1antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena3,
                delEscnario1antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena4,
                delEscnario1antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena5,
                delEscnario1antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena6,
                delEscnario1antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena7,
                delEscnario1antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena8,
                delEscnario1antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena9,
                delEscnario1antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena10,
                delEscnario1antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena11,
                delEscnario1antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena12,
                delEscnario1antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena13,
                delEscnario1antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena14,
                delEscnario1antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1antiguedadPena15,
                // //ESCENARIO 2
                delEscnario2antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena1,
                delEscnario2antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena2,
                delEscnario2antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena3,
                delEscnario2antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena4,
                delEscnario2antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena5,
                delEscnario2antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena6,
                delEscnario2antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena7,
                delEscnario2antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena8,
                delEscnario2antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena9,
                delEscnario2antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena10,
                delEscnario2antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena11,
                delEscnario2antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena12,
                delEscnario2antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena13,
                delEscnario2antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena14,
                delEscnario2antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2antiguedadPena15,
                // //ESCENARIO 3
                delEscnario3antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena1,
                delEscnario3antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena2,
                delEscnario3antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena3,
                delEscnario3antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena4,
                delEscnario3antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena5,
                delEscnario3antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena6,
                delEscnario3antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena7,
                delEscnario3antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena8,
                delEscnario3antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena9,
                delEscnario3antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena10,
                delEscnario3antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena11,
                delEscnario3antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena12,
                delEscnario3antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena13,
                delEscnario3antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena14,
                delEscnario3antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3antiguedadPena15,
                // //ESCENARIO 4
                delEscnario4antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena1,
                delEscnario4antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena2,
                delEscnario4antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena3,
                delEscnario4antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena4,
                delEscnario4antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena5,
                delEscnario4antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena6,
                delEscnario4antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena7,
                delEscnario4antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena8,
                delEscnario4antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena9,
                delEscnario4antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena10,
                delEscnario4antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena11,
                delEscnario4antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena12,
                delEscnario4antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena13,
                delEscnario4antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena14,
                delEscnario4antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4antiguedadPena15,
                // //ESCENARIO 5
                delEscnario5antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena1,
                delEscnario5antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena2,
                delEscnario5antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena3,
                delEscnario5antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena4,
                delEscnario5antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena5,
                delEscnario5antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena6,
                delEscnario5antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena7,
                delEscnario5antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena8,
                delEscnario5antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena9,
                delEscnario5antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena10,
                delEscnario5antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena11,
                delEscnario5antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena12,
                delEscnario5antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena13,
                delEscnario5antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena14,
                delEscnario5antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5antiguedadPena15,
                // //ESCENARIO 6
                delEscnario6antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena1,
                delEscnario6antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena2,
                delEscnario6antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena3,
                delEscnario6antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena4,
                delEscnario6antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena5,
                delEscnario6antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena6,
                delEscnario6antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena7,
                delEscnario6antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena8,
                delEscnario6antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena9,
                delEscnario6antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena10,
                delEscnario6antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena11,
                delEscnario6antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena12,
                delEscnario6antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena13,
                delEscnario6antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena14,
                delEscnario6antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6antiguedadPena15,
                // //ESCENARIO 7
                delEscnario7antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena1,
                delEscnario7antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena2,
                delEscnario7antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena3,
                delEscnario7antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena4,
                delEscnario7antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena5,
                delEscnario7antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena6,
                delEscnario7antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena7,
                delEscnario7antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena8,
                delEscnario7antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena9,
                delEscnario7antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena10,
                delEscnario7antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena11,
                delEscnario7antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena12,
                delEscnario7antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena13,
                delEscnario7antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena14,
                delEscnario7antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7antiguedadPena15,
                // //ESCENARIO 8
                delEscnario8antiguedadPena1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena1,
                delEscnario8antiguedadPena2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena2,
                delEscnario8antiguedadPena3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena3,
                delEscnario8antiguedadPena4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena4,
                delEscnario8antiguedadPena5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena5,
                delEscnario8antiguedadPena6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena6,
                delEscnario8antiguedadPena7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena7,
                delEscnario8antiguedadPena8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena8,
                delEscnario8antiguedadPena9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena9,
                delEscnario8antiguedadPena10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena10,
                delEscnario8antiguedadPena11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena11,
                delEscnario8antiguedadPena12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena12,
                delEscnario8antiguedadPena13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena13,
                delEscnario8antiguedadPena14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena14,
                delEscnario8antiguedadPena15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8antiguedadPena15,

                //ENDEUDAMIENTO PORCETAJE LIMITE
                // ESCENARIO 0
                delEscnario0porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento1,
                delEscnario0porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento2,
                delEscnario0porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento3,
                delEscnario0porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento4,
                delEscnario0porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento5,
                delEscnario0porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento6,
                delEscnario0porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento7,
                delEscnario0porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento8,
                delEscnario0porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento9,
                delEscnario0porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento10,
                delEscnario0porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento11,
                delEscnario0porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento12,
                delEscnario0porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento13,
                delEscnario0porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento14,
                delEscnario0porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento15,
                //ESCENARIO 1
                delEscnario1porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento1,
                delEscnario1porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento2,
                delEscnario1porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento3,
                delEscnario1porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento4,
                delEscnario1porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento5,
                delEscnario1porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento6,
                delEscnario1porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento7,
                delEscnario1porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento8,
                delEscnario1porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento9,
                delEscnario1porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento10,
                delEscnario1porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento11,
                delEscnario1porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento12,
                delEscnario1porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento13,
                delEscnario1porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1porcentajeEndeudamiento14,
                delEscnario1porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0porcentajeEndeudamiento15,
                //ESCENARIO 2
                delEscnario2porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento1,
                delEscnario2porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento2,
                delEscnario2porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento3,
                delEscnario2porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento4,
                delEscnario2porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento5,
                delEscnario2porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento6,
                delEscnario2porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento7,
                delEscnario2porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento8,
                delEscnario2porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento9,
                delEscnario2porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento10,
                delEscnario2porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento11,
                delEscnario2porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento12,
                delEscnario2porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento13,
                delEscnario2porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento14,
                delEscnario2porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2porcentajeEndeudamiento15,
                //ESCENARIO 3
                delEscnario3porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento1,
                delEscnario3porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento2,
                delEscnario3porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento3,
                delEscnario3porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento4,
                delEscnario3porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento5,
                delEscnario3porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento6,
                delEscnario3porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento7,
                delEscnario3porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento8,
                delEscnario3porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento9,
                delEscnario3porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento10,
                delEscnario3porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento11,
                delEscnario3porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento12,
                delEscnario3porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento13,
                delEscnario3porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento14,
                delEscnario3porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3porcentajeEndeudamiento15,
                //ESCENARIO 4
                delEscnario4porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento1,
                delEscnario4porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento2,
                delEscnario4porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento3,
                delEscnario4porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento4,
                delEscnario4porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento5,
                delEscnario4porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento6,
                delEscnario4porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento7,
                delEscnario4porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento8,
                delEscnario4porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento9,
                delEscnario4porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento10,
                delEscnario4porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento11,
                delEscnario4porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento12,
                delEscnario4porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento13,
                delEscnario4porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento14,
                delEscnario4porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4porcentajeEndeudamiento15,
                //ESCENARIO 5
                delEscnario5porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento1,
                delEscnario5porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento2,
                delEscnario5porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento3,
                delEscnario5porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento4,
                delEscnario5porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento5,
                delEscnario5porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento6,
                delEscnario5porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento7,
                delEscnario5porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento8,
                delEscnario5porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento9,
                delEscnario5porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento10,
                delEscnario5porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento11,
                delEscnario5porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento12,
                delEscnario5porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento13,
                delEscnario5porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento14,
                delEscnario5porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5porcentajeEndeudamiento15,
                //ESCENARIO 6
                delEscnario6porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento1,
                delEscnario6porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento2,
                delEscnario6porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento3,
                delEscnario6porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento4,
                delEscnario6porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento5,
                delEscnario6porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento6,
                delEscnario6porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento7,
                delEscnario6porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento8,
                delEscnario6porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento9,
                delEscnario6porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento10,
                delEscnario6porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento11,
                delEscnario6porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento12,
                delEscnario6porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento13,
                delEscnario6porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento14,
                delEscnario6porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6porcentajeEndeudamiento15,
                //ESCENARIO 7
                delEscnario7porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento1,
                delEscnario7porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento2,
                delEscnario7porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento3,
                delEscnario7porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento4,
                delEscnario7porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento5,
                delEscnario7porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento6,
                delEscnario7porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento7,
                delEscnario7porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento8,
                delEscnario7porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento9,
                delEscnario7porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento10,
                delEscnario7porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento11,
                delEscnario7porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento12,
                delEscnario7porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento13,
                delEscnario7porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento14,
                delEscnario7porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7porcentajeEndeudamiento15,
                //ESCENARIO 8
                delEscnario8porcentajeEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento1,
                delEscnario8porcentajeEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento2,
                delEscnario8porcentajeEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento3,
                delEscnario8porcentajeEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento4,
                delEscnario8porcentajeEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento5,
                delEscnario8porcentajeEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento6,
                delEscnario8porcentajeEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento7,
                delEscnario8porcentajeEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento8,
                delEscnario8porcentajeEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento9,
                delEscnario8porcentajeEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento10,
                delEscnario8porcentajeEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento11,
                delEscnario8porcentajeEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento12,
                delEscnario8porcentajeEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento13,
                delEscnario8porcentajeEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento14,
                delEscnario8porcentajeEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8porcentajeEndeudamiento15,

                // //ENDEUDAMIENTO PORCENTAJE PENA
                //ESCENARIO 0
                delEscnario0penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento1,
                delEscnario0penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento2,
                delEscnario0penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento3,
                delEscnario0penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento4,
                delEscnario0penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento5,
                delEscnario0penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento6,
                delEscnario0penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento7,
                delEscnario0penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento8,
                delEscnario0penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento9,
                delEscnario0penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento10,
                delEscnario0penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento11,
                delEscnario0penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento12,
                delEscnario0penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento13,
                delEscnario0penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento14,
                delEscnario0penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento15,
                //ESCENARIO 1
                delEscnario1penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento1,
                delEscnario1penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento2,
                delEscnario1penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento3,
                delEscnario1penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento4,
                delEscnario1penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento5,
                delEscnario1penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento6,
                delEscnario1penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento7,
                delEscnario1penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento8,
                delEscnario1penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento9,
                delEscnario1penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento10,
                delEscnario1penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento11,
                delEscnario1penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento12,
                delEscnario1penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento13,
                delEscnario1penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento14,
                delEscnario1penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario1penaEndeudamiento15,
                //ESCENARIO 2
                delEscnario2penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento1,
                delEscnario2penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento2,
                delEscnario2penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento3,
                delEscnario2penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento4,
                delEscnario2penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento5,
                delEscnario2penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento6,
                delEscnario2penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento7,
                delEscnario2penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento8,
                delEscnario2penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento9,
                delEscnario2penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento10,
                delEscnario2penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento11,
                delEscnario2penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento12,
                delEscnario2penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento13,
                delEscnario2penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento14,
                delEscnario2penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario2penaEndeudamiento15,
                //ESCENARIO 3
                delEscnario3penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento1,
                delEscnario3penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento2,
                delEscnario3penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento3,
                delEscnario3penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento4,
                delEscnario3penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento5,
                delEscnario3penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento6,
                delEscnario3penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento7,
                delEscnario3penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento8,
                delEscnario3penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento9,
                delEscnario3penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento10,
                delEscnario3penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento11,
                delEscnario3penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento12,
                delEscnario3penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento13,
                delEscnario3penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario3penaEndeudamiento14,
                delEscnario3penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario0penaEndeudamiento15,
                //ESCENARIO 4
                delEscnario4penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento1,
                delEscnario4penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento2,
                delEscnario4penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento3,
                delEscnario4penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento4,
                delEscnario4penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento5,
                delEscnario4penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento6,
                delEscnario4penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento7,
                delEscnario4penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento8,
                delEscnario4penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento9,
                delEscnario4penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento10,
                delEscnario4penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento11,
                delEscnario4penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento12,
                delEscnario4penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento13,
                delEscnario4penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento14,
                delEscnario4penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario4penaEndeudamiento15,
                //ESCENARIO 5
                delEscnario5penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento1,
                delEscnario5penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento2,
                delEscnario5penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento3,
                delEscnario5penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento4,
                delEscnario5penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento5,
                delEscnario5penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento6,
                delEscnario5penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento7,
                delEscnario5penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento8,
                delEscnario5penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento9,
                delEscnario5penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento10,
                delEscnario5penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento11,
                delEscnario5penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento12,
                delEscnario5penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento13,
                delEscnario5penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento14,
                delEscnario5penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario5penaEndeudamiento15,
                //ESCENARIO 6
                delEscnario6penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento1,
                delEscnario6penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento2,
                delEscnario6penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento3,
                delEscnario6penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento4,
                delEscnario6penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento5,
                delEscnario6penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento6,
                delEscnario6penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento7,
                delEscnario6penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento8,
                delEscnario6penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento9,
                delEscnario6penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento10,
                delEscnario6penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento11,
                delEscnario6penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento12,
                delEscnario6penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento13,
                delEscnario6penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento14,
                delEscnario6penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario6penaEndeudamiento15,
                //ESCENARIO 7
                delEscnario7penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento1,
                delEscnario7penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento2,
                delEscnario7penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento3,
                delEscnario7penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento4,
                delEscnario7penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento5,
                delEscnario7penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento6,
                delEscnario7penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento7,
                delEscnario7penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento8,
                delEscnario7penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento9,
                delEscnario7penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento10,
                delEscnario7penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento11,
                delEscnario7penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento12,
                delEscnario7penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento13,
                delEscnario7penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento14,
                delEscnario7penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario7penaEndeudamiento15,
                //ESCENARIO 8
                delEscnario8penaEndeudamiento1:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento1,
                delEscnario8penaEndeudamiento2:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento2,
                delEscnario8penaEndeudamiento3:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento3,
                delEscnario8penaEndeudamiento4:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento4,
                delEscnario8penaEndeudamiento5:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento5,
                delEscnario8penaEndeudamiento6:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento6,
                delEscnario8penaEndeudamiento7:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento7,
                delEscnario8penaEndeudamiento8:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento8,
                delEscnario8penaEndeudamiento9:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento9,
                delEscnario8penaEndeudamiento10:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento10,
                delEscnario8penaEndeudamiento11:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento11,
                delEscnario8penaEndeudamiento12:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento12,
                delEscnario8penaEndeudamiento13:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento13,
                delEscnario8penaEndeudamiento14:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento14,
                delEscnario8penaEndeudamiento15:
                  docDos.data().datosNuevaCalculadora
                    .delEscnario8penaEndeudamiento15,
              };
              calculadoras.push(calculadora);
            }
          }
        });

        setSetearCalculadoras(calculadoras);

        // let h = 1
        setEstadoButton(false);
        setCargando(0);
        setFlag(false);
        // console.log(calculadoras[0].ids[h])N
      }
      calc();
    }
  }, [date, flag, flagDos, idsVarios, navigate]);

  async function empezarCuestionario(calculadora) {
    console.log(calculadora);
    setSetearDatosCalculadora({
      bonificacion: calculadora.bonificacion,
      mostrarHipotecaFija:calculadora.mostrarHipotecaFija,
      mostrarHipotecaVariable:calculadora.mostrarHipotecaVariable,
      mostrarHipotecaMixta:calculadora.mostrarHipotecaMixta,
      primerPeriodoVariable: calculadora.primerPeriodoVariable,
      primerPeriodoMixto: calculadora.primerPeriodoMixto,
      hipotecaMinima: calculadora.hipotecaMinima,
      hipotecaMaxima: calculadora.hipotecaMaxima,
      nombre: calculadora.nombre,
      nombreInmobiliaria: calculadora.nombreInmobiliaria,
      porcentajeDelimitante: calculadora.porcentajeDelimitante,
      honorariosMinimoInferior: calculadora.honorariosMinimoInferior,
      honorariosMaximoInferior: calculadora.honorariosMaximoInferior,
      comisionMinima: calculadora.comisionMinima,
      comisionPrimeraIncremnto: calculadora.comisionPrimeraIncremnto,
      comisionExtraIncremento: calculadora.comisionExtraIncremento,
      uid: calculadora.uid,
      id: calculadora.id,
      mostrarComision: calculadora.mostrarComision,
      incuirEdadtitualMenor: calculadora.incuirEdadtitualMenor,
      maximoSobreTasacion: calculadora.maximoSobreTasacion,
      productoHipotecario: calculadora.productoHipotecario,
      tinPrimerosMesesVariable: calculadora.tinPrimerosMesesVariable,
      tinPrimerosMesesMixto: calculadora.tinPrimerosMesesMixto,
      plazoFijoMinimo: calculadora.plazoFijoMinimo,
      plazoFijoMaximo: calculadora.plazoFijoMaximo,
      plazoVariableMinimo: calculadora.plazoVariableMinimo,
      plazoVariableMaximo: calculadora.plazoVariableMaximo,
      plazoMixtoMinimo: calculadora.plazoMixtoMinimo,
      plazoMixtoMaximo: calculadora.plazoMixtoMaximo,
      edadMaximaTitular: calculadora.edadMaximaTitular,
      edadMaximaTitularMenor: calculadora.edadMaximaTitularMenor,
      hastaDiezFijo: calculadora.hastaDiezFijo,
      hastaQuinceFijo: calculadora.hastaQuinceFijo,
      hastaVeinteFijo: calculadora.hastaVeinteFijo,
      hastaVeinteCincoFijo: calculadora.hastaVeinteCincoFijo,
      hastaTreintaFijo: calculadora.hastaTreintaFijo,
      hastaTreintaCincoFijo: calculadora.hastaTreintaCincoFijo,
      hastaCuarentaFijo: calculadora.hastaCuarentaFijo,
      hastaCuarentaCincoFijo: calculadora.hastaCuarentaCincoFijo,
      hastaCincuentFijo: calculadora.hastaCincuentFijo,
      //interes variable
      hastaDiezVariable: calculadora.hastaDiezVariable,
      hastaQuinceVariable: calculadora.hastaQuinceVariable,
      hastaVeinteVariable: calculadora.hastaVeinteVariable,
      hastaVeinteCincoVariable: calculadora.hastaVeinteCincoVariable,
      hastaTreintaVariable: calculadora.hastaTreintaVariable,
      hastaTreintaCincoVariable: calculadora.hastaTreintaCincoVariable,
      hastaCuarentaVariable: calculadora.hastaCuarentaVariable,
      hastaCuarentaCincoVariable: calculadora.hastaCuarentaCincoVariable,
      hastaCincuentVariable: calculadora.hastaCincuentVariable,
      //interes mixto
      hastaDiezMixto: calculadora.hastaDiezMixto,
      hastaQuinceMixto: calculadora.hastaQuinceMixto,
      hastaVeinteMixto: calculadora.hastaVeinteMixto,
      hastaVeinteCincoMixto: calculadora.hastaVeinteCincoMixto,
      hastaTreintaMixto: calculadora.hastaTreintaMixto,
      hastaTreintaCincoMixto: calculadora.hastaTreintaCincoMixto,
      hastaCuarentaMixto: calculadora.hastaCuarentaMixto,
      hastaCuarentaCincoMixto: calculadora.hastaCuarentaCincoMixto,
      hastaCincuentMixto: calculadora.hastaCincuentMixto,
      //DOBLE GARANTIA
      delEscnario0DobleGarantia1: calculadora.delEscnario0DobleGarantia1,
      delEscnario0DobleGarantia2: calculadora.delEscnario0DobleGarantia2,
      delEscnario0DobleGarantia3: calculadora.delEscnario0DobleGarantia3,
      delEscnario0DobleGarantia4: calculadora.delEscnario0DobleGarantia4,
      delEscnario0DobleGarantia5: calculadora.delEscnario0DobleGarantia5,
      delEscnario0DobleGarantia6: calculadora.delEscnario0DobleGarantia6,
      delEscnario0DobleGarantia7: calculadora.delEscnario0DobleGarantia7,
      delEscnario0DobleGarantia8: calculadora.delEscnario0DobleGarantia8,
      delEscnario0DobleGarantia9: calculadora.delEscnario0DobleGarantia9,
      delEscnario0DobleGarantia10: calculadora.delEscnario0DobleGarantia10,
      delEscnario0DobleGarantia11: calculadora.delEscnario0DobleGarantia11,
      delEscnario0DobleGarantia12: calculadora.delEscnario0DobleGarantia12,
      //1
      delEscnario1DobleGarantia1: calculadora.delEscnario1DobleGarantia1,
      delEscnario1DobleGarantia2: calculadora.delEscnario1DobleGarantia2,
      delEscnario1DobleGarantia3: calculadora.delEscnario1DobleGarantia3,
      delEscnario1DobleGarantia4: calculadora.delEscnario1DobleGarantia4,
      delEscnario1DobleGarantia5: calculadora.delEscnario1DobleGarantia5,
      delEscnario1DobleGarantia6: calculadora.delEscnario1DobleGarantia6,
      delEscnario1DobleGarantia7: calculadora.delEscnario1DobleGarantia7,
      delEscnario1DobleGarantia8: calculadora.delEscnario1DobleGarantia8,
      delEscnario1DobleGarantia9: calculadora.delEscnario1DobleGarantia9,
      delEscnario1DobleGarantia10: calculadora.delEscnario1DobleGarantia10,
      delEscnario1DobleGarantia11: calculadora.delEscnario1DobleGarantia11,
      delEscnario1DobleGarantia12: calculadora.delEscnario1DobleGarantia12,
      //2
      delEscnario2DobleGarantia1: calculadora.delEscnario2DobleGarantia1,
      delEscnario2DobleGarantia2: calculadora.delEscnario2DobleGarantia2,
      delEscnario2DobleGarantia3: calculadora.delEscnario2DobleGarantia3,
      delEscnario2DobleGarantia4: calculadora.delEscnario2DobleGarantia4,
      delEscnario2DobleGarantia5: calculadora.delEscnario2DobleGarantia5,
      delEscnario2DobleGarantia6: calculadora.delEscnario2DobleGarantia6,
      delEscnario2DobleGarantia7: calculadora.delEscnario2DobleGarantia7,
      delEscnario2DobleGarantia8: calculadora.delEscnario2DobleGarantia8,
      delEscnario2DobleGarantia9: calculadora.delEscnario2DobleGarantia9,
      delEscnario2DobleGarantia10: calculadora.delEscnario2DobleGarantia10,
      delEscnario2DobleGarantia11: calculadora.delEscnario2DobleGarantia11,
      delEscnario2DobleGarantia12: calculadora.delEscnario2DobleGarantia12,
      //3
      delEscnario3DobleGarantia1: calculadora.delEscnario3DobleGarantia1,
      delEscnario3DobleGarantia2: calculadora.delEscnario3DobleGarantia2,
      delEscnario3DobleGarantia3: calculadora.delEscnario3DobleGarantia3,
      delEscnario3DobleGarantia4: calculadora.delEscnario3DobleGarantia4,
      delEscnario3DobleGarantia5: calculadora.delEscnario3DobleGarantia5,
      delEscnario3DobleGarantia6: calculadora.delEscnario3DobleGarantia6,
      delEscnario3DobleGarantia7: calculadora.delEscnario3DobleGarantia7,
      delEscnario3DobleGarantia8: calculadora.delEscnario3DobleGarantia8,
      delEscnario3DobleGarantia9: calculadora.delEscnario3DobleGarantia9,
      delEscnario3DobleGarantia10: calculadora.delEscnario3DobleGarantia10,
      delEscnario3DobleGarantia11: calculadora.delEscnario3DobleGarantia11,
      delEscnario3DobleGarantia12: calculadora.delEscnario3DobleGarantia12,
      //4
      delEscnario4DobleGarantia1: calculadora.delEscnario4DobleGarantia1,
      delEscnario4DobleGarantia2: calculadora.delEscnario4DobleGarantia2,
      delEscnario4DobleGarantia3: calculadora.delEscnario4DobleGarantia3,
      delEscnario4DobleGarantia4: calculadora.delEscnario4DobleGarantia4,
      delEscnario4DobleGarantia5: calculadora.delEscnario4DobleGarantia5,
      delEscnario4DobleGarantia6: calculadora.delEscnario4DobleGarantia6,
      delEscnario4DobleGarantia7: calculadora.delEscnario4DobleGarantia7,
      delEscnario4DobleGarantia8: calculadora.delEscnario4DobleGarantia8,
      delEscnario4DobleGarantia9: calculadora.delEscnario4DobleGarantia9,
      delEscnario4DobleGarantia10: calculadora.delEscnario4DobleGarantia10,
      delEscnario4DobleGarantia11: calculadora.delEscnario4DobleGarantia11,
      delEscnario4DobleGarantia12: calculadora.delEscnario4DobleGarantia12,
      //5
      delEscnario5DobleGarantia1: calculadora.delEscnario5DobleGarantia1,
      delEscnario5DobleGarantia2: calculadora.delEscnario5DobleGarantia2,
      delEscnario5DobleGarantia3: calculadora.delEscnario5DobleGarantia3,
      delEscnario5DobleGarantia4: calculadora.delEscnario5DobleGarantia4,
      delEscnario5DobleGarantia5: calculadora.delEscnario5DobleGarantia5,
      delEscnario5DobleGarantia6: calculadora.delEscnario5DobleGarantia6,
      delEscnario5DobleGarantia7: calculadora.delEscnario5DobleGarantia7,
      delEscnario5DobleGarantia8: calculadora.delEscnario5DobleGarantia8,
      delEscnario5DobleGarantia9: calculadora.delEscnario5DobleGarantia9,
      delEscnario5DobleGarantia10: calculadora.delEscnario5DobleGarantia10,
      delEscnario5DobleGarantia11: calculadora.delEscnario5DobleGarantia11,
      delEscnario5DobleGarantia12: calculadora.delEscnario5DobleGarantia12,
      //6
      delEscnario6DobleGarantia1: calculadora.delEscnario6DobleGarantia1,
      delEscnario6DobleGarantia2: calculadora.delEscnario6DobleGarantia2,
      delEscnario6DobleGarantia3: calculadora.delEscnario6DobleGarantia3,
      delEscnario6DobleGarantia4: calculadora.delEscnario6DobleGarantia4,
      delEscnario6DobleGarantia5: calculadora.delEscnario6DobleGarantia5,
      delEscnario6DobleGarantia6: calculadora.delEscnario6DobleGarantia6,
      delEscnario6DobleGarantia7: calculadora.delEscnario6DobleGarantia7,
      delEscnario6DobleGarantia8: calculadora.delEscnario6DobleGarantia8,
      delEscnario6DobleGarantia9: calculadora.delEscnario6DobleGarantia9,
      delEscnario6DobleGarantia10: calculadora.delEscnario6DobleGarantia10,
      delEscnario6DobleGarantia11: calculadora.delEscnario6DobleGarantia11,
      delEscnario6DobleGarantia12: calculadora.delEscnario6DobleGarantia12,
      //7
      delEscnario7DobleGarantia1: calculadora.delEscnario7DobleGarantia1,
      delEscnario7DobleGarantia2: calculadora.delEscnario7DobleGarantia2,
      delEscnario7DobleGarantia3: calculadora.delEscnario7DobleGarantia3,
      delEscnario7DobleGarantia4: calculadora.delEscnario7DobleGarantia4,
      delEscnario7DobleGarantia5: calculadora.delEscnario7DobleGarantia5,
      delEscnario7DobleGarantia6: calculadora.delEscnario7DobleGarantia6,
      delEscnario7DobleGarantia7: calculadora.delEscnario7DobleGarantia7,
      delEscnario7DobleGarantia8: calculadora.delEscnario7DobleGarantia8,
      delEscnario7DobleGarantia9: calculadora.delEscnario7DobleGarantia9,
      delEscnario7DobleGarantia10: calculadora.delEscnario7DobleGarantia10,
      delEscnario7DobleGarantia11: calculadora.delEscnario7DobleGarantia11,
      delEscnario7DobleGarantia12: calculadora.delEscnario7DobleGarantia12,
      //8
      delEscnario8DobleGarantia1: calculadora.delEscnario8DobleGarantia1,
      delEscnario8DobleGarantia2: calculadora.delEscnario8DobleGarantia2,
      delEscnario8DobleGarantia3: calculadora.delEscnario8DobleGarantia3,
      delEscnario8DobleGarantia4: calculadora.delEscnario8DobleGarantia4,
      delEscnario8DobleGarantia5: calculadora.delEscnario8DobleGarantia5,
      delEscnario8DobleGarantia6: calculadora.delEscnario8DobleGarantia6,
      delEscnario8DobleGarantia7: calculadora.delEscnario8DobleGarantia7,
      delEscnario8DobleGarantia8: calculadora.delEscnario8DobleGarantia8,
      delEscnario8DobleGarantia9: calculadora.delEscnario8DobleGarantia9,
      delEscnario8DobleGarantia10: calculadora.delEscnario8DobleGarantia10,
      delEscnario8DobleGarantia11: calculadora.delEscnario8DobleGarantia11,
      delEscnario8DobleGarantia12: calculadora.delEscnario8DobleGarantia12,
      //MEJORAS
      delEscnario0Mejoras1: calculadora.delEscnario0Mejoras1,
      delEscnario0Mejoras2: calculadora.delEscnario0Mejoras2,
      delEscnario0Mejoras3: calculadora.delEscnario0Mejoras3,
      delEscnario0Mejoras4: calculadora.delEscnario0Mejoras4,
      delEscnario0Mejoras5: calculadora.delEscnario0Mejoras5,
      delEscnario0Mejoras6: calculadora.delEscnario0Mejoras6,
      delEscnario0Mejoras7: calculadora.delEscnario0Mejoras7,
      delEscnario0Mejoras8: calculadora.delEscnario0Mejoras8,
      delEscnario0Mejoras9: calculadora.delEscnario0Mejoras9,
      delEscnario0Mejoras10: calculadora.delEscnario0Mejoras10,
      delEscnario0Mejoras11: calculadora.delEscnario0Mejoras11,
      delEscnario0Mejoras12: calculadora.delEscnario0Mejoras12,
      delEscnario0Mejoras13: calculadora.delEscnario0Mejoras13,
      delEscnario0Mejoras14: calculadora.delEscnario0Mejoras14,
      delEscnario0Mejoras15: calculadora.delEscnario0Mejoras15,
      //1
      delEscnario1Mejoras1: calculadora.delEscnario1Mejoras1,
      delEscnario1Mejoras2: calculadora.delEscnario1Mejoras2,
      delEscnario1Mejoras3: calculadora.delEscnario1Mejoras3,
      delEscnario1Mejoras4: calculadora.delEscnario1Mejoras4,
      delEscnario1Mejoras5: calculadora.delEscnario1Mejoras5,
      delEscnario1Mejoras6: calculadora.delEscnario1Mejoras6,
      delEscnario1Mejoras7: calculadora.delEscnario1Mejoras7,
      delEscnario1Mejoras8: calculadora.delEscnario1Mejoras8,
      delEscnario1Mejoras9: calculadora.delEscnario1Mejoras9,
      delEscnario1Mejoras10: calculadora.delEscnario1Mejoras10,
      delEscnario1Mejoras11: calculadora.delEscnario1Mejoras11,
      delEscnario1Mejoras12: calculadora.delEscnario1Mejoras12,
      delEscnario1Mejoras13: calculadora.delEscnario1Mejoras13,
      delEscnario1Mejoras14: calculadora.delEscnario1Mejoras14,
      delEscnario1Mejoras15: calculadora.delEscnario1Mejoras15,
      //2
      delEscnario2Mejoras1: calculadora.delEscnario2Mejoras1,
      delEscnario2Mejoras2: calculadora.delEscnario2Mejoras2,
      delEscnario2Mejoras3: calculadora.delEscnario2Mejoras3,
      delEscnario2Mejoras4: calculadora.delEscnario2Mejoras4,
      delEscnario2Mejoras5: calculadora.delEscnario2Mejoras5,
      delEscnario2Mejoras6: calculadora.delEscnario2Mejoras6,
      delEscnario2Mejoras7: calculadora.delEscnario2Mejoras7,
      delEscnario2Mejoras8: calculadora.delEscnario2Mejoras8,
      delEscnario2Mejoras9: calculadora.delEscnario2Mejoras9,
      delEscnario2Mejoras10: calculadora.delEscnario2Mejoras10,
      delEscnario2Mejoras11: calculadora.delEscnario2Mejoras11,
      delEscnario2Mejoras12: calculadora.delEscnario2Mejoras12,
      delEscnario2Mejoras13: calculadora.delEscnario2Mejoras13,
      delEscnario2Mejoras14: calculadora.delEscnario2Mejoras14,
      delEscnario2Mejoras15: calculadora.delEscnario2Mejoras15,
      //3
      delEscnario3Mejoras1: calculadora.delEscnario3Mejoras1,
      delEscnario3Mejoras2: calculadora.delEscnario3Mejoras2,
      delEscnario3Mejoras3: calculadora.delEscnario3Mejoras3,
      delEscnario3Mejoras4: calculadora.delEscnario3Mejoras4,
      delEscnario3Mejoras5: calculadora.delEscnario3Mejoras5,
      delEscnario3Mejoras6: calculadora.delEscnario3Mejoras6,
      delEscnario3Mejoras7: calculadora.delEscnario3Mejoras7,
      delEscnario3Mejoras8: calculadora.delEscnario3Mejoras8,
      delEscnario3Mejoras9: calculadora.delEscnario3Mejoras9,
      delEscnario3Mejoras10: calculadora.delEscnario3Mejoras10,
      delEscnario3Mejoras11: calculadora.delEscnario3Mejoras11,
      delEscnario3Mejoras12: calculadora.delEscnario3Mejoras12,
      delEscnario3Mejoras13: calculadora.delEscnario3Mejoras13,
      delEscnario3Mejoras14: calculadora.delEscnario3Mejoras14,
      delEscnario3Mejoras15: calculadora.delEscnario0Mejoras15,
      //4
      delEscnario4Mejoras1: calculadora.delEscnario4Mejoras1,
      delEscnario4Mejoras2: calculadora.delEscnario4Mejoras2,
      delEscnario4Mejoras3: calculadora.delEscnario4Mejoras3,
      delEscnario4Mejoras4: calculadora.delEscnario4Mejoras4,
      delEscnario4Mejoras5: calculadora.delEscnario4Mejoras5,
      delEscnario4Mejoras6: calculadora.delEscnario4Mejoras6,
      delEscnario4Mejoras7: calculadora.delEscnario4Mejoras7,
      delEscnario4Mejoras8: calculadora.delEscnario4Mejoras8,
      delEscnario4Mejoras9: calculadora.delEscnario4Mejoras9,
      delEscnario4Mejoras10: calculadora.delEscnario4Mejoras10,
      delEscnario4Mejoras11: calculadora.delEscnario4Mejoras11,
      delEscnario4Mejoras12: calculadora.delEscnario4Mejoras12,
      delEscnario4Mejoras13: calculadora.delEscnario4Mejoras13,
      delEscnario4Mejoras14: calculadora.delEscnario4Mejoras14,
      delEscnario4Mejoras15: calculadora.delEscnario4Mejoras15,
      //5
      delEscnario5Mejoras1: calculadora.delEscnario5Mejoras1,
      delEscnario5Mejoras2: calculadora.delEscnario5Mejoras2,
      delEscnario5Mejoras3: calculadora.delEscnario5Mejoras3,
      delEscnario5Mejoras4: calculadora.delEscnario5Mejoras4,
      delEscnario5Mejoras5: calculadora.delEscnario5Mejoras5,
      delEscnario5Mejoras6: calculadora.delEscnario5Mejoras6,
      delEscnario5Mejoras7: calculadora.delEscnario5Mejoras7,
      delEscnario5Mejoras8: calculadora.delEscnario5Mejoras8,
      delEscnario5Mejoras9: calculadora.delEscnario5Mejoras9,
      delEscnario5Mejoras10: calculadora.delEscnario5Mejoras10,
      delEscnario5Mejoras11: calculadora.delEscnario5Mejoras11,
      delEscnario5Mejoras12: calculadora.delEscnario5Mejoras12,
      delEscnario5Mejoras13: calculadora.delEscnario5Mejoras13,
      delEscnario5Mejoras14: calculadora.delEscnario5Mejoras14,
      delEscnario5Mejoras15: calculadora.delEscnario5Mejoras15,
      //6
      delEscnario6Mejoras1: calculadora.delEscnario6Mejoras1,
      delEscnario6Mejoras2: calculadora.delEscnario6Mejoras2,
      delEscnario6Mejoras3: calculadora.delEscnario6Mejoras3,
      delEscnario6Mejoras4: calculadora.delEscnario6Mejoras4,
      delEscnario6Mejoras5: calculadora.delEscnario6Mejoras5,
      delEscnario6Mejoras6: calculadora.delEscnario6Mejoras6,
      delEscnario6Mejoras7: calculadora.delEscnario6Mejoras7,
      delEscnario6Mejoras8: calculadora.delEscnario6Mejoras8,
      delEscnario6Mejoras9: calculadora.delEscnario6Mejoras9,
      delEscnario6Mejoras10: calculadora.delEscnario6Mejoras10,
      delEscnario6Mejoras11: calculadora.delEscnario6Mejoras11,
      delEscnario6Mejoras12: calculadora.delEscnario6Mejoras12,
      delEscnario6Mejoras13: calculadora.delEscnario6Mejoras13,
      delEscnario6Mejoras14: calculadora.delEscnario6Mejoras14,
      delEscnario6Mejoras15: calculadora.delEscnario6Mejoras15,
      //7
      delEscnario7Mejoras1: calculadora.delEscnario7Mejoras1,
      delEscnario7Mejoras2: calculadora.delEscnario7Mejoras2,
      delEscnario7Mejoras3: calculadora.delEscnario7Mejoras3,
      delEscnario7Mejoras4: calculadora.delEscnario7Mejoras4,
      delEscnario7Mejoras5: calculadora.delEscnario7Mejoras5,
      delEscnario7Mejoras6: calculadora.delEscnario7Mejoras6,
      delEscnario7Mejoras7: calculadora.delEscnario7Mejoras7,
      delEscnario7Mejoras8: calculadora.delEscnario7Mejoras8,
      delEscnario7Mejoras9: calculadora.delEscnario7Mejoras9,
      delEscnario7Mejoras10: calculadora.delEscnario7Mejoras10,
      delEscnario7Mejoras11: calculadora.delEscnario7Mejoras11,
      delEscnario7Mejoras12: calculadora.delEscnario7Mejoras12,
      delEscnario7Mejoras13: calculadora.delEscnario7Mejoras13,
      delEscnario7Mejoras14: calculadora.delEscnario7Mejoras14,
      delEscnario7Mejoras15: calculadora.delEscnario7Mejoras15,
      //8
      delEscnario8Mejoras1: calculadora.delEscnario8Mejoras1,
      delEscnario8Mejoras2: calculadora.delEscnario8Mejoras2,
      delEscnario8Mejoras3: calculadora.delEscnario8Mejoras3,
      delEscnario8Mejoras4: calculadora.delEscnario8Mejoras4,
      delEscnario8Mejoras5: calculadora.delEscnario8Mejoras5,
      delEscnario8Mejoras6: calculadora.delEscnario8Mejoras6,
      delEscnario8Mejoras7: calculadora.delEscnario8Mejoras7,
      delEscnario8Mejoras8: calculadora.delEscnario8Mejoras8,
      delEscnario8Mejoras9: calculadora.delEscnario8Mejoras9,
      delEscnario8Mejoras10: calculadora.delEscnario8Mejoras10,
      delEscnario8Mejoras11: calculadora.delEscnario8Mejoras11,
      delEscnario8Mejoras12: calculadora.delEscnario8Mejoras12,
      delEscnario8Mejoras13: calculadora.delEscnario8Mejoras13,
      delEscnario8Mejoras14: calculadora.delEscnario8Mejoras14,
      delEscnario8Mejoras15: calculadora.delEscnario8Mejoras15,
      //LIMITE PORCENTAJE
      //0
      delEscnario0Limite1: calculadora.delEscnario0Limite1,
      delEscnario0Limite2: calculadora.delEscnario0Limite2,
      delEscnario0Limite3: calculadora.delEscnario0Limite3,
      delEscnario0Limite4: calculadora.delEscnario0Limite4,
      delEscnario0Limite5: calculadora.delEscnario0Limite5,
      delEscnario0Limite6: calculadora.delEscnario0Limite6,
      delEscnario0Limite7: calculadora.delEscnario0Limite7,
      delEscnario0Limite8: calculadora.delEscnario0Limite8,
      delEscnario0Limite9: calculadora.delEscnario0Limite9,
      delEscnario0Limite10: calculadora.delEscnario0Limite10,
      delEscnario0Limite11: calculadora.delEscnario0Limite11,
      delEscnario0Limite12: calculadora.delEscnario0Limite12,
      delEscnario0Limite13: calculadora.delEscnario0Limite13,
      delEscnario0Limite14: calculadora.delEscnario0Limite14,
      delEscnario0Limite15: calculadora.delEscnario0Limite15,
      //1
      delEscnario1Limite1: calculadora.delEscnario1Limite1,
      delEscnario1Limite2: calculadora.delEscnario1Limite2,
      delEscnario1Limite3: calculadora.delEscnario1Limite3,
      delEscnario1Limite4: calculadora.delEscnario1Limite4,
      delEscnario1Limite5: calculadora.delEscnario1Limite5,
      delEscnario1Limite6: calculadora.delEscnario1Limite6,
      delEscnario1Limite7: calculadora.delEscnario1Limite7,
      delEscnario1Limite8: calculadora.delEscnario1Limite8,
      delEscnario1Limite9: calculadora.delEscnario1Limite9,
      delEscnario1Limite10: calculadora.delEscnario1Limite10,
      delEscnario1Limite11: calculadora.delEscnario1Limite11,
      delEscnario1Limite12: calculadora.delEscnario1Limite12,
      delEscnario1Limite13: calculadora.delEscnario1Limite13,
      delEscnario1Limite14: calculadora.delEscnario1Limite14,
      delEscnario1Limite15: calculadora.delEscnario0Limite15,
      //2
      delEscnario2Limite1: calculadora.delEscnario2Limite1,
      delEscnario2Limite2: calculadora.delEscnario2Limite2,
      delEscnario2Limite3: calculadora.delEscnario2Limite3,
      delEscnario2Limite4: calculadora.delEscnario2Limite4,
      delEscnario2Limite5: calculadora.delEscnario2Limite5,
      delEscnario2Limite6: calculadora.delEscnario2Limite6,
      delEscnario2Limite7: calculadora.delEscnario2Limite7,
      delEscnario2Limite8: calculadora.delEscnario2Limite8,
      delEscnario2Limite9: calculadora.delEscnario2Limite9,
      delEscnario2Limite10: calculadora.delEscnario2Limite10,
      delEscnario2Limite11: calculadora.delEscnario2Limite11,
      delEscnario2Limite12: calculadora.delEscnario2Limite12,
      delEscnario2Limite13: calculadora.delEscnario2Limite13,
      delEscnario2Limite14: calculadora.delEscnario2Limite14,
      delEscnario2Limite15: calculadora.delEscnario2Limite15,
      //3
      delEscnario3Limite1: calculadora.delEscnario3Limite1,
      delEscnario3Limite2: calculadora.delEscnario3Limite2,
      delEscnario3Limite3: calculadora.delEscnario3Limite3,
      delEscnario3Limite4: calculadora.delEscnario3Limite4,
      delEscnario3Limite5: calculadora.delEscnario3Limite5,
      delEscnario3Limite6: calculadora.delEscnario3Limite6,
      delEscnario3Limite7: calculadora.delEscnario3Limite7,
      delEscnario3Limite8: calculadora.delEscnario3Limite8,
      delEscnario3Limite9: calculadora.delEscnario3Limite9,
      delEscnario3Limite10: calculadora.delEscnario3Limite10,
      delEscnario3Limite11: calculadora.delEscnario3Limite11,
      delEscnario3Limite12: calculadora.delEscnario3Limite12,
      delEscnario3Limite13: calculadora.delEscnario3Limite13,
      delEscnario3Limite14: calculadora.delEscnario3Limite14,
      delEscnario3Limite15: calculadora.delEscnario3Limite15,
      //4
      delEscnario4Limite1: calculadora.delEscnario4Limite1,
      delEscnario4Limite2: calculadora.delEscnario4Limite2,
      delEscnario4Limite3: calculadora.delEscnario4Limite3,
      delEscnario4Limite4: calculadora.delEscnario4Limite4,
      delEscnario4Limite5: calculadora.delEscnario4Limite5,
      delEscnario4Limite6: calculadora.delEscnario4Limite6,
      delEscnario4Limite7: calculadora.delEscnario4Limite7,
      delEscnario4Limite8: calculadora.delEscnario4Limite8,
      delEscnario4Limite9: calculadora.delEscnario4Limite9,
      delEscnario4Limite10: calculadora.delEscnario4Limite10,
      delEscnario4Limite11: calculadora.delEscnario4Limite11,
      delEscnario4Limite12: calculadora.delEscnario4Limite12,
      delEscnario4Limite13: calculadora.delEscnario4Limite13,
      delEscnario4Limite14: calculadora.delEscnario4Limite14,
      delEscnario4Limite15: calculadora.delEscnario4Limite15,
      //5
      delEscnario5Limite1: calculadora.delEscnario5Limite1,
      delEscnario5Limite2: calculadora.delEscnario5Limite2,
      delEscnario5Limite3: calculadora.delEscnario5Limite3,
      delEscnario5Limite4: calculadora.delEscnario5Limite4,
      delEscnario5Limite5: calculadora.delEscnario5Limite5,
      delEscnario5Limite6: calculadora.delEscnario5Limite6,
      delEscnario5Limite7: calculadora.delEscnario5Limite7,
      delEscnario5Limite8: calculadora.delEscnario5Limite8,
      delEscnario5Limite9: calculadora.delEscnario5Limite9,
      delEscnario5Limite10: calculadora.delEscnario5Limite10,
      delEscnario5Limite11: calculadora.delEscnario5Limite11,
      delEscnario5Limite12: calculadora.delEscnario5Limite12,
      delEscnario5Limite13: calculadora.delEscnario5Limite13,
      delEscnario5Limite14: calculadora.delEscnario5Limite14,
      delEscnario5Limite15: calculadora.delEscnario5Limite15,
      //6
      delEscnario6Limite1: calculadora.delEscnario6Limite1,
      delEscnario6Limite2: calculadora.delEscnario6Limite2,
      delEscnario6Limite3: calculadora.delEscnario6Limite3,
      delEscnario6Limite4: calculadora.delEscnario6Limite4,
      delEscnario6Limite5: calculadora.delEscnario6Limite5,
      delEscnario6Limite6: calculadora.delEscnario6Limite6,
      delEscnario6Limite7: calculadora.delEscnario6Limite7,
      delEscnario6Limite8: calculadora.delEscnario6Limite8,
      delEscnario6Limite9: calculadora.delEscnario6Limite9,
      delEscnario6Limite10: calculadora.delEscnario6Limite10,
      delEscnario6Limite11: calculadora.delEscnario6Limite11,
      delEscnario6Limite12: calculadora.delEscnario6Limite12,
      delEscnario6Limite13: calculadora.delEscnario6Limite13,
      delEscnario6Limite14: calculadora.delEscnario6Limite14,
      delEscnario6Limite15: calculadora.delEscnario6Limite15,
      //7
      delEscnario7Limite1: calculadora.delEscnario7Limite1,
      delEscnario7Limite2: calculadora.delEscnario7Limite2,
      delEscnario7Limite3: calculadora.delEscnario7Limite3,
      delEscnario7Limite4: calculadora.delEscnario7Limite4,
      delEscnario7Limite5: calculadora.delEscnario7Limite5,
      delEscnario7Limite6: calculadora.delEscnario7Limite6,
      delEscnario7Limite7: calculadora.delEscnario7Limite7,
      delEscnario7Limite8: calculadora.delEscnario7Limite8,
      delEscnario7Limite9: calculadora.delEscnario7Limite9,
      delEscnario7Limite10: calculadora.delEscnario7Limite10,
      delEscnario7Limite11: calculadora.delEscnario7Limite11,
      delEscnario7Limite12: calculadora.delEscnario7Limite12,
      delEscnario7Limite13: calculadora.delEscnario7Limite13,
      delEscnario7Limite14: calculadora.delEscnario7Limite14,
      delEscnario7Limite15: calculadora.delEscnario7Limite15,
      //8
      delEscnario8Limite1: calculadora.delEscnario8Limite1,
      delEscnario8Limite2: calculadora.delEscnario8Limite2,
      delEscnario8Limite3: calculadora.delEscnario8Limite3,
      delEscnario8Limite4: calculadora.delEscnario8Limite4,
      delEscnario8Limite5: calculadora.delEscnario8Limite5,
      delEscnario8Limite6: calculadora.delEscnario8Limite6,
      delEscnario8Limite7: calculadora.delEscnario8Limite7,
      delEscnario8Limite8: calculadora.delEscnario8Limite8,
      delEscnario8Limite9: calculadora.delEscnario8Limite9,
      delEscnario8Limite10: calculadora.delEscnario8Limite10,
      delEscnario8Limite11: calculadora.delEscnario8Limite11,
      delEscnario8Limite12: calculadora.delEscnario8Limite12,
      delEscnario8Limite13: calculadora.delEscnario8Limite13,
      delEscnario8Limite14: calculadora.delEscnario8Limite14,
      delEscnario8Limite15: calculadora.delEscnario8Limite15,

      //DESDE (DESDE) X NUMERO PORCENTAJE
      delEscnario0: calculadora.delEscnario0,
      delEscnario1: calculadora.delEscnario1,
      delEscnario2: calculadora.delEscnario2,
      delEscnario3: calculadora.delEscnario3,
      delEscnario4: calculadora.delEscnario4,
      delEscnario5: calculadora.delEscnario5,
      delEscnario6: calculadora.delEscnario6,
      delEscnario7: calculadora.delEscnario7,
      delEscnario8: calculadora.delEscnario8,
      //HASTA (AL) X NUMERO PORCENTAJE
      alEscnario0: calculadora.alEscnario0,
      alEscnario1: calculadora.alEscnario1,
      alEscnario2: calculadora.alEscnario2,
      alEscnario3: calculadora.alEscnario3,
      alEscnario4: calculadora.alEscnario4,
      alEscnario5: calculadora.alEscnario5,
      alEscnario6: calculadora.alEscnario6,
      alEscnario7: calculadora.alEscnario7,
      alEscnario8: calculadora.alEscnario8,

      // ANTIGUEDAD DE CONTRATO MENOR A (TIEMPO)
      // ESCENARIO 0
      delEscnario0meses1: calculadora.delEscnario0meses1,
      delEscnario0meses2: calculadora.delEscnario0meses2,
      delEscnario0meses3: calculadora.delEscnario0meses3,
      delEscnario0meses4: calculadora.delEscnario0meses4,
      delEscnario0meses5: calculadora.delEscnario0meses5,
      delEscnario0meses6: calculadora.delEscnario0meses6,
      delEscnario0meses7: calculadora.delEscnario0meses7,
      delEscnario0meses8: calculadora.delEscnario0meses8,
      delEscnario0meses9: calculadora.delEscnario0meses9,
      delEscnario0meses10: calculadora.delEscnario0meses10,
      delEscnario0meses11: calculadora.delEscnario0meses11,
      delEscnario0meses12: calculadora.delEscnario0meses12,
      delEscnario0meses13: calculadora.delEscnario0meses13,
      delEscnario0meses14: calculadora.delEscnario0meses14,
      delEscnario0meses15: calculadora.delEscnario0meses15,
      //ESCENARIO 1
      delEscnario1meses1: calculadora.delEscnario1meses1,
      delEscnario1meses2: calculadora.delEscnario1meses2,
      delEscnario1meses3: calculadora.delEscnario1meses3,
      delEscnario1meses4: calculadora.delEscnario1meses4,
      delEscnario1meses5: calculadora.delEscnario1meses5,
      delEscnario1meses6: calculadora.delEscnario1meses6,
      delEscnario1meses7: calculadora.delEscnario1meses7,
      delEscnario1meses8: calculadora.delEscnario1meses8,
      delEscnario1meses9: calculadora.delEscnario1meses9,
      delEscnario1meses10: calculadora.delEscnario1meses10,
      delEscnario1meses11: calculadora.delEscnario1meses11,
      delEscnario1meses12: calculadora.delEscnario1meses12,
      delEscnario1meses13: calculadora.delEscnario1meses13,
      delEscnario1meses14: calculadora.delEscnario1meses14,
      delEscnario1meses15: calculadora.delEscnario0meses15,
      //ESCENARIO 2
      delEscnario2meses1: calculadora.delEscnario2meses1,
      delEscnario2meses2: calculadora.delEscnario2meses2,
      delEscnario2meses3: calculadora.delEscnario2meses3,
      delEscnario2meses4: calculadora.delEscnario2meses4,
      delEscnario2meses5: calculadora.delEscnario2meses5,
      delEscnario2meses6: calculadora.delEscnario2meses6,
      delEscnario2meses7: calculadora.delEscnario2meses7,
      delEscnario2meses8: calculadora.delEscnario2meses8,
      delEscnario2meses9: calculadora.delEscnario2meses9,
      delEscnario2meses10: calculadora.delEscnario2meses10,
      delEscnario2meses11: calculadora.delEscnario2meses11,
      delEscnario2meses12: calculadora.delEscnario2meses12,
      delEscnario2meses13: calculadora.delEscnario2meses13,
      delEscnario2meses14: calculadora.delEscnario2meses14,
      delEscnario2meses15: calculadora.delEscnario2meses15,
      //ESCENARIO 3
      delEscnario3meses1: calculadora.delEscnario3meses1,
      delEscnario3meses2: calculadora.delEscnario3meses2,
      delEscnario3meses3: calculadora.delEscnario3meses3,
      delEscnario3meses4: calculadora.delEscnario3meses4,
      delEscnario3meses5: calculadora.delEscnario3meses5,
      delEscnario3meses6: calculadora.delEscnario3meses6,
      delEscnario3meses7: calculadora.delEscnario3meses7,
      delEscnario3meses8: calculadora.delEscnario3meses8,
      delEscnario3meses9: calculadora.delEscnario3meses9,
      delEscnario3meses10: calculadora.delEscnario3meses10,
      delEscnario3meses11: calculadora.delEscnario3meses11,
      delEscnario3meses12: calculadora.delEscnario3meses12,
      delEscnario3meses13: calculadora.delEscnario3meses13,
      delEscnario3meses14: calculadora.delEscnario3meses14,
      delEscnario3meses15: calculadora.delEscnario3meses15,
      //ESCENARIO 4
      delEscnario4meses1: calculadora.delEscnario4meses1,
      delEscnario4meses2: calculadora.delEscnario4meses2,
      delEscnario4meses3: calculadora.delEscnario4meses3,
      delEscnario4meses4: calculadora.delEscnario4meses4,
      delEscnario4meses5: calculadora.delEscnario4meses5,
      delEscnario4meses6: calculadora.delEscnario4meses6,
      delEscnario4meses7: calculadora.delEscnario4meses7,
      delEscnario4meses8: calculadora.delEscnario4meses8,
      delEscnario4meses9: calculadora.delEscnario4meses9,
      delEscnario4meses10: calculadora.delEscnario4meses10,
      delEscnario4meses11: calculadora.delEscnario4meses11,
      delEscnario4meses12: calculadora.delEscnario4meses12,
      delEscnario4meses13: calculadora.delEscnario4meses13,
      delEscnario4meses14: calculadora.delEscnario4meses14,
      delEscnario4meses15: calculadora.delEscnario4meses15,
      //ESCENARIO 5
      delEscnario5meses1: calculadora.delEscnario5meses1,
      delEscnario5meses2: calculadora.delEscnario5meses2,
      delEscnario5meses3: calculadora.delEscnario5meses3,
      delEscnario5meses4: calculadora.delEscnario5meses4,
      delEscnario5meses5: calculadora.delEscnario5meses5,
      delEscnario5meses6: calculadora.delEscnario5meses6,
      delEscnario5meses7: calculadora.delEscnario5meses7,
      delEscnario5meses8: calculadora.delEscnario5meses8,
      delEscnario5meses9: calculadora.delEscnario5meses9,
      delEscnario5meses10: calculadora.delEscnario5meses10,
      delEscnario5meses11: calculadora.delEscnario5meses11,
      delEscnario5meses12: calculadora.delEscnario5meses12,
      delEscnario5meses13: calculadora.delEscnario5meses13,
      delEscnario5meses14: calculadora.delEscnario5meses14,
      delEscnario5meses15: calculadora.delEscnario5meses15,
      //ESCENARIO 6
      delEscnario6meses1: calculadora.delEscnario6meses1,
      delEscnario6meses2: calculadora.delEscnario6meses2,
      delEscnario6meses3: calculadora.delEscnario6meses3,
      delEscnario6meses4: calculadora.delEscnario6meses4,
      delEscnario6meses5: calculadora.delEscnario6meses5,
      delEscnario6meses6: calculadora.delEscnario6meses6,
      delEscnario6meses7: calculadora.delEscnario6meses7,
      delEscnario6meses8: calculadora.delEscnario6meses8,
      delEscnario6meses9: calculadora.delEscnario6meses9,
      delEscnario6meses10: calculadora.delEscnario6meses10,
      delEscnario6meses11: calculadora.delEscnario6meses11,
      delEscnario6meses12: calculadora.delEscnario6meses12,
      delEscnario6meses13: calculadora.delEscnario6meses13,
      delEscnario6meses14: calculadora.delEscnario6meses14,
      delEscnario6meses15: calculadora.delEscnario6meses15,
      //ESCENARIO 7
      delEscnario7meses1: calculadora.delEscnario7meses1,
      delEscnario7meses2: calculadora.delEscnario7meses2,
      delEscnario7meses3: calculadora.delEscnario7meses3,
      delEscnario7meses4: calculadora.delEscnario7meses4,
      delEscnario7meses5: calculadora.delEscnario7meses5,
      delEscnario7meses6: calculadora.delEscnario7meses6,
      delEscnario7meses7: calculadora.delEscnario7meses7,
      delEscnario7meses8: calculadora.delEscnario7meses8,
      delEscnario7meses9: calculadora.delEscnario7meses9,
      delEscnario7meses10: calculadora.delEscnario7meses10,
      delEscnario7meses11: calculadora.delEscnario7meses11,
      delEscnario7meses12: calculadora.delEscnario7meses12,
      delEscnario7meses13: calculadora.delEscnario7meses13,
      delEscnario7meses14: calculadora.delEscnario7meses14,
      delEscnario7meses15: calculadora.delEscnario7meses15,
      //ESCENARIO 8
      delEscnario8meses1: calculadora.delEscnario8meses1,
      delEscnario8meses2: calculadora.delEscnario8meses2,
      delEscnario8meses3: calculadora.delEscnario8meses3,
      delEscnario8meses4: calculadora.delEscnario8meses4,
      delEscnario8meses5: calculadora.delEscnario8meses5,
      delEscnario8meses6: calculadora.delEscnario8meses6,
      delEscnario8meses7: calculadora.delEscnario8meses7,
      delEscnario8meses8: calculadora.delEscnario8meses8,
      delEscnario8meses9: calculadora.delEscnario8meses9,
      delEscnario8meses10: calculadora.delEscnario8meses10,
      delEscnario8meses11: calculadora.delEscnario8meses11,
      delEscnario8meses12: calculadora.delEscnario8meses12,
      delEscnario8meses13: calculadora.delEscnario8meses13,
      delEscnario8meses14: calculadora.delEscnario8meses14,
      delEscnario8meses15: calculadora.delEscnario8meses15,

      // ANTIGUEDAD DE CONTRATO MENOR A (PENA)
      // ESCENARIO 0
      delEscnario0pena1: calculadora.delEscnario0pena1,
      delEscnario0pena2: calculadora.delEscnario0pena2,
      delEscnario0pena3: calculadora.delEscnario0pena3,
      delEscnario0pena4: calculadora.delEscnario0pena4,
      delEscnario0pena5: calculadora.delEscnario0pena5,
      delEscnario0pena6: calculadora.delEscnario0pena6,
      delEscnario0pena7: calculadora.delEscnario0pena7,
      delEscnario0pena8: calculadora.delEscnario0pena8,
      delEscnario0pena9: calculadora.delEscnario0pena9,
      delEscnario0pena10: calculadora.delEscnario0pena10,
      delEscnario0pena11: calculadora.delEscnario0pena11,
      delEscnario0pena12: calculadora.delEscnario0pena12,
      delEscnario0pena13: calculadora.delEscnario0pena13,
      delEscnario0pena14: calculadora.delEscnario0pena14,
      delEscnario0pena15: calculadora.delEscnario0pena15,
      //ESCENARIO 1
      delEscnario1pena1: calculadora.delEscnario1pena1,
      delEscnario1pena2: calculadora.delEscnario1pena2,
      delEscnario1pena3: calculadora.delEscnario1pena3,
      delEscnario1pena4: calculadora.delEscnario1pena4,
      delEscnario1pena5: calculadora.delEscnario1pena5,
      delEscnario1pena6: calculadora.delEscnario1pena6,
      delEscnario1pena7: calculadora.delEscnario1pena7,
      delEscnario1pena8: calculadora.delEscnario1pena8,
      delEscnario1pena9: calculadora.delEscnario1pena9,
      delEscnario1pena10: calculadora.delEscnario1pena10,
      delEscnario1pena11: calculadora.delEscnario1pena11,
      delEscnario1pena12: calculadora.delEscnario1pena12,
      delEscnario1pena13: calculadora.delEscnario1pena13,
      delEscnario1pena14: calculadora.delEscnario1pena14,
      delEscnario1pena15: calculadora.delEscnario1pena15,
      //ESCENARIO 2
      delEscnario2pena1: calculadora.delEscnario2pena1,
      delEscnario2pena2: calculadora.delEscnario2pena2,
      delEscnario2pena3: calculadora.delEscnario2pena3,
      delEscnario2pena4: calculadora.delEscnario2pena4,
      delEscnario2pena5: calculadora.delEscnario2pena5,
      delEscnario2pena6: calculadora.delEscnario2pena6,
      delEscnario2pena7: calculadora.delEscnario2pena7,
      delEscnario2pena8: calculadora.delEscnario2pena8,
      delEscnario2pena9: calculadora.delEscnario2pena9,
      delEscnario2pena10: calculadora.delEscnario2pena10,
      delEscnario2pena11: calculadora.delEscnario2pena11,
      delEscnario2pena12: calculadora.delEscnario2pena12,
      delEscnario2pena13: calculadora.delEscnario2pena13,
      delEscnario2pena14: calculadora.delEscnario2pena14,
      delEscnario2pena15: calculadora.delEscnario2pena15,
      //ESCENARIO 3
      delEscnario3pena1: calculadora.delEscnario3pena1,
      delEscnario3pena2: calculadora.delEscnario3pena2,
      delEscnario3pena3: calculadora.delEscnario3pena3,
      delEscnario3pena4: calculadora.delEscnario3pena4,
      delEscnario3pena5: calculadora.delEscnario3pena5,
      delEscnario3pena6: calculadora.delEscnario3pena6,
      delEscnario3pena7: calculadora.delEscnario3pena7,
      delEscnario3pena8: calculadora.delEscnario3pena8,
      delEscnario3pena9: calculadora.delEscnario3pena9,
      delEscnario3pena10: calculadora.delEscnario3pena10,
      delEscnario3pena11: calculadora.delEscnario3pena11,
      delEscnario3pena12: calculadora.delEscnario3pena12,
      delEscnario3pena13: calculadora.delEscnario3pena13,
      delEscnario3pena14: calculadora.delEscnario3pena14,
      delEscnario3pena15: calculadora.delEscnario3pena15,
      //ESCENARIO 4
      delEscnario4pena1: calculadora.delEscnario4pena1,
      delEscnario4pena2: calculadora.delEscnario4pena2,
      delEscnario4pena3: calculadora.delEscnario4pena3,
      delEscnario4pena4: calculadora.delEscnario4pena4,
      delEscnario4pena5: calculadora.delEscnario4pena5,
      delEscnario4pena6: calculadora.delEscnario4pena6,
      delEscnario4pena7: calculadora.delEscnario4pena7,
      delEscnario4pena8: calculadora.delEscnario4pena8,
      delEscnario4pena9: calculadora.delEscnario4pena9,
      delEscnario4pena10: calculadora.delEscnario4pena10,
      delEscnario4pena11: calculadora.delEscnario4pena11,
      delEscnario4pena12: calculadora.delEscnario4pena12,
      delEscnario4pena13: calculadora.delEscnario4pena13,
      delEscnario4pena14: calculadora.delEscnario4pena14,
      delEscnario4pena15: calculadora.delEscnario4pena15,
      //ESCENARIO 5
      delEscnario5pena1: calculadora.delEscnario5pena1,
      delEscnario5pena2: calculadora.delEscnario5pena2,
      delEscnario5pena3: calculadora.delEscnario5pena3,
      delEscnario5pena4: calculadora.delEscnario5pena4,
      delEscnario5pena5: calculadora.delEscnario5pena5,
      delEscnario5pena6: calculadora.delEscnario5pena6,
      delEscnario5pena7: calculadora.delEscnario5pena7,
      delEscnario5pena8: calculadora.delEscnario5pena8,
      delEscnario5pena9: calculadora.delEscnario5pena9,
      delEscnario5pena10: calculadora.delEscnario5pena10,
      delEscnario5pena11: calculadora.delEscnario5pena11,
      delEscnario5pena12: calculadora.delEscnario5pena12,
      delEscnario5pena13: calculadora.delEscnario5pena13,
      delEscnario5pena14: calculadora.delEscnario5pena14,
      delEscnario5pena15: calculadora.delEscnario5pena15,
      //ESCENARIO 6
      delEscnario6pena1: calculadora.delEscnario6pena1,
      delEscnario6pena2: calculadora.delEscnario6pena2,
      delEscnario6pena3: calculadora.delEscnario6pena3,
      delEscnario6pena4: calculadora.delEscnario6pena4,
      delEscnario6pena5: calculadora.delEscnario6pena5,
      delEscnario6pena6: calculadora.delEscnario6pena6,
      delEscnario6pena7: calculadora.delEscnario6pena7,
      delEscnario6pena8: calculadora.delEscnario6pena8,
      delEscnario6pena9: calculadora.delEscnario6pena9,
      delEscnario6pena10: calculadora.delEscnario6pena10,
      delEscnario6pena11: calculadora.delEscnario6pena11,
      delEscnario6pena12: calculadora.delEscnario6pena12,
      delEscnario6pena13: calculadora.delEscnario6pena13,
      delEscnario6pena14: calculadora.delEscnario6pena14,
      delEscnario6pena15: calculadora.delEscnario6pena15,
      //ESCENARIO 7
      delEscnario7pena1: calculadora.delEscnario7pena1,
      delEscnario7pena2: calculadora.delEscnario7pena2,
      delEscnario7pena3: calculadora.delEscnario7pena3,
      delEscnario7pena4: calculadora.delEscnario7pena4,
      delEscnario7pena5: calculadora.delEscnario7pena5,
      delEscnario7pena6: calculadora.delEscnario7pena6,
      delEscnario7pena7: calculadora.delEscnario7pena7,
      delEscnario7pena8: calculadora.delEscnario7pena8,
      delEscnario7pena9: calculadora.delEscnario7pena9,
      delEscnario7pena10: calculadora.delEscnario7pena10,
      delEscnario7pena11: calculadora.delEscnario7pena11,
      delEscnario7pena12: calculadora.delEscnario7pena12,
      delEscnario7pena13: calculadora.delEscnario7pena13,
      delEscnario7pena14: calculadora.delEscnario7pena14,
      delEscnario7pena15: calculadora.delEscnario0pena15,
      //ESCENARIO 8
      delEscnario8pena1: calculadora.delEscnario8pena1,
      delEscnario8pena2: calculadora.delEscnario8pena2,
      delEscnario8pena3: calculadora.delEscnario8pena3,
      delEscnario8pena4: calculadora.delEscnario8pena4,
      delEscnario8pena5: calculadora.delEscnario8pena5,
      delEscnario8pena6: calculadora.delEscnario8pena6,
      delEscnario8pena7: calculadora.delEscnario8pena7,
      delEscnario8pena8: calculadora.delEscnario8pena8,
      delEscnario8pena9: calculadora.delEscnario8pena9,
      delEscnario8pena10: calculadora.delEscnario8pena10,
      delEscnario8pena11: calculadora.delEscnario8pena11,
      delEscnario8pena12: calculadora.delEscnario8pena12,
      delEscnario8pena13: calculadora.delEscnario8pena13,
      delEscnario8pena14: calculadora.delEscnario8pena14,
      delEscnario8pena15: calculadora.delEscnario8pena15,

      // SEGUNDA ANTIGUEDAD MAYOR IGUAL(TIEMPO)
      // ESCENARIO 0
      delEscnario0antiguedadMeses1: calculadora.delEscnario0antiguedadMeses1,
      delEscnario0antiguedadMeses2: calculadora.delEscnario0antiguedadMeses2,
      delEscnario0antiguedadMeses3: calculadora.delEscnario0antiguedadMeses3,
      delEscnario0antiguedadMeses4: calculadora.delEscnario0antiguedadMeses4,
      delEscnario0antiguedadMeses5: calculadora.delEscnario0antiguedadMeses5,
      delEscnario0antiguedadMeses6: calculadora.delEscnario0antiguedadMeses6,
      delEscnario0antiguedadMeses7: calculadora.delEscnario0antiguedadMeses7,
      delEscnario0antiguedadMeses8: calculadora.delEscnario0antiguedadMeses8,
      delEscnario0antiguedadMeses9: calculadora.delEscnario0antiguedadMeses9,
      delEscnario0antiguedadMeses10: calculadora.delEscnario0antiguedadMeses10,
      delEscnario0antiguedadMeses11: calculadora.delEscnario0antiguedadMeses11,
      delEscnario0antiguedadMeses12: calculadora.delEscnario0antiguedadMeses12,
      delEscnario0antiguedadMeses13: calculadora.delEscnario0antiguedadMeses13,
      delEscnario0antiguedadMeses14: calculadora.delEscnario0antiguedadMeses14,
      delEscnario0antiguedadMeses15: calculadora.delEscnario0antiguedadMeses15,
      // ESCENARIO 1
      delEscnario1antiguedadMeses1: calculadora.delEscnario1antiguedadMeses1,
      delEscnario1antiguedadMeses2: calculadora.delEscnario1antiguedadMeses2,
      delEscnario1antiguedadMeses3: calculadora.delEscnario1antiguedadMeses3,
      delEscnario1antiguedadMeses4: calculadora.delEscnario1antiguedadMeses4,
      delEscnario1antiguedadMeses5: calculadora.delEscnario1antiguedadMeses5,
      delEscnario1antiguedadMeses6: calculadora.delEscnario1antiguedadMeses6,
      delEscnario1antiguedadMeses7: calculadora.delEscnario1antiguedadMeses7,
      delEscnario1antiguedadMeses8: calculadora.delEscnario1antiguedadMeses8,
      delEscnario1antiguedadMeses9: calculadora.delEscnario1antiguedadMeses9,
      delEscnario1antiguedadMeses10: calculadora.delEscnario1antiguedadMeses10,
      delEscnario1antiguedadMeses11: calculadora.delEscnario1antiguedadMeses11,
      delEscnario1antiguedadMeses12: calculadora.delEscnario1antiguedadMeses12,
      delEscnario1antiguedadMeses13: calculadora.delEscnario1antiguedadMeses13,
      delEscnario1antiguedadMeses14: calculadora.delEscnario1antiguedadMeses14,
      delEscnario1antiguedadMeses15: calculadora.delEscnario1antiguedadMeses15,
      // ESCENARIO 2
      delEscnario2antiguedadMeses1: calculadora.delEscnario2antiguedadMeses1,
      delEscnario2antiguedadMeses2: calculadora.delEscnario2antiguedadMeses2,
      delEscnario2antiguedadMeses3: calculadora.delEscnario2antiguedadMeses3,
      delEscnario2antiguedadMeses4: calculadora.delEscnario2antiguedadMeses4,
      delEscnario2antiguedadMeses5: calculadora.delEscnario2antiguedadMeses5,
      delEscnario2antiguedadMeses6: calculadora.delEscnario2antiguedadMeses6,
      delEscnario2antiguedadMeses7: calculadora.delEscnario2antiguedadMeses7,
      delEscnario2antiguedadMeses8: calculadora.delEscnario2antiguedadMeses8,
      delEscnario2antiguedadMeses9: calculadora.delEscnario2antiguedadMeses9,
      delEscnario2antiguedadMeses10: calculadora.delEscnario2antiguedadMeses10,
      delEscnario2antiguedadMeses11: calculadora.delEscnario2antiguedadMeses11,
      delEscnario2antiguedadMeses12: calculadora.delEscnario2antiguedadMeses12,
      delEscnario2antiguedadMeses13: calculadora.delEscnario2antiguedadMeses13,
      delEscnario2antiguedadMeses14: calculadora.delEscnario2antiguedadMeses14,
      delEscnario2antiguedadMeses15: calculadora.delEscnario2antiguedadMeses15,
      // ESCENARIO 3
      delEscnario3antiguedadMeses1: calculadora.delEscnario3antiguedadMeses1,
      delEscnario3antiguedadMeses2: calculadora.delEscnario3antiguedadMeses2,
      delEscnario3antiguedadMeses3: calculadora.delEscnario3antiguedadMeses3,
      delEscnario3antiguedadMeses4: calculadora.delEscnario3antiguedadMeses4,
      delEscnario3antiguedadMeses5: calculadora.delEscnario3antiguedadMeses5,
      delEscnario3antiguedadMeses6: calculadora.delEscnario3antiguedadMeses6,
      delEscnario3antiguedadMeses7: calculadora.delEscnario3antiguedadMeses7,
      delEscnario3antiguedadMeses8: calculadora.delEscnario3antiguedadMeses8,
      delEscnario3antiguedadMeses9: calculadora.delEscnario3antiguedadMeses9,
      delEscnario3antiguedadMeses10: calculadora.delEscnario3antiguedadMeses10,
      delEscnario3antiguedadMeses11: calculadora.delEscnario3antiguedadMeses11,
      delEscnario3antiguedadMeses12: calculadora.delEscnario3antiguedadMeses12,
      delEscnario3antiguedadMeses13: calculadora.delEscnario3antiguedadMeses13,
      delEscnario3antiguedadMeses14: calculadora.delEscnario3antiguedadMeses14,
      delEscnario3antiguedadMeses15: calculadora.delEscnario3antiguedadMeses15,
      // ESCENARIO 1
      // ESCENARIO 4
      delEscnario4antiguedadMeses1: calculadora.delEscnario4antiguedadMeses1,
      delEscnario4antiguedadMeses2: calculadora.delEscnario4antiguedadMeses2,
      delEscnario4antiguedadMeses3: calculadora.delEscnario4antiguedadMeses3,
      delEscnario4antiguedadMeses4: calculadora.delEscnario4antiguedadMeses4,
      delEscnario4antiguedadMeses5: calculadora.delEscnario4antiguedadMeses5,
      delEscnario4antiguedadMeses6: calculadora.delEscnario4antiguedadMeses6,
      delEscnario4antiguedadMeses7: calculadora.delEscnario4antiguedadMeses7,
      delEscnario4antiguedadMeses8: calculadora.delEscnario4antiguedadMeses8,
      delEscnario4antiguedadMeses9: calculadora.delEscnario4antiguedadMeses9,
      delEscnario4antiguedadMeses10: calculadora.delEscnario4antiguedadMeses10,
      delEscnario4antiguedadMeses11: calculadora.delEscnario4antiguedadMeses11,
      delEscnario4antiguedadMeses12: calculadora.delEscnario4antiguedadMeses12,
      delEscnario4antiguedadMeses13: calculadora.delEscnario4antiguedadMeses13,
      delEscnario4antiguedadMeses14: calculadora.delEscnario4antiguedadMeses14,
      delEscnario4antiguedadMeses15: calculadora.delEscnario4antiguedadMeses15,
      // ESCENARIO 1
      // ESCENARIO 5
      delEscnario5antiguedadMeses1: calculadora.delEscnario5antiguedadMeses1,
      delEscnario5antiguedadMeses2: calculadora.delEscnario5antiguedadMeses2,
      delEscnario5antiguedadMeses3: calculadora.delEscnario5antiguedadMeses3,
      delEscnario5antiguedadMeses4: calculadora.delEscnario5antiguedadMeses4,
      delEscnario5antiguedadMeses5: calculadora.delEscnario5antiguedadMeses5,
      delEscnario5antiguedadMeses6: calculadora.delEscnario5antiguedadMeses6,
      delEscnario5antiguedadMeses7: calculadora.delEscnario5antiguedadMeses7,
      delEscnario5antiguedadMeses8: calculadora.delEscnario5antiguedadMeses8,
      delEscnario5antiguedadMeses9: calculadora.delEscnario5antiguedadMeses9,
      delEscnario5antiguedadMeses10: calculadora.delEscnario5antiguedadMeses10,
      delEscnario5antiguedadMeses11: calculadora.delEscnario5antiguedadMeses11,
      delEscnario5antiguedadMeses12: calculadora.delEscnario5antiguedadMeses12,
      delEscnario5antiguedadMeses13: calculadora.delEscnario5antiguedadMeses13,
      delEscnario5antiguedadMeses14: calculadora.delEscnario5antiguedadMeses14,
      delEscnario5antiguedadMeses15: calculadora.delEscnario5antiguedadMeses15,
      // ESCENARIO 1
      // ESCENARIO 6
      delEscnario6antiguedadMeses1: calculadora.delEscnario6antiguedadMeses1,
      delEscnario6antiguedadMeses2: calculadora.delEscnario6antiguedadMeses2,
      delEscnario6antiguedadMeses3: calculadora.delEscnario6antiguedadMeses3,
      delEscnario6antiguedadMeses4: calculadora.delEscnario6antiguedadMeses4,
      delEscnario6antiguedadMeses5: calculadora.delEscnario6antiguedadMeses5,
      delEscnario6antiguedadMeses6: calculadora.delEscnario6antiguedadMeses6,
      delEscnario6antiguedadMeses7: calculadora.delEscnario6antiguedadMeses7,
      delEscnario6antiguedadMeses8: calculadora.delEscnario6antiguedadMeses8,
      delEscnario6antiguedadMeses9: calculadora.delEscnario6antiguedadMeses9,
      delEscnario6antiguedadMeses10: calculadora.delEscnario6antiguedadMeses10,
      delEscnario6antiguedadMeses11: calculadora.delEscnario6antiguedadMeses11,
      delEscnario6antiguedadMeses12: calculadora.delEscnario6antiguedadMeses12,
      delEscnario6antiguedadMeses13: calculadora.delEscnario6antiguedadMeses13,
      delEscnario6antiguedadMeses14: calculadora.delEscnario6antiguedadMeses14,
      delEscnario6antiguedadMeses15: calculadora.delEscnario6antiguedadMeses15,
      // ESCENARIO 1
      // ESCENARIO 7
      delEscnario7antiguedadMeses1: calculadora.delEscnario7antiguedadMeses1,
      delEscnario7antiguedadMeses2: calculadora.delEscnario7antiguedadMeses2,
      delEscnario7antiguedadMeses3: calculadora.delEscnario7antiguedadMeses3,
      delEscnario7antiguedadMeses4: calculadora.delEscnario7antiguedadMeses4,
      delEscnario7antiguedadMeses5: calculadora.delEscnario7antiguedadMeses5,
      delEscnario7antiguedadMeses6: calculadora.delEscnario7antiguedadMeses6,
      delEscnario7antiguedadMeses7: calculadora.delEscnario7antiguedadMeses7,
      delEscnario7antiguedadMeses8: calculadora.delEscnario7antiguedadMeses8,
      delEscnario7antiguedadMeses9: calculadora.delEscnario7antiguedadMeses9,
      delEscnario7antiguedadMeses10: calculadora.delEscnario7antiguedadMeses10,
      delEscnario7antiguedadMeses11: calculadora.delEscnario7antiguedadMeses11,
      delEscnario7antiguedadMeses12: calculadora.delEscnario7antiguedadMeses12,
      delEscnario7antiguedadMeses13: calculadora.delEscnario7antiguedadMeses13,
      delEscnario7antiguedadMeses14: calculadora.delEscnario7antiguedadMeses14,
      delEscnario7antiguedadMeses15: calculadora.delEscnario7antiguedadMeses15,
      // ESCENARIO 8
      delEscnario8antiguedadMeses1: calculadora.delEscnario8antiguedadMeses1,
      delEscnario8antiguedadMeses2: calculadora.delEscnario8antiguedadMeses2,
      delEscnario8antiguedadMeses3: calculadora.delEscnario8antiguedadMeses3,
      delEscnario8antiguedadMeses4: calculadora.delEscnario8antiguedadMeses4,
      delEscnario8antiguedadMeses5: calculadora.delEscnario8antiguedadMeses5,
      delEscnario8antiguedadMeses6: calculadora.delEscnario8antiguedadMeses6,
      delEscnario8antiguedadMeses7: calculadora.delEscnario8antiguedadMeses7,
      delEscnario8antiguedadMeses8: calculadora.delEscnario8antiguedadMeses8,
      delEscnario8antiguedadMeses9: calculadora.delEscnario8antiguedadMeses9,
      delEscnario8antiguedadMeses10: calculadora.delEscnario8antiguedadMeses10,
      delEscnario8antiguedadMeses11: calculadora.delEscnario8antiguedadMeses11,
      delEscnario8antiguedadMeses12: calculadora.delEscnario8antiguedadMeses12,
      delEscnario8antiguedadMeses13: calculadora.delEscnario8antiguedadMeses13,
      delEscnario8antiguedadMeses14: calculadora.delEscnario8antiguedadMeses14,
      delEscnario8antiguedadMeses15: calculadora.delEscnario8antiguedadMeses15,

      // SEGUNDA ANTIGUEDAD MAYOR IGUAL(PENA)
      // ESCENARIO 0
      delEscnario0antiguedadPena1: calculadora.delEscnario0antiguedadPena1,
      delEscnario0antiguedadPena2: calculadora.delEscnario0antiguedadPena2,
      delEscnario0antiguedadPena3: calculadora.delEscnario0antiguedadPena3,
      delEscnario0antiguedadPena4: calculadora.delEscnario0antiguedadPena4,
      delEscnario0antiguedadPena5: calculadora.delEscnario0antiguedadPena5,
      delEscnario0antiguedadPena6: calculadora.delEscnario0antiguedadPena6,
      delEscnario0antiguedadPena7: calculadora.delEscnario0antiguedadPena7,
      delEscnario0antiguedadPena8: calculadora.delEscnario0antiguedadPena8,
      delEscnario0antiguedadPena9: calculadora.delEscnario0antiguedadPena9,
      delEscnario0antiguedadPena10: calculadora.delEscnario0antiguedadPena10,
      delEscnario0antiguedadPena11: calculadora.delEscnario0antiguedadPena11,
      delEscnario0antiguedadPena12: calculadora.delEscnario0antiguedadPena12,
      delEscnario0antiguedadPena13: calculadora.delEscnario0antiguedadPena13,
      delEscnario0antiguedadPena14: calculadora.delEscnario0antiguedadPena14,
      delEscnario0antiguedadPena15: calculadora.delEscnario0antiguedadPena15,
      // //ESCENARIO 1
      delEscnario1antiguedadPena1: calculadora.delEscnario1antiguedadPena1,
      delEscnario1antiguedadPena2: calculadora.delEscnario1antiguedadPena2,
      delEscnario1antiguedadPena3: calculadora.delEscnario1antiguedadPena3,
      delEscnario1antiguedadPena4: calculadora.delEscnario1antiguedadPena4,
      delEscnario1antiguedadPena5: calculadora.delEscnario1antiguedadPena5,
      delEscnario1antiguedadPena6: calculadora.delEscnario1antiguedadPena6,
      delEscnario1antiguedadPena7: calculadora.delEscnario1antiguedadPena7,
      delEscnario1antiguedadPena8: calculadora.delEscnario1antiguedadPena8,
      delEscnario1antiguedadPena9: calculadora.delEscnario1antiguedadPena9,
      delEscnario1antiguedadPena10: calculadora.delEscnario1antiguedadPena10,
      delEscnario1antiguedadPena11: calculadora.delEscnario1antiguedadPena11,
      delEscnario1antiguedadPena12: calculadora.delEscnario1antiguedadPena12,
      delEscnario1antiguedadPena13: calculadora.delEscnario1antiguedadPena13,
      delEscnario1antiguedadPena14: calculadora.delEscnario1antiguedadPena14,
      delEscnario1antiguedadPena15: calculadora.delEscnario1antiguedadPena15,

      // //ESCENARIO 2
      delEscnario2antiguedadPena1: calculadora.delEscnario2antiguedadPena1,
      delEscnario2antiguedadPena2: calculadora.delEscnario2antiguedadPena2,
      delEscnario2antiguedadPena3: calculadora.delEscnario2antiguedadPena3,
      delEscnario2antiguedadPena4: calculadora.delEscnario2antiguedadPena4,
      delEscnario2antiguedadPena5: calculadora.delEscnario2antiguedadPena5,
      delEscnario2antiguedadPena6: calculadora.delEscnario2antiguedadPena6,
      delEscnario2antiguedadPena7: calculadora.delEscnario2antiguedadPena7,
      delEscnario2antiguedadPena8: calculadora.delEscnario2antiguedadPena8,
      delEscnario2antiguedadPena9: calculadora.delEscnario2antiguedadPena9,
      delEscnario2antiguedadPena10: calculadora.delEscnario2antiguedadPena10,
      delEscnario2antiguedadPena11: calculadora.delEscnario2antiguedadPena11,
      delEscnario2antiguedadPena12: calculadora.delEscnario2antiguedadPena12,
      delEscnario2antiguedadPena13: calculadora.delEscnario2antiguedadPena13,
      delEscnario2antiguedadPena14: calculadora.delEscnario2antiguedadPena14,
      delEscnario2antiguedadPena15: calculadora.delEscnario2antiguedadPena15,
      // //ESCENARIO 3
      delEscnario3antiguedadPena1: calculadora.delEscnario3antiguedadPena1,
      delEscnario3antiguedadPena2: calculadora.delEscnario3antiguedadPena2,
      delEscnario3antiguedadPena3: calculadora.delEscnario3antiguedadPena3,
      delEscnario3antiguedadPena4: calculadora.delEscnario3antiguedadPena4,
      delEscnario3antiguedadPena5: calculadora.delEscnario3antiguedadPena5,
      delEscnario3antiguedadPena6: calculadora.delEscnario3antiguedadPena6,
      delEscnario3antiguedadPena7: calculadora.delEscnario3antiguedadPena7,
      delEscnario3antiguedadPena8: calculadora.delEscnario3antiguedadPena8,
      delEscnario3antiguedadPena9: calculadora.delEscnario3antiguedadPena9,
      delEscnario3antiguedadPena10: calculadora.delEscnario3antiguedadPena10,
      delEscnario3antiguedadPena11: calculadora.delEscnario3antiguedadPena11,
      delEscnario3antiguedadPena12: calculadora.delEscnario3antiguedadPena12,
      delEscnario3antiguedadPena13: calculadora.delEscnario3antiguedadPena13,
      delEscnario3antiguedadPena14: calculadora.delEscnario3antiguedadPena14,
      delEscnario3antiguedadPena15: calculadora.delEscnario3antiguedadPena15,
      // //ESCENARIO 4
      delEscnario4antiguedadPena1: calculadora.delEscnario4antiguedadPena1,
      delEscnario4antiguedadPena2: calculadora.delEscnario4antiguedadPena2,
      delEscnario4antiguedadPena3: calculadora.delEscnario4antiguedadPena3,
      delEscnario4antiguedadPena4: calculadora.delEscnario4antiguedadPena4,
      delEscnario4antiguedadPena5: calculadora.delEscnario4antiguedadPena5,
      delEscnario4antiguedadPena6: calculadora.delEscnario4antiguedadPena6,
      delEscnario4antiguedadPena7: calculadora.delEscnario4antiguedadPena7,
      delEscnario4antiguedadPena8: calculadora.delEscnario4antiguedadPena8,
      delEscnario4antiguedadPena9: calculadora.delEscnario4antiguedadPena9,
      delEscnario4antiguedadPena10: calculadora.delEscnario4antiguedadPena10,
      delEscnario4antiguedadPena11: calculadora.delEscnario4antiguedadPena11,
      delEscnario4antiguedadPena12: calculadora.delEscnario4antiguedadPena12,
      delEscnario4antiguedadPena13: calculadora.delEscnario4antiguedadPena13,
      delEscnario4antiguedadPena14: calculadora.delEscnario4antiguedadPena14,
      delEscnario4antiguedadPena15: calculadora.delEscnario4antiguedadPena15,
      // //ESCENARIO 5
      delEscnario5antiguedadPena1: calculadora.delEscnario5antiguedadPena1,
      delEscnario5antiguedadPena2: calculadora.delEscnario5antiguedadPena2,
      delEscnario5antiguedadPena3: calculadora.delEscnario5antiguedadPena3,
      delEscnario5antiguedadPena4: calculadora.delEscnario5antiguedadPena4,
      delEscnario5antiguedadPena5: calculadora.delEscnario5antiguedadPena5,
      delEscnario5antiguedadPena6: calculadora.delEscnario5antiguedadPena6,
      delEscnario5antiguedadPena7: calculadora.delEscnario5antiguedadPena7,
      delEscnario5antiguedadPena8: calculadora.delEscnario5antiguedadPena8,
      delEscnario5antiguedadPena9: calculadora.delEscnario5antiguedadPena9,
      delEscnario5antiguedadPena10: calculadora.delEscnario5antiguedadPena10,
      delEscnario5antiguedadPena11: calculadora.delEscnario5antiguedadPena11,
      delEscnario5antiguedadPena12: calculadora.delEscnario5antiguedadPena12,
      delEscnario5antiguedadPena13: calculadora.delEscnario5antiguedadPena13,
      delEscnario5antiguedadPena14: calculadora.delEscnario5antiguedadPena14,
      delEscnario5antiguedadPena15: calculadora.delEscnario5antiguedadPena15,
      // //ESCENARIO 6
      delEscnario6antiguedadPena1: calculadora.delEscnario6antiguedadPena1,
      delEscnario6antiguedadPena2: calculadora.delEscnario6antiguedadPena2,
      delEscnario6antiguedadPena3: calculadora.delEscnario6antiguedadPena3,
      delEscnario6antiguedadPena4: calculadora.delEscnario6antiguedadPena4,
      delEscnario6antiguedadPena5: calculadora.delEscnario6antiguedadPena5,
      delEscnario6antiguedadPena6: calculadora.delEscnario6antiguedadPena6,
      delEscnario6antiguedadPena7: calculadora.delEscnario6antiguedadPena7,
      delEscnario6antiguedadPena8: calculadora.delEscnario6antiguedadPena8,
      delEscnario6antiguedadPena9: calculadora.delEscnario6antiguedadPena9,
      delEscnario6antiguedadPena10: calculadora.delEscnario6antiguedadPena10,
      delEscnario6antiguedadPena11: calculadora.delEscnario6antiguedadPena11,
      delEscnario6antiguedadPena12: calculadora.delEscnario6antiguedadPena12,
      delEscnario6antiguedadPena13: calculadora.delEscnario6antiguedadPena13,
      delEscnario6antiguedadPena14: calculadora.delEscnario6antiguedadPena14,
      delEscnario6antiguedadPena15: calculadora.delEscnario6antiguedadPena15,
      // //ESCENARIO 7
      delEscnario7antiguedadPena1: calculadora.delEscnario7antiguedadPena1,
      delEscnario7antiguedadPena2: calculadora.delEscnario7antiguedadPena2,
      delEscnario7antiguedadPena3: calculadora.delEscnario7antiguedadPena3,
      delEscnario7antiguedadPena4: calculadora.delEscnario7antiguedadPena4,
      delEscnario7antiguedadPena5: calculadora.delEscnario7antiguedadPena5,
      delEscnario7antiguedadPena6: calculadora.delEscnario7antiguedadPena6,
      delEscnario7antiguedadPena7: calculadora.delEscnario7antiguedadPena7,
      delEscnario7antiguedadPena8: calculadora.delEscnario7antiguedadPena8,
      delEscnario7antiguedadPena9: calculadora.delEscnario7antiguedadPena9,
      delEscnario7antiguedadPena10: calculadora.delEscnario7antiguedadPena10,
      delEscnario7antiguedadPena11: calculadora.delEscnario7antiguedadPena11,
      delEscnario7antiguedadPena12: calculadora.delEscnario7antiguedadPena12,
      delEscnario7antiguedadPena13: calculadora.delEscnario7antiguedadPena13,
      delEscnario7antiguedadPena14: calculadora.delEscnario7antiguedadPena14,
      delEscnario7antiguedadPena15: calculadora.delEscnario7antiguedadPena15,
      // //ESCENARIO 8
      delEscnario8antiguedadPena1: calculadora.delEscnario8antiguedadPena1,
      delEscnario8antiguedadPena2: calculadora.delEscnario8antiguedadPena2,
      delEscnario8antiguedadPena3: calculadora.delEscnario8antiguedadPena3,
      delEscnario8antiguedadPena4: calculadora.delEscnario8antiguedadPena4,
      delEscnario8antiguedadPena5: calculadora.delEscnario8antiguedadPena5,
      delEscnario8antiguedadPena6: calculadora.delEscnario8antiguedadPena6,
      delEscnario8antiguedadPena7: calculadora.delEscnario8antiguedadPena7,
      delEscnario8antiguedadPena8: calculadora.delEscnario8antiguedadPena8,
      delEscnario8antiguedadPena9: calculadora.delEscnario8antiguedadPena9,
      delEscnario8antiguedadPena10: calculadora.delEscnario8antiguedadPena10,
      delEscnario8antiguedadPena11: calculadora.delEscnario8antiguedadPena11,
      delEscnario8antiguedadPena12: calculadora.delEscnario8antiguedadPena12,
      delEscnario8antiguedadPena13: calculadora.delEscnario8antiguedadPena13,
      delEscnario8antiguedadPena14: calculadora.delEscnario8antiguedadPena14,
      delEscnario8antiguedadPena15: calculadora.delEscnario8antiguedadPena15,

      //ENDEUDAMIENTO PORCETAJE LIMITE
      // ESCENARIO 0
      delEscnario0porcentajeEndeudamiento1:
        calculadora.delEscnario0porcentajeEndeudamiento1,
      delEscnario0porcentajeEndeudamiento2:
        calculadora.delEscnario0porcentajeEndeudamiento2,
      delEscnario0porcentajeEndeudamiento3:
        calculadora.delEscnario0porcentajeEndeudamiento3,
      delEscnario0porcentajeEndeudamiento4:
        calculadora.delEscnario0porcentajeEndeudamiento4,
      delEscnario0porcentajeEndeudamiento5:
        calculadora.delEscnario0porcentajeEndeudamiento5,
      delEscnario0porcentajeEndeudamiento6:
        calculadora.delEscnario0porcentajeEndeudamiento6,
      delEscnario0porcentajeEndeudamiento7:
        calculadora.delEscnario0porcentajeEndeudamiento7,
      delEscnario0porcentajeEndeudamiento8:
        calculadora.delEscnario0porcentajeEndeudamiento8,
      delEscnario0porcentajeEndeudamiento9:
        calculadora.delEscnario0porcentajeEndeudamiento9,
      delEscnario0porcentajeEndeudamiento10:
        calculadora.delEscnario0porcentajeEndeudamiento10,
      delEscnario0porcentajeEndeudamiento11:
        calculadora.delEscnario0porcentajeEndeudamiento11,
      delEscnario0porcentajeEndeudamiento12:
        calculadora.delEscnario0porcentajeEndeudamiento12,
      delEscnario0porcentajeEndeudamiento13:
        calculadora.delEscnario0porcentajeEndeudamiento13,
      delEscnario0porcentajeEndeudamiento14:
        calculadora.delEscnario0porcentajeEndeudamiento14,
      delEscnario0porcentajeEndeudamiento15:
        calculadora.delEscnario0porcentajeEndeudamiento15,
      //ESCENARIO 1
      delEscnario1porcentajeEndeudamiento1:
        calculadora.delEscnario1porcentajeEndeudamiento1,
      delEscnario1porcentajeEndeudamiento2:
        calculadora.delEscnario1porcentajeEndeudamiento2,
      delEscnario1porcentajeEndeudamiento3:
        calculadora.delEscnario1porcentajeEndeudamiento3,
      delEscnario1porcentajeEndeudamiento4:
        calculadora.delEscnario1porcentajeEndeudamiento4,
      delEscnario1porcentajeEndeudamiento5:
        calculadora.delEscnario1porcentajeEndeudamiento5,
      delEscnario1porcentajeEndeudamiento6:
        calculadora.delEscnario1porcentajeEndeudamiento6,
      delEscnario1porcentajeEndeudamiento7:
        calculadora.delEscnario1porcentajeEndeudamiento7,
      delEscnario1porcentajeEndeudamiento8:
        calculadora.delEscnario1porcentajeEndeudamiento8,
      delEscnario1porcentajeEndeudamiento9:
        calculadora.delEscnario1porcentajeEndeudamiento9,
      delEscnario1porcentajeEndeudamiento10:
        calculadora.delEscnario1porcentajeEndeudamiento10,
      delEscnario1porcentajeEndeudamiento11:
        calculadora.delEscnario1porcentajeEndeudamiento11,
      delEscnario1porcentajeEndeudamiento12:
        calculadora.delEscnario1porcentajeEndeudamiento12,
      delEscnario1porcentajeEndeudamiento13:
        calculadora.delEscnario1porcentajeEndeudamiento13,
      delEscnario1porcentajeEndeudamiento14:
        calculadora.delEscnario1porcentajeEndeudamiento14,
      delEscnario1porcentajeEndeudamiento15:
        calculadora.delEscnario1porcentajeEndeudamiento15,
      //ESCENARIO 2
      delEscnario2porcentajeEndeudamiento1:
        calculadora.delEscnario2porcentajeEndeudamiento1,
      delEscnario2porcentajeEndeudamiento2:
        calculadora.delEscnario2porcentajeEndeudamiento2,
      delEscnario2porcentajeEndeudamiento3:
        calculadora.delEscnario2porcentajeEndeudamiento3,
      delEscnario2porcentajeEndeudamiento4:
        calculadora.delEscnario2porcentajeEndeudamiento4,
      delEscnario2porcentajeEndeudamiento5:
        calculadora.delEscnario2porcentajeEndeudamiento5,
      delEscnario2porcentajeEndeudamiento6:
        calculadora.delEscnario2porcentajeEndeudamiento6,
      delEscnario2porcentajeEndeudamiento7:
        calculadora.delEscnario2porcentajeEndeudamiento7,
      delEscnario2porcentajeEndeudamiento8:
        calculadora.delEscnario2porcentajeEndeudamiento8,
      delEscnario2porcentajeEndeudamiento9:
        calculadora.delEscnario2porcentajeEndeudamiento9,
      delEscnario2porcentajeEndeudamiento10:
        calculadora.delEscnario2porcentajeEndeudamiento10,
      delEscnario2porcentajeEndeudamiento11:
        calculadora.delEscnario2porcentajeEndeudamiento11,
      delEscnario2porcentajeEndeudamiento12:
        calculadora.delEscnario2porcentajeEndeudamiento12,
      delEscnario2porcentajeEndeudamiento13:
        calculadora.delEscnario2porcentajeEndeudamiento13,
      delEscnario2porcentajeEndeudamiento14:
        calculadora.delEscnario2porcentajeEndeudamiento14,
      delEscnario2porcentajeEndeudamiento15:
        calculadora.delEscnario2porcentajeEndeudamiento15,
      //ESCENARIO 3
      delEscnario3porcentajeEndeudamiento1:
        calculadora.delEscnario3porcentajeEndeudamiento1,
      delEscnario3porcentajeEndeudamiento2:
        calculadora.delEscnario3porcentajeEndeudamiento2,
      delEscnario3porcentajeEndeudamiento3:
        calculadora.delEscnario3porcentajeEndeudamiento3,
      delEscnario3porcentajeEndeudamiento4:
        calculadora.delEscnario3porcentajeEndeudamiento4,
      delEscnario3porcentajeEndeudamiento5:
        calculadora.delEscnario3porcentajeEndeudamiento5,
      delEscnario3porcentajeEndeudamiento6:
        calculadora.delEscnario3porcentajeEndeudamiento6,
      delEscnario3porcentajeEndeudamiento7:
        calculadora.delEscnario3porcentajeEndeudamiento7,
      delEscnario3porcentajeEndeudamiento8:
        calculadora.delEscnario3porcentajeEndeudamiento8,
      delEscnario3porcentajeEndeudamiento9:
        calculadora.delEscnario3porcentajeEndeudamiento9,
      delEscnario3porcentajeEndeudamiento10:
        calculadora.delEscnario3porcentajeEndeudamiento10,
      delEscnario3porcentajeEndeudamiento11:
        calculadora.delEscnario3porcentajeEndeudamiento11,
      delEscnario3porcentajeEndeudamiento12:
        calculadora.delEscnario3porcentajeEndeudamiento12,
      delEscnario3porcentajeEndeudamiento13:
        calculadora.delEscnario3porcentajeEndeudamiento13,
      delEscnario3porcentajeEndeudamiento14:
        calculadora.delEscnario3porcentajeEndeudamiento14,
      delEscnario3porcentajeEndeudamiento15:
        calculadora.delEscnario3porcentajeEndeudamiento15,
      //ESCENARIO 4
      delEscnario4porcentajeEndeudamiento1:
        calculadora.delEscnario4porcentajeEndeudamiento1,
      delEscnario4porcentajeEndeudamiento2:
        calculadora.delEscnario4porcentajeEndeudamiento2,
      delEscnario4porcentajeEndeudamiento3:
        calculadora.delEscnario4porcentajeEndeudamiento3,
      delEscnario4porcentajeEndeudamiento4:
        calculadora.delEscnario4porcentajeEndeudamiento4,
      delEscnario4porcentajeEndeudamiento5:
        calculadora.delEscnario4porcentajeEndeudamiento5,
      delEscnario4porcentajeEndeudamiento6:
        calculadora.delEscnario4porcentajeEndeudamiento6,
      delEscnario4porcentajeEndeudamiento7:
        calculadora.delEscnario4porcentajeEndeudamiento7,
      delEscnario4porcentajeEndeudamiento8:
        calculadora.delEscnario4porcentajeEndeudamiento8,
      delEscnario4porcentajeEndeudamiento9:
        calculadora.delEscnario4porcentajeEndeudamiento9,
      delEscnario4porcentajeEndeudamiento10:
        calculadora.delEscnario4porcentajeEndeudamiento10,
      delEscnario4porcentajeEndeudamiento11:
        calculadora.delEscnario4porcentajeEndeudamiento11,
      delEscnario4porcentajeEndeudamiento12:
        calculadora.delEscnario4porcentajeEndeudamiento12,
      delEscnario4porcentajeEndeudamiento13:
        calculadora.delEscnario4porcentajeEndeudamiento13,
      delEscnario4porcentajeEndeudamiento14:
        calculadora.delEscnario4porcentajeEndeudamiento14,
      delEscnario4porcentajeEndeudamiento15:
        calculadora.delEscnario4porcentajeEndeudamiento15,
      //ESCENARIO 5
      delEscnario5porcentajeEndeudamiento1:
        calculadora.delEscnario5porcentajeEndeudamiento1,
      delEscnario5porcentajeEndeudamiento2:
        calculadora.delEscnario5porcentajeEndeudamiento2,
      delEscnario5porcentajeEndeudamiento3:
        calculadora.delEscnario5porcentajeEndeudamiento3,
      delEscnario5porcentajeEndeudamiento4:
        calculadora.delEscnario5porcentajeEndeudamiento4,
      delEscnario5porcentajeEndeudamiento5:
        calculadora.delEscnario5porcentajeEndeudamiento5,
      delEscnario5porcentajeEndeudamiento6:
        calculadora.delEscnario5porcentajeEndeudamiento6,
      delEscnario5porcentajeEndeudamiento7:
        calculadora.delEscnario5porcentajeEndeudamiento7,
      delEscnario5porcentajeEndeudamiento8:
        calculadora.delEscnario5porcentajeEndeudamiento8,
      delEscnario5porcentajeEndeudamiento9:
        calculadora.delEscnario5porcentajeEndeudamiento9,
      delEscnario5porcentajeEndeudamiento10:
        calculadora.delEscnario5porcentajeEndeudamiento10,
      delEscnario5porcentajeEndeudamiento11:
        calculadora.delEscnario5porcentajeEndeudamiento11,
      delEscnario5porcentajeEndeudamiento12:
        calculadora.delEscnario5porcentajeEndeudamiento12,
      delEscnario5porcentajeEndeudamiento13:
        calculadora.delEscnario5porcentajeEndeudamiento13,
      delEscnario5porcentajeEndeudamiento14:
        calculadora.delEscnario5porcentajeEndeudamiento14,
      delEscnario5porcentajeEndeudamiento15:
        calculadora.delEscnario5porcentajeEndeudamiento15,
      //ESCENARIO 6
      delEscnario6porcentajeEndeudamiento1:
        calculadora.delEscnario6porcentajeEndeudamiento1,
      delEscnario6porcentajeEndeudamiento2:
        calculadora.delEscnario6porcentajeEndeudamiento2,
      delEscnario6porcentajeEndeudamiento3:
        calculadora.delEscnario6porcentajeEndeudamiento3,
      delEscnario6porcentajeEndeudamiento4:
        calculadora.delEscnario6porcentajeEndeudamiento4,
      delEscnario6porcentajeEndeudamiento5:
        calculadora.delEscnario6porcentajeEndeudamiento5,
      delEscnario6porcentajeEndeudamiento6:
        calculadora.delEscnario6porcentajeEndeudamiento6,
      delEscnario6porcentajeEndeudamiento7:
        calculadora.delEscnario6porcentajeEndeudamiento7,
      delEscnario6porcentajeEndeudamiento8:
        calculadora.delEscnario6porcentajeEndeudamiento8,
      delEscnario6porcentajeEndeudamiento9:
        calculadora.delEscnario6porcentajeEndeudamiento9,
      delEscnario6porcentajeEndeudamiento10:
        calculadora.delEscnario6porcentajeEndeudamiento10,
      delEscnario6porcentajeEndeudamiento11:
        calculadora.delEscnario6porcentajeEndeudamiento11,
      delEscnario6porcentajeEndeudamiento12:
        calculadora.delEscnario6porcentajeEndeudamiento12,
      delEscnario6porcentajeEndeudamiento13:
        calculadora.delEscnario6porcentajeEndeudamiento13,
      delEscnario6porcentajeEndeudamiento14:
        calculadora.delEscnario6porcentajeEndeudamiento14,
      delEscnario6porcentajeEndeudamiento15:
        calculadora.delEscnario6porcentajeEndeudamiento15,
      //ESCENARIO 7
      delEscnario7porcentajeEndeudamiento1:
        calculadora.delEscnario7porcentajeEndeudamiento1,
      delEscnario7porcentajeEndeudamiento2:
        calculadora.delEscnario7porcentajeEndeudamiento2,
      delEscnario7porcentajeEndeudamiento3:
        calculadora.delEscnario7porcentajeEndeudamiento3,
      delEscnario7porcentajeEndeudamiento4:
        calculadora.delEscnario7porcentajeEndeudamiento4,
      delEscnario7porcentajeEndeudamiento5:
        calculadora.delEscnario7porcentajeEndeudamiento5,
      delEscnario7porcentajeEndeudamiento6:
        calculadora.delEscnario7porcentajeEndeudamiento6,
      delEscnario7porcentajeEndeudamiento7:
        calculadora.delEscnario7porcentajeEndeudamiento7,
      delEscnario7porcentajeEndeudamiento8:
        calculadora.delEscnario7porcentajeEndeudamiento8,
      delEscnario7porcentajeEndeudamiento9:
        calculadora.delEscnario7porcentajeEndeudamiento9,
      delEscnario7porcentajeEndeudamiento10:
        calculadora.delEscnario7porcentajeEndeudamiento10,
      delEscnario7porcentajeEndeudamiento11:
        calculadora.delEscnario7porcentajeEndeudamiento11,
      delEscnario7porcentajeEndeudamiento12:
        calculadora.delEscnario7porcentajeEndeudamiento12,
      delEscnario7porcentajeEndeudamiento13:
        calculadora.delEscnario7porcentajeEndeudamiento13,
      delEscnario7porcentajeEndeudamiento14:
        calculadora.delEscnario7porcentajeEndeudamiento14,
      delEscnario7porcentajeEndeudamiento15:
        calculadora.delEscnario7porcentajeEndeudamiento15,
      //ESCENARIO 8
      delEscnario8porcentajeEndeudamiento1:
        calculadora.delEscnario8porcentajeEndeudamiento1,
      delEscnario8porcentajeEndeudamiento2:
        calculadora.delEscnario8porcentajeEndeudamiento2,
      delEscnario8porcentajeEndeudamiento3:
        calculadora.delEscnario8porcentajeEndeudamiento3,
      delEscnario8porcentajeEndeudamiento4:
        calculadora.delEscnario8porcentajeEndeudamiento4,
      delEscnario8porcentajeEndeudamiento5:
        calculadora.delEscnario8porcentajeEndeudamiento5,
      delEscnario8porcentajeEndeudamiento6:
        calculadora.delEscnario8porcentajeEndeudamiento6,
      delEscnario8porcentajeEndeudamiento7:
        calculadora.delEscnario8porcentajeEndeudamiento7,
      delEscnario8porcentajeEndeudamiento8:
        calculadora.delEscnario8porcentajeEndeudamiento8,
      delEscnario8porcentajeEndeudamiento9:
        calculadora.delEscnario8porcentajeEndeudamiento9,
      delEscnario8porcentajeEndeudamiento10:
        calculadora.delEscnario8porcentajeEndeudamiento10,
      delEscnario8porcentajeEndeudamiento11:
        calculadora.delEscnario8porcentajeEndeudamiento11,
      delEscnario8porcentajeEndeudamiento12:
        calculadora.delEscnario8porcentajeEndeudamiento12,
      delEscnario8porcentajeEndeudamiento13:
        calculadora.delEscnario8porcentajeEndeudamiento13,
      delEscnario8porcentajeEndeudamiento14:
        calculadora.delEscnario8porcentajeEndeudamiento14,
      delEscnario8porcentajeEndeudamiento15:
        calculadora.delEscnario8porcentajeEndeudamiento15,

      // //ENDEUDAMIENTO PORCENTAJE PENA
      //ESCENARIO 0
      delEscnario0penaEndeudamiento1:
        calculadora.delEscnario0penaEndeudamiento1,
      delEscnario0penaEndeudamiento2:
        calculadora.delEscnario0penaEndeudamiento2,
      delEscnario0penaEndeudamiento3:
        calculadora.delEscnario0penaEndeudamiento3,
      delEscnario0penaEndeudamiento4:
        calculadora.delEscnario0penaEndeudamiento4,
      delEscnario0penaEndeudamiento5:
        calculadora.delEscnario0penaEndeudamiento5,
      delEscnario0penaEndeudamiento6:
        calculadora.delEscnario0penaEndeudamiento6,
      delEscnario0penaEndeudamiento7:
        calculadora.delEscnario0penaEndeudamiento7,
      delEscnario0penaEndeudamiento8:
        calculadora.delEscnario0penaEndeudamiento8,
      delEscnario0penaEndeudamiento9:
        calculadora.delEscnario0penaEndeudamiento9,
      delEscnario0penaEndeudamiento10:
        calculadora.delEscnario0penaEndeudamiento10,
      delEscnario0penaEndeudamiento11:
        calculadora.delEscnario0penaEndeudamiento11,
      delEscnario0penaEndeudamiento12:
        calculadora.delEscnario0penaEndeudamiento12,
      delEscnario0penaEndeudamiento13:
        calculadora.delEscnario0penaEndeudamiento13,
      delEscnario0penaEndeudamiento14:
        calculadora.delEscnario0penaEndeudamiento14,
      delEscnario0penaEndeudamiento15:
        calculadora.delEscnario0penaEndeudamiento15,
      //ESCENARIO 1
      delEscnario1penaEndeudamiento1:
        calculadora.delEscnario1penaEndeudamiento1,
      delEscnario1penaEndeudamiento2:
        calculadora.delEscnario1penaEndeudamiento2,
      delEscnario1penaEndeudamiento3:
        calculadora.delEscnario1penaEndeudamiento3,
      delEscnario1penaEndeudamiento4:
        calculadora.delEscnario1penaEndeudamiento4,
      delEscnario1penaEndeudamiento5:
        calculadora.delEscnario1penaEndeudamiento5,
      delEscnario1penaEndeudamiento6:
        calculadora.delEscnario1penaEndeudamiento6,
      delEscnario1penaEndeudamiento7:
        calculadora.delEscnario1penaEndeudamiento7,
      delEscnario1penaEndeudamiento8:
        calculadora.delEscnario1penaEndeudamiento8,
      delEscnario1penaEndeudamiento9:
        calculadora.delEscnario1penaEndeudamiento9,
      delEscnario1penaEndeudamiento10:
        calculadora.delEscnario1penaEndeudamiento10,
      delEscnario1penaEndeudamiento11:
        calculadora.delEscnario1penaEndeudamiento11,
      delEscnario1penaEndeudamiento12:
        calculadora.delEscnario1penaEndeudamiento12,
      delEscnario1penaEndeudamiento13:
        calculadora.delEscnario1penaEndeudamiento13,
      delEscnario1penaEndeudamiento14:
        calculadora.delEscnario1penaEndeudamiento14,
      delEscnario1penaEndeudamiento15:
        calculadora.delEscnario1penaEndeudamiento15,
      //ESCENARIO 2
      delEscnario2penaEndeudamiento1:
        calculadora.delEscnario2penaEndeudamiento1,
      delEscnario2penaEndeudamiento2:
        calculadora.delEscnario2penaEndeudamiento2,
      delEscnario2penaEndeudamiento3:
        calculadora.delEscnario2penaEndeudamiento3,
      delEscnario2penaEndeudamiento4:
        calculadora.delEscnario2penaEndeudamiento4,
      delEscnario2penaEndeudamiento5:
        calculadora.delEscnario2penaEndeudamiento5,
      delEscnario2penaEndeudamiento6:
        calculadora.delEscnario2penaEndeudamiento6,
      delEscnario2penaEndeudamiento7:
        calculadora.delEscnario2penaEndeudamiento7,
      delEscnario2penaEndeudamiento8:
        calculadora.delEscnario2penaEndeudamiento8,
      delEscnario2penaEndeudamiento9:
        calculadora.delEscnario2penaEndeudamiento9,
      delEscnario2penaEndeudamiento10:
        calculadora.delEscnario2penaEndeudamiento10,
      delEscnario2penaEndeudamiento11:
        calculadora.delEscnario2penaEndeudamiento11,
      delEscnario2penaEndeudamiento12:
        calculadora.delEscnario2penaEndeudamiento12,
      delEscnario2penaEndeudamiento13:
        calculadora.delEscnario2penaEndeudamiento13,
      delEscnario2penaEndeudamiento14:
        calculadora.delEscnario2penaEndeudamiento14,
      delEscnario2penaEndeudamiento15:
        calculadora.delEscnario2penaEndeudamiento15,
      //ESCENARIO 3
      delEscnario3penaEndeudamiento1:
        calculadora.delEscnario3penaEndeudamiento1,
      delEscnario3penaEndeudamiento2:
        calculadora.delEscnario3penaEndeudamiento2,
      delEscnario3penaEndeudamiento3:
        calculadora.delEscnario3penaEndeudamiento3,
      delEscnario3penaEndeudamiento4:
        calculadora.delEscnario3penaEndeudamiento4,
      delEscnario3penaEndeudamiento5:
        calculadora.delEscnario3penaEndeudamiento5,
      delEscnario3penaEndeudamiento6:
        calculadora.delEscnario3penaEndeudamiento6,
      delEscnario3penaEndeudamiento7:
        calculadora.delEscnario3penaEndeudamiento7,
      delEscnario3penaEndeudamiento8:
        calculadora.delEscnario3penaEndeudamiento8,
      delEscnario3penaEndeudamiento9:
        calculadora.delEscnario3penaEndeudamiento9,
      delEscnario3penaEndeudamiento10:
        calculadora.delEscnario3penaEndeudamiento10,
      delEscnario3penaEndeudamiento11:
        calculadora.delEscnario3penaEndeudamiento11,
      delEscnario3penaEndeudamiento12:
        calculadora.delEscnario3penaEndeudamiento12,
      delEscnario3penaEndeudamiento13:
        calculadora.delEscnario3penaEndeudamiento13,
      delEscnario3penaEndeudamiento14:
        calculadora.delEscnario3penaEndeudamiento14,
      delEscnario3penaEndeudamiento15:
        calculadora.delEscnario3penaEndeudamiento15,
      //ESCENARIO 4
      delEscnario4penaEndeudamiento1:
        calculadora.delEscnario4penaEndeudamiento1,
      delEscnario4penaEndeudamiento2:
        calculadora.delEscnario4penaEndeudamiento2,
      delEscnario4penaEndeudamiento3:
        calculadora.delEscnario4penaEndeudamiento3,
      delEscnario4penaEndeudamiento4:
        calculadora.delEscnario4penaEndeudamiento4,
      delEscnario4penaEndeudamiento5:
        calculadora.delEscnario4penaEndeudamiento5,
      delEscnario4penaEndeudamiento6:
        calculadora.delEscnario4penaEndeudamiento6,
      delEscnario4penaEndeudamiento7:
        calculadora.delEscnario4penaEndeudamiento7,
      delEscnario4penaEndeudamiento8:
        calculadora.delEscnario4penaEndeudamiento8,
      delEscnario4penaEndeudamiento9:
        calculadora.delEscnario4penaEndeudamiento9,
      delEscnario4penaEndeudamiento10:
        calculadora.delEscnario4penaEndeudamiento10,
      delEscnario4penaEndeudamiento11:
        calculadora.delEscnario4penaEndeudamiento11,
      delEscnario4penaEndeudamiento12:
        calculadora.delEscnario4penaEndeudamiento12,
      delEscnario4penaEndeudamiento13:
        calculadora.delEscnario4penaEndeudamiento13,
      delEscnario4penaEndeudamiento14:
        calculadora.delEscnario4penaEndeudamiento14,
      delEscnario4penaEndeudamiento15:
        calculadora.delEscnario4penaEndeudamiento15,
      //ESCENARIO 5
      delEscnario5penaEndeudamiento1:
        calculadora.delEscnario5penaEndeudamiento1,
      delEscnario5penaEndeudamiento2:
        calculadora.delEscnario5penaEndeudamiento2,
      delEscnario5penaEndeudamiento3:
        calculadora.delEscnario5penaEndeudamiento3,
      delEscnario5penaEndeudamiento4:
        calculadora.delEscnario5penaEndeudamiento4,
      delEscnario5penaEndeudamiento5:
        calculadora.delEscnario5penaEndeudamiento5,
      delEscnario5penaEndeudamiento6:
        calculadora.delEscnario5penaEndeudamiento6,
      delEscnario5penaEndeudamiento7:
        calculadora.delEscnario5penaEndeudamiento7,
      delEscnario5penaEndeudamiento8:
        calculadora.delEscnario5penaEndeudamiento8,
      delEscnario5penaEndeudamiento9:
        calculadora.delEscnario5penaEndeudamiento9,
      delEscnario5penaEndeudamiento10:
        calculadora.delEscnario5penaEndeudamiento10,
      delEscnario5penaEndeudamiento11:
        calculadora.delEscnario5penaEndeudamiento11,
      delEscnario5penaEndeudamiento12:
        calculadora.delEscnario5penaEndeudamiento12,
      delEscnario5penaEndeudamiento13:
        calculadora.delEscnario5penaEndeudamiento13,
      delEscnario5penaEndeudamiento14:
        calculadora.delEscnario5penaEndeudamiento14,
      delEscnario5penaEndeudamiento15:
        calculadora.delEscnario5penaEndeudamiento15,
      //ESCENARIO 6
      delEscnario6penaEndeudamiento1:
        calculadora.delEscnario6penaEndeudamiento1,
      delEscnario6penaEndeudamiento2:
        calculadora.delEscnario6penaEndeudamiento2,
      delEscnario6penaEndeudamiento3:
        calculadora.delEscnario6penaEndeudamiento3,
      delEscnario6penaEndeudamiento4:
        calculadora.delEscnario6penaEndeudamiento4,
      delEscnario6penaEndeudamiento5:
        calculadora.delEscnario6penaEndeudamiento5,
      delEscnario6penaEndeudamiento6:
        calculadora.delEscnario6penaEndeudamiento6,
      delEscnario6penaEndeudamiento7:
        calculadora.delEscnario6penaEndeudamiento7,
      delEscnario6penaEndeudamiento8:
        calculadora.delEscnario6penaEndeudamiento8,
      delEscnario6penaEndeudamiento9:
        calculadora.delEscnario6penaEndeudamiento9,
      delEscnario6penaEndeudamiento10:
        calculadora.delEscnario6penaEndeudamiento10,
      delEscnario6penaEndeudamiento11:
        calculadora.delEscnario6penaEndeudamiento11,
      delEscnario6penaEndeudamiento12:
        calculadora.delEscnario6penaEndeudamiento12,
      delEscnario6penaEndeudamiento13:
        calculadora.delEscnario6penaEndeudamiento13,
      delEscnario6penaEndeudamiento14:
        calculadora.delEscnario6penaEndeudamiento14,
      delEscnario6penaEndeudamiento15:
        calculadora.delEscnario6penaEndeudamiento15,
      //ESCENARIO 7
      delEscnario7penaEndeudamiento1:
        calculadora.delEscnario7penaEndeudamiento1,
      delEscnario7penaEndeudamiento2:
        calculadora.delEscnario7penaEndeudamiento2,
      delEscnario7penaEndeudamiento3:
        calculadora.delEscnario7penaEndeudamiento3,
      delEscnario7penaEndeudamiento4:
        calculadora.delEscnario7penaEndeudamiento4,
      delEscnario7penaEndeudamiento5:
        calculadora.delEscnario7penaEndeudamiento5,
      delEscnario7penaEndeudamiento6:
        calculadora.delEscnario7penaEndeudamiento6,
      delEscnario7penaEndeudamiento7:
        calculadora.delEscnario7penaEndeudamiento7,
      delEscnario7penaEndeudamiento8:
        calculadora.delEscnario7penaEndeudamiento8,
      delEscnario7penaEndeudamiento9:
        calculadora.delEscnario7penaEndeudamiento9,
      delEscnario7penaEndeudamiento10:
        calculadora.delEscnario7penaEndeudamiento10,
      delEscnario7penaEndeudamiento11:
        calculadora.delEscnario7penaEndeudamiento11,
      delEscnario7penaEndeudamiento12:
        calculadora.delEscnario7penaEndeudamiento12,
      delEscnario7penaEndeudamiento13:
        calculadora.delEscnario7penaEndeudamiento13,
      delEscnario7penaEndeudamiento14:
        calculadora.delEscnario7penaEndeudamiento14,
      delEscnario7penaEndeudamiento15:
        calculadora.delEscnario7penaEndeudamiento15,
      //ESCENARIO 8
      delEscnario8penaEndeudamiento1:
        calculadora.delEscnario8penaEndeudamiento1,
      delEscnario8penaEndeudamiento2:
        calculadora.delEscnario8penaEndeudamiento2,
      delEscnario8penaEndeudamiento3:
        calculadora.delEscnario8penaEndeudamiento3,
      delEscnario8penaEndeudamiento4:
        calculadora.delEscnario8penaEndeudamiento4,
      delEscnario8penaEndeudamiento5:
        calculadora.delEscnario8penaEndeudamiento5,
      delEscnario8penaEndeudamiento6:
        calculadora.delEscnario8penaEndeudamiento6,
      delEscnario8penaEndeudamiento7:
        calculadora.delEscnario8penaEndeudamiento7,
      delEscnario8penaEndeudamiento8:
        calculadora.delEscnario8penaEndeudamiento8,
      delEscnario8penaEndeudamiento9:
        calculadora.delEscnario8penaEndeudamiento9,
      delEscnario8penaEndeudamiento10:
        calculadora.delEscnario8penaEndeudamiento10,
      delEscnario8penaEndeudamiento11:
        calculadora.delEscnario8penaEndeudamiento11,
      delEscnario8penaEndeudamiento12:
        calculadora.delEscnario8penaEndeudamiento12,
      delEscnario8penaEndeudamiento13:
        calculadora.delEscnario8penaEndeudamiento13,
      delEscnario8penaEndeudamiento14:
        calculadora.delEscnario8penaEndeudamiento14,
      delEscnario8penaEndeudamiento15:
        calculadora.delEscnario8penaEndeudamiento15,
    });
    setCuestionarioInicial(1);
    traerContratosBd();
    setMostrarBotones(false);
  }

  // push de opts
  //Estado options BD
  let pushing = [];
  const [contratoBD, setContratoBD] = useState([]);
  async function traerContratosBd() {
    const querySnapshot = await getDocs(collection(db, "tipoDeContrato"));
    querySnapshot.forEach((doc) => {
      let contrato = {
        nombre: doc.data().nombre,
        id: doc.data().id,
      };
      pushing.push(contrato);
    });
    setContratoBD(pushing);
  }

  return (
    <main className="container-firmax-verCalculadoras">
      <img
        src={logo}
        alt=""
        className="container-firmax-logo"
        style={{ marginBottom: "3rem" }}
      />
      {/*
        <h1 onClick={()=>console.log(setearCalculadoras)}>CALCULADORA/S</h1>
        <h1 onClick={()=>console.log(setearDatosCalculadora)}>CALCULADORA</h1>
        <h1 onClick={()=>console.log(contratoBD)}>CONTRATOS</h1> */}
      {cargando === 1 ? (
        <p style={{ paddingTop: "3rem", fontWeight: "700" }}>
          Cargando calculadoras....
        </p>
      ) : null}
      <>
        {mostrarBotones ? (
          <>
            <div className="container-firmax-verCalculadoras-contenedor-buttons">
              {setearCalculadoras.map((calculadora) => (
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="container-firmax-verCalculadoras-contenedor-button"
                  key={calculadora.uid}
                  href="#cuestionario"
                  onClick={() => empezarCuestionario(calculadora)}
                >
                  {calculadora.nombreInmobiliaria}
                </a>
              ))}
            </div>

            <div
              onClick={() => {
                actionBack
                  ? navigate("/inmobiliaria")
                  : navigate("/inmobiliaria/inicio");
              }}
              className="section-firmax-form-container-back padding-top"
            >
              <i className="bx bx-left-arrow-alt"></i>
              <p>Volver</p>
            </div>
          </>
        ) : null}
      </>

      {cuestionarioInicial ? (
        <>
          <LogicaFinanzas
            cuestionario={cuestionario}
            setCuestionario={setCuestionario}
            contratoBD={contratoBD}
            setContratoBD={setContratoBD}
            setearDatosCalculadora={setearDatosCalculadora}
            date={date}
            seteo={seteo}
            seteoDeDatosUser={seteoDeDatosUser}
          />
          <div
            onClick={() => {
              actionBack
                ? navigate("/inmobiliaria")
                : navigate("/inmobiliaria/inicio");
            }}
            className="section-firmax-form-container-back padding-top"
          >
            <i className="bx bx-left-arrow-alt"></i>
            <p>Volver</p>
          </div>
        </>
      ) : null}
    </main>
  );
};

export default VerCalculadoras;
