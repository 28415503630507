import { useState, useEffect } from "react";

import CuestionarioDos from "./CuestionarioDos";

import CuestionarioUno from "./CuestionarioUno";
// import { collection, getDocs } from "firebase/firestore";
// import { db } from '../../../Firebase/Credenciales'
import CuestionarioTres from "./CuestionarioTres";
import CuestionarioTresBis from "./CuestionariosTresBis";

const Calculadora = ({
  setCuestionario,
  cuestionario,
  contratoBD,
  setearDatosCalculadora,
  date,
  usuarioSimulacion,
  seteo,
  setSeteo,
  seteoDeDatosUser,
}) => {
  const [financiacion, setFinanciacion] = useState(0);
  //Datos del cuestionario
  const [datosCuestionario, setDatosCuestionario] = useState({});

  const [personas, setPersonas] = useState([1]);

  useEffect(() => {
    console.log(seteo);
    if (seteo) {
      setDatosCuestionario({
        resUno: usuarioSimulacion.resUno,
        peronasLength: usuarioSimulacion.peronasLength,
        interesFija: usuarioSimulacion.interesFija,
        aniosFija: usuarioSimulacion.aniosFija,
        tiempoFijoMinimo: usuarioSimulacion.tiempoFijoMinimo,
        tiempoFijoMaximo: usuarioSimulacion.tiempoFijoMaximo,
        cuotaInicialFija: usuarioSimulacion.cuotaInicialFija,
        //variable
        primerosMesesVar: usuarioSimulacion.primerosMesesVar,
        primerosMesesVarInteres: usuarioSimulacion.primerosMesesVarInteres,
        restoPlazoInteresVar: usuarioSimulacion.restoPlazoInteresVar,
        aniosVariable: usuarioSimulacion.aniosVariable,
        tiempoVariableMinimo: usuarioSimulacion.tiempoVariableMinimo,
        tiempoVariableMaximo: usuarioSimulacion.tiempoVariableMaximo,
        cuotaInicialVariable: usuarioSimulacion.cuotaInicialVariable,
        cuotaRestoVariable: usuarioSimulacion.cuotaRestoVariable,
        //mixto
        primerosMesesMix: usuarioSimulacion.primerosMesesMix,
        primerosMesesMixInteres: usuarioSimulacion.primerosMesesMixInteres,
        restoPlazoInteresMix: usuarioSimulacion.restoPlazoInteresMix,
        aniosMixto: usuarioSimulacion.aniosMixto,
        tiempoMixiableMinimo: usuarioSimulacion.tiempoMixiableMinimo,
        tiempoMixiableMaximo: usuarioSimulacion.tiempoMixiableMaximo,
        cuotaInicialMixiable: usuarioSimulacion.cuotaInicialMixiable,
        cuotaRestoMixiable: usuarioSimulacion.cuotaRestoMixiable,

        resPreguntaCero: usuarioSimulacion.resPreguntaCero,
        resTres: usuarioSimulacion.resTres,
        mejorasAntiguedad: usuarioSimulacion.mejorasAntiguedad,
        mejorasAntiguedadDos: usuarioSimulacion.mejorasAntiguedadDos,
        mejorasRatio: usuarioSimulacion.mejorasRatio,
        mejorasGarantia: usuarioSimulacion.mejorasGarantia,
        nombreViabilidad: usuarioSimulacion.nombreViabilidad,
        apellidoViabilidad: usuarioSimulacion.apellidoViabilidad,
        edadViavilidad: usuarioSimulacion.edadViavilidad,
        telefonoViabilidad: usuarioSimulacion.telefonoViabilidad,
        situacionLaboral: usuarioSimulacion.situacionLaboral,
        fechaDeIngreso: usuarioSimulacion.fechaDeIngreso,
        ingresosNetosMensuales: usuarioSimulacion.ingresosNetosMensuales,
        pagasAnuales: usuarioSimulacion.pagasAnuales,
        pagaDeCuotas: usuarioSimulacion.pagaDeCuotas,
        financiacionPorcentaje: usuarioSimulacion.financiacionPorcentaje,
        hipotecaSolicitada: usuarioSimulacion.hipotecaSolicitada,
        honorariosDeGestion: usuarioSimulacion.honorariosDeGestion,
        porcentaje: usuarioSimulacion.porcentaje,
        porcentajeMem: usuarioSimulacion.porcentajeMem,
        financiacionPorcen: usuarioSimulacion.financiacionPorcen,
        hipoteca: usuarioSimulacion.hipoteca,
        plazoHipoteca: usuarioSimulacion.plazoHipoteca,
        cuotaEstimadaFija: usuarioSimulacion.cuotaEstimadaFija,
        cuotaEstimadaVariable: usuarioSimulacion.cuotaEstimadaVariable,
        cuotaEstimadaVariableEuribor:
          usuarioSimulacion.cuotaEstimadaVariableEuribor,
        cuotaEstimadaMixta: usuarioSimulacion.cuotaEstimadaMixta,
        cuotaEstimadaMixtaEuribor: usuarioSimulacion.cuotaEstimadaMixtaEuribor,
        escenario: usuarioSimulacion.escenario,
        valorFijoMixto: usuarioSimulacion.valorFijoMixto,
        aniosTiempo: usuarioSimulacion.aniosTiempo,
        aniosTiempoVariable: usuarioSimulacion.aniosTiempoVariable,
        aniosTiempoMixto: usuarioSimulacion.aniosTiempoMixto,
        innteresFija: usuarioSimulacion.innteresFija,
        innteresVariable: usuarioSimulacion.innteresVariable,
        innteresVariableEuribor: usuarioSimulacion.innteresVariableEuribor,
        interesMixta: usuarioSimulacion.interesMixta,
        interesMixtaEuribor: usuarioSimulacion.interesMixtaEuribor,
        hipotecaSolicitadaConHonorarios:
          usuarioSimulacion.hipotecaSolicitadaConHonorarios === undefined
            ? 0
            : usuarioSimulacion.hipotecaSolicitadaConHonorarios,
        honorariosDeEscritura:
          usuarioSimulacion.honorariosDeEscritura === undefined
            ? "no"
            : usuarioSimulacion.honorariosDeEscritura,
        hipotecaSolicitadaHonarios:
          usuarioSimulacion.hipotecaSolicitadaHonarios === undefined
            ? 0
            : usuarioSimulacion.hipotecaSolicitadaHonarios,
        hipotecaMasComisionMasHonorarios:
          usuarioSimulacion.hipotecaMasComisionMasHonorarios === undefined
            ? 0
            : usuarioSimulacion.hipotecaMasComisionMasHonorarios,
        segundaGarantia:
          usuarioSimulacion.segundaGarantia === undefined
            ? "si"
            : usuarioSimulacion.segundaGarantia,
        valorAproxSegundaGarantia:
          usuarioSimulacion.valorAproxSegundaGarantia === undefined
            ? 0
            : usuarioSimulacion.valorAproxSegundaGarantia,
        totalAcobrar:
          usuarioSimulacion.totalAcobrar === undefined
            ? 0
            : usuarioSimulacion.totalAcobrar,
        calculadora: usuarioSimulacion.calculadora,
        //datos persona dos
        nombreViabilidadDos:
          usuarioSimulacion.nombreViabilidadDos === undefined
            ? undefined
            : usuarioSimulacion.nombreViabilidadDos,
        edadViavilidadDos:
          usuarioSimulacion.edadViavilidadDos === undefined
            ? undefined
            : usuarioSimulacion.edadViavilidadDos,
        fechaDeIngresoDos:
          usuarioSimulacion.fechaDeIngresoDos === undefined
            ? undefined
            : usuarioSimulacion.fechaDeIngresoDos,
        ingresosNetosMensualesDos:
          usuarioSimulacion.ingresosNetosMensualesDos === undefined
            ? undefined
            : usuarioSimulacion.ingresosNetosMensualesDos,
        pagasAnualesDos:
          usuarioSimulacion.pagasAnualesDos === undefined
            ? undefined
            : usuarioSimulacion.pagasAnualesDos,
        pagaDeCuotasDos:
          usuarioSimulacion.pagaDeCuotasDos === undefined
            ? undefined
            : usuarioSimulacion.pagaDeCuotasDos,
        situacionLaboralDos:
          usuarioSimulacion.situacionLaboralDos === undefined
            ? undefined
            : usuarioSimulacion.situacionLaboralDos,
        //datos persona tres
        nombreViabilidadTres:
          usuarioSimulacion.nombreViabilidadTres === undefined
            ? undefined
            : usuarioSimulacion.nombreViabilidadTres,
        edadViavilidadTres:
          usuarioSimulacion.edadViavilidadTres === undefined
            ? undefined
            : usuarioSimulacion.edadViavilidadTres,
        fechaDeIngresoTres:
          usuarioSimulacion.fechaDeIngresoTres === undefined
            ? undefined
            : usuarioSimulacion.fechaDeIngresoTres,
        ingresosNetosMensualesTres:
          usuarioSimulacion.ingresosNetosMensualesTres === undefined
            ? undefined
            : usuarioSimulacion.ingresosNetosMensualesTres,
        pagasAnualesTres:
          usuarioSimulacion.pagasAnualesTres === undefined
            ? undefined
            : usuarioSimulacion.pagasAnualesTres,
        pagaDeCuotasTres:
          usuarioSimulacion.pagaDeCuotasTres === undefined
            ? undefined
            : usuarioSimulacion.pagaDeCuotasTres,
        situacionLaboralTres:
          usuarioSimulacion.situacionLaboralTres === undefined
            ? undefined
            : usuarioSimulacion.situacionLaboralTres,
        //datos persona cuatro
        nombreViabilidadCuatro:
          usuarioSimulacion.nombreViabilidadCuatro === undefined
            ? undefined
            : usuarioSimulacion.nombreViabilidadCuatro,
        edadViavilidadCuatro:
          usuarioSimulacion.edadViavilidadCuatro === undefined
            ? undefined
            : usuarioSimulacion.edadViavilidadCuatro,
        fechaDeIngresoCuatro:
          usuarioSimulacion.fechaDeIngresoCuatro === undefined
            ? undefined
            : usuarioSimulacion.fechaDeIngresoCuatro,
        ingresosNetosMensualesCuatro:
          usuarioSimulacion.ingresosNetosMensualesCuatro === undefined
            ? undefined
            : usuarioSimulacion.ingresosNetosMensualesCuatro,
        pagasAnualesCuatro:
          usuarioSimulacion.pagasAnualesCuatro === undefined
            ? undefined
            : usuarioSimulacion.pagasAnualesCuatro,
        pagaDeCuotasCuatro:
          usuarioSimulacion.pagaDeCuotasCuatro === undefined
            ? undefined
            : usuarioSimulacion.pagaDeCuotasCuatro,
        situacionLaboralCuatro:
          usuarioSimulacion.situacionLaboralCuatro === undefined
            ? undefined
            : usuarioSimulacion.situacionLaboralCuatro,
        dniViabilidad: usuarioSimulacion.dniViabilidad,
        emailViabilidad: usuarioSimulacion.emailViabilidad,
        domicilioViabilidad: usuarioSimulacion.domicilioViabilidad,
        poblacionViabilidad: usuarioSimulacion.poblacionViabilidad,
        provinciaViabilidad: usuarioSimulacion.provinciaViabilidad,
        apellidoViabilidadDos:
          usuarioSimulacion.apellidoViabilidadDos === undefined
            ? undefined
            : usuarioSimulacion.apellidoViabilidadDos,
        dniViabilidadDos:
          usuarioSimulacion.dniViabilidadDos === undefined
            ? undefined
            : usuarioSimulacion.dniViabilidadDos,
        emailViabilidadDos:
          usuarioSimulacion.emailViabilidadDos === undefined
            ? undefined
            : usuarioSimulacion.emailViabilidadDos,
        telefonoViabilidadDos:
          usuarioSimulacion.telefonoViabilidadDos === undefined
            ? undefined
            : usuarioSimulacion.telefonoViabilidadDos,
        apellidoViabilidadTres:
          usuarioSimulacion.apellidoViabilidadTres === undefined
            ? undefined
            : usuarioSimulacion.apellidoViabilidadTres,
        dniViabilidadTres:
          usuarioSimulacion.dniViabilidadTres === undefined
            ? undefined
            : usuarioSimulacion.dniViabilidadTres,
        emailViabilidadTres:
          usuarioSimulacion.emailViabilidadTres === undefined
            ? undefined
            : usuarioSimulacion.emailViabilidadTres,
        telefonoViabilidadTres:
          usuarioSimulacion.telefonoViabilidadTres === undefined
            ? undefined
            : usuarioSimulacion.telefonoViabilidadTres,
        apellidoViabilidadCuatro:
          usuarioSimulacion.apellidoViabilidadCuatro === undefined
            ? undefined
            : usuarioSimulacion.apellidoViabilidadCuatro,
        dniViabilidadCuatro:
          usuarioSimulacion.dniViabilidadCuatro === undefined
            ? undefined
            : usuarioSimulacion.dniViabilidadCuatro,
        emailViabilidadCuatro:
          usuarioSimulacion.emailViabilidadCuatro === undefined
            ? undefined
            : usuarioSimulacion.emailViabilidadCuatro,
        telefonoViabilidadCuatro:
          usuarioSimulacion.telefonoViabilidadCuatro === undefined
            ? undefined
            : usuarioSimulacion.telefonoViabilidadCuatro,
        optMejoraGarantia:
          usuarioSimulacion.optMejoraGarantia === undefined
            ? undefined
            : usuarioSimulacion.optMejoraGarantia,
        optMejoraAntiguedad:
          usuarioSimulacion.optMejoraAntiguedad === undefined
            ? undefined
            : usuarioSimulacion.optMejoraAntiguedad,
      });

      console.log(usuarioSimulacion);

      for (let index = 1; index < datosCuestionario.peronasLength; index++) {
        personas.push(index + 1);
        console.log("asd me for");
        setSeteo(false);
      }
    }
  }, [
    datosCuestionario.peronasLength,
    personas,
    setSeteo,
    seteo,
    usuarioSimulacion,
  ]);

  return (
    <div>
      {/* <h1 onClick={()=>console.log(usuarioSimulacion)}>HOLA</h1> */}
      {cuestionario === 1 ? (
        <CuestionarioUno
          datosCuestionario={datosCuestionario}
          setDatosCuestionario={setDatosCuestionario}
          setCuestionario={setCuestionario}
          setearDatosCalculadora={setearDatosCalculadora}
        />
      ) : null}

      {cuestionario === 2 ? (
        <CuestionarioDos
          datosCuestionario={datosCuestionario}
          setDatosCuestionario={setDatosCuestionario}
          setCuestionario={setCuestionario}
          contratoBD={contratoBD}
          setearDatosCalculadora={setearDatosCalculadora}
          personas={personas}
          setPersonas={setPersonas}
          seteo={seteo}
        />
      ) : null}

      {cuestionario === 3 ? (
        <CuestionarioTres
          datosCuestionario={datosCuestionario}
          setDatosCuestionario={setDatosCuestionario}
          setCuestionario={setCuestionario}
          financiacion={financiacion}
          contratoBD={contratoBD}
          setearDatosCalculadora={setearDatosCalculadora}
          setFinanciacion={setFinanciacion}
          date={date}
          seteoDeDatosUser={seteoDeDatosUser}
        />
      ) : null}

      {cuestionario === 4 ? (
        <CuestionarioTresBis
          datosCuestionario={datosCuestionario}
          setDatosCuestionario={setDatosCuestionario}
          setCuestionario={setCuestionario}
          financiacion={financiacion}
          contratoBD={contratoBD}
          setearDatosCalculadora={setearDatosCalculadora}
          setFinanciacion={setFinanciacion}
          date={date}
          seteoDeDatosUser={seteoDeDatosUser}
        />
      ) : null}
    </div>
  );
};

export default Calculadora;
