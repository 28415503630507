/* eslint-disable jsx-a11y/heading-has-content */


// Usamos inicio para llevar al usuario a su respectivo inicio dependiendo de su rol

import { useEffect , useState } from "react";
import {useNavigate} from "react-router-dom";
import { doc, getDoc } from "firebase/firestore"; 
import { db } from '../Firebase/Credenciales'


const Redirect = ({user}) => {
    const [userEncontrado, setUserEncontrado] = useState(null)
    const [loading, setLoading] = useState(true)
    const usuario = user.uid;
    const navigate = useNavigate()
    console.log(usuario + " SOY USUARIO");
    useEffect(() => {
        //Encontrar usuario con el uid
        async function buscarUser() {
            const docRef = doc(db, "users" , usuario)
            const docSnap = await getDoc(docRef);
            console.log(docSnap.data() + " HASTA ACA LLEGO");
            console.log(docSnap.data().rol + " soy rol");
            if (docSnap.exists()) {
              
                setUserEncontrado(docSnap.data().rol)

                switch (userEncontrado) {
                    case "adminFirmax":
                        navigate('/firmax')
                        break;
                    case "inmobiliaria":
                        console.log("INMO ACA")
                        navigate('/inmobiliaria')
                        break;   
                    case "inmobiliariaEmpleado":
                        console.log("EMPLEADO ACA")
                        navigate('/inmobiliaria/inicio')
                        break
                    default:
                        break;
                }
            }else{
                navigate('/login')
            }

            //Funcion tiempo de carga del loading 1.2seg 
            const delay = (seconds) => new Promise(resolve => setTimeout(resolve , seconds))
            const func = async () => {
                await delay(3)
                setLoading("-10000px")
            }     
            func()  
        } 

        buscarUser()
    }, [navigate, userEncontrado, usuario])
    

    return ( 
        <section style={{top: loading}} className="contenedor-ring">
            <div className="ring">Loading
                <h6></h6>
            </div>
        </section>
     );
}
 
export default Redirect;