
import logo from '../../img/logo.png'
import {auth} from '../../Firebase/Credenciales'
import { onAuthStateChanged } from "firebase/auth";
import { signOut } from "firebase/auth";
import {useNavigate} from "react-router-dom";
import { useEffect , useState } from "react";
import { doc, getDoc } from "firebase/firestore"; 
import { db } from '../../Firebase/Credenciales'



const Inmobiliaria = () => {
    const navigate = useNavigate() 
    const [date , setDate] = useState("")
    const [datosUser, setdatosUser] = useState()

    useEffect(() => {
        onAuthStateChanged(auth, (userLog) => {
          if (userLog) {
            setDate(userLog); 
            async function buscarUser() {
              const docRef = doc(db, "users" , userLog.uid)
              const docSnap = await getDoc(docRef); 
              setdatosUser(docSnap.data())
              switch (docSnap.data().rol) {
                case "adminFirmax":
                    navigate('/firmax')
                    break;
                case "inmobiliaria":
                    navigate('/inmobiliaria')
                    break;   
                case "inmobiliariaEmpleado":
                      navigate('/inmobiliaria/inicio')
                      break;   
                default:
                    break;
            }
            }
            buscarUser()
          } else {
            setDate(null);
            navigate('/login')   
          }
        });
      }, [date, navigate]);

    //Listar inmos, pasa por props
    

    const cerrarSesion = () =>{
        signOut(auth).then(()=>{
            navigate('/')
        }).catch((err)=>{})
      }

    return ( 
        <section className='section-firmax'>
            <header className='section-firmax-head'>
                <img src={logo} alt="logo_firma" className='logo-firma'/>
                <h1 className='section-firmax-head-title'>Bienvenido {datosUser?.nombre}!!</h1>
            </header>

            <main className='section-firmax-main'>
                <button onClick={() => navigate('/inmobiliaria/Calculadoras')}>Simular Hipoteca</button>
                <button onClick={() => navigate('/inmobiliaria/simulacion')}>Recuperar simulacion</button>
                <button onClick={() => navigate('/inmobiliaria/configuracion')}>Configuracion</button>                
                <button onClick={cerrarSesion}>Cerrar sesion</button>
            </main>

        </section>
     );
}
 
export default Inmobiliaria;