import logo from '../../../img/logo.png'
import { useState , useEffect } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import {auth} from '../../../Firebase/Credenciales'
import {useNavigate} from "react-router-dom";
import { doc, setDoc , getDoc } from "firebase/firestore"; 
import {db} from '../../../Firebase/Credenciales'
import { onAuthStateChanged } from "firebase/auth";
import Swal from 'sweetalert2';

const CrearUser = () => {
    const [mensaje , setMensaje] = useState({
        mensaje:"",
        color:""
    })
    const [datosUser, setDatosUser] = useState({
        nombreComercial: "",
        nombreCompleto: "",
        dni: "",
        calle: "",
        altura:"",
        poblacion: "",
        provincia: "",
        telefono: "",
        email: "",
        contraseña: ""
    })
    const [date , setDate] = useState(null)
    const navigate = useNavigate()
    //Captura el target del form
    function handleInput(e) {
        setDatosUser({
            ...datosUser,
            [e.target.name] : e.target.value
        })
    }
    //Funcion para guardar user en Firebase
    async function crearUser(e) {
        e.preventDefault();
        //Creamos la cuenta
        await createUserWithEmailAndPassword(auth, datosUser.email, datosUser.contraseña)
        .then((userCredential) => {
            //Mensaje correcto
            setMensaje({
                mensaje: "Usuario creado correctamente",
                color: "#8FCD60"
            })
            console.log(userCredential.user.uid)
            //A su vez lo guardamos en BD con el rol en automatico (inmobiliaria)
            guardarUserBD(datosUser.nombreCompleto, 
                datosUser.nombreComercial,
                datosUser.calle,
                datosUser.altura,
                datosUser.poblacion,
                datosUser.provincia,
                datosUser.dni,
                datosUser.telefono,
                datosUser.email,
                userCredential.user.uid                        
            )
            // A su vez tambien lo guardamos en una coleccion aparte para poder usar los datos en el apartod de immo
            guardarUserColector(datosUser.nombreCompleto, 
                datosUser.nombreComercial,
                datosUser.calle,
                datosUser.altura,
                datosUser.poblacion,
                datosUser.provincia,
                datosUser.dni,
                datosUser.telefono,
                datosUser.email,
                userCredential.user.uid                        
            )
            guardarUserInmo(datosUser.nombreCompleto, 
                datosUser.nombreComercial,
                datosUser.calle,
                datosUser.altura,
                datosUser.poblacion,
                datosUser.provincia,
                datosUser.dni,
                datosUser.telefono,
                datosUser.email,
                userCredential.user.uid                        
            )
            //Reseteamos el form
            setDatosUser({
                nombreComercial: "",
                nombreCompleto: "",
                dni: "",
                calle: "",
                altura:"",
                poblacion: "",
                provincia: "",
                telefono: "",
                email: "",
                contraseña: ""
            })
            setTimeout(() => {
                setMensaje({
                    mensaje: "",
                    color: ""
                })
            }, 2000);
        }).catch((error) => {
            setMensaje({
                mensaje: "Error al crear el usuario. Minimo de caracteres 6(contraseña)",
                color: "red"
            })
            setTimeout(() => {
                setMensaje({
                    mensaje: "",
                    color: ""
                })
            }, 3000);
            console.log(error)
        });
    }

    //Guardar user en BD
    async function guardarUserBD(nombre, razonSocial, calle, altura, poblacion, provincia, dni, telefono, mail, uid) {
        await setDoc(doc(db, "users", uid), {
            nombre: nombre,
            razonSocial: razonSocial,
            calle: calle,
            altura: altura,
            poblacion: poblacion,
            provincia: provincia,
            dni: dni,
            telefono: telefono,
            mail: mail,
            rol: "inmobiliaria",
            uid: uid
          });
    }
    //Guardar en una Colleccion
    async function guardarUserColector(nombre, razonSocial, calle, altura, poblacion, provincia, dni, telefono, mail, uid) {
        await setDoc(doc(db, mail, mail), {
            nombre: nombre,
            razonSocial: razonSocial,
            calle: calle,
            altura: altura,
            poblacion: poblacion,
            provincia: provincia,
            dni: dni,
            telefono: telefono,
            mail: mail,
            rol: "inmobiliaria",
            uid: uid
          });
    }

    async function guardarUserInmo(nombre, razonSocial, calle, altura, poblacion, provincia, dni, telefono, mail, uid) {
        await setDoc(doc(db, "inmobiliarias", mail), {
            nombre: nombre,
            razonSocial: razonSocial,
            calle: calle,
            altura: altura,
            poblacion: poblacion,
            provincia: provincia,
            dni: dni,
            telefono: telefono,
            mail: mail,
            rol: "inmobiliaria",
            uid: uid
          });
    }

    useEffect(() => {
        onAuthStateChanged(auth, (userLog) => {
            if (userLog) {
              setDate(userLog); 
              async function buscarUser() {
                const docRef = doc(db, "users" , userLog.uid)
                const docSnap = await getDoc(docRef);
                switch (docSnap.data().rol) {
                  case "adminFirmax":
                      navigate('/firmax/Crear-user')
                      break;
                  case "inmobiliaria":
                      navigate('/inmobiliaria')
                      break;    
                  default:
                      break;
              }
              }
              buscarUser()
            } else {
              setDate(null);
              navigate('/login')   
            }
          });
      }, [date, navigate]);

      function mostrarAlert() {
        Swal.fire({
            title: "Atención!!",
            text: "Todos los mails creados NO PUEDEN TENER NINGUNA MAYUSCULA",
            icon:"info",
            confirmButtonColor:"#8FCD60"
        })
      }

    return ( 
        <section className='section-firmax'>
            <img className='logo-crear-user' src={logo} alt="logo_firma"/>
            <form onSubmit={crearUser} className='section-firmax-form bg realitive'>        
                <i onClick={mostrarAlert} className='bx bxs-alarm-exclamation leyenda-grid-alarm bg'></i>
                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>Nombre comercial</label>
                    <input
                    value={datosUser.nombreComercial}
                    name="nombreComercial"
                    onChange={handleInput}
                    type="text"
                    placeholder="“Fincas Barcelona”"
                    />
                </div>
    
                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>Nombre completo / Razón Social</label>
                    <input
                    value={datosUser.nombreCompleto}
                    name="nombreCompleto"
                    onChange={handleInput}
                    type="text"
                    placeholder="“ fincas Barcelona S.L.”"
                    />
                </div>
     
                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>Calle</label>
                    <input
                    value={datosUser.calle}
                    name="calle"
                    onChange={handleInput}
                    type="text"
                    placeholder="“Av. Sant Joan”"
                    />
                </div>

                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>Altura</label>
                    <input
                    value={datosUser.altura}
                    name="altura"
                    onChange={handleInput}
                    type="text"
                    placeholder="“3241”"
                    />
                </div>
               
                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>Población</label>
                    <input
                    value={datosUser.poblacion}
                    name="poblacion"
                    onChange={handleInput}
                    type="text"
                    placeholder="“Barcelona”"
                    />
                </div>
                
                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>Provincia</label>
                    <input
                    value={datosUser.provincia}
                    name="provincia"
                    onChange={handleInput}
                    type="text"
                    placeholder="“Barcelona”"
                    />
                </div>

                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>DNI/CIF</label>
                    <input
                    value={datosUser.dni}
                    name="dni"
                    onChange={handleInput}
                    type="text"
                    placeholder="“B-45151545”"
                    />
                </div>
                
                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>Teléfono</label>
                    <input
                    value={datosUser.telefono}
                    name="telefono"
                    onChange={handleInput}
                    type="number"
                    placeholder="+34919930855"
                    />
                </div>
               
                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>e-mail</label>
                    <input
                    value={datosUser.email}
                    name="email"
                    onChange={handleInput}
                    type="email"
                    placeholder="mail@gmail.com"
                    />
                </div>

                <div className='section-firmax-form-container-label-input bg'>
                    <label className='bg'>Contraseña</label>
                    <input
                    value={datosUser.contraseña}
                    name="contraseña"
                    onChange={handleInput}
                    type="password"
                    placeholder="*********"
                    />
                </div>
                <button type='submit' className='section-firmax-form-grid-width'>Crear usuario</button>

                <p className='section-firmax-form-grid-width bg' style={{color: mensaje.color}}>{mensaje.mensaje}</p>

                <div onClick={()=> navigate('/firmax')} className='section-firmax-form-container-back bg'>
                    <i className='bx bx-left-arrow-alt bg'></i>
                    <p className='bg'>Volver</p>
                </div>            
            </form>
        </section>
     );
}
 
export default CrearUser;