import logo from "../../img/logo.png";
import { auth, db } from "../../Firebase/Credenciales";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteDoc , collection, getDocs ,  doc, getDoc } from "firebase/firestore";
import Swal from "sweetalert2";

const ListaUserInmo = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [mostrarUsers, setmostrarUsers] = useState([]);
  const [stateButtonSearch, setStateButtonSearch] = useState(null);
  let users = [];

  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setDate(userLog);
        async function buscarUser() {
          const docRef = doc(db, "users", userLog.uid);
          const docSnap = await getDoc(docRef);
          switch (docSnap.data().rol) {
            case "adminFirmax":
              navigate("/firmax");
              break;
            case "inmobiliaria":
              navigate("/inmobiliaria/Lista");
              break;
            default:
              break;
          }
        }
        buscarUser();
      } else {
        setDate(null);
        navigate("/login");
      }
    });
  }, [date, navigate]);

  async function listarUsers() {
    const querySnapshot = await getDocs(collection(db, date.email));
    querySnapshot.forEach((doc) => {
      let user = {
        nombre: doc.data().nombre,
        apellido: doc.data().apellido,
        dni: doc.data().dni,
        mail: doc.data().mail,
        uid: doc.data().uid,
      };
      if (date.email !== doc.data().mail) {
        users.push(user);
      }
    });
    setmostrarUsers(users);
  }

  //Funcion atrapa traget y busca por apellido o dni
  async function handleSubmit(e) {
    e.preventDefault();
    //capturar input campos
    let apellido = e.target.apellido.value;
    const dni = e.target.dni.value;

    if (apellido.length === 0) {
      const querySnapshot = await getDocs(collection(db, date.email));
      querySnapshot.forEach((doc) => {
        const dniDoc = doc.data().dni;
        if (parseInt(dniDoc) === parseInt(dni)) {
          let user = {
            nombre: doc.data().nombre,
            apellido: doc.data().apellido,
            dni: doc.data().dni,
            mail: doc.data().email,
            uid: doc.data().uid,
          };
          users.push(user);
        }
      }); //fin forEach
      setmostrarUsers(users);
    } else {
      const querySnapshot = await getDocs(collection(db, date.email));
      querySnapshot.forEach((doc) => {
        let apellidoDoc = doc.data().apellido;

        if (apellido === apellidoDoc) {
          let user = {
            nombre: doc.data().nombre,
            apellido: doc.data().apellido,
            dni: doc.data().dni,
            mail: doc.data().email,
            uid: doc.data().uid,
          };
          users.push(user);
        }
      }); //fin forEach
      setmostrarUsers(users);
    }
  }

  async function eliminar(uid , mail) {
    console.log(date.email , uid);
 
    await deleteDoc(doc(db, date.email , mail));
    await deleteDoc(doc(db, "users" , uid));
    setmostrarUsers(mostrarUsers.filter((item) => item.mail !== mail));
    Swal.fire({
      title: "Eliminado correctamente",
      icon: "success",
      confirmButtonColor: "#8FCD60",
    });
  }
  return (
    <section className="section-firmax">
      <header className="section-firmax-head">
        <img src={logo} alt="logo_firma" />
      </header>

      <main className="section-firmax-main-lista-users hidden-scroll realitive">
        <div className="section-firmax-main-lista-users-hidden">
          <button onClick={listarUsers}>Mostrar empleados</button>
          <button onClick={() => setStateButtonSearch(true)}>
            Buscar empleado
          </button>
          {stateButtonSearch ? (
            <form
              className="section-firmax-main-lista-users-form"
              onSubmit={handleSubmit}
            >
              <input
                type="text"
                placeholder="Buscar por apellido"
                id="apellido"
              />
              <input type="number" placeholder="Buscar por DNI" id="dni" />
              <button type="submit">Buscar</button>
            </form>
          ) : null}
        </div>

        <div className="section-firmax-inmobiliaria-scroll">
          {mostrarUsers.map((user) => (
            <div
              key={user.dni}
              className="section-fimax-inmobiliaria-scroll-user-contenedor"
            >
              <h2>Nombre: {user.nombre}</h2>
              <h2>Apellido: {user.apellido}</h2>
              <h2>DNI: {user.dni}</h2>
              <h2>E-mail: {user.mail}</h2>
              <h2 className="btn-delete-bd" onClick={() => eliminar(user.uid , user.mail)}>
                Eliminar
              </h2>
            </div>
          ))}
        </div>
        <div
          style={{paddingTop:"1rem"}}
          onClick={() => navigate("/inmobiliaria")}
          className="section-firmax-form-container-back"
        >
          <i className="bx bx-left-arrow-alt padd"></i>
          <p>Volver</p>
        </div>
      </main>
    </section>
  );
};

export default ListaUserInmo;
