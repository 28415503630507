
import { useState } from 'react';
import Swal from 'sweetalert2';

const CuestionarioDos = ({datosCuestionario , setDatosCuestionario , setCuestionario , contratoBD , personas , setPersonas , setearDatosCalculadora, seteo}) => {
    
    const handleOptionChange = (e) =>{
        setDatosCuestionario({
            ...datosCuestionario,
            [e.target.name] : e.target.value,
            peronasLength: personas.length,
            honorariosDeEscritura: 'no'
        })
    }

    const next = (e) => {
        setDatosCuestionario({
            ...datosCuestionario,
            [e.target.name] : e.target.value,
        })

        if (datosCuestionario.resPreguntaCero === "si") {
            setCuestionario(3)
        }else{
            setCuestionario(4)
        }
    }

    const [forms, setFroms] = useState({
        formUno: true,
        formDos: false,
        formTres: false,
        formCuatro: false
    })

    function mostrarLeyendaUno() {
        Swal.fire({
            title: "Teléfono",
            text: "Los datos de contacto son necesarios para la gestión de la solicitud",
            icon:"question",
            confirmButtonColor:"#8FCD60"
        })
    }
    function mostrarLeyendaDos() {
        Swal.fire({
            title: "Fecha aproximada de inicio del empleo actual",
            text: "Sumas de las cuotas por préstamos, hipotecas o pensiones",
            icon:"question",
            confirmButtonColor:"#8FCD60"
        })
    }
    function mostrarLeyendaTres() {
        Swal.fire({
            title: "Ingresos Mensuales",
            text: "Sumas de los ingresos netos demostrables. Laborales, pensiones, rentas inmobiliarias",
            icon:"question",
            confirmButtonColor:"#8FCD60"
        })
    }
    function mostrarLeyendaCuatro() {
        Swal.fire({
            title: "Pago de cuotas y/o pensiones",
            text: "Sumas de las cuotas por préstamos, hipotecas o pensiones",
            icon:"question",
            confirmButtonColor:"#8FCD60"
        })
    }

 
    function agregarSolicitante(num) {
        switch (num) {
            case 2:    
                setFroms({
                    formUno: false,
                    formDos: true,
                    formTres: false,
                    formCuatro: false
                })
                setPersonas([
                    ...personas,
                    num
                ])
                break;
            case 3:
                setFroms({
                    formUno: false,
                    formDos: false,
                    formTres: true,
                    formCuatro: false
                })
                setPersonas([
                    ...personas,
                    num
                ])
                break;
            case 4:
                setFroms({
                    formUno: false,
                    formDos: false,
                    formTres: false,
                    formCuatro: true
                })
                setPersonas([
                    ...personas,
                    4
                ])
                break;
                case 5:
                    console.log(datosCuestionario);
                    break;
            default:
                break;
        }
    }

    function mostrarUsers(num) {
        let res = 0
        if (num === 5) {
            res = 4
        }else{
            res = num
        }
        switch (res) {
            case 1:
                setFroms({
                    formUno: true,
                    formDos: false,
                    formTres: false,
                    formCuatro: false
                })
                break;
            case 2:
                setFroms({
                    formUno: false,
                    formDos: true,
                    formTres: false,
                    formCuatro: false
                })
                break;
            case 3:
                setFroms({
                    formUno: false,
                    formDos: false,
                    formTres: true,
                    formCuatro: false
                })
                break;
            case 4:
                setFroms({
                    formUno: false,
                    formDos: false,
                    formTres: false,
                    formCuatro: true
                })
                break;        
            default:
                break;
        }
    }
    function eliminar(index){
        if (seteo) {
            Swal.fire({
                title: "Lo sentimos",
                text: "Al ser una simulacion recuperada no se podra eliminar los datos",
                icon:"warning",
                confirmButtonColor:"#8FCD60"
            })
        }else{
            let arr = []
            for (let i = 0; i < personas.length; i++) {
                if (index !== i) {
                    arr.push(personas)
                }
            }   
            console.log(index);
            switch (index) {
                case 1:    
                    setFroms({
                        formUno: true,
                        formDos: false,
                        formTres: false,
                        formCuatro: false
                    })
                    setPersonas(arr)
                    datosCuestionario.nombreViabilidadDos = ''
                    datosCuestionario.edadViavilidadDos = datosCuestionario.edadViavilidad
                    datosCuestionario.situacionLaboralDos = ''
                    datosCuestionario.fechaDeIngresoDos = ''
                    datosCuestionario.ingresosNetosMensualesDos = ''
                    datosCuestionario.pagaDeCuotasDos = ''
                    datosCuestionario.pagasAnualesDos = ''
                    break;
                case 2:
                    setFroms({
                        formUno: false,
                        formDos: true,
                        formTres: false,
                        formCuatro: false
                    })
                    setPersonas(arr)
                    datosCuestionario.nombreViabilidadTres = ''
                    datosCuestionario.edadViavilidadTres = datosCuestionario.edadViavilidad
                    datosCuestionario.situacionLaboralTres = ''
                    datosCuestionario.fechaDeIngresoTres = ''
                    datosCuestionario.ingresosNetosMensualesTres = ''
                    datosCuestionario.pagaDeCuotasTres = ''
                    datosCuestionario.pagasAnualesTres = ''
                    break;
                case 3:
                    setFroms({
                        formUno: false,
                        formDos: false,
                        formTres: true,
                        formCuatro: false
                    })
                    setPersonas(arr)
                    datosCuestionario.nombreViabilidadCuatro= ''
                    datosCuestionario.edadViavilidadCuatro= datosCuestionario.edadViavilidad
                    datosCuestionario.situacionLaboralCuatro= ''
                    datosCuestionario.fechaDeIngresoCuatro= ''
                    datosCuestionario.ingresosNetosMensualesCuatro= ''
                    datosCuestionario.pagaDeCuotasCuatro= ''
                    datosCuestionario.pagasAnualesCuatro= ''
                    break;
                    case 5:
                        console.log(datosCuestionario);
                        
                        break;
                default:
                    Swal.fire({
                        title: "No puede borrar el solicitante numero 1",
                        icon:"warning",
                        confirmButtonColor:"#8FCD60"
                    })
                    break;
            }
        }
        

    }
    return ( 
        <section>
       
            <div className='contenedor-solicitantes'>
            {personas.map((persona , index) => (
                <div style={{position:"relative"}}>
                <h5 style={{cursor:"pointer"}} onClick={()=>mostrarUsers(index + 1)}>Solicitante: {index + 1}</h5>
                <span onClick={()=> eliminar(index)}>x</span>
                </div>
            ))}
            </div>
            <section id="cuestionarioUno" className="container-firmax-section-cuestionario">
            <div className='title-calculadora'>
                    <h2 onClick={()=> console.log(datosCuestionario)} className="title-calculadora-sub-principal">Hipoteca:</h2>
                    <h2 className="title-calculadora-sub">{setearDatosCalculadora.nombreInmobiliaria}</h2>
                </div>
                <div className='container-firmax-form-flex'>
                    <h1>Estudio de</h1>
                    <h1 className='container-firmax-form-flex-color'>viabilidad</h1>
                </div>
        
                
                <form className="container-firmax-section-cuestionario-contenedor-form" onSubmit={next}>      
                    {forms.formUno ?
                        <>
                        <h3>Detalla los datos de los solicitantes numero uno.</h3>
                        <div className="container-firmax-div-flex">
                            <label>Nombre</label>
                            <input required className="asd" name="nombreViabilidad" type="text" onChange={handleOptionChange} value={datosCuestionario.nombreViabilidad}/>
                        </div>

                        <div className="container-firmax-div-flex">
                            <label>Apellidos</label>
                            <input required name="apellidoViabilidad" type="text" onChange={handleOptionChange} value={datosCuestionario.apellidoViabilidad}/>
                        </div>
                
                        <div className="container-firmax-div-flex input-select-width">
                            <label>Edad</label>
                            <select name="edadViavilidad" id="" onChange={handleOptionChange} value={datosCuestionario.edadViavilidad}>
                                <option  name="edadViavilidad" value="18"> 18</option>
                                <option  name="edadViavilidad" value="19"> 19</option>
                                <option  name="edadViavilidad" value="20"> 20</option>
                                <option  name="edadViavilidad" value="21"> 21</option>
                                <option  name="edadViavilidad" value="22"> 22</option>
                                <option  name="edadViavilidad" value="23"> 23</option>
                                <option  name="edadViavilidad" value="24"> 24</option>
                                <option  name="edadViavilidad" value="25"> 25</option>
                                <option  name="edadViavilidad" value="26"> 26</option>
                                <option  name="edadViavilidad" value="27"> 27</option>
                                <option  name="edadViavilidad" value="28"> 28</option>
                                <option  name="edadViavilidad" value="29"> 29</option>
                                <option  name="edadViavilidad" value="30"> 30</option>
                                <option  name="edadViavilidad" value="31"> 31</option>
                                <option  name="edadViavilidad" value="32"> 32</option>
                                <option  name="edadViavilidad" value="33"> 33</option>
                                <option  name="edadViavilidad" value="34"> 34</option>
                                <option  name="edadViavilidad" value="35"> 35</option>
                                <option  name="edadViavilidad" value="36"> 36</option>
                                <option  name="edadViavilidad" value="37"> 37</option>
                                <option  name="edadViavilidad" value="38"> 38</option>
                                <option  name="edadViavilidad" value="39"> 39</option>
                                <option  name="edadViavilidad" value="40"> 40</option>
                                <option  name="edadViavilidad" value="41"> 41</option>
                                <option  name="edadViavilidad" value="42"> 42</option>
                                <option  name="edadViavilidad" value="43"> 43</option>
                                <option  name="edadViavilidad" value="44"> 44</option>
                                <option  name="edadViavilidad" value="45"> 45</option>
                                <option  name="edadViavilidad" value="46"> 46</option>
                                <option  name="edadViavilidad" value="47"> 47</option>
                                <option  name="edadViavilidad" value="48"> 48</option>
                                <option  name="edadViavilidad" value="49"> 49</option>
                                <option  name="edadViavilidad" value="50"> 50</option>
                                <option  name="edadViavilidad" value="51"> 51</option>
                                <option  name="edadViavilidad" value="52"> 52</option>
                                <option  name="edadViavilidad" value="53"> 53</option>
                                <option  name="edadViavilidad" value="54"> 54</option>
                                <option  name="edadViavilidad" value="55"> 55</option>
                                <option  name="edadViavilidad" value="56"> 56</option>
                                <option  name="edadViavilidad" value="57"> 57</option>
                                <option  name="edadViavilidad" value="58"> 58</option>
                                <option  name="edadViavilidad" value="59"> 59</option>
                                <option  name="edadViavilidad" value="60"> 60</option>
                                <option  name="edadViavilidad" value="61"> 61</option>
                                <option  name="edadViavilidad" value="62"> 62</option>
                                <option  name="edadViavilidad" value="63"> 63</option>
                                <option  name="edadViavilidad" value="64"> 64</option>
                                <option  name="edadViavilidad" value="65"> 65</option>
                                <option  name="edadViavilidad" value="66"> 66</option>
                                <option  name="edadViavilidad" value="67"> 67</option>
                                <option  name="edadViavilidad" value="68"> 68</option>
                                <option  name="edadViavilidad" value="69"> 69</option>
                                <option  name="edadViavilidad" value="70"> 70</option>
                            </select>
                        </div>

                        <div className="container-firmax-div-flex relative">
                            <label>Teléfono</label>
                            <input required name="telefonoViabilidad" type="number" onChange={handleOptionChange} value={datosCuestionario.telefonoViabilidad}/>
                            <i onClick={mostrarLeyendaUno} className='bx bxs-alarm-exclamation two-absolute'></i>
                        </div>
                        
                        <div className="container-firmax-div-flex input-select-width">
                            <label>Situación laboral</label>
                            <select required name="situacionLaboral" id="" onChange={handleOptionChange} value={datosCuestionario.situacionLaboral}>
                                <option name="situacionLaboral" value=''>-Escoge una opción- *</option>
                                {contratoBD.map((contrato)=> (
                                    <option key={contrato.nombre} value={contrato.nombre}>{contrato.nombre}</option>
                                ))}          
                            </select>
                        </div>

                        <div className="relative">
                            <label>Fecha aproximada de inicio del empleo actual</label>
                            <input required type="date" onChange={handleOptionChange} name="fechaDeIngreso" value={datosCuestionario.fechaDeIngreso}/>
                            <i onClick={mostrarLeyendaDos} className='bx bxs-alarm-exclamation two-absolute-date'></i>
                        </div>
                
                        <div className="container-firmax-div-flex relative">
                            <label>Ingresos mensuales</label>
                            <input required name="ingresosNetosMensuales" type="number" onChange={handleOptionChange} value={datosCuestionario.ingresosNetosMensuales}/>
                            <i onClick={mostrarLeyendaTres} className='bx bxs-alarm-exclamation two-absolute'></i>
                            <h2 style={{position:"absolute" , top:"33px" , left:"60px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                        </div>

                        <div className="container-firmax-div-flex input-select-width">
                            <label>Número de pagas anuales</label>
                            <select required name="pagasAnuales" id="" onChange={handleOptionChange} value={datosCuestionario.pagasAnuales}>
                                <option name="pagasAnuales" value="0"> Elija una opción</option>
                                <option name="pagasAnuales" value="0"> Ninguna</option>
                                <option name="pagasAnuales" value="1"> 1</option>
                                <option name="pagasAnuales" value="2"> 2</option>
                                <option name="pagasAnuales" value="3"> 3</option>
                                <option name="pagasAnuales" value="4"> 4</option>
                                <option name="pagasAnuales" value="5"> 5</option>
                                <option name="pagasAnuales" value="6"> 6</option>
                                <option name="pagasAnuales" value="7"> 7</option>
                                <option name="pagasAnuales" value="8"> 8</option>
                                <option name="pagasAnuales" value="9"> 9</option>
                                <option name="pagasAnuales" value="10"> 10</option>
                                <option name="pagasAnuales" value="11"> 11</option>
                                <option name="pagasAnuales" value="12"> 12</option>
                                <option name="pagasAnuales" value="13"> 13</option>
                                <option name="pagasAnuales" value="14"> 14</option>
                                <option name="pagasAnuales" value="15"> 15</option>
                                <option name="pagasAnuales" value="16"> 16</option>
                            </select> 
                        </div>

                        <div className="container-firmax-div-flex relative">
                            <label>Pago de cuotas y/o pensiones</label>
                            <input required name="pagaDeCuotas" type="number" onChange={handleOptionChange} value={datosCuestionario.pagaDeCuotas}/>
                            <i onClick={mostrarLeyendaCuatro} className='bx bxs-alarm-exclamation two-absolute'></i>
                            <h2 style={{position:"absolute" , top:"34px" , left:"45px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                        </div>
                        {personas.length < 2 ?
                            <h3 onClick={()=>agregarSolicitante(2)} style={{cursor:"pointer" ,textAlign:"start" , color:"#000000a7", fontSize:"15px"}}>Agregar solicitante +</h3>  
                        :null}
                                          
                    </>
                    :null} 
                    
                    {forms.formDos ?
                    <>
                    <h3>Detalla los datos de los solicitantes numero dos.</h3>
                        <div className="container-firmax-div-flex">
                            <label>Nombre</label>
                            <input required className="asd" name="nombreViabilidadDos" type="text" onChange={handleOptionChange} value={datosCuestionario.nombreViabilidadDos}/>
                        </div>
                
                        <div className="container-firmax-div-flex input-select-width">
                            <label>Edad</label>
                            <select name="edadViavilidadDos" id="" onChange={handleOptionChange} value={datosCuestionario.edadViavilidadDos}>
                                <option  name="edadViavilidadDos" value="18"> 18</option>
                                <option  name="edadViavilidadDos" value="19"> 19</option>
                                <option  name="edadViavilidadDos" value="20"> 20</option>
                                <option  name="edadViavilidadDos" value="21"> 21</option>
                                <option  name="edadViavilidadDos" value="22"> 22</option>
                                <option  name="edadViavilidadDos" value="23"> 23</option>
                                <option  name="edadViavilidadDos" value="24"> 24</option>
                                <option  name="edadViavilidadDos" value="25"> 25</option>
                                <option  name="edadViavilidadDos" value="26"> 26</option>
                                <option  name="edadViavilidadDos" value="27"> 27</option>
                                <option  name="edadViavilidadDos" value="28"> 28</option>
                                <option  name="edadViavilidadDos" value="29"> 29</option>
                                <option  name="edadViavilidadDos" value="30"> 30</option>
                                <option  name="edadViavilidadDos" value="31"> 31</option>
                                <option  name="edadViavilidadDos" value="32"> 32</option>
                                <option  name="edadViavilidadDos" value="33"> 33</option>
                                <option  name="edadViavilidadDos" value="34"> 34</option>
                                <option  name="edadViavilidadDos" value="35"> 35</option>
                                <option  name="edadViavilidadDos" value="36"> 36</option>
                                <option  name="edadViavilidadDos" value="37"> 37</option>
                                <option  name="edadViavilidadDos" value="38"> 38</option>
                                <option  name="edadViavilidadDos" value="39"> 39</option>
                                <option  name="edadViavilidadDos" value="40"> 40</option>
                                <option  name="edadViavilidadDos" value="41"> 41</option>
                                <option  name="edadViavilidadDos" value="42"> 42</option>
                                <option  name="edadViavilidadDos" value="43"> 43</option>
                                <option  name="edadViavilidadDos" value="44"> 44</option>
                                <option  name="edadViavilidadDos" value="45"> 45</option>
                                <option  name="edadViavilidadDos" value="46"> 46</option>
                                <option  name="edadViavilidadDos" value="47"> 47</option>
                                <option  name="edadViavilidadDos" value="48"> 48</option>
                                <option  name="edadViavilidadDos" value="49"> 49</option>
                                <option  name="edadViavilidadDos" value="50"> 50</option>
                                <option  name="edadViavilidadDos" value="51"> 51</option>
                                <option  name="edadViavilidadDos" value="52"> 52</option>
                                <option  name="edadViavilidadDos" value="53"> 53</option>
                                <option  name="edadViavilidadDos" value="54"> 54</option>
                                <option  name="edadViavilidadDos" value="55"> 55</option>
                                <option  name="edadViavilidadDos" value="56"> 56</option>
                                <option  name="edadViavilidadDos" value="57"> 57</option>
                                <option  name="edadViavilidadDos" value="58"> 58</option>
                                <option  name="edadViavilidadDos" value="59"> 59</option>
                                <option  name="edadViavilidadDos" value="60"> 60</option>
                                <option  name="edadViavilidadDos" value="61"> 61</option>
                                <option  name="edadViavilidadDos" value="62"> 62</option>
                                <option  name="edadViavilidadDos" value="63"> 63</option>
                                <option  name="edadViavilidadDos" value="64"> 64</option>
                                <option  name="edadViavilidadDos" value="65"> 65</option>
                                <option  name="edadViavilidadDos" value="66"> 66</option>
                                <option  name="edadViavilidadDos" value="67"> 67</option>
                                <option  name="edadViavilidadDos" value="68"> 68</option>
                                <option  name="edadViavilidadDos" value="69"> 69</option>
                                <option  name="edadViavilidadDos" value="70"> 70</option>
                            </select>
                        </div>
                        
                        <div className="container-firmax-div-flex input-select-width">
                            <label>Situación laboral</label>
                            <select required name="situacionLaboralDos" id="" onChange={handleOptionChange} value={datosCuestionario.situacionLaboralDos}>
                                <option name="situacionLaboralDos" value=''>-Escoge una opción- *</option>
                                {contratoBD.map((contrato)=> (
                                    <option key={contrato.nombre} value={contrato.nombre}>{contrato.nombre}</option>
                                ))}          
                            </select>
                        </div>

                        <div className="relative">
                            <label>Fecha aproximada de inicio del empleo actual</label>
                            <input required type="date" onChange={handleOptionChange} name="fechaDeIngresoDos" value={datosCuestionario.fechaDeIngresoDos}/>
                            <i onClick={mostrarLeyendaDos} className='bx bxs-alarm-exclamation two-absolute-date'></i>
                        </div>
                
                        <div className="container-firmax-div-flex relative">
                            <label>Ingresos mensuales</label>
                            <input required name="ingresosNetosMensualesDos" type="number" onChange={handleOptionChange} value={datosCuestionario.ingresosNetosMensualesDos}/>
                            <i onClick={mostrarLeyendaTres} className='bx bxs-alarm-exclamation two-absolute'></i>
                            <h2 style={{position:"absolute" , top:"33px" , left:"60px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                        </div>

                        <div className="container-firmax-div-flex input-select-width">
                            <label>Número de pagas anuales</label>
                            <select required name="pagasAnualesDos" id="" onChange={handleOptionChange} value={datosCuestionario.pagasAnualesDos}>
                                <option name="pagasAnualesDos" value="0"> Elija una opción</option>
                                <option name="pagasAnualesDos" value="0"> Ninguna</option>
                                <option name="pagasAnualesDos" value="1"> 1</option>
                                <option name="pagasAnualesDos" value="2"> 2</option>
                                <option name="pagasAnualesDos" value="3"> 3</option>
                                <option name="pagasAnualesDos" value="4"> 4</option>
                                <option name="pagasAnualesDos" value="5"> 5</option>
                                <option name="pagasAnualesDos" value="6"> 6</option>
                                <option name="pagasAnualesDos" value="7"> 7</option>
                                <option name="pagasAnualesDos" value="8"> 8</option>
                                <option name="pagasAnualesDos" value="9"> 9</option>
                                <option name="pagasAnualesDos" value="10"> 10</option>
                                <option name="pagasAnualesDos" value="11"> 11</option>
                                <option name="pagasAnualesDos" value="12"> 12</option>
                                <option name="pagasAnualesDos" value="13"> 13</option>
                                <option name="pagasAnualesDos" value="14"> 14</option>
                                <option name="pagasAnualesDos" value="15"> 15</option>
                                <option name="pagasAnualesDos" value="16"> 16</option>
                            </select> 
                        </div>

                        <div className="container-firmax-div-flex relative">
                            <label>Pago de cuotas y/o pensiones</label>
                            <input required name="pagaDeCuotasDos" type="number" onChange={handleOptionChange} value={datosCuestionario.pagaDeCuotasDos}/>
                            <i onClick={mostrarLeyendaCuatro} className='bx bxs-alarm-exclamation two-absolute'></i>
                            <h2 style={{position:"absolute" , top:"34px" , left:"45px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                        </div>
                        {personas.length < 3 
                        ?  <h3 onClick={()=>agregarSolicitante(3)} style={{cursor:"pointer" ,textAlign:"start" , color:"#000000a7", fontSize:"15px"}}>Agregar solicitante +</h3> 
                        : null}
                                          
                    </>
                    : null}

                    {forms.formTres ?
                    <>
                    <h3>Detalla los datos de los solicitantes numero tres.</h3>
                        <div className="container-firmax-div-flex">
                            <label>Nombre</label>
                            <input required className="asd" name="nombreViabilidadTres" type="text" onChange={handleOptionChange} value={datosCuestionario.nombreViabilidadTres}/>
                        </div>
                
                        <div className="container-firmax-div-flex input-select-width">
                            <label>Edad</label>
                            <select name="edadViavilidadTres" id="" onChange={handleOptionChange} value={datosCuestionario.edadViavilidadTres}>
                                <option  name="edadViavilidadTres" value="18"> 18</option>
                                <option  name="edadViavilidadTres" value="19"> 19</option>
                                <option  name="edadViavilidadTres" value="20"> 20</option>
                                <option  name="edadViavilidadTres" value="21"> 21</option>
                                <option  name="edadViavilidadTres" value="22"> 22</option>
                                <option  name="edadViavilidadTres" value="23"> 23</option>
                                <option  name="edadViavilidadTres" value="24"> 24</option>
                                <option  name="edadViavilidadTres" value="25"> 25</option>
                                <option  name="edadViavilidadTres" value="26"> 26</option>
                                <option  name="edadViavilidadTres" value="27"> 27</option>
                                <option  name="edadViavilidadTres" value="28"> 28</option>
                                <option  name="edadViavilidadTres" value="29"> 29</option>
                                <option  name="edadViavilidadTres" value="30"> 30</option>
                                <option  name="edadViavilidadTres" value="31"> 31</option>
                                <option  name="edadViavilidadTres" value="32"> 32</option>
                                <option  name="edadViavilidadTres" value="33"> 33</option>
                                <option  name="edadViavilidadTres" value="34"> 34</option>
                                <option  name="edadViavilidadTres" value="35"> 35</option>
                                <option  name="edadViavilidadTres" value="36"> 36</option>
                                <option  name="edadViavilidadTres" value="37"> 37</option>
                                <option  name="edadViavilidadTres" value="38"> 38</option>
                                <option  name="edadViavilidadTres" value="39"> 39</option>
                                <option  name="edadViavilidadTres" value="40"> 40</option>
                                <option  name="edadViavilidadTres" value="41"> 41</option>
                                <option  name="edadViavilidadTres" value="42"> 42</option>
                                <option  name="edadViavilidadTres" value="43"> 43</option>
                                <option  name="edadViavilidadTres" value="44"> 44</option>
                                <option  name="edadViavilidadTres" value="45"> 45</option>
                                <option  name="edadViavilidadTres" value="46"> 46</option>
                                <option  name="edadViavilidadTres" value="47"> 47</option>
                                <option  name="edadViavilidadTres" value="48"> 48</option>
                                <option  name="edadViavilidadTres" value="49"> 49</option>
                                <option  name="edadViavilidadTres" value="50"> 50</option>
                                <option  name="edadViavilidadTres" value="51"> 51</option>
                                <option  name="edadViavilidadTres" value="52"> 52</option>
                                <option  name="edadViavilidadTres" value="53"> 53</option>
                                <option  name="edadViavilidadTres" value="54"> 54</option>
                                <option  name="edadViavilidadTres" value="55"> 55</option>
                                <option  name="edadViavilidadTres" value="56"> 56</option>
                                <option  name="edadViavilidadTres" value="57"> 57</option>
                                <option  name="edadViavilidadTres" value="58"> 58</option>
                                <option  name="edadViavilidadTres" value="59"> 59</option>
                                <option  name="edadViavilidadTres" value="60"> 60</option>
                                <option  name="edadViavilidadTres" value="61"> 61</option>
                                <option  name="edadViavilidadTres" value="62"> 62</option>
                                <option  name="edadViavilidadTres" value="63"> 63</option>
                                <option  name="edadViavilidadTres" value="64"> 64</option>
                                <option  name="edadViavilidadTres" value="65"> 65</option>
                                <option  name="edadViavilidadTres" value="66"> 66</option>
                                <option  name="edadViavilidadTres" value="67"> 67</option>
                                <option  name="edadViavilidadTres" value="68"> 68</option>
                                <option  name="edadViavilidadTres" value="69"> 69</option>
                                <option  name="edadViavilidadTres" value="70"> 70</option>
                            </select>
                        </div>
                        
                        <div className="container-firmax-div-flex input-select-width">
                            <label>Situación laboral</label>
                            <select required name="situacionLaboralTres" id="" onChange={handleOptionChange} value={datosCuestionario.situacionLaboralTres}>
                                <option name="situacionLaboralTres" value=''>-Escoge una opción- *</option>
                                {contratoBD.map((contrato)=> (
                                    <option name="situacionLaboralTres" key={contrato.nombre} value={contrato.nombre}>{contrato.nombre}</option>
                                ))}          
                            </select>
                        </div>

                        <div className="relative">
                            <label>Fecha aproximada de inicio del empleo actual</label>
                            <input required type="date" onChange={handleOptionChange} name="fechaDeIngresoTres" value={datosCuestionario.fechaDeIngresoTres}/>
                            <i onClick={mostrarLeyendaTres} className='bx bxs-alarm-exclamation two-absolute-date'></i>
                        </div>
                
                        <div className="container-firmax-div-flex relative">
                            <label>Ingresos mensuales</label>
                            <input required name="ingresosNetosMensualesTres" type="number" onChange={handleOptionChange} value={datosCuestionario.ingresosNetosMensualesTres}/>
                            <i onClick={mostrarLeyendaTres} className='bx bxs-alarm-exclamation two-absolute'></i>
                            <h2 style={{position:"absolute" , top:"33px" , left:"60px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                        </div>

                        <div className="container-firmax-div-flex input-select-width">
                            <label>Número de pagas anuales</label>
                            <select required name="pagasAnualesTres" id="" onChange={handleOptionChange} value={datosCuestionario.pagasAnualesTres}>
                                <option name="pagasAnualesTres" value="0"> Elija una opción</option>
                                <option name="pagasAnualesTres" value="0"> Ninguna</option>
                                <option name="pagasAnualesTres" value="1"> 1</option>
                                <option name="pagasAnualesTres" value="2"> 2</option>
                                <option name="pagasAnualesTres" value="3"> 3</option>
                                <option name="pagasAnualesTres" value="4"> 4</option>
                                <option name="pagasAnualesTres" value="5"> 5</option>
                                <option name="pagasAnualesTres" value="6"> 6</option>
                                <option name="pagasAnualesTres" value="7"> 7</option>
                                <option name="pagasAnualesTres" value="8"> 8</option>
                                <option name="pagasAnualesTres" value="9"> 9</option>
                                <option name="pagasAnualesTres" value="10"> 10</option>
                                <option name="pagasAnualesTres" value="11"> 11</option>
                                <option name="pagasAnualesTres" value="12"> 12</option>
                                <option name="pagasAnualesTres" value="13"> 13</option>
                                <option name="pagasAnualesTres" value="14"> 14</option>
                                <option name="pagasAnualesTres" value="15"> 15</option>
                                <option name="pagasAnualesTres" value="16"> 16</option>
                            </select> 
                        </div>

                        <div className="container-firmax-div-flex relative">
                            <label>Pago de cuotas y/o pensiones</label>
                            <input required name="pagaDeCuotasTres" type="number" onChange={handleOptionChange} value={datosCuestionario.pagaDeCuotasTres}/>
                            <i onClick={mostrarLeyendaCuatro} className='bx bxs-alarm-exclamation two-absolute'></i>
                            <h2 style={{position:"absolute" , top:"34px" , left:"45px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                        </div>
                        {personas.length < 4
                        ?  <h3 onClick={()=>agregarSolicitante(4)} style={{cursor:"pointer" ,textAlign:"start" , color:"#000000a7", fontSize:"15px"}}>Agregar solicitante +</h3> 
                        : null}             
                    </>
                    :null}

                    {forms.formCuatro ?
                    <>
                    <h3>Detalla los datos de los solicitantes numero cuatro.</h3>
                        <div className="container-firmax-div-flex">
                            <label>Nombre</label>
                            <input required className="asd" name="nombreViabilidadCuatro" type="text" onChange={handleOptionChange} value={datosCuestionario.nombreViabilidadCuatro}/>
                        </div>
                
                        <div className="container-firmax-div-flex input-select-width">
                            <label>Edad</label>
                            <select name="edadViavilidadCuatro" id="" onChange={handleOptionChange} value={datosCuestionario.edadViavilidadCuatro}>
                                <option  name="edadViavilidadCuatro" value="18"> 18</option>
                                <option  name="edadViavilidadCuatro" value="19"> 19</option>
                                <option  name="edadViavilidadCuatro" value="20"> 20</option>
                                <option  name="edadViavilidadCuatro" value="21"> 21</option>
                                <option  name="edadViavilidadCuatro" value="22"> 22</option>
                                <option  name="edadViavilidadCuatro" value="23"> 23</option>
                                <option  name="edadViavilidadCuatro" value="24"> 24</option>
                                <option  name="edadViavilidadCuatro" value="25"> 25</option>
                                <option  name="edadViavilidadCuatro" value="26"> 26</option>
                                <option  name="edadViavilidadCuatro" value="27"> 27</option>
                                <option  name="edadViavilidadCuatro" value="28"> 28</option>
                                <option  name="edadViavilidadCuatro" value="29"> 29</option>
                                <option  name="edadViavilidadCuatro" value="30"> 30</option>
                                <option  name="edadViavilidadCuatro" value="31"> 31</option>
                                <option  name="edadViavilidadCuatro" value="32"> 32</option>
                                <option  name="edadViavilidadCuatro" value="33"> 33</option>
                                <option  name="edadViavilidadCuatro" value="34"> 34</option>
                                <option  name="edadViavilidadCuatro" value="35"> 35</option>
                                <option  name="edadViavilidadCuatro" value="36"> 36</option>
                                <option  name="edadViavilidadCuatro" value="37"> 37</option>
                                <option  name="edadViavilidadCuatro" value="38"> 38</option>
                                <option  name="edadViavilidadCuatro" value="39"> 39</option>
                                <option  name="edadViavilidadCuatro" value="40"> 40</option>
                                <option  name="edadViavilidadCuatro" value="41"> 41</option>
                                <option  name="edadViavilidadCuatro" value="42"> 42</option>
                                <option  name="edadViavilidadCuatro" value="43"> 43</option>
                                <option  name="edadViavilidadCuatro" value="44"> 44</option>
                                <option  name="edadViavilidadCuatro" value="45"> 45</option>
                                <option  name="edadViavilidadCuatro" value="46"> 46</option>
                                <option  name="edadViavilidadCuatro" value="47"> 47</option>
                                <option  name="edadViavilidadCuatro" value="48"> 48</option>
                                <option  name="edadViavilidadCuatro" value="49"> 49</option>
                                <option  name="edadViavilidadCuatro" value="50"> 50</option>
                                <option  name="edadViavilidadCuatro" value="51"> 51</option>
                                <option  name="edadViavilidadCuatro" value="52"> 52</option>
                                <option  name="edadViavilidadCuatro" value="53"> 53</option>
                                <option  name="edadViavilidadCuatro" value="54"> 54</option>
                                <option  name="edadViavilidadCuatro" value="55"> 55</option>
                                <option  name="edadViavilidadCuatro" value="56"> 56</option>
                                <option  name="edadViavilidadCuatro" value="57"> 57</option>
                                <option  name="edadViavilidadCuatro" value="58"> 58</option>
                                <option  name="edadViavilidadCuatro" value="59"> 59</option>
                                <option  name="edadViavilidadCuatro" value="60"> 60</option>
                                <option  name="edadViavilidadCuatro" value="61"> 61</option>
                                <option  name="edadViavilidadCuatro" value="62"> 62</option>
                                <option  name="edadViavilidadCuatro" value="63"> 63</option>
                                <option  name="edadViavilidadCuatro" value="64"> 64</option>
                                <option  name="edadViavilidadCuatro" value="65"> 65</option>
                                <option  name="edadViavilidadCuatro" value="66"> 66</option>
                                <option  name="edadViavilidadCuatro" value="67"> 67</option>
                                <option  name="edadViavilidadCuatro" value="68"> 68</option>
                                <option  name="edadViavilidadCuatro" value="69"> 69</option>
                                <option  name="edadViavilidadCuatro" value="70"> 70</option>
                            </select>
                        </div>
                        
                        <div className="container-firmax-div-flex input-select-width">
                            <label>Situación laboral</label>
                            <select required name="situacionLaboralCuatro" id="" onChange={handleOptionChange} value={datosCuestionario.situacionLaboralCuatro}>
                                <option name="situacionLaboralCuatro" value=''>-Escoge una opción- *</option>
                                {contratoBD.map((contrato)=> (
                                    <option name="situacionLaboralCuatro" key={contrato.nombre} value={contrato.nombre}>{contrato.nombre}</option>
                                ))}          
                            </select>
                        </div>

                        <div className="relative">
                            <label>Fecha aproximada de inicio del empleo actual</label>
                            <input required type="date" onChange={handleOptionChange} name="fechaDeIngresoCuatro" value={datosCuestionario.fechaDeIngresoCuatro}/>
                            <i onClick={mostrarLeyendaCuatro} className='bx bxs-alarm-exclamation two-absolute-date'></i>
                        </div>
                
                        <div className="container-firmax-div-flex relative">
                            <label>Ingresos mensuales</label>
                            <input required name="ingresosNetosMensualesCuatro" type="number" onChange={handleOptionChange} value={datosCuestionario.ingresosNetosMensualesCuatro}/>
                            <i onClick={mostrarLeyendaCuatro} className='bx bxs-alarm-exclamation two-absolute'></i>
                            <h2 style={{position:"absolute" , top:"33px" , left:"60px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                        </div>

                        <div className="container-firmax-div-flex input-select-width">
                            <label>Número de pagas anuales</label>
                            <select required name="pagasAnualesCuatro" id="" onChange={handleOptionChange} value={datosCuestionario.pagasAnualesCuatro}>
                                <option name="pagasAnualesCuatro" value="0"> Elija una opción</option>
                                <option name="pagasAnualesCuatro" value="0"> Ninguna</option>
                                <option name="pagasAnualesCuatro" value="1"> 1</option>
                                <option name="pagasAnualesCuatro" value="2"> 2</option>
                                <option name="pagasAnualesCuatro" value="3"> 3</option>
                                <option name="pagasAnualesCuatro" value="4"> 4</option>
                                <option name="pagasAnualesCuatro" value="5"> 5</option>
                                <option name="pagasAnualesCuatro" value="6"> 6</option>
                                <option name="pagasAnualesCuatro" value="7"> 7</option>
                                <option name="pagasAnualesCuatro" value="8"> 8</option>
                                <option name="pagasAnualesCuatro" value="9"> 9</option>
                                <option name="pagasAnualesCuatro" value="10"> 10</option>
                                <option name="pagasAnualesCuatro" value="11"> 11</option>
                                <option name="pagasAnualesCuatro" value="12"> 12</option>
                                <option name="pagasAnualesCuatro" value="13"> 13</option>
                                <option name="pagasAnualesCuatro" value="14"> 14</option>
                                <option name="pagasAnualesCuatro" value="15"> 15</option>
                                <option name="pagasAnualesCuatro" value="16"> 16</option>
                            </select> 
                        </div>

                        <div className="container-firmax-div-flex relative">
                            <label>Pago de cuotas y/o pensiones</label>
                            <input required name="pagaDeCuotasCuatro" type="number" onChange={handleOptionChange} value={datosCuestionario.pagaDeCuotasCuatro}/>
                            <i onClick={mostrarLeyendaCuatro} className='bx bxs-alarm-exclamation two-absolute'></i>
                            <h2 style={{position:"absolute" , top:"34px" , left:"45px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                        </div>         
                    </>
                    :null}

                    <div style={{alignItems:"center" , justifyContent:"center"}} className="container-firmax-section-fija-flex">
                        <button
                            className="container-firmax-section-fija-button margin-buttom-cuest" 
                            onClick={()=>setCuestionario(1)}>Atras
                        </button>
                        <button
                            type="submit"
                            className="container-firmax-section-fija-button margin-buttom-cuest" 
                        >Siguiente
                        </button>
                    </div>       
                </form>
               
            </section> 
        </section>
     );
}
 
export default CuestionarioDos;