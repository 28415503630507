import logo from '../img/logo.png'
import imgTeam from '../img/imgTeam.png'
import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from '../Firebase/Credenciales'
import { useState } from 'react';

const Login = () => {

    const [error , setError] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault()
        //capturar input campos
        const mail = e.target.mail.value
        const pass = e.target.password.value
        //funcion inicio de session (con parametros)
        iniciarSesion(mail , pass)
    }

    //INICIO DE SESSION FUNCION
    const iniciarSesion = (mail , pass) => {
        signInWithEmailAndPassword(auth ,mail, pass).then((userCredential) => {
            if (userCredential) {
                console.log(userCredential)
            }
        })
        .catch(() => {setError("Usuario y/o contraseña incorrecto")});
    }

    return ( 
        <section className='container-section-login'>
            <div className='container-section-login-info'>
                <div className='container-section-login-info-form-info'>
                    <img src={logo} alt="logo_img" />
                    <form className='container-section-login-info-form' onSubmit={handleSubmit}>
                        <input type="email" placeholder="Nombre" id="mail" />
                        <input type="password" placeholder="Contraseña" id="password"/>
                        <button type="submit">Log in</button>
                        {error}
                    </form>
                </div>

                <img src={imgTeam} alt="" />
            </div>
        </section>
     );
}
 
export default Login;