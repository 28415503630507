import logo from "../../../img/logo.png";
import { db } from "../../../Firebase/Credenciales";
import { doc, deleteDoc } from "firebase/firestore";
import Swal from "sweetalert2";

const VerInmobiliarias = ({
  mostrarUsers,
  setMostrarInmo,
  setVerInmos,
  setmostrarUsers,
}) => {


  function volver() {
    setMostrarInmo(true);
    setVerInmos(false);
  }

  async function eliminar(uid) {
    await deleteDoc(doc(db, "inmobiliarias", uid));

    setmostrarUsers(mostrarUsers.filter((item) => item.uid !== uid));
    Swal.fire({
      title: "Eliminado correctamente",
      icon: "success",
      confirmButtonColor: "#8FCD60",
    });
  }

  return (
    <section className="section-firmax">
      <header className="section-firmax-head">
        <img src={logo} alt="logo_firma" />
      </header>

      <main className="section-firmax-main-lista-users hidden-scroll realitive">
        <div className="section-firmax-inmobiliaria-scroll">
          {mostrarUsers.map((user) => (
            <div
              key={user.dni}
              className="section-fimax-inmobiliaria-scroll-user-contenedor"
            >
              <h2>Nombre: {user.nombre}</h2>
              <h2>Razon social: {user.razonSocial}</h2>
              <h2>Telefono: {user.telefono}</h2>
              <h2>DNI: {user.dni}</h2>
              <h2>E-mail: {user.mail}</h2>
              <h2 className="btn-delete-bd" onClick={() => eliminar(user.uid)}>
                Eliminar
              </h2>
            </div>
          ))}
        </div>
        <div
          onClick={volver}
          style={{paddingTop:"3rem"}}
          className="section-firmax-form-container-back"
        >
          <i className="bx bx-left-arrow-alt padd"></i>
          <p>Volver</p>
        </div>
      </main>
    </section>
  );
};

export default VerInmobiliarias;
