import { useEffect, useState } from "react";
import CuestionarioCinco from "./CuestionarioCinco";
import Swal from "sweetalert2";

const CuestionarioTresBis = ({
  datosCuestionario,
  setDatosCuestionario,
  setCuestionario,
  financiacion,
  contratoBD,
  setFinanciacion,
  setearDatosCalculadora,
  date,
  seteoDeDatosUser
}) => {
  const [financiacionPorcen] = useState(0);
  const [modalDeCarga, setModalDeCarga] = useState(true);
  const [varIincremento, setVarIincremento] = useState(0);
  const [advertencia, setAdvertencia] = useState(false);
  //placeHolder

  const [opacity, setOpacity] = useState(1);

  const [honorariosMasEscritura, setHonorariosMasEscritura] = useState(
    parseInt(datosCuestionario.resTres)
  );

  // const [opacityEscritura , setOpacityEscritura] = useState(0)
  const [datosSegundaGarantia, setDatosSegundaGarantia] = useState({});

  const [honariosGestion, setHonorariosGestion] = useState(
    setearDatosCalculadora.comisionMinima
  );

  const [porcetajeEscenario, setPorcetajeEscenario] = useState();

  const [mostrarCuadros, setMostrarCuadros] = useState(true);

  const handleOptionChange = (e) => {
    setDatosCuestionario({
      ...datosCuestionario,
      [e.target.name]: e.target.value,
    });
  };

  const handleOptionChangeTwo = (e) => {
    setDatosSegundaGarantia({
      ...datosSegundaGarantia,
      [e.target.name]: e.target.value,
    });
  };

  function ocultar() {
    if (opacity === 1) {
      setOpacity(0);
    } else {
      setOpacity(1);
    }
  }

  function next(e) {
    console.log(datosCuestionario.honorariosDeEscritura);
    if (datosCuestionario.honorariosDeEscritura != 'null') {
      setDatosCuestionario({
        ...datosCuestionario,
        [e.target.name]: e.target.value,
        hipotecaSolicitadaHonarios:
          parseInt(datosCuestionario.hipotecaSolicitada) +
          parseInt(honariosGestion),
        honorariosDeGestion: parseInt(honariosGestion),
        hipotecaSolicitada: datosCuestionario.hipotecaSolicitada,
        hipotecaMasComisionMasHonorarios: honorariosMasEscritura,
        hipotecaSolicitadaConHonorarios:
          datosCuestionario.hipotecaSolicitadaConHonorarios,
        porcentajeMem: datosCuestionario.porcentaje,
        financiacionPorcen: datosCuestionario.porcentaje,
        segundaGarantia: datosSegundaGarantia.segundaGarantia,
        valorAproxSegundaGarantia: datosSegundaGarantia.valorAproxSegundaGarantia,
  
        totalAcobrar: total,
      });
      setMostrarCuadros(false);
      setModalDeCarga(true);
    } else {
      console.log('soy null next');
      e.preventDefault();
      Swal.fire({
        title: "Error",
        text: "Debe indicar si desea incluir los honorarios en escritura.",
        icon: "error",
        confirmButtonColor: "#8FCD60",
      });
    }
    
  }

  function advertenciaSgundaGarantia(e) {
    // console.log(proce);
    // console.log(datosCuestionario.porcentajeMem + " mem");
    if (datosCuestionario.honorariosDeEscritura != 'null') {
      if (datosCuestionario.porcentajeMem === undefined) {
        datosCuestionario.porcentajeMem = 80;
        setProce(80);
      }
      e.preventDefault();
      if (
        Math.ceil(datosCuestionario.porcentajeMem) >=
          parseInt(setearDatosCalculadora.delEscnario0) &&
        Math.ceil(datosCuestionario.porcentajeMem) <=
          parseInt(setearDatosCalculadora.alEscnario0)
      ) {
        setParamsAdevertencia(datosCuestionario.situacionLaboral, 0, e);
        setPorcetajeEscenario(setearDatosCalculadora.delEscnario0);
      } else if (
        Math.ceil(datosCuestionario.porcentajeMem) >=
          parseInt(setearDatosCalculadora.delEscnario1) &&
        Math.ceil(datosCuestionario.porcentajeMem) <=
          parseInt(setearDatosCalculadora.alEscnario1)
      ) {
        setParamsAdevertencia(datosCuestionario.situacionLaboral, 1, e);
        setPorcetajeEscenario(setearDatosCalculadora.delEscnario1);
      } else if (
        Math.ceil(datosCuestionario.porcentajeMem) >=
          parseInt(setearDatosCalculadora.delEscnario2) &&
        Math.ceil(datosCuestionario.porcentajeMem) <=
          parseInt(setearDatosCalculadora.alEscnario2)
      ) {
        setParamsAdevertencia(datosCuestionario.situacionLaboral, 2, e);
        setPorcetajeEscenario(setearDatosCalculadora.delEscnario2);
      } else if (
        Math.ceil(datosCuestionario.porcentajeMem) >=
          parseInt(setearDatosCalculadora.delEscnario3) &&
        Math.ceil(datosCuestionario.porcentajeMem) <=
          parseInt(setearDatosCalculadora.alEscnario3)
      ) {
        setParamsAdevertencia(datosCuestionario.situacionLaboral, 3, e);
        setPorcetajeEscenario(setearDatosCalculadora.delEscnario3);
      } else if (
        Math.ceil(datosCuestionario.porcentajeMem) >=
          parseInt(setearDatosCalculadora.delEscnario4) &&
        Math.ceil(datosCuestionario.porcentajeMem) <=
          parseInt(setearDatosCalculadora.alEscnario4)
      ) {
        setParamsAdevertencia(datosCuestionario.situacionLaboral, 4, e);
        setPorcetajeEscenario(setearDatosCalculadora.delEscnario4);
      } else if (
        Math.ceil(datosCuestionario.porcentajeMem) >=
          parseInt(setearDatosCalculadora.delEscnario5) &&
        Math.ceil(datosCuestionario.porcentajeMem) <=
          parseInt(setearDatosCalculadora.alEscnario5)
      ) {
        setParamsAdevertencia(datosCuestionario.situacionLaboral, 5, e);
        setPorcetajeEscenario(setearDatosCalculadora.delEscnario5);
      } else if (
        Math.ceil(datosCuestionario.porcentajeMem) >=
          parseInt(setearDatosCalculadora.delEscnario6) &&
        Math.ceil(datosCuestionario.porcentajeMem) <=
          parseInt(setearDatosCalculadora.alEscnario6)
      ) {
        setParamsAdevertencia(datosCuestionario.situacionLaboral, 6, e);
        setPorcetajeEscenario(setearDatosCalculadora.delEscnario6);
      } else if (
        Math.ceil(datosCuestionario.porcentajeMem) >=
          parseInt(setearDatosCalculadora.delEscnario7) &&
        Math.ceil(datosCuestionario.porcentajeMem) <=
          parseInt(setearDatosCalculadora.alEscnario7)
      ) {
        setParamsAdevertencia(datosCuestionario.situacionLaboral, 7, e);
        setPorcetajeEscenario(setearDatosCalculadora.delEscnario7);
      }
    } else {
      e.preventDefault();
      Swal.fire({
        title: "Error",
        text: "Debe indicar si desea incluir los honorarios en escritura.",
        icon: "error",
        confirmButtonColor: "#8FCD60",
      });
    }
   
  }

  function setParamsAdevertencia(contratoName, numEsc, e) {
    for (let i = 0; i < contratoBD.length; i++) {
      if (contratoName === contratoBD[i].nombre) {
        mostrarAdvert(contratoBD[i].id, numEsc, e);
      }
    }
  }
  const [btn, setBtn] = useState(true);

  function mostrarAdvert(id, numEsc, e) {
    // console.log(hola + asd);

    // console.log(setearDatosCalculadora[`delEscnario${numEsc}Limite${id}`]);
    if (setearDatosCalculadora[`delEscnario${numEsc}Limite${id}`] === "si") {
      setAdvertencia(true);
      setBtn(!btn);
    } else {
      next(e);
      console.log("asd");
    }
  }

  //total es honoraros de gestion
  const [total, setTotal] = useState(0);
  //contador para entrar a el primer click
  const [count, setCount] = useState(0);

  function sumarRestar(target) {
    // setearDatosCalculadora.
    switch (target) {
      case "suma":
        console.log(datosCuestionario.porcentaje + " proc");
        if (proce >= parseInt(setearDatosCalculadora.porcentajeDelimitante)) {
          console.log("2keko");
          let res =
            (parseInt(setearDatosCalculadora.comisionExtraIncremento) * 500) /
            100;
          setHonorariosGestion(parseInt(honariosGestion) + res);
          setTotal(total + 500);
          setVarIincremento(varIincremento + res);
          setCount(count + 1);
        } else {
          if (count === 0) {
            setHonorariosGestion(parseInt(honariosGestion) + 500);
            setTotal(total + 500);
            setCount(count + 1);
          } else {
            let res =
              (parseInt(setearDatosCalculadora.comisionExtraIncremento) * 500) /
              100;

            setHonorariosGestion(parseInt(honariosGestion) + res);
            setTotal(total + 500);
            setVarIincremento(varIincremento + res);
            setCount(count + 1);
          }
        }
        break;

      case "resta":
        if (
          datosCuestionario.porcentajeMem >=
          parseInt(setearDatosCalculadora.porcentajeDelimitante)
        ) {
          if (
            parseInt(honariosGestion) <= 500 ||
            parseInt(honariosGestion) <= 750
          ) {
            setHonorariosGestion(500);
            setVarIincremento(0);
            setTotal(parseInt(setearDatosCalculadora.honorariosMaximoInferior));
            setCount(0);
          } else {
            console.log("asd");
            let res =
              (parseInt(setearDatosCalculadora.comisionExtraIncremento) * 500) /
              100;
            setHonorariosGestion(parseInt(honariosGestion) - res);
            setTotal(total - res);
            setVarIincremento(varIincremento - res);
            setCount(count - 1);
          }
        } else {
          console.log("aca soy menor 11 del 6");

          if (
            parseInt(honariosGestion) <= 500 ||
            parseInt(honariosGestion) <= 750 ||
            count === 1
          ) {
            console.log("entro");
            setHonorariosGestion(500);
            setVarIincremento(0);
            setTotal(parseInt(setearDatosCalculadora.honorariosMinimoInferior));
            setCount(0);
            setPrimera(true);
          } else {
            console.log("aca soy menor 11 del 6 PARTE DOS");
            let res =
              (parseInt(setearDatosCalculadora.comisionPrimeraIncremnto) *
                500) /
              100;
            console.log("res: " + res);
            setHonorariosGestion(parseInt(honariosGestion) - res / 2);

            setTotal(total - res);
            setCount(count - 1);
          }
        }
        break;
      default:
        break;
    }
  }

  //aca seteamos el valor del select de %
  const [proce, setProce] = useState();
  const [primera, setPrimera] = useState(true);
  useEffect(() => {
    if (datosCuestionario.honorariosDeEscritura === "si") {
      setHonorariosMasEscritura(
        parseInt(datosCuestionario.resTres) + parseInt(total)
      );
    } else {
      setHonorariosMasEscritura(parseInt(datosCuestionario.resTres));
    }

    if (
      parseInt(datosCuestionario.porcentajeMem) >
      parseInt(setearDatosCalculadora.porcentajeDelimitante)
    ) {
      setTotal(
        parseInt(setearDatosCalculadora.honorariosMaximoInferior) +
          count * 500 +
          parseInt(setearDatosCalculadora.comisionMinima)
        // +
        // varIincremento +
        // parseInt(honariosGestion)
      );
      setPrimera(false);
    } else {
      setTotal(
        parseInt(setearDatosCalculadora.honorariosMinimoInferior) +
          count * 500 +
          parseInt(setearDatosCalculadora.comisionMinima)
        // +
        // varIincremento +
        // parseInt(honariosGestion)
      );
      setPrimera(false);
    }

    if (datosCuestionario.porcentajeMem === undefined) {
      datosCuestionario.hipotecaSolicitada =
        (80 * parseInt(datosCuestionario.resTres)) / 100;
      datosCuestionario.porcentaje = 80;
    } else {
      datosCuestionario.hipotecaSolicitadaConHonorarios =
        (parseInt(datosCuestionario.porcentajeMem) *
          parseInt(datosCuestionario.resTres)) /
        100;
    }

    if (datosCuestionario.porcentajeMem === undefined) {
      datosCuestionario.hipotecaSolicitadaConHonorarios =
        (80 * (parseInt(datosCuestionario.resTres) + total)) / 100;
    } else {
      datosCuestionario.hipotecaSolicitadaConHonorarios =
        (parseInt(datosCuestionario.porcentajeMem) *
          (parseInt(datosCuestionario.resTres) + total)) /
        100;
    }

    // console.log(datosCuestionario.hipotecaSolicitadaConHonorarios);
    // console.log(datosCuestionario.porcentajeMem + 1 );

    if (
      datosCuestionario.hipotecaSolicitadaConHonorarios <
        parseInt(setearDatosCalculadora.hipotecaMinima) ||
      datosCuestionario.hipotecaSolicitadaConHonorarios >
        parseInt(setearDatosCalculadora.hipotecaMaxima)
    ) {
      console.log("minmo activo");
    }
  }, [
    count,
    datosCuestionario,
    datosCuestionario.hipotecaSolicitada,
    datosCuestionario.honorariosDeEscritura,
    datosCuestionario.resTres,
    financiacionPorcen,
    honariosGestion,
    honorariosMasEscritura,
    primera,
    proce,
    setearDatosCalculadora.comisionMinima,
    setearDatosCalculadora.hipotecaMaxima,
    setearDatosCalculadora.hipotecaMinima,
    setearDatosCalculadora.honorariosMaximoInferior,
    setearDatosCalculadora.honorariosMinimoInferior,
    setearDatosCalculadora.porcentajeDelimitante,
    total,
    varIincremento,
  ]);

  function mostrarLeyendaUno() {
    Swal.fire({
      title: "Hipoteca Solicitada",
      text: "Detalla el porcentaje que quieres financiar.",
      icon: "question",
      confirmButtonColor: "#8FCD60",
    });
  }
  function mostrarLeyendaDos() {
    Swal.fire({
      title: "Incluir Honararios en Escritura",
      text: "Detalla si quieres incluir los honorarios en el importe de escritura sumándolos a la hipoteca solicitada.",
      icon: "question",
      confirmButtonColor: "#8FCD60",
    });
  }

  function volverAsetear(e) {
    datosCuestionario.porcentajeMem = e;
    setPrimera(true);
    setProce(e);
    console.log(proce);
    if (datosCuestionario.honorariosDeEscritura === "si") {
      datosCuestionario.hipotecaSolicitada =
        (parseInt(e) * parseInt(honorariosMasEscritura)) / 100;
    } else {
      datosCuestionario.hipotecaSolicitada =
        (parseInt(e) * parseInt(datosCuestionario.resTres)) / 100;
    }
    setProce(e);
    console.log(proce);
  }

  // function recalcularHonorarios() {
  //     console.log("asd");
  //     setTimeout(() => {
  //         setfirst(true)
  //         setHonorariosGestion(500)
  //         setAdvertenciaColor(true)
  //     }, 1300);
  // }

  function calcular(data) {
    datosCuestionario.porcentajeMem = proce;
    if (data === "si") {
      setHonorariosMasEscritura(parseInt(datosCuestionario.resTres) + total);
      if (proce === undefined) {
        datosCuestionario.hipotecaSolicitada =
          (80 * parseInt(parseInt(honorariosMasEscritura) + total)) / 100;
      } else {
        datosCuestionario.hipotecaSolicitada =
          (parseInt(proce) * (parseInt(honorariosMasEscritura) + total)) / 100;
      }
    } else if (data === "no") {
      console.log("Entro en no");
      if (proce === undefined) {
        datosCuestionario.hipotecaSolicitada =
          (80 * parseInt(datosCuestionario.resTres)) / 100;
      } else {
        console.log("Entro en no segunda parte");
        console.log(proce);
        datosCuestionario.hipotecaSolicitada =
          (parseInt(proce) * parseInt(datosCuestionario.resTres)) / 100;
        console.log(datosCuestionario.hipotecaSolicitada + " ASD");
      }
    }
  }

  function atras() {
    setModalDeCarga(false);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Estas seguro/a de volver atras?",
        text: "Se resetearan los honorarios de gestion",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Volver atras",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setCuestionario(2);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
  }
  return (
    <>
      {mostrarCuadros ? (
        <section
          id="cuestionarioUno"
          className="container-firmax-section-cuestionario"
        >
          <div className="title-calculadora">
            <h2 onClick={() => console.log(seteoDeDatosUser)}>Calculadora:</h2>
            <h1
              onClick={() =>
                console.log(datosCuestionario.honorariosDeEscritura)
              }
            >
              {setearDatosCalculadora.nombreInmobiliaria}
            </h1>
          </div>
          <div className="container-firmax-form-flex">
            <h1>Simula la</h1>
            <h1 className="container-firmax-form-flex-color">
              Hipoteca Firmax
            </h1>
          </div>
          <form
            className="container-firmax-section-cuestionario-contenedor-tres"
            onSubmit={btn ? advertenciaSgundaGarantia : next}
          >
            <div className="container-firmax-section-cuestionario-contenedor-form-flex grid-dos-dos">
              <div style={{ position: "relative" }}>
                {/* <p className="symbol">€</p> */}
                <h3>Importe de Venta</h3>
                {/* <input
                  value={datosCuestionario.resTres}
                  onChange={handleOptionChange}
                  type="number"
                /> */}
                <h4>{datosCuestionario.resTres}€</h4>
                <input
                  className="no-target"
                  value={datosCuestionario.financiacionPorcentaje}
                  onChange={handleOptionChange}
                  type="number"
                  name="financiacionPorcentaje"
                />
              </div>
              {/* -------------------------- */}
              {datosCuestionario.honorariosDeEscritura === "no" ||
              datosCuestionario.honorariosDeEscritura === undefined ? (
                <div className="relative">
                  {/* <p className="symbol">€</p> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        paddingRight: "30px",
                      }}
                    >
                      Hipoteca Solicitada{" "}
                      {/* {Math.round(financiacionPorcen * 10) / 10}% */}
                    </h3>

                    {/* name="honorariosDeEscritura"
                  onChange={handleOptionChange}
                  value={datosCuestionario.honorariosDeEscritura} */}
                    <select
                      onClick={(e) => volverAsetear(e.target.value)}
                      className="select-hipoteca-porcetaje"
                      style={{
                        position: "absolute",
                        left: "255px",
                        top: "-19px",
                        padding: "0",
                        height: "30px",
                        width: "60px",
                        borderRadius: "0",
                        border: "none",
                        fontSize: "17px",
                        fontWeight: "700",
                      }}
                      name="porcentaje"
                      value={datosCuestionario.porcentaje}
                      onChange={handleOptionChange}
                    >
                      <option name="porcentaje" value="10">
                        10%
                      </option>
                      <option name="porcentaje" value="11">
                        11%
                      </option>
                      <option name="porcentaje" value="12">
                        12%
                      </option>
                      <option name="porcentaje" value="13">
                        13%
                      </option>
                      <option name="porcentaje" value="14">
                        14%
                      </option>
                      <option name="porcentaje" value="15">
                        15%
                      </option>
                      <option name="porcentaje" value="16">
                        16%
                      </option>
                      <option name="porcentaje" value="17">
                        17%
                      </option>
                      <option name="porcentaje" value="18">
                        18%
                      </option>
                      <option name="porcentaje" value="19">
                        19%
                      </option>
                      <option name="porcentaje" value="20">
                        20%
                      </option>
                      <option name="porcentaje" value="21">
                        21%
                      </option>
                      <option name="porcentaje" value="22">
                        22%
                      </option>
                      <option name="porcentaje" value="23">
                        23%
                      </option>
                      <option name="porcentaje" value="24">
                        24%
                      </option>
                      <option name="porcentaje" value="25">
                        25%
                      </option>
                      <option name="porcentaje" value="26">
                        26%
                      </option>
                      <option name="porcentaje" value="27">
                        27%
                      </option>
                      <option name="porcentaje" value="28">
                        28%
                      </option>
                      <option name="porcentaje" value="29">
                        29%
                      </option>
                      <option name="porcentaje" value="30">
                        30%
                      </option>
                      <option name="porcentaje" value="31">
                        31%
                      </option>
                      <option name="porcentaje" value="32">
                        32%
                      </option>
                      <option name="porcentaje" value="33">
                        33%
                      </option>
                      <option name="porcentaje" value="34">
                        34%
                      </option>
                      <option name="porcentaje" value="35">
                        35%
                      </option>
                      <option name="porcentaje" value="36">
                        36%
                      </option>
                      <option name="porcentaje" value="37">
                        37%
                      </option>
                      <option name="porcentaje" value="38">
                        38%
                      </option>
                      <option name="porcentaje" value="39">
                        39%
                      </option>
                      <option name="porcentaje" value="40">
                        40%
                      </option>
                      <option name="porcentaje" value="41">
                        41%
                      </option>
                      <option name="porcentaje" value="42">
                        42%
                      </option>
                      <option name="porcentaje" value="43">
                        43%
                      </option>
                      <option name="porcentaje" value="44">
                        44%
                      </option>
                      <option name="porcentaje" value="45">
                        45%
                      </option>
                      <option name="porcentaje" value="46">
                        46%
                      </option>
                      <option name="porcentaje" value="47">
                        47%
                      </option>
                      <option name="porcentaje" value="48">
                        48%
                      </option>
                      <option name="porcentaje" value="49">
                        49%
                      </option>
                      <option name="porcentaje" value="50">
                        50%
                      </option>
                      <option name="porcentaje" value="51">
                        51%
                      </option>
                      <option name="porcentaje" value="52">
                        52%
                      </option>
                      <option name="porcentaje" value="53">
                        53%
                      </option>
                      <option name="porcentaje" value="54">
                        54%
                      </option>
                      <option name="porcentaje" value="55">
                        55%
                      </option>
                      <option name="porcentaje" value="56">
                        56%
                      </option>
                      <option name="porcentaje" value="57">
                        57%
                      </option>
                      <option name="porcentaje" value="58">
                        58%
                      </option>
                      <option name="porcentaje" value="59">
                        59%
                      </option>
                      <option name="porcentaje" value="60">
                        60%
                      </option>
                      <option name="porcentaje" value="61">
                        61%
                      </option>
                      <option name="porcentaje" value="62">
                        62%
                      </option>
                      <option name="porcentaje" value="63">
                        63%
                      </option>
                      <option name="porcentaje" value="64">
                        64%
                      </option>
                      <option name="porcentaje" value="65">
                        65%
                      </option>
                      <option name="porcentaje" value="66">
                        66%
                      </option>
                      <option name="porcentaje" value="67">
                        67%
                      </option>
                      <option name="porcentaje" value="68">
                        68%
                      </option>
                      <option name="porcentaje" value="69">
                        69%
                      </option>
                      <option name="porcentaje" value="70">
                        70%
                      </option>
                      <option name="porcentaje" value="71">
                        71%
                      </option>
                      <option name="porcentaje" value="72">
                        72%
                      </option>
                      <option name="porcentaje" value="73">
                        73%
                      </option>
                      <option name="porcentaje" value="74">
                        74%
                      </option>
                      <option name="porcentaje" value="75">
                        75%
                      </option>
                      <option name="porcentaje" value="76">
                        76%
                      </option>
                      <option name="porcentaje" value="77">
                        77%
                      </option>
                      <option name="porcentaje" value="78">
                        78%
                      </option>
                      <option name="porcentaje" value="79">
                        79%
                      </option>
                      <option name="porcentaje" defaultValue value="80">
                        80%
                      </option>
                      <option name="porcentaje" value="81">
                        81%
                      </option>
                      <option name="porcentaje" value="82">
                        82%
                      </option>
                      <option name="porcentaje" value="83">
                        83%
                      </option>
                      <option name="porcentaje" value="84">
                        84%
                      </option>
                      <option name="porcentaje" value="85">
                        85%
                      </option>
                      <option name="porcentaje" value="86">
                        86%
                      </option>
                      <option name="porcentaje" value="87">
                        87%
                      </option>
                      <option name="porcentaje" value="88">
                        88%
                      </option>
                      <option name="porcentaje" value="89">
                        89%
                      </option>
                      <option name="porcentaje" value="90">
                        90%
                      </option>
                      <option name="porcentaje" value="91">
                        91%
                      </option>
                      <option name="porcentaje" value="92">
                        92%
                      </option>
                      <option name="porcentaje" value="93">
                        93%
                      </option>
                      <option name="porcentaje" value="94">
                        94%
                      </option>
                      <option name="porcentaje" value="95">
                        95%
                      </option>
                      <option name="porcentaje" value="96">
                        96%
                      </option>
                      <option name="porcentaje" value="97">
                        97%
                      </option>
                      <option name="porcentaje" value="98">
                        98%
                      </option>
                      <option name="porcentaje" value="99">
                        99%
                      </option>
                      <option name="porcentaje" value="100">
                        100%
                      </option>
                      <option name="porcentaje" value="101">
                        101%
                      </option>
                      <option name="porcentaje" value="102">
                        102%
                      </option>
                      <option name="porcentaje" value="103">
                        103%
                      </option>
                      <option name="porcentaje" value="104">
                        104%
                      </option>
                      <option name="porcentaje" value="105">
                        105%
                      </option>
                      <option name="porcentaje" value="106">
                        106%
                      </option>
                      <option name="porcentaje" value="107">
                        107%
                      </option>
                      <option name="porcentaje" value="108">
                        108%
                      </option>
                      <option name="porcentaje" value="109">
                        109%
                      </option>
                      <option name="porcentaje" value="110">
                        110%
                      </option>
                      <option name="porcentaje" value="111">
                        111%
                      </option>
                      <option name="porcentaje" value="112">
                        112%
                      </option>
                      <option name="porcentaje" value="113">
                        113%
                      </option>
                      <option name="porcentaje" value="114">
                        114%
                      </option>
                      <option name="porcentaje" value="115">
                        115%
                      </option>
                      <option name="porcentaje" value="116">
                        116%
                      </option>
                      <option name="porcentaje" value="117">
                        117%
                      </option>
                      <option name="porcentaje" value="118">
                        118%
                      </option>
                      <option name="porcentaje" value="119">
                        119%
                      </option>
                      <option name="porcentaje" value="120">
                        120%
                      </option>
                      <option name="porcentaje" value="121">
                        121%
                      </option>
                      <option name="porcentaje" value="122">
                        122%
                      </option>
                      <option name="porcentaje" value="123">
                        123%
                      </option>
                      <option name="porcentaje" value="124">
                        124%
                      </option>
                      <option name="porcentaje" value="125">
                        125%
                      </option>
                      <option name="porcentaje" value="126">
                        126%
                      </option>
                      <option name="porcentaje" value="127">
                        127%
                      </option>
                      <option name="porcentaje" value="128">
                        128%
                      </option>
                      <option name="porcentaje" value="129">
                        129%
                      </option>
                      <option name="porcentaje" value="130">
                        130%
                      </option>
                    </select>
                  </div>
                  {/* <input
                  required
                  name="hipotecaSolicitada"
                  value={datosCuestionario.hipotecaSolicitada}
                  onChange={handleOptionChange}
                  type="number"
                /> */}
                  <h4>{datosCuestionario.hipotecaSolicitada}€</h4>
                  {/* onClick={(e)=>selectPorcentaje(e)} */}

                  <i
                    onClick={mostrarLeyendaUno}
                    className="bx bxs-alarm-exclamation tres-absolute tres-absolute-bis"
                  ></i>
                  {/* <p className="cuadro-informativo-absolute-tres-bis">Detalla el porcentaje que <br /> quieres financiar, si <br /> solicitas más del 90% es <br /> posible que sea necesario <br /> aportar una segunda <br /> garantia hipotecaria libre <br /> de cargas.</p> */}
                </div>
              ) : (
                <div className="relative">
                  {/* <p className="symbol">€</p> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        paddingRight: "30px",
                      }}
                    >
                      Hipoteca Solicitada{" "}
                      {/* {Math.round(financiacionPorcen * 10) / 10}% */}
                    </h3>

                    {/* name="honorariosDeEscritura"
                  onChange={handleOptionChange}
                  value={datosCuestionario.honorariosDeEscritura} */}
                    <select
                      onClick={(e) => volverAsetear(e.target.value)}
                      className="select-hipoteca-porcetaje"
                      style={{
                        position: "absolute",
                        left: "255px",
                        top: "-19px",
                        padding: "0",
                        height: "30px",
                        width: "60px",
                        borderRadius: "0",
                        border: "none",
                        fontSize: "17px",
                        fontWeight: "700",
                      }}
                      name="porcentaje"
                      value={datosCuestionario.porcentaje}
                      onChange={handleOptionChange}
                    >
                      <option name="porcentaje" value="30">
                        30%
                      </option>
                      <option name="porcentaje" value="31">
                        31%
                      </option>
                      <option name="porcentaje" value="32">
                        32%
                      </option>
                      <option name="porcentaje" value="33">
                        33%
                      </option>
                      <option name="porcentaje" value="34">
                        34%
                      </option>
                      <option name="porcentaje" value="35">
                        35%
                      </option>
                      <option name="porcentaje" value="36">
                        36%
                      </option>
                      <option name="porcentaje" value="37">
                        37%
                      </option>
                      <option name="porcentaje" value="38">
                        38%
                      </option>
                      <option name="porcentaje" value="39">
                        39%
                      </option>
                      <option name="porcentaje" value="40">
                        40%
                      </option>
                      <option name="porcentaje" value="41">
                        41%
                      </option>
                      <option name="porcentaje" value="42">
                        42%
                      </option>
                      <option name="porcentaje" value="43">
                        43%
                      </option>
                      <option name="porcentaje" value="44">
                        44%
                      </option>
                      <option name="porcentaje" value="45">
                        45%
                      </option>
                      <option name="porcentaje" value="46">
                        46%
                      </option>
                      <option name="porcentaje" value="47">
                        47%
                      </option>
                      <option name="porcentaje" value="48">
                        48%
                      </option>
                      <option name="porcentaje" value="49">
                        49%
                      </option>
                      <option name="porcentaje" value="50">
                        50%
                      </option>
                      <option name="porcentaje" value="51">
                        51%
                      </option>
                      <option name="porcentaje" value="52">
                        52%
                      </option>
                      <option name="porcentaje" value="53">
                        53%
                      </option>
                      <option name="porcentaje" value="54">
                        54%
                      </option>
                      <option name="porcentaje" value="55">
                        55%
                      </option>
                      <option name="porcentaje" value="56">
                        56%
                      </option>
                      <option name="porcentaje" value="57">
                        57%
                      </option>
                      <option name="porcentaje" value="58">
                        58%
                      </option>
                      <option name="porcentaje" value="59">
                        59%
                      </option>
                      <option name="porcentaje" value="60">
                        60%
                      </option>
                      <option name="porcentaje" value="61">
                        61%
                      </option>
                      <option name="porcentaje" value="62">
                        62%
                      </option>
                      <option name="porcentaje" value="63">
                        63%
                      </option>
                      <option name="porcentaje" value="64">
                        64%
                      </option>
                      <option name="porcentaje" value="65">
                        65%
                      </option>
                      <option name="porcentaje" value="66">
                        66%
                      </option>
                      <option name="porcentaje" value="67">
                        67%
                      </option>
                      <option name="porcentaje" value="68">
                        68%
                      </option>
                      <option name="porcentaje" value="69">
                        69%
                      </option>
                      <option name="porcentaje" value="70">
                        70%
                      </option>
                      <option name="porcentaje" value="71">
                        71%
                      </option>
                      <option name="porcentaje" value="72">
                        72%
                      </option>
                      <option name="porcentaje" value="73">
                        73%
                      </option>
                      <option name="porcentaje" value="74">
                        74%
                      </option>
                      <option name="porcentaje" value="75">
                        75%
                      </option>
                      <option name="porcentaje" value="76">
                        76%
                      </option>
                      <option name="porcentaje" value="77">
                        77%
                      </option>
                      <option name="porcentaje" value="78">
                        78%
                      </option>
                      <option name="porcentaje" value="79">
                        79%
                      </option>
                      <option name="porcentaje" defaultValue value="80">
                        80%
                      </option>
                      <option name="porcentaje" value="81">
                        81%
                      </option>
                      <option name="porcentaje" value="82">
                        82%
                      </option>
                      <option name="porcentaje" value="83">
                        83%
                      </option>
                      <option name="porcentaje" value="84">
                        84%
                      </option>
                      <option name="porcentaje" value="85">
                        85%
                      </option>
                      <option name="porcentaje" value="86">
                        86%
                      </option>
                      <option name="porcentaje" value="87">
                        87%
                      </option>
                      <option name="porcentaje" value="88">
                        88%
                      </option>
                      <option name="porcentaje" value="89">
                        89%
                      </option>
                      <option name="porcentaje" value="90">
                        90%
                      </option>
                      <option name="porcentaje" value="91">
                        91%
                      </option>
                      <option name="porcentaje" value="92">
                        92%
                      </option>
                      <option name="porcentaje" value="93">
                        93%
                      </option>
                      <option name="porcentaje" value="94">
                        94%
                      </option>
                      <option name="porcentaje" value="95">
                        95%
                      </option>
                      <option name="porcentaje" value="96">
                        96%
                      </option>
                      <option name="porcentaje" value="97">
                        97%
                      </option>
                      <option name="porcentaje" value="98">
                        98%
                      </option>
                      <option name="porcentaje" value="99">
                        99%
                      </option>
                      <option name="porcentaje" value="100">
                        100%
                      </option>
                      <option name="porcentaje" value="101">
                        101%
                      </option>
                      <option name="porcentaje" value="102">
                        102%
                      </option>
                      <option name="porcentaje" value="103">
                        103%
                      </option>
                      <option name="porcentaje" value="104">
                        104%
                      </option>
                      <option name="porcentaje" value="105">
                        105%
                      </option>
                      <option name="porcentaje" value="106">
                        106%
                      </option>
                      <option name="porcentaje" value="107">
                        107%
                      </option>
                      <option name="porcentaje" value="108">
                        108%
                      </option>
                      <option name="porcentaje" value="109">
                        109%
                      </option>
                      <option name="porcentaje" value="110">
                        110%
                      </option>
                      <option name="porcentaje" value="111">
                        111%
                      </option>
                      <option name="porcentaje" value="112">
                        112%
                      </option>
                      <option name="porcentaje" value="113">
                        113%
                      </option>
                      <option name="porcentaje" value="114">
                        114%
                      </option>
                      <option name="porcentaje" value="115">
                        115%
                      </option>
                      <option name="porcentaje" value="116">
                        116%
                      </option>
                      <option name="porcentaje" value="117">
                        117%
                      </option>
                      <option name="porcentaje" value="118">
                        118%
                      </option>
                      <option name="porcentaje" value="119">
                        119%
                      </option>
                      <option name="porcentaje" value="120">
                        120%
                      </option>
                      <option name="porcentaje" value="121">
                        121%
                      </option>
                      <option name="porcentaje" value="122">
                        122%
                      </option>
                      <option name="porcentaje" value="123">
                        123%
                      </option>
                      <option name="porcentaje" value="124">
                        124%
                      </option>
                      <option name="porcentaje" value="125">
                        125%
                      </option>
                      <option name="porcentaje" value="126">
                        126%
                      </option>
                      <option name="porcentaje" value="127">
                        127%
                      </option>
                      <option name="porcentaje" value="128">
                        128%
                      </option>
                      <option name="porcentaje" value="129">
                        129%
                      </option>
                      <option name="porcentaje" value="130">
                        130%
                      </option>
                    </select>
                  </div>
                  {/* <input
                  required
                  name="hipotecaSolicitada"
                  value={datosCuestionario.hipotecaSolicitada}
                  onChange={handleOptionChange}
                  type="number"
                /> */}
                  <h4>{datosCuestionario.hipotecaSolicitadaConHonorarios}€</h4>
                  {/* onClick={(e)=>selectPorcentaje(e)} */}

                  <i
                    onClick={mostrarLeyendaUno}
                    className="bx bxs-alarm-exclamation tres-absolute tres-absolute-bis"
                  ></i>
                  {/* <p className="cuadro-informativo-absolute-tres-bis">Detalla el porcentaje que <br /> quieres financiar, si <br /> solicitas más del 90% es <br /> posible que sea necesario <br /> aportar una segunda <br /> garantia hipotecaria libre <br /> de cargas.</p> */}
                </div>
              )}

              {/* -------------------------- */}
              <div className="relative">
                <h3>¿Incluir Honararios en Escritura?</h3>
                {/* onClick={(e) => calcular(e.target.value)} */}
                <select
                  onClick={(e) => calcular(e.target.value)}
                  style={{
                    padding: "0",
                    height: "78px",
                    width: "300px",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                  name="honorariosDeEscritura"
                  onChange={handleOptionChange}
                  value={datosCuestionario.honorariosDeEscritura}
                >
                  <option name="honorariosDeEscritura" value="null">Seleccione una de las opciones</option>
                  <option name="honorariosDeEscritura" value="si">
                    si
                  </option>
                  <option name="honorariosDeEscritura" value="no">
                    no
                  </option>
                </select>

                {/* <section className="contenedor-opts-honorarios">
                  <div className="contenedor-opts-honorarios-individual">
                    <input onClick={() => calcular('si')} type="checkbox" ></input>
                    <label htmlFor="">Si</label>
                  </div>
                  <div className="contenedor-opts-honorarios-individual">
                    <input onClick={() => calcular('no')} type="checkbox"></input>
                    <label htmlFor="">No</label>
                  </div>
                </section> */}

                <i
                  onClick={mostrarLeyendaDos}
                  className="bx bxs-alarm-exclamation tres-absolute"
                ></i>
                <p style={{ fontSize: "14px" }}>
                  Importe de escritura:{" "}
                  {datosCuestionario.honorariosDeEscritura === "si"
                    ? honorariosMasEscritura
                    : datosCuestionario.resTres}
                  €
                </p>
              </div>

              <div style={{ position: "relative" }}>
                <h3 style={{ opacity: opacity }}>Honorarios de Gestión</h3>
                <i
                  onClick={ocultar}
                  className="bx bx-low-vision"
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "0",
                    cursor: "pointer",
                  }}
                ></i>
                {/* <input style={{opacity: opacity}} value={honariosGestion} onChange={handleOptionChange} type="number" name="honorariosDeGestion"/> */}
                <div className="relative" style={{ opacity: opacity }}>
                  <h1
                    onClick={() => sumarRestar("suma")}
                    className="honorarios-gestion-mas"
                  >
                    +
                  </h1>
                  <h4>{total}€</h4>
                  {setearDatosCalculadora.mostrarComision === "si" ? (
                    <p style={{ fontSize: "14px", opacity: opacity }}>
                      Mi comisión: {honariosGestion}€
                    </p>
                  ) : null}

                  <h1
                    onClick={() => sumarRestar("resta")}
                    className="honorarios-gestion-menos"
                  >
                    -
                  </h1>
                </div>
                <input
                  className="no-target"
                  value={datosCuestionario.financiacionPorcentaje}
                  onChange={handleOptionChange}
                  type="number"
                  name="financiacionPorcentaje"
                />
              </div>
            </div>
            <h2 className="container-firmax-section-cuestionario-contenedor-tres-border">
              Estas financiando el{" "}
              {datosCuestionario.porcentajeMem === undefined
                ? "80"
                : Math.round(datosCuestionario.porcentajeMem * 10) / 10}
              % <br /> Ahorros necesarios:{" "}
              {datosCuestionario.honorariosDeEscritura === "si"
                ? datosCuestionario.resTres -
                  datosCuestionario.hipotecaSolicitadaConHonorarios
                : datosCuestionario.resTres -
                  datosCuestionario.hipotecaSolicitada}
              {/* datosCuestionario.resTres - datosCuestionario.hipotecaSolicitada < 0 ? '0' : datosCuestionario.resTres - datosCuestionario.hipotecaSolicitada */}
              € + gastos según impuestos aplicables
            </h2>
            {advertencia ? (
              <div
                style={{ paddingTop: "2rem" }}
                className="valorAproxSegundaGarantia-contenedor-tres-porcentaje"
              >
                <h1>
                  Para solicitar una hipoteca mayor al{" "}
                  {Math.round(porcetajeEscenario * 10) / 10}% <br /> importe de
                  compra, es posible <br /> que sea necesario aportar una
                  segunda <br /> vivienda como aval.
                </h1>
                <div className="container-firmax-div-flex-row">
                  <div className="container-firmax-div-flex-column">
                    <label>¿Aportas 2da garantía?</label>
                    <select
                      required
                      type="text"
                      value={datosSegundaGarantia.segundaGarantia}
                      name="segundaGarantia"
                      onChange={handleOptionChangeTwo}
                    >
                      <option value="">Seleccione</option>
                      <option name="segundaGarantia" value="si">
                        Si
                      </option>
                      <option name="segundaGarantia" value="no">
                        No
                      </option>
                    </select>
                  </div>

                  <div className="container-firmax-div-flex-column">
                    <label>Detalla su valor aproximado</label>
                    <input
                      required
                      type="number"
                      value={datosSegundaGarantia.valorAproxSegundaGarantia}
                      name="valorAproxSegundaGarantia"
                      onChange={handleOptionChangeTwo}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              className="container-firmax-section-fija-flex"
            >
              <button
                className="container-firmax-section-fija-button margin-buttom-cuest"
                onClick={atras}
                type="button"
              >
                Atras
              </button>

              {btn ? (
                <button
                  className="container-firmax-section-fija-button margin-buttom-cuest"
                  type="submit"
                >
                  Siguiente
                </button>
              ) : (
                <button
                  className="container-firmax-section-fija-button margin-buttom-cuest"
                  type="submit"
                >
                  Siguiente
                </button>
              )}
            </div>
          </form>
        </section>
      ) : (
        <CuestionarioCinco
          setMostrarCuadros={setMostrarCuadros}
          datosCuestionario={datosCuestionario}
          setDatosCuestionario={setDatosCuestionario}
          setCuestionario={setCuestionario}
          financiacion={financiacion}
          contratoBD={contratoBD}
          setFinanciacion={setFinanciacion}
          setearDatosCalculadora={setearDatosCalculadora}
          setHonorariosGestion={setHonorariosGestion}
          setTotal={setTotal}
          setVarIincremento={setVarIincremento}
          setCount={setCount}
          setModalDeCarga={setModalDeCarga}
          modalDeCarga={modalDeCarga}
          date={date}
          seteoDeDatosUser={seteoDeDatosUser}
        />
      )}
    </>
  );
};

export default CuestionarioTresBis;
