import { useEffect , useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth , db } from '../../../Firebase/Credenciales'
import { doc, getDoc , getDocs , collection , setDoc } from "firebase/firestore";
import logo from '../../../img/logo.png'
import {useNavigate} from "react-router-dom";
import Swal from 'sweetalert2';

const CrearMejoras = () => {
    const [date, setDate] = useState(null)
    const [mejoras, setMejoras] = useState([])
    const [flag, setflag] = useState(true)
    const [datosMejoraInput, setDatosMejoraInput] = useState({})
    const [modalCrear, setmodalCrear] = useState(false)
    const [modaActualziar, setModaActualziar] = useState(false)
    const [datosNewLeyenda, setDatosNewLeyenda] = useState({})
    const navigate = useNavigate()
    const [primerEndeu, setPrimerEndeu] = useState([])
    const [segundoEndeu, setSegundoEndeu] = useState([])
    const [garantia, setGarantia] = useState([])
    const [ratio, setRatio] = useState([])

    function handleInputChange(e) {
        setDatosMejoraInput({
            ...datosMejoraInput,
            [e.target.name] : e.target.value
        })
    }

    useEffect(() => {
        onAuthStateChanged(auth, (userLog) => {
            if (userLog) {
              setDate(userLog); 
              async function buscarUser() {
                const docRef = doc(db, "users" , userLog.uid)
                const docSnap = await getDoc(docRef);
                switch (docSnap.data().rol) {
                  case "adminFirmax":
                      navigate('/firmax/Crear-mejoras')
                      break;
                  case "inmobiliaria":
                      navigate('/inmobiliaria')
                      break;    
                  default:
                      break;
              }
              }
              buscarUser()
            } else {
              setDate(null);
              navigate('/login')   
            }

            let pushMejoras = []

            let pushMejorasUno = []

            let pushMejorasDos = []
 
            let pushMejorasTres = []

            
            let pushMejorasCuatro = []
         
            async function traerMejorasBD(prop) {
                const querySnapshot = await getDocs(collection(db, prop));
                querySnapshot.forEach((doc) => {
                let mejora = {
                    abreviatura: doc.data().abreviatura,
                    leyenda: doc.data().leyenda,
                    id: doc.data().id
                }  
                pushMejoras.push(mejora)
                });
                function compararPorId(a, b) {
                  if (a.id < b.id) {
                    return -1;
                  }
                  if (a.id > b.id) {
                    return 1;
                  }
                  return 0;
                }
                setMejoras(pushMejoras.sort(compararPorId))
        
                setflag(false)
            }
            
            //Va a pusheartodas las mejoras ma
            for (let i = 0; i < mejoras.length; i++) {
              if (mejoras[i].abreviatura.includes('ma')) {
                pushMejorasUno.push(mejoras[i])      
              }         
            }
            setPrimerEndeu(pushMejorasUno)

            for (let i = 0; i < mejoras.length; i++) {
              if (mejoras[i].abreviatura.includes('md')) {
                pushMejorasDos.push(mejoras[i])      
              }         
            }
            setSegundoEndeu(pushMejorasDos)

            for (let i = 0; i < mejoras.length; i++) {
              if (mejoras[i].abreviatura.includes('mr')) {
                pushMejorasTres.push(mejoras[i])      
              }         
            }
            setRatio(pushMejorasTres)

            for (let i = 0; i < mejoras.length; i++) {
              if (mejoras[i].abreviatura.includes('mg')) {
                pushMejorasCuatro.push(mejoras[i])      
              }         
            }
            setGarantia(pushMejorasCuatro)

            async function traeridMejoras() {
                const docRef = doc(db, "idMejoras" , "id")
                const docSnap = await getDoc(docRef);
                setidDeBD(docSnap.data().id);
            }
          

            if (flag) {
                traerMejorasBD("mejoras")
                traeridMejoras()
            }
          });
        }, [date, flag, mejoras, navigate]);

        const [idDeBD, setidDeBD] = useState()
       

        async function agregarMejora() {
            try {
              if (idDeBD > 100) {
                Swal.fire({
                  title: "Error",
                  text: "Has superado el limite de creación de mejoras, modifica alguna de las ya existentes!",
                  icon:"error",
                  confirmButtonColor:"#8FCD60"
              })
              }else{
                await setDoc(doc(db, "mejoras", datosMejoraInput.abreviatura), {
                  id: idDeBD + 1,
                  abreviatura: datosMejoraInput.abreviatura,
                  leyenda: datosMejoraInput.leyenda
                });
                await setDoc(doc(db, "idMejoras", "id"), {
                    id: idDeBD + 1
                })
                let timerInterval;
                Swal.fire({
                  title: "Guardado correctamente!!",
                  html: 'Este modal se cerrara automaticamente en: <b></b> milisegundos.',
                  icon: "success",
                  timer: 7000,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                      b.textContent = Swal.getTimerLeft()
                    }, 100)
                  },
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    
                  }
                })
                setflag(true)
                setmodalCrear(false)
                setDatosMejoraInput({
                  abreviatura: "",
                  leyenda:""
                })
                }//fin else
              } catch (error) {
                console.log(error);
                Swal.fire({
                  title: "Error",
                  text: "Intentelo nuevamente!",
                  icon:"error",
                  confirmButtonColor:"#8FCD60"
              })
              }
        }

        async function abrirModal(abreviatura , id) {
          setModaActualziar(true)
          setDatosNewLeyenda({
            abreviatura: abreviatura,
            id: id
          })
        }
        async function GuardarModalActualziar() {
    
          try {
            await setDoc(doc(db, "mejoras", datosNewLeyenda.abreviatura), {
              id: datosNewLeyenda.id,
              abreviatura: datosNewLeyenda.abreviatura,
              leyenda: datosMejoraInput.leyenda
            })
            Swal.fire({
              title: "Mejora modificada correctamente",
              icon:"success",
              confirmButtonColor:"#8FCD60"
          })
          setModaActualziar(false)
          setflag(true)
          } catch (error) {
            console.log(error);
          }  
        }
        function mostrarAlertaModal() {
          Swal.fire({
            title: "Atención!!",
            text: "Las diferencias de las mejoras en los 3 tipos de penalizaciones (Endeudamiento por tiempo, Ratio de endeudamiento y segundo endeudamiento) estan separados por el ID, para Primer endeudamiento se usara m1, m2, etc. Para el segundo endeudamiento se usara ids mm1, mm2, mm3, etc. Y para el ratio se usaran ids mmm1,mmm2, etc...",
            icon:"info",
            confirmButtonColor:"#8FCD60"
        })
        }
    return ( 
        <section className='section-firmax'>
        <header className='section-firmax-head'>
            <img src={logo} alt="logo_firma"/>
        </header>
      
        <main className='section-firmax-main-crear-mejoras'> 
            <i onClick={mostrarAlertaModal} style={{textAlign:"center"}} className='bx bxs-alarm-exclamation leyenda-grid-alarm'></i> 
            <button onClick={()=>setmodalCrear(true)} className="contenedor-calculadora-creacion-firmax-form-button">Agregar mejora +</button>
            {modalCrear 
            ?   <section className="section-agregar-mejora-guardar">
                    <label className="bg">ID: </label>
                    <input type="text" placeholder="EJ: m1" name="abreviatura" onChange={handleInputChange}/>
                    <label className="bg">Leyenda: </label>
                    <input type="text" name="leyenda" placeholder="EJ: Aportar una garantía inmobiliaria adicional" onChange={handleInputChange}/>
                    <button onClick={agregarMejora} className="contenedor-calculadora-creacion-firmax-form-button">Guardar mejora</button>
                    <button style={{background:"tomato"}} onClick={()=>setmodalCrear(false)} className="contenedor-calculadora-creacion-firmax-form-button">Cancelar</button>
                </section>
            : null}

            <div>
              <h3 style={{paddingBottom:"1rem"}}>Mejoras para antiguedad - Anotación ma1</h3>
              {primerEndeu.length === 0 
              ? <p>Todavia no hay ninguna mejora asignada</p>
              : primerEndeu.map(mejora => (
                  <div style={{textAlign:"center"}} className='tabla-contratos' key={mejora.id}>
                      <p>{mejora.abreviatura}</p>
                      <p>{mejora.leyenda}</p>
                      <div className='tabla-contratos-mod-eli'>
                          <i onClick={()=>abrirModal(mejora.abreviatura , mejora.id)} className='bx bx-edit-alt'>Edit</i>
                      </div>
                  </div>
              ))}
            </div>
            
            <div>
              <h3 style={{paddingBottom:"1rem"}}>Mejoras para antiguedad DOS - Anotación md1</h3>
                {segundoEndeu.length === 0 
                ? <p style={{paddingTop:"1rem"}}>Todavia no hay ninguna mejora asignada</p>
                : segundoEndeu.map(mejora => (
                    <div style={{textAlign:"center"}} className='tabla-contratos' key={mejora.id}>
                        <p>{mejora.abreviatura}</p>
                        <p>{mejora.leyenda}</p>
                        <div className='tabla-contratos-mod-eli'>
                            <i onClick={()=>abrirModal(mejora.abreviatura , mejora.id)} className='bx bx-edit-alt'>Edit</i>
                        </div>
                    </div>
                ))}
            </div>

            <div>
              <h3 style={{paddingBottom:"1rem"}}>Mejoras para RATIO endeudamiento - Anotación mr1</h3>
                {ratio.length === 0 
                ? <p style={{paddingTop:"1rem"}}>Todavia no hay ninguna mejora asignada</p>
                : ratio.map(mejora => (
                    <div style={{textAlign:"center"}} className='tabla-contratos' key={mejora.id}>
                        <p>{mejora.abreviatura}</p>
                        <p>{mejora.leyenda}</p>
                        <div className='tabla-contratos-mod-eli'>
                            <i onClick={()=>abrirModal(mejora.abreviatura , mejora.id)} className='bx bx-edit-alt'>Edit</i>
                        </div>
                    </div>
                ))}
            </div>

            <div>
              <h3 style={{paddingBottom:"1rem"}}>Mejoras para GARANTIA - Anotación mg1</h3>
                {garantia.length === 0 
                ? <p style={{paddingTop:"1rem"}}>Todavia no hay ninguna mejora asignada</p>
                : garantia.map(mejora => (
                    <div style={{textAlign:"center"}} className='tabla-contratos' key={mejora.id}>
                        <p>{mejora.abreviatura}</p>
                        <p>{mejora.leyenda}</p>
                        <div className='tabla-contratos-mod-eli'>
                            <i onClick={()=>abrirModal(mejora.abreviatura , mejora.id)} className='bx bx-edit-alt'>Edit</i>
                        </div>
                    </div>
                ))}
            </div>
          

             
            <div className='section-firmax-inmobiliaria-arrow'>
              <i onClick={()=> navigate('/firmax')} style={{padding:"2rem"}} className='bx bx-left-arrow-alt'>Volver</i>
            </div>  

            {modaActualziar 
            ?
            <div className="input-edit-mejora">
              <div className="input-edit-mejora-campos">
                <input className="section-agregar-mejora-guardar" type="text" placeholder="Ingrese la nueva leyenda" name="leyenda" onChange={handleInputChange}/>
                <button onClick={()=>setModaActualziar(false)} style={{background:"tomato"}} className="contenedor-calculadora-creacion-firmax-form-button">Cancelar</button>
                <button onClick={GuardarModalActualziar} className="contenedor-calculadora-creacion-firmax-form-button">Guardar</button>
              </div>
            </div>
            : null}
            
        </main>
    </section>    
     );
}
 
export default CrearMejoras;