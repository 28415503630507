import { useState } from "react"
import Swal from 'sweetalert2';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../Firebase/Credenciales";

const CuestionarioUno = ({datosCuestionario , setDatosCuestionario , setCuestionario , setearDatosCalculadora, datosSimulacion}) => {
    //datosCuestionario valores default cuestionario
    //setDatosCuestionario actualizar datos cuestionario
    //setCuestionario actualizar numero de cuestionario
    // eslint-disable-next-line no-unused-vars
    const [mejorasBD, setMejorasBD] = useState([])
    const [provincias] = useState([
        "Albacete",
        "Alicante",
        "Almería",
        "Asturias",
        "Ávila",
        "Badajoz",
        "Barcelona",
        "Burgos",
        "Cáceres",
        "Cádiz",
        "Cantabria",
        "Castellón",
        "Ciudad Real",
        "Córdoba",
        "Cuenca",
        "Girona",
        "Gerona",
        "Granada",
        "Guadalajara",
        "Guipúzcoa",
        "Huelva",
        "Huesca",
        "Islas Baleares",
        "Jaén",
        "La Coruña",
        "La Rioja",
        "Las Palmas",
        "León",
        "Lérida",
        "Lugo",
        "Madrid",
        "Málaga",
        "Murcia",
        "Navarra",
        "Orense",
        "Palencia" ,
        "Pontevedra" ,
        "Salamanca",
        "Tenerife",
        "Segovia",
        "Sevilla",
        "Soria",
        "Tarragona",
        "Teruel",
        "Toledo",
        "Valencia",
        "Valladolid",
        "Vizcaya",
        "Zamora",
        "Zaragoza",
    ])
    const handleOptionChange = (e) =>{
        setDatosCuestionario({
            ...datosCuestionario,
            [e.target.name] : e.target.value,
        })
    }

    const next = (e) => {
        e.preventDefault()
        traerMejorasBD(e)
        setDatosCuestionario({
            ...datosCuestionario,
            [e.target.name] : e.target.value,
        })

        if (datosCuestionario.resPreguntaCero === 'si' ||datosCuestionario.resPreguntaCero === 'no') {
            setCuestionario(2)
        }else{
            Swal.fire({
                title: "Elija una opcion",
                text:"Debe elegir si los honorarios de hipoteca ya están negociados con el vendedor dentro del precio de venta para continuar",
                icon:"info",
                confirmButtonColor:"#8FCD60"
            })
        }
    }

    async function traerMejorasBD(e) {
        let pushing = [];
        let mejoraEndeu = [];
        let mejoraEndeuDos = [];
        let mejoraRatio = [];
        let mejoraGarantia = [];
   
        const querySnapshot = await getDocs(collection(db, "mejoras"));
     
        querySnapshot.forEach((doc) => {
          let mejora = {
            id: doc.data().id,
            abreviatura: doc.data().abreviatura,
            leyenda: doc.data().leyenda,
          };
          pushing.push(mejora);
        });
       
        setMejorasBD(pushing);


        for (let i = 0; i < pushing.length; i++) {
            if (pushing[i].abreviatura.includes('ma')) {
                mejoraEndeu.push(pushing[i])
            }else if (pushing[i].abreviatura.includes('md')) {
                mejoraEndeuDos.push(pushing[i])
            }else if (pushing[i].abreviatura.includes('mr')) {
                mejoraRatio.push(pushing[i])
            }else{
                mejoraGarantia.push(pushing[i])
            }     
        }
        
        setDatosCuestionario({
            ...datosCuestionario,
            [e.target.name] : e.target.value,
            mejorasAntiguedad: mejoraEndeu,
            mejorasAntiguedadDos: mejoraEndeuDos,
            mejorasRatio: mejoraRatio,
            mejorasGarantia:mejoraGarantia
        })
    }

    function mostrarLeyendaUno() {
        Swal.fire({
            title: "Precio de venta",
            text:"Detalla el importe de venta antes de impuestos",
            icon:"question",
            confirmButtonColor:"#8FCD60"
        })
    }
    function mostrarLeyendaDos() {
        Swal.fire({
            title: "Valor de referencia",
            text:"Si conoces el valor de referencia publicado en catastro, detalla el importe para el calculo de impuestos",
            icon:"question",
            confirmButtonColor:"#8FCD60"
        })
    }

    // const [check, setCheck] = useState(false)
    // const [checkUno, setCheckUno] = useState(false)
    
    // function cambiarChek() {
    //     setCheck(true)
    //     setCheckUno(false)
    // }
    // function cambiarChekUno() {
    //     setCheck(false)
    //     setCheckUno(true)
    // }

    return ( 
            <section id="cuestionario" className="container-firmax-section-cuestionario">
                <div className='title-calculadora'>
                    <h2 className="title-calculadora-sub-principal">Hipoteca:</h2>
                    <h2 className="title-calculadora-sub">{setearDatosCalculadora.nombreInmobiliaria}</h2>
                </div>
                <div className='container-firmax-form-flex-title'>
                    <h1 onClick={()=> console.log(setearDatosCalculadora)}>Simula la</h1>
                    <h1 className='container-firmax-form-flex-color'>Hipoteca Firmax</h1>
                </div>
                <form className="container-firmax-section-cuestionario-contenedor-form" onSubmit={next}>
         
                       
                        <div className="container-firmax-div-flex input-select-width">
                        <label>¿Los honorarios de hipoteca ya están negociados con el vendedor dentro del precio de venta?</label>
                            {/* <div className="container-firmax-div-flex-uno-column">
                                <input onClick={cambiarChek} checked={check} type="checkbox" value={datosCuestionario.resPreguntaCero} name="resPreguntaCero" onChange={handleOptionChange}/>
                                <label>Si</label>
                            </div>

                            <div className="container-firmax-div-flex-uno-column">
                                <input onClick={cambiarChekUno} checked={checkUno} type="checkbox" value={datosCuestionario.resPreguntaCero} name="resPreguntaCero" onChange={handleOptionChange}/>
                                <label>No</label>
                            </div> */}
                            <select name="resPreguntaCero" id="" onChange={handleOptionChange} value={datosCuestionario.resPreguntaCero}>
                                <option name="resPreguntaCero" value=''>-Escoge una opción- *</option>
                                <option name="resPreguntaCero" value='si'>Si</option>
                                <option name="resPreguntaCero" value='no'>No</option>
                            </select>
                        </div>
                   
                    <div className="container-firmax-div-flex input-select-width">
                        <label>Tipo de transmisón</label>
                        <select required name="resUno" id="" onChange={handleOptionChange} value={datosCuestionario.resUno}>
                            <option name="resUno" value=''>-Escoge una opción- *</option>
                            <option name="resUno" value='Obra Nueva'>Obra Nueva</option>
                            <option name="resUno" value='Segunda Mano'>Segunda Mano</option>
                        </select>
                    </div>

                    <div className="container-firmax-div-flex input-select-width">
                        <label>Ciudad donde se encuentra el inmueble</label>
                
                        <select name="resDos" id="" onChange={handleOptionChange} value={datosCuestionario.resDos}>
                            {provincias.map(provincia => (
                                <option name="resDos" key={provincia} value={provincia}>{provincia}</option>
                            ))}
                        </select>
                    </div>

                    <div className="container-firmax-div-flex cuadro-informativo relative">
                        <label>Precio de venta</label>
                        <input required name="resTres" type="number" onChange={handleOptionChange} value={datosCuestionario.resTres}/>  
                        <i onClick={mostrarLeyendaUno} className='bx bxs-alarm-exclamation one-absolute'></i>
                        <h2 style={{position:"absolute" , top:"34px" , left:"59px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                    </div>
                    
                    <div className="container-firmax-div-flex cuadro-informativo relative">
                        <label>Valor de referencia</label>
                        <input name="resCuatro" type="number" onChange={handleOptionChange}/>                   
                        <i className='bx bxs-alarm-exclamation one-absolute' onClick={mostrarLeyendaDos}></i>  
                        <h2 style={{position:"absolute" , top:"34px" , left:"59px" , fontSize:"15px" , color:"#000000c1"}}>€</h2>
                    </div>

                    <button
                    style={{width:"100%" , margin:"0"}}
                    type="Submit"
                    className="container-firmax-section-fija-button margin-buttom-cuest" 
                    >Siguiente
                </button>
                </form>
         
            </section>


     );
}
 
export default CuestionarioUno;
