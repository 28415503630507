import logo from '../../../img/logo.png'
import { updatePassword } from "firebase/auth";
import { useEffect , useState } from 'react';
import { auth } from '../../../Firebase/Credenciales'
import {useNavigate} from "react-router-dom";
import { onAuthStateChanged , signOut } from "firebase/auth";
import { doc, getDoc} from "firebase/firestore";
import { db } from '../../../Firebase/Credenciales'

const CambiiarContraseña = () => {
    const [password, setPassword] = useState("")
    const [date , setDate] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        onAuthStateChanged(auth, (userLog) => {
            if (userLog) {
              setDate(userLog); 
              async function buscarUser() {
                const docRef = doc(db, "users" , userLog.uid)
                const docSnap = await getDoc(docRef);
                switch (docSnap.data().rol) {
                  case "adminFirmax":
                      navigate('/firmax/Cambiar-contraseña')
                      break;
                  case "inmobiliaria":
                      navigate('/inmobiliaria')
                      break;    
                  default:
                      break;
              }
              }
              buscarUser()
            } else {
              setDate(null);
              navigate('/login')   
            }
          });
      }, [date, navigate]);

    //Captura el target del form
    function handleInput(e) {
        setPassword(e.target.value)
    }
    //Enviar update
    function handleSubmit(e) {
        e.preventDefault()
        updatePassword(date, password).then(() => {
            signOut(auth).then(()=>{      
            }).catch((err)=>{console.log(err)}) 
        }).catch((error) => {
            console.log(error)
        });
    }

    return ( 
        <section className='section-firmax'>
            <header className='section-firmax-head'>
                <img src={logo} alt="logo_firma"/>
            </header>

            <main>
                <form onSubmit={handleSubmit} className='section-firmax-form-password'>
                    <label>Nueva contraseña</label>
                    <input
                    value={password}
                    onChange={handleInput}
                    type="password"
                    placeholder="***********"
                    />
                    <button type='submit' className='section-firmax-form-grid-width'>Enviar</button>
                </form>
                <div onClick={()=> navigate('/firmax')} className='section-firmax-form-container-back padding-top'>
                    <i className='bx bx-left-arrow-alt'></i>
                    <p>Volver</p>
                </div>    
            </main>
        </section>
    );
}
 
export default CambiiarContraseña;