import { useEffect, useState } from "react";
import ResViabilidad from "./ResViabilidad";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../Firebase/Credenciales";
import Swal from "sweetalert2";

const CuestionarioCinco = ({
  datosCuestionario,
  setCuestionario,
  setDatosCuestionario,
  financiacion,
  contratoBD,
  setearDatosCalculadora,
  setMostrarCuadros,
  setHonorariosGestion,
  setTotal,
  setVarIincremento,
  setCount,
  setModalDeCarga,
  modalDeCarga,
  date,
  seteoDeDatosUser
}) => {
  //estado de los check
  const [mensajeRatio, setMensajeRatio] = useState("");
  const [solicitudHipotecaria, setSolicitudHipotecaria]  = useState(0);
  const [fijo, setFijo] = useState(true);
  const [variable, setVariable] = useState(false);
  const [mixto, setMixto] = useState(false);
  const [edadCalculoFinal, setEdadCalculoFinal] = useState(0);
  const [resEdadMaxPlazoFijo, setresEdadMaxPlazoFijo] = useState(0);
  const [resEdadMaxPlazoVariable, setresEdadMaxPlazoVariable] = useState(0);
  const [resEdadMaxPlazoMixto, setresEdadMaxPlazoMixto] = useState(0);
  const [indiceUno, setIndiceUno] = useState(-1)
  const [indiceDos, setIndiceDos] = useState(-1)
  const [indiceTres, setIndiceTres] = useState(-1)
  const [indiceCuatro, setIndiceUCuatro] = useState(-1)
  //antiguedad
  const [antiUno, setAntiUno] = useState(0)
  const [antiDos, setAntiDos] = useState(-1)
  const [antiTres, setAntiTres] = useState(-1)
  const [antiCuatro, setAntiCuatro] = useState(-1)
  //variables de ayuda para recalculo en res
  const [mensualTotal, setMensualTotal] = useState()
  const [pagaCuotaTotal, setPagaCuotaTotal] = useState()
  //estado de años
  const [aniosTiempo, setaniosTiempo] = useState();
  const [aniosTiempoVariable, setaniosTiempoVariable] = useState();
  const [aniosTiempoMixto, setaniosTiempoMixto] = useState();

  //usaremos esta variable para definir el plazo maximo dependiendo la edad del mayor
  const [edadMaxima, setedadMaxima] = useState(0);
  const [cuotaEstimadaFija, setCuotaEstimadaFija] = useState(0);
  //el intenres aplica tanto como a fijo y viariable
  const [innteresFija, setIntresFija] = useState(0);
  const [innteresVariable, setInnteresVariable] = useState(0);
  const [innteresMixto, setInnteresMixto] = useState(0);

  const [cuotaEstimadaFijaVariable, setCuotaEstimadaVariable] = useState(0);
  const [cuotaEstimadaVariableEuribor, setCuotaEstimadaVariableEuribor] =
    useState(0);
  const [cuotaEstimadaMixta, setCuotaEstimadaMixta] = useState(0);
  const [cuotaEstimadaMixtaEuribor, setCuotaEstimadaMixtaEuribor] = useState(0);
  //variables de cuotas iniciales pero a plazo maximo
  const [cuotaInicialFijaMaxima, setCuotaInicialFijaMaxima] = useState(0);
  const [cuotaInicialVariableMaxima, setCuotaInicialVariableMaxima] =
    useState(0);
  const [cuotaInicialMixtaMaxima, setcuotaInicialMixtaMaxima] = useState(0);
  const [euribor, setEuribor] = useState(0);
  const [, setDiferencial] = useState(0);
  const [buscarDiferencialEuribor, setBuscarDiferencialEuribor] =
    useState(true);

  const [mostrarEndeu, setMostrarEndeu] = useState("no");
  const [mostrarRatio, setMostrarRatio] = useState("no");

  const [valorFijoMixto, setValorFijoMixto] = useState("fijo");
  const [numEscenario, setNumEscenario] = useState(0);
  //el resultado de las sumas y restas de las penalizaciones y beneficios
  const [porcentaje, setPorcentaje] = useState(100);

  //pena edad
  const [porcentajeUno, setPorcentajeUno] = useState(0);
  //pena endeudamiento
  const [porcentajeDos, setPorcentajeDos] = useState(0);
  //pena segunda penalizacion endeudamiento
  const [porcentajeTres , setPorcentajeTres] = useState(0);
  //bandera para calcular los plazos maximos
  const [unaVez, setUnaVez] = useState(true);
  //alojamiento de plazos y porcentaje
  const [plazoPorcetaje, setPlazoPorcetaje] = useState();
  //mostrar aler de confirmar
  const [mostrarConfirmar, setMostrarConfirmar] = useState(false);
  //mostrar el resultado del calculo
  const [viabilidad, setViabilidad] = useState({
    optUno: true,
    optDos: false,
    optTres: false,
  });
  //en este estado vamos a calcular solo una vez el plazo maximo
  const [calculoDeEdadMaximaPrincipio, setCalculoDeEdadMaximaPrincipio] =
    useState(true);
  //plazo q usaremos para la resta de edad con plazo maximo dependiendo si existe una edadDos
  const [plazoFinalSiExisteEdadDos, setPlazoFinalSiExisteEdadDos] = useState();

  function seteoTipo(valor, tipo) {
    setValorFijoMixto(tipo);
    switch (tipo) {
      case "fijo":
        console.log("fijo");
        setFijo(true);
        setVariable(false);
        setMixto(false);
        break;
      case "variable":
        setFijo(false);
        setVariable(true);
        setMixto(false);
        break;
      case "mixto":
        setFijo(false);
        setVariable(false);
        setMixto(true);
        break;
      default:
        break;
    }
  }

  async function numEndedamiento(
    ingresosNetosMensuales,
    pagasAnuales,
    pagaDeCuotas
  ) {
    let gastosMensuales = "";
    let estimacionCuota = "";

    let pagasExtras = parseInt(pagasAnuales);
    let ingrsosMensuales = parseInt(ingresosNetosMensuales) * pagasExtras;

    if (parseInt(pagaDeCuotas) > 0) {
      gastosMensuales = parseInt(pagaDeCuotas);
    } else {
      gastosMensuales = 0;
    }

    if (valorFijoMixto === "fijo") {
      estimacionCuota = parseInt(cuotaEstimadaFija);
    } else if (valorFijoMixto === "mixto") {
      estimacionCuota = parseInt(cuotaEstimadaMixta);
    } else {
      estimacionCuota = parseInt(cuotaEstimadaFijaVariable);
    }

    let resEndeudamiento =
      ((parseInt(gastosMensuales) + estimacionCuota) / ingrsosMensuales) *
      100 *
      12;

    return resEndeudamiento;
  }

  async function numEndedamientoTotal(ingresosNetosMensuales, pagaDeCuotas) {
    let gastosMensuales = "";
    let estimacionCuota = "";

    let ingrsosMensuales = parseInt(ingresosNetosMensuales);

    if (parseInt(pagaDeCuotas) > 0) {
      gastosMensuales = parseInt(pagaDeCuotas);
    } else {
      gastosMensuales = 0;
    }

    if (valorFijoMixto === "fijo") {
      estimacionCuota = parseInt(cuotaEstimadaFija);
    } else if (valorFijoMixto === "mixto") {
      estimacionCuota = parseInt(cuotaEstimadaMixta);
    } else {
      estimacionCuota = parseInt(cuotaEstimadaFijaVariable);
    }

    let resEndeudamiento =
      ((parseInt(gastosMensuales) + estimacionCuota) / ingrsosMensuales) *
      100 *
      12;

    return resEndeudamiento;
  }

  //calculo de cuota estimada
  async function calcularViabilidad(e) {
    setSolicitudHipotecaria(generarCaracteresAleatorios());
    console.log("entro o no?" + datosCuestionario.financiacionPorcen);
    const resEndeudamiento = await numEndedamiento(
      datosCuestionario.ingresosNetosMensuales,
      datosCuestionario.pagasAnuales,
      datosCuestionario.pagaDeCuotas
    );
    const resEndeudamientoDos = await numEndedamiento(
      datosCuestionario.ingresosNetosMensualesDos,
      datosCuestionario.pagasAnualesDos,
      datosCuestionario.pagaDeCuotasDos
    );
    const resEndeudamientoTres = await numEndedamiento(
      datosCuestionario.ingresosNetosMensualesTres,
      datosCuestionario.pagasAnualesTres,
      datosCuestionario.pagaDeCuotasTres
    );
    const resEndeudamientoCuatro = await numEndedamiento(
      datosCuestionario.ingresosNetosMensualesCuatro,
      datosCuestionario.pagasAnualesCuatro,
      datosCuestionario.pagaDeCuotasCuatro
    );

    console.log(resEndeudamiento + " resultado del ENDEUDAMIENTO ");
    console.log(resEndeudamientoDos + " resultado del ENDEUDAMIENTO DOS");
    console.log(resEndeudamientoTres + " resultado del ENDEUDAMIENTO TRES");
    console.log(resEndeudamientoCuatro + " resultado del ENDEUDAMIENTO CUATRO");
    //paso 1: calcular edad de antiguedad de trabajo
    let edad = await calcularEdad(datosCuestionario.fechaDeIngreso);
    setAntiUno(edad)
    let edadDos = await calcularEdad(datosCuestionario.fechaDeIngresoDos);
    setAntiDos(edadDos)
    let edadTres = await calcularEdad(datosCuestionario.fechaDeIngresoTres);
    setAntiTres(edadTres)
    let edadCuatro = await calcularEdad(datosCuestionario.fechaDeIngresoCuatro);
    setAntiCuatro(edadCuatro)
    let ref = parseInt(datosCuestionario.financiacionPorcen);
    console.log(edad + " :meses de tabajo");
    console.log(
      ref + " escenario"
    );
    //PASO 2: setar el escenario
    if (
      ref >= setearDatosCalculadora.delEscnario0 &&
      ref <= setearDatosCalculadora.alEscnario0
    ) {
      console.log("ESCENARIO CERO");
      setNumEscenario(0);
      await primeraPenalizacion(
        0,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario0meses1,
        setearDatosCalculadora.delEscnario0meses2,
        setearDatosCalculadora.delEscnario0meses3,
        setearDatosCalculadora.delEscnario0meses4,
        setearDatosCalculadora.delEscnario0meses5,
        setearDatosCalculadora.delEscnario0meses6,
        setearDatosCalculadora.delEscnario0meses7,
        setearDatosCalculadora.delEscnario0meses8,
        setearDatosCalculadora.delEscnario0meses9,
        setearDatosCalculadora.delEscnario0meses10,
        setearDatosCalculadora.delEscnario0meses11,
        setearDatosCalculadora.delEscnario0meses12,
        setearDatosCalculadora.delEscnario0meses13,
        setearDatosCalculadora.delEscnario0meses14,
        setearDatosCalculadora.delEscnario0meses15,
        setearDatosCalculadora.delEscnario0pena1,
        setearDatosCalculadora.delEscnario0pena2,
        setearDatosCalculadora.delEscnario0pena3,
        setearDatosCalculadora.delEscnario0pena4,
        setearDatosCalculadora.delEscnario0pena5,
        setearDatosCalculadora.delEscnario0pena6,
        setearDatosCalculadora.delEscnario0pena7,
        setearDatosCalculadora.delEscnario0pena8,
        setearDatosCalculadora.delEscnario0pena9,
        setearDatosCalculadora.delEscnario0pena10,
        setearDatosCalculadora.delEscnario0pena11,
        setearDatosCalculadora.delEscnario0pena12,
        setearDatosCalculadora.delEscnario0pena13,
        setearDatosCalculadora.delEscnario0pena14,
        setearDatosCalculadora.delEscnario0pena15,
        setearDatosCalculadora.delEscnario0antiguedadPena1,
        setearDatosCalculadora.delEscnario0antiguedadPena2,
        setearDatosCalculadora.delEscnario0antiguedadPena3,
        setearDatosCalculadora.delEscnario0antiguedadPena4,
        setearDatosCalculadora.delEscnario0antiguedadPena5,
        setearDatosCalculadora.delEscnario0antiguedadPena6,
        setearDatosCalculadora.delEscnario0antiguedadPena7,
        setearDatosCalculadora.delEscnario0antiguedadPena8,
        setearDatosCalculadora.delEscnario0antiguedadPena9,
        setearDatosCalculadora.delEscnario0antiguedadPena10,
        setearDatosCalculadora.delEscnario0antiguedadPena11,
        setearDatosCalculadora.delEscnario0antiguedadPena12,
        setearDatosCalculadora.delEscnario0antiguedadPena13,
        setearDatosCalculadora.delEscnario0antiguedadPena14,
        setearDatosCalculadora.delEscnario0antiguedadPena15
      );

      console.log(
        "PROBANDO: " +
          setearDatosCalculadora.delEscnario0porcentajeEndeudamiento1
      );
      await calcularEndeudamiento(
        0,
        resEndeudamiento,
        resEndeudamientoDos,
        resEndeudamientoTres,
        resEndeudamientoCuatro,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario0penaEndeudamiento1,
        setearDatosCalculadora.delEscnario0penaEndeudamiento2,
        setearDatosCalculadora.delEscnario0penaEndeudamiento3,
        setearDatosCalculadora.delEscnario0penaEndeudamiento4,
        setearDatosCalculadora.delEscnario0penaEndeudamiento5,
        setearDatosCalculadora.delEscnario0penaEndeudamiento6,
        setearDatosCalculadora.delEscnario0penaEndeudamiento7,
        setearDatosCalculadora.delEscnario0penaEndeudamiento8,
        setearDatosCalculadora.delEscnario0penaEndeudamiento9,
        setearDatosCalculadora.delEscnario0penaEndeudamiento10,
        setearDatosCalculadora.delEscnario0penaEndeudamiento11,
        setearDatosCalculadora.delEscnario0penaEndeudamiento12,
        setearDatosCalculadora.delEscnario0penaEndeudamiento13,
        setearDatosCalculadora.delEscnario0penaEndeudamiento14,
        setearDatosCalculadora.delEscnario0penaEndeudamiento15,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento1,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento2,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento3,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento4,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento5,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento6,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento7,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento8,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento9,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento10,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento11,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento12,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento13,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento14,
        setearDatosCalculadora.delEscnario0porcentajeEndeudamiento15
      );
    } else if (
      ref >= setearDatosCalculadora.delEscnario1 &&
      ref <= setearDatosCalculadora.alEscnario1
    ) {
      console.log("ESCENARIO UNO");
      setNumEscenario(1);
      await primeraPenalizacion(
        1,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario1meses1,
        setearDatosCalculadora.delEscnario1meses2,
        setearDatosCalculadora.delEscnario1meses3,
        setearDatosCalculadora.delEscnario1meses4,
        setearDatosCalculadora.delEscnario1meses5,
        setearDatosCalculadora.delEscnario1meses6,
        setearDatosCalculadora.delEscnario1meses7,
        setearDatosCalculadora.delEscnario1meses8,
        setearDatosCalculadora.delEscnario1meses9,
        setearDatosCalculadora.delEscnario1meses10,
        setearDatosCalculadora.delEscnario1meses11,
        setearDatosCalculadora.delEscnario1meses12,
        setearDatosCalculadora.delEscnario1meses13,
        setearDatosCalculadora.delEscnario1meses14,
        setearDatosCalculadora.delEscnario1meses15,
        setearDatosCalculadora.delEscnario1pena1,
        setearDatosCalculadora.delEscnario1pena2,
        setearDatosCalculadora.delEscnario1pena3,
        setearDatosCalculadora.delEscnario1pena4,
        setearDatosCalculadora.delEscnario1pena5,
        setearDatosCalculadora.delEscnario1pena6,
        setearDatosCalculadora.delEscnario1pena7,
        setearDatosCalculadora.delEscnario1pena8,
        setearDatosCalculadora.delEscnario1pena9,
        setearDatosCalculadora.delEscnario1pena10,
        setearDatosCalculadora.delEscnario1pena11,
        setearDatosCalculadora.delEscnario1pena12,
        setearDatosCalculadora.delEscnario1pena13,
        setearDatosCalculadora.delEscnario1pena14,
        setearDatosCalculadora.delEscnario1pena15,
        setearDatosCalculadora.delEscnario1antiguedadPena1,
        setearDatosCalculadora.delEscnario1antiguedadPena2,
        setearDatosCalculadora.delEscnario1antiguedadPena3,
        setearDatosCalculadora.delEscnario1antiguedadPena4,
        setearDatosCalculadora.delEscnario1antiguedadPena5,
        setearDatosCalculadora.delEscnario1antiguedadPena6,
        setearDatosCalculadora.delEscnario1antiguedadPena7,
        setearDatosCalculadora.delEscnario1antiguedadPena8,
        setearDatosCalculadora.delEscnario1antiguedadPena9,
        setearDatosCalculadora.delEscnario1antiguedadPena10,
        setearDatosCalculadora.delEscnario1antiguedadPena11,
        setearDatosCalculadora.delEscnario1antiguedadPena12,
        setearDatosCalculadora.delEscnario1antiguedadPena13,
        setearDatosCalculadora.delEscnario1antiguedadPena14,
        setearDatosCalculadora.delEscnario1antiguedadPena15
      );

      await calcularEndeudamiento(
        1,
        resEndeudamiento, //sacar mas 100
        resEndeudamientoDos,
        resEndeudamientoTres,
        resEndeudamientoCuatro,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario1penaEndeudamiento1,
        setearDatosCalculadora.delEscnario1penaEndeudamiento2,
        setearDatosCalculadora.delEscnario1penaEndeudamiento3,
        setearDatosCalculadora.delEscnario1penaEndeudamiento4,
        setearDatosCalculadora.delEscnario1penaEndeudamiento5,
        setearDatosCalculadora.delEscnario1penaEndeudamiento6,
        setearDatosCalculadora.delEscnario1penaEndeudamiento7,
        setearDatosCalculadora.delEscnario1penaEndeudamiento8,
        setearDatosCalculadora.delEscnario1penaEndeudamiento9,
        setearDatosCalculadora.delEscnario1penaEndeudamiento10,
        setearDatosCalculadora.delEscnario1penaEndeudamiento11,
        setearDatosCalculadora.delEscnario1penaEndeudamiento12,
        setearDatosCalculadora.delEscnario1penaEndeudamiento13,
        setearDatosCalculadora.delEscnario1penaEndeudamiento14,
        setearDatosCalculadora.delEscnario1penaEndeudamiento15,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento1,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento2,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento3,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento4,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento5,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento6,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento7,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento8,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento9,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento10,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento11,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento12,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento13,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento14,
        setearDatosCalculadora.delEscnario1porcentajeEndeudamiento15
      );
    } else if (
      ref >= setearDatosCalculadora.delEscnario2 &&
      ref <= setearDatosCalculadora.alEscnario2
    ) {
      console.log("ESCENARIO DOS");
      setNumEscenario(2);
      await primeraPenalizacion(
        2,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario2meses1,
        setearDatosCalculadora.delEscnario2meses2,
        setearDatosCalculadora.delEscnario2meses3,
        setearDatosCalculadora.delEscnario2meses4,
        setearDatosCalculadora.delEscnario2meses5,
        setearDatosCalculadora.delEscnario2meses6,
        setearDatosCalculadora.delEscnario2meses7,
        setearDatosCalculadora.delEscnario2meses8,
        setearDatosCalculadora.delEscnario2meses9,
        setearDatosCalculadora.delEscnario2meses10,
        setearDatosCalculadora.delEscnario2meses11,
        setearDatosCalculadora.delEscnario2meses12,
        setearDatosCalculadora.delEscnario2meses13,
        setearDatosCalculadora.delEscnario2meses14,
        setearDatosCalculadora.delEscnario2meses15,
        setearDatosCalculadora.delEscnario2pena1,
        setearDatosCalculadora.delEscnario2pena2,
        setearDatosCalculadora.delEscnario2pena3,
        setearDatosCalculadora.delEscnario2pena4,
        setearDatosCalculadora.delEscnario2pena5,
        setearDatosCalculadora.delEscnario2pena6,
        setearDatosCalculadora.delEscnario2pena7,
        setearDatosCalculadora.delEscnario2pena8,
        setearDatosCalculadora.delEscnario2pena9,
        setearDatosCalculadora.delEscnario2pena10,
        setearDatosCalculadora.delEscnario2pena11,
        setearDatosCalculadora.delEscnario2pena12,
        setearDatosCalculadora.delEscnario2pena13,
        setearDatosCalculadora.delEscnario2pena14,
        setearDatosCalculadora.delEscnario2pena15,
        setearDatosCalculadora.delEscnario2antiguedadPena1,
        setearDatosCalculadora.delEscnario2antiguedadPena2,
        setearDatosCalculadora.delEscnario2antiguedadPena3,
        setearDatosCalculadora.delEscnario2antiguedadPena4,
        setearDatosCalculadora.delEscnario2antiguedadPena5,
        setearDatosCalculadora.delEscnario2antiguedadPena6,
        setearDatosCalculadora.delEscnario2antiguedadPena7,
        setearDatosCalculadora.delEscnario2antiguedadPena8,
        setearDatosCalculadora.delEscnario2antiguedadPena9,
        setearDatosCalculadora.delEscnario2antiguedadPena10,
        setearDatosCalculadora.delEscnario2antiguedadPena11,
        setearDatosCalculadora.delEscnario2antiguedadPena12,
        setearDatosCalculadora.delEscnario2antiguedadPena13,
        setearDatosCalculadora.delEscnario2antiguedadPena14,
        setearDatosCalculadora.delEscnario2antiguedadPena15
      );

      await calcularEndeudamiento(
        2,
        resEndeudamiento, //sacar mas 100
        resEndeudamientoDos,
        resEndeudamientoTres,
        resEndeudamientoCuatro,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario2penaEndeudamiento1,
        setearDatosCalculadora.delEscnario2penaEndeudamiento2,
        setearDatosCalculadora.delEscnario2penaEndeudamiento3,
        setearDatosCalculadora.delEscnario2penaEndeudamiento4,
        setearDatosCalculadora.delEscnario2penaEndeudamiento5,
        setearDatosCalculadora.delEscnario2penaEndeudamiento6,
        setearDatosCalculadora.delEscnario2penaEndeudamiento7,
        setearDatosCalculadora.delEscnario2penaEndeudamiento8,
        setearDatosCalculadora.delEscnario2penaEndeudamiento9,
        setearDatosCalculadora.delEscnario2penaEndeudamiento10,
        setearDatosCalculadora.delEscnario2penaEndeudamiento11,
        setearDatosCalculadora.delEscnario2penaEndeudamiento12,
        setearDatosCalculadora.delEscnario2penaEndeudamiento13,
        setearDatosCalculadora.delEscnario2penaEndeudamiento14,
        setearDatosCalculadora.delEscnario2penaEndeudamiento15,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento1,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento2,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento3,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento4,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento5,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento6,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento7,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento8,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento9,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento10,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento11,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento12,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento13,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento14,
        setearDatosCalculadora.delEscnario2porcentajeEndeudamiento15
      );
    } else if (
      ref >= setearDatosCalculadora.delEscnario3 &&
      ref <= setearDatosCalculadora.alEscnario3
    ) {
      console.log("ESCENARIO TRES");
      setNumEscenario(3);
      await primeraPenalizacion(
        3,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario3meses1,
        setearDatosCalculadora.delEscnario3meses2,
        setearDatosCalculadora.delEscnario3meses3,
        setearDatosCalculadora.delEscnario3meses4,
        setearDatosCalculadora.delEscnario3meses5,
        setearDatosCalculadora.delEscnario3meses6,
        setearDatosCalculadora.delEscnario3meses7,
        setearDatosCalculadora.delEscnario3meses8,
        setearDatosCalculadora.delEscnario3meses9,
        setearDatosCalculadora.delEscnario3meses10,
        setearDatosCalculadora.delEscnario3meses11,
        setearDatosCalculadora.delEscnario3meses12,
        setearDatosCalculadora.delEscnario3meses13,
        setearDatosCalculadora.delEscnario3meses14,
        setearDatosCalculadora.delEscnario3meses15,
        setearDatosCalculadora.delEscnario3pena1,
        setearDatosCalculadora.delEscnario3pena2,
        setearDatosCalculadora.delEscnario3pena3,
        setearDatosCalculadora.delEscnario3pena4,
        setearDatosCalculadora.delEscnario3pena5,
        setearDatosCalculadora.delEscnario3pena6,
        setearDatosCalculadora.delEscnario3pena7,
        setearDatosCalculadora.delEscnario3pena8,
        setearDatosCalculadora.delEscnario3pena9,
        setearDatosCalculadora.delEscnario3pena10,
        setearDatosCalculadora.delEscnario3pena11,
        setearDatosCalculadora.delEscnario3pena12,
        setearDatosCalculadora.delEscnario3pena13,
        setearDatosCalculadora.delEscnario3pena14,
        setearDatosCalculadora.delEscnario3pena15,
        setearDatosCalculadora.delEscnario3antiguedadPena1,
        setearDatosCalculadora.delEscnario3antiguedadPena2,
        setearDatosCalculadora.delEscnario3antiguedadPena3,
        setearDatosCalculadora.delEscnario3antiguedadPena4,
        setearDatosCalculadora.delEscnario3antiguedadPena5,
        setearDatosCalculadora.delEscnario3antiguedadPena6,
        setearDatosCalculadora.delEscnario3antiguedadPena7,
        setearDatosCalculadora.delEscnario3antiguedadPena8,
        setearDatosCalculadora.delEscnario3antiguedadPena9,
        setearDatosCalculadora.delEscnario3antiguedadPena10,
        setearDatosCalculadora.delEscnario3antiguedadPena11,
        setearDatosCalculadora.delEscnario3antiguedadPena12,
        setearDatosCalculadora.delEscnario3antiguedadPena13,
        setearDatosCalculadora.delEscnario3antiguedadPena14,
        setearDatosCalculadora.delEscnario3antiguedadPena15
      );

      await calcularEndeudamiento(
        3,
        resEndeudamiento, //sacar mas 100
        resEndeudamientoDos,
        resEndeudamientoTres,
        resEndeudamientoCuatro,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario3penaEndeudamiento1,
        setearDatosCalculadora.delEscnario3penaEndeudamiento2,
        setearDatosCalculadora.delEscnario3penaEndeudamiento3,
        setearDatosCalculadora.delEscnario3penaEndeudamiento4,
        setearDatosCalculadora.delEscnario3penaEndeudamiento5,
        setearDatosCalculadora.delEscnario3penaEndeudamiento6,
        setearDatosCalculadora.delEscnario3penaEndeudamiento7,
        setearDatosCalculadora.delEscnario3penaEndeudamiento8,
        setearDatosCalculadora.delEscnario3penaEndeudamiento9,
        setearDatosCalculadora.delEscnario3penaEndeudamiento10,
        setearDatosCalculadora.delEscnario3penaEndeudamiento11,
        setearDatosCalculadora.delEscnario3penaEndeudamiento12,
        setearDatosCalculadora.delEscnario3penaEndeudamiento13,
        setearDatosCalculadora.delEscnario3penaEndeudamiento14,
        setearDatosCalculadora.delEscnario3penaEndeudamiento15,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento1,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento2,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento3,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento4,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento5,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento6,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento7,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento8,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento9,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento10,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento11,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento12,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento13,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento14,
        setearDatosCalculadora.delEscnario3porcentajeEndeudamiento15
      );
    } else if (
      ref >= setearDatosCalculadora.delEscnario4 &&
      ref <= setearDatosCalculadora.alEscnario4
    ) {
      console.log("ESCENARIO CUATRO");
      setNumEscenario(4);
      await primeraPenalizacion(
        4,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario4meses1,
        setearDatosCalculadora.delEscnario4meses2,
        setearDatosCalculadora.delEscnario4meses3,
        setearDatosCalculadora.delEscnario4meses4,
        setearDatosCalculadora.delEscnario4meses5,
        setearDatosCalculadora.delEscnario4meses6,
        setearDatosCalculadora.delEscnario4meses7,
        setearDatosCalculadora.delEscnario4meses8,
        setearDatosCalculadora.delEscnario4meses9,
        setearDatosCalculadora.delEscnario4meses10,
        setearDatosCalculadora.delEscnario4meses11,
        setearDatosCalculadora.delEscnario4meses12,
        setearDatosCalculadora.delEscnario4meses13,
        setearDatosCalculadora.delEscnario4meses14,
        setearDatosCalculadora.delEscnario4meses15,
        setearDatosCalculadora.delEscnario4pena1,
        setearDatosCalculadora.delEscnario4pena2,
        setearDatosCalculadora.delEscnario4pena3,
        setearDatosCalculadora.delEscnario4pena4,
        setearDatosCalculadora.delEscnario4pena5,
        setearDatosCalculadora.delEscnario4pena6,
        setearDatosCalculadora.delEscnario4pena7,
        setearDatosCalculadora.delEscnario4pena8,
        setearDatosCalculadora.delEscnario4pena9,
        setearDatosCalculadora.delEscnario4pena10,
        setearDatosCalculadora.delEscnario4pena11,
        setearDatosCalculadora.delEscnario4pena12,
        setearDatosCalculadora.delEscnario4pena13,
        setearDatosCalculadora.delEscnario4pena14,
        setearDatosCalculadora.delEscnario4pena15,
        setearDatosCalculadora.delEscnario4antiguedadPena1,
        setearDatosCalculadora.delEscnario4antiguedadPena2,
        setearDatosCalculadora.delEscnario4antiguedadPena3,
        setearDatosCalculadora.delEscnario4antiguedadPena4,
        setearDatosCalculadora.delEscnario4antiguedadPena5,
        setearDatosCalculadora.delEscnario4antiguedadPena6,
        setearDatosCalculadora.delEscnario4antiguedadPena7,
        setearDatosCalculadora.delEscnario4antiguedadPena8,
        setearDatosCalculadora.delEscnario4antiguedadPena9,
        setearDatosCalculadora.delEscnario4antiguedadPena10,
        setearDatosCalculadora.delEscnario4antiguedadPena11,
        setearDatosCalculadora.delEscnario4antiguedadPena12,
        setearDatosCalculadora.delEscnario4antiguedadPena13,
        setearDatosCalculadora.delEscnario4antiguedadPena14,
        setearDatosCalculadora.delEscnario4antiguedadPena15
      );

      await calcularEndeudamiento(
        4,
        resEndeudamiento, //sacar mas 100
        resEndeudamientoDos,
        resEndeudamientoTres,
        resEndeudamientoCuatro,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario4penaEndeudamiento1,
        setearDatosCalculadora.delEscnario4penaEndeudamiento2,
        setearDatosCalculadora.delEscnario4penaEndeudamiento3,
        setearDatosCalculadora.delEscnario4penaEndeudamiento4,
        setearDatosCalculadora.delEscnario4penaEndeudamiento5,
        setearDatosCalculadora.delEscnario4penaEndeudamiento6,
        setearDatosCalculadora.delEscnario4penaEndeudamiento7,
        setearDatosCalculadora.delEscnario4penaEndeudamiento8,
        setearDatosCalculadora.delEscnario4penaEndeudamiento9,
        setearDatosCalculadora.delEscnario4penaEndeudamiento10,
        setearDatosCalculadora.delEscnario4penaEndeudamiento11,
        setearDatosCalculadora.delEscnario4penaEndeudamiento12,
        setearDatosCalculadora.delEscnario4penaEndeudamiento13,
        setearDatosCalculadora.delEscnario4penaEndeudamiento14,
        setearDatosCalculadora.delEscnario4penaEndeudamiento15,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento1,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento2,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento3,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento4,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento5,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento6,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento7,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento8,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento9,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento10,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento11,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento12,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento13,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento14,
        setearDatosCalculadora.delEscnario4porcentajeEndeudamiento15
      );
    } else if (
      ref >= setearDatosCalculadora.delEscnario5 &&
      ref <= setearDatosCalculadora.alEscnario5
    ) {
      console.log("ESCENARIO CINCO");
      setNumEscenario(5);
      await primeraPenalizacion(
        5,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario5meses1,
        setearDatosCalculadora.delEscnario5meses2,
        setearDatosCalculadora.delEscnario5meses3,
        setearDatosCalculadora.delEscnario5meses4,
        setearDatosCalculadora.delEscnario5meses5,
        setearDatosCalculadora.delEscnario5meses6,
        setearDatosCalculadora.delEscnario5meses7,
        setearDatosCalculadora.delEscnario5meses8,
        setearDatosCalculadora.delEscnario5meses9,
        setearDatosCalculadora.delEscnario5meses10,
        setearDatosCalculadora.delEscnario5meses11,
        setearDatosCalculadora.delEscnario5meses12,
        setearDatosCalculadora.delEscnario5meses13,
        setearDatosCalculadora.delEscnario5meses14,
        setearDatosCalculadora.delEscnario5meses15,
        setearDatosCalculadora.delEscnario5pena1,
        setearDatosCalculadora.delEscnario5pena2,
        setearDatosCalculadora.delEscnario5pena3,
        setearDatosCalculadora.delEscnario5pena4,
        setearDatosCalculadora.delEscnario5pena5,
        setearDatosCalculadora.delEscnario5pena6,
        setearDatosCalculadora.delEscnario5pena7,
        setearDatosCalculadora.delEscnario5pena8,
        setearDatosCalculadora.delEscnario5pena9,
        setearDatosCalculadora.delEscnario5pena10,
        setearDatosCalculadora.delEscnario5pena11,
        setearDatosCalculadora.delEscnario5pena12,
        setearDatosCalculadora.delEscnario5pena13,
        setearDatosCalculadora.delEscnario5pena14,
        setearDatosCalculadora.delEscnario5pena15,
        setearDatosCalculadora.delEscnario5antiguedadPena1,
        setearDatosCalculadora.delEscnario5antiguedadPena2,
        setearDatosCalculadora.delEscnario5antiguedadPena3,
        setearDatosCalculadora.delEscnario5antiguedadPena4,
        setearDatosCalculadora.delEscnario5antiguedadPena5,
        setearDatosCalculadora.delEscnario5antiguedadPena6,
        setearDatosCalculadora.delEscnario5antiguedadPena7,
        setearDatosCalculadora.delEscnario5antiguedadPena8,
        setearDatosCalculadora.delEscnario5antiguedadPena9,
        setearDatosCalculadora.delEscnario5antiguedadPena10,
        setearDatosCalculadora.delEscnario5antiguedadPena11,
        setearDatosCalculadora.delEscnario5antiguedadPena12,
        setearDatosCalculadora.delEscnario5antiguedadPena13,
        setearDatosCalculadora.delEscnario5antiguedadPena14,
        setearDatosCalculadora.delEscnario5antiguedadPena15
      );

      await calcularEndeudamiento(
        5,
        resEndeudamiento, //sacar mas 100
        resEndeudamientoDos,
        resEndeudamientoTres,
        resEndeudamientoCuatro,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario5penaEndeudamiento1,
        setearDatosCalculadora.delEscnario5penaEndeudamiento2,
        setearDatosCalculadora.delEscnario5penaEndeudamiento3,
        setearDatosCalculadora.delEscnario5penaEndeudamiento4,
        setearDatosCalculadora.delEscnario5penaEndeudamiento5,
        setearDatosCalculadora.delEscnario5penaEndeudamiento6,
        setearDatosCalculadora.delEscnario5penaEndeudamiento7,
        setearDatosCalculadora.delEscnario5penaEndeudamiento8,
        setearDatosCalculadora.delEscnario5penaEndeudamiento9,
        setearDatosCalculadora.delEscnario5penaEndeudamiento10,
        setearDatosCalculadora.delEscnario5penaEndeudamiento11,
        setearDatosCalculadora.delEscnario5penaEndeudamiento12,
        setearDatosCalculadora.delEscnario5penaEndeudamiento13,
        setearDatosCalculadora.delEscnario5penaEndeudamiento14,
        setearDatosCalculadora.delEscnario5penaEndeudamiento15,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento1,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento2,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento3,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento4,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento5,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento6,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento7,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento8,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento9,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento10,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento11,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento12,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento13,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento14,
        setearDatosCalculadora.delEscnario5porcentajeEndeudamiento15
      );
    } else if (
      ref >= setearDatosCalculadora.delEscnario6 &&
      ref <= setearDatosCalculadora.alEscnario6
    ) {
      console.log("ESCENARIO SEIS");
      setNumEscenario(6);
      await primeraPenalizacion(
        6,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario6meses1,
        setearDatosCalculadora.delEscnario6meses2,
        setearDatosCalculadora.delEscnario6meses3,
        setearDatosCalculadora.delEscnario6meses4,
        setearDatosCalculadora.delEscnario6meses5,
        setearDatosCalculadora.delEscnario6meses6,
        setearDatosCalculadora.delEscnario6meses7,
        setearDatosCalculadora.delEscnario6meses8,
        setearDatosCalculadora.delEscnario6meses9,
        setearDatosCalculadora.delEscnario6meses10,
        setearDatosCalculadora.delEscnario6meses11,
        setearDatosCalculadora.delEscnario6meses12,
        setearDatosCalculadora.delEscnario6meses13,
        setearDatosCalculadora.delEscnario6meses14,
        setearDatosCalculadora.delEscnario6meses15,
        setearDatosCalculadora.delEscnario6pena1,
        setearDatosCalculadora.delEscnario6pena2,
        setearDatosCalculadora.delEscnario6pena3,
        setearDatosCalculadora.delEscnario6pena4,
        setearDatosCalculadora.delEscnario6pena5,
        setearDatosCalculadora.delEscnario6pena6,
        setearDatosCalculadora.delEscnario6pena7,
        setearDatosCalculadora.delEscnario6pena8,
        setearDatosCalculadora.delEscnario6pena9,
        setearDatosCalculadora.delEscnario6pena10,
        setearDatosCalculadora.delEscnario6pena11,
        setearDatosCalculadora.delEscnario6pena12,
        setearDatosCalculadora.delEscnario6pena13,
        setearDatosCalculadora.delEscnario6pena14,
        setearDatosCalculadora.delEscnario6pena15,
        setearDatosCalculadora.delEscnario6antiguedadPena1,
        setearDatosCalculadora.delEscnario6antiguedadPena2,
        setearDatosCalculadora.delEscnario6antiguedadPena3,
        setearDatosCalculadora.delEscnario6antiguedadPena4,
        setearDatosCalculadora.delEscnario6antiguedadPena5,
        setearDatosCalculadora.delEscnario6antiguedadPena6,
        setearDatosCalculadora.delEscnario6antiguedadPena7,
        setearDatosCalculadora.delEscnario6antiguedadPena8,
        setearDatosCalculadora.delEscnario6antiguedadPena9,
        setearDatosCalculadora.delEscnario6antiguedadPena10,
        setearDatosCalculadora.delEscnario6antiguedadPena11,
        setearDatosCalculadora.delEscnario6antiguedadPena12,
        setearDatosCalculadora.delEscnario6antiguedadPena13,
        setearDatosCalculadora.delEscnario6antiguedadPena14,
        setearDatosCalculadora.delEscnario6antiguedadPena15
      );

      await calcularEndeudamiento(
        6,
        resEndeudamiento, //sacar mas 100
        resEndeudamientoDos,
        resEndeudamientoTres,
        resEndeudamientoCuatro,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario6penaEndeudamiento1,
        setearDatosCalculadora.delEscnario6penaEndeudamiento2,
        setearDatosCalculadora.delEscnario6penaEndeudamiento3,
        setearDatosCalculadora.delEscnario6penaEndeudamiento4,
        setearDatosCalculadora.delEscnario6penaEndeudamiento5,
        setearDatosCalculadora.delEscnario6penaEndeudamiento6,
        setearDatosCalculadora.delEscnario6penaEndeudamiento7,
        setearDatosCalculadora.delEscnario6penaEndeudamiento8,
        setearDatosCalculadora.delEscnario6penaEndeudamiento9,
        setearDatosCalculadora.delEscnario6penaEndeudamiento10,
        setearDatosCalculadora.delEscnario6penaEndeudamiento11,
        setearDatosCalculadora.delEscnario6penaEndeudamiento12,
        setearDatosCalculadora.delEscnario6penaEndeudamiento13,
        setearDatosCalculadora.delEscnario6penaEndeudamiento14,
        setearDatosCalculadora.delEscnario6penaEndeudamiento15,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento1,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento2,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento3,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento4,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento5,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento6,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento7,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento8,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento9,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento10,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento11,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento12,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento13,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento14,
        setearDatosCalculadora.delEscnario6porcentajeEndeudamiento15
      );
    } else if (
      ref >= setearDatosCalculadora.delEscnario7 &&
      ref <= setearDatosCalculadora.alEscnario7
    ) {
      console.log("ESCENARIO SIETE");
      setNumEscenario(7);
      await primeraPenalizacion(
        7,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario7meses1,
        setearDatosCalculadora.delEscnario7meses2,
        setearDatosCalculadora.delEscnario7meses3,
        setearDatosCalculadora.delEscnario7meses4,
        setearDatosCalculadora.delEscnario7meses5,
        setearDatosCalculadora.delEscnario7meses6,
        setearDatosCalculadora.delEscnario7meses7,
        setearDatosCalculadora.delEscnario7meses8,
        setearDatosCalculadora.delEscnario7meses9,
        setearDatosCalculadora.delEscnario7meses10,
        setearDatosCalculadora.delEscnario7meses11,
        setearDatosCalculadora.delEscnario7meses12,
        setearDatosCalculadora.delEscnario7meses13,
        setearDatosCalculadora.delEscnario7meses14,
        setearDatosCalculadora.delEscnario7meses15,
        setearDatosCalculadora.delEscnario7pena1,
        setearDatosCalculadora.delEscnario7pena2,
        setearDatosCalculadora.delEscnario7pena3,
        setearDatosCalculadora.delEscnario7pena4,
        setearDatosCalculadora.delEscnario7pena5,
        setearDatosCalculadora.delEscnario7pena6,
        setearDatosCalculadora.delEscnario7pena7,
        setearDatosCalculadora.delEscnario7pena8,
        setearDatosCalculadora.delEscnario7pena9,
        setearDatosCalculadora.delEscnario7pena10,
        setearDatosCalculadora.delEscnario7pena11,
        setearDatosCalculadora.delEscnario7pena12,
        setearDatosCalculadora.delEscnario7pena13,
        setearDatosCalculadora.delEscnario7pena14,
        setearDatosCalculadora.delEscnario7pena15,
        setearDatosCalculadora.delEscnario7antiguedadPena1,
        setearDatosCalculadora.delEscnario7antiguedadPena2,
        setearDatosCalculadora.delEscnario7antiguedadPena3,
        setearDatosCalculadora.delEscnario7antiguedadPena4,
        setearDatosCalculadora.delEscnario7antiguedadPena5,
        setearDatosCalculadora.delEscnario7antiguedadPena6,
        setearDatosCalculadora.delEscnario7antiguedadPena7,
        setearDatosCalculadora.delEscnario7antiguedadPena8,
        setearDatosCalculadora.delEscnario7antiguedadPena9,
        setearDatosCalculadora.delEscnario7antiguedadPena10,
        setearDatosCalculadora.delEscnario7antiguedadPena11,
        setearDatosCalculadora.delEscnario7antiguedadPena12,
        setearDatosCalculadora.delEscnario7antiguedadPena13,
        setearDatosCalculadora.delEscnario7antiguedadPena14,
        setearDatosCalculadora.delEscnario7antiguedadPena15
      );

      await calcularEndeudamiento(
        7,
        resEndeudamiento, //sacar mas 100
        resEndeudamientoDos,
        resEndeudamientoTres,
        resEndeudamientoCuatro,
        edad,
        edadDos,
        edadTres,
        edadCuatro,
        setearDatosCalculadora.delEscnario7penaEndeudamiento1,
        setearDatosCalculadora.delEscnario7penaEndeudamiento2,
        setearDatosCalculadora.delEscnario7penaEndeudamiento3,
        setearDatosCalculadora.delEscnario7penaEndeudamiento4,
        setearDatosCalculadora.delEscnario7penaEndeudamiento5,
        setearDatosCalculadora.delEscnario7penaEndeudamiento6,
        setearDatosCalculadora.delEscnario7penaEndeudamiento7,
        setearDatosCalculadora.delEscnario7penaEndeudamiento8,
        setearDatosCalculadora.delEscnario7penaEndeudamiento9,
        setearDatosCalculadora.delEscnario7penaEndeudamiento10,
        setearDatosCalculadora.delEscnario7penaEndeudamiento11,
        setearDatosCalculadora.delEscnario7penaEndeudamiento12,
        setearDatosCalculadora.delEscnario7penaEndeudamiento13,
        setearDatosCalculadora.delEscnario7penaEndeudamiento14,
        setearDatosCalculadora.delEscnario7penaEndeudamiento15,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento1,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento2,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento3,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento4,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento5,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento6,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento7,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento8,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento9,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento10,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento11,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento12,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento13,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento14,
        setearDatosCalculadora.delEscnario7porcentajeEndeudamiento15
      );
    }
    // setMostrarConfirmar(true);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Estas por realizar el calculo de viabilidad",
        text: "Deseas confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, adelante!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          resultado(e);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          setPorcentaje(100);
        }
      });
  }

  async function resultado(e) {
    console.log(porcentaje + " #porcentaje");
    setPorcentaje(porcentaje - porcentajeUno - porcentajeDos - porcentajeTres);
    console.log(porcentajeUno + " uno");
    console.log(porcentajeDos + " dos");
    console.log(porcentajeTres + " tres");
    console.log("******************************************");
    console.log(porcentaje - porcentajeUno - porcentajeDos - porcentajeTres);
    console.log("******************************************");
    //cambiar a false
    setViabilidad(true);
    console.log(porcentaje + " :RESULTADO FINAL");
    setDatosCuestionario({
      ...datosCuestionario,
      [e.target.name]: e.target.value,
      hipoteca: valorFijoMixto,
      plazoHipoteca: aniosTiempo,
      cuotaEstimadaFija: cuotaEstimadaFija,

      cuotaEstimadaVariable: Math.trunc(cuotaEstimadaFijaVariable),
      cuotaEstimadaVariableEuribor: Math.trunc(cuotaEstimadaVariableEuribor),

      cuotaEstimadaMixta: Math.trunc(cuotaEstimadaMixta),
      cuotaEstimadaMixtaEuribor: Math.trunc(cuotaEstimadaMixtaEuribor),

      escenario: numEscenario,
      valorFijoMixto: valorFijoMixto,
      aniosTiempo: aniosTiempo,
      aniosTiempoVariable: aniosTiempoVariable,
      aniosTiempoMixto: aniosTiempoMixto,

      innteresFija: innteresFija,

      innteresVariable: innteresVariable,
      innteresVariableEuribor: setearDatosCalculadora.tinPrimerosMesesVariable,

      interesMixta: innteresMixto,
      interesMixtaEuribor: setearDatosCalculadora.tinPrimerosMesesMixto,
    });

    guardarSimulacionEnBD()
  }

  //paso 1: calcular edad
  async function calcularEdad(fecha) {
    // const fechaActual = new Date();
    // const anoActual = parseInt(fechaActual.getFullYear());

    // const anoNacimiento = parseInt(String(fecha).substring(0, 4));

    // let edad = anoActual - anoNacimiento;

    // let edadFinal = (edad * 12) / 1;
    const fechaActual = new Date();
    const fechaIngresada = new Date(fecha);

    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    const diaActual = fechaActual.getDate();

    const anioIngresado = fechaIngresada.getFullYear();
    const mesIngresado = fechaIngresada.getMonth() + 1;
    const diaIngresado = fechaIngresada.getDate();

    const mesesTranscurridos =
      (anioActual - anioIngresado) * 12 + (mesActual - mesIngresado);

    if (diaActual < diaIngresado) {
      return mesesTranscurridos - 1;
    }

    return mesesTranscurridos;
  }
  //paso 1: bis
  // function penaFecha(mesesTrabajados, meses) {
  //   let res = false;

  //   if (mesesTrabajados === undefined || mesesTrabajados === null) {
  //     res = true;
  //   } else {
  //     if (mesesTrabajados < parseInt(meses)) {
  //       res = true;
  //     }
  //   }

  //   return res;
  // }
  //paso 2: buscar contrato coincidinte
  async function primeraPenalizacion(
    escenario,
    mesesTrabajados,
    mesesTrabajadosDos,
    mesesTrabajadosTres,
    mesesTrabajadosCuatro,
    mesesUno,
    mesesDos,
    mesesTres,
    mesesCuatro,
    mesesCinco,
    mesesSeis,
    mesesSiete,
    mesesOcho,
    mesesNueve,
    mesesDiez,
    mesesOnce,
    mesesDoce,
    mesesTrece,
    mesesCatorce,
    mesesQuince,
    penaUno,
    penaDos,
    penaTres,
    penaCuatro,
    penaCinco,
    pensaSeis,
    penaSiete,
    penaOcho,
    penaNueve,
    penaDiez,
    penaOnce,
    penaDoce,
    penaTrece,
    penaCatorce,
    penaQuince,
    penaUnoSegunda,
    penaDosSegunda,
    penaTresSegunda,
    penaCuatroSegunda,
    penaCincoSegunda,
    pensaSeisSegunda,
    penaSieteSegunda,
    penaOchoSegunda,
    penaNueveSegunda,
    penaDiezSegunda,
    penaOnceSegunda,
    penaDoceSegunda,
    penaTreceSegunda,
    penaCatorceSegunda,
    penaQuinceSegunda
  ) {
    let indiceContrato = -1;
    let indiceContratoDos = -1;
    let indiceContratoTres = -1;
    let indiceContratoCuatro = -1;
    //definimos variables extras para poder setear la penalizacion mas baja
    let penaBaja = 0
    let penaBajaDos = 0
    let penaBajaTres = 0
    let penaBajaCuatro = 0
    setNumEscenario(escenario);
    //Contra UNO
    for (let index = 0; index < contratoBD.length; index++) {
      if (datosCuestionario.situacionLaboral === contratoBD[index].nombre) {
        indiceContrato = parseInt(contratoBD[index].id);
        penaBaja = setearDatosCalculadora[
          `delEscnario${escenario}pena${indiceContrato}`
        ];
        setIndiceUno(indiceContrato)
        break;
      }
    }

    setearPenaDobleGarantia(escenario,indiceContrato)
    //CONTRATO DOS
    if (datosCuestionario.situacionLaboralDos !== undefined) {
      for (let index = 0; index < contratoBD.length; index++) {
        if (
          datosCuestionario.situacionLaboralDos === contratoBD[index].nombre
        ) {
          indiceContratoDos = parseInt(contratoBD[index].id);
          penaBajaDos = setearDatosCalculadora[
            `delEscnario${escenario}pena${indiceContratoDos}`
          ];
          setIndiceDos(indiceContratoDos)
          break;
        }
      }
    }
    //CONTRATOS TRES
    if (datosCuestionario.situacionLaboralTres !== undefined) {
      for (let index = 0; index < contratoBD.length; index++) {
        if (
          datosCuestionario.situacionLaboralTres === contratoBD[index].nombre
        ) {
          indiceContratoTres = parseInt(contratoBD[index].id);
          penaBajaTres = setearDatosCalculadora[
            `delEscnario${escenario}pena${indiceContratoTres}`
          ];
          setIndiceTres(indiceContratoTres)
          break;
        }
      }
    }
    //CONTRATO CUATRO
    if (datosCuestionario.situacionLaboralCuatro !== undefined) {
      for (let index = 0; index < contratoBD.length; index++) {
        if (
          datosCuestionario.situacionLaboralCuatro === contratoBD[index].nombre
        ) {
          indiceContratoCuatro = parseInt(contratoBD[index].id);
          penaBajaCuatro = setearDatosCalculadora[
            `delEscnario${escenario}pena${indiceContratoCuatro}`
          ];
          setIndiceUCuatro(indiceContratoCuatro)
          break;
        }
      }
    }


    let anTiguedadSegundo =
      setearDatosCalculadora[
        `delEscnario${escenario}antiguedadMeses${indiceContratoDos}`
      ];
    let anTiguedadTercero =
      setearDatosCalculadora[
        `delEscnario${escenario}antiguedadMeses${indiceContratoTres}`
      ];
    let anTiguedadCuarto =
      setearDatosCalculadora[
        `delEscnario${escenario}antiguedadMeses${indiceContratoCuatro}`
      ];

    // ----------------------------------------------------------
    let penaSegundo =
      setearDatosCalculadora[
        `delEscnario${escenario}antiguedadPena${indiceContratoDos}`
      ];
    let penaTercero =
      setearDatosCalculadora[
        `delEscnario${escenario}antiguedadPena${indiceContratoTres}`
      ];
    let penaCuarto =
      setearDatosCalculadora[
        `delEscnario${escenario}antiguedadPena${indiceContratoCuatro}`
      ];


    let arrNums = [parseInt(penaBaja), parseInt(penaBajaDos), parseInt(penaBajaTres), parseInt(penaBajaCuatro)]
    // Filtra el arreglo para eliminar el número cero
    let filteredArr = arrNums.filter(num => num !== 0);
    console.log(filteredArr + " ARREGLO NUMBERS ");
    // Encuentra el número más bajo en el arreglo filtrado
    let minNum = Math.min(...filteredArr);

    console.log(penaBaja, penaBajaDos, penaBajaTres, penaBajaCuatro);
    console.log(minNum + " Soy la pena mas baja");

    switch (indiceContrato) {
      case 1:
        console.log("Entro en el contrato id PRIMER PENA 1");

        if (mesesTrabajados >= mesesUno) {
          console.log("soy mayor o iguaaaaaaaaaaaaaaaaaaaaal");
          if (parseInt(penaUnoSegunda) > 0 && parseInt(mesesUno) !== parseInt(mesesTrabajados)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          console.log(
            "1) Entro aca porq los meses del primero son menores y existe un segundo titular " +
              !isNaN(mesesTrabajadosDos) +
              " " +
              !isNaN(mesesTrabajadosTres)
          );
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            console.log("1) Entro aca porq los meses del segundo son menores y existe un segundo titular ");
            if (parseInt(penaSegundo) > 0 && parseInt(anTiguedadSegundo) !== parseInt(mesesTrabajadosDos)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            console.log("2) paso 2");
            if (!isNaN(mesesTrabajadosTres)) {
              console.log("3) paso 3");
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                console.log("4) paso 4");
                if (parseInt(penaTercero) > 0 && parseInt(anTiguedadTercero) !== parseInt(mesesTrabajadosTres)) {
                  console.log("5) paso 5");
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(anTiguedadCuarto) !== parseInt(mesesTrabajadosCuatro)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(anTiguedadTercero) !== parseInt(mesesTrabajadosTres)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(anTiguedadCuarto) !== parseInt(mesesTrabajadosCuatro)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          console.log(
            "jsdkjsakdljsaklfueiowruewioruweoiwlkewjlkdjklsajdasldjkasljoiweruioruwoe"
          );
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }

        break;
      case 2:
        console.log("Entro en el contrato id PRIMERA PENA 2");
        if (mesesTrabajados >= mesesDos) {
          if (parseInt(penaDosSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesDos)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 3:
        console.log("Entro en el contrato id PRIMERA PENA 3");

        if (mesesTrabajados >= mesesTres) {
          if (parseInt(penaTresSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesTres)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 4:
        console.log("Entro en el contrato id PRIMERA PENA 4");

        if (mesesTrabajados >= mesesCuatro) {
          if (parseInt(penaCuatroSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesCuatro)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 5:
        console.log("Entro en el contrato id PRIMERA PENA 5");

        if (mesesTrabajados >= mesesCinco) {
          if (parseInt(penaCincoSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesCinco)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 6:
        console.log("Entro en el contrato id PRIMERA PENA 6");

        if (mesesTrabajados >= mesesSeis) {
          if (parseInt(pensaSeisSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesSeis)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0  && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 7:
        console.log("Entro en el contrato id PRIMERA PENA 7");

        if (mesesTrabajados >= mesesSiete) {
          if (parseInt(penaSieteSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesSiete)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0  && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 8:
        console.log("Entro en el contrato id PRIMERA PENA 8");

        if (mesesTrabajados >= mesesOcho) {
          if (parseInt(penaOchoSegunda) > 0  && parseInt(mesesTrabajados) !== parseInt(mesesOcho)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 9:
        console.log("Entro en el contrato id PRIMERA PENA 9");

        if (mesesTrabajados >= mesesNueve) {
          if (parseInt(penaNueveSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesNueve)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 10:
        console.log("Entro en el contrato id PRIMERA PENA 10");

        if (mesesTrabajados >= mesesDiez) {
          if (parseInt(penaDiezSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesDiez)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 11:
        console.log("Entro en el contrato id PRIMERA PENA 11");

        if (mesesTrabajados >= mesesOnce) {
          if (parseInt(penaOnceSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesOnce)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 12:
        console.log("Entro en el contrato id PRIMERA PENA 12");

        if (mesesTrabajados >= mesesDoce) {
          if (parseInt(penaDoceSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesDoce)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 13:
        console.log("Entro en el contrato id PRIMERA PENA 13");

        if (mesesTrabajados >= mesesTrece) {
          if (parseInt(penaTreceSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesTrece)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 14:
        console.log("Entro en el contrato id PRIMERA PENA 14");

        if (mesesTrabajados >= mesesCatorce) {
          if (parseInt(penaCatorceSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesCatorce)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      case 15:
        console.log("Entro en el contrato id PRIMERA PENA 15");

        if (mesesTrabajados >= mesesQuince) {
          if (parseInt(penaQuinceSegunda) > 0 && parseInt(mesesTrabajados) !== parseInt(mesesQuince)) {
            setPorcentajeUno(minNum);
            setMostrarEndeu("si");
          }
        } else if (!isNaN(mesesTrabajadosDos)) {
          if (mesesTrabajadosDos >= anTiguedadSegundo) {
            if (parseInt(penaSegundo) > 0 && parseInt(mesesTrabajadosDos) !== parseInt(anTiguedadSegundo)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          } else {
            if (!isNaN(mesesTrabajadosTres)) {
              if (mesesTrabajadosTres >= anTiguedadTercero) {
                if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              } else {
                if (!isNaN(mesesTrabajadosCuatro)) {
                  if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
                    if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
                      setPorcentajeUno(minNum);
                      setMostrarEndeu("si");
                    }
                  } else {
                    setPorcentajeUno(minNum);
                    setMostrarEndeu("si");
                  }
                } else {
                  setPorcentajeUno(minNum);
                  setMostrarEndeu("si");
                }
              }
            } else {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosTres)) {
          if (mesesTrabajadosTres >= anTiguedadTercero) {
            if (parseInt(penaTercero) > 0 && parseInt(mesesTrabajadosTres) !== parseInt(anTiguedadTercero)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else if (!isNaN(mesesTrabajadosCuatro)) {
          if (mesesTrabajadosCuatro >= anTiguedadCuarto) {
            if (parseInt(penaCuarto) > 0 && parseInt(mesesTrabajadosCuatro) !== parseInt(anTiguedadCuarto)) {
              setPorcentajeUno(minNum);
              setMostrarEndeu("si");
            }
          }
        } else {
          setPorcentajeUno(minNum);
          setMostrarEndeu("si");
        }
        break;
      default:
        break;
    }
  }
  //paso 3:
  async function calcularEndeudamiento(
    escenario,
    resEndeudamiento,
    resEndeudamientoDos,
    resEndeudamientoTres,
    resEndeudamientoCuatro,
    edad,
    edadDos,
    edadTres,
    edadCuatro,
    penaUno,
    penaDos,
    penaTres,
    penaCuatro,
    penaCinco,
    pensaSeis,
    penaSiete,
    penaOcho,
    penaNueve,
    penaDiez,
    penaOnce,
    penaDoce,
    penaTrece,
    penaCatorce,
    penaQuince,
    porcentajeLimiteUno,
    porcentajeLimiteDos,
    porcentajeLimiteTres,
    porcentajeLimiteCuatro,
    porcentajeLimiteCinco,
    porcentajeLimiteSeis,
    porcentajeLimiteSiete,
    porcentajeLimiteOcho,
    porcentajeLimiteNueve,
    porcentajeLimiteDiez,
    porcentajeLimiteOnce,
    porcentajeLimiteDoce,
    porcentajeLimiteTrece,
    porcentajeLimiteCatorce,
    porcentajeLimiteQuince
  ) {
    let indiceContrato = -1;
    let indiceContratoDos = -1;
    let indiceContratoTres = -1;
    let indiceContratoCuatro = -1;

    let ingresosMensualesTotales = 0;
    let pagasAnualesTotales = 0;
    let pagaDeCuotasTotales = 0;
    //variables situacion 2
    let ingresosMensualesDos = 0;
    let pagasAnualesDos = 0;
    let pagaDeCuotasDos = 0;
    let ratioDos = 0
    let penaRatioDos = 0
    //variables situacion 3
    let ingresosMensualesTres = 0;
    let pagasAnualesTres = 0;
    let pagaDeCuotasTres = 0;
    let ratioTres = 0
    let penaRatioTres = 0
    //variables situacion  4
    let ingresosMensualesCuatro = 0;
    let pagasAnualesCuatro = 0;
    let pagaDeCuotasCuatro = 0;
    let ratioCuatro = 0
    let penaRatioCuatro = 0

  
    for (let index = 0; index < contratoBD.length; index++) {
      if (datosCuestionario.situacionLaboral === contratoBD[index].nombre) {
        indiceContrato = parseInt(contratoBD[index].id);
      }

      if (datosCuestionario.situacionLaboralDos === contratoBD[index].nombre) {
        indiceContratoDos = parseInt(contratoBD[index].id);
      }

      if (datosCuestionario.situacionLaboralTres === contratoBD[index].nombre) {
        indiceContratoTres = parseInt(contratoBD[index].id);
      }

      if (datosCuestionario.situacionLaboralCuatro === contratoBD[index].nombre) {
        indiceContratoCuatro = parseInt(contratoBD[index].id);
      }
    }
    //lo defino aca porque el uno siempre va a existir
    let ratioUno = setearDatosCalculadora[
      `delEscnario${escenario}porcentajeEndeudamiento${indiceContrato}`
    ]
    let penaRatioUno = setearDatosCalculadora[
      `delEscnario${escenario}penaEndeudamiento${indiceContrato}`
    ]
  


    //CONTRATO DOS
    console.log(datosCuestionario.situacionLaboral + " :laboral uno");
    console.log(datosCuestionario.situacionLaboralDos + " :laboral dos");
    console.log(datosCuestionario.situacionLaboralTres + " :laboral tres");
    console.log(datosCuestionario.situacionLaboralCuatro + " :laboral cuantro");

    if (datosCuestionario.situacionLaboralDos === undefined || datosCuestionario.situacionLaboralDos.length < 1 || datosCuestionario.situacionLaboralDos === 'undefined') {
      ingresosMensualesDos = 0;
      pagasAnualesDos = 0;
      pagaDeCuotasDos = 0;
      ratioDos = 0
    } else {
      // datosCuestionario.ingresosNetosMensualesDos , datosCuestionario.pagasAnualesDos, datosCuestionario.pagaDeCuotasDos
      ratioDos = setearDatosCalculadora[
        `delEscnario${escenario}porcentajeEndeudamiento${indiceContratoDos}`
      ]
      penaRatioDos = setearDatosCalculadora[
        `delEscnario${escenario}penaEndeudamiento${indiceContratoDos}`
      ]
      ingresosMensualesDos =
        parseInt(datosCuestionario.ingresosNetosMensualesDos) *
        parseInt(datosCuestionario.pagasAnualesDos);
      pagasAnualesDos = parseInt(datosCuestionario.pagasAnualesDos);
      pagaDeCuotasDos = parseInt(datosCuestionario.pagaDeCuotasDos);
    }
    //CONTRATOS TRES
    if (datosCuestionario.situacionLaboralTres === undefined || datosCuestionario.situacionLaboralTres.length < 1 || datosCuestionario.situacionLaboralTres === 'undefined') {
      ingresosMensualesTres = 0;
      pagasAnualesTres = 0;
      pagaDeCuotasTres = 0;
      ratioTres = 0
    } else {
      ratioTres = setearDatosCalculadora[
      `delEscnario${escenario}porcentajeEndeudamiento${indiceContratoTres}`
      ]
      penaRatioTres = setearDatosCalculadora[
        `delEscnario${escenario}penaEndeudamiento${indiceContratoTres}`
      ]
      ingresosMensualesTres =
        parseInt(datosCuestionario.ingresosNetosMensualesTres) *
        parseInt(datosCuestionario.pagasAnualesTres);
      pagasAnualesTres = parseInt(datosCuestionario.pagasAnualesTres);
      pagaDeCuotasTres = parseInt(datosCuestionario.pagaDeCuotasTres);
    }
    //CONTRATO CUATRO
    if (datosCuestionario.situacionLaboralCuatro === undefined || datosCuestionario.situacionLaboralCuatro.length < 1 || datosCuestionario.situacionLaboralCuatro === 'undefined') {
      ingresosMensualesCuatro = 0;
      pagasAnualesCuatro = 0;
      pagaDeCuotasCuatro = 0;
      ratioCuatro = 0
    } else {
      ratioCuatro = setearDatosCalculadora[
        `delEscnario${escenario}porcentajeEndeudamiento${indiceContratoCuatro}`
      ]
      penaRatioCuatro = setearDatosCalculadora[
        `delEscnario${escenario}penaEndeudamiento${indiceContratoCuatro}`
      ]
      ingresosMensualesCuatro =
        parseInt(datosCuestionario.ingresosNetosMensualesCuatro) *
        parseInt(datosCuestionario.pagasAnualesCuatro);
      pagasAnualesCuatro = parseInt(datosCuestionario.pagasAnualesCuatro);
      pagaDeCuotasCuatro = parseInt(datosCuestionario.pagaDeCuotasCuatro);
    }
 
    let arrNums = [ratioUno, ratioDos, ratioTres, ratioCuatro]
    let arrNumDos = [penaRatioUno, penaRatioDos, penaRatioTres, penaRatioCuatro]
    // Filtra el arreglo para eliminar el número cero
    let filteredArr = arrNumDos.filter(num => num !== 0);
    console.log(filteredArr + " ARREGLO NUMBERS ");
    // Encuentra el número más bajo en el arreglo filtrado
    let minNum = Math.min(...filteredArr);
    // console.log(minNum + " mas minimo de pena");
    const ratiosMasAlto = obtenerMayor(arrNums)
    // console.log(arrNums + " ARREGLOS DE RATIOS");
    console.log(arrNumDos + " ARREGLOS DE RATIOS PORCENTAJE");
    console.log(datosCuestionario.situacionLaboralCuatro + " datosCuestionario.situacionLaboralCuatro");
    console.log(ingresosMensualesCuatro + " ingresosMensualesCuatro");
    console.log(isNaN(datosCuestionario.situacionLaboralCuatro) + " isNaN");
    console.log(ratiosMasAlto + " ratio mas alto DE RATIOS");

    ingresosMensualesTotales =
      parseInt(datosCuestionario.ingresosNetosMensuales) *
        parseInt(datosCuestionario.pagasAnuales) +
      ingresosMensualesDos +
      ingresosMensualesTres +
      ingresosMensualesCuatro;

    pagasAnualesTotales =
      parseInt(datosCuestionario.pagasAnuales) +
      pagasAnualesDos +
      pagasAnualesTres +
      pagasAnualesCuatro;
    pagaDeCuotasTotales =
      parseInt(datosCuestionario.pagaDeCuotas) +
      pagaDeCuotasDos +
      pagaDeCuotasTres +
      pagaDeCuotasCuatro;

    console.log(
      ingresosMensualesTotales +
        " SOY INGRESOS MENSUALES TOTALES ++++++++++++++++++++++++++++"
    );
    // console.log(pagasAnualesTotales + " SOY PAGAS ANUALES TOTALES +++++++++++++++++++++++++");
    console.log(
      pagaDeCuotasTotales +
        " SOY PAGAS CUOTAS TOTALES +++++++++++++++++++++++++"
    );

    setMensualTotal(ingresosMensualesTotales)
    setPagaCuotaTotal(pagaDeCuotasTotales)
    let numEndeudamientoTotal = await numEndedamientoTotal(
      ingresosMensualesTotales,
      pagaDeCuotasTotales
    );

    console.log(numEndeudamientoTotal + " TOTAL DE TOTALES");
    // calculos para el maximo plazo q establecio el configurador de calculos (edad maxima calculada)
    let plazoFijoMaximoEndeudamiento = await buscarMaximo(
      1,
      ingresosMensualesTotales,
      pagasAnualesTotales,
      pagaDeCuotasTotales
    );
    let plazoVariableMaximoEndeudamiento = await buscarMaximo(
      2,
      ingresosMensualesTotales,
      pagasAnualesTotales,
      pagaDeCuotasTotales
    );
    let plazoMixtoMaximoEndeudamiento = await buscarMaximo(
      3,
      ingresosMensualesTotales,
      pagasAnualesTotales,
      pagaDeCuotasTotales
    );

    // calculos para el maximo plazo q establecio el configurador de calculos (edad maxima calculada)
    let plazoFijoMaxPorConfigurador = await buscarMaximo(
      4,
      ingresosMensualesTotales,
      pagasAnualesTotales,
      pagaDeCuotasTotales
    );
    let plazoVarMaxPorConfigurador = await buscarMaximo(
      5,
      ingresosMensualesTotales,
      pagasAnualesTotales,
      pagaDeCuotasTotales
    );
    let plazoMixMaxPorConfigurador = await buscarMaximo(
      6,
      ingresosMensualesTotales,
      pagasAnualesTotales,
      pagaDeCuotasTotales
    );
    console.log("maximo fijo: " + plazoFijoMaximoEndeudamiento);
    console.log("maximo variable: " + plazoVariableMaximoEndeudamiento);
    console.log("maximo mixto: " + plazoMixtoMaximoEndeudamiento);

    console.log("maximo fijo POR CONFIGURADOR: " + plazoFijoMaxPorConfigurador);
    console.log(
      "maximo variable POR CONFIGURADOR: " + plazoVarMaxPorConfigurador
    );
    console.log("maximo mixto POR CONFIGURADOR: " + plazoMixMaxPorConfigurador);
    
    const numerito = 1

    switch (numerito) {
      case 1:
        console.log("Entro en el endeudamiento id 1 FORZADO");
        //PREGUNTO SI PENALIZA POR TODOS LOS DATOS DE LOS SOLICITANTE
        if (Math.ceil(numEndeudamientoTotal) > parseFloat(ratiosMasAlto)) {
          //SI PENALIZO, VOY A BUSCAR EN EL MAXIMO DEL PLAZO FIJO SI EXISTE
          let res = false;
          let resDos = false;
          let resTres = false;
          //pregunto por maxima fija con limite cliente
          if (setearDatosCalculadora.mostrarHipotecaFija === "si") {
            if (
              parseFloat(plazoFijoMaximoEndeudamiento) >
              parseFloat(ratiosMasAlto)
            ) {
              res = false;
            } else {
              res = true;
            }
          }
          //pregunto por maxima variable con limite cliente
          if (setearDatosCalculadora.mostrarHipotecaVariable === "si") {
            if (
              parseFloat(plazoVariableMaximoEndeudamiento) >
              parseFloat(ratiosMasAlto)
            ) {
              resDos = false;
            } else {
              resDos = true;
            }
          }
          //pregunto por maxima mixta con limite cliente
          if (setearDatosCalculadora.mostrarHipotecaMixta === "si") {
            if (
              parseFloat(plazoMixtoMaximoEndeudamiento) >
              parseFloat(ratiosMasAlto)
            ) {
              resTres = false;
            } else {
              resTres = true;
            }
          }

          //ahora pregunto si alguno de los valores es true
          if (res === true || resDos === true || resTres === true) {
            setMensajeRatio(
              "No penalizo, ya que al ir al maximo del plazo (con la edad del menor) no penaliza"
            );
            console.log(mensajeRatio);
          } else {
            //preguntamos si con la edad del MAYOR yendo al plazo maximo (por hipoteca)
            let resCuatro = false;
            let resCinco = false;
            let resSeis = false;

            if (setearDatosCalculadora.mostrarHipotecaFija === "si") {
              if (
                parseFloat(plazoFijoMaxPorConfigurador) >
                parseFloat(ratiosMasAlto)
              ) {
                resCuatro = false;
              } else {
                resCuatro = true;
              }

              if (setearDatosCalculadora.mostrarHipotecaVariable === "si") {
                if (
                  parseFloat(plazoVarMaxPorConfigurador) >
                  parseFloat(ratiosMasAlto)
                ) {
                  resCinco = false;
                } else {
                  resCinco = true;
                }
              }
              //pregunto por maxima mixta con limite cliente
              if (setearDatosCalculadora.mostrarHipotecaMixta === "si") {
                if (
                  parseFloat(plazoMixMaxPorConfigurador) >
                  parseFloat(ratiosMasAlto)
                ) {
                  resSeis = false;
                } else {
                  resSeis = true;
                }
              }
              // preguintamos si algunas de las hipotecas esta en true
              if (resCuatro === true || resCinco === true || resSeis === true) {
                setMensajeRatio(
                  "No penalizo, ya que al ir al maximo del plazo (con la edad del menor) no penaliza"
                );
                console.log(mensajeRatio);
              } else {
                console.log("sigue dando false");
                setPorcentajeDos(minNum);
                setMostrarRatio("si");
              }
            }
          }
        }
        break;
      default:
        break;
    }
  }
  //paso 4: calcular segunda penalizacion
function setearPenaDobleGarantia(escenario,indiceContrato) {
  console.log("*--*-*-*-*-*-*-*-*-*-*-*--*");
  if (setearDatosCalculadora[`delEscnario${escenario}Limite${indiceContrato}`] === 'si') {
     if (datosCuestionario.segundaGarantia === 'no') {
      setPorcentajeTres(parseInt(setearDatosCalculadora[`delEscnario${escenario}DobleGarantia${indiceContrato}`]))
    }
  }
  
 
  console.log("*--*-*-*-*-*-*-*-*-*-*-*-**");
}
  async function buscarMaximo(
    id,
    ingresosNetosMensuales,
    pagasAnuales,
    pagaDeCuotas
  ) {
    let res = 0;
    switch (id) {
      case 1:
        let plazoFijoMaximoEndeudamiento =
          ((parseInt(pagaDeCuotas) + parseInt(plazoPorcetaje.plazoFijo)) /
            ingresosNetosMensuales) *
          100 *
          12;
        res = plazoFijoMaximoEndeudamiento;
        break;
      case 2:
        let plazoVariableMaximoEndeudamiento =
          ((parseInt(pagaDeCuotas) + parseInt(plazoPorcetaje.plazoVariable)) /
            ingresosNetosMensuales) *
          100 *
          12;
        res = plazoVariableMaximoEndeudamiento;
        break;
      case 3:
        let plazoMixtoMaximoEndeudamiento =
          ((parseInt(pagaDeCuotas) + parseInt(plazoPorcetaje.plazoMixto)) /
            ingresosNetosMensuales) *
          100 *
          12;
        res = plazoMixtoMaximoEndeudamiento;
        break;
      case 4:
        let plazoExtraMaxFijo =
          ((parseInt(pagaDeCuotas) + parseInt(cuotaInicialFijaMaxima)) /
            ingresosNetosMensuales) *
          100 *
          12;
        res = plazoExtraMaxFijo;
        break;
      case 5:
        let plazoExtraMaxVar =
          ((parseInt(pagaDeCuotas) + parseInt(cuotaInicialVariableMaxima)) /
            ingresosNetosMensuales) *
          100 *
          12;
        res = plazoExtraMaxVar;
        break;
      case 6:
        let plazoExtraMaxMix =
          ((parseInt(pagaDeCuotas) + parseInt(cuotaInicialMixtaMaxima)) /
            ingresosNetosMensuales) *
          100 *
          12;
        res = plazoExtraMaxMix;
        break;
      default:
        break;
    }

    return res;
  }

  function obtenerMayor(arreglo) {
    let mayor = Number.NEGATIVE_INFINITY; // Inicializar con un valor negativo grande
  
    for (let i = 0; i < arreglo.length; i++) {
      if (arreglo[i] > mayor) {
        mayor = arreglo[i];
      }
    }
  
    return mayor;
  }


  useEffect(() => {
    if (modalDeCarga) {
      setModalDeCarga(false);
      let timerInterval;
      Swal.fire({
        title: "Cargando resultados...!",
        html: "Se cerrara automaticamente en: <b></b> millisegundos.",
        timer: 3000,
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });
    }
    if (buscarDiferencialEuribor) {
      async function traerDifeerncialEuribor() {
        const docRef = doc(db, "diferencialEuribor", "diferencial");
        const docRefDos = doc(db, "diferencialEuribor", "euribor");
        const docSnap = await getDoc(docRef);
        const docSnapDos = await getDoc(docRefDos);
        setEuribor(docSnapDos.data().euribor);
        setDiferencial(docSnap.data().diferencial);

        setBuscarDiferencialEuribor(!buscarDiferencialEuribor);
      }
      traerDifeerncialEuribor();
    }
    if (setearDatosCalculadora.incuirEdadtitualMenor === "si") {
      setEdadCalculoFinal(parseInt(datosCuestionario.edadViavilidad));
      setPlazoFinalSiExisteEdadDos(
        parseInt(setearDatosCalculadora.edadMaximaTitularMenor)
      );
      if (
        datosCuestionario.edadViavilidad > datosCuestionario.edadViavilidadDos
      ) {
        setEdadCalculoFinal(parseInt(datosCuestionario.edadViavilidadDos));
      } else if (
        datosCuestionario.edadViavilidad > datosCuestionario.edadViavilidadTres
      ) {
        setEdadCalculoFinal(parseInt(datosCuestionario.edadViavilidadTres));
      } else if (
        datosCuestionario.edadViavilidad >
        datosCuestionario.edadViavilidadCuatro
      ) {
        setEdadCalculoFinal(parseInt(datosCuestionario.edadViavilidadCuatro));
      } else {
        setEdadCalculoFinal(parseInt(datosCuestionario.edadViavilidad));
      }
    } else {
      setEdadCalculoFinal(parseInt(datosCuestionario.edadViavilidad));
      setPlazoFinalSiExisteEdadDos(
        parseInt(setearDatosCalculadora.edadMaximaTitular)
      );
    }

    // if (datosCuestionario.edadViavilidadDos === undefined) {
    //   setPlazoFinalSiExisteEdadDos(parseInt(setearDatosCalculadora.edadMaximaTitular))
    // }else{
    //   setPlazoFinalSiExisteEdadDos(parseInt(setearDatosCalculadora.edadMaximaTitularMenor))
    // }

    if (valorFijoMixto === "fijo") {
      if (aniosTiempo <= 10) {
        setIntresFija(setearDatosCalculadora.hastaDiezFijo);
      } else if (aniosTiempo <= 15) {
        setIntresFija(setearDatosCalculadora.hastaQuinceFijo);
      } else if (aniosTiempo <= 20) {
        setIntresFija(setearDatosCalculadora.hastaVeinteFijo);
      } else if (aniosTiempo <= 25) {
        setIntresFija(setearDatosCalculadora.hastaVeinteCincoFijo);
      } else if (aniosTiempo <= 30) {
        setIntresFija(setearDatosCalculadora.hastaTreintaFijo);
      } else if (aniosTiempo <= 35) {
        setIntresFija(setearDatosCalculadora.hastaTreintaCincoFijo);
      } else if (aniosTiempo <= 40) {
        setIntresFija(setearDatosCalculadora.hastaCuarentaFijo);
      } else if (aniosTiempo <= 45) {
        setIntresFija(setearDatosCalculadora.hastaCuarentaCincoFijo);
      } else if (aniosTiempo <= 50) {
        setIntresFija(setearDatosCalculadora.hastaCincuentFijo);
      }

      if (setearDatosCalculadora.incuirEdadtitualMenor === "si") {
        if (
          edadCalculoFinal + parseInt(setearDatosCalculadora.plazoFijoMaximo) >
          plazoFinalSiExisteEdadDos
        ) {
          setresEdadMaxPlazoFijo(plazoFinalSiExisteEdadDos - edadCalculoFinal);
        } else {
          setresEdadMaxPlazoFijo(setearDatosCalculadora.plazoFijoMaximo);
        }
      } else {
        if (
          edadCalculoFinal + parseInt(setearDatosCalculadora.plazoFijoMaximo) >
          plazoFinalSiExisteEdadDos
        ) {
          setresEdadMaxPlazoFijo(plazoFinalSiExisteEdadDos - edadCalculoFinal);
        } else {
          setresEdadMaxPlazoFijo(setearDatosCalculadora.plazoFijoMaximo);
        }
      }

      if (setearDatosCalculadora.incuirEdadtitualMenor === "si") {
        if (
          edadCalculoFinal +
            parseInt(setearDatosCalculadora.plazoVariableMaximo) >=
          plazoFinalSiExisteEdadDos
        ) {
          setresEdadMaxPlazoVariable(
            plazoFinalSiExisteEdadDos - edadCalculoFinal
          );
        } else {
          setresEdadMaxPlazoVariable(
            setearDatosCalculadora.plazoVariableMaximo
          );
        }
      } else {
        if (
          edadCalculoFinal +
            parseInt(setearDatosCalculadora.plazoVariableMaximo) >=
          plazoFinalSiExisteEdadDos
        ) {
          setresEdadMaxPlazoVariable(
            plazoFinalSiExisteEdadDos - edadCalculoFinal
          );
        } else {
          setresEdadMaxPlazoVariable(
            setearDatosCalculadora.plazoVariableMaximo
          );
        }
      }

      if (setearDatosCalculadora.incuirEdadtitualMenor === "si") {
        if (
          edadCalculoFinal +
            parseInt(setearDatosCalculadora.plazoMixtoMaximo) >=
          plazoFinalSiExisteEdadDos
        ) {
          setresEdadMaxPlazoMixto(plazoFinalSiExisteEdadDos - edadCalculoFinal);
        } else {
          setresEdadMaxPlazoMixto(setearDatosCalculadora.plazoMixtoMaximo);
        }
      } else {
        if (
          edadCalculoFinal +
            parseInt(setearDatosCalculadora.plazoMixtoMaximo) >=
          plazoFinalSiExisteEdadDos
        ) {
          setresEdadMaxPlazoMixto(plazoFinalSiExisteEdadDos - edadCalculoFinal);
        } else {
          setresEdadMaxPlazoMixto(setearDatosCalculadora.plazoMixtoMaximo);
        }
      }
    } else if (valorFijoMixto === "variable") {
      if (aniosTiempo <= 10) {
        setInnteresVariable(setearDatosCalculadora.hastaDiezVariable);
      } else if (aniosTiempo <= 15) {
        setInnteresVariable(setearDatosCalculadora.hastaQuinceVariable);
      } else if (aniosTiempo <= 20) {
        setInnteresVariable(setearDatosCalculadora.hastaVeinteVariable);
      } else if (aniosTiempo <= 25) {
        setInnteresVariable(setearDatosCalculadora.hastaVeinteCincoVariable);
      } else if (aniosTiempo <= 30) {
        setInnteresVariable(setearDatosCalculadora.hastaTreintaVariable);
      } else if (aniosTiempo <= 35) {
        setInnteresVariable(setearDatosCalculadora.hastaTreintaCincoVariable);
      } else if (aniosTiempo <= 40) {
        setInnteresVariable(setearDatosCalculadora.hastaCuarentaVariable);
      } else if (aniosTiempo <= 45) {
        setInnteresVariable(setearDatosCalculadora.hastaCuarentaCincoVariable);
      } else if (aniosTiempo <= 50) {
        setInnteresVariable(setearDatosCalculadora.hastaCincuentVariable);
      }
    } else {
      if (aniosTiempo <= 10) {
        setInnteresMixto(setearDatosCalculadora.hastaDiezMixto);
      } else if (aniosTiempo <= 15) {
        setInnteresMixto(setearDatosCalculadora.hastaQuinceMixto);
      } else if (aniosTiempo <= 20) {
        setInnteresMixto(setearDatosCalculadora.hastaVeinteMixto);
      } else if (aniosTiempo <= 25) {
        setInnteresMixto(setearDatosCalculadora.hastaVeinteCincoMixto);
      } else if (aniosTiempo <= 30) {
        setInnteresMixto(setearDatosCalculadora.hastaTreintaMixto);
      } else if (aniosTiempo <= 35) {
        setInnteresMixto(setearDatosCalculadora.hastaTreintaCincoMixto);
      } else if (aniosTiempo <= 40) {
        setInnteresMixto(setearDatosCalculadora.hastaCuarentaMixto);
      } else if (aniosTiempo <= 45) {
        setInnteresMixto(setearDatosCalculadora.hastaCuarentaCincoMixto);
      } else if (aniosTiempo <= 50) {
        setInnteresMixto(setearDatosCalculadora.hastaCincuentMixto);
      }
    }

    if (calculoDeEdadMaximaPrincipio) {
      setTimeout(() => {
        setaniosTiempo(resEdadMaxPlazoFijo);
        setaniosTiempoVariable(resEdadMaxPlazoVariable);
        setaniosTiempoMixto(resEdadMaxPlazoMixto);
        setCalculoDeEdadMaximaPrincipio(false);
      }, 1000);
    }

    //***************************** */
    if (datosCuestionario.resPreguntaCero === "no") {
      if (datosCuestionario.honorariosDeEscritura === "si") {
        //si es si, es la suma de la hipoteca solicitada mas los honorarios
        let importeFinanciar = parseFloat(
          Math.floor(datosCuestionario.hipotecaSolicitadaConHonorarios / 500) *
            500
        );
        let interes = innteresFija / 100 / 12;
        //-----------------CALCULO CON EDAD DEL MAYOR ------------------------------------------ */

        // variables de apoyo para ver el plazo maximo

        // console.log(edadMaxima + " resto de edades");
        let valorMaxFijoInteres = 0;

        //-----------------------------------CALCULO INTERES FIJO MAXIMO CON EDAD MAXIMA
        if (edadMaxima <= 10) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaDiezFijo;
        } else if (edadMaxima <= 15) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaQuinceFijo;
        } else if (edadMaxima <= 20) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaVeinteFijo;
        } else if (edadMaxima <= 25) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaVeinteCincoFijo;
        } else if (edadMaxima <= 30) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaTreintaFijo;
        } else if (edadMaxima <= 35) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaTreintaCincoFijo;
        } else if (edadMaxima <= 40) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaCuarentaFijo;
        } else if (edadMaxima <= 45) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaCuarentaCincoFijo;
        } else if (edadMaxima >= 46) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaCincuentFijo;
        }

        //aca seteamos y pasamos a decimal el interes maximo posible con respecto a la edad final
        let interesMaximoPosibleFijo = valorMaxFijoInteres / 100 / 12;
        let interesMaximoPosibleVariable =
          setearDatosCalculadora.tinPrimerosMesesVariable / 100 / 12;
        let interesMaximoPosiblemixto =
          setearDatosCalculadora.tinPrimerosMesesMixto / 100 / 12;
        let plazoFijaMax;
        let plazoVarMax;
        let plazoMixMax;
        if (
          parseInt(edadMaxima) >
          parseInt(setearDatosCalculadora.plazoFijoMaximo)
        ) {
          plazoFijaMax = parseInt(setearDatosCalculadora.plazoFijoMaximo) * 12;
        } else {
          plazoFijaMax = edadMaxima * 12;
        }

        if (
          parseInt(edadMaxima) >
          parseInt(setearDatosCalculadora.plazoVariableMaximo)
        ) {
          plazoVarMax =
            parseInt(setearDatosCalculadora.plazoVariableMaximo) * 12;
        } else {
          plazoVarMax = edadMaxima * 12;
        }

        if (
          parseInt(edadMaxima) >
          parseInt(setearDatosCalculadora.plazoMixtoMaximo)
        ) {
          plazoMixMax = parseInt(setearDatosCalculadora.plazoMixtoMaximo) * 12;
        } else {
          plazoMixMax = edadMaxima * 12;
        }

        //fija cuota inicial con edad maxima
        let resUnoFijaMax = Math.pow(
          1 + interesMaximoPosibleFijo,
          plazoFijaMax
        );
        let resDosFijaMax = interesMaximoPosibleFijo * resUnoFijaMax;
        let resTrsFijaMax = resUnoFijaMax - 1;
        let resCuatroFijaMax = resDosFijaMax / resTrsFijaMax;
        setCuotaInicialFijaMaxima(
          Math.trunc(importeFinanciar * resCuatroFijaMax)
        );

        //variable cuota inicial con edad maxima
        let resUnoVarMax = Math.pow(
          1 + interesMaximoPosibleVariable,
          plazoVarMax
        );
        let resDosVarMax = interesMaximoPosibleVariable * resUnoVarMax;
        let resTrsVarMax = resUnoVarMax - 1;
        let resCuatroVarMax = resDosVarMax / resTrsVarMax;
        setCuotaInicialVariableMaxima(
          Math.trunc(importeFinanciar * resCuatroVarMax)
        );

        //mixta cuota inicial con edad maxima
        let resUnoMixMax = Math.pow(1 + interesMaximoPosiblemixto, plazoMixMax);
        let resDosMixMax = interesMaximoPosiblemixto * resUnoMixMax;
        let resTrsMixMax = resUnoMixMax - 1;
        let resCuatroMixMax = resDosMixMax / resTrsMixMax;
        setcuotaInicialMixtaMaxima(
          Math.trunc(importeFinanciar * resCuatroMixMax)
        );
        console.log(plazoFijaMax + " soy la edad maxzi FIJO");
        console.log(plazoVarMax + " soy la edad maxzi VAR");
        console.log(plazoMixMax + " soy la edad maxzi MIX");
        //-----------------FIN CALCULO CON EDAD DEL MAYOR ------------------------------------------ */

        //error en nombre, seria para cuota iniciar de interes variable
        let interesMixto =
          parseFloat(setearDatosCalculadora.tinPrimerosMesesVariable) /
          100 /
          12;

        let interesMixtoReal =
          parseFloat(setearDatosCalculadora.tinPrimerosMesesMixto) / 100 / 12;

        //variable para el uso del interes del resto del plazo de variable
        let interesEuribor =
          (parseFloat(innteresVariable) + parseFloat(euribor)) / 100 / 12;

        //variable para el uso del interes del resto del plazo de mixto
        let interesEuriborMixto =
          (parseFloat(innteresMixto) + parseFloat(euribor)) / 100 / 12;

        let plazo = aniosTiempo * 12;
        let plazoVariable = aniosTiempoVariable * 12;
        let plazoMixto = aniosTiempoMixto * 12;
        //plazo fijo
        let resUno = Math.pow(1 + interes, plazo);
        let resDos = interes * resUno;
        let resTrs = resUno - 1;
        let resCuatro = resDos / resTrs;

        let res = Math.trunc(importeFinanciar * resCuatro);
        setCuotaEstimadaFija(res);
        //plazo - variable
        let resUnoVariable = Math.pow(1 + interesMixto, plazoVariable);
        let resDosVariable = interesMixto * resUnoVariable;
        let resTrsVariable = resUnoVariable - 1;
        let resCuatroVariable = resDosVariable / resTrsVariable;

        let resVariable = Math.trunc(importeFinanciar * resCuatroVariable);
        setCuotaEstimadaVariable(resVariable);

        //plazo - variable - euribor
        let resUnoVariableEuribor = Math.pow(1 + interesEuribor, plazoVariable);
        let resDosVariableEuribor = interesEuribor * resUnoVariableEuribor;
        let resTrsVariableEuribor = resUnoVariableEuribor - 1;
        let resCuatroVariableEuribor =
          resDosVariableEuribor / resTrsVariableEuribor;

        let resVariableEuribor = Math.trunc(
          importeFinanciar * resCuatroVariableEuribor
        );

        setCuotaEstimadaVariableEuribor(resVariableEuribor);

        //mixta setCuotaEstimadaMixta
        let resUnoMixta = Math.pow(1 + interesMixtoReal, plazoMixto);
        let resDosMixta = interesMixtoReal * resUnoMixta;
        let resTrsMixta = resUnoMixta - 1;
        let resCuatroMixta = resDosMixta / resTrsMixta;
        let resVariableMixta = Math.trunc(importeFinanciar * resCuatroMixta);
        setCuotaEstimadaMixta(resVariableMixta);

        //mixta - euribor setCuotaEstimadaMixtaEuribor
        let resUnoMixtaEuribor = Math.pow(1 + interesEuriborMixto, plazoMixto);
        let resDosMixtaEuribor = interesEuriborMixto * resUnoMixtaEuribor;
        let resTrsMixtaEuribor = resUnoMixtaEuribor - 1;
        let resCuatroMixtaEuribor = resDosMixtaEuribor / resTrsMixtaEuribor;

        let resMixtaEuribor = Math.trunc(
          importeFinanciar * resCuatroMixtaEuribor
        );
        setCuotaEstimadaMixtaEuribor(resMixtaEuribor);
        //CALCULO LA ULTIMA CUOTA POSIBLE
        setTimeout(() => {
          setUnaVez(false);
          if (unaVez) {
            setPlazoPorcetaje({
              plazoFijo: res,
              plazoVariable: resVariable,
              plazoMixto: resVariableMixta,
            });
            setedadMaxima(
              parseInt(aniosTiempo) +
                parseInt(setearDatosCalculadora.edadMaximaTitular) -
                parseInt(setearDatosCalculadora.edadMaximaTitularMenor)
            );
          }
        }, 2000);
      } else {
        let importeFinanciar = parseInt(datosCuestionario.hipotecaSolicitada);

        let interes = innteresFija / 100 / 12;

        let interesMixto =
          parseFloat(setearDatosCalculadora.tinPrimerosMesesVariable) /
          100 /
          12;

        let interesMixtoReal =
          parseFloat(setearDatosCalculadora.tinPrimerosMesesMixto) / 100 / 12;
        let interesEuribor =
          (parseFloat(innteresVariable) + parseFloat(euribor)) / 100 / 12;

        //variable para el uso del interes del resto del plazo de mixto
        let interesEuriborMixto =
          (parseFloat(innteresMixto) + parseFloat(euribor)) / 100 / 12;

        let plazo = aniosTiempo * 12;
        let plazoVariable = aniosTiempoVariable * 12;
        let plazoMixto = aniosTiempoMixto * 12;
        //plazo fijo
        let resUno = Math.pow(1 + interes, plazo);
        let resDos = interes * resUno;
        let resTrs = resUno - 1;
        let resCuatro = resDos / resTrs;

        let res = Math.trunc(importeFinanciar * resCuatro);
        setCuotaEstimadaFija(res);

        //plazo - variable
        let resUnoVariable = Math.pow(1 + interesMixto, plazoVariable);
        let resDosVariable = interesMixto * resUnoVariable;
        let resTrsVariable = resUnoVariable - 1;
        let resCuatroVariable = resDosVariable / resTrsVariable;

        let resVariable = Math.trunc(importeFinanciar * resCuatroVariable);
        setCuotaEstimadaVariable(resVariable);

        //plazo - variable - euribor
        let resUnoVariableEuribor = Math.pow(1 + interesEuribor, plazoVariable);
        let resDosVariableEuribor = interesEuribor * resUnoVariableEuribor;
        let resTrsVariableEuribor = resUnoVariableEuribor - 1;
        let resCuatroVariableEuribor =
          resDosVariableEuribor / resTrsVariableEuribor;

        let resVariableEuribor = Math.trunc(
          importeFinanciar * resCuatroVariableEuribor
        );
        setCuotaEstimadaVariableEuribor(resVariableEuribor);

        //mixta setCuotaEstimadaMixta
        let resUnoMixta = Math.pow(1 + interesMixtoReal, plazoMixto);
        let resDosMixta = interesMixtoReal * resUnoMixta;
        let resTrsMixta = resUnoMixta - 1;
        let resCuatroMixta = resDosMixta / resTrsMixta;
        let resVariableMixta = Math.trunc(importeFinanciar * resCuatroMixta);
        setCuotaEstimadaMixta(resVariableMixta);

        //mixta - euribor setCuotaEstimadaMixtaEuribor
        let resUnoMixtaEuribor = Math.pow(1 + interesEuriborMixto, plazoMixto);
        let resDosMixtaEuribor = interesEuriborMixto * resUnoMixtaEuribor;
        let resTrsMixtaEuribor = resUnoMixtaEuribor - 1;
        let resCuatroMixtaEuribor = resDosMixtaEuribor / resTrsMixtaEuribor;

        let resMixtaEuribor = Math.trunc(
          importeFinanciar * resCuatroMixtaEuribor
        );
        setCuotaEstimadaMixtaEuribor(resMixtaEuribor);

        //CALCULO LA ULTIMA CUOTA POSIBLE

        setTimeout(() => {
          setUnaVez(false);
          if (unaVez) {
            setPlazoPorcetaje({
              plazoFijo: res,
              plazoVariable: resVariable,
              plazoMixto: resVariableMixta,
            });
            setedadMaxima(
              parseInt(aniosTiempo) +
                parseInt(setearDatosCalculadora.edadMaximaTitular) -
                parseInt(setearDatosCalculadora.edadMaximaTitularMenor)
            );
          }
        }, 2000);

        //-----------------CALCULO CON EDAD DEL MAYOR ------------------------------------------ */

        // variables de apoyo para ver el plazo maximo

        // console.log(edadMaxima + " resto de edades");
        let valorMaxFijoInteres = 0;

        //-----------------------------------CALCULO INTERES FIJO MAXIMO CON EDAD MAXIMA
        if (edadMaxima <= 10) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaDiezFijo;
        } else if (edadMaxima <= 15) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaQuinceFijo;
        } else if (edadMaxima <= 20) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaVeinteFijo;
        } else if (edadMaxima <= 25) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaVeinteCincoFijo;
        } else if (edadMaxima <= 30) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaTreintaFijo;
        } else if (edadMaxima <= 35) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaTreintaCincoFijo;
        } else if (edadMaxima <= 40) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaCuarentaFijo;
        } else if (edadMaxima <= 45) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaCuarentaCincoFijo;
        } else if (edadMaxima >= 46) {
          valorMaxFijoInteres = setearDatosCalculadora.hastaCincuentFijo;
        }

        //aca seteamos y pasamos a decimal el interes maximo posible con respecto a la edad final
        let interesMaximoPosibleFijo = valorMaxFijoInteres / 100 / 12;
        let interesMaximoPosibleVariable =
          setearDatosCalculadora.tinPrimerosMesesVariable / 100 / 12;
        let interesMaximoPosiblemixto =
          setearDatosCalculadora.tinPrimerosMesesMixto / 100 / 12;
        let plazoFijaMax;
        let plazoVarMax;
        let plazoMixMax;
        if (
          parseInt(edadMaxima) >
          parseInt(setearDatosCalculadora.plazoFijoMaximo)
        ) {
          plazoFijaMax = parseInt(setearDatosCalculadora.plazoFijoMaximo) * 12;
        } else {
          plazoFijaMax = edadMaxima * 12;
        }

        if (
          parseInt(edadMaxima) >
          parseInt(setearDatosCalculadora.plazoVariableMaximo)
        ) {
          plazoVarMax =
            parseInt(setearDatosCalculadora.plazoVariableMaximo) * 12;
        } else {
          plazoVarMax = edadMaxima * 12;
        }

        if (
          parseInt(edadMaxima) >
          parseInt(setearDatosCalculadora.plazoMixtoMaximo)
        ) {
          plazoMixMax = parseInt(setearDatosCalculadora.plazoMixtoMaximo) * 12;
        } else {
          plazoMixMax = edadMaxima * 12;
        }

        //fija cuota inicial con edad maxima
        let resUnoFijaMax = Math.pow(
          1 + interesMaximoPosibleFijo,
          plazoFijaMax
        );
        let resDosFijaMax = interesMaximoPosibleFijo * resUnoFijaMax;
        let resTrsFijaMax = resUnoFijaMax - 1;
        let resCuatroFijaMax = resDosFijaMax / resTrsFijaMax;
        setCuotaInicialFijaMaxima(
          Math.trunc(importeFinanciar * resCuatroFijaMax)
        );

        //variable cuota inicial con edad maxima
        let resUnoVarMax = Math.pow(
          1 + interesMaximoPosibleVariable,
          plazoVarMax
        );
        let resDosVarMax = interesMaximoPosibleVariable * resUnoVarMax;
        let resTrsVarMax = resUnoVarMax - 1;
        let resCuatroVarMax = resDosVarMax / resTrsVarMax;
        setCuotaInicialVariableMaxima(
          Math.trunc(importeFinanciar * resCuatroVarMax)
        );

        //mixta cuota inicial con edad maxima
        let resUnoMixMax = Math.pow(1 + interesMaximoPosiblemixto, plazoMixMax);
        let resDosMixMax = interesMaximoPosiblemixto * resUnoMixMax;
        let resTrsMixMax = resUnoMixMax - 1;
        let resCuatroMixMax = resDosMixMax / resTrsMixMax;
        setcuotaInicialMixtaMaxima(
          Math.trunc(importeFinanciar * resCuatroMixMax)
        );
        console.log(plazoFijaMax + " soy la edad maxzi FIJO");
        console.log(plazoVarMax + " soy la edad maxzi VAR");
        console.log(plazoMixMax + " soy la edad maxzi MIX");
        //-----------------FIN CALCULO CON EDAD DEL MAYOR ------------------------------------------ */
      }
    } else {
      let importeFinanciar = parseFloat(
        Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
      );
      let interes = innteresFija / 100 / 12;

      let interesMixto =
        parseFloat(setearDatosCalculadora.tinPrimerosMesesVariable) / 100 / 12;

      let interesMixtoReal =
        parseFloat(setearDatosCalculadora.tinPrimerosMesesMixto) / 100 / 12;

      let interesEuribor =
        (parseFloat(innteresVariable) + parseFloat(euribor)) / 100 / 12;
      let plazo = aniosTiempo * 12;
      let plazoVariable = aniosTiempoVariable * 12;
      let plazoMixto = aniosTiempoMixto * 12;
      //variable para el uso del interes del resto del plazo de mixto
      let interesEuriborMixto =
        (parseFloat(innteresMixto) + parseFloat(euribor)) / 100 / 12;

      //plazo fijo
      let resUno = Math.pow(1 + interes, plazo);
      let resDos = interes * resUno;
      let resTrs = resUno - 1;
      let resCuatro = resDos / resTrs;

      let res = Math.trunc(importeFinanciar * resCuatro);
      setCuotaEstimadaFija(res);

      //plazo - variable
      let resUnoVariable = Math.pow(1 + interesMixto, plazoVariable);
      let resDosVariable = interesMixto * resUnoVariable;
      let resTrsVariable = resUnoVariable - 1;
      let resCuatroVariable = resDosVariable / resTrsVariable;

      let resVariable = Math.trunc(importeFinanciar * resCuatroVariable);
      setCuotaEstimadaVariable(resVariable);

      //plazo - variable - euribor
      let resUnoVariableEuribor = Math.pow(1 + interesEuribor, plazoVariable);
      let resDosVariableEuribor = interesEuribor * resUnoVariableEuribor;
      let resTrsVariableEuribor = resUnoVariableEuribor - 1;
      let resCuatroVariableEuribor =
        resDosVariableEuribor / resTrsVariableEuribor;

      let resVariableEuribor = Math.trunc(
        importeFinanciar * resCuatroVariableEuribor
      );
      setCuotaEstimadaVariableEuribor(resVariableEuribor);

      //mixta setCuotaEstimadaMixta
      let resUnoMixta = Math.pow(1 + interesMixtoReal, plazoMixto);
      let resDosMixta = interesMixtoReal * resUnoMixta;
      let resTrsMixta = resUnoMixta - 1;
      let resCuatroMixta = resDosMixta / resTrsMixta;
      let resVariableMixta = Math.trunc(importeFinanciar * resCuatroMixta);
      setCuotaEstimadaMixta(resVariableMixta);

      //mixta - euribor setCuotaEstimadaMixtaEuribor
      let resUnoMixtaEuribor = Math.pow(1 + interesEuriborMixto, plazoMixto);
      let resDosMixtaEuribor = interesEuriborMixto * resUnoMixtaEuribor;
      let resTrsMixtaEuribor = resUnoMixtaEuribor - 1;
      let resCuatroMixtaEuribor = resDosMixtaEuribor / resTrsMixtaEuribor;

      let resMixtaEuribor = Math.trunc(
        importeFinanciar * resCuatroMixtaEuribor
      );
      setCuotaEstimadaMixtaEuribor(resMixtaEuribor);

      //CALCULO LA ULTIMA CUOTA POSIBLE

      setTimeout(() => {
        setUnaVez(false);
        if (unaVez) {
          setPlazoPorcetaje({
            plazoFijo: res,
            plazoVariable: resVariable,
            plazoMixto: resVariableMixta,
          });
          setedadMaxima(
            parseInt(aniosTiempo) +
              parseInt(setearDatosCalculadora.edadMaximaTitular) -
              parseInt(setearDatosCalculadora.edadMaximaTitularMenor)
          );
        }
      }, 2000);

      //-----------------CALCULO CON EDAD DEL MAYOR ------------------------------------------ */

      // variables de apoyo para ver el plazo maximo

      // console.log(edadMaxima + " resto de edades");
      let valorMaxFijoInteres = 0;

      //-----------------------------------CALCULO INTERES FIJO MAXIMO CON EDAD MAXIMA
      if (edadMaxima <= 10) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaDiezFijo;
      } else if (edadMaxima <= 15) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaQuinceFijo;
      } else if (edadMaxima <= 20) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaVeinteFijo;
      } else if (edadMaxima <= 25) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaVeinteCincoFijo;
      } else if (edadMaxima <= 30) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaTreintaFijo;
      } else if (edadMaxima <= 35) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaTreintaCincoFijo;
      } else if (edadMaxima <= 40) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaCuarentaFijo;
      } else if (edadMaxima <= 45) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaCuarentaCincoFijo;
      } else if (edadMaxima >= 46) {
        valorMaxFijoInteres = setearDatosCalculadora.hastaCincuentFijo;
      }

      //aca seteamos y pasamos a decimal el interes maximo posible con respecto a la edad final
      let interesMaximoPosibleFijo = valorMaxFijoInteres / 100 / 12;
      let interesMaximoPosibleVariable =
        setearDatosCalculadora.tinPrimerosMesesVariable / 100 / 12;
      let interesMaximoPosiblemixto =
        setearDatosCalculadora.tinPrimerosMesesMixto / 100 / 12;
      let plazoFijaMax;
      let plazoVarMax;
      let plazoMixMax;
      if (
        parseInt(edadMaxima) > parseInt(setearDatosCalculadora.plazoFijoMaximo)
      ) {
        plazoFijaMax = parseInt(setearDatosCalculadora.plazoFijoMaximo) * 12;
      } else {
        plazoFijaMax = edadMaxima * 12;
      }

      if (
        parseInt(edadMaxima) >
        parseInt(setearDatosCalculadora.plazoVariableMaximo)
      ) {
        plazoVarMax = parseInt(setearDatosCalculadora.plazoVariableMaximo) * 12;
      } else {
        plazoVarMax = edadMaxima * 12;
      }

      if (
        parseInt(edadMaxima) > parseInt(setearDatosCalculadora.plazoMixtoMaximo)
      ) {
        plazoMixMax = parseInt(setearDatosCalculadora.plazoMixtoMaximo) * 12;
      } else {
        plazoMixMax = edadMaxima * 12;
      }

      //fija cuota inicial con edad maxima
      let resUnoFijaMax = Math.pow(1 + interesMaximoPosibleFijo, plazoFijaMax);
      let resDosFijaMax = interesMaximoPosibleFijo * resUnoFijaMax;
      let resTrsFijaMax = resUnoFijaMax - 1;
      let resCuatroFijaMax = resDosFijaMax / resTrsFijaMax;
      setCuotaInicialFijaMaxima(
        Math.trunc(importeFinanciar * resCuatroFijaMax)
      );

      //variable cuota inicial con edad maxima
      let resUnoVarMax = Math.pow(
        1 + interesMaximoPosibleVariable,
        plazoVarMax
      );
      let resDosVarMax = interesMaximoPosibleVariable * resUnoVarMax;
      let resTrsVarMax = resUnoVarMax - 1;
      let resCuatroVarMax = resDosVarMax / resTrsVarMax;
      setCuotaInicialVariableMaxima(
        Math.trunc(importeFinanciar * resCuatroVarMax)
      );

      //mixta cuota inicial con edad maxima
      let resUnoMixMax = Math.pow(1 + interesMaximoPosiblemixto, plazoMixMax);
      let resDosMixMax = interesMaximoPosiblemixto * resUnoMixMax;
      let resTrsMixMax = resUnoMixMax - 1;
      let resCuatroMixMax = resDosMixMax / resTrsMixMax;
      setcuotaInicialMixtaMaxima(
        Math.trunc(importeFinanciar * resCuatroMixMax)
      );
      console.log(plazoFijaMax + " soy la edad maxzi FIJO");
      console.log(plazoVarMax + " soy la edad maxzi VAR");
      console.log(plazoMixMax + " soy la edad maxzi MIX");
      //-----------------FIN CALCULO CON EDAD DEL MAYOR ------------------------------------------ */
    }
  }, [
    aniosTiempo,
    aniosTiempoMixto,
    aniosTiempoVariable,
    buscarDiferencialEuribor,
    calculoDeEdadMaximaPrincipio,
    datosCuestionario.edadViavilidad,
    datosCuestionario.edadViavilidadCuatro,
    datosCuestionario.edadViavilidadDos,
    datosCuestionario.edadViavilidadTres,
    datosCuestionario.hipotecaSolicitada,
    datosCuestionario.hipotecaSolicitadaConHonorarios,
    datosCuestionario.honorariosDeEscritura,
    datosCuestionario.resPreguntaCero,
    edadCalculoFinal,
    edadMaxima,
    euribor,
    innteresFija,
    innteresMixto,
    innteresVariable,
    modalDeCarga,
    plazoFinalSiExisteEdadDos,
    resEdadMaxPlazoFijo,
    resEdadMaxPlazoMixto,
    resEdadMaxPlazoVariable,
    setModalDeCarga,
    setearDatosCalculadora.edadMaximaTitular,
    setearDatosCalculadora.edadMaximaTitularMenor,
    setearDatosCalculadora.hastaCincuentFijo,
    setearDatosCalculadora.hastaCincuentMixto,
    setearDatosCalculadora.hastaCincuentVariable,
    setearDatosCalculadora.hastaCuarentaCincoFijo,
    setearDatosCalculadora.hastaCuarentaCincoMixto,
    setearDatosCalculadora.hastaCuarentaCincoVariable,
    setearDatosCalculadora.hastaCuarentaFijo,
    setearDatosCalculadora.hastaCuarentaMixto,
    setearDatosCalculadora.hastaCuarentaVariable,
    setearDatosCalculadora.hastaDiezFijo,
    setearDatosCalculadora.hastaDiezMixto,
    setearDatosCalculadora.hastaDiezVariable,
    setearDatosCalculadora.hastaQuinceFijo,
    setearDatosCalculadora.hastaQuinceMixto,
    setearDatosCalculadora.hastaQuinceVariable,
    setearDatosCalculadora.hastaTreintaCincoFijo,
    setearDatosCalculadora.hastaTreintaCincoMixto,
    setearDatosCalculadora.hastaTreintaCincoVariable,
    setearDatosCalculadora.hastaTreintaFijo,
    setearDatosCalculadora.hastaTreintaMixto,
    setearDatosCalculadora.hastaTreintaVariable,
    setearDatosCalculadora.hastaVeinteCincoFijo,
    setearDatosCalculadora.hastaVeinteCincoMixto,
    setearDatosCalculadora.hastaVeinteCincoVariable,
    setearDatosCalculadora.hastaVeinteFijo,
    setearDatosCalculadora.hastaVeinteMixto,
    setearDatosCalculadora.hastaVeinteVariable,
    setearDatosCalculadora.incuirEdadtitualMenor,
    setearDatosCalculadora.plazoFijoMaximo,
    setearDatosCalculadora.plazoMixtoMaximo,
    setearDatosCalculadora.plazoVariableMaximo,
    setearDatosCalculadora.tinPrimerosMesesMixto,
    setearDatosCalculadora.tinPrimerosMesesVariable,
    unaVez,
    valorFijoMixto,
  ]);

  function back() {
    setModalDeCarga(false);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    if (datosCuestionario.resPreguntaCero === "si"){
      setCuestionario(3);
      setMostrarCuadros(true);
    }else{
      swalWithBootstrapButtons
      .fire({
        title: "Si vuelves atras se resetearan los honrarios de gestion!",
        text: "Esto significa que la hipoteca se volvera a calcular con los honorarios reseteados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, adelante!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
            setCuestionario(4);
            setHonorariosGestion(500);
            setCount(0);
            setCalculoDeEdadMaximaPrincipio(true);
            if (80 <= parseInt(setearDatosCalculadora.porcentajeDelimitante)) {
              setTotal(
                parseInt(setearDatosCalculadora.honorariosMinimoInferior)
              );
              setVarIincremento(0);
            } else {
              setTotal(
                parseInt(setearDatosCalculadora.honorariosMaximoInferior)
              );
              setVarIincremento(0);
            }
            setMostrarCuadros(true);
          
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          setPorcentaje(100);
        }
      });
    }

   
  }

  async function guardarSimulacionEnBD() {
    await setDoc(doc(db, "simulaciones", datosCuestionario.telefonoViabilidad), {
      
      peronasLength: datosCuestionario.peronasLength,
      interesFija: innteresFija,
      aniosFija: aniosTiempo,
      tiempoFijoMinimo: setearDatosCalculadora.plazoFijoMinimo,
      tiempoFijoMaximo: resEdadMaxPlazoFijo,
      cuotaInicialFija: datosCuestionario.cuotaEstimadaFija,
      //variable
      primerosMesesVar: setearDatosCalculadora.primerPeriodoVariable,
      primerosMesesVarInteres: setearDatosCalculadora.tinPrimerosMesesVariable,
      restoPlazoInteresVar: innteresVariable,
      aniosVariable: datosCuestionario.aniosTiempoVariable,
      tiempoVariableMinimo: setearDatosCalculadora.plazoFijoMinimo,
      tiempoVariableMaximo: datosCuestionario.aniosTiempoVariable,
      cuotaInicialVariable: datosCuestionario.cuotaEstimadaVariable,
      cuotaRestoVariable: datosCuestionario.cuotaEstimadaVariableEuribor,
     //mixto
     primerosMesesMix: setearDatosCalculadora.primerPeriodoMixto,
     primerosMesesMixInteres: setearDatosCalculadora.tinPrimerosMesesMixto,
     restoPlazoInteresMix: innteresMixto,
     aniosMixto: datosCuestionario.aniosTiempoMixto,
     tiempoMixiableMinimo: setearDatosCalculadora.plazoFijoMinimo,
     tiempoMixiableMaximo: datosCuestionario.aniosTiempoMixto,
     cuotaInicialMixiable: datosCuestionario.cuotaEstimadaMixta,
     cuotaRestoMixiable: datosCuestionario.cuotaEstimadaMixtaEuribor,

    resPreguntaCero: datosCuestionario.resPreguntaCero,
    resTres: datosCuestionario.resTres,
    mejorasAntiguedad: datosCuestionario.mejorasAntiguedad,
    mejorasAntiguedadDos: datosCuestionario.mejorasAntiguedadDos,
    mejorasRatio: datosCuestionario.mejorasRatio,
    mejorasGarantia: datosCuestionario.mejorasGarantia,
    nombreViabilidad: datosCuestionario.nombreViabilidad,
    apellidoViabilidad: datosCuestionario.apellidoViabilidad,
    edadViavilidad: datosCuestionario.edadViavilidad,
    telefonoViabilidad: datosCuestionario.telefonoViabilidad,
    situacionLaboral: datosCuestionario.situacionLaboral,
    fechaDeIngreso: datosCuestionario.fechaDeIngreso,
    ingresosNetosMensuales: datosCuestionario.ingresosNetosMensuales,
    pagasAnuales: datosCuestionario.pagasAnuales,
    pagaDeCuotas: datosCuestionario.pagaDeCuotas,
    financiacionPorcentaje: datosCuestionario.financiacionPorcentaje === undefined ? 0 : datosCuestionario.financiacionPorcentaje,
    hipotecaSolicitada: datosCuestionario.hipotecaSolicitada,
    honorariosDeGestion: datosCuestionario.honorariosDeGestion,
    porcentaje: datosCuestionario.porcentaje,
    porcentajeMem: datosCuestionario.porcentajeMem,
    financiacionPorcen: datosCuestionario.financiacionPorcen,
    hipoteca: datosCuestionario.hipoteca,
    plazoHipoteca: datosCuestionario.plazoHipoteca,
    cuotaEstimadaFija: datosCuestionario.cuotaEstimadaFija,
    cuotaEstimadaVariable: datosCuestionario.cuotaEstimadaVariable,
    cuotaEstimadaVariableEuribor: datosCuestionario.cuotaEstimadaVariableEuribor,
    cuotaEstimadaMixta: datosCuestionario.cuotaEstimadaMixta,
    cuotaEstimadaMixtaEuribor: datosCuestionario.cuotaEstimadaMixtaEuribor,
    escenario: datosCuestionario.escenario,
    valorFijoMixto: datosCuestionario.valorFijoMixto,
    aniosTiempo: datosCuestionario.aniosTiempo,
    aniosTiempoVariable: datosCuestionario.aniosTiempoVariable,
    aniosTiempoMixto: datosCuestionario.aniosTiempoMixto,
    innteresFija: datosCuestionario.innteresFija,
    innteresVariable: datosCuestionario.innteresVariable,
    innteresVariableEuribor: datosCuestionario.innteresVariableEuribor,
    interesMixta: datosCuestionario.interesMixta,
    interesMixtaEuribor: datosCuestionario.interesMixtaEuribor,
    hipotecaSolicitadaConHonorarios: datosCuestionario.hipotecaSolicitadaConHonorarios === undefined ? 0 : datosCuestionario.hipotecaSolicitadaConHonorarios,
    honorariosDeEscritura: datosCuestionario.honorariosDeEscritura === undefined ? 'no' : datosCuestionario.honorariosDeEscritura,
    hipotecaSolicitadaHonarios: datosCuestionario.hipotecaSolicitadaHonarios === undefined ? 0 : datosCuestionario.hipotecaSolicitadaHonarios,
    hipotecaMasComisionMasHonorarios: datosCuestionario.hipotecaMasComisionMasHonorarios === undefined ? 0 : datosCuestionario.hipotecaMasComisionMasHonorarios,
    segundaGarantia: datosCuestionario.segundaGarantia === undefined ? 'si' : datosCuestionario.segundaGarantia,
    valorAproxSegundaGarantia: datosCuestionario.valorAproxSegundaGarantia === undefined ? 0 : datosCuestionario.valorAproxSegundaGarantia,
    totalAcobrar: datosCuestionario.totalAcobrar === undefined ? 0 : datosCuestionario.totalAcobrar,
    calculadora: setearDatosCalculadora.id,
    dniViabilidad: datosCuestionario.dniViabilidad === undefined ? 'undefined' : datosCuestionario.dniViabilidad,
    emailViabilidad: datosCuestionario.emailViabilidad === undefined ? 'undefined' : datosCuestionario.emailViabilidad,
    domicilioViabilidad: datosCuestionario.domicilioViabilidad === undefined ? 'undefined' : datosCuestionario.domicilioViabilidad,
    poblacionViabilidad: datosCuestionario.poblacionViabilidad === undefined ? 'undefined' : datosCuestionario.poblacionViabilidad,
    provinciaViabilidad: datosCuestionario.provinciaViabilidad === undefined ? 'undefined' : datosCuestionario.provinciaViabilidad,
    //datos persona dos
    nombreViabilidadDos: datosCuestionario.nombreViabilidadDos === undefined ? 'undefined' : datosCuestionario.nombreViabilidadDos,
    edadViavilidadDos: datosCuestionario.edadViavilidadDos === undefined ? 'undefined' : datosCuestionario.edadViavilidadDos,
    fechaDeIngresoDos: datosCuestionario.fechaDeIngresoDos === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoDos,
    ingresosNetosMensualesDos: datosCuestionario.ingresosNetosMensualesDos === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesDos,
    pagasAnualesDos: datosCuestionario.pagasAnualesDos === undefined ? 'undefined' : datosCuestionario.pagasAnualesDos,
    pagaDeCuotasDos: datosCuestionario.pagaDeCuotasDos === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasDos,
    situacionLaboralDos: datosCuestionario.situacionLaboralDos === undefined ? 'undefined' : datosCuestionario.situacionLaboralDos,
    apellidoViabilidadDos: datosCuestionario.apellidoViabilidadDos === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadDos,
    dniViabilidadDos: datosCuestionario.dniViabilidadDos === undefined ? 'undefined' : datosCuestionario.dniViabilidadDos,
    emailViabilidadDos: datosCuestionario.emailViabilidadDos === undefined ? 'undefined' : datosCuestionario.emailViabilidadDos,
    telefonoViabilidadDos: datosCuestionario.telefonoViabilidadDos === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadDos,
    //datos persona tres
    nombreViabilidadTres: datosCuestionario.nombreViabilidadTres === undefined ? 'undefined' : datosCuestionario.nombreViabilidadTres,
    edadViavilidadTres: datosCuestionario.edadViavilidadTres === undefined ? 'undefined' : datosCuestionario.edadViavilidadTres,
    fechaDeIngresoTres: datosCuestionario.fechaDeIngresoTres === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoTres,
    ingresosNetosMensualesTres: datosCuestionario.ingresosNetosMensualesTres === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesTres,
    pagasAnualesTres: datosCuestionario.pagasAnualesTres === undefined ? 'undefined' : datosCuestionario.pagasAnualesTres,
    pagaDeCuotasTres: datosCuestionario.pagaDeCuotasTres === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasTres,
    situacionLaboralTres: datosCuestionario.situacionLaboralTres === undefined ? 'undefined' : datosCuestionario.situacionLaboralTres,
    apellidoViabilidadTres: datosCuestionario.apellidoViabilidadTres === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadTres,
    dniViabilidadTres: datosCuestionario.dniViabilidadTres === undefined ? 'undefined' : datosCuestionario.dniViabilidadTres,
    emailViabilidadTres: datosCuestionario.emailViabilidadTres === undefined ? 'undefined' : datosCuestionario.emailViabilidadTres,
    telefonoViabilidadTres: datosCuestionario.telefonoViabilidadTres === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadTres,
    //datos persona cuatro
    nombreViabilidadCuatro: datosCuestionario.nombreViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.nombreViabilidadCuatro,
    edadViavilidadCuatro: datosCuestionario.edadViavilidadCuatro === undefined ? 'undefined' : datosCuestionario.edadViavilidadCuatro,
    fechaDeIngresoCuatro: datosCuestionario.fechaDeIngresoCuatro === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoCuatro,
    ingresosNetosMensualesCuatro: datosCuestionario.ingresosNetosMensualesCuatro === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesCuatro,
    pagasAnualesCuatro: datosCuestionario.pagasAnualesCuatro === undefined ? 'undefined' : datosCuestionario.pagasAnualesCuatro,
    pagaDeCuotasCuatro: datosCuestionario.pagaDeCuotasCuatro === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasCuatro,
    situacionLaboralCuatro: datosCuestionario.situacionLaboralCuatro === undefined ? 'undefined' : datosCuestionario.situacionLaboralCuatro,
    apellidoViabilidadCuatro: datosCuestionario.apellidoViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadCuatro,
    dniViabilidadCuatro: datosCuestionario.dniViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.dniViabilidadCuatro,
    emailViabilidadCuatro: datosCuestionario.emailViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.emailViabilidadCuatro,
    telefonoViabilidadCuatro: datosCuestionario.telefonoViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadCuatro,
    viable: parseInt(porcentajeUno) + parseInt(porcentajeDos)+ parseInt(porcentajeTres) >= 75 ? 'si' : 'no',
  });
  }

  function generarCaracteresAleatorios() {
    const caracteres = [];
    // Generar los primeros 8 dígitos numéricos
    for (let i = 0; i < 8; i++) {
      const digitoAleatorio = Math.floor(Math.random() * 10);
      caracteres.push(digitoAleatorio);
    }
    // Generar los siguientes 4 caracteres alfabéticos
    const letras = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < 4; i++) {
      const letraAleatoria = letras.charAt(Math.floor(Math.random() * letras.length));
      caracteres.push(letraAleatoria);
    }
    // Convierte el array de caracteres en una cadena
    const resultadoAleatorio = caracteres.join('');  
    const resFinal = `${resultadoAleatorio}-${datosCuestionario.nombreViabilidad[0]}${datosCuestionario.apellidoViabilidad[0]}`
    return resFinal;
  }

  return (
    <>
      {viabilidad.optUno ? (
        <section className="container-firmax-form espaciado">
          <div className="title-calculadora">
            <h2 className="title-calculadora-sub-principal">Hipoteca:</h2>
            <h2 className="title-calculadora-sub">
              {setearDatosCalculadora.nombreInmobiliaria}
            </h2>
          </div>
          {/* <button onClick={()=>console.log(porcentaje , porcentajeUno , porcentajeDos, porcentajeTres)}>asd</button>   */}
          {/* <h1 style={{color:"red" , fontWeight:"700", fontSize:"30px"}}>Resultado viabilidad: {porcentaje}%</h1> */}
          <h1>
            Importe a solicitar:{" "}
            {datosCuestionario.resPreguntaCero === "si"
              ? Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
              : datosCuestionario.honorariosDeEscritura === "no"
              ? Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
              : Math.floor(
                  datosCuestionario.hipotecaSolicitadaConHonorarios / 500
                ) * 500}
            €
          </h1>
          {/* <h1
            onClick={() =>
              console.log(
                cuotaInicialFijaMaxima,
                cuotaInicialVariableMaxima,
                cuotaInicialMixtaMaxima
              )
            }
          >
            datos cues
          </h1> */}
          {/* <h1 onClick={() => console.log(seteoDeDatosUser)}>datos cuestionario</h1> */}
          <div className="container-firmax-form-title-intereses">
            <h1>Selecciona el</h1>
            <h1 style={{ color: "#8FCD60" }}>Tipo de interés</h1>
            <h1>y</h1>
            <h1 style={{ color: "#8FCD60" }}>Plazo</h1>
            <h1>de devolución</h1>
          </div>
          <div className="container-firmax-form-buttons-container">
            <button
              style={{
                boxShadow: fijo
                  ? "2px 5px 2px rgba(0,0,0,.5)"
                  : "0px 0px 0px rgba(0,0,0,.0)",
              }}
              onClick={() => seteoTipo(true, "fijo")}
              className="container-firmax-section-fija-button"
            >
              FIJO
            </button>
            <button
              style={{
                boxShadow: variable
                  ? "2px 5px 2px rgba(0,0,0,.5)"
                  : "0px 0px 0px rgba(0,0,0,.0)",
              }}
              onClick={() => seteoTipo(false, "variable")}
              className="container-firmax-section-fija-button"
            >
              VARIABLE
            </button>

            <button
              style={{
                boxShadow: mixto
                  ? "2px 5px 2px rgba(0,0,0,.5)"
                  : "0px 0px 0px rgba(0,0,0,.0)",
              }}
              onClick={() => seteoTipo(false, "mixto")}
              className="container-firmax-section-fija-button"
            >
              MIXTO
            </button>
          </div>
          {fijo ? (
            <>
              <section className="container-firmax-section-fija">
                <div className="container-firmax-section-fija-gap">
                  <h1 className="container-firmax-section-fija-colorOs">
                    Hipoteca Fija desde
                  </h1>
                  <h2>TIN bonificado</h2>
                  <h2 className="container-firmax-section-fija-colorOs">
                    {innteresFija}%
                  </h2>
                  <div className="container-firmax-section-fija-info-flex relative">
                    <p className="container-firmax-section-fija-info-flex-left">
                      {setearDatosCalculadora.plazoFijoMinimo}
                    </p>
                    <p className="container-firmax-section-fija-info-flex-right">
                      {resEdadMaxPlazoFijo}
                    </p>
                    <h2>Plazo elegido</h2>
                    <input
                      className="container-firmax-section-fija-input"
                      name="aniosTiempo"
                      type="number"
                      onChange={(e) => setaniosTiempo(e.target.value)}
                      value={aniosTiempo}
                    />
                    <h2>años</h2>
                    <input
                      style={{ marginBottom: "1rem" }}
                      className="input-range-width"
                      type="range"
                      min={setearDatosCalculadora.plazoFijoMinimo}
                      max={resEdadMaxPlazoFijo}
                      step="1"
                      value={aniosTiempo}
                      onChange={(e) => setaniosTiempo(e.target.value)}
                    />
                    <h2>Cuota inicial</h2>
                    <h1 className="container-firmax-section-fija-colorOs size">
                      {cuotaEstimadaFija}€
                    </h1>
                  </div>
                </div>
              </section>
              {mostrarConfirmar ? (
                <div className="container-firmax-section-confirmar-cancelar">
                  <button
                    style={{ background: "#da3c20" }}
                    onClick={() => setMostrarConfirmar(!mostrarConfirmar)}
                    className="container-firmax-section-fija-button"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={resultado}
                    className="container-firmax-section-fija-button"
                  >
                    Confirmar
                  </button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="container-firmax-section-fija-button alto"
                    onClick={back}
                  >
                    Atras
                  </button>
                  <button
                    onClick={(e) => calcularViabilidad(e)}
                    className="container-firmax-section-fija-button alto"
                  >
                    Calcular viabilidad de <br />
                    la hipoteca
                  </button>
                </div>
              )}
            </>
          ) : null}
          {variable ? (
            <>
              <section className="container-firmax-section-fija">
                <div className="container-firmax-section-fija-gap">
                  <h1 className="container-firmax-section-fija-colorOs">
                    Hipoteca Variable desde
                  </h1>
                  <div className="container-firmax-section-fija-flex">
                    <section>
                      <h2>
                        Primeros {setearDatosCalculadora.primerPeriodoVariable}{" "}
                        meses
                      </h2>
                      <h2 className="container-firmax-section-fija-colorOs">
                        {setearDatosCalculadora.tinPrimerosMesesVariable}%
                      </h2>
                    </section>
                    <section>
                      <h2>Resto de plazo Euribor +</h2>
                      <h2 className="container-firmax-section-fija-colorOs">
                        {innteresVariable}%
                      </h2>
                    </section>
                  </div>
                  <div className="container-firmax-section-fija-info-flex relative">
                    <p className="container-firmax-section-fija-info-flex-left">
                      {setearDatosCalculadora.plazoFijoMinimo}
                    </p>
                    <p className="container-firmax-section-fija-info-flex-right">
                      {resEdadMaxPlazoVariable}
                    </p>
                    <h2>Plazo elegido</h2>
                    <input
                      className="container-firmax-section-fija-input"
                      name="aniosTiempoVariable"
                      type="number"
                      onChange={(e) => setaniosTiempoVariable(e.target.value)}
                      value={aniosTiempoVariable}
                    />
                    <h2>años</h2>
                    <input
                      className="input-range-width"
                      type="range"
                      min={setearDatosCalculadora.plazoVariableMinimo}
                      max={resEdadMaxPlazoVariable}
                      step="1"
                      value={aniosTiempoVariable}
                      onChange={(e) => setaniosTiempoVariable(e.target.value)}
                    />
                    <div className="container-firmax-cuotas">
                      <div>
                        <h2>Cuota inicial</h2>
                        <h1 className="container-firmax-section-fija-colorOs size">
                          {Math.trunc(cuotaEstimadaFijaVariable)}€
                        </h1>
                      </div>
                      <div>
                        <h2>Resto del plazo</h2>
                        <h1 className="container-firmax-section-fija-colorOs size">
                          {cuotaEstimadaVariableEuribor}€
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {mostrarConfirmar ? (
                <div className="container-firmax-section-confirmar-cancelar">
                  <button
                    style={{ background: "#da3c20" }}
                    onClick={() => setMostrarConfirmar(!mostrarConfirmar)}
                    className="container-firmax-section-fija-button"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={resultado}
                    className="container-firmax-section-fija-button"
                  >
                    Confirmar
                  </button>
                </div>
              ) : (
                <div className="container-button-opts">
                  <button
                    className="container-firmax-section-fija-button alto"
                    onClick={back}
                  >
                    Atras
                  </button>
                  <button
                    onClick={(e) => calcularViabilidad(e)}
                    className="container-firmax-section-fija-button alto"
                  >
                    Calcular viabilidad de <br />
                    la hipoteca
                  </button>
                </div>
              )}
            </>
          ) : null}
          {mixto ? (
            <>
              <section className="container-firmax-section-fija">
                <div className="container-firmax-section-fija-gap">
                  <h1 className="container-firmax-section-fija-colorOs">
                    Hipoteca Mixta desde
                  </h1>
                  <div className="container-firmax-section-fija-flex">
                    <section>
                      <h2>
                        Primeros {setearDatosCalculadora.primerPeriodoMixto}{" "}
                        meses
                      </h2>
                      <h2 className="container-firmax-section-fija-colorOs">
                        {setearDatosCalculadora.tinPrimerosMesesMixto}%
                      </h2>
                    </section>
                    <section>
                      <h2>Resto de plazo Euribor +</h2>
                      <h2 className="container-firmax-section-fija-colorOs">
                        {innteresMixto}%
                      </h2>
                    </section>
                  </div>
                  <div className="container-firmax-section-fija-info-flex relative">
                    <p className="container-firmax-section-fija-info-flex-left">
                      {setearDatosCalculadora.plazoMixtoMinimo}
                    </p>
                    <p className="container-firmax-section-fija-info-flex-right">
                      {resEdadMaxPlazoMixto}
                    </p>
                    <h2>Plazo elegido</h2>
                    <input
                      className="container-firmax-section-fija-input"
                      name="aniosTiempoMixto"
                      type="number"
                      onChange={(e) => setaniosTiempoMixto(e.target.value)}
                      value={aniosTiempoMixto}
                    />
                    <h2>años</h2>
                    <input
                      className="input-range-width"
                      type="range"
                      min={setearDatosCalculadora.plazoMixtoMinimo}
                      max={resEdadMaxPlazoMixto}
                      step="1"
                      value={aniosTiempoMixto}
                      onChange={(e) => setaniosTiempoMixto(e.target.value)}
                    />
                    <div className="container-firmax-cuotas">
                      <div>
                        <h2>Cuota inicial</h2>
                        <h1 className="container-firmax-section-fija-colorOs size">
                          {Math.trunc(cuotaEstimadaMixta)}€
                        </h1>
                      </div>
                      <div>
                        <h2>Resto del plazo</h2>
                        <h1 className="container-firmax-section-fija-colorOs size">
                          {cuotaEstimadaMixtaEuribor}€
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {mostrarConfirmar ? (
                <div className="container-firmax-section-confirmar-cancelar">
                  <button
                    style={{ background: "#da3c20" }}
                    onClick={() => setMostrarConfirmar(!mostrarConfirmar)}
                    className="container-firmax-section-fija-button"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={resultado}
                    className="container-firmax-section-fija-button"
                  >
                    Confirmar
                  </button>
                </div>
              ) : (
                <div className="container-button-opts">
                  <button
                    className="container-firmax-section-fija-button alto"
                    onClick={back}
                  >
                    Atras
                  </button>
                  <button
                    onClick={(e) => calcularViabilidad(e)}
                    className="container-firmax-section-fija-button alto"
                  >
                    Calcular viabilidad de <br />
                    la hipoteca
                  </button>
                </div>
              )}
            </>
          ) : null}
        </section>
      ) : (
        <ResViabilidad
          setPorcentaje={setPorcentaje}
          porcentaje={porcentaje}
          setViabilidad={setViabilidad}
          viabilidad={viabilidad}
          datosCuestionario={datosCuestionario}
          contratoBD={contratoBD}
          setearDatosCalculadora={setearDatosCalculadora}
          mostrarEndeu={mostrarEndeu}
          mostrarRatio={mostrarRatio}
          numEscenario={numEscenario}
          setDatosCuestionario={setDatosCuestionario}
          euribor={euribor}
          innteresVariable={innteresVariable}
          innteresFija={innteresFija}
          porcentajeUno={porcentajeUno}
          porcentajeDos={porcentajeDos}
          porcentajeTres={porcentajeTres}
          mensualTotal={mensualTotal}
          pagaCuotaTotal={pagaCuotaTotal}
          indiceUno={indiceUno}
          indiceDos={indiceDos}
          indiceTres={indiceTres}
          indiceCuatro={indiceCuatro}
          antiUno={antiUno}
          antiDos={antiDos}
          antiTres={antiTres}
          antiCuatro={antiCuatro}
          date={date}
          resEdadMaxPlazoFijo={resEdadMaxPlazoFijo}
          aniosTiempo={aniosTiempo}
          resEdadMaxPlazoVariable={resEdadMaxPlazoVariable}
          innteresMixto={innteresMixto}
          seteoDeDatosUser={seteoDeDatosUser}
          solicitudHipotecaria={solicitudHipotecaria}
        />
      )}
    </>
  );
};

export default CuestionarioCinco;
