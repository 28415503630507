import logo from "../../img/logo.png";
import { useState, useEffect } from "react";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db, auth } from "../../Firebase/Credenciales";
import Swal from "sweetalert2";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import LogicaFinanzas from "./Calculadora/LogicaFinanzas";


const RecuperarSimulacion = () => {
  const navigate = useNavigate();
  const [usuarioSimulacion, setUsuarioSimulacion] = useState({});
  const [simulacionCss, setSimulacionCss] = useState("rec-simulacion");
  const [mostrar, setMostrar] = useState(false);
  const [seteo, setSeteo] = useState(true);
  const [pantallas, setPantallas] = useState(0);
  const [actionBack, setActionBack] = useState(null);
  const [date, setDate] = useState("");
  const [cuestionario, setCuestionario] = useState(1);
  const [seteoDeDatosUser, setSeteoDeDatosUser] = useState();
  const [setearDatosCalculadora, setSetearDatosCalculadora] = useState({
    nombre: "",
    nombreInmobiliaria: "",
    porcentajeDelimitante: "",
    honorariosMinimoInferior: "",
    honorariosMaximoInferior: "",
    comisionMinima: "",
    comisionPrimeraIncremnto: "",
    comisionExtraIncremento: "",
    uid: "",
    id: "",
  });
  const [simulaciones, setSimulaciones] = useState([]);
  const [simulacionesRespaldo, setSimulacionesRespaldo] = useState();
  const [mostrarSimulaciones, setmostrarSimulaciones] = useState(true);
  const [buscarFecha, setBuscarFecha] = useState();
  //const [datosCuest, serDatosCuest] = useContext(UserContext)
  let pushing = [];
  const [contratoBD, setContratoBD] = useState([]);

  async function traerContratosBd() {
    const querySnapshot = await getDocs(collection(db, "tipoDeContrato"));
    querySnapshot.forEach((doc) => {
      let contrato = {
        nombre: doc.data().nombre,
        id: doc.data().id,
      };
      pushing.push(contrato);
    });
    setContratoBD(pushing);
  }

  async function handleNumberChange(e) {
    e.preventDefault();
    let number = e.target.tel.value;
    let arr = []
    console.log(number);
    try {
      console.log(number + " asdasd");
      const docRef = doc(db, "simulaciones", number);
      const docSnap = await getDoc(docRef);
      await traerContratosBd();

      setUsuarioSimulacion({
        calculadoraSelected: docSnap.data().calculadoraSelected,
        solicitudHipotecariaID: docSnap.data().solicitudHipotecariaID,
        optMejoraGarantia: docSnap.data().optMejoraGarantia === undefined ? undefined : docSnap.data().optMejoraGarantia,
        optMejoraAntiguedad: docSnap.data().optMejoraAntiguedad === undefined ? undefined : docSnap.data().optMejoraAntiguedad,
        viable: docSnap.data().viable,
        razonSocial: docSnap.data().razonSocial,
        agente: docSnap.data().agente,
        fechaBD: docSnap.data().fechaBD,
        resUno: docSnap.data().resUno,
        interesFija: docSnap.data().interesFija,
        peronasLength: docSnap.data().peronasLength,
        aniosFija: docSnap.data().aniosFija,
        tiempoFijoMinimo: docSnap.data().tiempoFijoMinimo,
        tiempoFijoMaximo: docSnap.data().tiempoFijoMaximo,
        cuotaInicialFija: docSnap.data().cuotaInicialFija,
        //variable
        primerosMesesVar: docSnap.data().primerosMesesVar,
        primerosMesesVarInteres: docSnap.data().primerosMesesVarInteres,
        restoPlazoInteresVar: docSnap.data().restoPlazoInteresVar,
        aniosVariable: docSnap.data().aniosVariable,
        tiempoVariableMinimo: docSnap.data().tiempoVariableMinimo,
        tiempoVariableMaximo: docSnap.data().tiempoVariableMaximo,
        cuotaInicialVariable: docSnap.data().cuotaInicialVariable,
        cuotaRestoVariable: docSnap.data().cuotaRestoVariable,
        //mixto
        primerosMesesMix: docSnap.data().primerosMesesMix,
        primerosMesesMixInteres: docSnap.data().primerosMesesMixInteres,
        restoPlazoInteresMix: docSnap.data().restoPlazoInteresMix,
        aniosMixto: docSnap.data().aniosMixto,
        tiempoMixiableMinimo: docSnap.data().tiempoMixiableMinimo,
        tiempoMixiableMaximo: docSnap.data().tiempoMixiableMaximo,
        cuotaInicialMixiable: docSnap.data().cuotaInicialMixiable,
        cuotaRestoMixiable: docSnap.data().cuotaRestoMixiable,

        resPreguntaCero: docSnap.data().resPreguntaCero,
        resTres: docSnap.data().resTres,
        mejorasAntiguedad: docSnap.data().mejorasAntiguedad,
        mejorasAntiguedadDos: docSnap.data().mejorasAntiguedadDos,
        mejorasRatio: docSnap.data().mejorasRatio,
        mejorasGarantia: docSnap.data().mejorasGarantia,
        nombreViabilidad: docSnap.data().nombreViabilidad,
        apellidoViabilidad: docSnap.data().apellidoViabilidad,
        edadViavilidad: docSnap.data().edadViavilidad,
        telefonoViabilidad: docSnap.data().telefonoViabilidad,
        situacionLaboral: docSnap.data().situacionLaboral,
        fechaDeIngreso: docSnap.data().fechaDeIngreso,
        ingresosNetosMensuales: docSnap.data().ingresosNetosMensuales,
        pagasAnuales: docSnap.data().pagasAnuales,
        pagaDeCuotas: docSnap.data().pagaDeCuotas,
        financiacionPorcentaje: docSnap.data().financiacionPorcentaje,
        hipotecaSolicitada: docSnap.data().hipotecaSolicitada,
        honorariosDeGestion: docSnap.data().honorariosDeGestion,
        porcentaje: docSnap.data().porcentaje,
        porcentajeMem: docSnap.data().porcentajeMem,
        financiacionPorcen: docSnap.data().financiacionPorcen,
        hipoteca: docSnap.data().hipoteca,
        plazoHipoteca: docSnap.data().plazoHipoteca,
        cuotaEstimadaFija: docSnap.data().cuotaEstimadaFija,
        cuotaEstimadaVariable: docSnap.data().cuotaEstimadaVariable,
        cuotaEstimadaVariableEuribor:
          docSnap.data().cuotaEstimadaVariableEuribor,
        cuotaEstimadaMixta: docSnap.data().cuotaEstimadaMixta,
        cuotaEstimadaMixtaEuribor: docSnap.data().cuotaEstimadaMixtaEuribor,
        escenario: docSnap.data().escenario,
        valorFijoMixto: docSnap.data().valorFijoMixto,
        aniosTiempo: docSnap.data().aniosTiempo,
        aniosTiempoVariable: docSnap.data().aniosTiempoVariable,
        aniosTiempoMixto: docSnap.data().aniosTiempoMixto,
        innteresFija: docSnap.data().innteresFija,
        innteresVariable: docSnap.data().innteresVariable,
        innteresVariableEuribor: docSnap.data().innteresVariableEuribor,
        interesMixta: docSnap.data().interesMixta,
        interesMixtaEuribor: docSnap.data().interesMixtaEuribor,
        hipotecaSolicitadaConHonorarios:
          docSnap.data().hipotecaSolicitadaConHonorarios === undefined
            ? 0
            : docSnap.data().hipotecaSolicitadaConHonorarios,
        honorariosDeEscritura:
          docSnap.data().honorariosDeEscritura === undefined
            ? "no"
            : docSnap.data().honorariosDeEscritura,
        hipotecaSolicitadaHonarios:
          docSnap.data().hipotecaSolicitadaHonarios === undefined
            ? 0
            : docSnap.data().hipotecaSolicitadaHonarios,
        hipotecaMasComisionMasHonorarios:
          docSnap.data().hipotecaMasComisionMasHonorarios === undefined
            ? 0
            : docSnap.data().hipotecaMasComisionMasHonorarios,
        segundaGarantia:
          docSnap.data().segundaGarantia === undefined
            ? "si"
            : docSnap.data().segundaGarantia,
        valorAproxSegundaGarantia:
          docSnap.data().valorAproxSegundaGarantia === undefined
            ? 0
            : docSnap.data().valorAproxSegundaGarantia,
        totalAcobrar:
          docSnap.data().totalAcobrar === undefined
            ? 0
            : docSnap.data().totalAcobrar,
        calculadora: docSnap.data().calculadora,
        //datos persona dos
        nombreViabilidadDos:
          docSnap.data().nombreViabilidadDos === undefined
            ? undefined
            : docSnap.data().nombreViabilidadDos,
        edadViavilidadDos:
          docSnap.data().edadViavilidadDos === undefined
            ? undefined
            : docSnap.data().edadViavilidadDos,
        fechaDeIngresoDos:
          docSnap.data().fechaDeIngresoDos === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoDos,
        ingresosNetosMensualesDos:
          docSnap.data().ingresosNetosMensualesDos === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesDos,
        pagasAnualesDos:
          docSnap.data().pagasAnualesDos === undefined
            ? undefined
            : docSnap.data().pagasAnualesDos,
        pagaDeCuotasDos:
          docSnap.data().pagaDeCuotasDos === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasDos,
        situacionLaboralDos:
          docSnap.data().situacionLaboralDos === undefined
            ? undefined
            : docSnap.data().situacionLaboralDos,
        //datos persona tres
        nombreViabilidadTres:
          docSnap.data().nombreViabilidadTres === undefined
            ? undefined
            : docSnap.data().nombreViabilidadTres,
        edadViavilidadTres:
          docSnap.data().edadViavilidadTres === undefined
            ? undefined
            : docSnap.data().edadViavilidadTres,
        fechaDeIngresoTres:
          docSnap.data().fechaDeIngresoTres === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoTres,
        ingresosNetosMensualesTres:
          docSnap.data().ingresosNetosMensualesTres === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesTres,
        pagasAnualesTres:
          docSnap.data().pagasAnualesTres === undefined
            ? undefined
            : docSnap.data().pagasAnualesTres,
        pagaDeCuotasTres:
          docSnap.data().pagaDeCuotasTres === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasTres,
        situacionLaboralTres:
          docSnap.data().situacionLaboralTres === undefined
            ? undefined
            : docSnap.data().situacionLaboralTres,
        //datos persona cuatro
        nombreViabilidadCuatro:
          docSnap.data().nombreViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().nombreViabilidadCuatro,
        edadViavilidadCuatro:
          docSnap.data().edadViavilidadCuatro === undefined
            ? undefined
            : docSnap.data().edadViavilidadCuatro,
        fechaDeIngresoCuatro:
          docSnap.data().fechaDeIngresoCuatro === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoCuatro,
        ingresosNetosMensualesCuatro:
          docSnap.data().ingresosNetosMensualesCuatro === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesCuatro,
        pagasAnualesCuatro:
          docSnap.data().pagasAnualesCuatro === undefined
            ? undefined
            : docSnap.data().pagasAnualesCuatro,
        pagaDeCuotasCuatro:
          docSnap.data().pagaDeCuotasCuatro === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasCuatro,
        situacionLaboralCuatro:
          docSnap.data().situacionLaboralCuatro === undefined
            ? undefined
            : docSnap.data().situacionLaboralCuatro,
        dniViabilidad: docSnap.data().dniViabilidad,
        emailViabilidad: docSnap.data().emailViabilidad,
        domicilioViabilidad: docSnap.data().domicilioViabilidad,
        poblacionViabilidad: docSnap.data().poblacionViabilidad,
        provinciaViabilidad: docSnap.data().provinciaViabilidad,
        apellidoViabilidadDos:
          docSnap.data().apellidoViabilidadDos === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadDos,
        dniViabilidadDos:
          docSnap.data().dniViabilidadDos === undefined
            ? undefined
            : docSnap.data().dniViabilidadDos,
        emailViabilidadDos:
          docSnap.data().emailViabilidadDos === undefined
            ? undefined
            : docSnap.data().emailViabilidadDos,
        telefonoViabilidadDos:
          docSnap.data().telefonoViabilidadDos === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadDos,
        apellidoViabilidadTres:
          docSnap.data().apellidoViabilidadTres === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadTres,
        dniViabilidadTres:
          docSnap.data().dniViabilidadTres === undefined
            ? undefined
            : docSnap.data().dniViabilidadTres,
        emailViabilidadTres:
          docSnap.data().emailViabilidadTres === undefined
            ? undefined
            : docSnap.data().emailViabilidadTres,
        telefonoViabilidadTres:
          docSnap.data().telefonoViabilidadTres === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadTres,
        apellidoViabilidadCuatro:
          docSnap.data().apellidoViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadCuatro,
        dniViabilidadCuatro:
          docSnap.data().dniViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().dniViabilidadCuatro,
        emailViabilidadCuatro:
          docSnap.data().emailViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().emailViabilidadCuatro,
        telefonoViabilidadCuatro:
          docSnap.data().telefonoViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadCuatro,
      });

      arr.push({
        calculadoraSelected: docSnap.data().calculadoraSelected,
        solicitudHipotecariaID: docSnap.data().solicitudHipotecariaID,
        optMejoraGarantia: docSnap.data().optMejoraGarantia === undefined ? undefined : docSnap.data().optMejoraGarantia,
        optMejoraAntiguedad: docSnap.data().optMejoraAntiguedad === undefined ? undefined : docSnap.data().optMejoraAntiguedad,
        viable: docSnap.data().viable,
        razonSocial: docSnap.data().razonSocial,
        agente: docSnap.data().agente,
        resUno: docSnap.data().resUno,
        fechaBD: docSnap.data().fechaBD,
        interesFija: docSnap.data().interesFija,
        peronasLength: docSnap.data().peronasLength,
        aniosFija: docSnap.data().aniosFija,
        tiempoFijoMinimo: docSnap.data().tiempoFijoMinimo,
        tiempoFijoMaximo: docSnap.data().tiempoFijoMaximo,
        cuotaInicialFija: docSnap.data().cuotaInicialFija,
        //variable
        primerosMesesVar: docSnap.data().primerosMesesVar,
        primerosMesesVarInteres: docSnap.data().primerosMesesVarInteres,
        restoPlazoInteresVar: docSnap.data().restoPlazoInteresVar,
        aniosVariable: docSnap.data().aniosVariable,
        tiempoVariableMinimo: docSnap.data().tiempoVariableMinimo,
        tiempoVariableMaximo: docSnap.data().tiempoVariableMaximo,
        cuotaInicialVariable: docSnap.data().cuotaInicialVariable,
        cuotaRestoVariable: docSnap.data().cuotaRestoVariable,
        //mixto
        primerosMesesMix: docSnap.data().primerosMesesMix,
        primerosMesesMixInteres: docSnap.data().primerosMesesMixInteres,
        restoPlazoInteresMix: docSnap.data().restoPlazoInteresMix,
        aniosMixto: docSnap.data().aniosMixto,
        tiempoMixiableMinimo: docSnap.data().tiempoMixiableMinimo,
        tiempoMixiableMaximo: docSnap.data().tiempoMixiableMaximo,
        cuotaInicialMixiable: docSnap.data().cuotaInicialMixiable,
        cuotaRestoMixiable: docSnap.data().cuotaRestoMixiable,

        resPreguntaCero: docSnap.data().resPreguntaCero,
        resTres: docSnap.data().resTres,
        mejorasAntiguedad: docSnap.data().mejorasAntiguedad,
        mejorasAntiguedadDos: docSnap.data().mejorasAntiguedadDos,
        mejorasRatio: docSnap.data().mejorasRatio,
        mejorasGarantia: docSnap.data().mejorasGarantia,
        nombreViabilidad: docSnap.data().nombreViabilidad,
        apellidoViabilidad: docSnap.data().apellidoViabilidad,
        edadViavilidad: docSnap.data().edadViavilidad,
        telefonoViabilidad: docSnap.data().telefonoViabilidad,
        situacionLaboral: docSnap.data().situacionLaboral,
        fechaDeIngreso: docSnap.data().fechaDeIngreso,
        ingresosNetosMensuales: docSnap.data().ingresosNetosMensuales,
        pagasAnuales: docSnap.data().pagasAnuales,
        pagaDeCuotas: docSnap.data().pagaDeCuotas,
        financiacionPorcentaje: docSnap.data().financiacionPorcentaje,
        hipotecaSolicitada: docSnap.data().hipotecaSolicitada,
        honorariosDeGestion: docSnap.data().honorariosDeGestion,
        porcentaje: docSnap.data().porcentaje,
        porcentajeMem: docSnap.data().porcentajeMem,
        financiacionPorcen: docSnap.data().financiacionPorcen,
        hipoteca: docSnap.data().hipoteca,
        plazoHipoteca: docSnap.data().plazoHipoteca,
        cuotaEstimadaFija: docSnap.data().cuotaEstimadaFija,
        cuotaEstimadaVariable: docSnap.data().cuotaEstimadaVariable,
        cuotaEstimadaVariableEuribor:
          docSnap.data().cuotaEstimadaVariableEuribor,
        cuotaEstimadaMixta: docSnap.data().cuotaEstimadaMixta,
        cuotaEstimadaMixtaEuribor: docSnap.data().cuotaEstimadaMixtaEuribor,
        escenario: docSnap.data().escenario,
        valorFijoMixto: docSnap.data().valorFijoMixto,
        aniosTiempo: docSnap.data().aniosTiempo,
        aniosTiempoVariable: docSnap.data().aniosTiempoVariable,
        aniosTiempoMixto: docSnap.data().aniosTiempoMixto,
        innteresFija: docSnap.data().innteresFija,
        innteresVariable: docSnap.data().innteresVariable,
        innteresVariableEuribor: docSnap.data().innteresVariableEuribor,
        interesMixta: docSnap.data().interesMixta,
        interesMixtaEuribor: docSnap.data().interesMixtaEuribor,
        hipotecaSolicitadaConHonorarios:
          docSnap.data().hipotecaSolicitadaConHonorarios === undefined
            ? 0
            : docSnap.data().hipotecaSolicitadaConHonorarios,
        honorariosDeEscritura:
          docSnap.data().honorariosDeEscritura === undefined
            ? "no"
            : docSnap.data().honorariosDeEscritura,
        hipotecaSolicitadaHonarios:
          docSnap.data().hipotecaSolicitadaHonarios === undefined
            ? 0
            : docSnap.data().hipotecaSolicitadaHonarios,
        hipotecaMasComisionMasHonorarios:
          docSnap.data().hipotecaMasComisionMasHonorarios === undefined
            ? 0
            : docSnap.data().hipotecaMasComisionMasHonorarios,
        segundaGarantia:
          docSnap.data().segundaGarantia === undefined
            ? "si"
            : docSnap.data().segundaGarantia,
        valorAproxSegundaGarantia:
          docSnap.data().valorAproxSegundaGarantia === undefined
            ? 0
            : docSnap.data().valorAproxSegundaGarantia,
        totalAcobrar:
          docSnap.data().totalAcobrar === undefined
            ? 0
            : docSnap.data().totalAcobrar,
        calculadora: docSnap.data().calculadora,
        //datos persona dos
        nombreViabilidadDos:
          docSnap.data().nombreViabilidadDos === undefined
            ? undefined
            : docSnap.data().nombreViabilidadDos,
        edadViavilidadDos:
          docSnap.data().edadViavilidadDos === undefined
            ? undefined
            : docSnap.data().edadViavilidadDos,
        fechaDeIngresoDos:
          docSnap.data().fechaDeIngresoDos === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoDos,
        ingresosNetosMensualesDos:
          docSnap.data().ingresosNetosMensualesDos === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesDos,
        pagasAnualesDos:
          docSnap.data().pagasAnualesDos === undefined
            ? undefined
            : docSnap.data().pagasAnualesDos,
        pagaDeCuotasDos:
          docSnap.data().pagaDeCuotasDos === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasDos,
        situacionLaboralDos:
          docSnap.data().situacionLaboralDos === undefined
            ? undefined
            : docSnap.data().situacionLaboralDos,
        //datos persona tres
        nombreViabilidadTres:
          docSnap.data().nombreViabilidadTres === undefined
            ? undefined
            : docSnap.data().nombreViabilidadTres,
        edadViavilidadTres:
          docSnap.data().edadViavilidadTres === undefined
            ? undefined
            : docSnap.data().edadViavilidadTres,
        fechaDeIngresoTres:
          docSnap.data().fechaDeIngresoTres === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoTres,
        ingresosNetosMensualesTres:
          docSnap.data().ingresosNetosMensualesTres === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesTres,
        pagasAnualesTres:
          docSnap.data().pagasAnualesTres === undefined
            ? undefined
            : docSnap.data().pagasAnualesTres,
        pagaDeCuotasTres:
          docSnap.data().pagaDeCuotasTres === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasTres,
        situacionLaboralTres:
          docSnap.data().situacionLaboralTres === undefined
            ? undefined
            : docSnap.data().situacionLaboralTres,
        //datos persona cuatro
        nombreViabilidadCuatro:
          docSnap.data().nombreViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().nombreViabilidadCuatro,
        edadViavilidadCuatro:
          docSnap.data().edadViavilidadCuatro === undefined
            ? undefined
            : docSnap.data().edadViavilidadCuatro,
        fechaDeIngresoCuatro:
          docSnap.data().fechaDeIngresoCuatro === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoCuatro,
        ingresosNetosMensualesCuatro:
          docSnap.data().ingresosNetosMensualesCuatro === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesCuatro,
        pagasAnualesCuatro:
          docSnap.data().pagasAnualesCuatro === undefined
            ? undefined
            : docSnap.data().pagasAnualesCuatro,
        pagaDeCuotasCuatro:
          docSnap.data().pagaDeCuotasCuatro === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasCuatro,
        situacionLaboralCuatro:
          docSnap.data().situacionLaboralCuatro === undefined
            ? undefined
            : docSnap.data().situacionLaboralCuatro,
        dniViabilidad: docSnap.data().dniViabilidad,
        emailViabilidad: docSnap.data().emailViabilidad,
        domicilioViabilidad: docSnap.data().domicilioViabilidad,
        poblacionViabilidad: docSnap.data().poblacionViabilidad,
        provinciaViabilidad: docSnap.data().provinciaViabilidad,
        apellidoViabilidadDos:
          docSnap.data().apellidoViabilidadDos === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadDos,
        dniViabilidadDos:
          docSnap.data().dniViabilidadDos === undefined
            ? undefined
            : docSnap.data().dniViabilidadDos,
        emailViabilidadDos:
          docSnap.data().emailViabilidadDos === undefined
            ? undefined
            : docSnap.data().emailViabilidadDos,
        telefonoViabilidadDos:
          docSnap.data().telefonoViabilidadDos === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadDos,
        apellidoViabilidadTres:
          docSnap.data().apellidoViabilidadTres === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadTres,
        dniViabilidadTres:
          docSnap.data().dniViabilidadTres === undefined
            ? undefined
            : docSnap.data().dniViabilidadTres,
        emailViabilidadTres:
          docSnap.data().emailViabilidadTres === undefined
            ? undefined
            : docSnap.data().emailViabilidadTres,
        telefonoViabilidadTres:
          docSnap.data().telefonoViabilidadTres === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadTres,
        apellidoViabilidadCuatro:
          docSnap.data().apellidoViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadCuatro,
        dniViabilidadCuatro:
          docSnap.data().dniViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().dniViabilidadCuatro,
        emailViabilidadCuatro:
          docSnap.data().emailViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().emailViabilidadCuatro,
        telefonoViabilidadCuatro:
          docSnap.data().telefonoViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadCuatro,
      })

      setSimulaciones(arr)
      setMostrar(true);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Lo sentimos, el usuario con ese telefono no existe`,
        footer: "Revise bien el campo y vuelva a intentarlo",
      });
    }
  }

  async function seteoDeUser(usuario) {
    try {
      await traerContratosBd();
      setUsuarioSimulacion(usuario);
      setMostrar(true);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Lo sentimos, el usuario con ese telefono no existe`,
        footer: "Revise bien el campo y vuelva a intentarlo",
      });
    }
  }

  async function empezarCuestionario() {
    const querySnapshotDos = await getDocs(collection(db, "calculadoras"));

    querySnapshotDos.forEach((docDos) => {
      console.log(docDos.data().id);
      console.log(usuarioSimulacion.calculadora);
      if (docDos.data().id === usuarioSimulacion.calculadora) {
        setSetearDatosCalculadora(docDos.data().datosNuevaCalculadora);
        setPantallas(1);
        setSimulacionCss("rec-simulacion-dos");

        Swal.fire({
          icon: "info",
          title: "A tener en cuenta...",
          text: `Al continuar con la nueva simulación, deberá tener en cuenta que los honorarios de gestión seleccionados y el plazo elegido en la simulación previamente realizada, se pondrán automáticamente en su estado por defecto.`,
          footer: "Revise bien cada campo antes de continuar",
        });
      }
    });
  }

  async function traerSimulaciones(mail) {
    let pushing = [];
    const querySnapshot = await getDocs(collection(db, mail));
    querySnapshot.forEach((docSnap) => {
      let simulaciones = {
        calculadoraSelected: docSnap.data().calculadoraSelected,
        solicitudHipotecariaID: docSnap.data().solicitudHipotecariaID,
        optMejoraGarantia: docSnap.data().optMejoraGarantia === undefined ? undefined : docSnap.data().optMejoraGarantia,
        optMejoraAntiguedad: docSnap.data().optMejoraAntiguedad === undefined ? undefined : docSnap.data().optMejoraAntiguedad,
        razonSocial: docSnap.data().razonSocial,
        agente: docSnap.data().agente,
        resUno: docSnap.data().resUno,
        fechaBD: docSnap.data().fechaBD,
        interesFija: docSnap.data().interesFija,
        peronasLength: docSnap.data().peronasLength,
        aniosFija: docSnap.data().aniosFija,
        tiempoFijoMinimo: docSnap.data().tiempoFijoMinimo,
        tiempoFijoMaximo: docSnap.data().tiempoFijoMaximo,
        cuotaInicialFija: docSnap.data().cuotaInicialFija,
        //variable
        primerosMesesVar: docSnap.data().primerosMesesVar,
        primerosMesesVarInteres: docSnap.data().primerosMesesVarInteres,
        restoPlazoInteresVar: docSnap.data().restoPlazoInteresVar,
        aniosVariable: docSnap.data().aniosVariable,
        tiempoVariableMinimo: docSnap.data().tiempoVariableMinimo,
        tiempoVariableMaximo: docSnap.data().tiempoVariableMaximo,
        cuotaInicialVariable: docSnap.data().cuotaInicialVariable,
        cuotaRestoVariable: docSnap.data().cuotaRestoVariable,
        //mixto
        primerosMesesMix: docSnap.data().primerosMesesMix,
        primerosMesesMixInteres: docSnap.data().primerosMesesMixInteres,
        restoPlazoInteresMix: docSnap.data().restoPlazoInteresMix,
        aniosMixto: docSnap.data().aniosMixto,
        tiempoMixiableMinimo: docSnap.data().tiempoMixiableMinimo,
        tiempoMixiableMaximo: docSnap.data().tiempoMixiableMaximo,
        cuotaInicialMixiable: docSnap.data().cuotaInicialMixiable,
        cuotaRestoMixiable: docSnap.data().cuotaRestoMixiable,

        resPreguntaCero: docSnap.data().resPreguntaCero,
        resTres: docSnap.data().resTres,
        mejorasAntiguedad: docSnap.data().mejorasAntiguedad,
        mejorasAntiguedadDos: docSnap.data().mejorasAntiguedadDos,
        mejorasRatio: docSnap.data().mejorasRatio,
        mejorasGarantia: docSnap.data().mejorasGarantia,
        nombreViabilidad: docSnap.data().nombreViabilidad,
        edadViavilidad: docSnap.data().edadViavilidad,
        apellidoViabilidad: docSnap.data().apellidoViabilidad,
        telefonoViabilidad: docSnap.data().telefonoViabilidad,
        situacionLaboral: docSnap.data().situacionLaboral,
        fechaDeIngreso: docSnap.data().fechaDeIngreso,
        ingresosNetosMensuales: docSnap.data().ingresosNetosMensuales,
        pagasAnuales: docSnap.data().pagasAnuales,
        pagaDeCuotas: docSnap.data().pagaDeCuotas,
        financiacionPorcentaje: docSnap.data().financiacionPorcentaje,
        hipotecaSolicitada: docSnap.data().hipotecaSolicitada,
        honorariosDeGestion: docSnap.data().honorariosDeGestion,
        porcentaje: docSnap.data().porcentaje,
        porcentajeMem: docSnap.data().porcentajeMem,
        financiacionPorcen: docSnap.data().financiacionPorcen,
        hipoteca: docSnap.data().hipoteca,
        plazoHipoteca: docSnap.data().plazoHipoteca,
        cuotaEstimadaFija: docSnap.data().cuotaEstimadaFija,
        cuotaEstimadaVariable: docSnap.data().cuotaEstimadaVariable,
        cuotaEstimadaVariableEuribor:
          docSnap.data().cuotaEstimadaVariableEuribor,
        cuotaEstimadaMixta: docSnap.data().cuotaEstimadaMixta,
        cuotaEstimadaMixtaEuribor: docSnap.data().cuotaEstimadaMixtaEuribor,
        escenario: docSnap.data().escenario,
        valorFijoMixto: docSnap.data().valorFijoMixto,
        aniosTiempo: docSnap.data().aniosTiempo,
        aniosTiempoVariable: docSnap.data().aniosTiempoVariable,
        aniosTiempoMixto: docSnap.data().aniosTiempoMixto,
        innteresFija: docSnap.data().innteresFija,
        innteresVariable: docSnap.data().innteresVariable,
        innteresVariableEuribor: docSnap.data().innteresVariableEuribor,
        interesMixta: docSnap.data().interesMixta,
        interesMixtaEuribor: docSnap.data().interesMixtaEuribor,
        hipotecaSolicitadaConHonorarios:
          docSnap.data().hipotecaSolicitadaConHonorarios === undefined
            ? 0
            : docSnap.data().hipotecaSolicitadaConHonorarios,
        honorariosDeEscritura:
          docSnap.data().honorariosDeEscritura === undefined
            ? "no"
            : docSnap.data().honorariosDeEscritura,
        hipotecaSolicitadaHonarios:
          docSnap.data().hipotecaSolicitadaHonarios === undefined
            ? 0
            : docSnap.data().hipotecaSolicitadaHonarios,
        hipotecaMasComisionMasHonorarios:
          docSnap.data().hipotecaMasComisionMasHonorarios === undefined
            ? 0
            : docSnap.data().hipotecaMasComisionMasHonorarios,
        segundaGarantia:
          docSnap.data().segundaGarantia === undefined
            ? "si"
            : docSnap.data().segundaGarantia,
        valorAproxSegundaGarantia:
          docSnap.data().valorAproxSegundaGarantia === undefined
            ? 0
            : docSnap.data().valorAproxSegundaGarantia,
        totalAcobrar:
          docSnap.data().totalAcobrar === undefined
            ? 0
            : docSnap.data().totalAcobrar,
        calculadora: docSnap.data().calculadora,
        //datos persona dos
        nombreViabilidadDos:
          docSnap.data().nombreViabilidadDos === undefined
            ? undefined
            : docSnap.data().nombreViabilidadDos,
        edadViavilidadDos:
          docSnap.data().edadViavilidadDos === undefined
            ? undefined
            : docSnap.data().edadViavilidadDos,
        fechaDeIngresoDos:
          docSnap.data().fechaDeIngresoDos === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoDos,
        ingresosNetosMensualesDos:
          docSnap.data().ingresosNetosMensualesDos === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesDos,
        pagasAnualesDos:
          docSnap.data().pagasAnualesDos === undefined
            ? undefined
            : docSnap.data().pagasAnualesDos,
        pagaDeCuotasDos:
          docSnap.data().pagaDeCuotasDos === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasDos,
        situacionLaboralDos:
          docSnap.data().situacionLaboralDos === undefined
            ? undefined
            : docSnap.data().situacionLaboralDos,
        //datos persona tres
        nombreViabilidadTres:
          docSnap.data().nombreViabilidadTres === undefined
            ? undefined
            : docSnap.data().nombreViabilidadTres,
        edadViavilidadTres:
          docSnap.data().edadViavilidadTres === undefined
            ? undefined
            : docSnap.data().edadViavilidadTres,
        fechaDeIngresoTres:
          docSnap.data().fechaDeIngresoTres === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoTres,
        ingresosNetosMensualesTres:
          docSnap.data().ingresosNetosMensualesTres === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesTres,
        pagasAnualesTres:
          docSnap.data().pagasAnualesTres === undefined
            ? undefined
            : docSnap.data().pagasAnualesTres,
        pagaDeCuotasTres:
          docSnap.data().pagaDeCuotasTres === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasTres,
        situacionLaboralTres:
          docSnap.data().situacionLaboralTres === undefined
            ? undefined
            : docSnap.data().situacionLaboralTres,
        //datos persona cuatro
        nombreViabilidadCuatro:
          docSnap.data().nombreViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().nombreViabilidadCuatro,
        edadViavilidadCuatro:
          docSnap.data().edadViavilidadCuatro === undefined
            ? undefined
            : docSnap.data().edadViavilidadCuatro,
        fechaDeIngresoCuatro:
          docSnap.data().fechaDeIngresoCuatro === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoCuatro,
        ingresosNetosMensualesCuatro:
          docSnap.data().ingresosNetosMensualesCuatro === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesCuatro,
        pagasAnualesCuatro:
          docSnap.data().pagasAnualesCuatro === undefined
            ? undefined
            : docSnap.data().pagasAnualesCuatro,
        pagaDeCuotasCuatro:
          docSnap.data().pagaDeCuotasCuatro === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasCuatro,
        situacionLaboralCuatro:
          docSnap.data().situacionLaboralCuatro === undefined
            ? undefined
            : docSnap.data().situacionLaboralCuatro,
        dniViabilidad: docSnap.data().dniViabilidad,
        emailViabilidad: docSnap.data().emailViabilidad,
        domicilioViabilidad: docSnap.data().domicilioViabilidad,
        poblacionViabilidad: docSnap.data().poblacionViabilidad,
        provinciaViabilidad: docSnap.data().provinciaViabilidad,
        apellidoViabilidadDos:
          docSnap.data().apellidoViabilidadDos === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadDos,
        dniViabilidadDos:
          docSnap.data().dniViabilidadDos === undefined
            ? undefined
            : docSnap.data().dniViabilidadDos,
        emailViabilidadDos:
          docSnap.data().emailViabilidadDos === undefined
            ? undefined
            : docSnap.data().emailViabilidadDos,
        telefonoViabilidadDos:
          docSnap.data().telefonoViabilidadDos === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadDos,
        apellidoViabilidadTres:
          docSnap.data().apellidoViabilidadTres === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadTres,
        dniViabilidadTres:
          docSnap.data().dniViabilidadTres === undefined
            ? undefined
            : docSnap.data().dniViabilidadTres,
        emailViabilidadTres:
          docSnap.data().emailViabilidadTres === undefined
            ? undefined
            : docSnap.data().emailViabilidadTres,
        telefonoViabilidadTres:
          docSnap.data().telefonoViabilidadTres === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadTres,
        apellidoViabilidadCuatro:
          docSnap.data().apellidoViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadCuatro,
        dniViabilidadCuatro:
          docSnap.data().dniViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().dniViabilidadCuatro,
        emailViabilidadCuatro:
          docSnap.data().emailViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().emailViabilidadCuatro,
        telefonoViabilidadCuatro:
          docSnap.data().telefonoViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadCuatro,
        viable: docSnap.data().viable,
      };
      if (!docSnap.data().rol) {
        pushing.push(simulaciones);
      }
      const newArr = pushing.sort((a, b) => {
        const dateA = new Date(b.fechaBD.split('-').reverse().join('-'));
        const dateB = new Date(a.fechaBD.split('-').reverse().join('-'));
        return dateA - dateB;
      });
      console.log(newArr);
      setSimulaciones(newArr);
      setSimulacionesRespaldo(newArr);
    });
  }

  function buscarPorFecha(e) {
    e.preventDefault();
    let encontrados = [];
    for (let i = 0; i < simulacionesRespaldo.length; i++) {
      console.log(buscarFecha + " asd");
      if (buscarFecha === simulacionesRespaldo[i].fechaBD) {
        console.log("existo");
        encontrados.push(simulacionesRespaldo[i]);
      }
    }
    setSimulaciones(encontrados);
  }

  function handleChange(e) {
    let fecha = ([e.target.name] = e.target.value);
    let partesFecha = fecha.split("-");

    let dia = parseInt(partesFecha[2]).toString(); // Convertir a número y luego a cadena para eliminar ceros
    let mes = parseInt(partesFecha[1]).toString();

    let nuevaFecha = dia + "-" + mes + "-" + partesFecha[0];
    setBuscarFecha(nuevaFecha);
  }

  function buscarTodas() {
    setSimulaciones(simulacionesRespaldo);
  }

  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setDate(userLog);
        async function buscarUser() {
          const docRef = doc(db, "users", userLog.uid);
          const docSnap = await getDoc(docRef);
          setSeteoDeDatosUser(docSnap.data());
          switch (docSnap.data().rol) {
            case "adminFirmax":
              navigate("/firmax");
              break;
            case "inmobiliaria":
              navigate("/inmobiliaria/simulacion");
              setActionBack(true);
              if (
                mostrarSimulaciones &&
                docSnap.data().rol === "inmobiliaria"
              ) {
                traerSimulaciones(userLog.email);
                setmostrarSimulaciones(false);
              }
              break;
            case "inmobiliariaEmpleado":
              navigate("/inmobiliaria/simulacion");
              setActionBack(false);
              if (
                mostrarSimulaciones &&
                docSnap.data().rol === "inmobiliaria"
              ) {
                traerSimulaciones(userLog.email);
                setmostrarSimulaciones(false);
              }
              break;
            default:
              break;
          }
        }
        buscarUser();
      } else {
        setDate(null);
        navigate("/login");
      }
    });
  }, [date.email, mostrarSimulaciones, navigate]);

  return (
    <section className={simulacionCss}>
      <img
        src={logo}
        alt=""
        className="container-firmax-logo"
        style={{ marginBottom: "3rem" }}
        onClick={() => console.log(simulaciones)}
      />
      {pantallas === 0 ? (
        <>
          <section className="contenesor-usuarios max-width">
            <div className="contenedor-filtro-busqueda">
              <form
                onSubmit={
                  seteoDeDatosUser?.rol === "inmobiliaria"
                    ? buscarPorFecha
                    : handleNumberChange
                }
                className="busqueda-fecha"
              >
                <p>
                  {seteoDeDatosUser?.rol === "inmobiliaria"
                    ? "Buscar por fecha"
                    : "Buscar por telefono"}
                </p>

                {seteoDeDatosUser?.rol === "inmobiliaria" ? (
                  <input
                    type="date"
                    name="fechaBusqueda"
                    onChange={handleChange}
                  />
                ) : (
                  <input
                    required
                    type="tel"
                    placeholder="Ingresa un número de teléfono"
                    name="tel"
                    id="tel"
                  />
                )}

                <button type="submit" className="btn-buscar">
                  Buscar
                </button>
              </form>
              {seteoDeDatosUser?.rol === "inmobiliaria" ? (
                <button className="btn-buscar" onClick={buscarTodas}>
                  Ver todas las simulaciones
                </button>
              ) : null}
            </div>
            <table className="main-medico">
              <thead>
                <tr>
                  <th>ID simulación</th>
                  <th style={{ width: '150px' }}>Fecha</th>
                  <th>Tipo</th>
                  <th>Cliente/s</th>
                  <th>Teléfono</th>
                  <th>Agente</th>
                  <th>Viable</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {simulaciones?.map((simulacion) => (
                  <tr key={simulacion.telefonoViabilidad}>
                    <td className="border ancho-especial">
                      <p>{simulacion.solicitudHipotecariaID}</p>
                    </td>
                    <td className="border">
                      <p>{simulacion.fechaBD}</p>
                    </td>
                    <td className="border">
                      <p>{simulacion.calculadoraSelected}</p>
                    </td>
                    <td className="border">
                      <p>
                        {simulacion.nombreViabilidad}{" "}
                        {simulacion.apellidoViabilidad}
                      </p>
                    </td>
                    <td className="border">
                      <p>{simulacion.telefonoViabilidad}</p>
                    </td>
                    <td className="border">
                      <p>{simulacion.agente}</p>
                    </td>
                    <td className="border" style={{ textAlign: "center" }}>
                      <p
                        style={{
                          color: simulacion.viable === "si" ? "green" : "red",
                          fontWeight: "500",
                        }}
                      >
                        {simulacion.viable}
                      </p>
                    </td>
                    <td className="border" style={{ textAlign: "center" }}>
                      <p style={{ cursor: "pointer" }} onClick={() => seteoDeUser(simulacion)}
                      >
                        <i class='bx bx-folder-open'></i>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {simulaciones.length <= 0 ?
              <p style={{ textAlign: "center", color: "rgba(0, 0, 0, .7)" }}>
                {seteoDeDatosUser?.rol === "inmobiliaria"
                  ? null
                  : "Debe buscar por telefono para visualizar la simulación"}
              </p>
              : null}
          </section>

          {/* <form className="search-container" onSubmit={handleNumberChange}> */}
          {/* <h1>Búsqueda por teléfono</h1>
            <input
              required
              type="tel"
              placeholder="Ingresa un número de teléfono"
              name="tel"
              id="tel"
            />
            <button className="search-container-buttom" type="submit">
              Buscar
            </button> */}

          {mostrar ? (
            <section
              style={{ marginTop: "0.5rem" }}
              className="contenedor-simulacion-detalles"
            >
              <div className="contenedor-simulacion-detalles-info">
                <div className="contenedor-simulacion-detalles-info-texto">
                  <h3>ESCRITURA</h3>
                  {usuarioSimulacion.resTres}Є
                </div>
                <div className="contenedor-simulacion-detalles-info-texto">
                  <h3>HIPOTECA</h3>
                  <p>{usuarioSimulacion.hipotecaSolicitada}Є</p>
                </div>
                <div className="contenedor-simulacion-detalles-info-texto">
                  <h3>ENTRADA</h3>
                  {usuarioSimulacion.honorariosDeEscritura === "si"
                    ? usuarioSimulacion.resTres -
                    usuarioSimulacion.hipotecaSolicitadaConHonorarios
                    : usuarioSimulacion.resTres -
                    usuarioSimulacion.hipotecaSolicitada}
                  Є
                </div>
              </div>
              {usuarioSimulacion.valorFijoMixto === "fijo" ? (
                <div className="contenedor-simulacion-detalles-datos">
                  <h2>
                    Hipoteca <span style={{ color: "#69b62d" }}>Fija</span>{" "}
                    desde
                  </h2>
                  <h2>{usuarioSimulacion.innteresFija}%</h2>
                  <div className="contenedor-simulacion-detalles-datos-row">
                    <p>Plazo</p>
                    <h2>{usuarioSimulacion.aniosTiempo}</h2>
                    <p>años</p>
                  </div>
                  <h3>Cuota estimada</h3>
                  <h3 style={{ textAlign: "center" }}>
                    {usuarioSimulacion.cuotaEstimadaFija}Є
                  </h3>
                </div>
              ) : usuarioSimulacion.valorFijoMixto === "variable" ? (
                <div className="contenedor-simulacion-detalles-datos">
                  <h2>
                    Hipoteca <span style={{ color: "#69b62d" }}>Variable</span>{" "}
                    desde
                  </h2>
                  <div style={{ display: "flex", gap: " 2rem" }}>
                    <h2>{usuarioSimulacion.innteresVariableEuribor}%</h2>
                    <h2>{usuarioSimulacion.innteresVariable}%</h2>
                  </div>

                  <div className="contenedor-simulacion-detalles-datos-row">
                    <p>Plazo</p>
                    <h2>{usuarioSimulacion.aniosTiempoVariable}</h2>
                    <p>años</p>
                  </div>
                  <div style={{ display: "flex", gap: " 2rem" }}>
                    <section>
                      <h3>Cuota estimada</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {usuarioSimulacion.cuotaEstimadaVariable}$
                      </h3>
                    </section>

                    <section>
                      <h3>Resto de plazo</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {usuarioSimulacion.cuotaEstimadaVariableEuribor}$
                      </h3>
                    </section>
                  </div>
                </div>
              ) : (
                <div className="contenedor-simulacion-detalles-datos">
                  <h2>
                    Hipoteca <span style={{ color: "#69b62d" }}>Mixto</span>{" "}
                    desde
                  </h2>
                  <div style={{ display: "flex", gap: " 2rem" }}>
                    <h2>{usuarioSimulacion.interesMixtaEuribor}%</h2>
                    <h2>{usuarioSimulacion.interesMixta}%</h2>
                  </div>

                  <div className="contenedor-simulacion-detalles-datos-row">
                    <p>Plazo</p>
                    <h2>{usuarioSimulacion.aniosTiempoMixto}</h2>
                    <p>años</p>
                  </div>
                  <div style={{ display: "flex", gap: " 2rem" }}>
                    <section>
                      <h3>Cuota estimada</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {usuarioSimulacion.cuotaEstimadaMixta}$
                      </h3>
                    </section>

                    <section>
                      <h3>Resto de plazo</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {usuarioSimulacion.cuotaEstimadaMixtaEuribor}$
                      </h3>
                    </section>
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', gap: '1rem' }}>
                <button
                  onClick={() => setMostrar(false)}
                  className="search-container-buttom"
                  style={{ background: 'tomato' }}
                >
                  Cerrar
                </button>
                <button
                  onClick={empezarCuestionario}
                  className="search-container-buttom"
                >
                  Recalcular hipoteca
                </button>
              </div>
            </section>
          ) : null}
          {/* </form> */}
        </>
      ) : (
        <LogicaFinanzas
          cuestionario={cuestionario}
          setCuestionario={setCuestionario}
          contratoBD={contratoBD}
          setContratoBD={setContratoBD}
          setearDatosCalculadora={setearDatosCalculadora}
          date={date}
          seteo={seteo}
          usuarioSimulacion={usuarioSimulacion}
          setSeteo={setSeteo}
          seteoDeDatosUser={seteoDeDatosUser}
        />
      )}
      {/* cuestionario={cuestionario} setCuestionario={setCuestionario} */}



      {/* <DataTable value={simulaciones}
            paginator
            rows={4}
            dataKey="id"
            // filters={diagnostico}
            filterDisplay="row"
            loading={loading}
            globalFilterFields={['diagnostico']}
            emptyMessage="No customers found."
            tableStyle={{ minWidth:'1000px' ,maxWidth: '1000px', minHeight:'400px',maxHeight: '400px' }}
        >
            <Column field="telefonoViabilidad" header="Simulacion" filter filterPlaceholder="Simulacion" style={{ width: '30%' }} headerStyle={headerStyle} />
            <Column field="fechaBD" header="Fecha" filter filterPlaceholder="Fecha" style={{ width: '30%' }} headerStyle={headerStyle} ></Column>
            <Column field="resUno" header="Tipo" filter filterPlaceholder="Apellido" style={{ width: '30%' }} headerStyle={headerStyle} ></Column>
            <Column field="telefono" header="Telefono" style={{ width: '30%' }} headerStyle={headerStyle} ></Column>
            <Column field="dni" header="DNI" style={{ width: '30%' }} headerStyle={headerStyle} ></Column>
        </DataTable> */}


      <div
        onClick={() => {
          actionBack
            ? navigate("/inmobiliaria")
            : navigate("/inmobiliaria/inicio");
        }}
        className="section-firmax-form-container-back padding-top"
      >
        <i className="bx bx-left-arrow-alt"></i>
        <p>Volver</p>
      </div>
    </section>
  );
};

export default RecuperarSimulacion;

