import { useEffect ,useRef, useState } from "react";
import pulgar from "../../../img/pulgar.png";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../../../Firebase/Credenciales";
import ContratoAcargoDelVendedor from "../Contratos/ContratoAcargoDelVendedor";
import ContratoSiIncluido from "../Contratos/ContratoSiIncluido";
import ContratoNoIncluidosEscritura from "../Contratos/ContratoNoIncluidosEscritura";
import { PDFViewer } from "@react-pdf/renderer";
import Swal from "sweetalert2";
import emailjs from '@emailjs/browser';

const ResViabilidad = ({
  setPorcentaje,
  porcentaje,
  setViabilidad,
  viabilidad,
  datosCuestionario,
  contratoBD,
  setearDatosCalculadora,
  mostrarEndeu,
  mostrarRatio,
  mostrarSegundoEndeu,
  numEscenario,
  setDatosCuestionario,
  euribor,
  innteresVariable,
  innteresFija,
  porcentajeUno,
  porcentajeDos,
  porcentajeTres,
  mensualTotal,
  pagaCuotaTotal,
  indiceUno,
  indiceDos,
  indiceTres,
  indiceCuatro,
  antiUno,
  antiDos,
  antiTres,
  antiCuatro,
  date,
  aniosTiempo,
  resEdadMaxPlazoFijo,
  resEdadMaxPlazoVariable,
  innteresMixto,
  seteoDeDatosUser,
  solicitudHipotecaria
}) => {
  //la prop viabildiad es para controlar los flujos d elos carteles en el cuestionario anterior y este mismo
  const [modalDeLaSimulacion, setmodalDeLaSimulacion] = useState(0);
// const [mostrarModal, setMostrarModal] = useState(true)
  const [mostrarVisualizar, setmostrarVisualizar] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [mesesInteres, setMesesInteres] = useState(
    {
      meses: 0,
      interes: 0,
      restoInteres: 0
    }
  );
  //variables de apoyo para recalcular y de ser positivo no penalizar
  const [visualizarGarantia, setvisualizarGarantia] = useState(true)
  const [visualizarAntiguedad, setvisualizarAntiguedad] = useState(true)
  const [visualizarEndeudamiento, setVisualizarEndeudamiento] = useState(true)
  const [primerEndeu, setPrimerEndeu] = useState([]);
  const [ratio, setRatio] = useState([]);
  const [dobleGarantiaBD, setDobleGarantiaBD] = useState([]);
  const [mejorasMinisucula, setMejorasMinisucula] = useState();
  const [mejorasMostrar, setMejorasMostrar] = useState(false);

  const [vistaPdf, setVistaPdf] = useState(false);

  const [aniosAmeses, setAniosAeses] = useState(0);
  const [resPorcentaje, setResPorcentaje] = useState(
    100 -
      parseInt(porcentajeUno) -
      parseInt(porcentajeDos) -
      parseInt(porcentajeTres)
  );
  const form = useRef();
  function volver() {
    setResPorcentaje(0)
    setViabilidad({
      optUno: true,
      optDos: false,
      optTres: false,
    });
    setResPorcentaje(100);
  }
  const handleOptionChange = (e) => {
    setDatosCuestionario({
      ...datosCuestionario,
      [e.target.name]: e.target.value,
    });
    console.log(datosCuestionario);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_a93vpsn', 'template_mqrq7li', form.current, 'KO_NjEd0agwiJA22j')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      emailjs.sendForm('service_gaudfe5', 'template_66p4qur', form.current, 'KO_NjEd0agwiJA22j')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  // function mostrarDatoscues() {
  //   Object.entries(datosCuestionario).map(([clave, valor]) => (
  //     console.log(clave, valor)
      
  //   ))
  // }
  
  async function guardarSimulacionEnBD() {
    await setDoc(doc(db, "simulaciones", solicitudHipotecaria), {
      optMejoraGarantia: datosCuestionario.optMejoraGarantia === undefined ? 'undefined' : datosCuestionario.optMejoraGarantia,
      optMejoraAntiguedad: datosCuestionario.optMejoraAntiguedad === undefined ? 'undefined' : datosCuestionario.optMejoraAntiguedad ,
      fechaBD: fechaBD,
      razonSocial: seteoDeDatosUser.razonSocial,
      agente: seteoDeDatosUser.rol === 'inmobiliaria' ? seteoDeDatosUser.razonSocial : `${seteoDeDatosUser.nombre} ${seteoDeDatosUser.apellido}`,
      resUno: datosCuestionario.resUno,
      peronasLength: datosCuestionario.peronasLength,
      interesFija: innteresFija,
      aniosFija: aniosTiempo,
      tiempoFijoMinimo: setearDatosCalculadora.plazoFijoMinimo,
      tiempoFijoMaximo: resEdadMaxPlazoFijo,
      cuotaInicialFija: datosCuestionario.cuotaEstimadaFija,
      //variable
      primerosMesesVar: setearDatosCalculadora.primerPeriodoVariable,
      primerosMesesVarInteres: setearDatosCalculadora.tinPrimerosMesesVariable,
      restoPlazoInteresVar: innteresVariable,
      aniosVariable: datosCuestionario.aniosTiempoVariable,
      tiempoVariableMinimo: setearDatosCalculadora.plazoFijoMinimo,
      tiempoVariableMaximo: datosCuestionario.aniosTiempoVariable,
      cuotaInicialVariable: datosCuestionario.cuotaEstimadaVariable,
      cuotaRestoVariable: datosCuestionario.cuotaEstimadaVariableEuribor,
     //mixto
     primerosMesesMix: setearDatosCalculadora.primerPeriodoMixto,
     primerosMesesMixInteres: setearDatosCalculadora.tinPrimerosMesesMixto,
     restoPlazoInteresMix: innteresMixto,
     aniosMixto: datosCuestionario.aniosTiempoMixto,
     tiempoMixiableMinimo: setearDatosCalculadora.plazoFijoMinimo,
     tiempoMixiableMaximo: datosCuestionario.aniosTiempoMixto,
     cuotaInicialMixiable: datosCuestionario.cuotaEstimadaMixta,
     cuotaRestoMixiable: datosCuestionario.cuotaEstimadaMixtaEuribor,

    resPreguntaCero: datosCuestionario.resPreguntaCero,
    resTres: datosCuestionario.resTres,
    mejorasAntiguedad: datosCuestionario.mejorasAntiguedad,
    mejorasAntiguedadDos: datosCuestionario.mejorasAntiguedadDos,
    mejorasRatio: datosCuestionario.mejorasRatio,
    mejorasGarantia: datosCuestionario.mejorasGarantia,
    nombreViabilidad: datosCuestionario.nombreViabilidad,
    apellidoViabilidad: datosCuestionario.apellidoViabilidad,
    edadViavilidad: datosCuestionario.edadViavilidad,
    telefonoViabilidad: datosCuestionario.telefonoViabilidad,
    situacionLaboral: datosCuestionario.situacionLaboral,
    fechaDeIngreso: datosCuestionario.fechaDeIngreso,
    ingresosNetosMensuales: datosCuestionario.ingresosNetosMensuales,
    pagasAnuales: datosCuestionario.pagasAnuales,
    pagaDeCuotas: datosCuestionario.pagaDeCuotas,
    financiacionPorcentaje: datosCuestionario.financiacionPorcentaje === undefined ? 0 : datosCuestionario.financiacionPorcentaje,
    hipotecaSolicitada: datosCuestionario.hipotecaSolicitada,
    honorariosDeGestion: datosCuestionario.honorariosDeGestion,
    porcentaje: datosCuestionario.porcentaje,
    porcentajeMem: datosCuestionario.porcentajeMem,
    financiacionPorcen: datosCuestionario.financiacionPorcen,
    hipoteca: datosCuestionario.hipoteca,
    plazoHipoteca: datosCuestionario.plazoHipoteca,
    cuotaEstimadaFija: datosCuestionario.cuotaEstimadaFija,
    cuotaEstimadaVariable: datosCuestionario.cuotaEstimadaVariable,
    cuotaEstimadaVariableEuribor: datosCuestionario.cuotaEstimadaVariableEuribor,
    cuotaEstimadaMixta: datosCuestionario.cuotaEstimadaMixta,
    cuotaEstimadaMixtaEuribor: datosCuestionario.cuotaEstimadaMixtaEuribor,
    escenario: datosCuestionario.escenario,
    valorFijoMixto: datosCuestionario.valorFijoMixto,
    aniosTiempo: datosCuestionario.aniosTiempo,
    aniosTiempoVariable: datosCuestionario.aniosTiempoVariable,
    aniosTiempoMixto: datosCuestionario.aniosTiempoMixto,
    innteresFija: datosCuestionario.innteresFija,
    innteresVariable: datosCuestionario.innteresVariable,
    innteresVariableEuribor: datosCuestionario.innteresVariableEuribor,
    interesMixta: datosCuestionario.interesMixta,
    interesMixtaEuribor: datosCuestionario.interesMixtaEuribor,
    hipotecaSolicitadaConHonorarios: datosCuestionario.hipotecaSolicitadaConHonorarios === undefined ? 0 : datosCuestionario.hipotecaSolicitadaConHonorarios,
    honorariosDeEscritura: datosCuestionario.honorariosDeEscritura === undefined ? 'no' : datosCuestionario.honorariosDeEscritura,
    hipotecaSolicitadaHonarios: datosCuestionario.hipotecaSolicitadaHonarios === undefined ? 0 : datosCuestionario.hipotecaSolicitadaHonarios,
    hipotecaMasComisionMasHonorarios: datosCuestionario.hipotecaMasComisionMasHonorarios === undefined ? 0 : datosCuestionario.hipotecaMasComisionMasHonorarios,
    segundaGarantia: datosCuestionario.segundaGarantia === undefined ? 'si' : datosCuestionario.segundaGarantia,
    valorAproxSegundaGarantia: datosCuestionario.valorAproxSegundaGarantia === undefined ? 0 : datosCuestionario.valorAproxSegundaGarantia,
    totalAcobrar: datosCuestionario.totalAcobrar === undefined ? 0 : datosCuestionario.totalAcobrar,
    calculadora: setearDatosCalculadora.id,
    dniViabilidad: datosCuestionario.dniViabilidad === undefined ? 'undefined' : datosCuestionario.dniViabilidad,
    emailViabilidad: datosCuestionario.emailViabilidad === undefined ? 'undefined' : datosCuestionario.emailViabilidad,
    domicilioViabilidad: datosCuestionario.domicilioViabilidad === undefined ? 'undefined' : datosCuestionario.domicilioViabilidad,
    poblacionViabilidad: datosCuestionario.poblacionViabilidad === undefined ? 'undefined' : datosCuestionario.poblacionViabilidad,
    provinciaViabilidad: datosCuestionario.provinciaViabilidad === undefined ? 'undefined' : datosCuestionario.provinciaViabilidad,
    //datos persona dos
    nombreViabilidadDos: datosCuestionario.nombreViabilidadDos === undefined ? 'undefined' : datosCuestionario.nombreViabilidadDos,
    edadViavilidadDos: datosCuestionario.edadViavilidadDos === undefined ? 'undefined' : datosCuestionario.edadViavilidadDos,
    fechaDeIngresoDos: datosCuestionario.fechaDeIngresoDos === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoDos,
    ingresosNetosMensualesDos: datosCuestionario.ingresosNetosMensualesDos === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesDos,
    pagasAnualesDos: datosCuestionario.pagasAnualesDos === undefined ? 'undefined' : datosCuestionario.pagasAnualesDos,
    pagaDeCuotasDos: datosCuestionario.pagaDeCuotasDos === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasDos,
    situacionLaboralDos: datosCuestionario.situacionLaboralDos === undefined ? 'undefined' : datosCuestionario.situacionLaboralDos,
    apellidoViabilidadDos: datosCuestionario.apellidoViabilidadDos === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadDos,
    dniViabilidadDos: datosCuestionario.dniViabilidadDos === undefined ? 'undefined' : datosCuestionario.dniViabilidadDos,
    emailViabilidadDos: datosCuestionario.emailViabilidadDos === undefined ? 'undefined' : datosCuestionario.emailViabilidadDos,
    telefonoViabilidadDos: datosCuestionario.telefonoViabilidadDos === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadDos,
    //datos persona tres
    nombreViabilidadTres: datosCuestionario.nombreViabilidadTres === undefined ? 'undefined' : datosCuestionario.nombreViabilidadTres,
    edadViavilidadTres: datosCuestionario.edadViavilidadTres === undefined ? 'undefined' : datosCuestionario.edadViavilidadTres,
    fechaDeIngresoTres: datosCuestionario.fechaDeIngresoTres === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoTres,
    ingresosNetosMensualesTres: datosCuestionario.ingresosNetosMensualesTres === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesTres,
    pagasAnualesTres: datosCuestionario.pagasAnualesTres === undefined ? 'undefined' : datosCuestionario.pagasAnualesTres,
    pagaDeCuotasTres: datosCuestionario.pagaDeCuotasTres === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasTres,
    situacionLaboralTres: datosCuestionario.situacionLaboralTres === undefined ? 'undefined' : datosCuestionario.situacionLaboralTres,
    apellidoViabilidadTres: datosCuestionario.apellidoViabilidadTres === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadTres,
    dniViabilidadTres: datosCuestionario.dniViabilidadTres === undefined ? 'undefined' : datosCuestionario.dniViabilidadTres,
    emailViabilidadTres: datosCuestionario.emailViabilidadTres === undefined ? 'undefined' : datosCuestionario.emailViabilidadTres,
    telefonoViabilidadTres: datosCuestionario.telefonoViabilidadTres === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadTres,
    //datos persona cuatro
    nombreViabilidadCuatro: datosCuestionario.nombreViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.nombreViabilidadCuatro,
    edadViavilidadCuatro: datosCuestionario.edadViavilidadCuatro === undefined ? 'undefined' : datosCuestionario.edadViavilidadCuatro,
    fechaDeIngresoCuatro: datosCuestionario.fechaDeIngresoCuatro === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoCuatro,
    ingresosNetosMensualesCuatro: datosCuestionario.ingresosNetosMensualesCuatro === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesCuatro,
    pagasAnualesCuatro: datosCuestionario.pagasAnualesCuatro === undefined ? 'undefined' : datosCuestionario.pagasAnualesCuatro,
    pagaDeCuotasCuatro: datosCuestionario.pagaDeCuotasCuatro === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasCuatro,
    situacionLaboralCuatro: datosCuestionario.situacionLaboralCuatro === undefined ? 'undefined' : datosCuestionario.situacionLaboralCuatro,
    apellidoViabilidadCuatro: datosCuestionario.apellidoViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadCuatro,
    dniViabilidadCuatro: datosCuestionario.dniViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.dniViabilidadCuatro,
    emailViabilidadCuatro: datosCuestionario.emailViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.emailViabilidadCuatro,
    telefonoViabilidadCuatro: datosCuestionario.telefonoViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadCuatro,
    viable: resPorcentaje >= 75 ? 'si' : 'no',
  });

  await setDoc(doc(db, seteoDeDatosUser.mail, solicitudHipotecaria), {
    optMejoraGarantia: datosCuestionario.optMejoraGarantia === undefined ? 'undefined' : datosCuestionario.optMejoraGarantia,
    optMejoraAntiguedad: datosCuestionario.optMejoraAntiguedad === undefined ? 'undefined' : datosCuestionario.optMejoraAntiguedad ,
    fechaBD: fechaBD,
    razonSocial: seteoDeDatosUser.razonSocial,
    agente: seteoDeDatosUser.rol === 'inmobiliaria' ? seteoDeDatosUser.razonSocial : `${seteoDeDatosUser.nombre} ${seteoDeDatosUser.apellido}`,
    resUno: datosCuestionario.resUno,
    peronasLength: datosCuestionario.peronasLength,
    interesFija: innteresFija,
    aniosFija: aniosTiempo,
    tiempoFijoMinimo: setearDatosCalculadora.plazoFijoMinimo,
    tiempoFijoMaximo: resEdadMaxPlazoFijo,
    cuotaInicialFija: datosCuestionario.cuotaEstimadaFija,
    //variable
    primerosMesesVar: setearDatosCalculadora.primerPeriodoVariable,
    primerosMesesVarInteres: setearDatosCalculadora.tinPrimerosMesesVariable,
    restoPlazoInteresVar: innteresVariable,
    aniosVariable: datosCuestionario.aniosTiempoVariable,
    tiempoVariableMinimo: setearDatosCalculadora.plazoFijoMinimo,
    tiempoVariableMaximo: datosCuestionario.aniosTiempoVariable,
    cuotaInicialVariable: datosCuestionario.cuotaEstimadaVariable,
    cuotaRestoVariable: datosCuestionario.cuotaEstimadaVariableEuribor,
   //mixto
   primerosMesesMix: setearDatosCalculadora.primerPeriodoMixto,
   primerosMesesMixInteres: setearDatosCalculadora.tinPrimerosMesesMixto,
   restoPlazoInteresMix: innteresMixto,
   aniosMixto: datosCuestionario.aniosTiempoMixto,
   tiempoMixiableMinimo: setearDatosCalculadora.plazoFijoMinimo,
   tiempoMixiableMaximo: datosCuestionario.aniosTiempoMixto,
   cuotaInicialMixiable: datosCuestionario.cuotaEstimadaMixta,
   cuotaRestoMixiable: datosCuestionario.cuotaEstimadaMixtaEuribor,

  resPreguntaCero: datosCuestionario.resPreguntaCero,
  resTres: datosCuestionario.resTres,
  mejorasAntiguedad: datosCuestionario.mejorasAntiguedad,
  mejorasAntiguedadDos: datosCuestionario.mejorasAntiguedadDos,
  mejorasRatio: datosCuestionario.mejorasRatio,
  mejorasGarantia: datosCuestionario.mejorasGarantia,
  nombreViabilidad: datosCuestionario.nombreViabilidad,
  apellidoViabilidad: datosCuestionario.apellidoViabilidad,
  edadViavilidad: datosCuestionario.edadViavilidad,
  telefonoViabilidad: datosCuestionario.telefonoViabilidad,
  situacionLaboral: datosCuestionario.situacionLaboral,
  fechaDeIngreso: datosCuestionario.fechaDeIngreso,
  ingresosNetosMensuales: datosCuestionario.ingresosNetosMensuales,
  pagasAnuales: datosCuestionario.pagasAnuales,
  pagaDeCuotas: datosCuestionario.pagaDeCuotas,
  financiacionPorcentaje: datosCuestionario.financiacionPorcentaje === undefined ? 0 : datosCuestionario.financiacionPorcentaje,
  hipotecaSolicitada: datosCuestionario.hipotecaSolicitada,
  honorariosDeGestion: datosCuestionario.honorariosDeGestion,
  porcentaje: datosCuestionario.porcentaje,
  porcentajeMem: datosCuestionario.porcentajeMem,
  financiacionPorcen: datosCuestionario.financiacionPorcen,
  hipoteca: datosCuestionario.hipoteca,
  plazoHipoteca: datosCuestionario.plazoHipoteca,
  cuotaEstimadaFija: datosCuestionario.cuotaEstimadaFija,
  cuotaEstimadaVariable: datosCuestionario.cuotaEstimadaVariable,
  cuotaEstimadaVariableEuribor: datosCuestionario.cuotaEstimadaVariableEuribor,
  cuotaEstimadaMixta: datosCuestionario.cuotaEstimadaMixta,
  cuotaEstimadaMixtaEuribor: datosCuestionario.cuotaEstimadaMixtaEuribor,
  escenario: datosCuestionario.escenario,
  valorFijoMixto: datosCuestionario.valorFijoMixto,
  aniosTiempo: datosCuestionario.aniosTiempo,
  aniosTiempoVariable: datosCuestionario.aniosTiempoVariable,
  aniosTiempoMixto: datosCuestionario.aniosTiempoMixto,
  innteresFija: datosCuestionario.innteresFija,
  innteresVariable: datosCuestionario.innteresVariable,
  innteresVariableEuribor: datosCuestionario.innteresVariableEuribor,
  interesMixta: datosCuestionario.interesMixta,
  interesMixtaEuribor: datosCuestionario.interesMixtaEuribor,
  hipotecaSolicitadaConHonorarios: datosCuestionario.hipotecaSolicitadaConHonorarios === undefined ? 0 : datosCuestionario.hipotecaSolicitadaConHonorarios,
  honorariosDeEscritura: datosCuestionario.honorariosDeEscritura === undefined ? 'no' : datosCuestionario.honorariosDeEscritura,
  hipotecaSolicitadaHonarios: datosCuestionario.hipotecaSolicitadaHonarios === undefined ? 0 : datosCuestionario.hipotecaSolicitadaHonarios,
  hipotecaMasComisionMasHonorarios: datosCuestionario.hipotecaMasComisionMasHonorarios === undefined ? 0 : datosCuestionario.hipotecaMasComisionMasHonorarios,
  segundaGarantia: datosCuestionario.segundaGarantia === undefined ? 'si' : datosCuestionario.segundaGarantia,
  valorAproxSegundaGarantia: datosCuestionario.valorAproxSegundaGarantia === undefined ? 0 : datosCuestionario.valorAproxSegundaGarantia,
  totalAcobrar: datosCuestionario.totalAcobrar === undefined ? 0 : datosCuestionario.totalAcobrar,
  calculadora: setearDatosCalculadora.id,
  dniViabilidad: datosCuestionario.dniViabilidad === undefined ? 'undefined' : datosCuestionario.dniViabilidad,
  emailViabilidad: datosCuestionario.emailViabilidad === undefined ? 'undefined' : datosCuestionario.emailViabilidad,
  domicilioViabilidad: datosCuestionario.domicilioViabilidad === undefined ? 'undefined' : datosCuestionario.domicilioViabilidad,
  poblacionViabilidad: datosCuestionario.poblacionViabilidad === undefined ? 'undefined' : datosCuestionario.poblacionViabilidad,
  provinciaViabilidad: datosCuestionario.provinciaViabilidad === undefined ? 'undefined' : datosCuestionario.provinciaViabilidad,
  //datos persona dos
  nombreViabilidadDos: datosCuestionario.nombreViabilidadDos === undefined ? 'undefined' : datosCuestionario.nombreViabilidadDos,
  edadViavilidadDos: datosCuestionario.edadViavilidadDos === undefined ? 'undefined' : datosCuestionario.edadViavilidadDos,
  fechaDeIngresoDos: datosCuestionario.fechaDeIngresoDos === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoDos,
  ingresosNetosMensualesDos: datosCuestionario.ingresosNetosMensualesDos === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesDos,
  pagasAnualesDos: datosCuestionario.pagasAnualesDos === undefined ? 'undefined' : datosCuestionario.pagasAnualesDos,
  pagaDeCuotasDos: datosCuestionario.pagaDeCuotasDos === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasDos,
  situacionLaboralDos: datosCuestionario.situacionLaboralDos === undefined ? 'undefined' : datosCuestionario.situacionLaboralDos,
  apellidoViabilidadDos: datosCuestionario.apellidoViabilidadDos === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadDos,
  dniViabilidadDos: datosCuestionario.dniViabilidadDos === undefined ? 'undefined' : datosCuestionario.dniViabilidadDos,
  emailViabilidadDos: datosCuestionario.emailViabilidadDos === undefined ? 'undefined' : datosCuestionario.emailViabilidadDos,
  telefonoViabilidadDos: datosCuestionario.telefonoViabilidadDos === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadDos,
  //datos persona tres
  nombreViabilidadTres: datosCuestionario.nombreViabilidadTres === undefined ? 'undefined' : datosCuestionario.nombreViabilidadTres,
  edadViavilidadTres: datosCuestionario.edadViavilidadTres === undefined ? 'undefined' : datosCuestionario.edadViavilidadTres,
  fechaDeIngresoTres: datosCuestionario.fechaDeIngresoTres === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoTres,
  ingresosNetosMensualesTres: datosCuestionario.ingresosNetosMensualesTres === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesTres,
  pagasAnualesTres: datosCuestionario.pagasAnualesTres === undefined ? 'undefined' : datosCuestionario.pagasAnualesTres,
  pagaDeCuotasTres: datosCuestionario.pagaDeCuotasTres === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasTres,
  situacionLaboralTres: datosCuestionario.situacionLaboralTres === undefined ? 'undefined' : datosCuestionario.situacionLaboralTres,
  apellidoViabilidadTres: datosCuestionario.apellidoViabilidadTres === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadTres,
  dniViabilidadTres: datosCuestionario.dniViabilidadTres === undefined ? 'undefined' : datosCuestionario.dniViabilidadTres,
  emailViabilidadTres: datosCuestionario.emailViabilidadTres === undefined ? 'undefined' : datosCuestionario.emailViabilidadTres,
  telefonoViabilidadTres: datosCuestionario.telefonoViabilidadTres === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadTres,
  //datos persona cuatro
  nombreViabilidadCuatro: datosCuestionario.nombreViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.nombreViabilidadCuatro,
  edadViavilidadCuatro: datosCuestionario.edadViavilidadCuatro === undefined ? 'undefined' : datosCuestionario.edadViavilidadCuatro,
  fechaDeIngresoCuatro: datosCuestionario.fechaDeIngresoCuatro === undefined ? 'undefined' : datosCuestionario.fechaDeIngresoCuatro,
  ingresosNetosMensualesCuatro: datosCuestionario.ingresosNetosMensualesCuatro === undefined ? 'undefined' : datosCuestionario.ingresosNetosMensualesCuatro,
  pagasAnualesCuatro: datosCuestionario.pagasAnualesCuatro === undefined ? 'undefined' : datosCuestionario.pagasAnualesCuatro,
  pagaDeCuotasCuatro: datosCuestionario.pagaDeCuotasCuatro === undefined ? 'undefined' : datosCuestionario.pagaDeCuotasCuatro,
  situacionLaboralCuatro: datosCuestionario.situacionLaboralCuatro === undefined ? 'undefined' : datosCuestionario.situacionLaboralCuatro,
  apellidoViabilidadCuatro: datosCuestionario.apellidoViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.apellidoViabilidadCuatro,
  dniViabilidadCuatro: datosCuestionario.dniViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.dniViabilidadCuatro,
  emailViabilidadCuatro: datosCuestionario.emailViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.emailViabilidadCuatro,
  telefonoViabilidadCuatro: datosCuestionario.telefonoViabilidadCuatro === undefined ? 'undefined' : datosCuestionario.telefonoViabilidadCuatro,
  viable: resPorcentaje >= 75 ? 'si' : 'no',
  solicitudHipotecariaID: solicitudHipotecaria,
  calculadoraSelected: setearDatosCalculadora.nombreInmobiliaria,
});
  }



  useEffect(() => {
    if (datosCuestionario.honorariosDeEscritura) {
      
    }
    if (resPorcentaje >= 100) {
      setmodalDeLaSimulacion(1);
    
    } else if (resPorcentaje >= 75 && resPorcentaje < 100) {
      setmodalDeLaSimulacion(2);
     
    } else {
      let indiceContrato = -1;
      for (let index = 0; index < contratoBD.length; index++) {
        if (datosCuestionario.situacionLaboral === contratoBD[index].nombre) {
          indiceContrato = parseInt(contratoBD[index].id);
          break;
        }
      }
      setMejorasMinisucula(
        setearDatosCalculadora[
          `delEscnario${numEscenario}Mejoras${indiceContrato}`
        ].toLowerCase()
      );
      setmodalDeLaSimulacion(3);
      
    }
  }, [contratoBD, datosCuestionario.situacionLaboral, numEscenario, resPorcentaje, setearDatosCalculadora, datosCuestionario.honorariosDeEscritura]);

  const [mostrarCuadros, setMostrarCuadros] = useState(true);

  function generarContratos(e) {
    guardarSimulacionEnBD();
    sendEmail(e)
    setMostrarCuadros(false);
    setVistaPdf(true);
    mostrarContratos();
  }

  function mostrarContratos() {
    switch (datosCuestionario.valorFijoMixto) {
      case "fijo":
        setAniosAeses(parseInt(datosCuestionario.aniosTiempo) * 12);
        setMesesInteres(
          {
            meses: datosCuestionario.aniosTiempo,
            interes: datosCuestionario.innteresFija,
            restoInteres: datosCuestionario.innteresFija
          }
        )
        break;
      case "variable":
        setAniosAeses(parseInt(datosCuestionario.aniosTiempoVariable) * 12);
        setMesesInteres(
          {
            meses: datosCuestionario.primerosMesesVar,
            interes: datosCuestionario.innteresVariableEuribor,
            restoInteres: datosCuestionario.innteresVariable
          }
        )
        break;
      case "mixto":
        console.log("asd");
        setAniosAeses(parseInt(datosCuestionario.aniosTiempoMixto) * 12);
        setMesesInteres(
          {
            meses: datosCuestionario.primerosMesesMix,
            interes: datosCuestionario.interesMixtaEuribor,
            restoInteres: datosCuestionario.interesMixta

          }
        )
        break;
      default:
        break;
    }
    console.log(solicitudHipotecaria + " solicitud");
    setConfirmar(true);
  }

  function recalculoDeHipoteca(numeritoPorcentaje) {
    console.log(numeritoPorcentaje + " numeritoPorcentaje");
    let hipoteca;
    let res;
    //FIJA
    let cuotaFija;
    let resFija;
    //VARIABLE
    let cuotaVariable;
    let resVariable;
    let cuotaVariableRestoPlazo;
    let resVariableRestoPlazo;
    //MIXTA
    let cuotaMixta;
    let resMixta;
    let cuotaMixtaRestoPlazo;
    let resMixtaRestoPlazo;
 
    if (datosCuestionario.resPreguntaCero === "si") {
      console.log("aqui entro paso UNO");

      hipoteca = Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500;
      datosCuestionario.hipotecaSolicitada =
        Math.floor(
          (numeritoPorcentaje * hipoteca) / parseInt(datosCuestionario.porcentaje) / 500
        ) * 500;

      //recalculamos la cuota fija
      cuotaFija = parseInt(datosCuestionario.cuotaEstimadaFija);
      resFija = (numeritoPorcentaje * cuotaFija) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaFija = Math.floor(resFija);

      //recalculamos la cuota variable
      cuotaVariable = parseInt(datosCuestionario.cuotaEstimadaVariable);
      resVariable =
        (numeritoPorcentaje * cuotaVariable) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaVariable = Math.floor(resVariable);

      cuotaVariableRestoPlazo = parseInt(
        datosCuestionario.cuotaEstimadaVariableEuribor
      );
      resVariableRestoPlazo =
        (numeritoPorcentaje * cuotaVariableRestoPlazo) /
        parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaVariableEuribor = Math.floor(
        resVariableRestoPlazo
      );

      //recalculamos la cuota mixta
      cuotaMixta = parseInt(datosCuestionario.cuotaEstimadaMixta);
      resMixta = (numeritoPorcentaje * cuotaMixta) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaMixta = Math.floor(resMixta);

      cuotaMixtaRestoPlazo = parseInt(
        datosCuestionario.cuotaEstimadaMixtaEuribor
      );
      resMixtaRestoPlazo =
        (numeritoPorcentaje * cuotaMixtaRestoPlazo) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaMixtaEuribor =
        Math.floor(resMixtaRestoPlazo);
      
      let resFijaRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resFija)
      let resVarRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resVariable)
      let resMixRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resMixta)

      let filteredArr = [parseInt(resFijaRatio), parseInt(resVarRatio), parseInt(resMixRatio)]
      let minNum = Math.min(...filteredArr);
      console.log(minNum + " MIN");

      
        
    } else if (datosCuestionario.honorariosDeEscritura === "no") {
      console.log(" ENTRO EN Q NO LOS TENGO: " +  Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500);
      hipoteca = Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500;
      res = (numeritoPorcentaje * hipoteca) / parseInt(datosCuestionario.porcentaje);
      console.log(numeritoPorcentaje * hipoteca + " res multi");
      console.log((numeritoPorcentaje * hipoteca)/parseInt(datosCuestionario.porcentaje) + " res multi");
      console.log(res + " res");
      console.log(numeritoPorcentaje + " numeritoPorcentaje");

      datosCuestionario.hipotecaSolicitada = Math.floor(res / 500) * 500;

      //recalculamos la cuota fija
      cuotaFija = parseInt(datosCuestionario.cuotaEstimadaFija);
      resFija = (numeritoPorcentaje * cuotaFija) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaFija = Math.floor(resFija);

      //recalculamos la cuota variable
      cuotaVariable = parseInt(datosCuestionario.cuotaEstimadaVariable);
      resVariable =
        (numeritoPorcentaje * cuotaVariable) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaVariable = Math.floor(resVariable);

      cuotaVariableRestoPlazo = parseInt(
        datosCuestionario.cuotaEstimadaVariableEuribor
      );
      resVariableRestoPlazo =
        (numeritoPorcentaje * cuotaVariableRestoPlazo) /
        parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaVariableEuribor = Math.floor(
        resVariableRestoPlazo
      );

      //recalculamos la cuota mixta
      cuotaMixta = parseInt(datosCuestionario.cuotaEstimadaMixta);
      resMixta = (numeritoPorcentaje * cuotaMixta) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaMixta = Math.floor(resMixta);

      cuotaMixtaRestoPlazo = parseInt(
        datosCuestionario.cuotaEstimadaMixtaEuribor
      );
      resMixtaRestoPlazo =
        (numeritoPorcentaje * cuotaMixtaRestoPlazo) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaMixtaEuribor =
        Math.floor(resMixtaRestoPlazo);

        let resFijaRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resFija)
        let resVarRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resVariable)
        let resMixRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resMixta)
        let filteredArr = [parseInt(resFijaRatio), parseInt(resVarRatio), parseInt(resMixRatio)]
        let minNum = Math.min(...filteredArr);
        console.log(minNum + " MIN");
    } else {
      hipoteca =
        Math.floor(datosCuestionario.hipotecaSolicitadaConHonorarios / 500) *
        500;
      res = (numeritoPorcentaje * hipoteca) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.hipotecaSolicitadaConHonorarios =
        Math.floor(res / 500) * 500;

      //recalculamos la cuota fija
      cuotaFija = parseInt(datosCuestionario.cuotaEstimadaFija);

      resFija = (numeritoPorcentaje * cuotaFija) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaFija = resFija;
      //recalculamos la cuota variable
      cuotaVariable = parseInt(datosCuestionario.cuotaEstimadaVariable);
      resVariable =
        (numeritoPorcentaje * cuotaVariable) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaVariable = resVariable;

      cuotaVariableRestoPlazo = parseInt(
        datosCuestionario.cuotaEstimadaVariableEuribor
      );
      resVariableRestoPlazo =
        (numeritoPorcentaje * cuotaVariableRestoPlazo) /
        parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaVariableEuribor = resVariableRestoPlazo;
      //recalculamos la cuota mixta
      cuotaMixta = parseInt(datosCuestionario.cuotaEstimadaMixta);
      resMixta = (numeritoPorcentaje * cuotaMixta) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaMixta = resMixta;

      cuotaMixtaRestoPlazo = parseInt(
        datosCuestionario.cuotaEstimadaMixtaEuribor
      );
      resMixtaRestoPlazo =
        (numeritoPorcentaje * cuotaMixtaRestoPlazo) / parseInt(datosCuestionario.porcentaje);
      datosCuestionario.cuotaEstimadaMixtaEuribor = resMixtaRestoPlazo;

      let resFijaRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resFija)
      let resVarRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resVariable)
      let resMixRatio = numEndedamientoTotal(mensualTotal, pagaCuotaTotal, resMixta)

      let filteredArr = [parseInt(resFijaRatio), parseInt(resVarRatio), parseInt(resMixRatio)]
      let minNum = Math.min(...filteredArr);
      console.log(minNum + " MIN");

      datosCuestionario.porcentaje = numeritoPorcentaje
      datosCuestionario.porcentajeMem = numeritoPorcentaje
      datosCuestionario.financiacionPorcen = numeritoPorcentaje
      datosCuestionario.financiacionPorcentaje = numeritoPorcentaje
    }

    // console.log(datosCuestionario.hipotecaSolicitada);

    //console.log(datosCuestionario.hipotecaSolicitadaConHonorarios);

    //console.log(datosCuestionario.hipotecaSolicitada + "hipoteca recalculada");
    //console.log(
      //datosCuestionario.cuotaEstimadaFija + "hipoteca cuota fija recalcu"
    //);

    //console.log(datosCuestionario.cuotaEstimadaVariable);
    // console.log(datosCuestionario.cuotaEstimadaVariableEuribor);
  }

  function setearEscenario(ref) {
    let numEscenario;
    if (
      ref >= setearDatosCalculadora.delEscnario0 &&
      ref <= setearDatosCalculadora.alEscnario0
    ){
      numEscenario = 0
    }else if (
      ref >= setearDatosCalculadora.delEscnario1 &&
      ref <= setearDatosCalculadora.alEscnario1
    ){
      numEscenario = 1
    }else if (
      ref >= setearDatosCalculadora.delEscnario2 &&
      ref <= setearDatosCalculadora.alEscnario2
    ){
      numEscenario = 2
    }else if (
      ref >= setearDatosCalculadora.delEscnario3 &&
      ref <= setearDatosCalculadora.alEscnario3
    ){
      numEscenario = 3
    }else if (
      ref >= setearDatosCalculadora.delEscnario4 &&
      ref <= setearDatosCalculadora.alEscnario4
    ){
      numEscenario = 4
    }else if (
      ref >= setearDatosCalculadora.delEscnario5 &&
      ref <= setearDatosCalculadora.alEscnario5
    ){
      numEscenario = 5
    }

    return numEscenario
  }

  function numEndedamientoTotal(ingresosNetosMensuales, pagaDeCuotas, estimacionCuota) {
    console.log(ingresosNetosMensuales +  "  ingresosMensuales");
    console.log(pagaDeCuotas);
    let gastosMensuales = "";

    let ingrsosMensuales = parseInt(ingresosNetosMensuales);

    if (parseInt(pagaDeCuotas) > 0) {
      gastosMensuales = parseInt(pagaDeCuotas);
    } else {
      gastosMensuales = 0;
    }

  
    let resEndeudamiento =
      ((parseInt(gastosMensuales) + estimacionCuota) / ingrsosMensuales) *
      100 *
      12;

    return resEndeudamiento;
  }
  function mensaje(porcentaje) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: `Estas solicitando una hipoteca menor al ${porcentaje}%`,
      footer: "No se ha podido recalcular",
    });
  }

  function recalculoAntiguedad(con1, con2, con3, con4, con5, con6, con7, con8, numEscenario, numeritoPorcentaje, mostrar) {
    console.log("num por: " + numeritoPorcentaje);
    console.log(con5, con6, con7, con8);
    console.log(antiUno, antiDos);
    //console.log(antiDos + " meses TRABAJADOS 2");
    console.log("PASO 2: ENTRO EN LA PARTE DE BUSCAR PENALIZACION EN EL ESCENARIO DEL 90%");
    let arrNums = [con5, con6, con7, con8]
    // Filtra el arreglo para eliminar el número cero
    let filteredArr =  arrNums.filter(num => !isNaN(num) && num !== 0);
    // Encuentra el número más bajo en el arreglo filtrado
    let minNum = Math.min(...filteredArr);
    console.log(minNum + " SOY EL MAS BAJO");
    let calUno = false
    let calDos = false
    let calTres = false
    let calCuatro = false
  
    if (con1 < antiUno && con1 !== 0) {
      calUno = true
    }
    if (!isNaN(con2)) {
      if (con2 < antiDos && con2 !== 0) {
        calDos = true
      }
    }
    if (!isNaN(con3)) {
      if (con3 < antiTres && con3 !== 0) {
        calTres = true
      }
    }
    if (!isNaN(con4)) {
      if (con4 < antiCuatro && con4 !== 0) {
        calCuatro = true
      }
    }

    console.log(calUno, calDos, calTres, calCuatro);
 
    if (calUno === true || calDos === true || calTres === true || calCuatro === true) {
      console.log("PASO 3: ENCONTRE AL MENOS 1 TITUAR QUE NO PENALIZA EN LA ANTIGUEDAD EN EL ESCENARIO DEL 90%");
      recalculoDeHipoteca(numeritoPorcentaje);
      datosCuestionario.optMejoraAntiguedad = 'save'
      datosCuestionario.porcentaje = numeritoPorcentaje
      datosCuestionario.porcentajeMem = numeritoPorcentaje
      datosCuestionario.financiacionPorcen = numeritoPorcentaje
      setvisualizarAntiguedad(false)
      setResPorcentaje(resPorcentaje + porcentajeUno)
    }else{
      //setmostrarAntiguedad(false)
      console.log("PASO 3: SIGO PENALIZANDO EN LA ANTIGUEDAD EN EL ESCENARIO DEL 90% ");
      Swal.fire({
        icon: "error",
        title: "Lo sentimos...",
        text: `Aun realizando el recalculo, sigue penalizando`,
        footer: "Intente el recalculo con un porcentaje menor",
      });
    }

  }



  function mostrarCosas(mostrar) {

    switch (datosCuestionario.optMejoraAntiguedad) { 
      case "ma1":
        setResPorcentaje(resPorcentaje + porcentajeUno)
        setvisualizarAntiguedad(false)
        break;
      case "ma2":
        if (parseInt(datosCuestionario.porcentaje) > 90) {
          let numEscenario = setearEscenario(90)
          let con1 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceUno}`
          ];
          let con2 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceDos}`
          ];
          let con3 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceTres}`
          ];
          let con4 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceCuatro}`
          ];
      
          let con5 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceUno}`
          ];
          let con6 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceDos}`
          ];
          let con7 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceTres}`
          ];
          let con8 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceCuatro}`
          ];

          console.log("PASO 1: ENTRO EN EL SETEO DEL 90%");
          recalculoAntiguedad(parseInt(con1), parseInt(con2), parseInt(con3), parseInt(con4), parseInt(con5), parseInt(con6), parseInt(con7), parseInt(con8), numEscenario, 90, mostrar)
    
          // let numAntiguedad = 
          // mejoraDeAntiguedad = parseInt(porcentajeUno)
          // console.log(mejoraDeAntiguedad + " deberao darme 25 desde swtich");
          // setResPorcentaje(parseInt(resPorcentaje) + parseInt(porcentajeUno));
        } else {
          mensaje(90);
        }
        break;

      case "ma3":
        console.log("BAJAR AL 80?");
        if (parseInt(datosCuestionario.porcentaje) > 80) {
          let numEscenario = setearEscenario(80)
          let con1 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceUno}`
          ];
          let con2 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceDos}`
          ];
          let con3 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceTres}`
          ];
          let con4 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceCuatro}`
          ];
      
          let con5 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceUno}`
          ];
          let con6 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceDos}`
          ];
          let con7 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceTres}`
          ];
          let con8 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceCuatro}`
          ];
          recalculoAntiguedad(parseInt(con1), parseInt(con2), parseInt(con3), parseInt(con4), parseInt(con5), parseInt(con6), parseInt(con7), parseInt(con8), numEscenario, 80, mostrar);
          //setResPorcentaje(parseInt(resPorcentaje) + parseInt(porcentajeUno));
          datosCuestionario.optMejoraAntiguedad = 'none'
         
        } else {
          mensaje(80);
        }
        break;

      case "ma4":
        console.log("soy igual");
        if (parseInt(datosCuestionario.porcentaje) > 70) {
          let numEscenario = setearEscenario(70)
          let con1 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceUno}`
          ];
          let con2 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceDos}`
          ];
          let con3 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceTres}`
          ];
          let con4 =setearDatosCalculadora[
            `delEscnario${numEscenario}meses${indiceCuatro}`
          ];
      
          let con5 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceUno}`
          ];
          let con6 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceDos}`
          ];
          let con7 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceTres}`
          ];
          let con8 =setearDatosCalculadora[
            `delEscnario${numEscenario}pena${indiceCuatro}`
          ];
          recalculoAntiguedad(parseInt(con1), parseInt(con2), parseInt(con3), parseInt(con4), parseInt(con5), parseInt(con6), parseInt(con7), parseInt(con8), numEscenario, 70, mostrar);
          //setResPorcentaje(parseInt(resPorcentaje) + parseInt(porcentajeUno));
          datosCuestionario.optMejoraAntiguedad = 'none'
        } else {
          mensaje(70);
        }
        break;
      default:
        break;
    }

  
      // recalcularDeEndeudamiento()

      // recalcularDeGarantia()
    
  }
  function recalcularDeGarantia(){
    if (datosCuestionario.optMejoraGarantia === 'mg1') {
      datosCuestionario.optMejoraGarantia = 'none'
      setResPorcentaje(resPorcentaje + porcentajeTres)
      datosCuestionario.segundaGarantia = 'si'
      setvisualizarGarantia(false)
    }
  }

  function recalcularDeEndeudamiento(){
    console.log(datosCuestionario.optMejoraEndeudamiento);
    if (datosCuestionario.optMejoraEndeudamiento === 'mr1') {
      datosCuestionario.optMejoraEndeudamiento = 'none'
      setResPorcentaje(resPorcentaje + porcentajeDos)
      setVisualizarEndeudamiento(false)
    }
  }

  function mostrarMejoras() {
    guardarSimulacionEnBD();
    //buscamos las mejoras dependiendo el escenario y el contrato

    //vamos a setear las diferentes mejoras dependiendo si estan en el configurador
    let endeuPrimero = [];
   
    let ratio = [];
    let garantia = [];
    console.log(mejorasMinisucula);
    //PRIMER FOR PARA LA PRIMERA ANTIGUEDAD
    for (let i = 0; i < datosCuestionario.mejorasAntiguedad.length; i++) {
      if (
        mejorasMinisucula.includes(
          datosCuestionario.mejorasAntiguedad[i].abreviatura
        )
      ) {
        endeuPrimero.push(datosCuestionario.mejorasAntiguedad[i]);
      }
    }
    setPrimerEndeu(endeuPrimero);
    // //SEGUNDO FOR PARA LA PRIMERA ANTIGUEDAD DOS
 
    // //TERCER FOR PARA EL RATIO
    for (let k = 0; k < datosCuestionario.mejorasRatio.length; k++) {
      if (
        mejorasMinisucula.includes(
          datosCuestionario.mejorasRatio[k].abreviatura
        )
      ) {
        ratio.push(datosCuestionario.mejorasRatio[k]);
      }
    }
    setRatio(ratio);
    //4TO FOR PARA LA GARANTIA
    for (let k = 0; k < datosCuestionario.mejorasGarantia.length; k++) {
      if (
        mejorasMinisucula.includes(
          datosCuestionario.mejorasGarantia[k].abreviatura
        )
      ) {
        garantia.push(datosCuestionario.mejorasGarantia[k]);
      }
    }
    setDobleGarantiaBD(garantia);
    // console.log(ratio);
  
    // console.log(primerEndeu);
    setMejorasMostrar(true);
  }

  const [mensajeEmailJS] = useState(
    `
      Solicitud Hipotecaria N°: ${solicitudHipotecaria}
      El usuario: ${datosCuestionario.nombreViabilidad} ${datosCuestionario.apellidoViabilidad}, telefono: ${datosCuestionario.telefonoViabilidad} ha realizado una 
      simulacion, la cual dio como resultado:${resPorcentaje}%.
      
      Gestor Inmobiliario: ${date.email}
    `
    )

    const [mensajeEmailJSDos] = useState(
      `
        Solicitud Hipotecaria N°: ${solicitudHipotecaria}
        Datos de solicitante: ${datosCuestionario.nombreViabilidad} ${datosCuestionario.apellidoViabilidad}, telefono: ${datosCuestionario.telefonoViabilidad}
        Hola ${date.email}, ¡Enhorabuena!, has realizado una nueva solicitud hipotecaria.

        Adjunto tienes los datos aportados en la solicitud.
        Para iniciar el proceso es necesario que nos envíes los siguientes documentos:
          •	Solicitud Hipotecaria firmada por los solicitantes
          •	Condiciones Hipotecarias Firmada por los solicitantes
          •	LOPD firmada por los solicitantes
        Documentación de/los inmuebles a Hipotecar:
          •	Nota simple
          •	Referencia catastral
          •	Cédula de habitabilidad
          •	Certificado de eficiencia energética
          •	Encargo firmado por el vendedor (solo para el caso que los honorarios se hayan pactado con el vendedor)
      `
      )
    const fechaD = new Date();
    const dia = fechaD.getDate(); 
    const mes = fechaD.getMonth() + 1; 
    const anio = fechaD.getFullYear(); 
    let mesString = ''
    switch (mes) {
      case 1:
        mesString = 'Enero'
        break;
      case 2:
        mesString = 'Febrero'
        break; 
      case 3:
        mesString = 'Marzo'
        break;
      case 4:
        mesString = 'Abril'
        break;
      case 5:
        mesString = 'Mayo'
        break;
      case 6:
        mesString = 'Junio'
        break;  
      case 7:
        mesString = 'Julio'
        break; 
      case 8:
        mesString = 'Agosto'
        break; 
      case 9:
        mesString = 'Septiembre'
        break; 
      case 10:
        mesString = 'Octubre'
        break; 
      case 11:
        mesString = 'Noviembre'
        break; 
      case 12:
        mesString = 'Diciembre'
        break; 
      default:
        break;
    }
    
    let fecha = `a ${dia} de ${mesString} de ${anio}`
    let fechaBD = `${dia}-${mes}-${anio}`
    console.log(fecha + " sadsadsadsadasd");


  return (
    <>
      <h1 onClick={() => console.log(datosCuestionario)}>Datos cuestionario </h1>
      {/* <h1 onClick={guardarSimulacionEnBD}>Guardar</h1> */}
      {/* <h1 onClick={mostrarDatoscues}>mostrar datos cuest</h1> */}
      {/* mostrarEndeu , mostrarRatio , mostrarSegundoEndeu */}
      {/* <h1 onClick={() => console.log(resPorcentaje)}>res porcentaje</h1> */}
      {/* <h1
        onClick={() =>
          console.log(porcentajeUno, porcentajeDos, porcentajeTres)
        }
      >
        Penas
      </h1> */}

      <p onClick={volver}>{"<-"}Volver</p>
      {/* <h1 onClick={() => console.log(!isNaN(datosCuestionario.nombreViabilidadCuatro))}>datosCuestionario.optMejoraEndeudamiento</h1> */}
      {/* <h1 onClick={() => console.log(resPorcentaje)}>resPorcentaje</h1> */}
      {/* <h1 onClick={() => console.log(date.email)}>resPorcentaje</h1> */}
      {mostrarCuadros ? (
        <>
          {modalDeLaSimulacion === 1 ? (
            <div className="contenedor-simulacion aprobada-contenedor">
              <h1 onClick={() => setPorcentaje(resPorcentaje - 50)}>
                Resultado de la Simulación Hipotecaria
              </h1>
              {/* <h1 onClick={()=>console.log(porcentajeUno , porcentajeDos, porcentajeTres)}>asd</h1> */}
              {/* <h1 onClick={()=>console.log(dobleGarantiaBD)}>asd asd</h1> */}
              {/* <h1 onClick={volver}>volver</h1> */}
              <div className="contenedor-simulacion-res aprobada-res">
                <img
                  className="contenedor-simulacion-res-img"
                  src={pulgar}
                  alt="img_pulgar"
                />
              </div>
              <section className="contenedor-simulacion-leyenda">
                <h3>Enhorabuena!!! Tu simulación es viable</h3>
                <p>
                  Ya puedes reservar el inmueble. <br />
                  Confirma la solicitud para comenzar con el proceso.
                </p>
              </section>
              <section className="contenedor-simulacion-mejoras">
                <div className="contenedor-simulacion-mejoras-opts">
                  <a
                    href="#complete"
                    onClick={mostrarContratos}
                    className="container-firmax-section-fija-button"
                  >
                    Confirmar solicitud
                  </a>
                  <button
                    onClick={() => setmostrarVisualizar(!mostrarVisualizar)}
                    className="container-firmax-section-fija-button"
                  >
                    Visualizar solicitud
                  </button>
                </div>
              </section>
              {mostrarVisualizar ? (
                <section className="contenedor-simulacion-detalles">
                  <div className="contenedor-simulacion-detalles-info">
                    <div className="contenedor-simulacion-detalles-info-texto">
                      <h3>ESCRITURA</h3>
                      {datosCuestionario.honorariosDeEscritura === 'si'
                      ? `${datosCuestionario.hipotecaMasComisionMasHonorarios}Є`
                      : `${datosCuestionario.resTres}Є`
                      }
                      
                    </div>
                    <div className="contenedor-simulacion-detalles-info-texto">
                    <h3>HIPOTECA</h3>
                      <p> {datosCuestionario.resPreguntaCero === "si"
                      ? Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
                      : datosCuestionario.honorariosDeEscritura === "no"
                      ? Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
                      : Math.floor(
                          datosCuestionario.hipotecaSolicitadaConHonorarios / 500
                        ) * 500}
                      €</p>
                    </div>
                    <div className="contenedor-simulacion-detalles-info-texto">
                    <h3>ENTRADA</h3>
                      <p> {datosCuestionario.resPreguntaCero === "si"
                      ? parseInt(datosCuestionario.resTres) - Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
                      : datosCuestionario.honorariosDeEscritura === "no"
                      ? parseInt(datosCuestionario.resTres) - Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
                      : parseInt(datosCuestionario.hipotecaMasComisionMasHonorarios) - Math.floor(
                          datosCuestionario.hipotecaSolicitadaConHonorarios / 500
                        ) * 500}
                      €</p>
                    </div>
                  </div>
                  {datosCuestionario.valorFijoMixto === "fijo" ? (
                    <div className="contenedor-simulacion-detalles-datos">
                      <h2>
                        Hipoteca <span style={{ color: "#69b62d" }}>Fija</span>{" "}
                        desde
                      </h2>
                  
                      <h2>{Math.floor(datosCuestionario.innteresFija)}%</h2>
                      <div className="contenedor-simulacion-detalles-datos-row">
                        <p>Plazo</p>
                        <h2 style={{fontSize:"18px", fontWeight:"400"}}>{datosCuestionario.aniosTiempo}</h2>
                        <p>años</p>
                      </div>
                      <h3>Cuota estimada</h3>
                      <h3>{Math.floor(datosCuestionario.cuotaEstimadaFija)}Є</h3>
                    </div>
                  ) : datosCuestionario.valorFijoMixto === "variable" ? (
                    <div className="contenedor-simulacion-detalles-datos">
                      <h2>
                        Hipoteca{" "}
                        <span style={{ color: "#69b62d" }}>Variable</span> desde
                      </h2>
                      <div style={{ display: "flex", gap: " 2rem" }}>
                        <div>
                          <p style={{fontSize:"12px"}}>Primeros {setearDatosCalculadora.primerPeriodoVariable}{" "}
                        meses</p>
                        <h2>{datosCuestionario.innteresVariableEuribor}%</h2>
                        </div>

                        <div>
                          <p style={{fontSize:"12px"}}>Resto de plazo</p>
                          <h2><span style={{fontWeight:"400", fontSize:"12px"}}>Euribor</span> + {datosCuestionario.innteresVariable}%</h2>
                        </div>
                       
                      </div>

                      <div className="contenedor-simulacion-detalles-datos-row">
                        <p>Plazo</p>
                        <h2 style={{fontSize:"18px", fontWeight:"400"}}>{datosCuestionario.aniosTiempoVariable}</h2>
                        <p>años</p>
                      </div>
                      <div style={{ display: "flex", gap: " 2rem" }}>
                        <section>
                          <h3>Cuota estimada</h3>
                          <h3>{datosCuestionario.cuotaEstimadaVariable}Є</h3>
                        </section>

                        <section>
                          <h3>Resto de plazo</h3>
                          <h3>
                            {datosCuestionario.cuotaEstimadaVariableEuribor}Є
                          </h3>
                        </section>
                      </div>
                    </div>
                  ) : (
                    <div className="contenedor-simulacion-detalles-datos">
                      <h2>
                        Hipoteca <span style={{ color: "#69b62d" }}>Mixto</span>{" "}
                        desde
                      </h2>
                      <div style={{ display: "flex", gap: " 2rem" }}>
                      <div>
                            <p style={{fontSize:"12px"}}>Primeros {setearDatosCalculadora.primerPeriodoMixto}{" "}
                            meses</p>
                            <h2>{datosCuestionario.interesMixtaEuribor}%</h2>
                        </div>

                        <div>
                          <p style={{fontSize:"12px"}}>Resto de plazo</p>
                          <h2><span style={{fontWeight:"400", fontSize:"12px"}}>Euribor</span> + {datosCuestionario.interesMixta}%</h2>
                        </div>
                      </div>

                      <div className="contenedor-simulacion-detalles-datos-row">
                        <p>Plazo</p>
                        <h2 style={{fontSize:"18px", fontWeight:"400"}}>{datosCuestionario.aniosTiempoMixto}</h2>
                        <p>años</p>
                      </div>
                      <div style={{ display: "flex", gap: " 2rem" }}>
                        <section>
                          <h3>Cuota inicial</h3>
                          <h3>{datosCuestionario.cuotaEstimadaMixta}Є</h3>
                        </section>

                        <section>
                          <h3>Resto de plazo</h3>
                          <h3>
                            {datosCuestionario.cuotaEstimadaMixtaEuribor}Є
                          </h3>
                        </section>
                      </div>
                    </div>
                  )}
                </section>
              ) : null}
              <section className="contenedor-simulacion-leyenda">
                <p>
                  Confirma la solicitud para imprimir en el encargo y LOPD. Una
                  vez recibamos estos <br />
                  documentos firmados nos pondremos en contacto con los clientes
                  para <br />
                  comenzar con el proceso.
                </p>
              </section>
              <section className="contenedor-simulacion-leyenda">
                <p style={{ fontSize: "12px", paddingTop: "3rem" }}>
                  Los datos proporcionados en esta simulación son únicamente de
                  carácter orientativo. <br />
                  Bajo ningún concepto forman parte de una oferta vinculante,
                  dichos datos quedarán <br />
                  sujetos a la viabilidad de la solicitud y al estudio de la
                  documentación aportada <br />
                  por quien la solicite.
                </p>
              </section>
            </div>
          ) : null}

          {modalDeLaSimulacion === 2 ? (
            <div className="contenedor-simulacion aprobada-contenedor">
              <h1 onClick={() => setPorcentaje(porcentaje - 25)}>
                Resultado de la Simulación Hipotecaria
              </h1>
              <div
                onClick={() => console.log(datosCuestionario)}
                className="contenedor-simulacion-res aprobada-res"
              >
                VIABILIDAD <br />
                DEL 75%
              </div>
              <section className="contenedor-simulacion-leyenda">
                <p>
                  Puedes hacer una reserva aunque recomendamos que sea <br />
                  condicionada a la hipoteca. <br />
                  Confirma la solicitud para comenzar el proceso. Tras recibir
                  la solicitud firmada por <br />
                  tu gestor de Firmax se pondrá en contacto para comentar los
                  detalles.
                </p>
              </section>
              <section className="contenedor-simulacion-mejoras">
                <div className="contenedor-simulacion-mejoras-opts">
                  <a
                    href="#complete"
                    onClick={mostrarContratos}
                    className="container-firmax-section-fija-button"
                  >
                    Confirmar solicitud
                  </a>
                  <button
                    onClick={() => setmostrarVisualizar(!mostrarVisualizar)}
                    className="container-firmax-section-fija-button"
                  >
                    Visualizar solicitud
                  </button>
                </div>
              </section>
              {mostrarVisualizar ? (
                <section className="contenedor-simulacion-detalles">
                  <div className="contenedor-simulacion-detalles-info">
                    <div className="contenedor-simulacion-detalles-info-texto">
                    <h3>ESCRITURA</h3>
                      {datosCuestionario.honorariosDeEscritura === 'si'
                      ? `${datosCuestionario.hipotecaMasComisionMasHonorarios}Є`
                      : `${datosCuestionario.resTres}Є`
                      }
                    </div>
                    <div className="contenedor-simulacion-detalles-info-texto">
                      <h3>HIPOTECA</h3>
                      <p> {datosCuestionario.resPreguntaCero === "si"
                      ? Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
                      : datosCuestionario.honorariosDeEscritura === "no"
                      ? Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
                      : Math.floor(
                          datosCuestionario.hipotecaSolicitadaConHonorarios / 500
                        ) * 500}
                    €</p>
                    </div>
                    <div className="contenedor-simulacion-detalles-info-texto">
                    <h3>ENTRADA</h3>
                      <p> {datosCuestionario.resPreguntaCero === "si"
                      ? parseInt(datosCuestionario.resTres) - Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
                      : datosCuestionario.honorariosDeEscritura === "no"
                      ? parseInt(datosCuestionario.resTres) - Math.floor(datosCuestionario.hipotecaSolicitada / 500) * 500
                      : parseInt(datosCuestionario.hipotecaMasComisionMasHonorarios) - Math.floor(
                          datosCuestionario.hipotecaSolicitadaConHonorarios / 500
                        ) * 500}
                      €</p>
                    </div>
                  </div>
                  {datosCuestionario.valorFijoMixto === "fijo" ? (
                    <div className="contenedor-simulacion-detalles-datos">
                      <h2>
                        Hipoteca <span style={{ color: "#69b62d" }}>Fija</span>{" "}
                        desde
                      </h2>
                      <h2>{datosCuestionario.innteresFija}%</h2>
                      <div className="contenedor-simulacion-detalles-datos-row">
                        <p>Plazo</p>
                        <h2 style={{fontSize:"18px", fontWeight:"400"}}>{datosCuestionario.aniosTiempo}</h2>
                        <p>años</p>
                      </div>
                      <h3>Cuota estimada</h3>
                      <h3>{Math.floor(datosCuestionario.cuotaEstimadaFija)}Є</h3>
                    </div>
                  ) : datosCuestionario.valorFijoMixto === "variable" ? (
                    <div className="contenedor-simulacion-detalles-datos">
                      <h2>
                        Hipoteca{" "}
                        <span style={{ color: "#69b62d" }}>Variable</span> desde
                      </h2>
                      <div style={{ display: "flex", gap: " 2rem" }}>
                      <div>
                          <p style={{fontSize:"12px"}}>Primeros {setearDatosCalculadora.primerPeriodoVariable}{" "}
                        meses</p>
                        <h2>{datosCuestionario.innteresVariableEuribor}%</h2>
                        </div>

                        <div>
                          <p style={{fontSize:"12px"}}>Resto de plazo</p>
                          <h2><span style={{fontWeight:"400", fontSize:"12px"}}>Euribor</span> + {datosCuestionario.innteresVariable}%</h2>
                        </div>
                      </div>

                      <div className="contenedor-simulacion-detalles-datos-row">
                        <p>Plazo</p>
                        <h2 style={{fontSize:"18px", fontWeight:"400"}}>{datosCuestionario.aniosTiempoVariable}</h2>
                        <p>años</p>
                      </div>
                      <div style={{ display: "flex", gap: " 2rem" }}>
                        <section>
                          <h3>Cuota inicial</h3>
                          <h3>{datosCuestionario.cuotaEstimadaVariable}Є</h3>
                        </section>

                        <section>
                          <h3>Resto de plazo</h3>
                          <h3>
                            {datosCuestionario.cuotaEstimadaVariableEuribor}Є
                          </h3>
                        </section>
                      </div>
                    </div>
                  ) : (
                    <div className="contenedor-simulacion-detalles-datos">
                      <h2>
                        Hipoteca <span style={{ color: "#69b62d" }}>Mixto</span>{" "}
                        desde
                      </h2>
                      <div style={{ display: "flex", gap: " 2rem" }}>
                        <div>
                            <p style={{fontSize:"12px"}}>Primeros {setearDatosCalculadora.primerPeriodoMixto}{" "}
                            meses</p>
                            <h2>{datosCuestionario.interesMixtaEuribor}%</h2>
                        </div>

                        <div>
                          <p style={{fontSize:"12px"}}>Resto de plazo</p>
                          <h2><span style={{fontWeight:"400", fontSize:"12px"}}>Euribor</span> + {datosCuestionario.interesMixta}%</h2>
                        </div>
                     
               
                      </div>

                      <div className="contenedor-simulacion-detalles-datos-row">
                        <p>Plazo</p>
                        <h2 style={{fontSize:"18px", fontWeight:"400"}}>{datosCuestionario.aniosTiempoMixto}</h2>
                        <p>años</p>
                      </div>
                      <div style={{ display: "flex", gap: " 2rem" }}>
                        <section>
                          <h3>Cuota inicial</h3>
                          <h3>{datosCuestionario.cuotaEstimadaMixta}Є</h3>
                        </section>

                        <section>
                          <h3>Resto de plazo</h3>
                          <h3>
                            {datosCuestionario.cuotaEstimadaMixtaEuribor}Є
                          </h3>
                        </section>
                      </div>
                    </div>
                  )}
                </section>
              ) : null}

              <section className="contenedor-simulacion-leyenda">
                <p>
                  Confirma la solicitud para imprimir en el encargo y LOPD. Una
                  vez recibamos estos <br />
                  documentos firmados nos pondremos en contacto con los clientes
                  para <br />
                  comenzar con el proceso.
                </p>
              </section>
              <section className="contenedor-simulacion-leyenda">
                <p style={{ fontSize: "12px", paddingTop: "3rem" }}>
                  Los datos proporcionados en esta simulación son únicamente de
                  carácter orientativo. <br />
                  Bajo ningún concepto forman parte de una oferta vinculante,
                  dichos datos quedarán <br />
                  sujetos a la viabilidad de la solicitud y al estudio de la
                  documentación aportada <br />
                  por quien la solicite.
                </p>
              </section>
            </div>
          ) : null}

          {modalDeLaSimulacion === 3 ? (
            <div className="contenedor-simulacion">
              <h1 onClick={() => console.log(datosCuestionario)}>
                Resultado de la Simulación Hipotecaria
              </h1>
              <div className="contenedor-simulacion-res">
                VIABILIDAD <br /> BAJA
              </div>
              <section className="contenedor-simulacion-leyenda">
                <p>
                  Tendremos que hacer cambios para mejorar la viabilidad de la
                  hipoteca. <br />
                  Has click en las posibles mejoras detalladas a continuación o
                  ponte en contacto con <br /> tu gestor Firmax para analizar
                  las posibilidades.
                </p>

                {!mejorasMostrar ? (
                  <button
                    style={{ marginTop: "1rem" }}
                    onClick={mostrarMejoras}
                    className="container-firmax-section-fija-button"
                  >
                    Mostrar mejoras
                  </button>
                ) : null}
              </section>

              {mejorasMostrar ? (
                <section>
                  {porcentajeUno > 0 
                  && visualizarAntiguedad === true ? (
                    <div className="contenedor-mejoras-separadas">
                      <label htmlFor="">
                        Seleccione una opcion para no penalizar por Antiguedad
                      </label>
                      <select
                        name="optMejoraAntiguedad"
                        onChange={handleOptionChange}
                      >
                        <option value="">Seleccione</option>
                        {primerEndeu.map((mejora) => (
                          <option
                            name="optMejoraAntiguedad"
                            key={mejora.id}
                            value={mejora.abreviatura}
                          >
                            {mejora.leyenda}
                          </option>
                        ))}
                      </select>
                      <button
                          onClick={()=>mostrarCosas('si')}
                          className="btns-recalculo"
                        >
                        Recalcular antiguedad
                      </button>
                    </div>
                  ) : null}

                  {porcentajeDos > 0 
                   && visualizarEndeudamiento === true? (
                    <div className="contenedor-mejoras-separadas">
                      <label htmlFor="">
                        Seleccione una opcion para no penalizar por
                        Endeudamiento
                      </label>
                      <select
                        name="optMejoraEndeudamiento"
                        id=""
                        onChange={handleOptionChange}
                      >
                        <option value="">Seleccione</option>
                        {ratio.map((mejora) => (
                          <option
                            name="optMejoraEndeudamiento"
                            key={mejora.id}
                            value={mejora.abreviatura}
                          >
                            {mejora.leyenda}
                          </option>
                        ))}
                      </select>
                      <button
                          onClick={recalcularDeEndeudamiento}
                          className="btns-recalculo"
                        >
                        Recalcular endeudamiento
                      </button>
                    </div>
                  ) : null}

                  {porcentajeTres > 0 && 
                  visualizarGarantia === true &&
                  datosCuestionario.segundaGarantia === "no" ? (
                    <div className="contenedor-mejoras-separadas">
                      <label htmlFor="">
                        Seleccione una opcion para no penalizar por Garantia
                      </label>
                      <select
                        name="optMejoraGarantia"
                        id=""
                        onChange={handleOptionChange}
                      >
                        <option value="">Seleccione</option>
                        {dobleGarantiaBD.map((mejora) => (
                          <option
                            name="optMejoraGarantia"
                            key={mejora.id}
                            value={mejora.abreviatura}
                          >
                            {mejora.leyenda}
                          </option>
                        ))}
                      </select>
                      <button
                          onClick={recalcularDeGarantia}
                          className="btns-recalculo"
                        >
                        Recalcular garantia
                      </button>
                    </div>
                  ) : null}

                  {/* {mejorasMostrar ? (
                    <button
                      onClick={()=>mostrarCosas('si')}
                      className="container-firmax-section-fija-button"
                    >
                      Recalcular
                    </button>
                  ) : null} */}
                </section>
              ) : null}

              <section
                style={{ paddingTop: "1rem" }}
                className="contenedor-simulacion-leyenda"
                onClick={mostrarCosas}
              >
                <p>
                  Al marcar estas mejoras, la viabilidad se racalcula
                  automáticamente. <br />
                  Si la viabilidad mejora, ya podrá confirmar la solicitud.
                </p>
              </section>
              <section className="contenedor-simulacion-leyenda">
                <p style={{ fontSize: "12px", paddingTop: "3rem" }}>
                  Los datos proporcionados en esta simulación son únicamente de
                  carácter orientativo. <br />
                  Bajo ningún concepto forman parte de una oferta vinculante,
                  dichos datos quedarán <br />
                  sujetos a la viabilidad de la solicitud y al estudio de la
                  documentación aportada <br />
                  por quien la solicite.
                </p>
              </section>
            </div>
          ) : null}

          {confirmar ? (
            <form ref={form} className="form-contratos" onSubmit={generarContratos}>
              <section
                className="contenedor-simulacion-contratos"
                id="complete"
              >
                <div className="contenedor-simulacion-contratos-datos">
                  <textarea style={{opacity:"0"}} value={mensajeEmailJS} name="message" onChange={handleOptionChange}/>
                  <textarea style={{opacity:"0"}} value={mensajeEmailJSDos} name="messageDos" onChange={handleOptionChange}/>
                  <h3>
                    Para finalizar completa estos datos de{" "}
                    <span style={{ color: "#69b62d" }}>
                      {datosCuestionario.nombreViabilidad}
                    </span>
                    :
                  </h3>
                  <label>N° de DNI:</label>
                  <input
                    required
                    type="text"
                    name="dniViabilidad"
                    onChange={handleOptionChange}
                    value={datosCuestionario.dniViabilidad}
                  />
                  <label>E-mail:</label>
                  <input
                    required
                    type="emial"
                    name="emailViabilidad"
                    onChange={handleOptionChange}
                    value={datosCuestionario.emailViabilidad}
                  />
                  <label>Domicilio:</label>
                  <input
                    required
                    type="text"
                    name="domicilioViabilidad"
                    onChange={handleOptionChange}
                    value={datosCuestionario.domicilioViabilidad}
                  />
                  <label>Localidad:</label>
                  <input
                    required
                    type="text"
                    name="poblacionViabilidad"
                    onChange={handleOptionChange}
                    value={datosCuestionario.poblacionViabilidad}
                  />
                  <label>Provincia:</label>
                  <input
                    required
                    type="text"
                    name="provinciaViabilidad"
                    onChange={handleOptionChange}
                    value={datosCuestionario.provinciaViabilidad}
                  />
                </div>
              </section>
              {datosCuestionario.nombreViabilidadDos && datosCuestionario.nombreViabilidadDos !== 'undefined' && isNaN(datosCuestionario.nombreViabilidadDos) ? (
                <section className="contenedor-simulacion-contratos">
                  <div className="contenedor-simulacion-contratos-datos">
                    <h3>
                      Para finalizar completa estos datos de{" "}
                      <span style={{ color: "#69b62d" }}>
                        {datosCuestionario.nombreViabilidadDos}
                      </span>
                      :
                    </h3>
                    <label>Apellidos:</label>
                    <input
                      required
                      type="text"
                      name="apellidoViabilidadDos"
                      onChange={handleOptionChange}
                      value={datosCuestionario.apellidoViabilidadDos}
                    />
                    <label>N° de DNI:</label>
                    <input
                      required
                      type="text"
                      name="dniViabilidadDos"
                      onChange={handleOptionChange}
                      value={datosCuestionario.dniViabilidadDos}
                    />
                    <label>E-mail:</label>
                    <input
                      required
                      type="email"
                      name="emailViabilidadDos"
                      onChange={handleOptionChange}
                      value={datosCuestionario.emailViabilidadDos}
                    />
                    <label>Teléfono:</label>
                    <input
                      required
                      type="number"
                      name="telefonoViabilidadDos"
                      onChange={handleOptionChange}
                      value={datosCuestionario.telefonoViabilidadDos}
                    />
                  </div>
                </section>
              ) : null}
              {datosCuestionario.nombreViabilidadTres && datosCuestionario.nombreViabilidadTres !== 'undefined' && isNaN(datosCuestionario.nombreViabilidadTres) ? (
                <section className="contenedor-simulacion-contratos">
                  <div className="contenedor-simulacion-contratos-datos">
                    <h3>
                      Para finalizar completa estos datos de{" "}
                      <span style={{ color: "#69b62d" }}>
                        {datosCuestionario.nombreViabilidadTres}
                      </span>
                      :
                    </h3>
                    <label>Apellidos:</label>
                    <input
                      required
                      type="text"
                      name="apellidoViabilidadTres"
                      onChange={handleOptionChange}
                      value={datosCuestionario.apellidoViabilidadTres}
                    />
                    <label>N° de DNI:</label>
                    <input
                      required
                      type="text"
                      name="dniViabilidadTres"
                      onChange={handleOptionChange}
                      value={datosCuestionario.dniViabilidadTres}
                    />
                    <label>E-mail:</label>
                    <input
                      required
                      type="email"
                      name="emailViabilidadTres"
                      onChange={handleOptionChange}
                      value={datosCuestionario.emailViabilidadTres}
                    />
                    <label>Teléfono:</label>
                    <input
                      required
                      type="number"
                      name="telefonoViabilidadTres"
                      onChange={handleOptionChange}
                      value={datosCuestionario.telefonoViabilidadTres}
                    />
                  </div>
                </section>
              ) : null}
              {datosCuestionario.nombreViabilidadCuatro &&datosCuestionario.nombreViabilidadCuatro !== 'undefined' && isNaN(datosCuestionario.nombreViabilidadCuatro) ? (
                <section className="contenedor-simulacion-contratos">
                  <div className="contenedor-simulacion-contratos-datos">
                    <h3>
                      Para finalizar completa estos datos de{" "}
                      <span style={{ color: "#69b62d" }}>
                        {datosCuestionario.nombreViabilidadCuatro}
                      </span>
                      :
                    </h3>
                    <label>Apellidos:</label>
                    <input
                      required
                      type="text"
                      name="apellidoViabilidadCuatro"
                      onChange={handleOptionChange}
                      value={datosCuestionario.apellidoViabilidadCuatro}
                    />
                    <label>N° de DNI:</label>
                    <input
                      required
                      type="text"
                      name="dniViabilidadCuatro"
                      onChange={handleOptionChange}
                      value={datosCuestionario.dniViabilidadCuatro}
                    />
                    <label>E-mail:</label>
                    <input
                      required
                      type="email"
                      name="emailViabilidadCuatro"
                      onChange={handleOptionChange}
                      value={datosCuestionario.emailViabilidadCuatro}
                    />
                    <label>Teléfono:</label>
                    <input
                      required
                      type="number"
                      name="telefonoViabilidadCuatro"
                      onChange={handleOptionChange}
                      value={datosCuestionario.telefonoViabilidadCuatro}
                    />
                  </div>
                  
                </section>
              ) : null}
              <button
                type="submit"
                value="Send"
                className="container-firmax-section-fija-button center"
              >
                Generar contrato
              </button>
            </form>
          ) : null}
        </>
      ) : null}

      {vistaPdf ? (
        datosCuestionario.resPreguntaCero === "si" ? (
          <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            <ContratoAcargoDelVendedor
              datosCuestionario={datosCuestionario}
              euribor={euribor}
              aniosAmeses={aniosAmeses}
              setearDatosCalculadora={setearDatosCalculadora}
              innteresVariable={innteresVariable}
              innteresFija={innteresFija}
              fecha={fecha}
              optMejoraGarantia={datosCuestionario.optMejoraGarantia}
              optMejoraAntiguedad={datosCuestionario.optMejoraAntiguedad}
              mesesInteres={mesesInteres}
              solicitudHipotecaria={solicitudHipotecaria}
            />
          </PDFViewer>
        ) : datosCuestionario.honorariosDeEscritura === "si" ? (
          <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            <ContratoSiIncluido
              datosCuestionario={datosCuestionario}
              euribor={euribor}
              aniosAmeses={aniosAmeses}
              setearDatosCalculadora={setearDatosCalculadora}
              innteresVariable={innteresVariable}
              innteresFija={innteresFija}
              fecha={fecha}
              optMejoraGarantia={datosCuestionario.optMejoraGarantia}
              optMejoraAntiguedad={datosCuestionario.optMejoraAntiguedad}
              mesesInteres={mesesInteres}
              solicitudHipotecaria={solicitudHipotecaria}
            />
          </PDFViewer>
        ) : (
          <PDFViewer style={{ width: "100vw", height: "100vh" }}>
            <ContratoNoIncluidosEscritura
              datosCuestionario={datosCuestionario}
              euribor={euribor}
              aniosAmeses={aniosAmeses}
              setearDatosCalculadora={setearDatosCalculadora}
              innteresVariable={innteresVariable}
              innteresFija={innteresFija}
              fecha={fecha}
              optMejoraGarantia={datosCuestionario.optMejoraGarantia}
              optMejoraAntiguedad={datosCuestionario.optMejoraAntiguedad}
              mesesInteres={mesesInteres}
              solicitudHipotecaria={solicitudHipotecaria}
            />
          </PDFViewer>
        )
      ) : null}
    </>
  );
};

export default ResViabilidad;
