import { useState , useEffect } from "react";
import './App.css';
import { onAuthStateChanged } from "firebase/auth";
import {auth} from './Firebase/Credenciales'
import Redirect from "./Pages/Redirect";
import Login from "./Pages/Login";




function App() {

  const [estadoLogin, setEstadoLogin] = useState(null);

  //render de estadoLogin
  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setEstadoLogin(userLog);
      } else {
        setEstadoLogin(null);
      }
    });
  }, [estadoLogin]);
  

  return <div>{estadoLogin ? <Redirect user={estadoLogin} /> : <Login/>}</div>
}

export default App;
