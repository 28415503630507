/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import logo from "../../../img/logo.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../../Firebase/Credenciales";
import { v4 as uuidv4 } from "uuid";
import { onAuthStateChanged } from "firebase/auth";
import Swal from "sweetalert2";

const CrearCalculadora = () => {
  const navigate = useNavigate();
  const [correct, setCorrect] = useState(false);
  const [date, setDate] = useState(null);
  const [contratoBD, setContratoBD] = useState([]);
  const [flag, setFlag] = useState(true);
  const [calcusExistentes, setcCalcusExistentes] = useState([]);
  const [target, setTarget] = useState(true);
  const [modificar, setModificar] = useState(false);
  const [estadoCrearCalculadora, setestadoCrearCalculadora] = useState([
    {
      name: true,
    },
    {
      name: false,
    },
    {
      name: false,
    },
  ]);

  const [idCalcuMod, setidCalcuMod] = useState("");

  const [datosNuevaCalculadora, setDatosNuevaCalculadora] = useState({});

  const [escnarios, setEscnarios] = useState([]);
  const [newEscenario, setNewEscenario] = useState(0);

  const [optCalcus, setOptCalcus] = useState(0);

  function valueChangeCalculadora(e) {
    e.preventDefault();
    setDatosNuevaCalculadora({
      ...datosNuevaCalculadora,
      [e.target.name]: e.target.value,
    });
  }

  async function guardarEnBD(e) {
    e.preventDefault();
    try {
      console.log(mostrarContratos);

      await exitoso("guardada");
      await setDoc(doc(db, "calculadoras", datosNuevaCalculadora.nombre), {
        datosNuevaCalculadora,
        id: uuidv4(),
        numEscenarios: newEscenario,
      });

      setCorrect(true);
    } catch (error) {
      console.log(error + " Error");
      Swal.fire({
        title: "Error",
        text: "Intentelo nuevamente!",
        icon: "error",
        confirmButtonColor: "#8FCD60",
      });
    }
  }

  async function modificarEnBD(e) {
    e.preventDefault();
    try {
      await exitoso("guardada");
      await setDoc(doc(db, "calculadoras", datosNuevaCalculadora.nombre), {
        datosNuevaCalculadora,
        id: idCalcuMod,
        numEscenarios: newEscenario,
      });
      setCorrect(true);
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Intentelo nuevamente!",
        icon: "error",
        confirmButtonColor: "#8FCD60",
      });
    }
  }

  function crearCalculo() {
    setOptCalcus(0);
    setearEscenario(0);
    setDatosNuevaCalculadora({
      bonificacion: "",
      primerPeriodoVariable: "",
      primerPeriodoMixto: "",
      hipotecaMinima: "",
      hipotecaMaxima: "",
      edadMaximaTitularMenor: "",
      nombre: "",
      maximoSobreTasacion: "",
      tinPrimerosMesesVariable: "",
      tinPrimerosMesesMixto: "",
      productoHipotecario: "",
      nombreInmobiliaria: "",
      porcentajeDelimitante: "",
      honorariosMinimoInferior: "",
      honorariosMaximoInferior: "",
      comisionMinima: "",
      comisionPrimeraIncremnto: "",
      comisionExtraIncremento: "",
      uid: "",
      id: "",
      mostrarComision: "",
      incuirEdadtitualMenor: "",
      plazoFijoMinimo: "",
      plazoFijoMaximo: "",
      plazoVariableMinimo: "",
      plazoVariableMaximo: "",
      plazoMixtoMinimo: "",
      plazoMixtoMaximo: "",
      edadMaximaTitular: "",
      hastaDiezFijo: "",
      hastaQuinceFijo: "",
      hastaVeinteFijo: "",
      hastaVeinteCincoFijo: "",
      hastaTreintaFijo: "",
      hastaTreintaCincoFijo: "",
      hastaCuarentaFijo: "",
      hastaCuarentaCincoFijo: "",
      hastaCincuentFijo: "",
      //interes variable
      hastaDiezVariable: "",
      hastaQuinceVariable: "",
      hastaVeinteVariable: "",
      hastaVeinteCincoVariable: "",
      hastaTreintaVariable: "",
      hastaTreintaCincoVariable: "",
      hastaCuarentaVariable: "",
      hastaCuarentaCincoVariable: "",
      hastaCincuentVariable: "",
      //interes mixto
      hastaDiezMixto: "",
      hastaQuinceMixto: "",
      hastaVeinteMixto: "",
      hastaVeinteCincoMixto: "",
      hastaTreintaMixto: "",
      hastaTreintaCincoMixto: "",
      hastaCuarentaMixto: "",
      hastaCuarentaCincoMixto: "",
      hastaCincuentMixto: "",
      //LIMITES DE PORCENTAJE
      //0
      delEscnario0Limite1: "",
      delEscnario0Limite2: "",
      delEscnario0Limite3: "",
      delEscnario0Limite4: "",
      delEscnario0Limite5: "",
      delEscnario0Limite6: "",
      delEscnario0Limite7: "",
      delEscnario0Limite8: "",
      delEscnario0Limite9: "",
      delEscnario0Limite10: "",
      delEscnario0Limite11: "",
      delEscnario0Limite12: "",
      //1
      delEscnario1Limite1: "",
      delEscnario1Limite2: "",
      delEscnario1Limite3: "",
      delEscnario1Limite4: "",
      delEscnario1Limite5: "",
      delEscnario1Limite6: "",
      delEscnario1Limite7: "",
      delEscnario1Limite8: "",
      delEscnario1Limite9: "",
      delEscnario1Limite10: "",
      delEscnario1Limite11: "",
      delEscnario1Limite12: "",
      //2
      delEscnario2Limite1: "",
      delEscnario2Limite2: "",
      delEscnario2Limite3: "",
      delEscnario2Limite4: "",
      delEscnario2Limite5: "",
      delEscnario2Limite6: "",
      delEscnario2Limite7: "",
      delEscnario2Limite8: "",
      delEscnario2Limite9: "",
      delEscnario2Limite10: "",
      delEscnario2Limite11: "",
      delEscnario2Limite12: "",
      //3
      delEscnario3Limite1: "",
      delEscnario3Limite2: "",
      delEscnario3Limite3: "",
      delEscnario3Limite4: "",
      delEscnario3Limite5: "",
      delEscnario3Limite6: "",
      delEscnario3Limite7: "",
      delEscnario3Limite8: "",
      delEscnario3Limite9: "",
      delEscnario3Limite10: "",
      delEscnario3Limite11: "",
      delEscnario3Limite12: "",
      //4
      delEscnario4Limite1: "",
      delEscnario4Limite2: "",
      delEscnario4Limite3: "",
      delEscnario4Limite4: "",
      delEscnario4Limite5: "",
      delEscnario4Limite6: "",
      delEscnario4Limite7: "",
      delEscnario4Limite8: "",
      delEscnario4Limite9: "",
      delEscnario4Limite10: "",
      delEscnario4Limite11: "",
      delEscnario4Limite12: "",
      //5
      delEscnario5Limite1: "",
      delEscnario5Limite2: "",
      delEscnario5Limite3: "",
      delEscnario5Limite4: "",
      delEscnario5Limite5: "",
      delEscnario5Limite6: "",
      delEscnario5Limite7: "",
      delEscnario5Limite8: "",
      delEscnario5Limite9: "",
      delEscnario5Limite10: "",
      delEscnario5Limite11: "",
      delEscnario5Limite12: "",
      //6
      delEscnario6Limite1: "",
      delEscnario6Limite2: "",
      delEscnario6Limite3: "",
      delEscnario6Limite4: "",
      delEscnario6Limite5: "",
      delEscnario6Limite6: "",
      delEscnario6Limite7: "",
      delEscnario6Limite8: "",
      delEscnario6Limite9: "",
      delEscnario6Limite10: "",
      delEscnario6Limite11: "",
      delEscnario6Limite12: "",
      //7
      delEscnario7Limite1: "",
      delEscnario7Limite2: "",
      delEscnario7Limite3: "",
      delEscnario7Limite4: "",
      delEscnario7Limite5: "",
      delEscnario7Limite6: "",
      delEscnario7Limite7: "",
      delEscnario7Limite8: "",
      delEscnario7Limite9: "",
      delEscnario7Limite10: "",
      delEscnario7Limite11: "",
      delEscnario7Limite12: "",
      //8
      delEscnario8Limite1: "",
      delEscnario8Limite2: "",
      delEscnario8Limite3: "",
      delEscnario8Limite4: "",
      delEscnario8Limite5: "",
      delEscnario8Limite6: "",
      delEscnario8Limite7: "",
      delEscnario8Limite8: "",
      delEscnario8Limite9: "",
      delEscnario8Limite10: "",
      delEscnario8Limite11: "",
      delEscnario8Limite12: "",
      //MEJORAS
      delEscnario0Mejoras1: "",
      delEscnario0Mejoras2: "",
      delEscnario0Mejoras3: "",
      delEscnario0Mejoras4: "",
      delEscnario0Mejoras5: "",
      delEscnario0Mejoras6: "",
      delEscnario0Mejoras7: "",
      delEscnario0Mejoras8: "",
      delEscnario0Mejoras9: "",
      delEscnario0Mejoras10: "",
      delEscnario0Mejoras11: "",
      delEscnario0Mejoras12: "",
      //1
      delEscnario1Mejoras1: "",
      delEscnario1Mejoras2: "",
      delEscnario1Mejoras3: "",
      delEscnario1Mejoras4: "",
      delEscnario1Mejoras5: "",
      delEscnario1Mejoras6: "",
      delEscnario1Mejoras7: "",
      delEscnario1Mejoras8: "",
      delEscnario1Mejoras9: "",
      delEscnario1Mejoras10: "",
      delEscnario1Mejoras11: "",
      delEscnario1Mejoras12: "",
      //2
      delEscnario2Mejoras1: "",
      delEscnario2Mejoras2: "",
      delEscnario2Mejoras3: "",
      delEscnario2Mejoras4: "",
      delEscnario2Mejoras5: "",
      delEscnario2Mejoras6: "",
      delEscnario2Mejoras7: "",
      delEscnario2Mejoras8: "",
      delEscnario2Mejoras9: "",
      delEscnario2Mejoras10: "",
      delEscnario2Mejoras11: "",
      delEscnario2Mejoras12: "",
      //3
      delEscnario3Mejoras1: "",
      delEscnario3Mejoras2: "",
      delEscnario3Mejoras3: "",
      delEscnario3Mejoras4: "",
      delEscnario3Mejoras5: "",
      delEscnario3Mejoras6: "",
      delEscnario3Mejoras7: "",
      delEscnario3Mejoras8: "",
      delEscnario3Mejoras9: "",
      delEscnario3Mejoras10: "",
      delEscnario3Mejoras11: "",
      delEscnario3Mejoras12: "",
      //4
      delEscnario4Mejoras1: "",
      delEscnario4Mejoras2: "",
      delEscnario4Mejoras3: "",
      delEscnario4Mejoras4: "",
      delEscnario4Mejoras5: "",
      delEscnario4Mejoras6: "",
      delEscnario4Mejoras7: "",
      delEscnario4Mejoras8: "",
      delEscnario4Mejoras9: "",
      delEscnario4Mejoras10: "",
      delEscnario4Mejoras11: "",
      delEscnario4Mejoras12: "",
      //5
      delEscnario5Mejoras1: "",
      delEscnario5Mejoras2: "",
      delEscnario5Mejoras3: "",
      delEscnario5Mejoras4: "",
      delEscnario5Mejoras5: "",
      delEscnario5Mejoras6: "",
      delEscnario5Mejoras7: "",
      delEscnario5Mejoras8: "",
      delEscnario5Mejoras9: "",
      delEscnario5Mejoras10: "",
      delEscnario5Mejoras11: "",
      delEscnario5Mejoras12: "",
      //6
      delEscnario6Mejoras1: "",
      delEscnario6Mejoras2: "",
      delEscnario6Mejoras3: "",
      delEscnario6Mejoras4: "",
      delEscnario6Mejoras5: "",
      delEscnario6Mejoras6: "",
      delEscnario6Mejoras7: "",
      delEscnario6Mejoras8: "",
      delEscnario6Mejoras9: "",
      delEscnario6Mejoras10: "",
      delEscnario6Mejoras11: "",
      delEscnario6Mejoras12: "",
      //7
      delEscnario7Mejoras1: "",
      delEscnario7Mejoras2: "",
      delEscnario7Mejoras3: "",
      delEscnario7Mejoras4: "",
      delEscnario7Mejoras5: "",
      delEscnario7Mejoras6: "",
      delEscnario7Mejoras7: "",
      delEscnario7Mejoras8: "",
      delEscnario7Mejoras9: "",
      delEscnario7Mejoras10: "",
      delEscnario7Mejoras11: "",
      delEscnario7Mejoras12: "",
      //8
      delEscnario8Mejoras1: "",
      delEscnario8Mejoras2: "",
      delEscnario8Mejoras3: "",
      delEscnario8Mejoras4: "",
      delEscnario8Mejoras5: "",
      delEscnario8Mejoras6: "",
      delEscnario8Mejoras7: "",
      delEscnario8Mejoras8: "",
      delEscnario8Mejoras9: "",
      delEscnario8Mejoras10: "",
      delEscnario8Mejoras11: "",
      delEscnario8Mejoras12: "",
      //DOBLE GARANTIA
      delEscnario0DobleGarantia1: "",
      delEscnario0DobleGarantia2: "",
      delEscnario0DobleGarantia3: "",
      delEscnario0DobleGarantia4: "",
      delEscnario0DobleGarantia5: "",
      delEscnario0DobleGarantia6: "",
      delEscnario0DobleGarantia7: "",
      delEscnario0DobleGarantia8: "",
      delEscnario0DobleGarantia9: "",
      delEscnario0DobleGarantia10: "",
      delEscnario0DobleGarantia11: "",
      delEscnario0DobleGarantia12: "",
      //1
      delEscnario1DobleGarantia1: "",
      delEscnario1DobleGarantia2: "",
      delEscnario1DobleGarantia3: "",
      delEscnario1DobleGarantia4: "",
      delEscnario1DobleGarantia5: "",
      delEscnario1DobleGarantia6: "",
      delEscnario1DobleGarantia7: "",
      delEscnario1DobleGarantia8: "",
      delEscnario1DobleGarantia9: "",
      delEscnario1DobleGarantia10: "",
      delEscnario1DobleGarantia11: "",
      delEscnario1DobleGarantia12: "",
      //2
      delEscnario2DobleGarantia1: "",
      delEscnario2DobleGarantia2: "",
      delEscnario2DobleGarantia3: "",
      delEscnario2DobleGarantia4: "",
      delEscnario2DobleGarantia5: "",
      delEscnario2DobleGarantia6: "",
      delEscnario2DobleGarantia7: "",
      delEscnario2DobleGarantia8: "",
      delEscnario2DobleGarantia9: "",
      delEscnario2DobleGarantia10: "",
      delEscnario2DobleGarantia11: "",
      delEscnario2DobleGarantia12: "",
      //3
      delEscnario3DobleGarantia1: "",
      delEscnario3DobleGarantia2: "",
      delEscnario3DobleGarantia3: "",
      delEscnario3DobleGarantia4: "",
      delEscnario3DobleGarantia5: "",
      delEscnario3DobleGarantia6: "",
      delEscnario3DobleGarantia7: "",
      delEscnario3DobleGarantia8: "",
      delEscnario3DobleGarantia9: "",
      delEscnario3DobleGarantia10: "",
      delEscnario3DobleGarantia11: "",
      delEscnario3DobleGarantia12: "",
      //4
      delEscnario4DobleGarantia1: "",
      delEscnario4DobleGarantia2: "",
      delEscnario4DobleGarantia3: "",
      delEscnario4DobleGarantia4: "",
      delEscnario4DobleGarantia5: "",
      delEscnario4DobleGarantia6: "",
      delEscnario4DobleGarantia7: "",
      delEscnario4DobleGarantia8: "",
      delEscnario4DobleGarantia9: "",
      delEscnario4DobleGarantia10: "",
      delEscnario4DobleGarantia11: "",
      delEscnario4DobleGarantia12: "",
      //5
      delEscnario5DobleGarantia1: "",
      delEscnario5DobleGarantia2: "",
      delEscnario5DobleGarantia3: "",
      delEscnario5DobleGarantia4: "",
      delEscnario5DobleGarantia5: "",
      delEscnario5DobleGarantia6: "",
      delEscnario5DobleGarantia7: "",
      delEscnario5DobleGarantia8: "",
      delEscnario5DobleGarantia9: "",
      delEscnario5DobleGarantia10: "",
      delEscnario5DobleGarantia11: "",
      delEscnario5DobleGarantia12: "",
      //6
      delEscnario6DobleGarantia1: "",
      delEscnario6DobleGarantia2: "",
      delEscnario6DobleGarantia3: "",
      delEscnario6DobleGarantia4: "",
      delEscnario6DobleGarantia5: "",
      delEscnario6DobleGarantia6: "",
      delEscnario6DobleGarantia7: "",
      delEscnario6DobleGarantia8: "",
      delEscnario6DobleGarantia9: "",
      delEscnario6DobleGarantia10: "",
      delEscnario6DobleGarantia11: "",
      delEscnario6DobleGarantia12: "",
      //7
      delEscnario7DobleGarantia1: "",
      delEscnario7DobleGarantia2: "",
      delEscnario7DobleGarantia3: "",
      delEscnario7DobleGarantia4: "",
      delEscnario7DobleGarantia5: "",
      delEscnario7DobleGarantia6: "",
      delEscnario7DobleGarantia7: "",
      delEscnario7DobleGarantia8: "",
      delEscnario7DobleGarantia9: "",
      delEscnario7DobleGarantia10: "",
      delEscnario7DobleGarantia11: "",
      delEscnario7DobleGarantia12: "",
      //8
      delEscnario8DobleGarantia1: "",
      delEscnario8DobleGarantia2: "",
      delEscnario8DobleGarantia3: "",
      delEscnario8DobleGarantia4: "",
      delEscnario8DobleGarantia5: "",
      delEscnario8DobleGarantia6: "",
      delEscnario8DobleGarantia7: "",
      delEscnario8DobleGarantia8: "",
      delEscnario8DobleGarantia9: "",
      delEscnario8DobleGarantia10: "",
      delEscnario8DobleGarantia11: "",
      delEscnario8DobleGarantia12: "",
      //DESDE (DESDE)  NUMERO PORCENTAJE
      delEscnario0: "",
      delEscnario1: "",
      delEscnario2: "",
      delEscnario3: "",
      delEscnario4: "",
      delEscnario5: "",
      delEscnario6: "",
      delEscnario7: "",
      delEscnario8: "",
      //HASTA (AL) X NUMERO PORCENTAJE
      alEscnario0: "",
      alEscnario1: "",
      alEscnario2: "",
      alEscnario3: "",
      alEscnario4: "",
      alEscnario5: "",
      alEscnario6: "",
      alEscnario7: "",
      alEscnario8: "",
      //LIMITE DE FINANCIAZION CARTEL POR ESCENARIO

      // ANTIGUEDAD DE CONTRATO MENOR A (TIEMPO)
      // ESCENARIO 0
      delEscnario0meses1: "",
      delEscnario0meses2: "",
      delEscnario0meses3: "",
      delEscnario0meses4: "",
      delEscnario0meses5: "",
      delEscnario0meses6: "",
      delEscnario0meses7: "",
      delEscnario0meses8: "",
      delEscnario0meses9: "",
      delEscnario0meses10: "",
      delEscnario0meses11: "",
      delEscnario0meses12: "",
      //ESCENARIO 1
      delEscnario1meses1: "",
      delEscnario1meses2: "",
      delEscnario1meses3: "",
      delEscnario1meses4: "",
      delEscnario1meses5: "",
      delEscnario1meses6: "",
      delEscnario1meses7: "",
      delEscnario1meses8: "",
      delEscnario1meses9: "",
      delEscnario1meses10: "",
      delEscnario1meses11: "",
      delEscnario1meses12: "",
      //ESCENARIO 2
      delEscnario2meses1: "",
      delEscnario2meses2: "",
      delEscnario2meses3: "",
      delEscnario2meses4: "",
      delEscnario2meses5: "",
      delEscnario2meses6: "",
      delEscnario2meses7: "",
      delEscnario2meses8: "",
      delEscnario2meses9: "",
      delEscnario2meses10: "",
      delEscnario2meses11: "",
      delEscnario2meses12: "",
      //ESCENARIO 3
      delEscnario3meses1: "",
      delEscnario3meses2: "",
      delEscnario3meses3: "",
      delEscnario3meses4: "",
      delEscnario3meses5: "",
      delEscnario3meses6: "",
      delEscnario3meses7: "",
      delEscnario3meses8: "",
      delEscnario3meses9: "",
      delEscnario3meses10: "",
      delEscnario3meses11: "",
      delEscnario3meses12: "",
      //ESCENARIO 4
      delEscnario4meses1: "",
      delEscnario4meses2: "",
      delEscnario4meses3: "",
      delEscnario4meses4: "",
      delEscnario4meses5: "",
      delEscnario4meses6: "",
      delEscnario4meses7: "",
      delEscnario4meses8: "",
      delEscnario4meses9: "",
      delEscnario4meses10: "",
      delEscnario4meses11: "",
      delEscnario4meses12: "",
      //ESCENARIO 5
      delEscnario5meses1: "",
      delEscnario5meses2: "",
      delEscnario5meses3: "",
      delEscnario5meses4: "",
      delEscnario5meses5: "",
      delEscnario5meses6: "",
      delEscnario5meses7: "",
      delEscnario5meses8: "",
      delEscnario5meses9: "",
      delEscnario5meses10: "",
      delEscnario5meses11: "",
      delEscnario5meses12: "",
      //ESCENARIO 6
      delEscnario6meses1: "",
      delEscnario6meses2: "",
      delEscnario6meses3: "",
      delEscnario6meses4: "",
      delEscnario6meses5: "",
      delEscnario6meses6: "",
      delEscnario6meses7: "",
      delEscnario6meses8: "",
      delEscnario6meses9: "",
      delEscnario6meses10: "",
      delEscnario6meses11: "",
      delEscnario6meses12: "",
      //ESCENARIO 7
      delEscnario7meses1: "",
      delEscnario7meses2: "",
      delEscnario7meses3: "",
      delEscnario7meses4: "",
      delEscnario7meses5: "",
      delEscnario7meses6: "",
      delEscnario7meses7: "",
      delEscnario7meses8: "",
      delEscnario7meses9: "",
      delEscnario7meses10: "",
      delEscnario7meses11: "",
      delEscnario7meses12: "",
      //ESCENARIO 8
      delEscnario8meses1: "",
      delEscnario8meses2: "",
      delEscnario8meses3: "",
      delEscnario8meses4: "",
      delEscnario8meses5: "",
      delEscnario8meses6: "",
      delEscnario8meses7: "",
      delEscnario8meses8: "",
      delEscnario8meses9: "",
      delEscnario8meses10: "",
      delEscnario8meses11: "",
      delEscnario8meses12: "",

      // ANTIGUEDAD DE CONTRATO MENOR A (PENA)
      // ESCENARIO 0
      delEscnario0pena1: "",
      delEscnario0pena2: "",
      delEscnario0pena3: "",
      delEscnario0pena4: "",
      delEscnario0pena5: "",
      delEscnario0pena6: "",
      delEscnario0pena7: "",
      delEscnario0pena8: "",
      delEscnario0pena9: "",
      delEscnario0pena10: "",
      delEscnario0pena11: "",
      delEscnario0pena12: "",
      //ESCENARIO 1
      delEscnario1pena1: "",
      delEscnario1pena2: "",
      delEscnario1pena3: "",
      delEscnario1pena4: "",
      delEscnario1pena5: "",
      delEscnario1pena6: "",
      delEscnario1pena7: "",
      delEscnario1pena8: "",
      delEscnario1pena9: "",
      delEscnario1pena10: "",
      delEscnario1pena11: "",
      delEscnario1pena12: "",
      //ESCENARIO 2
      delEscnario2pena1: "",
      delEscnario2pena2: "",
      delEscnario2pena3: "",
      delEscnario2pena4: "",
      delEscnario2pena5: "",
      delEscnario2pena6: "",
      delEscnario2pena7: "",
      delEscnario2pena8: "",
      delEscnario2pena9: "",
      delEscnario2pena10: "",
      delEscnario2pena11: "",
      delEscnario2pena12: "",
      //ESCENARIO 3
      delEscnario3pena1: "",
      delEscnario3pena2: "",
      delEscnario3pena3: "",
      delEscnario3pena4: "",
      delEscnario3pena5: "",
      delEscnario3pena6: "",
      delEscnario3pena7: "",
      delEscnario3pena8: "",
      delEscnario3pena9: "",
      delEscnario3pena10: "",
      delEscnario3pena11: "",
      delEscnario3pena12: "",
      //ESCENARIO 4
      delEscnario4pena1: "",
      delEscnario4pena2: "",
      delEscnario4pena3: "",
      delEscnario4pena4: "",
      delEscnario4pena5: "",
      delEscnario4pena6: "",
      delEscnario4pena7: "",
      delEscnario4pena8: "",
      delEscnario4pena9: "",
      delEscnario4pena10: "",
      delEscnario4pena11: "",
      delEscnario4pena12: "",
      //ESCENARIO 5
      delEscnario5pena1: "",
      delEscnario5pena2: "",
      delEscnario5pena3: "",
      delEscnario5pena4: "",
      delEscnario5pena5: "",
      delEscnario5pena6: "",
      delEscnario5pena7: "",
      delEscnario5pena8: "",
      delEscnario5pena9: "",
      delEscnario5pena10: "",
      delEscnario5pena11: "",
      delEscnario5pena12: "",
      //ESCENARIO 6
      delEscnario6pena1: "",
      delEscnario6pena2: "",
      delEscnario6pena3: "",
      delEscnario6pena4: "",
      delEscnario6pena5: "",
      delEscnario6pena6: "",
      delEscnario6pena7: "",
      delEscnario6pena8: "",
      delEscnario6pena9: "",
      delEscnario6pena10: "",
      delEscnario6pena11: "",
      delEscnario6pena12: "",
      //ESCENARIO 7
      delEscnario7pena1: "",
      delEscnario7pena2: "",
      delEscnario7pena3: "",
      delEscnario7pena4: "",
      delEscnario7pena5: "",
      delEscnario7pena6: "",
      delEscnario7pena7: "",
      delEscnario7pena8: "",
      delEscnario7pena9: "",
      delEscnario7pena10: "",
      delEscnario7pena11: "",
      delEscnario7pena12: "",
      //ESCENARIO 8
      delEscnario8pena1: "",
      delEscnario8pena2: "",
      delEscnario8pena3: "",
      delEscnario8pena4: "",
      delEscnario8pena5: "",
      delEscnario8pena6: "",
      delEscnario8pena7: "",
      delEscnario8pena8: "",
      delEscnario8pena9: "",
      delEscnario8pena10: "",
      delEscnario8pena11: "",
      delEscnario8pena12: "",

      // SEGUNDA ANTIGUEDAD MAYOR IGUAL(TIEMPO)
      // ESCENARIO 0
      delEscnario0antiguedadMeses1: "",
      delEscnario0antiguedadMeses2: "",
      delEscnario0antiguedadMeses3: "",
      delEscnario0antiguedadMeses4: "",
      delEscnario0antiguedadMeses5: "",
      delEscnario0antiguedadMeses6: "",
      delEscnario0antiguedadMeses7: "",
      delEscnario0antiguedadMeses8: "",
      delEscnario0antiguedadMeses9: "",
      delEscnario0antiguedadMeses10: "",
      delEscnario0antiguedadMeses11: "",
      delEscnario0antiguedadMeses12: "",

      // ESCENARIO 1
      delEscnario1antiguedadMeses1: "",
      delEscnario1antiguedadMeses2: "",
      delEscnario1antiguedadMeses3: "",
      delEscnario1antiguedadMeses4: "",
      delEscnario1antiguedadMeses5: "",
      delEscnario1antiguedadMeses6: "",
      delEscnario1antiguedadMeses7: "",
      delEscnario1antiguedadMeses8: "",
      delEscnario1antiguedadMeses9: "",
      delEscnario1antiguedadMeses10: "",
      delEscnario1antiguedadMeses11: "",
      delEscnario1antiguedadMeses12: "",

      // ESCENARIO 2
      delEscnario2antiguedadMeses1: "",
      delEscnario2antiguedadMeses2: "",
      delEscnario2antiguedadMeses3: "",
      delEscnario2antiguedadMeses4: "",
      delEscnario2antiguedadMeses5: "",
      delEscnario2antiguedadMeses6: "",
      delEscnario2antiguedadMeses7: "",
      delEscnario2antiguedadMeses8: "",
      delEscnario2antiguedadMeses9: "",
      delEscnario2antiguedadMeses10: "",
      delEscnario2antiguedadMeses11: "",
      delEscnario2antiguedadMeses12: "",

      // ESCENARIO 3
      delEscnario3antiguedadMeses1: "",
      delEscnario3antiguedadMeses2: "",
      delEscnario3antiguedadMeses3: "",
      delEscnario3antiguedadMeses4: "",
      delEscnario3antiguedadMeses5: "",
      delEscnario3antiguedadMeses6: "",
      delEscnario3antiguedadMeses7: "",
      delEscnario3antiguedadMeses8: "",
      delEscnario3antiguedadMeses9: "",
      delEscnario3antiguedadMeses10: "",
      delEscnario3antiguedadMeses11: "",
      delEscnario3antiguedadMeses12: "",

      // ESCENARIO 4
      delEscnario4antiguedadMeses1: "",
      delEscnario4antiguedadMeses2: "",
      delEscnario4antiguedadMeses3: "",
      delEscnario4antiguedadMeses4: "",
      delEscnario4antiguedadMeses5: "",
      delEscnario4antiguedadMeses6: "",
      delEscnario4antiguedadMeses7: "",
      delEscnario4antiguedadMeses8: "",
      delEscnario4antiguedadMeses9: "",
      delEscnario4antiguedadMeses10: "",
      delEscnario4antiguedadMeses11: "",
      delEscnario4antiguedadMeses12: "",

      // ESCENARIO 5
      delEscnario5antiguedadMeses1: "",
      delEscnario5antiguedadMeses2: "",
      delEscnario5antiguedadMeses3: "",
      delEscnario5antiguedadMeses4: "",
      delEscnario5antiguedadMeses5: "",
      delEscnario5antiguedadMeses6: "",
      delEscnario5antiguedadMeses7: "",
      delEscnario5antiguedadMeses8: "",
      delEscnario5antiguedadMeses9: "",
      delEscnario5antiguedadMeses10: "",
      delEscnario5antiguedadMeses11: "",
      delEscnario5antiguedadMeses12: "",

      // ESCENARIO 6
      delEscnario6antiguedadMeses1: "",
      delEscnario6antiguedadMeses2: "",
      delEscnario6antiguedadMeses3: "",
      delEscnario6antiguedadMeses4: "",
      delEscnario6antiguedadMeses5: "",
      delEscnario6antiguedadMeses6: "",
      delEscnario6antiguedadMeses7: "",
      delEscnario6antiguedadMeses8: "",
      delEscnario6antiguedadMeses9: "",
      delEscnario6antiguedadMeses10: "",
      delEscnario6antiguedadMeses11: "",
      delEscnario6antiguedadMeses12: "",

      // ESCENARIO 7
      delEscnario7antiguedadMeses1: "",
      delEscnario7antiguedadMeses2: "",
      delEscnario7antiguedadMeses3: "",
      delEscnario7antiguedadMeses4: "",
      delEscnario7antiguedadMeses5: "",
      delEscnario7antiguedadMeses6: "",
      delEscnario7antiguedadMeses7: "",
      delEscnario7antiguedadMeses8: "",
      delEscnario7antiguedadMeses9: "",
      delEscnario7antiguedadMeses10: "",
      delEscnario7antiguedadMeses11: "",
      delEscnario7antiguedadMeses12: "",

      // ESCENARIO 8
      delEscnario8antiguedadMeses1: "",
      delEscnario8antiguedadMeses2: "",
      delEscnario8antiguedadMeses3: "",
      delEscnario8antiguedadMeses4: "",
      delEscnario8antiguedadMeses5: "",
      delEscnario8antiguedadMeses6: "",
      delEscnario8antiguedadMeses7: "",
      delEscnario8antiguedadMeses8: "",
      delEscnario8antiguedadMeses9: "",
      delEscnario8antiguedadMeses10: "",
      delEscnario8antiguedadMeses11: "",
      delEscnario8antiguedadMeses12: "",

      // SEGUNDA ANTIGUEDAD MAYOR IGUAL(PENA)
      // ESCENARIO 0
      delEscnario0antiguedadPena1: "",
      delEscnario0antiguedadPena2: "",
      delEscnario0antiguedadPena3: "",
      delEscnario0antiguedadPena4: "",
      delEscnario0antiguedadPena5: "",
      delEscnario0antiguedadPena6: "",
      delEscnario0antiguedadPena7: "",
      delEscnario0antiguedadPena8: "",
      delEscnario0antiguedadPena9: "",
      delEscnario0antiguedadPena10: "",
      delEscnario0antiguedadPena11: "",
      delEscnario0antiguedadPena12: "",

      // //ESCENARIO 1
      delEscnario1antiguedadPena1: "",
      delEscnario1antiguedadPena2: "",
      delEscnario1antiguedadPena3: "",
      delEscnario1antiguedadPena4: "",
      delEscnario1antiguedadPena5: "",
      delEscnario1antiguedadPena6: "",
      delEscnario1antiguedadPena7: "",
      delEscnario1antiguedadPena8: "",
      delEscnario1antiguedadPena9: "",
      delEscnario1antiguedadPena10: "",
      delEscnario1antiguedadPena11: "",
      delEscnario1antiguedadPena12: "",

      // //ESCENARIO 2
      delEscnario2antiguedadPena1: "",
      delEscnario2antiguedadPena2: "",
      delEscnario2antiguedadPena3: "",
      delEscnario2antiguedadPena4: "",
      delEscnario2antiguedadPena5: "",
      delEscnario2antiguedadPena6: "",
      delEscnario2antiguedadPena7: "",
      delEscnario2antiguedadPena8: "",
      delEscnario2antiguedadPena9: "",
      delEscnario2antiguedadPena10: "",
      delEscnario2antiguedadPena11: "",
      delEscnario2antiguedadPena12: "",

      // //ESCENARIO 3
      delEscnario3antiguedadPena1: "",
      delEscnario3antiguedadPena2: "",
      delEscnario3antiguedadPena3: "",
      delEscnario3antiguedadPena4: "",
      delEscnario3antiguedadPena5: "",
      delEscnario3antiguedadPena6: "",
      delEscnario3antiguedadPena7: "",
      delEscnario3antiguedadPena8: "",
      delEscnario3antiguedadPena9: "",
      delEscnario3antiguedadPena10: "",
      delEscnario3antiguedadPena11: "",
      delEscnario3antiguedadPena12: "",

      // //ESCENARIO 4
      delEscnario4antiguedadPena1: "",
      delEscnario4antiguedadPena2: "",
      delEscnario4antiguedadPena3: "",
      delEscnario4antiguedadPena4: "",
      delEscnario4antiguedadPena5: "",
      delEscnario4antiguedadPena6: "",
      delEscnario4antiguedadPena7: "",
      delEscnario4antiguedadPena8: "",
      delEscnario4antiguedadPena9: "",
      delEscnario4antiguedadPena10: "",
      delEscnario4antiguedadPena11: "",
      delEscnario4antiguedadPena12: "",

      // //ESCENARIO 5
      delEscnario5antiguedadPena1: "",
      delEscnario5antiguedadPena2: "",
      delEscnario5antiguedadPena3: "",
      delEscnario5antiguedadPena4: "",
      delEscnario5antiguedadPena5: "",
      delEscnario5antiguedadPena6: "",
      delEscnario5antiguedadPena7: "",
      delEscnario5antiguedadPena8: "",
      delEscnario5antiguedadPena9: "",
      delEscnario5antiguedadPena10: "",
      delEscnario5antiguedadPena11: "",
      delEscnario5antiguedadPena12: "",

      // //ESCENARIO 6
      delEscnario6antiguedadPena1: "",
      delEscnario6antiguedadPena2: "",
      delEscnario6antiguedadPena3: "",
      delEscnario6antiguedadPena4: "",
      delEscnario6antiguedadPena5: "",
      delEscnario6antiguedadPena6: "",
      delEscnario6antiguedadPena7: "",
      delEscnario6antiguedadPena8: "",
      delEscnario6antiguedadPena9: "",
      delEscnario6antiguedadPena10: "",
      delEscnario6antiguedadPena11: "",
      delEscnario6antiguedadPena12: "",

      // //ESCENARIO 7
      delEscnario7antiguedadPena1: "",
      delEscnario7antiguedadPena2: "",
      delEscnario7antiguedadPena3: "",
      delEscnario7antiguedadPena4: "",
      delEscnario7antiguedadPena5: "",
      delEscnario7antiguedadPena6: "",
      delEscnario7antiguedadPena7: "",
      delEscnario7antiguedadPena8: "",
      delEscnario7antiguedadPena9: "",
      delEscnario7antiguedadPena10: "",
      delEscnario7antiguedadPena11: "",
      delEscnario7antiguedadPena12: "",

      // //ESCENARIO 8
      delEscnario8antiguedadPena1: "",
      delEscnario8antiguedadPena2: "",
      delEscnario8antiguedadPena3: "",
      delEscnario8antiguedadPena4: "",
      delEscnario8antiguedadPena5: "",
      delEscnario8antiguedadPena6: "",
      delEscnario8antiguedadPena7: "",
      delEscnario8antiguedadPena8: "",
      delEscnario8antiguedadPena9: "",
      delEscnario8antiguedadPena10: "",
      delEscnario8antiguedadPena11: "",
      delEscnario8antiguedadPena12: "",

      //ENDEUDAMIENTO PORCETAJE LIMITE
      // ESCENARIO 0
      delEscnario0porcentajeEndeudamiento1: "",
      delEscnario0porcentajeEndeudamiento2: "",
      delEscnario0porcentajeEndeudamiento3: "",
      delEscnario0porcentajeEndeudamiento4: "",
      delEscnario0porcentajeEndeudamiento5: "",
      delEscnario0porcentajeEndeudamiento6: "",
      delEscnario0porcentajeEndeudamiento7: "",
      delEscnario0porcentajeEndeudamiento8: "",
      delEscnario0porcentajeEndeudamiento9: "",
      delEscnario0porcentajeEndeudamiento10: "",
      delEscnario0porcentajeEndeudamiento11: "",
      delEscnario0porcentajeEndeudamiento12: "",

      //ESCENARIO 1
      delEscnario1porcentajeEndeudamiento1: "",
      delEscnario1porcentajeEndeudamiento2: "",
      delEscnario1porcentajeEndeudamiento3: "",
      delEscnario1porcentajeEndeudamiento4: "",
      delEscnario1porcentajeEndeudamiento5: "",
      delEscnario1porcentajeEndeudamiento6: "",
      delEscnario1porcentajeEndeudamiento7: "",
      delEscnario1porcentajeEndeudamiento8: "",
      delEscnario1porcentajeEndeudamiento9: "",
      delEscnario1porcentajeEndeudamiento10: "",
      delEscnario1porcentajeEndeudamiento11: "",
      delEscnario1porcentajeEndeudamiento12: "",

      //ESCENARIO 2
      delEscnario2porcentajeEndeudamiento1: "",
      delEscnario2porcentajeEndeudamiento2: "",
      delEscnario2porcentajeEndeudamiento3: "",
      delEscnario2porcentajeEndeudamiento4: "",
      delEscnario2porcentajeEndeudamiento5: "",
      delEscnario2porcentajeEndeudamiento6: "",
      delEscnario2porcentajeEndeudamiento7: "",
      delEscnario2porcentajeEndeudamiento8: "",
      delEscnario2porcentajeEndeudamiento9: "",
      delEscnario2porcentajeEndeudamiento10: "",
      delEscnario2porcentajeEndeudamiento11: "",
      delEscnario2porcentajeEndeudamiento12: "",

      //ESCENARIO 3
      delEscnario3porcentajeEndeudamiento1: "",
      delEscnario3porcentajeEndeudamiento2: "",
      delEscnario3porcentajeEndeudamiento3: "",
      delEscnario3porcentajeEndeudamiento4: "",
      delEscnario3porcentajeEndeudamiento5: "",
      delEscnario3porcentajeEndeudamiento6: "",
      delEscnario3porcentajeEndeudamiento7: "",
      delEscnario3porcentajeEndeudamiento8: "",
      delEscnario3porcentajeEndeudamiento9: "",
      delEscnario3porcentajeEndeudamiento10: "",
      delEscnario3porcentajeEndeudamiento11: "",
      delEscnario3porcentajeEndeudamiento12: "",

      //ESCENARIO 4
      delEscnario4porcentajeEndeudamiento1: "",
      delEscnario4porcentajeEndeudamiento2: "",
      delEscnario4porcentajeEndeudamiento3: "",
      delEscnario4porcentajeEndeudamiento4: "",
      delEscnario4porcentajeEndeudamiento5: "",
      delEscnario4porcentajeEndeudamiento6: "",
      delEscnario4porcentajeEndeudamiento7: "",
      delEscnario4porcentajeEndeudamiento8: "",
      delEscnario4porcentajeEndeudamiento9: "",
      delEscnario4porcentajeEndeudamiento10: "",
      delEscnario4porcentajeEndeudamiento11: "",
      delEscnario4porcentajeEndeudamiento12: "",

      //ESCENARIO 5
      delEscnario5porcentajeEndeudamiento1: "",
      delEscnario5porcentajeEndeudamiento2: "",
      delEscnario5porcentajeEndeudamiento3: "",
      delEscnario5porcentajeEndeudamiento4: "",
      delEscnario5porcentajeEndeudamiento5: "",
      delEscnario5porcentajeEndeudamiento6: "",
      delEscnario5porcentajeEndeudamiento7: "",
      delEscnario5porcentajeEndeudamiento8: "",
      delEscnario5porcentajeEndeudamiento9: "",
      delEscnario5porcentajeEndeudamiento10: "",
      delEscnario5porcentajeEndeudamiento11: "",
      delEscnario5porcentajeEndeudamiento12: "",

      //ESCENARIO 6
      delEscnario6porcentajeEndeudamiento1: "",
      delEscnario6porcentajeEndeudamiento2: "",
      delEscnario6porcentajeEndeudamiento3: "",
      delEscnario6porcentajeEndeudamiento4: "",
      delEscnario6porcentajeEndeudamiento5: "",
      delEscnario6porcentajeEndeudamiento6: "",
      delEscnario6porcentajeEndeudamiento7: "",
      delEscnario6porcentajeEndeudamiento8: "",
      delEscnario6porcentajeEndeudamiento9: "",
      delEscnario6porcentajeEndeudamiento10: "",
      delEscnario6porcentajeEndeudamiento11: "",
      delEscnario6porcentajeEndeudamiento12: "",

      //ESCENARIO 7
      delEscnario7porcentajeEndeudamiento1: "",
      delEscnario7porcentajeEndeudamiento2: "",
      delEscnario7porcentajeEndeudamiento3: "",
      delEscnario7porcentajeEndeudamiento4: "",
      delEscnario7porcentajeEndeudamiento5: "",
      delEscnario7porcentajeEndeudamiento6: "",
      delEscnario7porcentajeEndeudamiento7: "",
      delEscnario7porcentajeEndeudamiento8: "",
      delEscnario7porcentajeEndeudamiento9: "",
      delEscnario7porcentajeEndeudamiento10: "",
      delEscnario7porcentajeEndeudamiento11: "",
      delEscnario7porcentajeEndeudamiento12: "",

      //ESCENARIO 8
      delEscnario8porcentajeEndeudamiento1: "",
      delEscnario8porcentajeEndeudamiento2: "",
      delEscnario8porcentajeEndeudamiento3: "",
      delEscnario8porcentajeEndeudamiento4: "",
      delEscnario8porcentajeEndeudamiento5: "",
      delEscnario8porcentajeEndeudamiento6: "",
      delEscnario8porcentajeEndeudamiento7: "",
      delEscnario8porcentajeEndeudamiento8: "",
      delEscnario8porcentajeEndeudamiento9: "",
      delEscnario8porcentajeEndeudamiento10: "",
      delEscnario8porcentajeEndeudamiento11: "",
      delEscnario8porcentajeEndeudamiento12: "",

      // //ENDEUDAMIENTO PORCENTAJE PENA
      //ESCENARIO 0
      delEscnario0penaEndeudamiento1: "",
      delEscnario0penaEndeudamiento2: "",
      delEscnario0penaEndeudamiento3: "",
      delEscnario0penaEndeudamiento4: "",
      delEscnario0penaEndeudamiento5: "",
      delEscnario0penaEndeudamiento6: "",
      delEscnario0penaEndeudamiento7: "",
      delEscnario0penaEndeudamiento8: "",
      delEscnario0penaEndeudamiento9: "",
      delEscnario0penaEndeudamiento10: "",
      delEscnario0penaEndeudamiento11: "",
      delEscnario0penaEndeudamiento12: "",

      //ESCENARIO 1
      delEscnario1penaEndeudamiento1: "",
      delEscnario1penaEndeudamiento2: "",
      delEscnario1penaEndeudamiento3: "",
      delEscnario1penaEndeudamiento4: "",
      delEscnario1penaEndeudamiento5: "",
      delEscnario1penaEndeudamiento6: "",
      delEscnario1penaEndeudamiento7: "",
      delEscnario1penaEndeudamiento8: "",
      delEscnario1penaEndeudamiento9: "",
      delEscnario1penaEndeudamiento10: "",
      delEscnario1penaEndeudamiento11: "",
      delEscnario1penaEndeudamiento12: "",

      //ESCENARIO 2
      delEscnario2penaEndeudamiento1: "",
      delEscnario2penaEndeudamiento2: "",
      delEscnario2penaEndeudamiento3: "",
      delEscnario2penaEndeudamiento4: "",
      delEscnario2penaEndeudamiento5: "",
      delEscnario2penaEndeudamiento6: "",
      delEscnario2penaEndeudamiento7: "",
      delEscnario2penaEndeudamiento8: "",
      delEscnario2penaEndeudamiento9: "",
      delEscnario2penaEndeudamiento10: "",
      delEscnario2penaEndeudamiento11: "",
      delEscnario2penaEndeudamiento12: "",

      //ESCENARIO 3
      delEscnario3penaEndeudamiento1: "",
      delEscnario3penaEndeudamiento2: "",
      delEscnario3penaEndeudamiento3: "",
      delEscnario3penaEndeudamiento4: "",
      delEscnario3penaEndeudamiento5: "",
      delEscnario3penaEndeudamiento6: "",
      delEscnario3penaEndeudamiento7: "",
      delEscnario3penaEndeudamiento8: "",
      delEscnario3penaEndeudamiento9: "",
      delEscnario3penaEndeudamiento10: "",
      delEscnario3penaEndeudamiento11: "",
      delEscnario3penaEndeudamiento12: "",

      //ESCENARIO 4
      delEscnario4penaEndeudamiento1: "",
      delEscnario4penaEndeudamiento2: "",
      delEscnario4penaEndeudamiento3: "",
      delEscnario4penaEndeudamiento4: "",
      delEscnario4penaEndeudamiento5: "",
      delEscnario4penaEndeudamiento6: "",
      delEscnario4penaEndeudamiento7: "",
      delEscnario4penaEndeudamiento8: "",
      delEscnario4penaEndeudamiento9: "",
      delEscnario4penaEndeudamiento10: "",
      delEscnario4penaEndeudamiento11: "",
      delEscnario4penaEndeudamiento12: "",

      //ESCENARIO 5
      delEscnario5penaEndeudamiento1: "",
      delEscnario5penaEndeudamiento2: "",
      delEscnario5penaEndeudamiento3: "",
      delEscnario5penaEndeudamiento4: "",
      delEscnario5penaEndeudamiento5: "",
      delEscnario5penaEndeudamiento6: "",
      delEscnario5penaEndeudamiento7: "",
      delEscnario5penaEndeudamiento8: "",
      delEscnario5penaEndeudamiento9: "",
      delEscnario5penaEndeudamiento10: "",
      delEscnario5penaEndeudamiento11: "",
      delEscnario5penaEndeudamiento12: "",

      //ESCENARIO 6
      delEscnario6penaEndeudamiento1: "",
      delEscnario6penaEndeudamiento2: "",
      delEscnario6penaEndeudamiento3: "",
      delEscnario6penaEndeudamiento4: "",
      delEscnario6penaEndeudamiento5: "",
      delEscnario6penaEndeudamiento6: "",
      delEscnario6penaEndeudamiento7: "",
      delEscnario6penaEndeudamiento8: "",
      delEscnario6penaEndeudamiento9: "",
      delEscnario6penaEndeudamiento10: "",
      delEscnario6penaEndeudamiento11: "",
      delEscnario6penaEndeudamiento12: "",

      //ESCENARIO 7
      delEscnario7penaEndeudamiento1: "",
      delEscnario7penaEndeudamiento2: "",
      delEscnario7penaEndeudamiento3: "",
      delEscnario7penaEndeudamiento4: "",
      delEscnario7penaEndeudamiento5: "",
      delEscnario7penaEndeudamiento6: "",
      delEscnario7penaEndeudamiento7: "",
      delEscnario7penaEndeudamiento8: "",
      delEscnario7penaEndeudamiento9: "",
      delEscnario7penaEndeudamiento10: "",
      delEscnario7penaEndeudamiento11: "",
      delEscnario7penaEndeudamiento12: "",

      //ESCENARIO 8
      delEscnario8penaEndeudamiento1: "",
      delEscnario8penaEndeudamiento2: "",
      delEscnario8penaEndeudamiento3: "",
      delEscnario8penaEndeudamiento4: "",
      delEscnario8penaEndeudamiento5: "",
      delEscnario8penaEndeudamiento6: "",
      delEscnario8penaEndeudamiento7: "",
      delEscnario8penaEndeudamiento8: "",
      delEscnario8penaEndeudamiento9: "",
      delEscnario8penaEndeudamiento10: "",
      delEscnario8penaEndeudamiento11: "",
      delEscnario8penaEndeudamiento12: "",
    });
    setModificar(false);
    setestadoCrearCalculadora([
      {
        name: true,
      },
      {
        name: false,
      },
      {
        name: false,
      },
    ]);
    setOptCalcus(0);
  }
  async function mod() {
    setestadoCrearCalculadora([
      {
        name: false,
      },
      {
        name: true,
      },
      {
        name: false,
      },
    ]);
  }
  async function mostrarFormDelete() {
    await mod();

    let calculadoras = [];
    const querySnapshot = await getDocs(collection(db, "calculadoras"));
    querySnapshot.forEach((doc) => {
      let calculadora = {
        id: doc.data().id,
        params: doc.data().datosNuevaCalculadora,
      };
      calculadoras.push(calculadora);
    });
    setcCalcusExistentes(calculadoras);
  }

  function handleModificateChange(e) {
    setTarget(e.target.value);
  }

  async function eliminarCalcu() {
    await exitoso("borrada");
    await deleteDoc(doc(db, "calculadoras", target));
    mostrarFormDelete();
  }

  async function exitoso(nombre) {
    Swal.fire({
      title: `Calculadora ${nombre} correctamente!!`,
      icon: "success",
      confirmButtonColor: "#8FCD60",
    });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setDate(userLog);
        async function buscarUser() {
          const docRef = doc(db, "users", userLog.uid);
          const docSnap = await getDoc(docRef);
          switch (docSnap.data().rol) {
            case "adminFirmax":
              navigate("/firmax/Crear-calculadora");
              break;
            case "inmobiliaria":
              navigate("/inmobiliaria");
              break;
            default:
              break;
          }
        }
        buscarUser();
      } else {
        setDate(null);
        navigate("/login");
      }
    });
    if (correct) {
      setTimeout(() => {
        setCorrect(false);
      }, 3000);
    }
    if (flag) {
      async function traerContratosBd() {
        let pushing = [];
        const querySnapshot = await getDocs(collection(db, "tipoDeContrato"));
        querySnapshot.forEach((doc) => {
          let contrato = {
            id: doc.data().id,
            nombre: doc.data().nombre,
          };
          pushing.push(contrato);
        });
        setContratoBD(pushing);
        setFlag(false);
      }
      traerContratosBd();
    }
  }, [contratoBD, correct, flag, navigate]);

  const [mostrarContratos, setMostrarContactos] = useState(false);

  async function cargarContratos(e) {
    e.preventDefault();
    setMostrarContactos(!mostrarContratos);
  }

  async function calc() {
    setestadoCrearCalculadora([
      {
        name: false,
      },
      {
        name: false,
      },
      {
        name: true,
      },
    ]);
    const querySnapshotDos = await getDocs(collection(db, "calculadoras"));
    let calculadoras = [];
    querySnapshotDos.forEach((docDos) => {
      let calculadora = {
        bonificacion: docDos.data().datosNuevaCalculadora.bonificacion,
        mostrarHipotecaFija:
          docDos.data().datosNuevaCalculadora.mostrarHipotecaFija,
        mostrarHipotecaVariable:
          docDos.data().datosNuevaCalculadora.mostrarHipotecaVariable,
          mostrarHipotecaMixta:
          docDos.data().datosNuevaCalculadora.mostrarHipotecaMixta,
        primerPeriodoVariable:
          docDos.data().datosNuevaCalculadora.primerPeriodoVariable,
        primerPeriodoMixto:
          docDos.data().datosNuevaCalculadora.primerPeriodoMixto,
        edadMaximaTitularMenor:
          docDos.data().datosNuevaCalculadora.edadMaximaTitularMenor,
        hipotecaMinima: docDos.data().datosNuevaCalculadora.hipotecaMinima,
        hipotecaMaxima: docDos.data().datosNuevaCalculadora.hipotecaMaxima,
        numEsc: docDos.data().numEscenarios,
        nombre: docDos.data().datosNuevaCalculadora.nombre,
        nombreInmobiliaria:
          docDos.data().datosNuevaCalculadora.nombreInmobiliaria,
        porcentajeDelimitante:
          docDos.data().datosNuevaCalculadora.porcentajeDelimitante,
        honorariosMinimoInferior:
          docDos.data().datosNuevaCalculadora.honorariosMinimoInferior,
        honorariosMaximoInferior:
          docDos.data().datosNuevaCalculadora.honorariosMaximoInferior,
        comisionMinima: docDos.data().datosNuevaCalculadora.comisionMinima,
        comisionPrimeraIncremnto:
          docDos.data().datosNuevaCalculadora.comisionPrimeraIncremnto,
        comisionExtraIncremento:
          docDos.data().datosNuevaCalculadora.comisionExtraIncremento,
        uid: docDos._document.key.path.segments[6],
        id: docDos.data().id,
        mostrarComision: docDos.data().datosNuevaCalculadora.mostrarComision,
        incuirEdadtitualMenor:
          docDos.data().datosNuevaCalculadora.incuirEdadtitualMenor,
        plazoFijoMinimo: docDos.data().datosNuevaCalculadora.plazoFijoMinimo,
        plazoFijoMaximo: docDos.data().datosNuevaCalculadora.plazoFijoMaximo,
        plazoVariableMinimo:
          docDos.data().datosNuevaCalculadora.plazoVariableMinimo,
        plazoVariableMaximo:
          docDos.data().datosNuevaCalculadora.plazoVariableMaximo,
        plazoMixtoMinimo: docDos.data().datosNuevaCalculadora.plazoMixtoMinimo,
        plazoMixtoMaximo: docDos.data().datosNuevaCalculadora.plazoMixtoMaximo,
        edadMaximaTitular:
          docDos.data().datosNuevaCalculadora.edadMaximaTitular,
        maximoSobreTasacion:
          docDos.data().datosNuevaCalculadora.maximoSobreTasacion,
        productoHipotecario:
          docDos.data().datosNuevaCalculadora.productoHipotecario,
        tinPrimerosMesesVariable:
          docDos.data().datosNuevaCalculadora.tinPrimerosMesesVariable,
        tinPrimerosMesesMixto:
          docDos.data().datosNuevaCalculadora.tinPrimerosMesesMixto,
        //intereses fijo
        hastaDiezFijo: docDos.data().datosNuevaCalculadora.hastaDiezFijo,
        hastaQuinceFijo: docDos.data().datosNuevaCalculadora.hastaQuinceFijo,
        hastaVeinteFijo: docDos.data().datosNuevaCalculadora.hastaVeinteFijo,
        hastaVeinteCincoFijo:
          docDos.data().datosNuevaCalculadora.hastaVeinteCincoFijo,
        hastaTreintaFijo: docDos.data().datosNuevaCalculadora.hastaTreintaFijo,
        hastaTreintaCincoFijo:
          docDos.data().datosNuevaCalculadora.hastaTreintaCincoFijo,
        hastaCuarentaFijo:
          docDos.data().datosNuevaCalculadora.hastaCuarentaFijo,
        hastaCuarentaCincoFijo:
          docDos.data().datosNuevaCalculadora.hastaCuarentaCincoFijo,
        hastaCincuentFijo:
          docDos.data().datosNuevaCalculadora.hastaCincuentFijo,
        //interes variable
        hastaDiezVariable:
          docDos.data().datosNuevaCalculadora.hastaDiezVariable,
        hastaQuinceVariable:
          docDos.data().datosNuevaCalculadora.hastaQuinceVariable,
        hastaVeinteVariable:
          docDos.data().datosNuevaCalculadora.hastaVeinteVariable,
        hastaVeinteCincoVariable:
          docDos.data().datosNuevaCalculadora.hastaVeinteCincoVariable,
        hastaTreintaVariable:
          docDos.data().datosNuevaCalculadora.hastaTreintaVariable,
        hastaTreintaCincoVariable:
          docDos.data().datosNuevaCalculadora.hastaTreintaCincoVariable,
        hastaCuarentaVariable:
          docDos.data().datosNuevaCalculadora.hastaCuarentaVariable,
        hastaCuarentaCincoVariable:
          docDos.data().datosNuevaCalculadora.hastaCuarentaCincoVariable,
        hastaCincuentVariable:
          docDos.data().datosNuevaCalculadora.hastaCincuentVariable,
        // INTERES MIXTO
        hastaDiezMixto: docDos.data().datosNuevaCalculadora.hastaDiezMixto,
        hastaQuinceMixto: docDos.data().datosNuevaCalculadora.hastaQuinceMixto,
        hastaVeinteMixto: docDos.data().datosNuevaCalculadora.hastaVeinteMixto,
        hastaVeinteCincoMixto:
          docDos.data().datosNuevaCalculadora.hastaVeinteCincoMixto,
        hastaTreintaMixto:
          docDos.data().datosNuevaCalculadora.hastaTreintaMixto,
        hastaTreintaCincoMixto:
          docDos.data().datosNuevaCalculadora.hastaTreintaCincoMixto,
        hastaCuarentaMixto:
          docDos.data().datosNuevaCalculadora.hastaCuarentaMixto,
        hastaCuarentaCincoMixto:
          docDos.data().datosNuevaCalculadora.hastaCuarentaCincoMixto,
        hastaCincuentMixto:
          docDos.data().datosNuevaCalculadora.hastaCincuentMixto,
        //DOBLE GARANTIA
        delEscnario0DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia1,
        delEscnario0DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia2,
        delEscnario0DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia3,
        delEscnario0DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia4,
        delEscnario0DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia5,
        delEscnario0DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia6,
        delEscnario0DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia7,
        delEscnario0DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia8,
        delEscnario0DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia9,
        delEscnario0DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia10,
        delEscnario0DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia11,
        delEscnario0DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario0DobleGarantia12,
        //1
        delEscnario1DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia1,
        delEscnario1DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia2,
        delEscnario1DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia3,
        delEscnario1DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia4,
        delEscnario1DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia5,
        delEscnario1DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia6,
        delEscnario1DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia7,
        delEscnario1DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia8,
        delEscnario1DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia9,
        delEscnario1DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia10,
        delEscnario1DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia11,
        delEscnario1DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario1DobleGarantia12,
        //2
        delEscnario2DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia1,
        delEscnario2DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia2,
        delEscnario2DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia3,
        delEscnario2DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia4,
        delEscnario2DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia5,
        delEscnario2DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia6,
        delEscnario2DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia7,
        delEscnario2DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia8,
        delEscnario2DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia9,
        delEscnario2DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia10,
        delEscnario2DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia11,
        delEscnario2DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario2DobleGarantia12,
        //3
        delEscnario3DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia1,
        delEscnario3DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia2,
        delEscnario3DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia3,
        delEscnario3DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia4,
        delEscnario3DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia5,
        delEscnario3DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia6,
        delEscnario3DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia7,
        delEscnario3DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia8,
        delEscnario3DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia9,
        delEscnario3DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia10,
        delEscnario3DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia11,
        delEscnario3DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario3DobleGarantia12,
        //4
        delEscnario4DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia1,
        delEscnario4DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia2,
        delEscnario4DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia3,
        delEscnario4DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia4,
        delEscnario4DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia5,
        delEscnario4DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia6,
        delEscnario4DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia7,
        delEscnario4DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia8,
        delEscnario4DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia9,
        delEscnario4DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia10,
        delEscnario4DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia11,
        delEscnario4DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario4DobleGarantia12,
        //5
        delEscnario5DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia1,
        delEscnario5DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia2,
        delEscnario5DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia3,
        delEscnario5DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia4,
        delEscnario5DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia5,
        delEscnario5DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia6,
        delEscnario5DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia7,
        delEscnario5DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia8,
        delEscnario5DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia9,
        delEscnario5DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia10,
        delEscnario5DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia11,
        delEscnario5DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario5DobleGarantia12,
        //6
        delEscnario6DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia1,
        delEscnario6DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia2,
        delEscnario6DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia3,
        delEscnario6DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia4,
        delEscnario6DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia5,
        delEscnario6DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia6,
        delEscnario6DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia7,
        delEscnario6DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia8,
        delEscnario6DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia9,
        delEscnario6DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia10,
        delEscnario6DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia11,
        delEscnario6DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario6DobleGarantia12,
        //7
        delEscnario7DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia1,
        delEscnario7DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia2,
        delEscnario7DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia3,
        delEscnario7DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia4,
        delEscnario7DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia5,
        delEscnario7DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia6,
        delEscnario7DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia7,
        delEscnario7DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia8,
        delEscnario7DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia9,
        delEscnario7DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia10,
        delEscnario7DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia11,
        delEscnario7DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario7DobleGarantia12,
        //8
        delEscnario8DobleGarantia1:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia1,
        delEscnario8DobleGarantia2:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia2,
        delEscnario8DobleGarantia3:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia3,
        delEscnario8DobleGarantia4:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia4,
        delEscnario8DobleGarantia5:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia5,
        delEscnario8DobleGarantia6:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia6,
        delEscnario8DobleGarantia7:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia7,
        delEscnario8DobleGarantia8:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia8,
        delEscnario8DobleGarantia9:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia9,
        delEscnario8DobleGarantia10:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia10,
        delEscnario8DobleGarantia11:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia11,
        delEscnario8DobleGarantia12:
          docDos.data().datosNuevaCalculadora.delEscnario8DobleGarantia12,

        //MEJORAS
        delEscnario0Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras1,
        delEscnario0Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras2,
        delEscnario0Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras3,
        delEscnario0Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras4,
        delEscnario0Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras5,
        delEscnario0Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras6,
        delEscnario0Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras7,
        delEscnario0Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras8,
        delEscnario0Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras9,
        delEscnario0Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras10,
        delEscnario0Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras11,
        delEscnario0Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario0Mejoras12,
        //1
        delEscnario1Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras1,
        delEscnario1Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras2,
        delEscnario1Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras3,
        delEscnario1Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras4,
        delEscnario1Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras5,
        delEscnario1Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras6,
        delEscnario1Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras7,
        delEscnario1Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras8,
        delEscnario1Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras9,
        delEscnario1Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras10,
        delEscnario1Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras11,
        delEscnario1Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario1Mejoras12,
        //2
        delEscnario2Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras1,
        delEscnario2Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras2,
        delEscnario2Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras3,
        delEscnario2Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras4,
        delEscnario2Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras5,
        delEscnario2Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras6,
        delEscnario2Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras7,
        delEscnario2Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras8,
        delEscnario2Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras9,
        delEscnario2Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras10,
        delEscnario2Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras11,
        delEscnario2Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario2Mejoras12,
        //3
        delEscnario3Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras1,
        delEscnario3Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras2,
        delEscnario3Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras3,
        delEscnario3Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras4,
        delEscnario3Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras5,
        delEscnario3Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras6,
        delEscnario3Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras7,
        delEscnario3Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras8,
        delEscnario3Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras9,
        delEscnario3Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras10,
        delEscnario3Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras11,
        delEscnario3Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario3Mejoras12,
        //4
        delEscnario4Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras1,
        delEscnario4Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras2,
        delEscnario4Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras3,
        delEscnario4Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras4,
        delEscnario4Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras5,
        delEscnario4Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras6,
        delEscnario4Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras7,
        delEscnario4Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras8,
        delEscnario4Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras9,
        delEscnario4Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras10,
        delEscnario4Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras11,
        delEscnario4Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario4Mejoras12,
        //5
        delEscnario5Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras1,
        delEscnario5Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras2,
        delEscnario5Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras3,
        delEscnario5Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras4,
        delEscnario5Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras5,
        delEscnario5Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras6,
        delEscnario5Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras7,
        delEscnario5Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras8,
        delEscnario5Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras9,
        delEscnario5Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras10,
        delEscnario5Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras11,
        delEscnario5Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario5Mejoras12,
        //6
        delEscnario6Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras1,
        delEscnario6Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras2,
        delEscnario6Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras3,
        delEscnario6Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras4,
        delEscnario6Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras5,
        delEscnario6Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras6,
        delEscnario6Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras7,
        delEscnario6Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras8,
        delEscnario6Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras9,
        delEscnario6Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras10,
        delEscnario6Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras11,
        delEscnario6Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario6Mejoras12,
        //7
        delEscnario7Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras1,
        delEscnario7Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras2,
        delEscnario7Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras3,
        delEscnario7Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras4,
        delEscnario7Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras5,
        delEscnario7Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras6,
        delEscnario7Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras7,
        delEscnario7Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras8,
        delEscnario7Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras9,
        delEscnario7Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras10,
        delEscnario7Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras11,
        delEscnario7Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario7Mejoras12,
        //8
        delEscnario8Mejoras1:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras1,
        delEscnario8Mejoras2:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras2,
        delEscnario8Mejoras3:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras3,
        delEscnario8Mejoras4:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras4,
        delEscnario8Mejoras5:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras5,
        delEscnario8Mejoras6:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras6,
        delEscnario8Mejoras7:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras7,
        delEscnario8Mejoras8:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras8,
        delEscnario8Mejoras9:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras9,
        delEscnario8Mejoras10:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras10,
        delEscnario8Mejoras11:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras11,
        delEscnario8Mejoras12:
          docDos.data().datosNuevaCalculadora.delEscnario8Mejoras12,

        //LIMITES DE PORCENTAJE
        //0
        delEscnario0Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite1,
        delEscnario0Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite2,
        delEscnario0Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite3,
        delEscnario0Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite4,
        delEscnario0Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite5,
        delEscnario0Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite6,
        delEscnario0Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite7,
        delEscnario0Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite8,
        delEscnario0Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite9,
        delEscnario0Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite10,
        delEscnario0Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite11,
        delEscnario0Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario0Limite12,
        //1
        delEscnario1Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite1,
        delEscnario1Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite2,
        delEscnario1Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite3,
        delEscnario1Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite4,
        delEscnario1Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite5,
        delEscnario1Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite6,
        delEscnario1Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite7,
        delEscnario1Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite8,
        delEscnario1Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite9,
        delEscnario1Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite10,
        delEscnario1Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite11,
        delEscnario1Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario1Limite12,
        //2
        delEscnario2Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite1,
        delEscnario2Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite2,
        delEscnario2Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite3,
        delEscnario2Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite4,
        delEscnario2Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite5,
        delEscnario2Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite6,
        delEscnario2Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite7,
        delEscnario2Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite8,
        delEscnario2Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite9,
        delEscnario2Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite10,
        delEscnario2Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite11,
        delEscnario2Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario2Limite12,
        //3
        delEscnario3Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite1,
        delEscnario3Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite2,
        delEscnario3Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite3,
        delEscnario3Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite4,
        delEscnario3Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite5,
        delEscnario3Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite6,
        delEscnario3Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite7,
        delEscnario3Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite8,
        delEscnario3Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite9,
        delEscnario3Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite10,
        delEscnario3Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite11,
        delEscnario3Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario3Limite12,
        //4
        delEscnario4Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite1,
        delEscnario4Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite2,
        delEscnario4Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite3,
        delEscnario4Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite4,
        delEscnario4Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite5,
        delEscnario4Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite6,
        delEscnario4Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite7,
        delEscnario4Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite8,
        delEscnario4Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite9,
        delEscnario4Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite10,
        delEscnario4Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite11,
        delEscnario4Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario4Limite12,
        //5
        delEscnario5Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite1,
        delEscnario5Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite2,
        delEscnario5Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite3,
        delEscnario5Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite4,
        delEscnario5Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite5,
        delEscnario5Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite6,
        delEscnario5Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite7,
        delEscnario5Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite8,
        delEscnario5Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite9,
        delEscnario5Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite10,
        delEscnario5Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite11,
        delEscnario5Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario5Limite12,
        //6
        delEscnario6Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite1,
        delEscnario6Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite2,
        delEscnario6Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite3,
        delEscnario6Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite4,
        delEscnario6Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite5,
        delEscnario6Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite6,
        delEscnario6Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite7,
        delEscnario6Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite8,
        delEscnario6Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite9,
        delEscnario6Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite10,
        delEscnario6Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite11,
        delEscnario6Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario6Limite12,
        //7
        delEscnario7Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite1,
        delEscnario7Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite2,
        delEscnario7Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite3,
        delEscnario7Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite4,
        delEscnario7Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite5,
        delEscnario7Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite6,
        delEscnario7Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite7,
        delEscnario7Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite8,
        delEscnario7Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite9,
        delEscnario7Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite10,
        delEscnario7Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite11,
        delEscnario7Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario7Limite12,
        //8
        delEscnario8Limite1:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite1,
        delEscnario8Limite2:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite2,
        delEscnario8Limite3:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite3,
        delEscnario8Limite4:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite4,
        delEscnario8Limite5:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite5,
        delEscnario8Limite6:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite6,
        delEscnario8Limite7:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite7,
        delEscnario8Limite8:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite8,
        delEscnario8Limite9:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite9,
        delEscnario8Limite10:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite10,
        delEscnario8Limite11:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite11,
        delEscnario8Limite12:
          docDos.data().datosNuevaCalculadora.delEscnario8Limite12,
        //DESDE (DESDE) X NUMERO PORCENTAJE
        delEscnario0: docDos.data().datosNuevaCalculadora.delEscnario0,
        delEscnario1: docDos.data().datosNuevaCalculadora.delEscnario1,
        delEscnario2: docDos.data().datosNuevaCalculadora.delEscnario2,
        delEscnario3: docDos.data().datosNuevaCalculadora.delEscnario3,
        delEscnario4: docDos.data().datosNuevaCalculadora.delEscnario4,
        delEscnario5: docDos.data().datosNuevaCalculadora.delEscnario5,
        delEscnario6: docDos.data().datosNuevaCalculadora.delEscnario6,
        delEscnario7: docDos.data().datosNuevaCalculadora.delEscnario7,
        delEscnario8: docDos.data().datosNuevaCalculadora.delEscnario8,
        //HASTA (AL) X NUMERO PORCENTAJE
        alEscnario0: docDos.data().datosNuevaCalculadora.alEscnario0,
        alEscnario1: docDos.data().datosNuevaCalculadora.alEscnario1,
        alEscnario2: docDos.data().datosNuevaCalculadora.alEscnario2,
        alEscnario3: docDos.data().datosNuevaCalculadora.alEscnario3,
        alEscnario4: docDos.data().datosNuevaCalculadora.alEscnario4,
        alEscnario5: docDos.data().datosNuevaCalculadora.alEscnario5,
        alEscnario6: docDos.data().datosNuevaCalculadora.alEscnario6,
        alEscnario7: docDos.data().datosNuevaCalculadora.alEscnario7,
        alEscnario8: docDos.data().datosNuevaCalculadora.alEscnario8,
        //LIMITE DE FINANCIAZION CARTEL POR ESCENARIO

        // ANTIGUEDAD DE CONTRATO MENOR A (TIEMPO)
        // ESCENARIO 0
        delEscnario0meses1:
          docDos.data().datosNuevaCalculadora.delEscnario0meses1,
        delEscnario0meses2:
          docDos.data().datosNuevaCalculadora.delEscnario0meses2,
        delEscnario0meses3:
          docDos.data().datosNuevaCalculadora.delEscnario0meses3,
        delEscnario0meses4:
          docDos.data().datosNuevaCalculadora.delEscnario0meses4,
        delEscnario0meses5:
          docDos.data().datosNuevaCalculadora.delEscnario0meses5,
        delEscnario0meses6:
          docDos.data().datosNuevaCalculadora.delEscnario0meses6,
        delEscnario0meses7:
          docDos.data().datosNuevaCalculadora.delEscnario0meses7,
        delEscnario0meses8:
          docDos.data().datosNuevaCalculadora.delEscnario0meses8,
        delEscnario0meses9:
          docDos.data().datosNuevaCalculadora.delEscnario0meses9,
        delEscnario0meses10:
          docDos.data().datosNuevaCalculadora.delEscnario0meses10,
        delEscnario0meses11:
          docDos.data().datosNuevaCalculadora.delEscnario0meses11,
        delEscnario0meses12:
          docDos.data().datosNuevaCalculadora.delEscnario0meses12,
        //ESCENARIO 1
        delEscnario1meses1:
          docDos.data().datosNuevaCalculadora.delEscnario1meses1,
        delEscnario1meses2:
          docDos.data().datosNuevaCalculadora.delEscnario1meses2,
        delEscnario1meses3:
          docDos.data().datosNuevaCalculadora.delEscnario1meses3,
        delEscnario1meses4:
          docDos.data().datosNuevaCalculadora.delEscnario1meses4,
        delEscnario1meses5:
          docDos.data().datosNuevaCalculadora.delEscnario1meses5,
        delEscnario1meses6:
          docDos.data().datosNuevaCalculadora.delEscnario1meses6,
        delEscnario1meses7:
          docDos.data().datosNuevaCalculadora.delEscnario1meses7,
        delEscnario1meses8:
          docDos.data().datosNuevaCalculadora.delEscnario1meses8,
        delEscnario1meses9:
          docDos.data().datosNuevaCalculadora.delEscnario1meses9,
        delEscnario1meses10:
          docDos.data().datosNuevaCalculadora.delEscnario1meses10,
        delEscnario1meses11:
          docDos.data().datosNuevaCalculadora.delEscnario1meses11,
        delEscnario1meses12:
          docDos.data().datosNuevaCalculadora.delEscnario1meses12,
        //ESCENARIO 2
        delEscnario2meses1:
          docDos.data().datosNuevaCalculadora.delEscnario2meses1,
        delEscnario2meses2:
          docDos.data().datosNuevaCalculadora.delEscnario2meses2,
        delEscnario2meses3:
          docDos.data().datosNuevaCalculadora.delEscnario2meses3,
        delEscnario2meses4:
          docDos.data().datosNuevaCalculadora.delEscnario2meses4,
        delEscnario2meses5:
          docDos.data().datosNuevaCalculadora.delEscnario2meses5,
        delEscnario2meses6:
          docDos.data().datosNuevaCalculadora.delEscnario2meses6,
        delEscnario2meses7:
          docDos.data().datosNuevaCalculadora.delEscnario2meses7,
        delEscnario2meses8:
          docDos.data().datosNuevaCalculadora.delEscnario2meses8,
        delEscnario2meses9:
          docDos.data().datosNuevaCalculadora.delEscnario2meses9,
        delEscnario2meses10:
          docDos.data().datosNuevaCalculadora.delEscnario2meses10,
        delEscnario2meses11:
          docDos.data().datosNuevaCalculadora.delEscnario2meses11,
        delEscnario2meses12:
          docDos.data().datosNuevaCalculadora.delEscnario2meses12,
        //ESCENARIO 3
        delEscnario3meses1:
          docDos.data().datosNuevaCalculadora.delEscnario3meses1,
        delEscnario3meses2:
          docDos.data().datosNuevaCalculadora.delEscnario3meses2,
        delEscnario3meses3:
          docDos.data().datosNuevaCalculadora.delEscnario3meses3,
        delEscnario3meses4:
          docDos.data().datosNuevaCalculadora.delEscnario3meses4,
        delEscnario3meses5:
          docDos.data().datosNuevaCalculadora.delEscnario3meses5,
        delEscnario3meses6:
          docDos.data().datosNuevaCalculadora.delEscnario3meses6,
        delEscnario3meses7:
          docDos.data().datosNuevaCalculadora.delEscnario3meses7,
        delEscnario3meses8:
          docDos.data().datosNuevaCalculadora.delEscnario3meses8,
        delEscnario3meses9:
          docDos.data().datosNuevaCalculadora.delEscnario3meses9,
        delEscnario3meses10:
          docDos.data().datosNuevaCalculadora.delEscnario3meses10,
        delEscnario3meses11:
          docDos.data().datosNuevaCalculadora.delEscnario3meses11,
        delEscnario3meses12:
          docDos.data().datosNuevaCalculadora.delEscnario3meses12,
        //ESCENARIO 4
        delEscnario4meses1:
          docDos.data().datosNuevaCalculadora.delEscnario4meses1,
        delEscnario4meses2:
          docDos.data().datosNuevaCalculadora.delEscnario4meses2,
        delEscnario4meses3:
          docDos.data().datosNuevaCalculadora.delEscnario4meses3,
        delEscnario4meses4:
          docDos.data().datosNuevaCalculadora.delEscnario4meses4,
        delEscnario4meses5:
          docDos.data().datosNuevaCalculadora.delEscnario4meses5,
        delEscnario4meses6:
          docDos.data().datosNuevaCalculadora.delEscnario4meses6,
        delEscnario4meses7:
          docDos.data().datosNuevaCalculadora.delEscnario4meses7,
        delEscnario4meses8:
          docDos.data().datosNuevaCalculadora.delEscnario4meses8,
        delEscnario4meses9:
          docDos.data().datosNuevaCalculadora.delEscnario4meses9,
        delEscnario4meses10:
          docDos.data().datosNuevaCalculadora.delEscnario4meses10,
        delEscnario4meses11:
          docDos.data().datosNuevaCalculadora.delEscnario4meses11,
        delEscnario4meses12:
          docDos.data().datosNuevaCalculadora.delEscnario4meses12,
        //ESCENARIO 5
        delEscnario5meses1:
          docDos.data().datosNuevaCalculadora.delEscnario5meses1,
        delEscnario5meses2:
          docDos.data().datosNuevaCalculadora.delEscnario5meses2,
        delEscnario5meses3:
          docDos.data().datosNuevaCalculadora.delEscnario5meses3,
        delEscnario5meses4:
          docDos.data().datosNuevaCalculadora.delEscnario5meses4,
        delEscnario5meses5:
          docDos.data().datosNuevaCalculadora.delEscnario5meses5,
        delEscnario5meses6:
          docDos.data().datosNuevaCalculadora.delEscnario5meses6,
        delEscnario5meses7:
          docDos.data().datosNuevaCalculadora.delEscnario5meses7,
        delEscnario5meses8:
          docDos.data().datosNuevaCalculadora.delEscnario5meses8,
        delEscnario5meses9:
          docDos.data().datosNuevaCalculadora.delEscnario5meses9,
        delEscnario5meses10:
          docDos.data().datosNuevaCalculadora.delEscnario5meses10,
        delEscnario5meses11:
          docDos.data().datosNuevaCalculadora.delEscnario5meses11,
        delEscnario5meses12:
          docDos.data().datosNuevaCalculadora.delEscnario5meses12,
        //ESCENARIO 6
        delEscnario6meses1:
          docDos.data().datosNuevaCalculadora.delEscnario6meses1,
        delEscnario6meses2:
          docDos.data().datosNuevaCalculadora.delEscnario6meses2,
        delEscnario6meses3:
          docDos.data().datosNuevaCalculadora.delEscnario6meses3,
        delEscnario6meses4:
          docDos.data().datosNuevaCalculadora.delEscnario6meses4,
        delEscnario6meses5:
          docDos.data().datosNuevaCalculadora.delEscnario6meses5,
        delEscnario6meses6:
          docDos.data().datosNuevaCalculadora.delEscnario6meses6,
        delEscnario6meses7:
          docDos.data().datosNuevaCalculadora.delEscnario6meses7,
        delEscnario6meses8:
          docDos.data().datosNuevaCalculadora.delEscnario6meses8,
        delEscnario6meses9:
          docDos.data().datosNuevaCalculadora.delEscnario6meses9,
        delEscnario6meses10:
          docDos.data().datosNuevaCalculadora.delEscnario6meses10,
        delEscnario6meses11:
          docDos.data().datosNuevaCalculadora.delEscnario6meses11,
        delEscnario6meses12:
          docDos.data().datosNuevaCalculadora.delEscnario6meses12,
        //ESCENARIO 7
        delEscnario7meses1:
          docDos.data().datosNuevaCalculadora.delEscnario7meses1,
        delEscnario7meses2:
          docDos.data().datosNuevaCalculadora.delEscnario7meses2,
        delEscnario7meses3:
          docDos.data().datosNuevaCalculadora.delEscnario7meses3,
        delEscnario7meses4:
          docDos.data().datosNuevaCalculadora.delEscnario7meses4,
        delEscnario7meses5:
          docDos.data().datosNuevaCalculadora.delEscnario7meses5,
        delEscnario7meses6:
          docDos.data().datosNuevaCalculadora.delEscnario7meses6,
        delEscnario7meses7:
          docDos.data().datosNuevaCalculadora.delEscnario7meses7,
        delEscnario7meses8:
          docDos.data().datosNuevaCalculadora.delEscnario7meses8,
        delEscnario7meses9:
          docDos.data().datosNuevaCalculadora.delEscnario7meses9,
        delEscnario7meses10:
          docDos.data().datosNuevaCalculadora.delEscnario7meses10,
        delEscnario7meses11:
          docDos.data().datosNuevaCalculadora.delEscnario7meses11,
        delEscnario7meses12:
          docDos.data().datosNuevaCalculadora.delEscnario7meses12,
        //ESCENARIO 8
        delEscnario8meses1:
          docDos.data().datosNuevaCalculadora.delEscnario8meses1,
        delEscnario8meses2:
          docDos.data().datosNuevaCalculadora.delEscnario8meses2,
        delEscnario8meses3:
          docDos.data().datosNuevaCalculadora.delEscnario8meses3,
        delEscnario8meses4:
          docDos.data().datosNuevaCalculadora.delEscnario8meses4,
        delEscnario8meses5:
          docDos.data().datosNuevaCalculadora.delEscnario8meses5,
        delEscnario8meses6:
          docDos.data().datosNuevaCalculadora.delEscnario8meses6,
        delEscnario8meses7:
          docDos.data().datosNuevaCalculadora.delEscnario8meses7,
        delEscnario8meses8:
          docDos.data().datosNuevaCalculadora.delEscnario8meses8,
        delEscnario8meses9:
          docDos.data().datosNuevaCalculadora.delEscnario8meses9,
        delEscnario8meses10:
          docDos.data().datosNuevaCalculadora.delEscnario8meses10,
        delEscnario8meses11:
          docDos.data().datosNuevaCalculadora.delEscnario8meses11,
        delEscnario8meses12:
          docDos.data().datosNuevaCalculadora.delEscnario8meses12,

        // ANTIGUEDAD DE CONTRATO MENOR A (PENA)
        // ESCENARIO 0
        delEscnario0pena1:
          docDos.data().datosNuevaCalculadora.delEscnario0pena1,
        delEscnario0pena2:
          docDos.data().datosNuevaCalculadora.delEscnario0pena2,
        delEscnario0pena3:
          docDos.data().datosNuevaCalculadora.delEscnario0pena3,
        delEscnario0pena4:
          docDos.data().datosNuevaCalculadora.delEscnario0pena4,
        delEscnario0pena5:
          docDos.data().datosNuevaCalculadora.delEscnario0pena5,
        delEscnario0pena6:
          docDos.data().datosNuevaCalculadora.delEscnario0pena6,
        delEscnario0pena7:
          docDos.data().datosNuevaCalculadora.delEscnario0pena7,
        delEscnario0pena8:
          docDos.data().datosNuevaCalculadora.delEscnario0pena8,
        delEscnario0pena9:
          docDos.data().datosNuevaCalculadora.delEscnario0pena9,
        delEscnario0pena10:
          docDos.data().datosNuevaCalculadora.delEscnario0pena10,
        delEscnario0pena11:
          docDos.data().datosNuevaCalculadora.delEscnario0pena11,
        delEscnario0pena12:
          docDos.data().datosNuevaCalculadora.delEscnario0pena12,
        //ESCENARIO 1
        delEscnario1pena1:
          docDos.data().datosNuevaCalculadora.delEscnario1pena1,
        delEscnario1pena2:
          docDos.data().datosNuevaCalculadora.delEscnario1pena2,
        delEscnario1pena3:
          docDos.data().datosNuevaCalculadora.delEscnario1pena3,
        delEscnario1pena4:
          docDos.data().datosNuevaCalculadora.delEscnario1pena4,
        delEscnario1pena5:
          docDos.data().datosNuevaCalculadora.delEscnario1pena5,
        delEscnario1pena6:
          docDos.data().datosNuevaCalculadora.delEscnario1pena6,
        delEscnario1pena7:
          docDos.data().datosNuevaCalculadora.delEscnario1pena7,
        delEscnario1pena8:
          docDos.data().datosNuevaCalculadora.delEscnario1pena8,
        delEscnario1pena9:
          docDos.data().datosNuevaCalculadora.delEscnario1pena9,
        delEscnario1pena10:
          docDos.data().datosNuevaCalculadora.delEscnario1pena10,
        delEscnario1pena11:
          docDos.data().datosNuevaCalculadora.delEscnario1pena11,
        delEscnario1pena12:
          docDos.data().datosNuevaCalculadora.delEscnario1pena12,
        //ESCENARIO 2
        delEscnario2pena1:
          docDos.data().datosNuevaCalculadora.delEscnario2pena1,
        delEscnario2pena2:
          docDos.data().datosNuevaCalculadora.delEscnario2pena2,
        delEscnario2pena3:
          docDos.data().datosNuevaCalculadora.delEscnario2pena3,
        delEscnario2pena4:
          docDos.data().datosNuevaCalculadora.delEscnario2pena4,
        delEscnario2pena5:
          docDos.data().datosNuevaCalculadora.delEscnario2pena5,
        delEscnario2pena6:
          docDos.data().datosNuevaCalculadora.delEscnario2pena6,
        delEscnario2pena7:
          docDos.data().datosNuevaCalculadora.delEscnario2pena7,
        delEscnario2pena8:
          docDos.data().datosNuevaCalculadora.delEscnario2pena8,
        delEscnario2pena9:
          docDos.data().datosNuevaCalculadora.delEscnario2pena9,
        delEscnario2pena10:
          docDos.data().datosNuevaCalculadora.delEscnario2pena10,
        delEscnario2pena11:
          docDos.data().datosNuevaCalculadora.delEscnario2pena11,
        delEscnario2pena12:
          docDos.data().datosNuevaCalculadora.delEscnario2pena12,
        //ESCENARIO 3
        delEscnario3pena1:
          docDos.data().datosNuevaCalculadora.delEscnario3pena1,
        delEscnario3pena2:
          docDos.data().datosNuevaCalculadora.delEscnario3pena2,
        delEscnario3pena3:
          docDos.data().datosNuevaCalculadora.delEscnario3pena3,
        delEscnario3pena4:
          docDos.data().datosNuevaCalculadora.delEscnario3pena4,
        delEscnario3pena5:
          docDos.data().datosNuevaCalculadora.delEscnario3pena5,
        delEscnario3pena6:
          docDos.data().datosNuevaCalculadora.delEscnario3pena6,
        delEscnario3pena7:
          docDos.data().datosNuevaCalculadora.delEscnario3pena7,
        delEscnario3pena8:
          docDos.data().datosNuevaCalculadora.delEscnario3pena8,
        delEscnario3pena9:
          docDos.data().datosNuevaCalculadora.delEscnario3pena9,
        delEscnario3pena10:
          docDos.data().datosNuevaCalculadora.delEscnario3pena10,
        delEscnario3pena11:
          docDos.data().datosNuevaCalculadora.delEscnario3pena11,
        delEscnario3pena12:
          docDos.data().datosNuevaCalculadora.delEscnario3pena12,
        //ESCENARIO 4
        delEscnario4pena1:
          docDos.data().datosNuevaCalculadora.delEscnario4pena1,
        delEscnario4pena2:
          docDos.data().datosNuevaCalculadora.delEscnario4pena2,
        delEscnario4pena3:
          docDos.data().datosNuevaCalculadora.delEscnario4pena3,
        delEscnario4pena4:
          docDos.data().datosNuevaCalculadora.delEscnario4pena4,
        delEscnario4pena5:
          docDos.data().datosNuevaCalculadora.delEscnario4pena5,
        delEscnario4pena6:
          docDos.data().datosNuevaCalculadora.delEscnario4pena6,
        delEscnario4pena7:
          docDos.data().datosNuevaCalculadora.delEscnario4pena7,
        delEscnario4pena8:
          docDos.data().datosNuevaCalculadora.delEscnario4pena8,
        delEscnario4pena9:
          docDos.data().datosNuevaCalculadora.delEscnario4pena9,
        delEscnario4pena10:
          docDos.data().datosNuevaCalculadora.delEscnario4pena10,
        delEscnario4pena11:
          docDos.data().datosNuevaCalculadora.delEscnario4pena11,
        delEscnario4pena12:
          docDos.data().datosNuevaCalculadora.delEscnario4pena12,
        //ESCENARIO 5
        delEscnario5pena1:
          docDos.data().datosNuevaCalculadora.delEscnario5pena1,
        delEscnario5pena2:
          docDos.data().datosNuevaCalculadora.delEscnario5pena2,
        delEscnario5pena3:
          docDos.data().datosNuevaCalculadora.delEscnario5pena3,
        delEscnario5pena4:
          docDos.data().datosNuevaCalculadora.delEscnario5pena4,
        delEscnario5pena5:
          docDos.data().datosNuevaCalculadora.delEscnario5pena5,
        delEscnario5pena6:
          docDos.data().datosNuevaCalculadora.delEscnario5pena6,
        delEscnario5pena7:
          docDos.data().datosNuevaCalculadora.delEscnario5pena7,
        delEscnario5pena8:
          docDos.data().datosNuevaCalculadora.delEscnario5pena8,
        delEscnario5pena9:
          docDos.data().datosNuevaCalculadora.delEscnario5pena9,
        delEscnario5pena10:
          docDos.data().datosNuevaCalculadora.delEscnario5pena10,
        delEscnario5pena11:
          docDos.data().datosNuevaCalculadora.delEscnario5pena11,
        delEscnario5pena12:
          docDos.data().datosNuevaCalculadora.delEscnario5pena12,
        //ESCENARIO 6
        delEscnario6pena1:
          docDos.data().datosNuevaCalculadora.delEscnario6pena1,
        delEscnario6pena2:
          docDos.data().datosNuevaCalculadora.delEscnario6pena2,
        delEscnario6pena3:
          docDos.data().datosNuevaCalculadora.delEscnario6pena3,
        delEscnario6pena4:
          docDos.data().datosNuevaCalculadora.delEscnario6pena4,
        delEscnario6pena5:
          docDos.data().datosNuevaCalculadora.delEscnario6pena5,
        delEscnario6pena6:
          docDos.data().datosNuevaCalculadora.delEscnario6pena6,
        delEscnario6pena7:
          docDos.data().datosNuevaCalculadora.delEscnario6pena7,
        delEscnario6pena8:
          docDos.data().datosNuevaCalculadora.delEscnario6pena8,
        delEscnario6pena9:
          docDos.data().datosNuevaCalculadora.delEscnario6pena9,
        delEscnario6pena10:
          docDos.data().datosNuevaCalculadora.delEscnario6pena10,
        delEscnario6pena11:
          docDos.data().datosNuevaCalculadora.delEscnario6pena11,
        delEscnario6pena12:
          docDos.data().datosNuevaCalculadora.delEscnario6pena12,
        //ESCENARIO 7
        delEscnario7pena1:
          docDos.data().datosNuevaCalculadora.delEscnario7pena1,
        delEscnario7pena2:
          docDos.data().datosNuevaCalculadora.delEscnario7pena2,
        delEscnario7pena3:
          docDos.data().datosNuevaCalculadora.delEscnario7pena3,
        delEscnario7pena4:
          docDos.data().datosNuevaCalculadora.delEscnario7pena4,
        delEscnario7pena5:
          docDos.data().datosNuevaCalculadora.delEscnario7pena5,
        delEscnario7pena6:
          docDos.data().datosNuevaCalculadora.delEscnario7pena6,
        delEscnario7pena7:
          docDos.data().datosNuevaCalculadora.delEscnario7pena7,
        delEscnario7pena8:
          docDos.data().datosNuevaCalculadora.delEscnario7pena8,
        delEscnario7pena9:
          docDos.data().datosNuevaCalculadora.delEscnario7pena9,
        delEscnario7pena10:
          docDos.data().datosNuevaCalculadora.delEscnario7pena10,
        delEscnario7pena11:
          docDos.data().datosNuevaCalculadora.delEscnario7pena11,
        delEscnario7pena12:
          docDos.data().datosNuevaCalculadora.delEscnario7pena12,
        //ESCENARIO 8
        delEscnario8pena1:
          docDos.data().datosNuevaCalculadora.delEscnario8pena1,
        delEscnario8pena2:
          docDos.data().datosNuevaCalculadora.delEscnario8pena2,
        delEscnario8pena3:
          docDos.data().datosNuevaCalculadora.delEscnario8pena3,
        delEscnario8pena4:
          docDos.data().datosNuevaCalculadora.delEscnario8pena4,
        delEscnario8pena5:
          docDos.data().datosNuevaCalculadora.delEscnario8pena5,
        delEscnario8pena6:
          docDos.data().datosNuevaCalculadora.delEscnario8pena6,
        delEscnario8pena7:
          docDos.data().datosNuevaCalculadora.delEscnario8pena7,
        delEscnario8pena8:
          docDos.data().datosNuevaCalculadora.delEscnario8pena8,
        delEscnario8pena9:
          docDos.data().datosNuevaCalculadora.delEscnario8pena9,
        delEscnario8pena10:
          docDos.data().datosNuevaCalculadora.delEscnario8pena10,
        delEscnario8pena11:
          docDos.data().datosNuevaCalculadora.delEscnario8pena11,
        delEscnario8pena12:
          docDos.data().datosNuevaCalculadora.delEscnario8pena12,

        // SEGUNDA ANTIGUEDAD MAYOR IGUAL(TIEMPO)
        // ESCENARIO 0
        delEscnario0antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses1,
        delEscnario0antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses2,
        delEscnario0antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses3,
        delEscnario0antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses4,
        delEscnario0antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses5,
        delEscnario0antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses6,
        delEscnario0antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses7,
        delEscnario0antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses8,
        delEscnario0antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses9,
        delEscnario0antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses10,
        delEscnario0antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses11,
        delEscnario0antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadMeses12,

        // ESCENARIO 1
        delEscnario1antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses1,
        delEscnario1antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses2,
        delEscnario1antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses3,
        delEscnario1antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses4,
        delEscnario1antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses5,
        delEscnario1antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses6,
        delEscnario1antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses7,
        delEscnario1antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses8,
        delEscnario1antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses9,
        delEscnario1antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses10,
        delEscnario1antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses11,
        delEscnario1antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadMeses12,

        // ESCENARIO 2
        delEscnario2antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses1,
        delEscnario2antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses2,
        delEscnario2antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses3,
        delEscnario2antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses4,
        delEscnario2antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses5,
        delEscnario2antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses6,
        delEscnario2antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses7,
        delEscnario2antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses8,
        delEscnario2antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses9,
        delEscnario2antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses10,
        delEscnario2antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses11,
        delEscnario2antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadMeses12,

        // ESCENARIO 3
        delEscnario3antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses1,
        delEscnario3antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses2,
        delEscnario3antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses3,
        delEscnario3antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses4,
        delEscnario3antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses5,
        delEscnario3antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses6,
        delEscnario3antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses7,
        delEscnario3antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses8,
        delEscnario3antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses9,
        delEscnario3antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses10,
        delEscnario3antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses11,
        delEscnario3antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadMeses12,

        // ESCENARIO 4
        delEscnario4antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses1,
        delEscnario4antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses2,
        delEscnario4antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses3,
        delEscnario4antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses4,
        delEscnario4antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses5,
        delEscnario4antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses6,
        delEscnario4antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses7,
        delEscnario4antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses8,
        delEscnario4antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses9,
        delEscnario4antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses10,
        delEscnario4antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses11,
        delEscnario4antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadMeses12,

        // ESCENARIO 5
        delEscnario5antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses1,
        delEscnario5antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses2,
        delEscnario5antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses3,
        delEscnario5antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses4,
        delEscnario5antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses5,
        delEscnario5antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses6,
        delEscnario5antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses7,
        delEscnario5antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses8,
        delEscnario5antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses9,
        delEscnario5antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses10,
        delEscnario5antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses11,
        delEscnario5antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadMeses12,

        // ESCENARIO 6
        delEscnario6antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses1,
        delEscnario6antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses2,
        delEscnario6antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses3,
        delEscnario6antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses4,
        delEscnario6antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses5,
        delEscnario6antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses6,
        delEscnario6antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses7,
        delEscnario6antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses8,
        delEscnario6antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses9,
        delEscnario6antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses10,
        delEscnario6antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses11,
        delEscnario6antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadMeses12,

        // ESCENARIO 7
        delEscnario7antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses1,
        delEscnario7antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses2,
        delEscnario7antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses3,
        delEscnario7antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses4,
        delEscnario7antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses5,
        delEscnario7antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses6,
        delEscnario7antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses7,
        delEscnario7antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses8,
        delEscnario7antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses9,
        delEscnario7antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses10,
        delEscnario7antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses11,
        delEscnario7antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadMeses12,

        // ESCENARIO 8
        delEscnario8antiguedadMeses1:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses1,
        delEscnario8antiguedadMeses2:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses2,
        delEscnario8antiguedadMeses3:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses3,
        delEscnario8antiguedadMeses4:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses4,
        delEscnario8antiguedadMeses5:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses5,
        delEscnario8antiguedadMeses6:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses6,
        delEscnario8antiguedadMeses7:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses7,
        delEscnario8antiguedadMeses8:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses8,
        delEscnario8antiguedadMeses9:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses9,
        delEscnario8antiguedadMeses10:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses10,
        delEscnario8antiguedadMeses11:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses11,
        delEscnario8antiguedadMeses12:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadMeses12,

        // SEGUNDA ANTIGUEDAD MAYOR IGUAL(PENA)
        // ESCENARIO 0
        delEscnario0antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena1,
        delEscnario0antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena2,
        delEscnario0antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena3,
        delEscnario0antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena4,
        delEscnario0antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena5,
        delEscnario0antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena6,
        delEscnario0antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena7,
        delEscnario0antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena8,
        delEscnario0antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena9,
        delEscnario0antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena10,
        delEscnario0antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena11,
        delEscnario0antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario0antiguedadPena12,

        // //ESCENARIO 1
        delEscnario1antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena1,
        delEscnario1antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena2,
        delEscnario1antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena3,
        delEscnario1antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena4,
        delEscnario1antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena5,
        delEscnario1antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena6,
        delEscnario1antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena7,
        delEscnario1antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena8,
        delEscnario1antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena9,
        delEscnario1antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena10,
        delEscnario1antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena11,
        delEscnario1antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario1antiguedadPena12,

        // //ESCENARIO 2
        delEscnario2antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena1,
        delEscnario2antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena2,
        delEscnario2antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena3,
        delEscnario2antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena4,
        delEscnario2antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena5,
        delEscnario2antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena6,
        delEscnario2antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena7,
        delEscnario2antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena8,
        delEscnario2antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena9,
        delEscnario2antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena10,
        delEscnario2antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena11,
        delEscnario2antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario2antiguedadPena12,

        // //ESCENARIO 3
        delEscnario3antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena1,
        delEscnario3antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena2,
        delEscnario3antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena3,
        delEscnario3antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena4,
        delEscnario3antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena5,
        delEscnario3antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena6,
        delEscnario3antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena7,
        delEscnario3antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena8,
        delEscnario3antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena9,
        delEscnario3antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena10,
        delEscnario3antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena11,
        delEscnario3antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario3antiguedadPena12,

        // //ESCENARIO 4
        delEscnario4antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena1,
        delEscnario4antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena2,
        delEscnario4antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena3,
        delEscnario4antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena4,
        delEscnario4antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena5,
        delEscnario4antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena6,
        delEscnario4antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena7,
        delEscnario4antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena8,
        delEscnario4antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena9,
        delEscnario4antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena10,
        delEscnario4antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena11,
        delEscnario4antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario4antiguedadPena12,

        // //ESCENARIO 5
        delEscnario5antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena1,
        delEscnario5antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena2,
        delEscnario5antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena3,
        delEscnario5antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena4,
        delEscnario5antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena5,
        delEscnario5antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena6,
        delEscnario5antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena7,
        delEscnario5antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena8,
        delEscnario5antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena9,
        delEscnario5antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena10,
        delEscnario5antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena11,
        delEscnario5antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario5antiguedadPena12,

        // //ESCENARIO 6
        delEscnario6antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena1,
        delEscnario6antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena2,
        delEscnario6antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena3,
        delEscnario6antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena4,
        delEscnario6antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena5,
        delEscnario6antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena6,
        delEscnario6antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena7,
        delEscnario6antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena8,
        delEscnario6antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena9,
        delEscnario6antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena10,
        delEscnario6antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena11,
        delEscnario6antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario6antiguedadPena12,

        // //ESCENARIO 7
        delEscnario7antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena1,
        delEscnario7antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena2,
        delEscnario7antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena3,
        delEscnario7antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena4,
        delEscnario7antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena5,
        delEscnario7antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena6,
        delEscnario7antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena7,
        delEscnario7antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena8,
        delEscnario7antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena9,
        delEscnario7antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena10,
        delEscnario7antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena11,
        delEscnario7antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario7antiguedadPena12,

        // //ESCENARIO 8
        delEscnario8antiguedadPena1:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena1,
        delEscnario8antiguedadPena2:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena2,
        delEscnario8antiguedadPena3:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena3,
        delEscnario8antiguedadPena4:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena4,
        delEscnario8antiguedadPena5:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena5,
        delEscnario8antiguedadPena6:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena6,
        delEscnario8antiguedadPena7:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena7,
        delEscnario8antiguedadPena8:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena8,
        delEscnario8antiguedadPena9:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena9,
        delEscnario8antiguedadPena10:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena10,
        delEscnario8antiguedadPena11:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena11,
        delEscnario8antiguedadPena12:
          docDos.data().datosNuevaCalculadora.delEscnario8antiguedadPena12,

        //ENDEUDAMIENTO PORCETAJE LIMITE
        // ESCENARIO 0
        delEscnario0porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento1,
        delEscnario0porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento2,
        delEscnario0porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento3,
        delEscnario0porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento4,
        delEscnario0porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento5,
        delEscnario0porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento6,
        delEscnario0porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento7,
        delEscnario0porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento8,
        delEscnario0porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento9,
        delEscnario0porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento10,
        delEscnario0porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento11,
        delEscnario0porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario0porcentajeEndeudamiento12,

        //ESCENARIO 1
        delEscnario1porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento1,
        delEscnario1porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento2,
        delEscnario1porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento3,
        delEscnario1porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento4,
        delEscnario1porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento5,
        delEscnario1porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento6,
        delEscnario1porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento7,
        delEscnario1porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento8,
        delEscnario1porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento9,
        delEscnario1porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento10,
        delEscnario1porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento11,
        delEscnario1porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario1porcentajeEndeudamiento12,

        //ESCENARIO 2
        delEscnario2porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento1,
        delEscnario2porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento2,
        delEscnario2porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento3,
        delEscnario2porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento4,
        delEscnario2porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento5,
        delEscnario2porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento6,
        delEscnario2porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento7,
        delEscnario2porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento8,
        delEscnario2porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento9,
        delEscnario2porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento10,
        delEscnario2porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento11,
        delEscnario2porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario2porcentajeEndeudamiento12,

        //ESCENARIO 3
        delEscnario3porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento1,
        delEscnario3porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento2,
        delEscnario3porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento3,
        delEscnario3porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento4,
        delEscnario3porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento5,
        delEscnario3porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento6,
        delEscnario3porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento7,
        delEscnario3porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento8,
        delEscnario3porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento9,
        delEscnario3porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento10,
        delEscnario3porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento11,
        delEscnario3porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario3porcentajeEndeudamiento12,

        //ESCENARIO 4
        delEscnario4porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento1,
        delEscnario4porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento2,
        delEscnario4porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento3,
        delEscnario4porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento4,
        delEscnario4porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento5,
        delEscnario4porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento6,
        delEscnario4porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento7,
        delEscnario4porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento8,
        delEscnario4porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento9,
        delEscnario4porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento10,
        delEscnario4porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento11,
        delEscnario4porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario4porcentajeEndeudamiento12,

        //ESCENARIO 5
        delEscnario5porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento1,
        delEscnario5porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento2,
        delEscnario5porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento3,
        delEscnario5porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento4,
        delEscnario5porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento5,
        delEscnario5porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento6,
        delEscnario5porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento7,
        delEscnario5porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento8,
        delEscnario5porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento9,
        delEscnario5porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento10,
        delEscnario5porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento11,
        delEscnario5porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario5porcentajeEndeudamiento12,

        //ESCENARIO 6
        delEscnario6porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento1,
        delEscnario6porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento2,
        delEscnario6porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento3,
        delEscnario6porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento4,
        delEscnario6porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento5,
        delEscnario6porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento6,
        delEscnario6porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento7,
        delEscnario6porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento8,
        delEscnario6porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento9,
        delEscnario6porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento10,
        delEscnario6porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento11,
        delEscnario6porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario6porcentajeEndeudamiento12,

        //ESCENARIO 7
        delEscnario7porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento1,
        delEscnario7porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento2,
        delEscnario7porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento3,
        delEscnario7porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento4,
        delEscnario7porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento5,
        delEscnario7porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento6,
        delEscnario7porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento7,
        delEscnario7porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento8,
        delEscnario7porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento9,
        delEscnario7porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento10,
        delEscnario7porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento11,
        delEscnario7porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario7porcentajeEndeudamiento12,

        //ESCENARIO 8
        delEscnario8porcentajeEndeudamiento1:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento1,
        delEscnario8porcentajeEndeudamiento2:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento2,
        delEscnario8porcentajeEndeudamiento3:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento3,
        delEscnario8porcentajeEndeudamiento4:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento4,
        delEscnario8porcentajeEndeudamiento5:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento5,
        delEscnario8porcentajeEndeudamiento6:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento6,
        delEscnario8porcentajeEndeudamiento7:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento7,
        delEscnario8porcentajeEndeudamiento8:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento8,
        delEscnario8porcentajeEndeudamiento9:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento9,
        delEscnario8porcentajeEndeudamiento10:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento10,
        delEscnario8porcentajeEndeudamiento11:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento11,
        delEscnario8porcentajeEndeudamiento12:
          docDos.data().datosNuevaCalculadora
            .delEscnario8porcentajeEndeudamiento12,

        // //ENDEUDAMIENTO PORCENTAJE PENA
        //ESCENARIO 0
        delEscnario0penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento1,
        delEscnario0penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento2,
        delEscnario0penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento3,
        delEscnario0penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento4,
        delEscnario0penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento5,
        delEscnario0penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento6,
        delEscnario0penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento7,
        delEscnario0penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento8,
        delEscnario0penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento9,
        delEscnario0penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento10,
        delEscnario0penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento11,
        delEscnario0penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario0penaEndeudamiento12,

        //ESCENARIO 1
        delEscnario1penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento1,
        delEscnario1penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento2,
        delEscnario1penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento3,
        delEscnario1penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento4,
        delEscnario1penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento5,
        delEscnario1penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento6,
        delEscnario1penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento7,
        delEscnario1penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento8,
        delEscnario1penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento9,
        delEscnario1penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento10,
        delEscnario1penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento11,
        delEscnario1penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario1penaEndeudamiento12,

        //ESCENARIO 2
        delEscnario2penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento1,
        delEscnario2penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento2,
        delEscnario2penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento3,
        delEscnario2penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento4,
        delEscnario2penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento5,
        delEscnario2penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento6,
        delEscnario2penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento7,
        delEscnario2penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento8,
        delEscnario2penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento9,
        delEscnario2penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento10,
        delEscnario2penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento11,
        delEscnario2penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario2penaEndeudamiento12,

        //ESCENARIO 3
        delEscnario3penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento1,
        delEscnario3penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento2,
        delEscnario3penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento3,
        delEscnario3penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento4,
        delEscnario3penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento5,
        delEscnario3penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento6,
        delEscnario3penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento7,
        delEscnario3penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento8,
        delEscnario3penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento9,
        delEscnario3penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento10,
        delEscnario3penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento11,
        delEscnario3penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario3penaEndeudamiento12,

        //ESCENARIO 4
        delEscnario4penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento1,
        delEscnario4penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento2,
        delEscnario4penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento3,
        delEscnario4penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento4,
        delEscnario4penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento5,
        delEscnario4penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento6,
        delEscnario4penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento7,
        delEscnario4penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento8,
        delEscnario4penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento9,
        delEscnario4penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento10,
        delEscnario4penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento11,
        delEscnario4penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario4penaEndeudamiento12,

        //ESCENARIO 5
        delEscnario5penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento1,
        delEscnario5penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento2,
        delEscnario5penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento3,
        delEscnario5penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento4,
        delEscnario5penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento5,
        delEscnario5penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento6,
        delEscnario5penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento7,
        delEscnario5penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento8,
        delEscnario5penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento9,
        delEscnario5penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento10,
        delEscnario5penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento11,
        delEscnario5penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario5penaEndeudamiento12,

        //ESCENARIO 6
        delEscnario6penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento1,
        delEscnario6penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento2,
        delEscnario6penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento3,
        delEscnario6penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento4,
        delEscnario6penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento5,
        delEscnario6penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento6,
        delEscnario6penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento7,
        delEscnario6penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento8,
        delEscnario6penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento9,
        delEscnario6penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento10,
        delEscnario6penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento11,
        delEscnario6penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario6penaEndeudamiento12,

        //ESCENARIO 7
        delEscnario7penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento1,
        delEscnario7penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento2,
        delEscnario7penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento3,
        delEscnario7penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento4,
        delEscnario7penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento5,
        delEscnario7penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento6,
        delEscnario7penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento7,
        delEscnario7penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento8,
        delEscnario7penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento9,
        delEscnario7penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento10,
        delEscnario7penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento11,
        delEscnario7penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario7penaEndeudamiento12,

        //ESCENARIO 8
        delEscnario8penaEndeudamiento1:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento1,
        delEscnario8penaEndeudamiento2:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento2,
        delEscnario8penaEndeudamiento3:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento3,
        delEscnario8penaEndeudamiento4:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento4,
        delEscnario8penaEndeudamiento5:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento5,
        delEscnario8penaEndeudamiento6:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento6,
        delEscnario8penaEndeudamiento7:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento7,
        delEscnario8penaEndeudamiento8:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento8,
        delEscnario8penaEndeudamiento9:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento9,
        delEscnario8penaEndeudamiento10:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento10,
        delEscnario8penaEndeudamiento11:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento11,
        delEscnario8penaEndeudamiento12:
          docDos.data().datosNuevaCalculadora.delEscnario8penaEndeudamiento12,
      };
      calculadoras.push(calculadora);
    });
    setDatosNuevaCalculadora(calculadoras);
  }

  function setearParaModificar(calcu) {
    setModificar(true);
    setOptCalcus(1);
    setNewEscenario(calcu.numEsc);
    escnarios.splice(0, escnarios.length);
    for (let i = 0; i < calcu.numEsc; i++) {
      escnarios.push("Escenario " + i);
    }
    setDatosNuevaCalculadora(calcu);
    setidCalcuMod(calcu.id);
    setestadoCrearCalculadora([
      {
        name: true,
      },
      {
        name: false,
      },
      {
        name: false,
      },
    ]);
  }

  function mostrarAlert() {
    Swal.fire({
      title: "Atención!!",
      text: "Es importante que las mejoras se anoten de la siguiente forma. Ejemplo: ma1- 25 , ma2 - 25 , ma4 - 50 , ma6 25. Siempre separandolas por una coma y su penalización por un guion, en caso de no tener ninguna mejora colocar un 0. Recordatorio de siglas Mejoras antiguedad ma1, segunda antiguedad md1, ratio mr1 y garantia mg1",
      icon: "info",
      confirmButtonColor: "#8FCD60",
    });
  }

  function setearEscenario(numEscenario) {
    escnarios.splice(0, escnarios.length);
    for (let i = 0; i < numEscenario; i++) {
      escnarios.push("Escenario " + i);
      console.log(escnarios);
    }
    setOptCalcus(1);
    setNewEscenario(numEscenario);
  }

  function next(e, opt) {
    e.preventDefault();
    setOptCalcus(opt);
  }

  return (
    <section className="contenedor-calculadora-creacion-firmax">
      <img
        onClick={() => console.log(datosNuevaCalculadora)}
        className="logo-calculadora"
        src={logo}
        alt="logo_firma"
      />

      <div className="contenedor-buttons-calcu">
        <button
          onClick={crearCalculo}
          className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
        >
          Crear
        </button>
        <button
          onClick={mostrarFormDelete}
          className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
        >
          Eliminar
        </button>
        <button
          onClick={calc}
          className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
        >
          Modificar
        </button>
        {/* <button onClick={()=>console.log(datosNuevaCalculadora)}>asd</button> */}
      </div>

      {estadoCrearCalculadora[0].name ? (
        <>
          {optCalcus === 0 ? (
            <form className="contenedor-calculadora-creacion-firmax-form">
              <label>
                0. Estas a punto de iniciar la creacion de una nueva
                calculadora, para ello deberas seleccionar el numero de
                escenarios que tendra.
              </label>
              <div className="contenedor-calculadora-creacion-firmax-form-opts-buttons">
                <button
                  onClick={() => setearEscenario(3)}
                  className="btn-crear-calculadora"
                >
                  3 escenarios
                </button>
                <button
                  onClick={() => setearEscenario(4)}
                  className="btn-crear-calculadora"
                >
                  4 escenarios
                </button>
                <button
                  onClick={() => setearEscenario(5)}
                  className="btn-crear-calculadora"
                >
                  5 escenarios
                </button>
                <button
                  onClick={() => setearEscenario(6)}
                  className="btn-crear-calculadora"
                >
                  6 escenarios
                </button>
                <button
                  onClick={() => setearEscenario(7)}
                  className="btn-crear-calculadora"
                >
                  7 escenarios
                </button>
                <button
                  onClick={() => setearEscenario(8)}
                  className="btn-crear-calculadora"
                >
                  8 escenarios
                </button>
              </div>
            </form>
          ) : null}

          {optCalcus === 1 ? (
            <>
              <form
                className="contenedor-calculadora-creacion-firmax-form"
                onSubmit={(e) => next(e, 2)}
              >
                <label className="font">
                  Paso uno: Datos generales de la Hipoteca
                </label>
                <div className="contenedor-calculadora-creacion-firmax-form-row">
                  <label htmlFor="">Nombre interino</label>
                  <input
                    onChange={valueChangeCalculadora}
                    value={datosNuevaCalculadora.nombre}
                    name="nombre"
                    type="text"
                  />
                </div>

                <div className="contenedor-calculadora-creacion-firmax-form-row">
                  <label htmlFor="">Nombre visualizado</label>
                  <input
                    required
                    onChange={valueChangeCalculadora}
                    name="nombreInmobiliaria"
                    type="text"
                    value={datosNuevaCalculadora.nombreInmobiliaria}
                  />
                </div>

                <div className="contenedor-calculadora-creacion-firmax-form-row">
                  <label htmlFor="">Producto Hipotecario: </label>
                  <input
                    required
                    onChange={valueChangeCalculadora}
                    type="text"
                    name="productoHipotecario"
                    value={datosNuevaCalculadora.productoHipotecario}
                  />
                </div>

                <div className="contenedor-calculadora-creacion-firmax-form-row">
                  <label htmlFor="">Bonificación: </label>
                  <input
                    required
                    onChange={valueChangeCalculadora}
                    type="text"
                    name="bonificacion"
                    value={datosNuevaCalculadora.bonificacion}
                  />
                </div>

                <div className="contenedor-calculadora-creacion-firmax-form-row">
                  <label htmlFor="">Hipoteca Minima: </label>
                  <div style={{ display: "flex" }}>
                    <input
                      style={{ width: "80px" }}
                      required
                      onChange={valueChangeCalculadora}
                      type="number"
                      name="hipotecaMinima"
                      value={datosNuevaCalculadora.hipotecaMinima}
                    />
                    <p>€</p>
                  </div>
                </div>

                <div className="contenedor-calculadora-creacion-firmax-form-row">
                  <label htmlFor="">Hipoteca Maxima: </label>
                  <div style={{ display: "flex" }}>
                    <input
                      required
                      style={{ width: "80px" }}
                      onChange={valueChangeCalculadora}
                      type="number"
                      name="hipotecaMaxima"
                      value={datosNuevaCalculadora.hipotecaMaxima}
                    />
                    <p>€</p>
                  </div>
                </div>
                <div className="contenedor-calculadora-creacion-firmax-form-row">
                  <label htmlFor="">% máximo sobre Tasación: </label>
                  <div style={{ display: "flex" }}>
                    <input
                      required
                      style={{ width: "80px" }}
                      onChange={valueChangeCalculadora}
                      type="number"
                      name="maximoSobreTasacion"
                      value={datosNuevaCalculadora.maximoSobreTasacion}
                    />
                    <p>%</p>
                  </div>
                </div>
                <div className="cont-opt-next">
                  <button
                    onClick={() => setOptCalcus(0)}
                    style={{ background: "tomato" }}
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Atras
                  </button>
                  <button
                    type="submit"
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Siguiente
                  </button>
                </div>
              </form>
            </>
          ) : null}

          {optCalcus === 2 ? (
            <>
              <form
                className="contenedor-calculadora-creacion-firmax-form"
                onSubmit={(e) => next(e, 3)}
              >
                <label className="font">Paso dos: Limite de plazos</label>
                <div className="contenedor-calculadora-creacion-firmax-form-grid">
                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Plazo FIJO minimo total:</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="plazoFijoMinimo"
                        value={datosNuevaCalculadora.plazoFijoMinimo}
                      />
                      <p>años</p>
                    </div>
                  </div>
                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Plazo FIJO maximo total:</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="plazoFijoMaximo"
                        value={datosNuevaCalculadora.plazoFijoMaximo}
                      />
                      <p>años</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Plazo VARIABLE minimo total</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="plazoVariableMinimo"
                        value={datosNuevaCalculadora.plazoVariableMinimo}
                      />
                      <p>años</p>
                    </div>
                  </div>
                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Plazo VARIABLE maximo total</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="plazoVariableMaximo"
                        value={datosNuevaCalculadora.plazoVariableMaximo}
                      />
                      <p>años</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Plazo MIXTO minimo total</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="plazoMixtoMinimo"
                        value={datosNuevaCalculadora.plazoMixtoMinimo}
                      />
                      <p>años</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Plazo MIXTO maximo total</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="plazoMixtoMaximo"
                        value={datosNuevaCalculadora.plazoMixtoMaximo}
                      />
                      <p>años</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">1er período VARIABLE:</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="primerPeriodoVariable"
                        value={datosNuevaCalculadora.primerPeriodoVariable}
                      />
                      <p>meses</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">1er período MIXTO:</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="primerPeriodoMixto"
                        value={datosNuevaCalculadora.primerPeriodoMixto}
                      />
                      <p>meses</p>
                    </div>
                  </div>
                </div>
                <div className="cont-opt-next">
                  <button
                    onClick={() => setOptCalcus(1)}
                    style={{ background: "tomato" }}
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Atras
                  </button>
                  <button
                    type="submit"
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Siguiente
                  </button>
                </div>
              </form>
            </>
          ) : null}

          {optCalcus === 3 ? (
            <>
              <form
                className="contenedor-calculadora-creacion-firmax-form"
                onSubmit={(e) => next(e, 4)}
              >
                <label className="font">
                  Paso tres: Detalla los tipos de interes aplicable
                </label>

                <div className="contenedor-calculadora-creacion-firmax-form-grid grid-tres">
                  <div className="contenedor-calculadora-creacion-firmax-form-row ">
                    <label style={{ fontSize: "24px" }} htmlFor="">
                      Plazos - interes FIJO
                    </label>
                    <label htmlFor="">Mostrar hipoteca?</label>
                    <select
                      name="mostrarHipotecaFija"
                      onChange={valueChangeCalculadora}
                      value={datosNuevaCalculadora.mostrarHipotecaFija}
                      style={{
                        fontSize: "18px",
                        width: "150px",
                        height: "30px",
                      }}
                      required
                    >
                      <option name="mostrarHipotecaFija" value="si">
                        Si
                      </option>
                      <option name="mostrarHipotecaFija" value="no">
                        No
                      </option>
                    </select>

                    <label htmlFor="">De 5 a 10 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaDiezFijo"
                        value={datosNuevaCalculadora.hastaDiezFijo}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 11 a 15 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaQuinceFijo"
                        value={datosNuevaCalculadora.hastaQuinceFijo}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 16 a 20 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        style={{ width: "40px", textAlign: "center" }}
                        type="number"
                        name="hastaVeinteFijo"
                        value={datosNuevaCalculadora.hastaVeinteFijo}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 21 a 25 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaVeinteCincoFijo"
                        value={datosNuevaCalculadora.hastaVeinteCincoFijo}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 26 a 30 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaTreintaFijo"
                        value={datosNuevaCalculadora.hastaTreintaFijo}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 31 a 35 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        style={{ width: "40px", textAlign: "center" }}
                        type="number"
                        name="hastaTreintaCincoFijo"
                        value={datosNuevaCalculadora.hastaTreintaCincoFijo}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 36 a 40 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        style={{ width: "40px", textAlign: "center" }}
                        type="number"
                        name="hastaCuarentaFijo"
                        value={datosNuevaCalculadora.hastaCuarentaFijo}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 41 a 45 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        style={{ width: "40px", textAlign: "center" }}
                        type="number"
                        name="hastaCuarentaCincoFijo"
                        value={datosNuevaCalculadora.hastaCuarentaCincoFijo}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 46 a 50 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        style={{ width: "40px", textAlign: "center" }}
                        name="hastaCincuentFijo"
                        value={datosNuevaCalculadora.hastaCincuentFijo}
                      />
                      <p>%</p>
                    </div>
                  </div>
                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label style={{ fontSize: "24px" }} htmlFor="">
                      Plazos - interes Variable
                    </label>

                    <label htmlFor="">Mostrar hipoteca?</label>
                    <select
                      name="mostrarHipotecaVariable"
                      onChange={valueChangeCalculadora}
                      value={datosNuevaCalculadora.mostrarHipotecaVariable}
                      style={{
                        fontSize: "18px",
                        width: "150px",
                        height: "30px",
                      }}
                      required
                    >
                      <option name="mostrarHipotecaVariable" value="si">
                        Si
                      </option>
                      <option name="mostrarHipotecaVariable" value="no">
                        No
                      </option>
                    </select>

                    <label htmlFor="">De 5 a 10 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaDiezVariable"
                        value={datosNuevaCalculadora.hastaDiezVariable}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 11 a 15 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaQuinceVariable"
                        value={datosNuevaCalculadora.hastaQuinceVariable}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 16 a 20 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaVeinteVariable"
                        value={datosNuevaCalculadora.hastaVeinteVariable}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 21 a 25 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaVeinteCincoVariable"
                        value={datosNuevaCalculadora.hastaVeinteCincoVariable}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 26 a 30 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaTreintaVariable"
                        value={datosNuevaCalculadora.hastaTreintaVariable}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 31 a 35 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaTreintaCincoVariable"
                        value={datosNuevaCalculadora.hastaTreintaCincoVariable}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 36 a 40 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaCuarentaVariable"
                        value={datosNuevaCalculadora.hastaCuarentaVariable}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 41 a 45 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaCuarentaCincoVariable"
                        value={datosNuevaCalculadora.hastaCuarentaCincoVariable}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 46 a 50 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaCincuentVariable"
                        value={datosNuevaCalculadora.hastaCincuentVariable}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <div className="contenedor-calculadora-creacion-firmax-form-row">
                      <label htmlFor="">TIN primeros meses variable:</label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <input
                          required
                          onChange={valueChangeCalculadora}
                          type="number"
                          step="any"
                          name="tinPrimerosMesesVariable"
                          value={datosNuevaCalculadora.tinPrimerosMesesVariable}
                          style={{ width: "40px", textAlign: "center" }}
                        />
                        <p>%</p>
                      </div>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label style={{ fontSize: "24px" }} htmlFor="">
                      Plazos - interes Mixto
                    </label>

                    <label htmlFor="">Mostrar hipoteca?</label>
                    <select
                      name="mostrarHipotecaMixta"
                      onChange={valueChangeCalculadora}
                      value={datosNuevaCalculadora.mostrarHipotecaMixta}
                      style={{
                        fontSize: "18px",
                        width: "150px",
                        height: "30px",
                      }}
                      required
                    >
                      <option name="mostrarHipotecaMixta" value="si">
                        Si
                      </option>
                      <option name="mostrarHipotecaMixta" value="no">
                        No
                      </option>
                    </select>

                    <label htmlFor="">De 5 a 10 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaDiezMixto"
                        value={datosNuevaCalculadora.hastaDiezMixto}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 11 a 15 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        style={{ width: "40px", textAlign: "center" }}
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaQuinceMixto"
                        value={datosNuevaCalculadora.hastaQuinceMixto}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 16 a 20 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaVeinteMixto"
                        value={datosNuevaCalculadora.hastaVeinteMixto}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 21 a 25 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaVeinteCincoMixto"
                        value={datosNuevaCalculadora.hastaVeinteCincoMixto}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 26 a 30 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaTreintaMixto"
                        value={datosNuevaCalculadora.hastaTreintaMixto}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 31 a 35 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaTreintaCincoMixto"
                        value={datosNuevaCalculadora.hastaTreintaCincoMixto}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 36 a 40 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaCuarentaMixto"
                        value={datosNuevaCalculadora.hastaCuarentaMixto}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 41 a 45 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaCuarentaCincoMixto"
                        value={datosNuevaCalculadora.hastaCuarentaCincoMixto}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <label htmlFor="">De 46 a 50 años</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="hastaCincuentMixto"
                        value={datosNuevaCalculadora.hastaCincuentMixto}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>

                    <div className="contenedor-calculadora-creacion-firmax-form-row">
                      <label htmlFor="">TIN primeros meses mixto:</label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <input
                          required
                          onChange={valueChangeCalculadora}
                          type="number"
                          step="any"
                          name="tinPrimerosMesesMixto"
                          value={datosNuevaCalculadora.tinPrimerosMesesMixto}
                          style={{ width: "40px", textAlign: "center" }}
                        />
                        <p>%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cont-opt-next">
                  <button
                    onClick={() => setOptCalcus(2)}
                    style={{ background: "tomato" }}
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Atras
                  </button>
                  <button
                    type="submit"
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Siguiente
                  </button>
                </div>
              </form>
            </>
          ) : null}

          {optCalcus === 4 ? (
            <>
              <form
                className="contenedor-calculadora-creacion-firmax-form"
                onSubmit={(e) => next(e, 5)}
              >
                <label className="font">
                  Paso cuatro: Configura los honorarios y comisiones
                </label>
                <div className="contenedor-calculadora-creacion-firmax-form-grid">
                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">% delimitante = o menor al:</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        name="porcentajeDelimitante"
                        type="number"
                        value={datosNuevaCalculadora.porcentajeDelimitante}
                        style={{ width: "80px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label>H. Minimos inferior al % delimitante</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        name="honorariosMinimoInferior"
                        type="number"
                        value={datosNuevaCalculadora.honorariosMinimoInferior}
                        style={{ width: "80px", textAlign: "center" }}
                      />
                      <p>€</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label>H. Minimos superior al % delimitante</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        name="honorariosMaximoInferior"
                        type="number"
                        value={datosNuevaCalculadora.honorariosMaximoInferior}
                        style={{ width: "80px", textAlign: "center" }}
                      />
                      <p>€</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Comisión mínima al colaborador</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        name="comisionMinima"
                        type="number"
                        value={datosNuevaCalculadora.comisionMinima}
                        style={{ width: "80px", textAlign: "center" }}
                      />
                      <p>€</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">
                      C. Sobre el 1° incremento en S. Inferior al % limitante
                    </label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        name="comisionPrimeraIncremnto"
                        type="number"
                        value={datosNuevaCalculadora.comisionPrimeraIncremnto}
                        style={{ width: "80px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">
                      C. Sobre el 1° incremento en S. Superior al % limitante
                    </label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        name="comisionExtraIncremento"
                        type="number"
                        value={datosNuevaCalculadora.comisionExtraIncremento}
                        style={{ width: "80px", textAlign: "center" }}
                      />
                      <p>%</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Mostrar comision si/no</label>
                    <select
                      name="mostrarComision"
                      onChange={valueChangeCalculadora}
                      value={datosNuevaCalculadora.mostrarComision}
                      style={{
                        fontSize: "18px",
                        width: "150px",
                        height: "30px",
                      }}
                    >
                      <option name="mostrarComision" value="null">
                        Seleccione
                      </option>
                      <option name="mostrarComision" value="si">
                        Si
                      </option>
                      <option name="mostrarComision" value="no">
                        No
                      </option>
                    </select>
                    {/* <input
                      required
                      onChange={valueChangeCalculadora}
                      type="text"
                      name="mostrarComision"
                      value={datosNuevaCalculadora.mostrarComision}
                    /> */}
                  </div>
                </div>
                <div className="cont-opt-next">
                  <button
                    onClick={() => setOptCalcus(3)}
                    style={{ background: "tomato" }}
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Atras
                  </button>
                  <button
                    type="submit"
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Siguiente
                  </button>
                </div>
              </form>
            </>
          ) : null}

          {optCalcus === 5 ? (
            <>
              <form
                className="contenedor-calculadora-creacion-firmax-form"
                onSubmit={(e) => next(e, 6)}
              >
                <label className="font">
                  Paso cinco: Configura las edades de los x titulares
                </label>
                <div className="contenedor-calculadora-creacion-firmax-form-grid">
                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">
                      Seleccione la opcion predeterminada
                    </label>
                    <select
                      style={{
                        fontSize: "18px",
                        width: "180px",
                        height: "30px",
                      }}
                      value={datosNuevaCalculadora.incuirEdadtitualMenor}
                      name="incuirEdadtitualMenor"
                      onChange={valueChangeCalculadora}
                    >
                      <option name="mostrarComision" value="null">
                        Seleccione
                      </option>
                      <option name="incuirEdadtitualMenor" value="si">
                        Edad del menor
                      </option>
                      <option name="incuirEdadtitualMenor" value="no">
                        Edad del mayor
                      </option>
                    </select>
                    {/* <input
                      required
                      onChange={valueChangeCalculadora}
                      type="text"
                      name="incuirEdadtitualMenor"
                      value={datosNuevaCalculadora.incuirEdadtitualMenor}
                    /> */}
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Edad maxima del mayor</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="edadMaximaTitular"
                        value={datosNuevaCalculadora.edadMaximaTitular}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>años</p>
                    </div>
                  </div>

                  <div className="contenedor-calculadora-creacion-firmax-form-row">
                    <label htmlFor="">Edad maxima del menor</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <input
                        required
                        onChange={valueChangeCalculadora}
                        type="number"
                        name="edadMaximaTitularMenor"
                        value={datosNuevaCalculadora.edadMaximaTitularMenor}
                        style={{ width: "40px", textAlign: "center" }}
                      />
                      <p>años</p>
                    </div>
                  </div>
                </div>

                <div className="cont-opt-next">
                  <button
                    onClick={() => setOptCalcus(4)}
                    style={{ background: "tomato" }}
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Atras
                  </button>
                  <button
                    type="submit"
                    className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                  >
                    Siguiente
                  </button>
                </div>
              </form>
            </>
          ) : null}

          {optCalcus === 6 ? (
            <>
              <form
                className="contenedor-calculadora-creacion-firmax-form"
                onSubmit={modificar ? modificarEnBD : guardarEnBD}
              >
                <div className="grid">
                  {escnarios.map((escnario, index) => (
                    <div
                      key={index}
                      className="escenarios-container-individual"
                    >
                      <h1 style={{ paddingBottom: "2rem", background: "#fff" }}>
                        {escnario}
                      </h1>
                      <section
                        style={{
                          display: "flex",
                          gap: "1rem",
                          justifyContent: "center",
                          background: "#fff",
                        }}
                      >
                        <div>
                          <label>Del:</label>
                          <input
                            required
                            value={datosNuevaCalculadora[`delEscnario${index}`]}
                            name={`delEscnario${index}`}
                            type="number"
                            onChange={valueChangeCalculadora}
                            style={{ width: "40px", textAlign: "center" }}
                          />
                          <label style={{ marginRight: "35px" }}>%</label>
                          <label>Al: </label>
                          <input
                            required
                            value={datosNuevaCalculadora[`alEscnario${index}`]}
                            name={`alEscnario${index}`}
                            type="number"
                            onChange={valueChangeCalculadora}
                            style={{ width: "40px", textAlign: "center" }}
                          />
                          <label>%</label>
                        </div>
                      </section>
                      {contratoBD.map((contrato) => (
                        <div
                          key={contrato.nombre}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "1rem",
                            background: "#fff",
                          }}
                        >
                          {/* <h1>del: {datosNuevaCalculadora[`delEscnario${index}`]}%</h1> */}
                          <section
                            style={{
                              border: "solid 1px rgba(0, 0, 0, .3)",
                              background: "#fff",
                              padding: "1rem",
                              position: "relative",
                            }}
                          >
                            <p
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                color: "rgba(0,0,0,.5)",
                              }}
                            >
                              Del:{" "}
                              {datosNuevaCalculadora[`delEscnario${index}`]}%
                              al: {datosNuevaCalculadora[`alEscnario${index}`]}%
                            </p>
                            <h3 style={{ background: "#fff" }}>
                              {contrato.nombre}
                            </h3>
                            <h3
                              style={{ paddingTop: "1rem", background: "#fff" }}
                            >
                              Antiguedad
                            </h3>
                            <label style={{ paddingRight: "0.5rem" }}>
                              Menor a:
                            </label>
                            {/* placeholder={datosNuevaCalculadora.datosNuevaCalculadora[`alEscnario${index}`]} est place holder es lo q va a salir */}
                            <input
                              required
                              style={{ width: "50px", textAlign: "center" }}
                              value={
                                datosNuevaCalculadora[
                                  `delEscnario${index}meses${contrato.id}`
                                ]
                              }
                              name={`delEscnario${index}meses${contrato.id}`}
                              type="number"
                              onChange={valueChangeCalculadora}
                            />
                            <label>meses</label>
                            <label style={{ paddingLeft: "1rem" }}>
                              Penalización:
                            </label>
                            <input
                              required
                              style={{ width: "50px", textAlign: "center" }}
                              value={
                                datosNuevaCalculadora[
                                  `delEscnario${index}pena${contrato.id}`
                                ]
                              }
                              name={`delEscnario${index}pena${contrato.id}`}
                              type="number"
                              onChange={valueChangeCalculadora}
                            />
                            <label>%</label>
                            <h3
                              style={{ paddingTop: "1rem", background: "#fff" }}
                            >
                              Antiguedad dos
                            </h3>
                            <label
                              style={{
                                paddingRight: "0.5rem",
                                background: "#fff",
                              }}
                            >
                              Igual o mayor a:
                            </label>
                            <input
                              required
                              style={{
                                width: "50px",
                                textAlign: "center",
                                background: "#fff",
                              }}
                              value={
                                datosNuevaCalculadora[
                                  `delEscnario${index}antiguedadMeses${contrato.id}`
                                ]
                              }
                              name={`delEscnario${index}antiguedadMeses${contrato.id}`}
                              type="number"
                              onChange={valueChangeCalculadora}
                            />
                            <label>meses</label>
                            <label
                              style={{
                                paddingLeft: "1rem",
                                background: "#fff",
                              }}
                            >
                              Penalización:
                            </label>
                            <input
                              required
                              style={{ width: "50px", textAlign: "center" }}
                              value={
                                datosNuevaCalculadora[
                                  `delEscnario${index}antiguedadPena${contrato.id}`
                                ]
                              }
                              name={`delEscnario${index}antiguedadPena${contrato.id}`}
                              type="number"
                              onChange={valueChangeCalculadora}
                            />
                            <label>%</label>
                            <h3
                              style={{ paddingTop: "1rem", background: "#fff" }}
                            >
                              Endeudamiento
                            </h3>
                            <label style={{ paddingRight: "0.5rem" }}>
                              Mayor a %:
                            </label>
                            <input
                              required
                              style={{ width: "50px", textAlign: "center" }}
                              value={
                                datosNuevaCalculadora[
                                  `delEscnario${index}porcentajeEndeudamiento${contrato.id}`
                                ]
                              }
                              name={`delEscnario${index}porcentajeEndeudamiento${contrato.id}`}
                              type="number"
                              onChange={valueChangeCalculadora}
                            />
                            <label>meses</label>
                            <label style={{ paddingLeft: "1rem" }}>
                              Penalización:
                            </label>
                            <input
                              required
                              style={{ width: "50px", textAlign: "center" }}
                              value={
                                datosNuevaCalculadora[
                                  `delEscnario${index}penaEndeudamiento${contrato.id}`
                                ]
                              }
                              name={`delEscnario${index}penaEndeudamiento${contrato.id}`}
                              type="number"
                              onChange={valueChangeCalculadora}
                            />
                            <label>%</label>

                            <h3
                              style={{ paddingTop: "1rem", background: "#fff" }}
                            >
                              Doble garantia
                            </h3>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <label>Mostrar cartel:</label>
                              <select
                                name={`delEscnario${index}Limite${contrato.id}`}
                                onChange={valueChangeCalculadora}
                                value={
                                  datosNuevaCalculadora[
                                    `delEscnario${index}Limite${contrato.id}`
                                  ]
                                }
                                style={{
                                  fontSize: "18px",
                                  width: "70px",
                                  height: "30px",
                                  marginRight: "10px",
                                }}
                              >
                                <option>Seleccione</option>
                                <option
                                  name={`delEscnario${index}Limite${contrato.id}`}
                                  value="si"
                                >
                                  Si
                                </option>
                                <option
                                  name={`delEscnario${index}Limite${contrato.id}`}
                                  value="no"
                                >
                                  No
                                </option>
                              </select>
                              <label style={{ paddingRight: "0.5rem" }}>
                                Penalización de:
                              </label>
                              <input
                                required
                                style={{ width: "50px", textAlign: "center" }}
                                value={
                                  datosNuevaCalculadora[
                                    `delEscnario${index}DobleGarantia${contrato.id}`
                                  ]
                                }
                                name={`delEscnario${index}DobleGarantia${contrato.id}`}
                                type="number"
                                onChange={valueChangeCalculadora}
                              />
                              <label>%</label>
                            </div>

                            <h3
                              style={{ paddingTop: "1rem", background: "#fff" }}
                            >
                              Mejoras
                            </h3>
                            <i
                              onClick={mostrarAlert}
                              className="bx bxs-alarm-exclamation leyenda-grid-alarm leyenda-crear-calc"
                            ></i>
                            <input
                              required
                              style={{
                                borderBottom: "solid 1px rgba(0,0,0,.8)",
                                textAlign: "center",
                                margin: "2rem 0",
                              }}
                              value={
                                datosNuevaCalculadora[
                                  `delEscnario${index}Mejoras${contrato.id}`
                                ]
                              }
                              name={`delEscnario${index}Mejoras${contrato.id}`}
                              onChange={valueChangeCalculadora}
                            />
                          </section>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <button
                  onClick={() => setOptCalcus(5)}
                  style={{ background: "tomato", width: "100%" }}
                  className="contenedor-calculadora-creacion-firmax-form-button width-button-calcu"
                >
                  Atras
                </button>
                <button
                  type="submit"
                  className="contenedor-calculadora-creacion-firmax-form-button"
                >
                  {modificar ? "Modificar calculadora" : "Guardar calculadora"}
                </button>
              </form>
            </>
          ) : null}
        </>
      ) : null}

      {estadoCrearCalculadora[1].name ? (
        <section className="contenedor-modificcar-calcu">
          <select className="select-size" onChange={handleModificateChange}>
            <option value="">Seleccione la opción que desea</option>
            {calcusExistentes.map((calcu) => (
              <option key={calcu.id} value={calcu.params.nombre}>
                {calcu.params.nombre}
              </option>
            ))}
          </select>
          <button
            className="contenedor-calculadora-creacion-firmax-form-button"
            onClick={eliminarCalcu}
          >
            Eliminar calculadora
          </button>
        </section>
      ) : null}

      {estadoCrearCalculadora[2].name ? (
        <section className="contenedor-buttons-setCal">
          {datosNuevaCalculadora.length > 0
            ? datosNuevaCalculadora.map((calcu) => (
                <button
                  onClick={() => setearParaModificar(calcu)}
                  className="btn-crear-calculadora"
                  key={calcu.nombre}
                  value=""
                >
                  {calcu.nombre}
                </button>
              ))
            : null}
        </section>
      ) : null}

      <div
        onClick={() => navigate("/firmax")}
        className="section-firmax-form-container-back"
      >
        <i className="bx bx-left-arrow-alt"></i>
        <p>Volver</p>
      </div>
    </section>
  );
};

export default CrearCalculadora;
