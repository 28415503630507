import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import firma from "../../../img/frma.png";
import logo from "../../../img/logo.png";
import logoCuadrado from "../../../img/logoCuadrado.jpg";

function ContratoAcargoDelVendedor({
  datosCuestionario,
  euribor,
  aniosAmeses,
  setearDatosCalculadora,
  innteresVariable,
  innteresFija,
  fecha,
  optMejoraGarantia,
  optMejoraAntiguedad,
  mesesInteres,
  solicitudHipotecaria
}) {
  return (
    <Document>
      <Page size="A4" style={{ fontSize: "10px", padding: "10px" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1",
            borderColor: "#888a87",
            borderStyle: "solid",
            color: "#888a87e4",
          }}
        >
          <View>
            <Text
              style={{
                borderBottom: "1",
                borderColor: "#888a87",
                borderStyle: "solid",
              }}
            >
              Firmax Servicios Financieros
            </Text>
            <Text>E-mail: oficina@firmax.es</Text>
            <Text>
              N° Póliza de Responsabilidad Profesional: BATR2011917731
            </Text>
            <Text>N° Resgistro del Banco de España: D325</Text>
          </View>
          <Image style={{ height: "45px", width: "150px" }} src={logo} />
        </View>
        <Text style={{ paddingTop: "10px" }}>
          SOLICITUD HIPOTECARIA Nº: {solicitudHipotecaria}
        </Text>
        <Text style={{ textAlign: "right" }}>
          En {datosCuestionario.provinciaViabilidad} {fecha}
        </Text>
        <Text style={{ paddingTop: "20px" }}>
          1)Por la presente solicitud, don/dña{" "}
          <Text style={{ fontWeight: "700" }}>
            {datosCuestionario.nombreViabilidad}{" "}
            {datosCuestionario.apellidoViabilidad}
          </Text>{" "}
          Con d.n.i. / n.i.e. nº {datosCuestionario.dniViabilidad}
        </Text>
        <Text>
          Y don/dña{" "}
          {datosCuestionario.nombreViabilidadDos  && datosCuestionario.nombreViabilidadDos !== "undefined" && isNaN(datosCuestionario.nombreViabilidadDos) ? (
            <Text style={{ fontWeight: "700" }}>
              {datosCuestionario.nombreViabilidadDos}{" "}
              {datosCuestionario.apellidoViabilidadDos}
            </Text>
          ) : (
            "......................................"
          )}{" "}
          Con d.n.i. / n.i.e. nº{" "}
          {datosCuestionario.dniViabilidadDos && datosCuestionario.dniViabilidadDos !== "undefined"
            ? datosCuestionario.dniViabilidadDos
            : "........"}
        </Text>
        <Text>
          Y don/dña{" "}
          {datosCuestionario.nombreViabilidadTres && datosCuestionario.nombreViabilidadTres !== "undefined" && isNaN(datosCuestionario.nombreViabilidadTres) ? (
            <Text style={{ fontWeight: "700" }}>
              {datosCuestionario.nombreViabilidadTres}{" "}
              {datosCuestionario.apellidoViabilidadTres}
            </Text>
          ) : (
            "......................................"
          )}{" "}
          Con d.n.i. / n.i.e. nº{" "}
          {datosCuestionario.dniViabilidadTres && datosCuestionario.dniViabilidadTres !== "undefined"
            ? datosCuestionario.dniViabilidadTres
            : "........"}
        </Text>
        <Text>
          Y don/dña{" "}
          {datosCuestionario.nombreViabilidadCuatro && datosCuestionario.nombreViabilidadCuatro !== "undefined" && isNaN(datosCuestionario.nombreViabilidadCuatro) ? (
            <Text style={{ fontWeight: "700" }}>
              {datosCuestionario.nombreViabilidadCuatro}{" "}
              {datosCuestionario.apellidoViabilidadCuatro}
            </Text>
          ) : (
            "......................................"
          )}{" "}
          Con d.n.i. / n.i.e. nº{" "}
          {datosCuestionario.dniViabilidadCuatro && datosCuestionario.dniViabilidadCuatro !== "undefined"
            ? datosCuestionario.dniViabilidadCuatro
            : "........"}
        </Text>
        <Text>
          Domiciliado/s en {datosCuestionario.poblacionViabilidad} {datosCuestionario.domicilioViabilidad}, provincia{" "}
          {datosCuestionario.provinciaViabilidad}, tel n°{" "}
          {datosCuestionario.telefonoViabilidad} y e-mail{" "}
          {datosCuestionario.emailViabilidad}
        </Text>
        <Text style={{ paddingTop: "10px" }}>
          (En adelante "El Cliente"), confía a Opción Costa Brava SL., a través
          de los convenios que mantenga con las diferentes entidades de crédito,
          la solicitud, gestión, negociación y aprobación de unas condiciones
          concretas para un Préstamo Hipotecario
        </Text>
        <Text style={{ paddingTop: "10px" }}>
          2) Sera en todo caso abonado por el cliente cualquiera de los gastos
          derivados de dicha gestión que no correspondan legalmente a la entidad
          bancaria (Tasaciones, Notaria, Registro, Gestorías, impuestos,
          verificaciones registrales, comisiones bancarias, seguros, etc.),
          comprometiéndose además a proporcionar al gestor toda la
          documentación, así como la de terceros, que fuese necesaria para la
          consecución del encargo.
        </Text>
        <Text style={{ paddingTop: "10px" }}>
          3) En el momento de la aprobación de las condiciones solicitadas, por
          parte de cualquiera de las entidades financieras homologadas por el
          Banco de España, con condiciones iguales o mejores que la aceptadas en
          el Anexo 1, se entenderá por cumplido el objeto del presente encargo.
          Se establece como medio de comunicación el e-mail detallado en la vía
          de contacto el punto 1 del presente encargo.
        </Text>{" "}
   
        <Text style={{ paddingTop: "10px" }}>
          4) Opcion Costa Brava SL no asume responsabilidad alguna causada por
          error u omisión de los datos facilitados por el cliente, así como en
          lo referente al tiempo y modo de autorización de la solicitud y/o al
          efectivo otorgamiento del préstamo.
        </Text>
        <Text style={{ paddingTop: "10px" }}>
          5) El cliente puede ejercer sus derechos recogidos en los artículos 21
          y 22 de la ley 2/2019 del 31 de marzo, por la que se regula la
          contratación con los consumidores de préstamos o créditos hipotecarios
          y servicios de intermediación para la contratación de préstamo o
          crédito. Para ello el cliente deberá de comunicar su petición por
          escrito a la dirección postal Av. Costa Brava, 81 Local A de Vidreres,
          provincia de Girona (17411), o electrónica descritos en el encabezado
          de este documento.
        </Text>
        <Text style={{ paddingTop: "10px" }}>
          6) Las quejas y reclamaciones podrán ser presentadas ante el depto. de
          atención al cliente, en cualquier oficina abierta al público del ICI,
          así como en la dirección de correo electrónico
          reclamaciones@firmax.es.
        </Text>
        <Text style={{ paddingTop: "10px" }}>
          7) Las partes someten cualquier controversia derivada de la presente
          solicitud de forma exclusiva a los jueces y tribunales de la ciudad de
          Girona.
        </Text>
        <View
          style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}
        >
          {/* <Image style={{ height: "300px" }} src={img} /> */}
          <View
            style={{
              paddingBottom: "10px",
              width: "50%",
              height: "80px",
              border: "1",
              borderColor: "#c4f3a1",
              borderStyle: "solid",
            }}
          >
            <Text>
              Cliente: {datosCuestionario.nombreViabilidad}{" "}
              {datosCuestionario.apellidoViabilidad}
            </Text>
            <Text style={{ paddingTop: "40px" }}>Fdo:</Text>
          </View>

          <View
            style={{
              paddingBottom: "10px",
              width: "50%",
              height: "80px",
              border: "1",
              borderColor: "#c4f3a1",
              borderStyle: "solid",
            }}
          >
            <Text>
              Cliente:{" "}
              {datosCuestionario.nombreViabilidadDos  && datosCuestionario.nombreViabilidadDos !== "undefined" && isNaN(datosCuestionario.nombreViabilidadDos)
                ? `${datosCuestionario.nombreViabilidadDos}  ${datosCuestionario.apellidoViabilidadDos}`
                : "................."}
            </Text>
            <Text style={{ paddingTop: "40px" }}>Fdo:</Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <View
            style={{
              paddingBottom: "10px",
              width: "50%",
              height: "80px",
              border: "1",
              borderColor: "#c4f3a1",
              borderStyle: "solid",
            }}
          >
            <Text>
              Cliente:{" "}
              {datosCuestionario.nombreViabilidadTres  && datosCuestionario.nombreViabilidadTres !== "undefined" && isNaN(datosCuestionario.nombreViabilidadTres)
                ? `${datosCuestionario.nombreViabilidadTres}  ${datosCuestionario.apellidoViabilidadTres}`
                : "................."}
            </Text>
            <Text style={{ paddingTop: "40px" }}>Fdo:</Text>
          </View>
          <View
            style={{
              paddingBottom: "10px",
              width: "50%",
              height: "80px",
              border: "1",
              borderColor: "#c4f3a1",
              borderStyle: "solid",
            }}
          >
            <Text>
              Cliente:{" "}
              {datosCuestionario.nombreViabilidadCuatro  && datosCuestionario.nombreViabilidadCuatro !== "undefined" && isNaN(datosCuestionario.nombreViabilidadCuatro)
                ? `${datosCuestionario.nombreViabilidadCuatro}  ${datosCuestionario.apellidoViabilidadCuatro}`
                : "................."}
            </Text>
            <Text style={{ paddingTop: "40px" }}>Fdo:</Text>
            <Image
              style={{
                height: "65px",
                width: "100px",
                position: "absolute",
                bottom: "-130px",
                right: "0",
              }}
              src={firma}
            />
          </View>
          <Image
              style={{
                height: "50px",
                width: "60px",
                position: "absolute",
                bottom: "-200px",
                left: "250px",   
              }}
              src={logoCuadrado}
            />
        </View>
    
      </Page>

      <Page size="A4" style={{ fontSize: "10px", padding: "10px" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1",
            borderColor: "#888a87",
            borderStyle: "solid",
            color: "#888a87e4",
          }}
        >
          <View>
            <Text
              style={{
                borderBottom: "1",
                borderColor: "#888a87",
                borderStyle: "solid",
              }}
            >
              Firmax Servicios Financieros
            </Text>
            <Text>E-mail: oficina@firmax.es</Text>
            <Text>
              N° Póliza de Responsabilidad Profesional: BATR2011917731
            </Text>
            <Text>N° Resgistro del Banco de España: D325</Text>
          </View>
          <Image style={{ height: "45px", width: "150px" }} src={logo} />
        </View>

        <Text style={{ paddingTop: "10px" }}>
          ANEXO SOLICITUD HIPOTECARIA Nº: {solicitudHipotecaria}
        </Text>
        <Text style={{ textAlign: "right" }}>
          En {datosCuestionario.provinciaViabilidad} {fecha}
        </Text>

        <Text style={{ paddingTop: "10px" }}>
          Los titulares solicitantes declaran los datos que se detallan a
          continuación:
        </Text>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: "10px",
            borderBottom: "4",
            borderColor: "#c4f3a1",
            borderStyle: "solid",
          }}
        >
          <Text style={{ backgroundColor: "#fff", width: "20%" }}>
            Datos aportados
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.nombreViabilidad
              ? datosCuestionario.nombreViabilidad
              : "---"}
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.nombreViabilidadDos &&
             datosCuestionario.nombreViabilidadDos !== undefined &&
            datosCuestionario.nombreViabilidadDos !== "undefined"
              ? datosCuestionario.nombreViabilidadDos
              : "---"}
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.nombreViabilidadTres &&
             datosCuestionario.nombreViabilidadTres !== undefined &&
            datosCuestionario.nombreViabilidadTres !== "undefined"
              ? datosCuestionario.nombreViabilidadTres
              : "---"}
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.nombreViabilidadCuatro &&
               datosCuestionario.nombreViabilidadCuatro !== undefined &&
            datosCuestionario.nombreViabilidadCuatro !== "undefined"
              ? datosCuestionario.nombreViabilidadCuatro
              : "---"}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#c4f3a1",
          }}
        >
          <Text style={{ backgroundColor: "#fff", width: "20%" }}>Edad: </Text>
          {datosCuestionario.edadViavilidad ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.edadViavilidad}
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.edadViavilidadDos &&
          datosCuestionario.edadViavilidadDos !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.edadViavilidadDos}
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.edadViavilidadTres &&
          datosCuestionario.edadViavilidadTres !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.edadViavilidadTres}
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.edadViavilidadCuatro &&
          datosCuestionario.edadViavilidadCuatro !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.edadViavilidadCuatro}
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text style={{ backgroundColor: "#fff", width: "20%" }}>
            Situación Laboral:{" "}
          </Text>
          {datosCuestionario.situacionLaboral ? (
            <Text style={{ width: "16%", fontSize: "8px" }}>
              {datosCuestionario.situacionLaboral}
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.situacionLaboralDos &&
          datosCuestionario.situacionLaboralDos !== "undefined" ? (
            <Text style={{ width: "16%", fontSize: "8px" }}>
              {datosCuestionario.situacionLaboralDos}
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.situacionLaboralTres &&
          datosCuestionario.situacionLaboralTres !== "undefined" ? (
            <Text style={{ width: "16%", fontSize: "8px" }}>
              {datosCuestionario.situacionLaboralTres}
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.situacionLaboralCuatro &&
          datosCuestionario.situacionLaboralCuatro !== "undefined" ? (
            <Text style={{ width: "16%", fontSize: "8px" }}>
              {datosCuestionario.situacionLaboralCuatro}
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#c4f3a1",
          }}
        >
          <Text style={{ backgroundColor: "#fff", width: "20%" }}>
            Inicio empleo actual:
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.fechaDeIngreso}
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.fechaDeIngresoDos &&
            datosCuestionario.fechaDeIngresoDos !== "undefined"
              ? datosCuestionario.fechaDeIngresoDos
              : "---"}
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.fechaDeIngresoTres &&
            datosCuestionario.fechaDeIngresoTres !== "undefined"
              ? datosCuestionario.fechaDeIngresoTres
              : "---"}
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.fechaDeIngresoCuatro &&
            datosCuestionario.fechaDeIngresoCuatro !== "undefined"
              ? datosCuestionario.fechaDeIngresoCuatro
              : "---"}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text style={{ backgroundColor: "#fff", width: "20%" }}>
            Ingresos fiscales:
          </Text>
          {datosCuestionario.ingresosNetosMensuales ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.ingresosNetosMensuales}€
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.ingresosNetosMensualesDos &&
          datosCuestionario.ingresosNetosMensualesDos !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.ingresosNetosMensualesDos}€
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.ingresosNetosMensualesTres &&
          datosCuestionario.ingresosNetosMensualesTres !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.ingresosNetosMensualesTres}€
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.ingresosNetosMensualesCuatro &&
          datosCuestionario.ingresosNetosMensualesCuatro !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.ingresosNetosMensualesCuatro}€
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#c4f3a1",
          }}
        >
          <Text style={{ backgroundColor: "#fff", width: "20%" }}>
            Nº pagas anuales:
          </Text>
          <Text style={{ width: "16%" }}>{datosCuestionario.pagasAnuales}</Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.pagasAnualesDos &&
            datosCuestionario.pagasAnualesDos !== "undefined"
              ? datosCuestionario.pagasAnualesDos
              : "---"}
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.pagasAnualesTres &&
            datosCuestionario.pagasAnualesTres !== "undefined"
              ? datosCuestionario.pagasAnualesTres
              : "---"}
          </Text>
          <Text style={{ width: "16%" }}>
            {datosCuestionario.pagasAnualesCuatro &&
            datosCuestionario.pagasAnualesCuatro !== "undefined"
              ? datosCuestionario.pagasAnualesCuatro
              : "---"}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text style={{ backgroundColor: "#fff", width: "20%" }}>
            Cuotas mensuales:
          </Text>
          {datosCuestionario.pagaDeCuotas ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.pagaDeCuotas}€
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.pagaDeCuotasDos &&
          datosCuestionario.pagaDeCuotasDos !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.pagaDeCuotasDos}€
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.pagaDeCuotasTres &&
          datosCuestionario.pagaDeCuotasTres !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.pagaDeCuotasTres}€
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
          {datosCuestionario.pagaDeCuotasCuatro &&
          datosCuestionario.pagaDeCuotasCuatro !== "undefined" ? (
            <Text style={{ width: "16%" }}>
              {datosCuestionario.pagaDeCuotasCuatro}€
            </Text>
          ) : (
            <Text style={{ width: "16%" }}> --- </Text>
          )}
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "20px",
            borderBottom: "4",
            borderColor: "#c4f3a1",
            borderStyle: "solid",
          }}
        >
          <Text
            style={{
              padding: "10px 0",
            }}
          >
            Aportación garantías extras:
          </Text>
          <Text
            style={{
              backgroundColor: "#c4f3a1",
              padding: "10px 0",
              width: "60%",
              textAlign: "center",
            }}
          >
            {optMejoraAntiguedad === 'ma1' ? 'Aval solvente' : null} {optMejoraGarantia === 'mg1' || optMejoraGarantia === 'none' || datosCuestionario.segundaGarantia === 'si'  ? '/ 2ª garantía Hipotecaria' : null} {" "}
          </Text>
        </View>

        <Text style={{ marginTop: "20px", textAlign: "center" }}>
          Condiciones Hipotecarias Solicitadas
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            border: "1",
            borderStyle: "solid",
            borderTop: "4",
            borderColor: "#c4f3a1",
          }}
        >
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "60px" }}>
                Producto Hipotecario:
              </Text>
              <Text
                style={{
                  backgroundColor: "#c4f3a1",
                  width: "80px",
                  textAlign: "center",
                }}
              >
                {setearDatosCalculadora.productoHipotecario}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "60px" }}>Hipoteca Solicitada:</Text>
            
                <Text style={{ width: "80px", textAlign: "center" }}>
                  {Math.floor(
                    datosCuestionario.hipotecaSolicitada / 500
                  ) * 500}€
                </Text>
         
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>
                % máximo sobre Tasación:{" "}
              </Text>
              <Text
                style={{
                  backgroundColor: "#c4f3a1",
                  width: "80px",
                  textAlign: "center",
                }}
              >
                {setearDatosCalculadora.maximoSobreTasacion}%
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>% máximo sobre CV:</Text>
              <Text style={{ width: "80px", textAlign: "center" }}>{datosCuestionario.porcentaje}%</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>Plazo de devolución:</Text>
              <Text
                style={{
                  backgroundColor: "#c4f3a1",
                  width: "80px",
                  textAlign: "center",
                }}
              >
                {aniosAmeses} meses
              </Text>
            </View>
          </View>

          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>
                Tipo de Interés Bonificado:
              </Text>
              <Text
                style={{
                  backgroundColor: "#c4f3a1",
                  width: "80px",
                  textAlign: "center",
                }}
              >
                {datosCuestionario.valorFijoMixto === "fijo"
                  ? "Fijo"
                  : datosCuestionario.valorFijoMixto === "variable"
                  ? "Variable"
                  : "Mixto"}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>
              {datosCuestionario.valorFijoMixto === "variable" ||
                  datosCuestionario.valorFijoMixto === "mixto"
                  ? `TIN primeros ${mesesInteres.meses} meses:`
                  : "TIN"
                }
              </Text>
              <Text style={{ width: "80px", textAlign: "center" }}>
                {datosCuestionario.hipoteca === "fijo"
                  ? `${innteresFija}%`
                  : `${mesesInteres.interes}%`
                }
            
              </Text>
            </View>
            {/* ------------ */}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>
                TIN resto de plazo Euribor + :
              </Text>
              <Text
                style={{
                  backgroundColor: "#c4f3a1",
                  width: "80px",
                  textAlign: "center",
                }}
              >
                   {datosCuestionario.hipoteca === "fijo"
                  ? "---"
                  : `${mesesInteres.restoInteres}%`}
              </Text>
            </View>
            {/* ------------ */}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>Cuota Inicial:</Text>
              <Text style={{ width: "80px", textAlign: "center" }}>
                {datosCuestionario.valorFijoMixto === "fijo"
                  ? Math.floor(datosCuestionario.cuotaEstimadaFija)
                  : datosCuestionario.valorFijoMixto === "variable" 
                  ? Math.floor(datosCuestionario.cuotaEstimadaVariable)
                  : Math.floor(datosCuestionario.cuotaEstimadaMixta)
                  }
                €
              </Text>
            </View>
            {/* ------------ */}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>
                *Cuota Resto de plazo:
              </Text>
              <Text
                style={{
                  backgroundColor: "#c4f3a1",
                  width: "80px",
                  textAlign: "center",
                }}
              >
                {datosCuestionario.valorFijoMixto === "fijo"
                  ? "---"
                  : datosCuestionario.valorFijoMixto === "variable" 
                  ? `${datosCuestionario.cuotaEstimadaVariableEuribor}€`
                  : `${datosCuestionario.cuotaEstimadaMixtaEuribor}€` }
              </Text>
            </View>
            {/* ------------ */}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ paddingRight: "40px" }}>
                *Cálculo realizado con Euribor al:
              </Text>
              <Text style={{ width: "80px", textAlign: "center" }}>
                {datosCuestionario.valorFijoMixto === "fijo"
                  ? "---"
                  : `${euribor}%`}
              </Text>
            </View>
          </View>

          {/* ------------ */}
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "20px",
            borderBottom: "4",
            borderColor: "#c4f3a1",
            borderStyle: "solid",
          }}
        >
          <View
            style={{
              padding: "10px 0",
            }}
          >
            <Text>Productos que bonifican</Text>
            <Text>el tipo de interés detallado</Text>
          </View>
          <Text
            style={{
              backgroundColor: "#c4f3a1",
              padding: "10px 0",
              width: "60%",
              textAlign: "center",
            }}
          >
            {setearDatosCalculadora.bonificacion}
          </Text>
        </View>

        <View
          style={{ display: "flex", flexDirection: "row", paddingTop: "30px" }}
        >
          <View
            style={{
              paddingBottom: "10px",
              width: "50%",
              height: "80px",
              border: "1",
              borderColor: "#c4f3a1",
              borderStyle: "solid",
            }}
          >
            <Text>
              Cliente: {datosCuestionario.nombreViabilidad}{" "}
              {datosCuestionario.apellidoViabilidad}
            </Text>
            <Text style={{ paddingTop: "40px" }}>Fdo:</Text>
          </View>

          <View
            style={{
              paddingBottom: "10px",
              width: "50%",
              height: "80px",
              border: "1",
              borderColor: "#c4f3a1",
              borderStyle: "solid",
            }}
          >
            <Text>
              Cliente:{" "}
              {datosCuestionario.nombreViabilidadDos  && datosCuestionario.nombreViabilidadDos !== "undefined" && isNaN(datosCuestionario.nombreViabilidadDos)
                ? `${datosCuestionario.nombreViabilidadDos}  ${datosCuestionario.apellidoViabilidadDos}`
                : "................."}
            </Text>
            <Text style={{ paddingTop: "40px" }}>Fdo:</Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <View
            style={{
              paddingBottom: "10px",
              width: "50%",
              height: "80px",
              border: "1",
              borderColor: "#c4f3a1",
              borderStyle: "solid",
            }}
          >
            <Text>
              Cliente:{" "}
              {datosCuestionario.nombreViabilidadTres  && datosCuestionario.nombreViabilidadTres !== "undefined" && isNaN(datosCuestionario.nombreViabilidadTres)
                ? `${datosCuestionario.nombreViabilidadTres}  ${datosCuestionario.apellidoViabilidadTres}`
                : "................."}
            </Text>
            <Text style={{ paddingTop: "40px" }}>Fdo:</Text>
          </View>
          <View
            style={{
              paddingBottom: "10px",
              width: "50%",
              height: "80px",
              border: "1",
              borderColor: "#c4f3a1",
              borderStyle: "solid",
            }}
          >
            <Text>
              Cliente:{" "}
              {datosCuestionario.nombreViabilidadCuatro  && datosCuestionario.nombreViabilidadCuatro !== "undefined" && isNaN(datosCuestionario.nombreViabilidadCuatro)
                ? `${datosCuestionario.nombreViabilidadCuatro}  ${datosCuestionario.apellidoViabilidadCuatro}`
                : "................."}
            </Text>
            <Text style={{ paddingTop: "40px" }}>Fdo:</Text>
          </View>
          <Image
            style={{
              height: "65px",
              width: "100px",
              position: "absolute",
              bottom: "-100px",
              right: "0",
            }}
            src={firma}
          />
          <Image
              style={{
                height: "50px",
                width: "60px",
                position: "absolute",
                bottom: "-220px",
                left: "250px",   
              }}
              src={logoCuadrado}
            />
        </View>
          
      
      </Page>

      <Page size="A4" style={{ fontSize: "10px", padding: "10px" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1",
            borderColor: "#888a87",
            borderStyle: "solid",
            color: "#888a87e4",
          }}
        >
          <View>
            <Text
              style={{
                borderBottom: "1",
                borderColor: "#888a87",
                borderStyle: "solid",
              }}
            >
              Firmax Servicios Financieros
            </Text>
            <Text>E-mail: oficina@firmax.es</Text>
            <Text>
              N° Póliza de Responsabilidad Profesional: BATR2011917731
            </Text>
            <Text>N° Resgistro del Banco de España: D325</Text>
          </View>
          <Image style={{ height: "45px", width: "150px" }} src={logo} />
        </View>
        <Text style={{ paddingTop: "10px" }}>
          L.O.P.D. PARA LA SOLICITUD HIPOTECARIA Nº: {solicitudHipotecaria}
        </Text>
        <Text style={{ textAlign: "right" }}>
          En {datosCuestionario.provinciaViabilidad} {fecha}
        </Text>

        <Text style={{ paddingTop: "10px", textAlign: "center" }}>
          CLÁUSULA DE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL
        </Text>

        <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
          Las condiciones para el tratamiento de datos de carácter personal se
          rigen por lo dispuesto en la Ley Orgánica 3/2018, de 5 de diciembre,
          de Protección de Datos Personales y garantía de los derechos digitales
          (en adelante, LOPD-GDD) y en el Reglamento (UE) 2016/679, del
          Parlamento Europeo y del Consejo, relativo a la protección de las
          personas físicas en lo que respecta al tratamiento de datos personales
          (en adelante, RGPD).
        </Text>

        <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
          El abajo firmante declara haber sido informado y expresamente
          consiente la incorporación de sus datos de carácter personal a los
          ficheros responsabilidad de OPCION COSTA BRAVA S.L., así como a su
          tratamiento informatizado o no de los mismos. Estos datos son
          necesarios para la contratación de los servicios solicitados a OPCION
          COSTA BRAVA S.L. y su ocultación, inexactitud o falta de veracidad
          podrá implicar una merma o la imposibilidad de poder prestarle el
          servicio. La recogida y tratamiento de los Datos Personales por parte
          de OPCION COSTA BRAVA S.L. se lleva a cabo con una finalidad comercial
          y financiera; autorizándose expresamente por el firmante la
          realización de consultas, tanto ante organismos públicos como
          privados, sobre su solvencia patrimonial, situación laboral,
          información crediticia, jurídica o fiscal, que permitan realizar una
          valoración de riesgos, de cara a la concesión de un préstamo-crédito
          con garantía hipotecaria o sin ella. Igualmente, el abajo firmante
          autoriza expresamente para que los datos por él facilitados, puedan
          servir de base para la gestión administrativa, estadística, contable,
          así como para desarrollar, negociar y seleccionar créditos y préstamos
          relativos a los servicios prestados por Opción Costa Brava SL. El
          abajo firmante presta su consentimiento para que sus Datos Personales
          puedan ser comunicados a las entidades que se detalla al final del
          presente documento(*) , para su utilización por éstas con la finalidad
          descrita en el apartado anterior, de cara a facilitar el desarrollo,
          cumplimiento y control de la relación jurídica establecida con OPCION
          COSTA BRAVA S.L. Especialmente consiente que cualquiera de dichas
          entidades financieras pueda realizar la consulta de sus datos
          personales y riesgos crediticios a la CIRBE y a los demás registros de
          incidencias crediticias por encargo de OPCION COSTA BRAVA S.L.
        </Text>

        <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
          FormaAsimismo, el abajo firmante autoriza a OPCION COSTA BRAVA S.L.
          para que comunique sus Datos Personales, además de a las entidades a
          que se hace referencia en el párrafo anterior y cuya descripción se
          detalla al final de este documento, a las empresas de su grupo con las
          finalidades expuestas en el párrafo segundo del apartado anterior. En
          este sentido, estas entidades quedan autorizadas para enviar al
          firmante por cualquier medio, electrónico o no, informaciones de los
          productos y servicios que dichas entidades comercialicen, tanto en la
          actualidad como en el futuro. OPCION COSTA BRAVA S.L. garantiza que ha
          adoptado las medidas oportunas de seguridad en sus instalaciones,
          sistemas y ficheros, así como que todos los datos personales
          facilitados por el abajo firmante serán utilizados con la
          confidencialidad, finalidad, en forma y con las limitación y derechos
          contemplados en la normativa vigente.
        </Text>


        <Text
          style={{ paddingTop: "10px", textAlign: "center", fontSize: "9px" }}
        >
          INFORMACIÓN DE PROTECCIÓN DE DATOS
        </Text>

        <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
          De conformidad con el RGPD y la LOPD-GDD, sus datos serán tratados por
          el responsable del tratamiento con la finalidad de prestarle los
          servicios contratados, y la base jurídica del tratamiento será la
          ejecución de un contrato o precontrato. Los datos no serán cedidos sin
          su consentimiento, excepto las cesiones necesarias para poder prestar
          el servicio a las entidades financieras correspondientes, o cuando la
          cesión sea consecuencia de una obligación legal. Sus datos serán
          conservados mientras se mantenga nuestra relación, mientras no
          solicite la supresión de los mismos, o mientras exista una obligación
          legal de conservarlos. Podrá ejercitar los derechos de acceso,
          rectificación, oposición, supresión, limitación y portabilidad,
          adjuntando una copia de su documento de identidad, escribiendo a la
          dirección: OPCION COSTA BRAVA S.L Av. Costa Brava, 81 Local A, 17411
          Vidreres. En dicha solicitud deberá especificar en concreto qué
          derecho o derechos desea ejercitar.
        </Text>

        <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
          Nota: este documento debe ser conservado por las entidades a
          disposición del Banco de España.
        </Text>

        <Text style={{ paddingTop: "10px", fontSize: "7px" }}>
          (*) A&G BANCA PRIVADA, S.A; ABANCA CORPORACION BANCARIA, S.A.;
          ALLFUNDS BANK, S.A.; ANDBANK ESPAÑA, S.A.; ARESBANK, S.A.; BANCA
          MARCH, S.A.; BANCA PUEYO, S.A.; BANCO ALCALA, S.A.; BANCO BILBAO
          VIZCAYA ARGENTARIA, S.A.; BANCO CAIXA GERAL, S.A.;BANCO CAMINOS,
          S.A.;BANCO CETELEM, S.A; BANCO COOPERATIVO ESPAÑOL, S.A.; BANCO DE
          ALBACETE, S.A. ; BANCO DE CAJA ESPAÑA INVER. SALAMANCA Y SORIA,S.A.;
          BANCO DE CASTILLA-LA MANCHA, S.A.; BANCO DE CREDITO SOCIAL
          COOPERATIVO, S.A.; BANCO DE DEPOSITOS, S.A.; BANCO DE SABADELL, S.A.;
          BANCO EUROPEO DE FINANZAS, S.A.; BANCO FINANTIA SOFINLOC, S.A.; BANCO
          INDUSTRIAL DE BILBAO, S.A.; BANCO INVERSIS, S.A.; BANCO MARE NOSTRUM,
          S.A.; BANCO MEDIOLANUM, S.A.; BANCO OCCIDENTAL, S.A.; BANCO PASTOR,
          S.A.; BANCO PICHINCHA ESPAÑA, S.A.; BANCO POPULAR ESPAÑOL, S.A.; BANCO
          SANTANDER, S.A.; BANCOFAR, S.A.; BANCOVAL SECURITIES SERVICES, S.A.;
          BANK DEGROOF PETERCAM SPAIN, S.A.; BANKIA, S.A.; BANKINTER, S.A.;
          BANKOA, S.A.; BANQUE MAROCAINE COMMERCE EXTERIEUR INTERNAT.,S.A.; BNP
          PARIBAS ESPAÑA, S.A.; CAIXABANK, S.A.; CAJASUR BANCO, S.A.; CECABANK,
          S.A.; CITIBANK ESPAÑA, S.A.; DEUTSCHE BANK, S.A.E.; EBN BANCO DE
          NEGOCIOS, S.A.; EVO BANCO S.A.; IBERCAJA BANCO, S.A.; KUTXABANK, S.A.;
          LIBERBANK, S.A..; NUEVO MICRO BANK, S.A.; OPEN BANK, S.A.; POPULAR
          BANCA PRIVADA, S.A.; RENTA 4 BANCO, S.A.; SANTANDER CONSUMER FINANCE,
          S.A.; SANTANDER INVESTMENT, S.A.; SANTANDER SECURITIES SERVICES, S.A.;
          SELF TRADE BANK, S.A.; TARGOBANK, S.A.; UNICAJA BANCO, S.A.;WIZINK
          BANK, S.A.O CUALQUIER ENTIDAD RESULTANTE DE FUSIONES O ADQUISICIONES
          EN LAS QUE ALGUNA DE LAS ANTERIORES ENTIDADES PARTICIPE DIRECTA O
          INDIRECTAMENTE.
        </Text>

        <Text style={{ paddingTop: "20px", fontSize: "9px" }}>
          Don/Dña {datosCuestionario.nombreViabilidad}{" "}
          {datosCuestionario.apellidoViabilidad}, Con d.n.i. / n.i.e. nº{" "}
          {datosCuestionario.dniViabilidad} y e-mail{" "}
          {datosCuestionario.emailViabilidad} en carácter de titular de la
          solicitud Hipotecaria detallada en el encabezado.
        </Text>
        <View style={{ width: "100%", position: "relative" }}>
          <Image
            style={{
              height: "65px",
              width: "100px",
              position: "absolute",
              right: "0",
            }}
            src={firma}
          />
        </View>
        <View style={{position:"relative", width:"100%", display:"flex", alignItems:"center", justifyContent:"center", paddingTop:"150px"}}>
          <Image
            style={{
              height: "50px",
              width: "60px",
              // position: "absolute",
              // bottom: "-230px",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
            src={logoCuadrado}
          />
        </View>
      </Page>

      {datosCuestionario.nombreViabilidadDos &&
      datosCuestionario.nombreViabilidadDos !== "undefined" ? (
        <Page size="A4" style={{ fontSize: "10px", padding: "10px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1",
              borderColor: "#888a87",
              borderStyle: "solid",
              color: "#888a87e4",
            }}
          >
            <View>
              <Text
                style={{
                  borderBottom: "1",
                  borderColor: "#888a87",
                  borderStyle: "solid",
                }}
              >
                Firmax Servicios Financieros
              </Text>
              <Text>E-mail: oficina@firmax.es</Text>
              <Text>
                N° Póliza de Responsabilidad Profesional: BATR2011917731
              </Text>
              <Text>N° Resgistro del Banco de España: D325</Text>
            </View>
            <Image style={{ height: "45px", width: "150px" }} src={logo} />
          </View>
          <Text style={{ paddingTop: "10px" }}>
            L.O.P.D. PARA LA SOLICITUD HIPOTECARIA Nº: {solicitudHipotecaria}
          </Text>
          <Text style={{ textAlign: "right" }}>
            En {datosCuestionario.provinciaViabilidad} {fecha}
          </Text>

          <Text style={{ paddingTop: "10px", textAlign: "center" }}>
            CLÁUSULA DE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            Las condiciones para el tratamiento de datos de carácter personal se
            rigen por lo dispuesto en la Ley Orgánica 3/2018, de 5 de diciembre,
            de Protección de Datos Personales y garantía de los derechos
            digitales (en adelante, LOPD-GDD) y en el Reglamento (UE) 2016/679,
            del Parlamento Europeo y del Consejo, relativo a la protección de
            las personas físicas en lo que respecta al tratamiento de datos
            personales (en adelante, RGPD).
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            El abajo firmante declara haber sido informado y expresamente
            consiente la incorporación de sus datos de carácter personal a los
            ficheros responsabilidad de OPCION COSTA BRAVA S.L., así como a su
            tratamiento informatizado o no de los mismos. Estos datos son
            necesarios para la contratación de los servicios solicitados a
            OPCION COSTA BRAVA S.L. y su ocultación, inexactitud o falta de
            veracidad podrá implicar una merma o la imposibilidad de poder
            prestarle el servicio. La recogida y tratamiento de los Datos
            Personales por parte de OPCION COSTA BRAVA S.L. se lleva a cabo con
            una finalidad comercial y financiera; autorizándose expresamente por
            el firmante la realización de consultas, tanto ante organismos
            públicos como privados, sobre su solvencia patrimonial, situación
            laboral, información crediticia, jurídica o fiscal, que permitan
            realizar una valoración de riesgos, de cara a la concesión de un
            préstamo-crédito con garantía hipotecaria o sin ella. Igualmente, el
            abajo firmante autoriza expresamente para que los datos por él
            facilitados, puedan servir de base para la gestión administrativa,
            estadística, contable, así como para desarrollar, negociar y
            seleccionar créditos y préstamos relativos a los servicios prestados
            por Opción Costa Brava SL. El abajo firmante presta su
            consentimiento para que sus Datos Personales puedan ser comunicados
            a las entidades que se detalla al final del presente documento(*) ,
            para su utilización por éstas con la finalidad descrita en el
            apartado anterior, de cara a facilitar el desarrollo, cumplimiento y
            control de la relación jurídica establecida con OPCION COSTA BRAVA
            S.L. Especialmente consiente que cualquiera de dichas entidades
            financieras pueda realizar la consulta de sus datos personales y
            riesgos crediticios a la CIRBE y a los demás registros de
            incidencias crediticias por encargo de OPCION COSTA BRAVA S.L.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            FormaAsimismo, el abajo firmante autoriza a OPCION COSTA BRAVA S.L.
            para que comunique sus Datos Personales, además de a las entidades a
            que se hace referencia en el párrafo anterior y cuya descripción se
            detalla al final de este documento, a las empresas de su grupo con
            las finalidades expuestas en el párrafo segundo del apartado
            anterior. En este sentido, estas entidades quedan autorizadas para
            enviar al firmante por cualquier medio, electrónico o no,
            informaciones de los productos y servicios que dichas entidades
            comercialicen, tanto en la actualidad como en el futuro. OPCION
            COSTA BRAVA S.L. garantiza que ha adoptado las medidas oportunas de
            seguridad en sus instalaciones, sistemas y ficheros, así como que
            todos los datos personales facilitados por el abajo firmante serán
            utilizados con la confidencialidad, finalidad, en forma y con las
            limitación y derechos contemplados en la normativa vigente.
          </Text>

  

          <Text
            style={{ paddingTop: "10px", textAlign: "center", fontSize: "9px" }}
          >
            INFORMACIÓN DE PROTECCIÓN DE DATOS
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            De conformidad con el RGPD y la LOPD-GDD, sus datos serán tratados
            por el responsable del tratamiento con la finalidad de prestarle los
            servicios contratados, y la base jurídica del tratamiento será la
            ejecución de un contrato o precontrato. Los datos no serán cedidos
            sin su consentimiento, excepto las cesiones necesarias para poder
            prestar el servicio a las entidades financieras correspondientes, o
            cuando la cesión sea consecuencia de una obligación legal. Sus datos
            serán conservados mientras se mantenga nuestra relación, mientras no
            solicite la supresión de los mismos, o mientras exista una
            obligación legal de conservarlos. Podrá ejercitar los derechos de
            acceso, rectificación, oposición, supresión, limitación y
            portabilidad, adjuntando una copia de su documento de identidad,
            escribiendo a la dirección: OPCION COSTA BRAVA S.L Av. Costa Brava,
            81 Local A, 17411 Vidreres. En dicha solicitud deberá especificar en
            concreto qué derecho o derechos desea ejercitar.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            Nota: este documento debe ser conservado por las entidades a
            disposición del Banco de España.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "7px" }}>
            (*) A&G BANCA PRIVADA, S.A; ABANCA CORPORACION BANCARIA, S.A.;
            ALLFUNDS BANK, S.A.; ANDBANK ESPAÑA, S.A.; ARESBANK, S.A.; BANCA
            MARCH, S.A.; BANCA PUEYO, S.A.; BANCO ALCALA, S.A.; BANCO BILBAO
            VIZCAYA ARGENTARIA, S.A.; BANCO CAIXA GERAL, S.A.;BANCO CAMINOS,
            S.A.;BANCO CETELEM, S.A; BANCO COOPERATIVO ESPAÑOL, S.A.; BANCO DE
            ALBACETE, S.A. ; BANCO DE CAJA ESPAÑA INVER. SALAMANCA Y SORIA,S.A.;
            BANCO DE CASTILLA-LA MANCHA, S.A.; BANCO DE CREDITO SOCIAL
            COOPERATIVO, S.A.; BANCO DE DEPOSITOS, S.A.; BANCO DE SABADELL,
            S.A.; BANCO EUROPEO DE FINANZAS, S.A.; BANCO FINANTIA SOFINLOC,
            S.A.; BANCO INDUSTRIAL DE BILBAO, S.A.; BANCO INVERSIS, S.A.; BANCO
            MARE NOSTRUM, S.A.; BANCO MEDIOLANUM, S.A.; BANCO OCCIDENTAL, S.A.;
            BANCO PASTOR, S.A.; BANCO PICHINCHA ESPAÑA, S.A.; BANCO POPULAR
            ESPAÑOL, S.A.; BANCO SANTANDER, S.A.; BANCOFAR, S.A.; BANCOVAL
            SECURITIES SERVICES, S.A.; BANK DEGROOF PETERCAM SPAIN, S.A.;
            BANKIA, S.A.; BANKINTER, S.A.; BANKOA, S.A.; BANQUE MAROCAINE
            COMMERCE EXTERIEUR INTERNAT.,S.A.; BNP PARIBAS ESPAÑA, S.A.;
            CAIXABANK, S.A.; CAJASUR BANCO, S.A.; CECABANK, S.A.; CITIBANK
            ESPAÑA, S.A.; DEUTSCHE BANK, S.A.E.; EBN BANCO DE NEGOCIOS, S.A.;
            EVO BANCO S.A.; IBERCAJA BANCO, S.A.; KUTXABANK, S.A.; LIBERBANK,
            S.A..; NUEVO MICRO BANK, S.A.; OPEN BANK, S.A.; POPULAR BANCA
            PRIVADA, S.A.; RENTA 4 BANCO, S.A.; SANTANDER CONSUMER FINANCE,
            S.A.; SANTANDER INVESTMENT, S.A.; SANTANDER SECURITIES SERVICES,
            S.A.; SELF TRADE BANK, S.A.; TARGOBANK, S.A.; UNICAJA BANCO,
            S.A.;WIZINK BANK, S.A.O CUALQUIER ENTIDAD RESULTANTE DE FUSIONES O
            ADQUISICIONES EN LAS QUE ALGUNA DE LAS ANTERIORES ENTIDADES
            PARTICIPE DIRECTA O INDIRECTAMENTE.
          </Text>

          <Text style={{ paddingTop: "20px", fontSize: "9px" }}>
            Don/Dña {datosCuestionario.nombreViabilidadDos}{" "}
            {datosCuestionario.apellidoViabilidadDos}, Con d.n.i. / n.i.e. nº{" "}
            {datosCuestionario.dniViabilidadDos} y e-mail{" "}
            {datosCuestionario.emailViabilidadDos} en carácter de titular de la
            solicitud Hipotecaria detallada en el encabezado.
          </Text>
          <View style={{ width: "100%", position: "relative" }}>
            <Image
              style={{
                height: "65px",
                width: "100px",
                position: "absolute",
                right: "0",
              }}
              src={firma}
            />
          </View>
          <View style={{position:"relative", width:"100%", display:"flex", alignItems:"center", justifyContent:"center", paddingTop:"150px"}}>
          <Image
            style={{
              height: "50px",
              width: "60px",
              // position: "absolute",
              // bottom: "-230px",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
            src={logoCuadrado}
          />
        </View>
        </Page>
      ) : null}

      {datosCuestionario.nombreViabilidadTres &&
      datosCuestionario.nombreViabilidadTres !== "undefined" ? (
        <Page size="A4" style={{ fontSize: "10px", padding: "10px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1",
              borderColor: "#888a87",
              borderStyle: "solid",
              color: "#888a87e4",
            }}
          >
            <View>
              <Text
                style={{
                  borderBottom: "1",
                  borderColor: "#888a87",
                  borderStyle: "solid",
                }}
              >
                Firmax Servicios Financieros
              </Text>
              <Text>E-mail: oficina@firmax.es</Text>
              <Text>
                N° Póliza de Responsabilidad Profesional: BATR2011917731
              </Text>
              <Text>N° Resgistro del Banco de España: D325</Text>
            </View>
            <Image style={{ height: "45px", width: "150px" }} src={logo} />
          </View>
          <Text style={{ paddingTop: "10px" }}>
            L.O.P.D. PARA LA SOLICITUD HIPOTECARIA Nº: {solicitudHipotecaria}
          </Text>
          <Text style={{ textAlign: "right" }}>
            En {datosCuestionario.provinciaViabilidad} {fecha}
          </Text>

          <Text style={{ paddingTop: "10px", textAlign: "center" }}>
            CLÁUSULA DE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            Las condiciones para el tratamiento de datos de carácter personal se
            rigen por lo dispuesto en la Ley Orgánica 3/2018, de 5 de diciembre,
            de Protección de Datos Personales y garantía de los derechos
            digitales (en adelante, LOPD-GDD) y en el Reglamento (UE) 2016/679,
            del Parlamento Europeo y del Consejo, relativo a la protección de
            las personas físicas en lo que respecta al tratamiento de datos
            personales (en adelante, RGPD).
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            El abajo firmante declara haber sido informado y expresamente
            consiente la incorporación de sus datos de carácter personal a los
            ficheros responsabilidad de OPCION COSTA BRAVA S.L., así como a su
            tratamiento informatizado o no de los mismos. Estos datos son
            necesarios para la contratación de los servicios solicitados a
            OPCION COSTA BRAVA S.L. y su ocultación, inexactitud o falta de
            veracidad podrá implicar una merma o la imposibilidad de poder
            prestarle el servicio. La recogida y tratamiento de los Datos
            Personales por parte de OPCION COSTA BRAVA S.L. se lleva a cabo con
            una finalidad comercial y financiera; autorizándose expresamente por
            el firmante la realización de consultas, tanto ante organismos
            públicos como privados, sobre su solvencia patrimonial, situación
            laboral, información crediticia, jurídica o fiscal, que permitan
            realizar una valoración de riesgos, de cara a la concesión de un
            préstamo-crédito con garantía hipotecaria o sin ella. Igualmente, el
            abajo firmante autoriza expresamente para que los datos por él
            facilitados, puedan servir de base para la gestión administrativa,
            estadística, contable, así como para desarrollar, negociar y
            seleccionar créditos y préstamos relativos a los servicios prestados
            por Opción Costa Brava SL. El abajo firmante presta su
            consentimiento para que sus Datos Personales puedan ser comunicados
            a las entidades que se detalla al final del presente documento(*) ,
            para su utilización por éstas con la finalidad descrita en el
            apartado anterior, de cara a facilitar el desarrollo, cumplimiento y
            control de la relación jurídica establecida con OPCION COSTA BRAVA
            S.L. Especialmente consiente que cualquiera de dichas entidades
            financieras pueda realizar la consulta de sus datos personales y
            riesgos crediticios a la CIRBE y a los demás registros de
            incidencias crediticias por encargo de OPCION COSTA BRAVA S.L.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            FormaAsimismo, el abajo firmante autoriza a OPCION COSTA BRAVA S.L.
            para que comunique sus Datos Personales, además de a las entidades a
            que se hace referencia en el párrafo anterior y cuya descripción se
            detalla al final de este documento, a las empresas de su grupo con
            las finalidades expuestas en el párrafo segundo del apartado
            anterior. En este sentido, estas entidades quedan autorizadas para
            enviar al firmante por cualquier medio, electrónico o no,
            informaciones de los productos y servicios que dichas entidades
            comercialicen, tanto en la actualidad como en el futuro. OPCION
            COSTA BRAVA S.L. garantiza que ha adoptado las medidas oportunas de
            seguridad en sus instalaciones, sistemas y ficheros, así como que
            todos los datos personales facilitados por el abajo firmante serán
            utilizados con la confidencialidad, finalidad, en forma y con las
            limitación y derechos contemplados en la normativa vigente.
          </Text>

  

          <Text
            style={{ paddingTop: "10px", textAlign: "center", fontSize: "9px" }}
          >
            INFORMACIÓN DE PROTECCIÓN DE DATOS
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            De conformidad con el RGPD y la LOPD-GDD, sus datos serán tratados
            por el responsable del tratamiento con la finalidad de prestarle los
            servicios contratados, y la base jurídica del tratamiento será la
            ejecución de un contrato o precontrato. Los datos no serán cedidos
            sin su consentimiento, excepto las cesiones necesarias para poder
            prestar el servicio a las entidades financieras correspondientes, o
            cuando la cesión sea consecuencia de una obligación legal. Sus datos
            serán conservados mientras se mantenga nuestra relación, mientras no
            solicite la supresión de los mismos, o mientras exista una
            obligación legal de conservarlos. Podrá ejercitar los derechos de
            acceso, rectificación, oposición, supresión, limitación y
            portabilidad, adjuntando una copia de su documento de identidad,
            escribiendo a la dirección: OPCION COSTA BRAVA S.L Av. Costa Brava,
            81 Local A, 17411 Vidreres. En dicha solicitud deberá especificar en
            concreto qué derecho o derechos desea ejercitar.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            Nota: este documento debe ser conservado por las entidades a
            disposición del Banco de España.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "7px" }}>
            (*) A&G BANCA PRIVADA, S.A; ABANCA CORPORACION BANCARIA, S.A.;
            ALLFUNDS BANK, S.A.; ANDBANK ESPAÑA, S.A.; ARESBANK, S.A.; BANCA
            MARCH, S.A.; BANCA PUEYO, S.A.; BANCO ALCALA, S.A.; BANCO BILBAO
            VIZCAYA ARGENTARIA, S.A.; BANCO CAIXA GERAL, S.A.;BANCO CAMINOS,
            S.A.;BANCO CETELEM, S.A; BANCO COOPERATIVO ESPAÑOL, S.A.; BANCO DE
            ALBACETE, S.A. ; BANCO DE CAJA ESPAÑA INVER. SALAMANCA Y SORIA,S.A.;
            BANCO DE CASTILLA-LA MANCHA, S.A.; BANCO DE CREDITO SOCIAL
            COOPERATIVO, S.A.; BANCO DE DEPOSITOS, S.A.; BANCO DE SABADELL,
            S.A.; BANCO EUROPEO DE FINANZAS, S.A.; BANCO FINANTIA SOFINLOC,
            S.A.; BANCO INDUSTRIAL DE BILBAO, S.A.; BANCO INVERSIS, S.A.; BANCO
            MARE NOSTRUM, S.A.; BANCO MEDIOLANUM, S.A.; BANCO OCCIDENTAL, S.A.;
            BANCO PASTOR, S.A.; BANCO PICHINCHA ESPAÑA, S.A.; BANCO POPULAR
            ESPAÑOL, S.A.; BANCO SANTANDER, S.A.; BANCOFAR, S.A.; BANCOVAL
            SECURITIES SERVICES, S.A.; BANK DEGROOF PETERCAM SPAIN, S.A.;
            BANKIA, S.A.; BANKINTER, S.A.; BANKOA, S.A.; BANQUE MAROCAINE
            COMMERCE EXTERIEUR INTERNAT.,S.A.; BNP PARIBAS ESPAÑA, S.A.;
            CAIXABANK, S.A.; CAJASUR BANCO, S.A.; CECABANK, S.A.; CITIBANK
            ESPAÑA, S.A.; DEUTSCHE BANK, S.A.E.; EBN BANCO DE NEGOCIOS, S.A.;
            EVO BANCO S.A.; IBERCAJA BANCO, S.A.; KUTXABANK, S.A.; LIBERBANK,
            S.A..; NUEVO MICRO BANK, S.A.; OPEN BANK, S.A.; POPULAR BANCA
            PRIVADA, S.A.; RENTA 4 BANCO, S.A.; SANTANDER CONSUMER FINANCE,
            S.A.; SANTANDER INVESTMENT, S.A.; SANTANDER SECURITIES SERVICES,
            S.A.; SELF TRADE BANK, S.A.; TARGOBANK, S.A.; UNICAJA BANCO,
            S.A.;WIZINK BANK, S.A.O CUALQUIER ENTIDAD RESULTANTE DE FUSIONES O
            ADQUISICIONES EN LAS QUE ALGUNA DE LAS ANTERIORES ENTIDADES
            PARTICIPE DIRECTA O INDIRECTAMENTE.
          </Text>

          <Text style={{ paddingTop: "20px", fontSize: "9px" }}>
            Don/Dña {datosCuestionario.nombreViabilidadTres}{" "}
            {datosCuestionario.apellidoViabilidadTres}, Con d.n.i. / n.i.e. nº{" "}
            {datosCuestionario.dniViabilidadTres} y e-mail{" "}
            {datosCuestionario.emailViabilidadTres} en carácter de titular de la
            solicitud Hipotecaria detallada en el encabezado.
          </Text>
          <View style={{ width: "100%", position: "relative" }}>
            <Image
              style={{
                height: "65px",
                width: "100px",
                position: "absolute",
                right: "0",
              }}
              src={firma}
            />
          </View>
          <View style={{position:"relative", width:"100%", display:"flex", alignItems:"center", justifyContent:"center", paddingTop:"150px"}}>
          <Image
            style={{
              height: "50px",
                width: "60px",
              // position: "absolute",
              // bottom: "-230px",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
            src={logoCuadrado}
          />
        </View>
        </Page>
      ) : null}

      {datosCuestionario.nombreViabilidadCuatro &&
      datosCuestionario.nombreViabilidadCuatro !== "undefined" ? (
        <Page size="A4" style={{ fontSize: "10px", padding: "10px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1",
              borderColor: "#888a87",
              borderStyle: "solid",
              color: "#888a87e4",
            }}
          >
            <View>
              <Text
                style={{
                  borderBottom: "1",
                  borderColor: "#888a87",
                  borderStyle: "solid",
                }}
              >
                Firmax Servicios Financieros
              </Text>
              <Text>E-mail: oficina@firmax.es</Text>
              <Text>
                N° Póliza de Responsabilidad Profesional: BATR2011917731
              </Text>
              <Text>N° Resgistro del Banco de España: D325</Text>
            </View>
            <Image style={{ height: "45px", width: "150px" }} src={logo} />
          </View>
          <Text style={{ paddingTop: "10px" }}>
            L.O.P.D. PARA LA SOLICITUD HIPOTECARIA Nº: {solicitudHipotecaria}
          </Text>
          <Text style={{ textAlign: "right" }}>
            En {datosCuestionario.provinciaViabilidad} {fecha}
          </Text>

          <Text style={{ paddingTop: "10px", textAlign: "center" }}>
            CLÁUSULA DE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            Las condiciones para el tratamiento de datos de carácter personal se
            rigen por lo dispuesto en la Ley Orgánica 3/2018, de 5 de diciembre,
            de Protección de Datos Personales y garantía de los derechos
            digitales (en adelante, LOPD-GDD) y en el Reglamento (UE) 2016/679,
            del Parlamento Europeo y del Consejo, relativo a la protección de
            las personas físicas en lo que respecta al tratamiento de datos
            personales (en adelante, RGPD).
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            El abajo firmante declara haber sido informado y expresamente
            consiente la incorporación de sus datos de carácter personal a los
            ficheros responsabilidad de OPCION COSTA BRAVA S.L., así como a su
            tratamiento informatizado o no de los mismos. Estos datos son
            necesarios para la contratación de los servicios solicitados a
            OPCION COSTA BRAVA S.L. y su ocultación, inexactitud o falta de
            veracidad podrá implicar una merma o la imposibilidad de poder
            prestarle el servicio. La recogida y tratamiento de los Datos
            Personales por parte de OPCION COSTA BRAVA S.L. se lleva a cabo con
            una finalidad comercial y financiera; autorizándose expresamente por
            el firmante la realización de consultas, tanto ante organismos
            públicos como privados, sobre su solvencia patrimonial, situación
            laboral, información crediticia, jurídica o fiscal, que permitan
            realizar una valoración de riesgos, de cara a la concesión de un
            préstamo-crédito con garantía hipotecaria o sin ella. Igualmente, el
            abajo firmante autoriza expresamente para que los datos por él
            facilitados, puedan servir de base para la gestión administrativa,
            estadística, contable, así como para desarrollar, negociar y
            seleccionar créditos y préstamos relativos a los servicios prestados
            por Opción Costa Brava SL. El abajo firmante presta su
            consentimiento para que sus Datos Personales puedan ser comunicados
            a las entidades que se detalla al final del presente documento(*) ,
            para su utilización por éstas con la finalidad descrita en el
            apartado anterior, de cara a facilitar el desarrollo, cumplimiento y
            control de la relación jurídica establecida con OPCION COSTA BRAVA
            S.L. Especialmente consiente que cualquiera de dichas entidades
            financieras pueda realizar la consulta de sus datos personales y
            riesgos crediticios a la CIRBE y a los demás registros de
            incidencias crediticias por encargo de OPCION COSTA BRAVA S.L.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            FormaAsimismo, el abajo firmante autoriza a OPCION COSTA BRAVA S.L.
            para que comunique sus Datos Personales, además de a las entidades a
            que se hace referencia en el párrafo anterior y cuya descripción se
            detalla al final de este documento, a las empresas de su grupo con
            las finalidades expuestas en el párrafo segundo del apartado
            anterior. En este sentido, estas entidades quedan autorizadas para
            enviar al firmante por cualquier medio, electrónico o no,
            informaciones de los productos y servicios que dichas entidades
            comercialicen, tanto en la actualidad como en el futuro. OPCION
            COSTA BRAVA S.L. garantiza que ha adoptado las medidas oportunas de
            seguridad en sus instalaciones, sistemas y ficheros, así como que
            todos los datos personales facilitados por el abajo firmante serán
            utilizados con la confidencialidad, finalidad, en forma y con las
            limitación y derechos contemplados en la normativa vigente.
          </Text>

  

          <Text
            style={{ paddingTop: "10px", textAlign: "center", fontSize: "9px" }}
          >
            INFORMACIÓN DE PROTECCIÓN DE DATOS
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            De conformidad con el RGPD y la LOPD-GDD, sus datos serán tratados
            por el responsable del tratamiento con la finalidad de prestarle los
            servicios contratados, y la base jurídica del tratamiento será la
            ejecución de un contrato o precontrato. Los datos no serán cedidos
            sin su consentimiento, excepto las cesiones necesarias para poder
            prestar el servicio a las entidades financieras correspondientes, o
            cuando la cesión sea consecuencia de una obligación legal. Sus datos
            serán conservados mientras se mantenga nuestra relación, mientras no
            solicite la supresión de los mismos, o mientras exista una
            obligación legal de conservarlos. Podrá ejercitar los derechos de
            acceso, rectificación, oposición, supresión, limitación y
            portabilidad, adjuntando una copia de su documento de identidad,
            escribiendo a la dirección: OPCION COSTA BRAVA S.L Av. Costa Brava,
            81 Local A, 17411 Vidreres. En dicha solicitud deberá especificar en
            concreto qué derecho o derechos desea ejercitar.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "9px" }}>
            Nota: este documento debe ser conservado por las entidades a
            disposición del Banco de España.
          </Text>

          <Text style={{ paddingTop: "10px", fontSize: "7px" }}>
            (*) A&G BANCA PRIVADA, S.A; ABANCA CORPORACION BANCARIA, S.A.;
            ALLFUNDS BANK, S.A.; ANDBANK ESPAÑA, S.A.; ARESBANK, S.A.; BANCA
            MARCH, S.A.; BANCA PUEYO, S.A.; BANCO ALCALA, S.A.; BANCO BILBAO
            VIZCAYA ARGENTARIA, S.A.; BANCO CAIXA GERAL, S.A.;BANCO CAMINOS,
            S.A.;BANCO CETELEM, S.A; BANCO COOPERATIVO ESPAÑOL, S.A.; BANCO DE
            ALBACETE, S.A. ; BANCO DE CAJA ESPAÑA INVER. SALAMANCA Y SORIA,S.A.;
            BANCO DE CASTILLA-LA MANCHA, S.A.; BANCO DE CREDITO SOCIAL
            COOPERATIVO, S.A.; BANCO DE DEPOSITOS, S.A.; BANCO DE SABADELL,
            S.A.; BANCO EUROPEO DE FINANZAS, S.A.; BANCO FINANTIA SOFINLOC,
            S.A.; BANCO INDUSTRIAL DE BILBAO, S.A.; BANCO INVERSIS, S.A.; BANCO
            MARE NOSTRUM, S.A.; BANCO MEDIOLANUM, S.A.; BANCO OCCIDENTAL, S.A.;
            BANCO PASTOR, S.A.; BANCO PICHINCHA ESPAÑA, S.A.; BANCO POPULAR
            ESPAÑOL, S.A.; BANCO SANTANDER, S.A.; BANCOFAR, S.A.; BANCOVAL
            SECURITIES SERVICES, S.A.; BANK DEGROOF PETERCAM SPAIN, S.A.;
            BANKIA, S.A.; BANKINTER, S.A.; BANKOA, S.A.; BANQUE MAROCAINE
            COMMERCE EXTERIEUR INTERNAT.,S.A.; BNP PARIBAS ESPAÑA, S.A.;
            CAIXABANK, S.A.; CAJASUR BANCO, S.A.; CECABANK, S.A.; CITIBANK
            ESPAÑA, S.A.; DEUTSCHE BANK, S.A.E.; EBN BANCO DE NEGOCIOS, S.A.;
            EVO BANCO S.A.; IBERCAJA BANCO, S.A.; KUTXABANK, S.A.; LIBERBANK,
            S.A..; NUEVO MICRO BANK, S.A.; OPEN BANK, S.A.; POPULAR BANCA
            PRIVADA, S.A.; RENTA 4 BANCO, S.A.; SANTANDER CONSUMER FINANCE,
            S.A.; SANTANDER INVESTMENT, S.A.; SANTANDER SECURITIES SERVICES,
            S.A.; SELF TRADE BANK, S.A.; TARGOBANK, S.A.; UNICAJA BANCO,
            S.A.;WIZINK BANK, S.A.O CUALQUIER ENTIDAD RESULTANTE DE FUSIONES O
            ADQUISICIONES EN LAS QUE ALGUNA DE LAS ANTERIORES ENTIDADES
            PARTICIPE DIRECTA O INDIRECTAMENTE.
          </Text>

          <Text style={{ paddingTop: "20px", fontSize: "9px" }}>
            Don/Dña {datosCuestionario.nombreViabilidadCuatro}{" "}
            {datosCuestionario.apellidoViabilidadCuatro}, Con d.n.i. / n.i.e. nº{" "}
            {datosCuestionario.dniViabilidadCuatro} y e-mail{" "}
            {datosCuestionario.emailViabilidadCuatro} en carácter de titular de
            la solicitud Hipotecaria detallada en el encabezado.
          </Text>
          <View style={{ width: "100%", position: "relative" }}>
            <Image
              style={{
                height: "65px",
                width: "100px",
                position: "absolute",
                right: "0",
              }}
              src={firma}
            />
          </View>
          <View style={{position:"relative", width:"100%", display:"flex", alignItems:"center", justifyContent:"center", paddingTop:"150px"}}>
          <Image
            style={{
              height: "50px",
              width: "60px",
              // position: "absolute",
              // bottom: "-230px",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
            src={logoCuadrado}
          />
        </View>
        </Page>
      ) : null}
    </Document>
  );
}

export default ContratoAcargoDelVendedor;
