// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD47iKnbAlPBTMHYh9xrFj89LqeYXO2HRs",
  authDomain: "firmax-app-97044.firebaseapp.com",
  projectId: "firmax-app-97044",
  storageBucket: "firmax-app-97044.appspot.com",
  messagingSenderId: "454723951244",
  appId: "1:454723951244:web:15ced8ac92e848e7d3b9a9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

