import { useEffect, useState } from "react";
import CuestionarioCinco from "./CuestionarioCinco";

const CuestionarioTres = ({
  datosCuestionario,
  setDatosCuestionario,
  setCuestionario,
  financiacion,
  contratoBD,
  setFinanciacion,
  setearDatosCalculadora,
  date,
  seteoDeDatosUser
}) => {
  const [financiacionPorcen, setFinanciacionPorcen] = useState(0);
  const [resto, setResto] = useState(0);
  const [advertencia, setAdvertencia] = useState(null);
  const [modalDeCarga, setModalDeCarga] = useState(true)

  const [mostrarCuadros, setMostrarCuadros] = useState(true);
  //formula de C.Estimada

  const [porcentajeNumro, setporcentajeNumro] = useState(80);
  const [cal, setCal] = useState(true);

  const handleOptionChange = (e) => {
    setDatosCuestionario({
      ...datosCuestionario,
      [e.target.name]: e.target.value,
      porcentajeMem: porcentajeNumro,
      financiacionPorcen: porcentajeNumro,
    });
  };

  function siguiente(e) {
    setDatosCuestionario({
      ...datosCuestionario,
      [e.target.name]: e.target.value,
      honorariosDeGestion: parseInt(setearDatosCalculadora.comisionMinima),
      porcentaje: porcentajeNumro,
      porcentajeMem: porcentajeNumro,
      financiacionPorcen: porcentajeNumro,
    });
    setMostrarCuadros(false);
    setModalDeCarga(true);
  }

  useEffect(() => {
    if (cal) {
      setDatosCuestionario({
        ...datosCuestionario,
        hipotecaSolicitada: (datosCuestionario.resTres * porcentajeNumro) / 100,
      });
      setCal(false);
    }
    let res =
      (datosCuestionario.hipotecaSolicitada * 100) / datosCuestionario.resTres;
    datosCuestionario.financiacionPorcentaje = res;

    if (datosCuestionario.resTres > datosCuestionario.hipotecaSolicitada) {
      let restoCuenta =
        datosCuestionario.resTres - datosCuestionario.hipotecaSolicitada;
      setResto(restoCuenta);
    } else {
      setResto(0);
    }
    setFinanciacionPorcen(res);
    if (res > 90) {
      setAdvertencia(true);
    } else {
      setAdvertencia(null);
    }
  }, [
    cal,
    datosCuestionario,
    datosCuestionario.hipotecaSolicitada,
    datosCuestionario.resTres,
    porcentajeNumro,
    setDatosCuestionario,
  ]);

  function selectPorcentaje(e) {
    setporcentajeNumro(e.target.value);
    setCal(true);
  }

  return (
    <>
      {mostrarCuadros ? (
        <section
          id="cuestionarioUno"
          className="container-firmax-section-cuestionario"
        >
          <div className="title-calculadora">
            <h2
              onClick={() => console.log(seteoDeDatosUser)}
              className="title-calculadora-sub-principal"
            >
              Hipoteca:
            </h2>
            <h2 className="title-calculadora-sub">
              {setearDatosCalculadora.nombreInmobiliaria}
            </h2>
          </div>
          <div className="container-firmax-form-flex">
            <h1>Simula la</h1>
            <h1 className="container-firmax-form-flex-color">
              Hipoteca Firmax
            </h1>
          </div>
          <form
            className="container-firmax-section-cuestionario-contenedor-tres"
            onSubmit={siguiente}
          >
            <div className="container-firmax-section-cuestionario-contenedor-form-flex">
              <div style={{ position: "relative" }}>
                <p className="symbol">€</p>
                <h3>Importe de Venta</h3>
                <input
                  value={datosCuestionario.resTres}
                  onChange={handleOptionChange}
                  type="number"
                />
                <input
                  className="no-target"
                  value={datosCuestionario.financiacionPorcentaje}
                  onChange={handleOptionChange}
                  type="number"
                  name="financiacionPorcentaje"
                />
              </div>

              <div style={{ position: "relative" }}>
                <p className="symbol">€</p>
                <h3>Hipoteca Solicitada</h3>
                <input
                  required
                  name="hipotecaSolicitada"
                  onChange={handleOptionChange}
                  value={datosCuestionario.hipotecaSolicitada}
                  type="number"
                />
                <select
                  onChange={handleOptionChange}
                  onClick={(e) => selectPorcentaje(e)}
                  className="flecha-porcentaje"
                  style={{
                    position: "absolute",
                    left: "230px",
                    top: "-20px",
                    padding: "0",
                    height: "30px",
                    width: "60px",
                    borderRadius: "0",
                    border: "none",
                    fontSize: "15px",
                  }}
                  name="porcentajeNumro"
                  id=""
                >

                  <option name="porcentaje" value="30">
                    30%
                  </option>
                  <option name="porcentaje" value="31">
                    31%
                  </option>
                  <option name="porcentaje" value="32">
                    32%
                  </option>
                  <option name="porcentaje" value="33">
                    33%
                  </option>
                  <option name="porcentaje" value="34">
                    34%
                  </option>
                  <option name="porcentaje" value="35">
                    35%
                  </option>
                  <option name="porcentaje" value="36">
                    36%
                  </option>
                  <option name="porcentaje" value="37">
                    37%
                  </option>
                  <option name="porcentaje" value="38">
                    38%
                  </option>
                  <option name="porcentaje" value="39">
                    39%
                  </option>
                  <option name="porcentaje" value="40">
                    40%
                  </option>
                  <option name="porcentaje" value="41">
                    41%
                  </option>
                  <option name="porcentaje" value="42">
                    42%
                  </option>
                  <option name="porcentaje" value="43">
                    43%
                  </option>
                  <option name="porcentaje" value="44">
                    44%
                  </option>
                  <option name="porcentaje" value="45">
                    45%
                  </option>
                  <option name="porcentaje" value="46">
                    46%
                  </option>
                  <option name="porcentaje" value="47">
                    47%
                  </option>
                  <option name="porcentaje" value="48">
                    48%
                  </option>
                  <option name="porcentaje" value="49">
                    49%
                  </option>
                  <option name="porcentaje" value="50">
                    50%
                  </option>
                  <option name="porcentaje" value="51">
                    51%
                  </option>
                  <option name="porcentaje" value="52">
                    52%
                  </option>
                  <option name="porcentaje" value="53">
                    53%
                  </option>
                  <option name="porcentaje" value="54">
                    54%
                  </option>
                  <option name="porcentaje" value="55">
                    55%
                  </option>
                  <option name="porcentaje" value="56">
                    56%
                  </option>
                  <option name="porcentaje" value="57">
                    57%
                  </option>
                  <option name="porcentaje" value="58">
                    58%
                  </option>
                  <option name="porcentaje" value="59">
                    59%
                  </option>
                  <option name="porcentaje" value="60">
                    60%
                  </option>
                  <option name="porcentaje" value="61">
                    61%
                  </option>
                  <option name="porcentaje" value="62">
                    62%
                  </option>
                  <option name="porcentaje" value="63">
                    63%
                  </option>
                  <option name="porcentaje" value="64">
                    64%
                  </option>
                  <option name="porcentaje" value="65">
                    65%
                  </option>
                  <option name="porcentaje" value="66">
                    66%
                  </option>
                  <option name="porcentaje" value="67">
                    67%
                  </option>
                  <option name="porcentaje" value="68">
                    68%
                  </option>
                  <option name="porcentaje" value="69">
                    69%
                  </option>
                  <option name="porcentaje" value="70">
                    70%
                  </option>
                  <option name="porcentaje" value="71">
                    71%
                  </option>
                  <option name="porcentaje" value="72">
                    72%
                  </option>
                  <option name="porcentaje" value="73">
                    73%
                  </option>
                  <option name="porcentaje" value="74">
                    74%
                  </option>
                  <option name="porcentaje" value="75">
                    75%
                  </option>
                  <option name="porcentaje" value="76">
                    76%
                  </option>
                  <option name="porcentaje" value="77">
                    77%
                  </option>
                  <option name="porcentaje" value="78">
                    78%
                  </option>
                  <option name="porcentaje" value="79">
                    79%
                  </option>
                  <option selected name="porcentaje" value="80">
                    80%
                  </option>
                  <option name="porcentaje" value="81">
                    81%
                  </option>
                  <option name="porcentaje" value="82">
                    82%
                  </option>
                  <option name="porcentaje" value="83">
                    83%
                  </option>
                  <option name="porcentaje" value="84">
                    84%
                  </option>
                  <option name="porcentaje" value="85">
                    85%
                  </option>
                  <option name="porcentaje" value="86">
                    86%
                  </option>
                  <option name="porcentaje" value="87">
                    87%
                  </option>
                  <option name="porcentaje" value="88">
                    88%
                  </option>
                  <option name="porcentaje" value="89">
                    89%
                  </option>
                  <option name="porcentaje" value="90">
                    90%
                  </option>
                  <option name="porcentaje" value="91">
                    91%
                  </option>
                  <option name="porcentaje" value="92">
                    92%
                  </option>
                  <option name="porcentaje" value="93">
                    93%
                  </option>
                  <option name="porcentaje" value="94">
                    94%
                  </option>
                  <option name="porcentaje" value="95">
                    95%
                  </option>
                  <option name="porcentaje" value="96">
                    96%
                  </option>
                  <option name="porcentaje" value="97">
                    97%
                  </option>
                  <option name="porcentaje" value="98">
                    98%
                  </option>
                  <option name="porcentaje" value="99">
                    99%
                  </option>
                  <option name="porcentaje" value="100">
                    100%
                  </option>
                  <option name="porcentaje" value="101">
                    101%
                  </option>
                  <option name="porcentaje" value="102">
                    102%
                  </option>
                  <option name="porcentaje" value="103">
                    103%
                  </option>
                  <option name="porcentaje" value="104">
                    104%
                  </option>
                  <option name="porcentaje" value="105">
                    105%
                  </option>
                  <option name="porcentaje" value="106">
                    106%
                  </option>
                  <option name="porcentaje" value="107">
                    107%
                  </option>
                  <option name="porcentaje" value="108">
                    108%
                  </option>
                  <option name="porcentaje" value="109">
                    109%
                  </option>
                  <option name="porcentaje" value="110">
                    110%
                  </option>
                  <option name="porcentaje" value="111">
                    111%
                  </option>
                  <option name="porcentaje" value="112">
                    112%
                  </option>
                  <option name="porcentaje" value="113">
                    113%
                  </option>
                  <option name="porcentaje" value="114">
                    114%
                  </option>
                  <option name="porcentaje" value="115">
                    115%
                  </option>
                  <option name="porcentaje" value="116">
                    116%
                  </option>
                  <option name="porcentaje" value="117">
                    117%
                  </option>
                  <option name="porcentaje" value="118">
                    118%
                  </option>
                  <option name="porcentaje" value="119">
                    119%
                  </option>
                  <option name="porcentaje" value="120">
                    120%
                  </option>
                  <option name="porcentaje" value="121">
                    121%
                  </option>
                  <option name="porcentaje" value="122">
                    122%
                  </option>
                  <option name="porcentaje" value="123">
                    123%
                  </option>
                  <option name="porcentaje" value="124">
                    124%
                  </option>
                  <option name="porcentaje" value="125">
                    125%
                  </option>
                  <option name="porcentaje" value="126">
                    126%
                  </option>
                  <option name="porcentaje" value="127">
                    127%
                  </option>
                  <option name="porcentaje" value="128">
                    128%
                  </option>
                  <option name="porcentaje" value="129">
                    129%
                  </option>
                  <option name="porcentaje" value="130">
                    130%
                  </option>
                </select>
              </div>
            </div>
            <h2 className="container-firmax-section-cuestionario-contenedor-tres-border">
              Estas financiando el {Math.round(financiacionPorcen * 10) / 10}%
              <br /> Ahorros necesarios: {resto}€ + gastos según impuestos
              aplicables
            </h2>
            {advertencia ? (
              <div
                style={{ paddingTop: "2rem" }}
                className="valorAproxSegundaGarantia-contenedor-tres-porcentaje"
              >
                <h1>
                  Para solicitar una hipoteca mayor al 90% <br /> importe de
                  compra, es posible <br /> que sea necesario aportar una
                  segunda <br /> vivienda como aval.
                </h1>
                <div className="container-firmax-div-flex-row">
                  <div className="container-firmax-div-flex-column">
                    <label>¿Aportas 2da garantía?</label>
                    <select
                      required
                      name="segundaGarantia"
                      value={datosCuestionario.segundaGarantia}
                      onChange={handleOptionChange}
                    >
                      <option value="si">Si</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <div className="container-firmax-div-flex-column">
                    <label>Detalla su valor aproximado</label>
                    <input
                      type="number"
                      value={datosCuestionario.valorAproxSegundaGarantia}
                      name="valorAproxSegundaGarantia"
                      onChange={handleOptionChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              className="container-firmax-section-fija-flex"
            >
              <button
                className="container-firmax-section-fija-button margin-buttom-cuest"
                onClick={() => setCuestionario(2)}
              >
                Atras
              </button>
              <button
                className="container-firmax-section-fija-button margin-buttom-cuest"
                type="submit"
              >
                Siguiente
              </button>
            </div>
          </form>
        </section>
      ) : (
        <CuestionarioCinco
          date={date}
          setMostrarCuadros={setMostrarCuadros}
          datosCuestionario={datosCuestionario}
          setDatosCuestionario={setDatosCuestionario}
          setCuestionario={setCuestionario}
          financiacion={financiacion}
          contratoBD={contratoBD}
          setFinanciacion={setFinanciacion}
          setearDatosCalculadora={setearDatosCalculadora}
          setModalDeCarga={setModalDeCarga}
          modalDeCarga={modalDeCarga}
          seteoDeDatosUser={seteoDeDatosUser}
        />
      )}
    </>
  );
};

export default CuestionarioTres;
