import logo from "../../img/logo.png";
import { auth } from "../../Firebase/Credenciales";
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../Firebase/Credenciales";

const DatosInmoAdmin = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [datosUser, setdatosUser] = useState();
  const [newDate, setNewDate] = useState();
  const [listEmpleados, setListEmpleados] = useState([]);
  const [idsVarios, setIdsVarios] = useState([true]);
  const [flag, setFlag] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalCrear, setModalCrear] = useState(false);

  const [mostrarUsuariosAgencia, setMostrarUsuariosAgencia] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setDate(userLog);
        async function buscarUser() {
          const docRef = doc(db, "users", userLog.uid);
          const docSnap = await getDoc(docRef);
          setdatosUser(docSnap.data());
          switch (docSnap.data().rol) {
            case "adminFirmax":
              navigate("/firmax");
              break;
            case "inmobiliaria":
              navigate("/inmobiliaria/configuracion");

              //Buscamos los empleados
              let empleado = [];
              let ids = [];
              const querySnapshot = await getDocs(collection(db, date.email));
              querySnapshot.forEach((doc) => {
                if (date.email !== doc.data().mail && !doc.data().fechaBD) {
                  empleado.push(doc.data());
                } else {
                  let id = {
                    idsVarios: doc.data().ids,
                  };
                  ids.push(id);
                }
              });

              if (flag) {
                seteoDeUsuarios(empleado);
                seteDeIds(ids);
              }

              break;
            case "inmobiliariaEmpleado":
              navigate("/inmobiliaria/inicio");
              break;
            default:
              break;
          }
        }
        buscarUser();
      } else {
        setDate(null);
        navigate("/login");
      }
    });
  }, [date, flag, listEmpleados, navigate]);

  function seteoDeUsuarios(empleado) {
    setListEmpleados(empleado);
    setFlag(false);
  }
  function seteDeIds(ids) {
    setIdsVarios({
      1: ids[0].idsVarios[1],
      2: ids[0].idsVarios[2],
      3: ids[0].idsVarios[3],
      4: ids[0].idsVarios[4],
    });
  }

  function abrirModalEditar(empleado) {
    setNewDate(empleado);
    setModal(true);
  }
  function cerrarModal() {
    setNewDate();
    setModal(false);
  }
  async function eliminar(uid, mail) {
    console.log(uid);
    console.log(mail);
    try {
      await deleteDoc(doc(db, datosUser.mail, mail));
      await deleteDoc(doc(db, "users", uid));
      await deleteDoc(doc(db, mail, mail));

      setFlag(!flag);
      Swal.fire({
        title: "Eliminado correctamente",
        icon: "success",
        confirmButtonColor: "#8FCD60",
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function editarEmpleado() {
    await editarEmpleadoIndividual();
    await editarEmpleadoDentroDelAdmin();
    await editarEmpleadoListaGral();
  }

  async function editarEmpleadoIndividual() {
    try {
      await setDoc(doc(db, newDate.mail, newDate.mail), {
        nombre: newDate.nombre,
        apellido: newDate.apellido,
        dni: newDate.dni,
        telefono: newDate.telefono,
        mail: newDate.mail,
        rol: "inmobiliariaEmpleado",
        ids: newDate,
        uid: newDate.uid,
      });
      setFlag(!flag);
      setModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function editarEmpleadoDentroDelAdmin() {
    try {
      await setDoc(doc(db, datosUser.mail, newDate.mail), {
        nombre: newDate.nombre,
        apellido: newDate.apellido,
        dni: newDate.dni,
        telefono: newDate.telefono,
        mail: newDate.mail,
        rol: "inmobiliariaEmpleado",
        ids: newDate,
        uid: newDate.uid,
      });
      setFlag(!flag);
      setModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function editarEmpleadoListaGral() {
    try {
      await setDoc(doc(db, "users", newDate.uid), {
        nombre: newDate.nombre,
        apellido: newDate.apellido,
        dni: newDate.dni,
        telefono: newDate.telefono,
        mail: newDate.mail,
        rol: "inmobiliariaEmpleado",
        ids: newDate,
        uid: newDate.uid,
      });
      setFlag(!flag);
      setModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  //Funcion para guardar user en Firebase
  async function crearUser(e) {
    e.preventDefault();
    //Creamos la cuenta
    await createUserWithEmailAndPassword(auth, newDate.mail, newDate.contraseña)
      .then(async (userCredential) => {
        //Mensaje correcto
        //guardado en listado general
        await setDoc(doc(db, "users", userCredential.user.uid), {
          nombre: newDate.nombre,
          apellido: newDate.apellido,
          dni: newDate.dni,
          telefono: newDate.telefono,
          mail: newDate.mail,
          rol: "inmobiliariaEmpleado",
          ids: idsVarios,
          uid: userCredential.user.uid,
          razonSocial: datosUser.razonSocial
        });
        //guardado en bd como usuario unico
        await setDoc(doc(db, newDate.mail, newDate.mail), {
          nombre: newDate.nombre,
          apellido: newDate.apellido,
          dni: newDate.dni,
          telefono: newDate.telefono,
          mail: newDate.mail,
          rol: "inmobiliariaEmpleado",
          ids: idsVarios,
          uid: userCredential.user.uid,
          razonSocial: datosUser.razonSocial
        });
        //guardado dentro de la bd de su admin
        await setDoc(doc(db, datosUser.mail, newDate.mail), {
          nombre: newDate.nombre,
          apellido: newDate.apellido,
          dni: newDate.dni,
          telefono: newDate.telefono,
          mail: newDate.mail,
          rol: "inmobiliariaEmpleado",
          ids: idsVarios,
          uid: userCredential.user.uid,
          razonSocial: datosUser.razonSocial
        });

        setModalCrear(false);
        //Reseteamos el form
        setNewDate({
          nombre: "",
          apellido: "",
          dni: "",
          telefono: "",
          email: "",
          contraseña: "",
        });
        //Cierro sesion
        cerrarSesion();

        console.log("se creo correctamente");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const cerrarSesion = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function mostrarAlert() {
    Swal.fire({
      title: "Atención!!",
      text: "Todos los mails creados NO PUEDEN TENER NINGUNA MAYUSCULA",
      icon: "info",
      confirmButtonColor: "#8FCD60",
    });
  }

  function handleChangeDate(e) {
    setNewDate({
      ...newDate,
      [e.target.name]: e.target.value,
    });
    console.log(newDate);
  }

  return (
    <section className="section-firmax">
      <header className="section-firmax-head">
        <img
          onClick={() => console.log(datosUser)}
          src={logo}
          alt="logo_firma"
          className="logo-firma"
        />
        <h1 className="section-firmax-head-title">
          Bienvenido {datosUser?.nombre}!!
        </h1>
      </header>

      <main className="section-firmax-main">
        <button style={{ boxShadow: mostrarUsuariosAgencia ? '0px 5px 6px rgba(0, 0, 0, 0.4)' : null }} onClick={() => setMostrarUsuariosAgencia(!mostrarUsuariosAgencia)}>
          Tus usuarios
        </button>
        <button style={{ boxShadow: mostrarUsuariosAgencia ? null : '0px 5px 6px rgba(0, 0, 0, 0.4)' }} onClick={() => setMostrarUsuariosAgencia(!mostrarUsuariosAgencia)}>Tu agencia</button>
        <button onClick={() => navigate("/inmobiliaria/Cambiar-contraseña")}>
          Cambiar contraseña
        </button>
      </main>

      {mostrarUsuariosAgencia ? (
        <>
          <section className="contenesor-usuarios">
            <p onClick={() => setModalCrear(true)} className="agregar">
              <span>+</span> Agregar Usuario
            </p>
            <table className="main-medico">
              <thead>
                <tr>
                  <th className="ancho-especial">Nombre</th>
                  <th>Apellido/s</th>
                  <th>Teléfono</th>
                  <th>E-mail</th>
                  <th>Editar</th>
                  <th>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {listEmpleados?.map((empleado, index) => (
                  <tr key={empleado.telefono}>
                    <td className="border ancho-especial">
                      <p>{empleado.nombre}</p>
                    </td>
                    <td className="border">
                      <p>{empleado.apellido}</p>
                    </td>
                    <td className="border">
                      <p>{empleado.telefono}</p>
                    </td>
                    <td className="border">
                      <p>{empleado.mail}</p>
                    </td>
                    <td className="border">
                      <i
                        onClick={() => abrirModalEditar(empleado)}
                        className="bx bx-edit-alt"
                      ></i>
                    </td>
                    <td className="border">
                      <i
                        onClick={() => eliminar(empleado.uid, empleado.mail)}
                        className=" bx bx-trash"
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>

          <div
            onClick={() => navigate("/inmobiliaria")}
            style={{ marginTop: "1rem" }}
            className="section-firmax-form-container-back"
          >
            <i className="bx bx-left-arrow-alt"></i>
            <p>Volver</p>
          </div>

          {newDate && modal ? (
            <section className="sombra-crear-new">
              <div className="new-user">
                <label className="input-label">Nombre</label>
                <input
                  className="input-field"
                  type="text"
                  value={newDate.nombre}
                  name="nombre"
                  onChange={handleChangeDate}
                />
                <label className="input-label">Apellido</label>
                <input
                  className="input-field"
                  type="text"
                  value={newDate.apellido}
                  name="apellido"
                  onChange={handleChangeDate}
                />
                <label className="input-label">Telefono</label>
                <input
                  className="input-field"
                  type="number"
                  value={newDate.telefono}
                  name="telefono"
                  onChange={handleChangeDate}
                />
                <label className="input-label">Dni</label>
                <input
                  className="input-field"
                  type="text"
                  value={newDate.dni}
                  name="dni"
                  onChange={handleChangeDate}
                />
                <button className="edit-button" onClick={editarEmpleado}>
                  Editar
                </button>
                <button
                  style={{ background: "tomato" }}
                  className="edit-button"
                  onClick={cerrarModal}
                >
                  Cerrar
                </button>
              </div>
            </section>
          ) : null}

          {modalCrear ? (
            <section className="sombra-crear-new">
              <form className="new-user" onSubmit={crearUser}>
                <i
                  onClick={mostrarAlert}
                  className="bx bxs-alarm-exclamation alarma-crear-user"
                  style={{ cursor: "pointer" }}
                ></i>
                <label className="input-label">Nombre</label>
                <input
                  required
                  className="input-field"
                  type="text"
                  value={newDate?.nombre}
                  name="nombre"
                  onChange={handleChangeDate}
                />
                <label className="input-label">Apellido</label>
                <input
                  required
                  className="input-field"
                  type="text"
                  value={newDate?.apellido}
                  name="apellido"
                  onChange={handleChangeDate}
                />
                <label className="input-label">Telefono</label>
                <input
                  required
                  className="input-field"
                  type="number"
                  value={newDate?.telefono}
                  name="telefono"
                  onChange={handleChangeDate}
                />
                <label className="input-label">Dni</label>
                <input
                  required
                  className="input-field"
                  type="text"
                  value={newDate?.dni}
                  name="dni"
                  onChange={handleChangeDate}
                />

                <label className="input-label">E-mail</label>
                <input
                  required
                  className="input-field"
                  type="email"
                  value={newDate?.mail}
                  name="mail"
                  onChange={handleChangeDate}
                />

                <label className="input-label">Contraseña</label>
                <input
                  required
                  className="input-field"
                  type="password"
                  value={newDate?.contraseña}
                  name="contraseña"
                  onChange={handleChangeDate}
                />
                <button type="submit" className="edit-button">
                  Crear empleado
                </button>

                <button
                  type="button"
                  style={{ background: "tomato" }}
                  className="edit-button"
                  onClick={() => console.log(setModalCrear(false))}
                >
                  Cerrar
                </button>
              </form>
            </section>
          ) : null}
        </>
      ) : <section className="contenedor-info-agrencia">
        <h2>{datosUser?.nombre}</h2>
        <div className="info-agrencia">
          <p><span>Direccion:</span> {datosUser?.calle} {datosUser?.altura}</p>
          <p><span>Provincia:</span> {datosUser?.provincia}</p>
          <p><span>Población:</span> {datosUser?.poblacion}</p>
          <p><span>E-mail:</span> {datosUser?.mail}</p>
          <p><span>Teléfono:</span> {datosUser?.telefono}</p>
          <p><span>CIF/DNI/NIE:</span> {datosUser?.dni}</p>
          <p><span>Razon social:</span> {datosUser?.razonSocial}</p>
        </div>
      </section>}
    </section>
  );
};

export default DatosInmoAdmin;
