import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../Firebase/Credenciales";
import logo from "../../../img/logo.png";
import { useNavigate } from "react-router-dom";

const Simulaciones = () => {
  const [mostrarSimulaciones, setmostrarSimulaciones] = useState(true);
  const [simulaciones, setSimulaciones] = useState();
  const [simulacionesRespaldo, setSimulacionesRespaldo] = useState();
  const [usuarioSimulacion, setUsuarioSimulacion] = useState();
  const [buscarFecha, setBuscarFecha] = useState();
  const navigate = useNavigate();

  async function traerSimulaciones() {
    let pushing = [];
    const querySnapshot = await getDocs(collection(db, "simulaciones"));
    querySnapshot.forEach((docSnap) => {
      let simulaciones = {
        razonSocial: docSnap.data().razonSocial,
        agente: docSnap.data().agente,
        resUno: docSnap.data().resUno,
        fechaBD: docSnap.data().fechaBD,
        interesFija: docSnap.data().interesFija,
        peronasLength: docSnap.data().peronasLength,
        aniosFija: docSnap.data().aniosFija,
        tiempoFijoMinimo: docSnap.data().tiempoFijoMinimo,
        tiempoFijoMaximo: docSnap.data().tiempoFijoMaximo,
        cuotaInicialFija: docSnap.data().cuotaInicialFija,
        //variable
        primerosMesesVar: docSnap.data().primerosMesesVar,
        primerosMesesVarInteres: docSnap.data().primerosMesesVarInteres,
        restoPlazoInteresVar: docSnap.data().restoPlazoInteresVar,
        aniosVariable: docSnap.data().aniosVariable,
        tiempoVariableMinimo: docSnap.data().tiempoVariableMinimo,
        tiempoVariableMaximo: docSnap.data().tiempoVariableMaximo,
        cuotaInicialVariable: docSnap.data().cuotaInicialVariable,
        cuotaRestoVariable: docSnap.data().cuotaRestoVariable,
        //mixto
        primerosMesesMix: docSnap.data().primerosMesesMix,
        primerosMesesMixInteres: docSnap.data().primerosMesesMixInteres,
        restoPlazoInteresMix: docSnap.data().restoPlazoInteresMix,
        aniosMixto: docSnap.data().aniosMixto,
        tiempoMixiableMinimo: docSnap.data().tiempoMixiableMinimo,
        tiempoMixiableMaximo: docSnap.data().tiempoMixiableMaximo,
        cuotaInicialMixiable: docSnap.data().cuotaInicialMixiable,
        cuotaRestoMixiable: docSnap.data().cuotaRestoMixiable,

        resPreguntaCero: docSnap.data().resPreguntaCero,
        resTres: docSnap.data().resTres,
        mejorasAntiguedad: docSnap.data().mejorasAntiguedad,
        mejorasAntiguedadDos: docSnap.data().mejorasAntiguedadDos,
        mejorasRatio: docSnap.data().mejorasRatio,
        mejorasGarantia: docSnap.data().mejorasGarantia,
        nombreViabilidad: docSnap.data().nombreViabilidad,
        edadViavilidad: docSnap.data().edadViavilidad,
        apellidoViabilidad: docSnap.data().apellidoViabilidad,
        telefonoViabilidad: docSnap.data().telefonoViabilidad,
        situacionLaboral: docSnap.data().situacionLaboral,
        fechaDeIngreso: docSnap.data().fechaDeIngreso,
        ingresosNetosMensuales: docSnap.data().ingresosNetosMensuales,
        pagasAnuales: docSnap.data().pagasAnuales,
        pagaDeCuotas: docSnap.data().pagaDeCuotas,
        financiacionPorcentaje: docSnap.data().financiacionPorcentaje,
        hipotecaSolicitada: docSnap.data().hipotecaSolicitada,
        honorariosDeGestion: docSnap.data().honorariosDeGestion,
        porcentaje: docSnap.data().porcentaje,
        porcentajeMem: docSnap.data().porcentajeMem,
        financiacionPorcen: docSnap.data().financiacionPorcen,
        hipoteca: docSnap.data().hipoteca,
        plazoHipoteca: docSnap.data().plazoHipoteca,
        cuotaEstimadaFija: docSnap.data().cuotaEstimadaFija,
        cuotaEstimadaVariable: docSnap.data().cuotaEstimadaVariable,
        cuotaEstimadaVariableEuribor:
          docSnap.data().cuotaEstimadaVariableEuribor,
        cuotaEstimadaMixta: docSnap.data().cuotaEstimadaMixta,
        cuotaEstimadaMixtaEuribor: docSnap.data().cuotaEstimadaMixtaEuribor,
        escenario: docSnap.data().escenario,
        valorFijoMixto: docSnap.data().valorFijoMixto,
        aniosTiempo: docSnap.data().aniosTiempo,
        aniosTiempoVariable: docSnap.data().aniosTiempoVariable,
        aniosTiempoMixto: docSnap.data().aniosTiempoMixto,
        innteresFija: docSnap.data().innteresFija,
        innteresVariable: docSnap.data().innteresVariable,
        innteresVariableEuribor: docSnap.data().innteresVariableEuribor,
        interesMixta: docSnap.data().interesMixta,
        interesMixtaEuribor: docSnap.data().interesMixtaEuribor,
        hipotecaSolicitadaConHonorarios:
          docSnap.data().hipotecaSolicitadaConHonorarios === undefined
            ? 0
            : docSnap.data().hipotecaSolicitadaConHonorarios,
        honorariosDeEscritura:
          docSnap.data().honorariosDeEscritura === undefined
            ? "no"
            : docSnap.data().honorariosDeEscritura,
        hipotecaSolicitadaHonarios:
          docSnap.data().hipotecaSolicitadaHonarios === undefined
            ? 0
            : docSnap.data().hipotecaSolicitadaHonarios,
        hipotecaMasComisionMasHonorarios:
          docSnap.data().hipotecaMasComisionMasHonorarios === undefined
            ? 0
            : docSnap.data().hipotecaMasComisionMasHonorarios,
        segundaGarantia:
          docSnap.data().segundaGarantia === undefined
            ? "si"
            : docSnap.data().segundaGarantia,
        valorAproxSegundaGarantia:
          docSnap.data().valorAproxSegundaGarantia === undefined
            ? 0
            : docSnap.data().valorAproxSegundaGarantia,
        totalAcobrar:
          docSnap.data().totalAcobrar === undefined
            ? 0
            : docSnap.data().totalAcobrar,
        calculadora: docSnap.data().calculadora,
        //datos persona dos
        nombreViabilidadDos:
          docSnap.data().nombreViabilidadDos === undefined
            ? undefined
            : docSnap.data().nombreViabilidadDos,
        edadViavilidadDos:
          docSnap.data().edadViavilidadDos === undefined
            ? undefined
            : docSnap.data().edadViavilidadDos,
        fechaDeIngresoDos:
          docSnap.data().fechaDeIngresoDos === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoDos,
        ingresosNetosMensualesDos:
          docSnap.data().ingresosNetosMensualesDos === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesDos,
        pagasAnualesDos:
          docSnap.data().pagasAnualesDos === undefined
            ? undefined
            : docSnap.data().pagasAnualesDos,
        pagaDeCuotasDos:
          docSnap.data().pagaDeCuotasDos === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasDos,
        situacionLaboralDos:
          docSnap.data().situacionLaboralDos === undefined
            ? undefined
            : docSnap.data().situacionLaboralDos,
        //datos persona tres
        nombreViabilidadTres:
          docSnap.data().nombreViabilidadTres === undefined
            ? undefined
            : docSnap.data().nombreViabilidadTres,
        edadViavilidadTres:
          docSnap.data().edadViavilidadTres === undefined
            ? undefined
            : docSnap.data().edadViavilidadTres,
        fechaDeIngresoTres:
          docSnap.data().fechaDeIngresoTres === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoTres,
        ingresosNetosMensualesTres:
          docSnap.data().ingresosNetosMensualesTres === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesTres,
        pagasAnualesTres:
          docSnap.data().pagasAnualesTres === undefined
            ? undefined
            : docSnap.data().pagasAnualesTres,
        pagaDeCuotasTres:
          docSnap.data().pagaDeCuotasTres === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasTres,
        situacionLaboralTres:
          docSnap.data().situacionLaboralTres === undefined
            ? undefined
            : docSnap.data().situacionLaboralTres,
        //datos persona cuatro
        nombreViabilidadCuatro:
          docSnap.data().nombreViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().nombreViabilidadCuatro,
        edadViavilidadCuatro:
          docSnap.data().edadViavilidadCuatro === undefined
            ? undefined
            : docSnap.data().edadViavilidadCuatro,
        fechaDeIngresoCuatro:
          docSnap.data().fechaDeIngresoCuatro === undefined
            ? undefined
            : docSnap.data().fechaDeIngresoCuatro,
        ingresosNetosMensualesCuatro:
          docSnap.data().ingresosNetosMensualesCuatro === undefined
            ? undefined
            : docSnap.data().ingresosNetosMensualesCuatro,
        pagasAnualesCuatro:
          docSnap.data().pagasAnualesCuatro === undefined
            ? undefined
            : docSnap.data().pagasAnualesCuatro,
        pagaDeCuotasCuatro:
          docSnap.data().pagaDeCuotasCuatro === undefined
            ? undefined
            : docSnap.data().pagaDeCuotasCuatro,
        situacionLaboralCuatro:
          docSnap.data().situacionLaboralCuatro === undefined
            ? undefined
            : docSnap.data().situacionLaboralCuatro,
        dniViabilidad: docSnap.data().dniViabilidad,
        emailViabilidad: docSnap.data().emailViabilidad,
        domicilioViabilidad: docSnap.data().domicilioViabilidad,
        poblacionViabilidad: docSnap.data().poblacionViabilidad,
        provinciaViabilidad: docSnap.data().provinciaViabilidad,
        apellidoViabilidadDos:
          docSnap.data().apellidoViabilidadDos === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadDos,
        dniViabilidadDos:
          docSnap.data().dniViabilidadDos === undefined
            ? undefined
            : docSnap.data().dniViabilidadDos,
        emailViabilidadDos:
          docSnap.data().emailViabilidadDos === undefined
            ? undefined
            : docSnap.data().emailViabilidadDos,
        telefonoViabilidadDos:
          docSnap.data().telefonoViabilidadDos === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadDos,
        apellidoViabilidadTres:
          docSnap.data().apellidoViabilidadTres === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadTres,
        dniViabilidadTres:
          docSnap.data().dniViabilidadTres === undefined
            ? undefined
            : docSnap.data().dniViabilidadTres,
        emailViabilidadTres:
          docSnap.data().emailViabilidadTres === undefined
            ? undefined
            : docSnap.data().emailViabilidadTres,
        telefonoViabilidadTres:
          docSnap.data().telefonoViabilidadTres === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadTres,
        apellidoViabilidadCuatro:
          docSnap.data().apellidoViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().apellidoViabilidadCuatro,
        dniViabilidadCuatro:
          docSnap.data().dniViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().dniViabilidadCuatro,
        emailViabilidadCuatro:
          docSnap.data().emailViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().emailViabilidadCuatro,
        telefonoViabilidadCuatro:
          docSnap.data().telefonoViabilidadCuatro === undefined
            ? undefined
            : docSnap.data().telefonoViabilidadCuatro,
        viable: docSnap.data().viable,
      };
      pushing.push(simulaciones);
      setSimulaciones(pushing);
      setSimulacionesRespaldo(pushing);
    });
  }

  function buscarPorFecha(e) {
    e.preventDefault();
    let encontrados = [];
    for (let i = 0; i < simulacionesRespaldo.length; i++) {
      console.log(buscarFecha + " asd");
      if (buscarFecha === simulacionesRespaldo[i].fechaBD) {
        console.log("existo");
        encontrados.push(simulacionesRespaldo[i]);
      }
    }
    setSimulaciones(encontrados);
  }

  function buscarTodas() {
    setSimulaciones(simulacionesRespaldo);
  }

  function handleChange(e) {
    let fecha = ([e.target.name] = e.target.value);
    let partesFecha = fecha.split("-");

    let dia = parseInt(partesFecha[2]).toString(); // Convertir a número y luego a cadena para eliminar ceros
    let mes = parseInt(partesFecha[1]).toString();

    let nuevaFecha = dia + "-" + mes + "-" + partesFecha[0];
    setBuscarFecha(nuevaFecha);
  }
  useEffect(() => {
    if (mostrarSimulaciones) {
      traerSimulaciones();
      setmostrarSimulaciones(false);
    }
    console.log(simulaciones);
  }, [mostrarSimulaciones, simulaciones]);

  return (
    <section className="section-firmax-simulaciones">
      <header className="section-firmax-head">
        <img src={logo} alt="logo_firma" />
      </header>

      <main>
        <section className="contenesor-usuarios max-width">
          <div className="contenedor-filtro-busqueda">
            <form onSubmit={buscarPorFecha} className="busqueda-fecha">
              <p>Buscar por fecha</p>
              <input type="date" name="fechaBusqueda" onChange={handleChange} />
              <button type="submit" className="btn-buscar">
                Buscar
              </button>
            </form>
            <button className="btn-buscar" onClick={buscarTodas}>
              Ver todas las simulaciones
            </button>
          </div>
          <table className="main-medico">
            <thead>
              <tr>
                <th>Simulacion</th>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Cliente/s</th>
                <th>Teléfono</th>
                <th>Inmobiliaria</th>
                <th>Agente</th>
                <th>Viable</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {simulaciones?.map((simulacion) => (
                <tr key={simulacion.telefonoViabilidad}>
                  <td className="border ancho-especial">
                    <p>{simulacion.telefonoViabilidad}</p>
                  </td>
                  <td className="border">
                    <p>{simulacion.fechaBD}</p>
                  </td>
                  <td className="border">
                    <p>{simulacion.resUno}</p>
                  </td>
                  <td className="border">
                    <p>
                      {simulacion.nombreViabilidad}{" "}
                      {simulacion.apellidoViabilidad}
                    </p>
                  </td>
                  <td className="border">
                    <p>{simulacion.telefonoViabilidad}</p>
                  </td>
                  <td className="border">
                    <p>{simulacion.razonSocial}</p>
                  </td>
                  <td className="border">
                    <p>{simulacion.agente}</p>
                  </td>
                  <td className="border" style={{ textAlign: "center" }}>
                    <p
                      style={{
                        color: simulacion.viable === "si" ? "green" : "red",
                        fontWeight: "500",
                      }}
                    >
                      {simulacion.viable}
                    </p>
                  </td>
                  <td className="border" style={{ textAlign: "center" }}>
                    <p style={{cursor:"pointer"}} onClick={()=>setUsuarioSimulacion(simulacion)}>Ver simulación</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>


        <section>
          {usuarioSimulacion ? (
            <section className="contenedor-simulacion-detalles-dos">
              <div className="contenedor-simulacion-detalles-info-dos">
                <div className="contenedor-simulacion-detalles-info-texto">
                    <h3>Precio de venta</h3>
                    <p>{usuarioSimulacion.resTres}Є</p>
                </div>
                <div className="contenedor-simulacion-detalles-info-texto">
                  <h3>Hipoteca</h3>
                  {usuarioSimulacion.resTres}Є
                </div>
                <div className="contenedor-simulacion-detalles-info-texto">
                  <h3>Escritura</h3>
                  <p>{parseInt(usuarioSimulacion.totalAcobrar) + parseInt(usuarioSimulacion.resTres)}Є</p>
                </div>
                <div className="contenedor-simulacion-detalles-info-texto">
                  <h3>Honorarios</h3>
                  {usuarioSimulacion.totalAcobrar}Є
                </div>
                <div className="contenedor-simulacion-detalles-info-texto">
                  <h3>2ª Garantía</h3>
                  {usuarioSimulacion.segundaGarantia}
                </div>
                <div className="contenedor-simulacion-detalles-info-texto">
                  <h3>Valor 2ª Garantía</h3>
                  {usuarioSimulacion.segundaGarantia === 'si' ? `${usuarioSimulacion.valorAproxSegundaGarantia}Є` : "0Є"}
                </div>
              </div>
              {usuarioSimulacion.valorFijoMixto === "fijo" ? (
                <div className="contenedor-simulacion-detalles-datos">
                  <h2>
                    Hipoteca <span style={{ color: "#69b62d" }}>Fija</span>{" "}
                    desde
                  </h2>
                  <h2>{usuarioSimulacion.innteresFija}%</h2>
                  <div className="contenedor-simulacion-detalles-datos-row">
                    <p>Plazo</p>
                    <h2>{usuarioSimulacion.aniosTiempo}</h2>
                    <p>años</p>
                  </div>
                  <h3>Cuota estimada</h3>
                  <h3 style={{ textAlign: "center" }}>
                    {usuarioSimulacion.cuotaEstimadaFija}Є
                  </h3>
                </div>
              ) : usuarioSimulacion.valorFijoMixto === "variable" ? (
                <div className="contenedor-simulacion-detalles-datos">
                  <h2>
                    Hipoteca <span style={{ color: "#69b62d" }}>Variable</span>{" "}
                    desde
                  </h2>
                  <div style={{ display: "flex", gap: " 2rem" }}>
                    <h2>{usuarioSimulacion.innteresVariableEuribor}%</h2>
                    <h2>{usuarioSimulacion.innteresVariable}%</h2>
                  </div>

                  <div className="contenedor-simulacion-detalles-datos-row">
                    <p>Plazo</p>
                    <h2>{usuarioSimulacion.aniosTiempoVariable}</h2>
                    <p>años</p>
                  </div>
                  <div style={{ display: "flex", gap: " 2rem" }}>
                    <section>
                      <h3>Cuota estimada</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {usuarioSimulacion.cuotaEstimadaVariable}$
                      </h3>
                    </section>

                    <section>
                      <h3>Resto de plazo</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {usuarioSimulacion.cuotaEstimadaVariableEuribor}$
                      </h3>
                    </section>
                  </div>
                </div>
              ) : (
                <div className="contenedor-simulacion-detalles-datos">
                  <h2>
                    Hipoteca <span style={{ color: "#69b62d" }}>Mixto</span>{" "}
                    desde
                  </h2>
                  <div style={{ display: "flex", gap: " 2rem" }}>
                    <h2>{usuarioSimulacion.interesMixtaEuribor}%</h2>
                    <h2>{usuarioSimulacion.interesMixta}%</h2>
                  </div>

                  <div className="contenedor-simulacion-detalles-datos-row">
                    <p>Plazo</p>
                    <h2>{usuarioSimulacion.aniosTiempoMixto}</h2>
                    <p>años</p>
                  </div>
                  <div style={{ display: "flex", gap: " 2rem" }}>
                    <section>
                      <h3>Cuota estimada</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {usuarioSimulacion.cuotaEstimadaMixta}$
                      </h3>
                    </section>

                    <section>
                      <h3>Resto de plazo</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {usuarioSimulacion.cuotaEstimadaMixtaEuribor}$
                      </h3>
                    </section>
                  </div>
                </div>
              )}
            </section>
          ) : null}
        </section>
      </main>

      <div className="section-firmax-inmobiliaria-arrow">
        <i
          onClick={() => navigate("/firmax")}
          style={{ padding: "2rem" }}
          className="bx bx-left-arrow-alt"
        >
          Volver
        </i>
      </div>
    </section>
  );
};

export default Simulaciones;
