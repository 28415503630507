import logo from "../../img/logo.png";
import { auth } from "../../Firebase/Credenciales";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Firebase/Credenciales";
import { useState, useEffect } from "react";
import Inmobiliarias from "./Sections/Inmobiliarias";
import { doc, getDoc } from "firebase/firestore";
import VerInmobiliarias from "./Sections/VerInmobiliarias";

const AdminFirmax = () => {
  const navigate = useNavigate();
  let users = [];
  let calculadoras = [];
  let datosForm = [];
  const [mostrarUsers, setmostrarUsers] = useState([]);
  const [mostrarCalculadoras, setMostrarCalculadoras] = useState([]);
  const [mostrarInmo, setMostrarInmo] = useState(true);
  const [asignarCalcu, setAsignarCalcu] = useState(false);
  const [verInmos, setVerInmos] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setDate(userLog);
        async function buscarUser() {
          const docRef = doc(db, "users", userLog.uid);
          const docSnap = await getDoc(docRef);
          switch (docSnap.data().rol) {
            case "adminFirmax":
              navigate("/firmax");
              break;
            case "inmobiliaria":
              navigate("/inmobiliaria");
              break;
            default:
              break;
          }
        }
        buscarUser();
      } else {
        setDate(null);
        navigate("/login");
      }
    });
  }, [date, navigate]);

  const cerrarSesion = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function listarUsers() {
    const querySnapshot = await getDocs(collection(db, "inmobiliarias"));
    querySnapshot.forEach((doc) => {
      let user = {
        nombre: doc.data().nombre,
        razonSocial: doc.data().razonSocial,
        dni: doc.data().dni,
        mail: doc.data().mail,
        telefono: doc.data().telefono,
        calle: doc.data().calle,
        altura: doc.data().altura,
      };
      datosForm.push(user);
      users.push({
        ...user,
        uid: doc._document.key.path.segments[6],
      });
    });

    setmostrarUsers(users);

    const querySnapshotDos = await getDocs(collection(db, "calculadoras"));
    querySnapshotDos.forEach((docDos) => {
      let calculadora = {
        nombre: docDos.data().datosNuevaCalculadora.nombre,
        id: docDos.data().id,
      };
      calculadoras.push(calculadora);
    });
    setMostrarCalculadoras(calculadoras);
    setMostrarInmo(!mostrarInmo);
    setAsignarCalcu(!asignarCalcu)
  }

  async function listarUsersParaVer() {
    console.log("asd");
    const querySnapshot = await getDocs(collection(db, "inmobiliarias"));
    querySnapshot.forEach((doc) => {
      let user = {
        nombre: doc.data().nombre,
        razonSocial: doc.data().razonSocial,
        dni: doc.data().dni,
        mail: doc.data().mail,
        telefono: doc.data().telefono,
        calle: doc.data().calle,
        altura: doc.data().altura,
      };
      datosForm.push(user);
      users.push({
        ...user,
        uid: doc._document.key.path.segments[6],
      });
    });
    setmostrarUsers(users);
    setVerInmos(!verInmos);
    setMostrarInmo(!mostrarInmo);
  }

  return (
    <>
      {mostrarInmo ? (
        <section className="contenedor-section-firmax">
          <header className="section-firmax-head">
            <img src={logo} alt="logo_firma" />
          </header>

          <main className="section-firmax-main">
            <button onClick={() => navigate("/firmax/Crear-calculadora")}>
              Crear calculadora
            </button>
            <button onClick={() => navigate("/firmax/Crear-contrato")}>
              Crear contratos
            </button>
            <button onClick={listarUsers}>Asignar Calculadora</button>
            <button onClick={listarUsersParaVer}>Ver inmobiliarias</button>
            <button onClick={() => navigate("/firmax/Crear-user")}>
              Crear usuario
            </button>
            <button onClick={() => navigate("/firmax/Crear-mejoras")}>
              Crear mejoras
            </button>
            <button onClick={() => navigate("/firmax/Cambiar-contraseña")}>
              Cambiar contraseña
            </button>
            <button
              onClick={() => navigate("/firmax/Actualizar-diferencial-euribor")}
            >
              Diferencial/Euribor
            </button>
            <button
              onClick={() => navigate("/firmax/simulaciones")}
            >
              Ver simulaciones
            </button>
            <button onClick={cerrarSesion}>Cerrar sesion</button>
          </main>
        </section>
      ) : null}

      {asignarCalcu ? (
        <Inmobiliarias
          mostrarUsers={mostrarUsers}
          setMostrarInmo={setMostrarInmo}
          mostrarInmo={mostrarInmo}
          mostrarCalculadoras={mostrarCalculadoras}
          setAsignarCalcu = {setAsignarCalcu}
        />
      ) : null}

      {verInmos ? (
        <VerInmobiliarias
          mostrarUsers={mostrarUsers}
          setMostrarInmo={setMostrarInmo}
          setVerInmos={setVerInmos}
          setmostrarUsers={setmostrarUsers}
        />
      ) : null}
    </>
  );
};

export default AdminFirmax;
