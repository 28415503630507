/* eslint-disable no-unused-vars */
import logo from "../../../img/logo.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../../Firebase/Credenciales";
import { onAuthStateChanged } from "firebase/auth";
import Swal from "sweetalert2";

const CrearCalculadora = () => {
  const [correcto, setCorrecto] = useState(null);
  const [colorButton, setColorButton] = useState(false);

  const [tipoDeContrato, setTipoDeContrato] = useState({
    nombre: "",
  });

  const [date, setDate] = useState("");

  const [listContratos, setListContratos] = useState([]);
  const [end, setEend] = useState(true);
  const [datosAntiguio, setDatosAntiguio] = useState();
  const [modal, setModal] = useState(false);
  const [modalSombra, setModalSombra] = useState(false);
  const [modalCrear, setModalCrear] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [uidEliminar, setUidEliminar] = useState("");
  const navigate = useNavigate();

  function onChangeTipoDeContratos(e) {
    setTipoDeContrato({
      ...tipoDeContrato,
      [e.target.name]: e.target.value,
    });
  }
  function handleNewName(e) {
    setDatosAntiguio({
      ...datosAntiguio,
      nuevoContrato: e.target.value,
    });
  }

  //guardar collecion en BD -- 1
  async function guardarTipoDeContrato(e) {
    e.preventDefault();
    try {
      let timerInterval;
      Swal.fire({
        title: "Guardado correctamente!!",
        html: "Este modal se cerrara automaticamente en: <b></b> milisegundos.",
        icon: "success",
        timer: 7000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
          setEend(true);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          setModalCrear(false);
          setModalSombra(false);
        }
      });
      const docRef = doc(db, "id", "id");
      const docSnap = await getDoc(docRef);
      await setDoc(doc(db, "tipoDeContrato", tipoDeContrato.tipoDeContrato), {
        nombre: tipoDeContrato.tipoDeContrato,
        id: docSnap.data().id + 1,
      });
      setColorButton(true);
      setTipoDeContrato({
        tipoDeContrato: "",
      });
      await setDoc(doc(db, "id", "id"), {
        id: docSnap.data().id + 1,
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Intentelo nuevamente!",
        icon: "error",
        confirmButtonColor: "#8FCD60",
      });
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setDate(userLog);
        async function buscarUser() {
          const docRef = doc(db, "users", userLog.uid);
          const docSnap = await getDoc(docRef);
          switch (docSnap.data().rol) {
            case "adminFirmax":
              navigate("/firmax/Crear-contrato");
              break;
            case "inmobiliaria":
              navigate("/inmobiliaria");
              break;
            default:
              break;
          }
        }
        buscarUser();
      } else {
        setDate(null);
        navigate("/login");
      }
    });

    if (correcto === true || correcto === false) {
      setTimeout(() => {
        setCorrecto(null);
      }, 3300);
    }

    setTimeout(() => {
      setColorButton(false);
    }, 6000);

    let pushing = [];

    async function traerContratosBd() {
      const querySnapshot = await getDocs(collection(db, "tipoDeContrato"));
      querySnapshot.forEach((doc) => {
        let contrato = {
          nombre: doc.data().nombre,
          id: doc.data().id,
        };
        pushing.push(contrato);
      });

      function compararPorId(a, b) {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      }
      setListContratos(pushing.sort(compararPorId));
      setEend(false);
    }
    if (end) {
      traerContratosBd();
    }
  }, [colorButton, correcto, end, listContratos, navigate]);

  async function modificar(idProp, nombreProp, e) {
    e.preventDefault();
    try {
      await setDoc(doc(db, "tipoDeContrato", nombreProp), {
        id: idProp,
        nombre: datosAntiguio.nuevoContrato,
      });
      setEend(true);
      let timerInterval;
      Swal.fire({
        title: "Guardado correctamente!!",
        html: "Este modal se cerrara automaticamente en: <b></b> milisegundos.",
        icon: "success",
        timer: 7000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          setModal(false);
          setModalSombra(false);
        }
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Intentelo nuevamente!",
        icon: "error",
        confirmButtonColor: "#8FCD60",
      });
    }
  }

  function abrirModal(idProp, nombreProp) {
    setDatosAntiguio({
      id: idProp,
      contrato: nombreProp,
    });
    setModal(true);
    setModalSombra(true);
  }
  function mostrarModalCrear() {
    setModalCrear(true);
    setModalSombra(true);
  }
  function cerrarModal(num) {
    if (num === 1) {
      setModalCrear(false);
      setModalSombra(false);
    } else {
      setModal(false);
      setModalSombra(false);
    }
  }

  async function abrirModalCerrar(uid, opt) {
    switch (opt) {
      case 1:
        setModalSombra(true);
        setModalEliminar(true);
        setUidEliminar(uid);
        break;
      case 2:
        console.log(uid);
        await deleteDoc(doc(db, "tipoDeContrato", uid));
        Swal.fire({
          title: "Eliminado correctamente",
          icon:"success",
          confirmButtonColor:"#8FCD60"
      })
        setModalSombra(false);
        setModalEliminar(false);
        setEend(true);
        break;
      case 3:
        setModalSombra(false);
        setModalEliminar(false);
        break;
      default:
        break;
    }
  }
  return (
    <section className="contenedor-calculadora-parametros-firmax">
      <img className="logo-calculadora" src={logo} alt="logo_firma" />
      <div
        style={{ top: modalSombra ? "0" : "-500rem" }}
        className="sombra"
      ></div>
      <main
        style={{
          position: "relative",
          height: "70vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={mostrarModalCrear}
          className="container-firmax-section-fija-button"
        >
          Crear nuevo contrato
        </button>
        {modalCrear ? (
          <form
            style={{ padding: "3rem" }}
            className="form-crear"
            onSubmit={guardarTipoDeContrato}
          >
            <div className="grid-entero">
              <label className="container-flex-opt-escenarios-column-center">
                Tipo de contrato
              </label>
              <label style={{ textAlign: "left", padding: "1rem" }}>
                Nombre
              </label>
              <input
                required
                className="grid-witht"
                name="tipoDeContrato"
                value={tipoDeContrato.tipoDeContrato}
                onChange={onChangeTipoDeContratos}
                type="text"
                placeholder="Tipo de contrato....*"
              />
            </div>
            <div className="container-buttons-save-cancel">
              <button
                style={{ background: "tomato" }}
                onClick={() => cerrarModal(1)}
                className="container-firmax-section-fija-button ancho"
              >
                Cancelar
              </button>
              <button
                className="container-firmax-section-fija-button button-grid ancho"
                type="submit"
              >
                Guardar Contrato
              </button>
            </div>
          </form>
        ) : null}
        {modal ? (
          <form
            style={{ top: modal ? "50%" : "-500rem" }}
            className="form-edit"
          >
            <div style={{ display: "flex", gap: "2rem" }}>
              <label>
                ID: <span>{datosAntiguio.id}</span>
              </label>
              <label>
                Nombre: <span>{datosAntiguio.contrato}</span>
              </label>
            </div>

            <div style={{ display: "flex", gap: "2rem" }}>
              <label>Nuevo nombre:</label>
              <input
                type="text"
                className="grid-witht"
                onChange={handleNewName}
              />
            </div>
            <div className="container-buttons-save-cancel">
              <button
                style={{ background: "tomato" }}
                onClick={() => cerrarModal(2)}
                className="container-firmax-section-fija-button ancho"
              >
                Cancelar
              </button>
              <button
                onClick={(e) =>
                  modificar(datosAntiguio.id, datosAntiguio.contrato, e)
                }
                className="container-firmax-section-fija-button ancho"
              >
                Guardar cambios
              </button>
            </div>
          </form>
        ) : null}
        {modalEliminar ? (
          <div className="form-edit" style={{ textAlign: "center" }}>
            <h2>¿Estas seguro de elininar?</h2>
            <div className="container-buttons-save-cancel">
              <button
                style={{ background: "tomato" }}
                onClick={() => abrirModalCerrar("null", 3)}
                className="container-firmax-section-fija-button ancho"
              >
                Cancelar
              </button>
              <button
                onClick={() => abrirModalCerrar(uidEliminar, 2)}
                className="container-firmax-section-fija-button ancho"
              >
                Eliminar
              </button>
            </div>
          </div>
        ) : null}
        
        {listContratos.map((x, index) => (
          <div className="tabla-contratos" key={x.id}>
            <p className="ancho-index">{index + 1}.</p>
            <p className="ancho-nombre">{x.nombre}</p>
            <div className="tabla-contratos-mod-eli">
              <i
                onClick={() => abrirModal(x.id, x.nombre)}
                className="bx bx-calendar-edit"
              ></i>
              {/* <i
                onClick={() => abrirModalCerrar(x.nombre, 1)}
                className="bx bx-trash"
              ></i> */}
            </div>
          </div>
        ))}
        <div className="section-firmax-inmobiliaria-arrow"    onClick={()=> navigate('/firmax')}>
          <i
            style={{ padding: "2rem 0" }}
            className="bx bx-left-arrow-alt"
          >
            
          </i>
          Volver
        </div>
      </main>
    </section>
  );
};

export default CrearCalculadora;
