import logo from '../../../img/logo.png'
import {  auth } from '../../../Firebase/Credenciales'
import { useEffect , useState } from 'react';
import {useNavigate} from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc , getDoc } from "firebase/firestore"; 
import {db} from '../../../Firebase/Credenciales'
import Swal from 'sweetalert2';

const Inmobiliarias = ({mostrarUsers , setMostrarInmo , mostrarInmo , mostrarCalculadoras , setAsignarCalcu}) => {
    const [date , setDate] = useState("")
    
    const [valueSelect, setValueSelect] = useState({
      1: "",
      2: "",
      3: "",
      4: "",
    })

    const [datosUserFiltrado, setDatosUserFiltrado] = useState({
      nombre:"",
      razonSocial: "",
      dni: "", 
      calle: "", 
      altura: "", 
      mail: "" ,
      telefono: "",
      uid:""
    })

    const [mostrarForm, setmostrarForm] = useState(null)

    const navigate = useNavigate()
    
    function handleSelectChange(e) {
      setValueSelect({
        ...valueSelect,
        [e.target.name] : e.target.value
      })
    }
  
    function setearUser() {
      for (let index = 0; index < mostrarUsers.length; index++) {
        if (mostrarUsers[index].nombre === valueSelect.inmobiliariaSelect) {
          setDatosUserFiltrado({
            nombre:mostrarUsers[index].nombre,
            razonSocial: mostrarUsers[index].razonSocial,
            dni: mostrarUsers[index].dni, 
            calle: mostrarUsers[index].calle, 
            altura: mostrarUsers[index].altura, 
            mail: mostrarUsers[index].mail ,
            telefono: mostrarUsers[index].telefono,
            uid:mostrarUsers[index].uid
          })
        }
      }
      setmostrarForm(!mostrarForm)
      console.log(datosUserFiltrado)
    }

    async function guardarParametrosNuevos(e) {
      e.preventDefault()
      console.log(datosUserFiltrado , valueSelect)
      Swal.fire({
        title: "Guardado exitoso!",
        icon:"success",
        confirmButtonColor:"#8FCD60"
    })
      try {
        await setDoc(doc(db, datosUserFiltrado.mail, datosUserFiltrado.mail), {
          nombre: datosUserFiltrado.nombre,
          razonSocial: datosUserFiltrado.razonSocial,
          calle: datosUserFiltrado.calle,
          altura: datosUserFiltrado.altura,
          dni: datosUserFiltrado.dni,
          telefono: datosUserFiltrado.telefono,
          mail: datosUserFiltrado.mail,
          rol: "inmobiliaria",
          uid: datosUserFiltrado.uid,
          ids: valueSelect,
        });
        await setDoc(doc(db, "inmobiliarias", datosUserFiltrado.mail), {
          nombre: datosUserFiltrado.nombre,
          razonSocial: datosUserFiltrado.razonSocial,
          calle: datosUserFiltrado.calle,
          altura: datosUserFiltrado.altura,
          dni: datosUserFiltrado.dni,
          telefono: datosUserFiltrado.telefono,
          mail: datosUserFiltrado.mail,
          rol: "inmobiliaria",
          uid: datosUserFiltrado.uid,
          ids: valueSelect,
        });
        
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Intentelo nuevamente!",
          icon:"error",
          confirmButtonColor:"#8FCD60"
      })
      }
  }


    // let pruebita = []
    // // eslint-disable-next-line no-unused-vars
    // const [first, setfirst] = useState([])

    // async function calc() {
    //   const querySnapshotDos = await getDocs(collection(db, "rosita@gmail.com"));
    //   querySnapshotDos.forEach((docDos) => {
    //       let calculadora = {
    //         nombre: docDos.data().nombre,
    //         razonSocial: docDos.data().razonSocial,
    //         calle: docDos.data().calle,
    //         altura: docDos.data().altura,
    //         dni: docDos.data().dni,
    //         telefono: docDos.data().telefono,
    //         mail: docDos.data().mail,
    //         rol: docDos.data().rol,
    //         uid: docDos.data().uid,
    //         ids: docDos.data().ids,
    //       }
    //       pruebita.push(calculadora)
    //   })

    //   setfirst(pruebita)

    //   let h = 1

    //   console.log(pruebita[0].ids[h])
    // }

    useEffect(() => {
      onAuthStateChanged(auth, (userLog) => {
          if (userLog) {
            setDate(userLog); 
            async function buscarUser() {
              const docRef = doc(db, "users" , userLog.uid)
              const docSnap = await getDoc(docRef);
              switch (docSnap.data().rol) {
                case "adminFirmax":
                    navigate('/firmax')
                    break;
                case "inmobiliaria":
                    navigate('/inmobiliaria')
                    break;    
                default:
                    break;
            }
            }
            buscarUser()
          } else {
            setDate(null);
            navigate('/login')   
          }
        });
    }, [date, navigate]);
     
    function volver() {
      setAsignarCalcu(false)
      setMostrarInmo(!mostrarInmo)
    }

    return ( 
        <section className='contenedor-calculadora-creacion-firmax-section'> 
            <img src={logo} alt="logo_firma"/>
            <main >
                <div className='contenedor-calculadora-creacion-firmax-section-select'>
                  <select className='select-size' name="inmobiliariaSelect" onChange={handleSelectChange}>
                    <option value="">Seleccione la Inmobiliaria</option>
                      {mostrarUsers.map((usuario)=> (
                        <option name="inmobiliariaSelect" value={usuario.nombre} key={usuario.nombre} className='section-fimax-inmobiliaria-scroll-user-contenedor'>
                          {usuario.nombre}
                        </option>
                      ))}
                  </select>
                  <button className='contenedor-calculadora-creacion-firmax-form-button' onClick={setearUser}>Buscar inmobiliaria</button>
                </div>
  
              {mostrarForm ?   
                  <form className='contenedor-calculadora-creacion-firmax-form form-grid-inmo' onSubmit={guardarParametrosNuevos}>
                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label>Nombre</label>
                      <input name="nombre" type="text" value={datosUserFiltrado.nombre}/>
                    </div>

                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Razon Social</label>
                      <input name="razonSocial" type="text" value={datosUserFiltrado.razonSocial}/>
                    </div>

                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Calle</label>
                      <input name="calle" type="text" value={datosUserFiltrado.calle} />
                    </div>

                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Altura</label>
                      <input name="altura" type="number" value={datosUserFiltrado.altura}/>
                    </div>

                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Mail</label>
                      <input name="mail" type="text" value={datosUserFiltrado.mail}/>
                    </div>

                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Telefono</label>
                      <input name="telefono" type="number" value={datosUserFiltrado.telefono}/>
                    </div>

                    
                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Calculadora uno</label>
                      <select className='select-size' name="1" id="" onChange={handleSelectChange}>
                      <option value="1">Seleccione la Calculadora</option>
                        {mostrarCalculadoras.map((calculadora)=> (
                          <option name="1" value={calculadora.id} key={calculadora.id} className='section-fimax-inmobiliaria-scroll-user-contenedor'>
                            {calculadora.nombre}
                          </option>
                        ))}
                      </select>
                    </div>

                    
                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Calculadora dos</label>
                      <select className='select-size' name="2" id="" onChange={handleSelectChange}>
                        <option value="">Seleccione la Calculadora</option>
                        {mostrarCalculadoras.map((calculadora)=> (
                          <option name="2" value={calculadora.id} key={calculadora.id} className='section-fimax-inmobiliaria-scroll-user-contenedor'>
                            {calculadora.nombre}
                          </option>
                      ))}
                      </select>
                    </div>

                    
                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Calculadora tres</label>
                      <select className='select-size' name="3" id="" onChange={handleSelectChange}>
                        <option value="">Seleccione la Calculadora</option>
                        {mostrarCalculadoras.map((calculadora)=> (
                          <option name="3" value={calculadora.id} key={calculadora.id} className='section-fimax-inmobiliaria-scroll-user-contenedor'>
                          {calculadora.nombre} 
                          </option>
                        ))}
                      </select>
                    </div>

                    
                    <div className='contenedor-calculadora-creacion-firmax-form-row'>
                      <label htmlFor="">Calculadora cuatro</label>
                      <select className='select-size' name="4" id="" onChange={handleSelectChange}>
                        <option value="">Seleccione la Calculadora</option>
                          {mostrarCalculadoras.map((calculadora)=> (
                            <option name="4" value={calculadora.id} key={calculadora.id} className='section-fimax-inmobiliaria-scroll-user-contenedor'>
                              {calculadora.nombre}
                            </option>
                          ))}
                      </select>
                    </div>
                    <button style={{gridColumn:"1/3"}} type='submit' className='contenedor-calculadora-creacion-firmax-form-button'>Asignar Calculadora</button>
                  </form>   
                :null}
            </main>

            <div className='section-firmax-inmobiliaria-arrow'>
                <i onClick={volver} className='bx bx-left-arrow-alt'>Volver</i>
            </div>   

            {/* <button onClick={calc}>CALCULAR</button> */}
               
        </section>
     );
}
 
export default Inmobiliarias;