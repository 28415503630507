import logo from "../../../img/logo.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../../../Firebase/Credenciales";
import Swal from "sweetalert2";

function DiferencialEuribor() {
  const [date, setDate] = useState(null);
  const [datosForm, setDatosForm] = useState({
    diferencial: '',
    euribor: ''
  });
  const [flag, setFlag] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (userLog) => {
      if (userLog) {
        setDate(userLog);
        async function buscarUser() {
          const docRef = doc(db, "users", userLog.uid);
          const docSnap = await getDoc(docRef);
          switch (docSnap.data().rol) {
            case "adminFirmax":
              navigate("/firmax/Actualizar-diferencial-euribor");
              break;
            case "inmobiliaria":
              navigate("/inmobiliaria");
              break;
            default:
              break;
          }
        }
        buscarUser();
      } else {
        setDate(null);
        navigate("/login");
      }
    });

    if (flag) {
        async function traerDifeerncialEuribor() {
            const docRef = doc(db, "diferencialEuribor" , "diferencial")
            const docRefDos = doc(db, "diferencialEuribor" , "euribor")
            const docSnap = await getDoc(docRef);
            const docSnapDos = await getDoc(docRefDos);
            setDatosForm({
                diferencial: docSnap.data().diferencial,
                euribor: docSnapDos.data().euribor
            });
            setFlag(!flag)
        }
        traerDifeerncialEuribor()
    }
  }, [date, flag, navigate]);

  function handleInputChange(e) {
    setDatosForm({
      ...datosForm,
      [e.target.name]: e.target.value,
    });
    console.log(datosForm);
  }

  async function actualizarDatos(e) {
    e.preventDefault();
    await setDoc(doc(db, "diferencialEuribor", "diferencial"), {
      diferencial: datosForm.diferencial,
    });

    await setDoc(doc(db, "diferencialEuribor", "euribor"), {
      euribor: datosForm.euribor,
    });

    Swal.fire({
      title: "Se modifico correctamente",
      icon: "success",
      confirmButtonColor: "#8FCD60",
    });
    setFlag(true)
  }

  return (
    <section className="section-firmax">
      <header className="section-firmax-head">
        <img src={logo} alt="logo_firma" />
      </header>

      <main className="main-firmax-form-alto">
        <form
          className="section-firmax-form-password"
          onSubmit={actualizarDatos}
        >
          <label>Diferencial</label>
          <input
            step="0.01"
            type="number"
            name="diferencial"
            value={datosForm.diferencial}
            onChange={handleInputChange}
          />
          <label>Euribor</label>
          <input
            step="0.1"
            type="number"
            name="euribor"
            onChange={handleInputChange}
            value={datosForm.euribor}
          />
          <button type="submit" className="section-firmax-form-grid-width">
            Actualizar
          </button>
        </form>

        <div
          onClick={() => navigate("/firmax")}
          className="section-firmax-form-container-back padding-top"
        >
          <i className="bx bx-left-arrow-alt"></i>
          <p>Volver</p>
        </div>
      </main>
    </section>
  );
}

export default DiferencialEuribor;
